import { useAuth } from '@/hooks/Authentication/auth';
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useDeleteTopicComment } from '@/hooks/Feedback/useDeleteTopicComment';
import { useModals } from '@/hooks/ModalManager';
import { DeleteIcon } from '@chakra-ui/icons';
import { Avatar, Flex, IconButton, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { ActivityItemProps } from './types';

export const CommentItem: React.FC<ActivityItemProps> = ({ item, topicId }) => {
  const { user } = useAuth();
  const permissions = useUserPermissions();
  const modals = useModals();
  const deleteTopicComment = useDeleteTopicComment();

  const flexDirection = user?.id === item.authorId ? 'row-reverse' : 'row';

  const align = user?.id === item.authorId ? 'flex-end' : 'flex-start';

  const justify = user?.id === item.authorId ? 'flex-end' : 'flex-start';

  const background = user?.id === item.authorId ? 'blackAlpha.50' : 'purple.50';

  const borderColor =
    user?.id === item.authorId ? 'blackAlpha.100' : 'purple.100';

  const borderRadius =
    user?.id === item.authorId ? '10px 10px 0 10px' : '0 10px 10px 10px';

  const handleDelete = React.useCallback(() => {
    modals.confirm({
      title: 'Atenção!',
      body:
        'Tem certeza que deseja deletar este comentário? Esta ação não pode ser desfeita.',
      onConfirm: async () => {
        await deleteTopicComment.mutateAsync({
          commentId: item.id,
          topicId,
        });
      },
    });
  }, [deleteTopicComment, item.id, modals, topicId]);

  return (
    <Flex w="full" justify={justify}>
      <Flex gap="4" flexDir={flexDirection} alignItems={align} role="group">
        <Avatar name={item?.authorName} size="md" />
        <Flex
          mt="1"
          bg={background}
          p="3"
          flexDir="column"
          minW="150px"
          maxW="250px"
          h="fit-content"
          borderWidth="thin"
          borderColor={borderColor}
          borderRadius={borderRadius}
        >
          <Text fontWeight="bold" color="purple.500">
            {item?.authorName}
          </Text>
          <Text>{item?.content}</Text>
          <Text color="muted" mt="2">
            {moment(item.createdAt).calendar()}
          </Text>
        </Flex>
        <IconButton
          variant="outline"
          alignSelf="center"
          isDisabled={!permissions.checkPermission('admin')}
          icon={<DeleteIcon />}
          onClick={handleDelete}
          aria-label="delete"
        />
      </Flex>
    </Flex>
  );
};
