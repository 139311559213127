import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import { parseFieldConditionals } from '@/modules/WebForm/utils/parseFieldConditionals';
import debounce from '@/utils/debounce';
import {
  Box,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Tag,
} from '@chakra-ui/react';
import React from 'react';

import FormControlComponent from '../FormControlComponent';

const format = (val: string) => `${val}`;
const parse = (val: string) => val.replace('[ˆ0-9.]', '');

const IntegerContent: React.FC<QuestionModel> = () => {
  const { error, onChange, fieldState, alert, field, clear } = useField();

  const [value, setValue] = React.useState(() => {
    if (fieldState && !Array.isArray(fieldState)) {
      const num = parseFloat(fieldState?.content);
      if (!Number.isNaN(num)) {
        return num.toFixed();
      }
    }
    return '';
  });

  const update = React.useRef(
    debounce((value: string) => {
      const answer = createAnswer(parseFloat(value));
      const conditionalResult = parseFieldConditionals(field, answer);
      const isInvalid = conditionalResult.error;

      if (isInvalid) {
        clear();
        setValue('');
      }
    }, 500),
  );

  const handleBlur = React.useCallback(() => {
    onChange(createAnswer(parseFloat(value)));
  }, [value, onChange]);

  const handleFocus = React.useCallback(() => {
    if (value.length === 0) {
      setValue('0.00');
      onChange(createAnswer(0.0));
    }
  }, [value.length, onChange]);

  const onChangeText = React.useCallback((valueString: string) => {
    const answer = `${parse(valueString)}`;
    setValue(answer);
    update.current(answer);
  }, []);

  return (
    <FormControlComponent isInvalid={error}>
      <HStack alignItems="center">
        <Box>R$</Box>
        <NumberInput
          onChange={onChangeText}
          value={format(value)}
          min={0}
          step={0.5}
          precision={2}
          position="relative"
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </HStack>
      <Tag size="lg" colorScheme="red" mt="4" hidden={!alert}>
        {alert}
      </Tag>
    </FormControlComponent>
  );
};

export default IntegerContent;
