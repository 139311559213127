import React from 'react';
import { Box, Text, Icon } from '@chakra-ui/react';
import { FiFile } from 'react-icons/fi';
import { FileRejection, DropEvent, useDropzone } from 'react-dropzone';

type PlaceholderProps = {
  title?: string;
  subtitle?: string;
};
export const Placeholder: React.FC<PlaceholderProps> = ({
  title = 'Arraste e solte imagens aqui',
  subtitle = 'Anexe quantas imagens desejar. Cada imagem não deve exceder 5mb',
}) => {
  return (
    <Box
      flex="1"
      display="flex"
      flexFlow="row"
      gap="4"
      justifyContent="center"
      alignItems="center"
      color="muted"
    >
      <Icon as={FiFile} fontSize="36" />
      <Box fontSize="sm" textAlign="start" flexGrow={0}>
        <Text fontWeight="bold">{title}</Text>
        <Text fontWeight="normal">{subtitle}</Text>
      </Box>
    </Box>
  );
};

export type DropzoneProps = {
  onDrop?:
    | (<T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent,
      ) => void)
    | undefined;
  placeholder?: React.ReactNode;
  placeholderProps?: PlaceholderProps;
  hasFiles?: boolean;
  accept?: string | string[] | undefined;
  maxFiles?: number;
};

export const Dropzone: React.FC<DropzoneProps> = ({
  onDrop = () => null,
  placeholderProps,
  placeholder = <Placeholder {...placeholderProps} />,
  children,
  hasFiles,
  accept,
  maxFiles = 10,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles,
    accept,
  });
  return (
    <Box
      w="full"
      minH="32"
      rounded="md"
      p="2"
      bg="white"
      border="2px dashed var(--chakra-colors-blackAlpha-200)"
      cursor="pointer"
      display="flex"
      _hover={{ bg: !hasFiles ? 'blackAlpha.50' : '' }}
      transition="background-color 150ms ease 0s"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {hasFiles ? children : placeholder}
    </Box>
  );
};
