/* eslint-disable @typescript-eslint/ban-types */
import { forwardRef } from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';
import * as React from 'react';
import { FieldValues } from 'react-hook-form';

import { Fields } from './Fields';
import { FormProps, HookForm } from './Form';
import { FormLayout } from './layout';
import { SubmitButton } from './SubmitButton';

interface AutoFormOptions {
  /**
   * The submit button label.
   * Pass `null` to render no submit button.
   */
  submitLabel?: React.ReactNode;
  /**
   * The schema.
   * Supports object schema, Yup or Zod.
   * @see https://www.saas-ui.dev/docs/forms/auto-form
   */
  schema: any;
  /**
   * The field resolver.
   */
  fieldResolver?: any;
}

export interface AutoFormProps<
  TFieldValues extends FieldValues,
  TContext extends object = object
> extends Omit<FormProps<TFieldValues, TContext>, 'schema' | 'children'>,
    AutoFormOptions {
  children?: React.ReactNode;
}

export const AutoForm = forwardRef(
  <
    TFieldValues extends FieldValues = FieldValues,
    TContext extends object = object
  >(
    props: AutoFormProps<TFieldValues, TContext>,
    ref: React.ForwardedRef<HTMLFormElement>,
  ) => {
    const {
      schema,
      submitLabel = 'Submit',
      fieldResolver,
      children,
      ...rest
    } = props;

    return (
      <HookForm {...rest} schema={schema} ref={ref}>
        <FormLayout>
          <Fields schema={schema} fieldResolver={fieldResolver} />
          {submitLabel && <SubmitButton>{submitLabel}</SubmitButton>}
          {children}
        </FormLayout>
      </HookForm>
    );
  },
);

if (__DEV__) {
  AutoForm.displayName = 'AutoForm';
}
