const elementExists = document.getElementById('google-api-key');

if (!elementExists) {
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
  script.id = 'google-api-key';
  document.body.appendChild(script);
}

export default elementExists;
