import { gql } from '@/gql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import React from 'react';

import { queryAutomationFlow } from './useFetchFlow';

export const deleteAutomationStepMutation = gql(`#graphql
  mutation DeleteAutomationStep($input: StepsDeleteInput!) {
    deleteAutomationStep(input: $input) {
      automationStep {
       ...AutomationStepFragment
      }
    }
  }
`);

export function useDeleteAutomationStep() {
  const toast = useToast();
  const [mutate, payload] = useMutation(deleteAutomationStepMutation, {
    refetchQueries: [queryAutomationFlow],
  });

  const deleteAutomationStep = React.useCallback(
    async (id: string) => {
      const result = await mutate({
        variables: {
          input: {
            id,
          },
        },
      });

      if (result.data || result.errors?.length) {
        toast({
          title: result.errors?.length ? 'Ocorreu um erro' : 'Etapa exluída',
          status: result.errors?.length ? 'error' : 'success',
        });
      }
    },
    [mutate, toast],
  );
  return {
    deleteAutomationStep,
    ...payload,
  };
}
