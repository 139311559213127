import styled from 'styled-components';

export type ErrorContainerTypes = {
  hasError?: boolean;
};

export const container = styled.div``;

export const ErrorContainer = styled.div<ErrorContainerTypes>`
  border-radius: 10px;
  padding: 5px 0px 5px 0px;
  ${({ theme, hasError }) => {
    if (hasError) return `box-shadow: 0 0 0 1px ${theme.colors.error};`;
    return null;
  }}
`;
