import { HttpResponse } from '@/modules/shared/data/protocols/http';
import { PostFormParams } from './interfaces';

const createOrUpdate = async (params: PostFormParams) => {
  const { apiAnswers, apiServices, itemId, options } = params;
  if (itemId) {
    const resp = await apiServices.update({
      ids: [itemId],
      data: apiAnswers,
      updateAll: false,
    });
    return resp;
  }
  const resp = await apiServices.create({
    ...apiAnswers,
    options: options || null,
  });
  return resp;
};

const PostForm = async (params: PostFormParams): Promise<HttpResponse<any>> => {
  const resp = await createOrUpdate(params);
  return resp;
};

export default PostForm;
