import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@components/Button';
import { AlertModalRenderProps } from './interfaces';
import {
  ContentContainer,
  Title,
  ButtonContainer,
  SubtitleContainer,
} from './styles';

const AlertModal: React.FC<AlertModalRenderProps> = ({
  onClose,
  title,
  subTitle,
}) => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <Title>{t(title)}</Title>
      <SubtitleContainer>{subTitle}</SubtitleContainer>
      <ButtonContainer>
        <Button type="CONFIRM" callback={onClose} />
      </ButtonContainer>
    </ContentContainer>
  );
};

export default AlertModal;
