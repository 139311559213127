// day: "yyyy-mm-dd"

function parseDayToDate(raw: string): Date {
  const [y, m, d] = raw.split('-');
  // return new Date(+dateParts[0], parseInt(dateParts[1], 10) - 1, +dateParts[2]);
  return new Date(parseInt(y, 10), parseInt(m, 10) - 1, parseInt(d, 10));
}

function parseDateToDay(raw: Date): string {
  const day = raw.getDate();
  const month = raw.getMonth();
  const year = raw.getFullYear();
  return `${year}-${month}-${day}`;
}

// yyyy-mm-dd -> dd/mm/yyyy
function formatDay(raw: string): string {
  const [y, m, d] = raw.split('-');
  return `${d}/${m}/${y}`;
}

// dd/mm/yyyy ->  yyyy-mm-dd
function unformatDay(raw: string): string {
  const [d, m, y] = raw.split('/');
  return `${y}-${m}-${d}`;
}

function getDifferenceInDays(date1: number, date2: number): number {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60 * 60 * 24);
}

export {
  getDifferenceInDays,
  parseDayToDate,
  parseDateToDay,
  formatDay,
  unformatDay,
};
