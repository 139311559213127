import { FeedbackError, FeedbackSuccess } from '@/components/Feedback';
import config from '@/Constants';
import { useModals } from '@/hooks/ModalManager';
import { checklistServices } from '@/modules/shared/adapters/ApiServices';
import { TaskChecklistScreen } from '@/screens/registrations/Tasks';
import { TaskWithEvent } from '@/screens/registrations/Tasks/interfaces';
import { changeTaskStatus } from '@/screens/registrations/Tasks/services/changeTaskStatus';
import { deleteTaskEvent } from '@/screens/registrations/Tasks/services/deleteTaskEvent';
import { deleteTasksService } from '@/screens/registrations/Tasks/services/deleteTasksService';
import { startTaskEvent } from '@/screens/registrations/Tasks/services/startTaskEvent';
import { createContext } from '@/utils/react-utils';
import { useToast } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import useSWR from 'swr';

import { getTaskAndEvent } from './hooks/getTaskAndEvent';
import { Checklist } from './interfaces';

type SummaryProps = {
  id: string;
  type: 'event' | 'task';
  onUpdate?: (data: PartialWithId<Checklist>) => Promise<void>;
  refresh?: () => void;
  handleStart?: () => void;
  continueChecklist?: () => void;
  handleFinish?: () => void;
  handleRestart?: () => void;
  handleDelete?: () => void;
  handleUpdateTaskStatus?: () => void;
};

export type ChecklistSummaryContextProps = SummaryProps & {
  isLoading: boolean;
  data?: any;
  revalidate?: () => void;
};

const [Context, useChecklistSummary] = createContext<
  ChecklistSummaryContextProps
>({
  name: 'ChecklistSummaryContext',
});

export const formatTaskData = (task: any) => {
  if (!task) return task;
  const newData: Checklist = {
    ...task,
    author_name: task.member,
    local_name: task.local?.name,
    local_address_name: task.local?.address_name,
    parentable_name: task.name,
    formularies: task.formulary_name ? [task.formulary_name] : [],
  };
  return newData;
};

const ChecklistSummaryProvider: React.FC<SummaryProps> = (props) => {
  const { id, type, children, onUpdate, refresh } = props;
  const modals = useModals();
  const queryClient = useQueryClient();
  const [isUpdating, setIsUpdating] = React.useState(false);
  const toast = useToast();
  const { t } = useTranslation();

  const updateChecklist = useCallback(
    async (data: PartialWithId<Checklist>) => {
      try {
        const resp = await checklistServices.update({
          ids: [data.id],
          data,
          updateAll: false,
        });
        if (resp.statusCode >= 200 && resp.statusCode < 300) {
          toast({
            status: 'success',
            title: t('common.success'),
            description: t('messageEdit.event'),
          });
        } else {
          toast({
            status: 'error',
            title: t('common.error'),
            description: t('checklists.errors.update_checklist'),
          });
        }
      } catch {
        toast({
          status: 'error',
          title: t('common.error'),
          description: t('checklists.errors.update_checklist'),
        });
      }
    },
    [t, toast],
  );

  const { data, revalidate, isValidating, mutate } = useSWR<
    PartialWithId<TaskWithEvent>
  >(
    `${config.TASKS_WITH_EVENT_URL}/${id}`,
    async () => {
      return getTaskAndEvent(id, type);
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const isLoading = isValidating;

  const formatedData = type === 'task' ? formatTaskData(data) : data;

  const handleUpdate = React.useCallback(
    async (data: PartialWithId<Checklist>) => {
      onUpdate ? await onUpdate(data) : updateChecklist(data);
      const eventId = type === 'event' ? id : formatedData?.event?.id;
      const oldData = queryClient.getQueryData<Checklist>(
        `${config.EVENTS_URL}/${eventId}`,
      );

      if (!oldData) return;

      const newData: Checklist = {
        ...oldData,
        ...data,
      };

      queryClient.setQueriesData<Checklist>(
        `${config.EVENTS_URL}/${eventId}`,
        newData,
      );
    },
    [formatedData?.event?.id, id, onUpdate, queryClient, type, updateChecklist],
  );

  const continueChecklist = React.useCallback(async () => {
    modals.open({
      id: 'task-checklist',
      size: 'full',
      motionPreset: 'slideInBottom',
      closeOnEsc: false,
      children: (
        <TaskChecklistScreen onSubmit={revalidate} taskId={formatedData?.id} />
      ),
    });
  }, [formatedData?.id, modals, revalidate]);

  const handleStart = React.useCallback(async () => {
    try {
      setIsUpdating(true);
      const res = await changeTaskStatus(formatedData, 'draft');
      await startTaskEvent(formatedData);
      mutate(res);
      await revalidate();
      continueChecklist();
    } catch (e) {
      FeedbackError({ mainText: 'Unexpected Error' });
    } finally {
      setIsUpdating(false);
    }
  }, [continueChecklist, formatedData, mutate, revalidate]);

  const handleRestart = React.useCallback(async () => {
    try {
      setIsUpdating(true);
      const res = await changeTaskStatus(formatedData, 'pending');
      await deleteTaskEvent(formatedData);
      mutate(res);
    } catch (e) {
      FeedbackError({ mainText: 'Unexpected Error' });
    } finally {
      setIsUpdating(false);
    }
  }, [formatedData, mutate]);

  const handleFinish = React.useCallback(async () => {
    try {
      setIsUpdating(true);
      const res = await changeTaskStatus(formatedData, 'done');
      mutate(res);
      revalidate();
    } catch (e) {
      FeedbackError({ mainText: 'Unexpected Error' });
    } finally {
      setIsUpdating(false);
      FeedbackSuccess({
        mainText: 'Tarefa finalizada.',
        subText: 'Você poderá visualizar o relatório do checklist.',
      });
    }
  }, [formatedData, mutate, revalidate]);

  const handleDelete = React.useCallback(async () => {
    await deleteTasksService([formatedData?.id]);
    modals.pop();
    refresh && refresh();
  }, [formatedData?.id, modals, refresh]);

  return (
    <Context
      value={{
        id,
        type,
        data: formatedData,
        onUpdate: handleUpdate,
        handleStart,
        continueChecklist,
        handleRestart,
        handleFinish,
        handleDelete,
        isLoading: isUpdating || isLoading,
        revalidate,
      }}
    >
      {children}
    </Context>
  );
};

export { useChecklistSummary, ChecklistSummaryProvider };
