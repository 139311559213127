import { QueryFilterClient } from '@queryfilter/data/protocols';
import {
  FiltersData,
  FetchOptionsParams,
  FetchOptionsResponse,
} from '@queryfilter/domain/interfaces';

import Config from '@/Constants';
import { AxiosHelper } from '@shared/infra/protocols/http';
import { UnexpectedError } from '@/modules/shared/domain/errors';

class AxiosQueryFilterClient implements QueryFilterClient {
  async fetchFilters(screen: string): Promise<FiltersData> {
    const response = await AxiosHelper.get({
      url: screen.includes('api/')
        ? screen
        : `${Config.FILTERS_URL}?screen=${screen}`,
    });
    if (response.statusCode < 200 || response.statusCode >= 300) {
      throw new UnexpectedError();
    }
    const data: FiltersData = response.body;
    return data;
  }

  async fetchOptions({
    path,
    query,
    page,
    searchValue,
    title
  }: FetchOptionsParams): Promise<FetchOptionsResponse> {
    let url = path;
    if (query) url += query;
    if (page) url += `&page=${page}`;
    if (searchValue) url += `&searchValue=${searchValue}`;
    if (title) url += `&keyFilter=${title}`;
    const response = await AxiosHelper.get({
      url,
    });
    if (response.statusCode < 200 || response.statusCode >= 300) {
      throw new UnexpectedError();
    }
    const data: FetchOptionsResponse = response.body;
    return data;
  }
}

export default AxiosQueryFilterClient;
