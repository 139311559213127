import React from 'react';

import { useTranslation } from 'react-i18next';
import { Input } from '@chakra-ui/react';
import { InformEmailProps } from './localGeneric';
import { SubmitButton } from '../../components';
import { CommonContainer, Title, Description } from '../components';

const InformEmail: React.FC<InformEmailProps> = ({
  handleEmailChange,
  email,
  handleSubmitEmail,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <CommonContainer>
      <Title content={t('password_recovery.title')} />
      <Description content={t('password_recovery.get_email_description')} />
      <Input
        variant="auth"
        value={email}
        placeholder="E-mail"
        autoCapitalize="none"
        onChange={handleEmailChange}
        margin={0}
        size="lg"
      />
      <SubmitButton
        buttonContent={t('buttons.confirm')}
        callback={handleSubmitEmail}
        style={{ margin: 0, marginTop: 15 }}
        disabled={!email}
        isLoading={isLoading}
      />
    </CommonContainer>
  );
};

export default InformEmail;
