import React from 'react';

import { FieldContext } from '../context/Field';
import { useWebFormState } from '../data/formState';
import { Answer, IField } from '../interfaces';

export type UseField<
  T extends Answer | Answer[] | undefined = Answer | Answer[] | undefined
> = {
  fieldState: T;
  error?: boolean;
  alert?: string | undefined;
  field: IField;
  onChange: (value: T) => void;
  clear: () => void;
};

/** subscribe to transient changes on answer */

export default function useField<
  T extends Answer | Answer[] | undefined = Answer | Answer[] | undefined
>(): UseField<T> {
  const { fieldId } = React.useContext(FieldContext);

  const onChangeAnswer = useWebFormState(
    React.useCallback((state) => state.onChangeAnswer, []),
  );

  const clearAnswer = useWebFormState(
    React.useCallback((state) => state.clearAnswer, []),
  );

  const field = useWebFormState(
    React.useCallback((state) => state.fields?.[fieldId], [fieldId]),
  );

  const error = useWebFormState(
    React.useCallback((state) => state.errors?.[fieldId], [fieldId]),
  );
  const alert = useWebFormState(
    React.useCallback((state) => state.alerts?.[fieldId], [fieldId]),
  );

  const answers = useWebFormState(
    React.useCallback((state) => state.answers?.[fieldId] as T, [fieldId]),
  );

  const onChange = React.useCallback(
    (answer: T) => {
      onChangeAnswer(fieldId, answer);
    },
    [fieldId, onChangeAnswer],
  );

  const clear = React.useCallback(() => {
    clearAnswer(fieldId);
  }, [clearAnswer, fieldId]);

  return { onChange, clear, fieldState: answers, alert, error, field };
}
