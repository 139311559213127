/* eslint-disable no-underscore-dangle */
import * as Yup from 'yup';
import { Option } from '@/modules/core/domain/interfaces';

const validateQuestion = Yup.object().shape({
  name: Yup.string().min(2).required('Nome é obrigatório'),
  question_type: Yup.string().required(),
  options_attributes: Yup.array().test(
    'notEmptyArr',
    'Adicione no mínimo uma opção',
    function callback(arr) {
      const options: Option[] = arr as Option[];
      const hasOptions = [
        'radio',
        'radio_combo',
        'checkbox_combo',
        'checkbox',
      ].includes(this.parent.question_type || '');
      if (!hasOptions) return true;

      const validOptions = options?.filter((item) => !item._destroy);
      return validOptions && validOptions.length > 0;
    },
  ),
  batch_collections: Yup.array().test(
    'notEmptyArr',
    'Adicione no mínimo uma opção',
    function callback(arr) {
      const hasOptions = ['collections'].includes(
        this.parent.question_type || '',
      );
      if (!hasOptions) return true;

      return !!arr && arr.length > 0;
    },
  ),
});

export default validateQuestion;
