import React, { useState, useCallback } from 'react';

import Tooltip from '@components/Tooltip';
import { PhoneValidator } from '@utils/Validators';
import debounce from '@utils/debounce';
import {
  QuestionContainer,
  QuestionHeader,
  QuestionTitle,
  QuestionSubtitle,
  Input,
  InfoTooltip,
  TooltipContainer,
} from '../common';
import { PhoneProps } from './interfaces';

const Phone: React.FC<PhoneProps> = ({
  question,
  errors,
  initialValues,
  onChange,
  viewOnly,
}) => {
  const disabled = !!viewOnly;
  const { phoneParser, phoneStrToNumber, phoneValidator } = PhoneValidator;
  const [phone, setPhone] = useState<string>(
    initialValues?.values ? phoneParser(initialValues?.values) : '',
  );
  const [debouncedPhone] = useState(() =>
    debounce(async (newText: string) => {
      const phoneNumber = phoneStrToNumber(newText);
      if (phoneNumber === '') onChange(phoneNumber);
      if (phoneValidator(phoneNumber)) onChange(phoneNumber);
    }, 200),
  );

  const onChangeInput = useCallback(
    (newPhone: string) => {
      setPhone(phoneParser(newPhone));
      debouncedPhone(newPhone);
    },
    [debouncedPhone, phoneParser],
  );

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionTitle>{question.name}</QuestionTitle>
        {question.tipText && (
          <Tooltip description={question.tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      <Input
        value={phone}
        disabled={disabled}
        type="text"
        onChange={(e) => onChangeInput(e.target.value)}
        hasError={
          (phone ? !phoneValidator(phoneStrToNumber(phone)) : false) || !!errors
        }
      />
      <QuestionSubtitle hasError={!!errors}>
        {errors && errors.errors}
      </QuestionSubtitle>
    </QuestionContainer>
  );
};

export default Phone;
