import { Table, Td } from '../components/table';
import { useReport } from './hooks/use-context';

function ReportDetails() {
  const { header } = useReport();

  if (!header) return null;

  const {
    os_number,
    client_name,
    os_type,
    service_location,
    shed_type,
  } = header;

  return (
    <div
      style={{
        width: '100%',
        fontSize: '0.875rem',
        fontWeight: 'bold',
      }}
    >
      <Table>
        <tbody>
          {!!os_number && (
            <tr>
              <Td>NÚMERO DA OS</Td>
              <Td>{os_number}</Td>
            </tr>
          )}

          {!!client_name && (
            <tr>
              <Td>NOME DO CLIENTE</Td>
              <Td>{client_name}</Td>
            </tr>
          )}

          {!!os_type && (
            <tr>
              <Td>TIPO DE OS</Td>
              <Td>{os_type}</Td>
            </tr>
          )}

          {!!service_location && (
            <tr>
              <Td>LOCAL DE ATENDIMENTO</Td>
              <Td>{service_location}</Td>
            </tr>
          )}

          {!!shed_type && (
            <tr>
              <Td>TIPO DE GALPÃO</Td>
              <Td>{shed_type}</Td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export function Header() {
  const { header } = useReport();

  if (!header) return null;

  const formattedTitle = header.event_name?.split(/ - #/)[0];

  return (
    <div
      style={{
        marginBottom: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        color: 'black',
        pageBreakAfter: 'avoid',
        width: '100%',
      }}
    >
      <h1
        style={{
          fontSize: '1.875rem',
          fontWeight: 500,
          lineHeight: '1.25',
        }}
      >
        {formattedTitle}
      </h1>

      <ReportDetails />
    </div>
  );
}
