import { Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import FormPages from './FormPages';

const Sidebar: React.FC<FlexProps> = (props) => {
  return (
    <Flex
      flexDir="column"
      as="nav"
      zIndex="sticky"
      h="full"
      w="80"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg={useColorModeValue('white', 'gray.800')}
      borderColor={useColorModeValue('inherit', 'gray.700')}
      borderRightWidth="1px"
      {...props}
    >
      <Scrollbars>
        <FormPages />
      </Scrollbars>
    </Flex>
  );
};

export default Sidebar;
