import config from '@/Constants';
import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { useMutation, useQueryClient } from 'react-query';
import { createFeedback, getFeedbackCount } from './services/createFeedback';

export const useCreateFeedback = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(createFeedback, {
    onMutate({ discussionTopicId, vote }) {
      const queryKey = [config.DISCUSSION_TOPICS_URL, discussionTopicId];
      const previousTopicData = queryClient.getQueryData<DiscussionTopic>(
        queryKey,
      );

      if (!previousTopicData) return;
      const feedbackCount = getFeedbackCount(previousTopicData, vote);
      const updatedTopic: DiscussionTopic = {
        ...previousTopicData,
        voteCurrentUser: vote,
        countLikes: feedbackCount.likes,
        countDislikes: feedbackCount.dislikes,
      };

      queryClient.setQueryData<DiscussionTopic>(queryKey, (old) => ({
        ...old,
        ...updatedTopic,
      }));
    },
    onSettled(data, error, variables) {
      const queryKey = [
        config.DISCUSSION_TOPICS_URL,
        variables.discussionTopicId,
      ];
      queryClient.invalidateQueries(queryKey);
    },
  });
  return mutation;
};
