import { presentImportModal } from '@/components/ImporterModal';
import PermissionButton from '@/components_v2/ChakraComponents/PermissionButton';
import ExporterContextMenu from '@/components_v2/ExporterContextMenu';
import { useAuth } from '@/hooks/Authentication/auth';
import { taskServices as importService } from '@/modules/shared/adapters/ImporterService';
import { getExporter } from '@/screens/registrations/Tasks/services/getExporter';
import { Button, ButtonGroup, Flex, HStack, Icon } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiDownload, FiPlus } from 'react-icons/fi';

import { useTasks } from '../../../../hooks/tasks';
import Filters from './components/Filters';
import Search from './components/Search';
import Stats from './components/Stats';
import TasksList from './components/TasksList';

const ListView: React.FC = () => {
  const { openTaskForm } = useTasks();
  const { t } = useTranslation();
  const { user } = useAuth();

  const openImportModal = useCallback(() => {
    presentImportModal({
      importService,
    });
  }, []);

  const canCreateTask = user?.permit_create_task;

  return (
    <Flex overflow="hidden" direction="column" w="100%">
      <Flex
        mb={4}
        justifyContent="space-between"
        alignItems="center"
        flexDir="row"
      >
        <Search />
        <HStack>
          <ButtonGroup isAttached>
            <Button
              onClick={openImportModal}
              variant="outline"
              rightIcon={<Icon as={FiDownload} />}
            >
              {t('tasks.import_tasks')}
            </Button>
            <ExporterContextMenu
              getExporter={getExporter}
              options={{ only_public_events: false }}
              button={
                <Button variant="outline" rightIcon={<Icon as={FiDownload} />}>
                  {t('tasks.export_tasks')}
                </Button>
              }
              screenName="tasks"
            />

            <ExporterContextMenu
              getExporter={getExporter}
              options={{ only_public_events: false }}
              button={
                <Button variant="outline" rightIcon={<Icon as={FiDownload} />}>
                  {t('tasks.export_answers')}
                </Button>
              }
              screenName="task_answers"
            />
          </ButtonGroup>

          <PermissionButton
            rightIcon={<Icon as={FiPlus} />}
            colorScheme="brand"
            onClick={() => openTaskForm()}
            externalPermission={canCreateTask}
          >
            {t('tasks.screen_header.new_task')}
          </PermissionButton>
        </HStack>
      </Flex>
      <Stats />
      <Filters />
      <TasksList />
    </Flex>
  );
};

export default ListView;
