import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import { ComponentStyleConfig } from '@chakra-ui/react';
import {
  mode,
  PartsStyleFunction,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools';

const roundedStyleItem: SystemStyleFunction = (props) => {
  return {
    py: '0.4rem',
    px: '0.8rem',
    mx: '2',
    width: 'calc(100% - 14px)',
    rounded: 'lg',
    _focus: {
      bg: mode('gray.100', 'whiteAlpha.100')(props),
    },
    _active: {
      bg: mode('gray.200', 'whiteAlpha.200')(props),
    },
    _expanded: {
      bg: mode('gray.100', 'whiteAlpha.100')(props),
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  };
};

const roundedStyleList: SystemStyleFunction = (props) => {
  return {
    bg: mode('#fff', 'gray.700')(props),
    boxShadow: mode('sm', 'dark-lg')(props),
    color: 'inherit',
    minW: '3xs',
    py: '2',
    zIndex: 1,
    borderRadius: 'xl',
    borderWidth: '1px',
  };
};

const roundedStyle: PartsStyleFunction<typeof parts> = (props) => ({
  ...props,
  item: roundedStyleItem(props),
  list: roundedStyleList(props),
});

export default {
  variants: {
    rounded: roundedStyle,
  },
} as ComponentStyleConfig;
