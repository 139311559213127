import styled from 'styled-components';
import {
  FiTrash2,
  FiChevronRight,
  FiX,
  FiCopy,
  FiGitBranch,
  FiFileText,
  FiEdit,
  FiHelpCircle,
  FiCheckSquare,
  FiMoreHorizontal,
  FiAlignLeft,
  FiArrowUp,
  FiArrowDown,
  FiFilter,
  FiCheck,
  FiDownload,
  FiSearch,
  FiSquare,
  FiCheckCircle,
  FiCircle,
  FiUpload,
  FiPhone,
  FiUsers,
  FiAtSign,
  FiLink2,
  FiTag,
  FiChevronDown,
  FiUser,
} from 'react-icons/fi';

export const IconTag = styled(FiTag)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconLink = styled(FiLink2)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconAtSign = styled(FiAtSign)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconUsers = styled(FiUsers)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const UserIcon = styled(FiUser)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconPhone = styled(FiPhone)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconExport = styled(FiUpload)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconRadio = styled(FiCircle)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconCheckedRadio = styled(FiCheckCircle)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconDelete = styled(FiTrash2)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconX = styled(FiX)`
  width: 9px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  margin-left: 4px;
  backface-visibility: hidden;
`;

export const IconArrow = styled(FiChevronRight)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconCheck = styled(FiCheck)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconTrigger = styled(FiGitBranch)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconCopy = styled(FiCopy)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconCheckedCheckbox = styled(FiCheckSquare)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconCheckbox = styled(FiSquare)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconSearch = styled(FiSearch)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconForm = styled(FiFileText)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const EditIcon = styled(FiEdit)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconQuestion = styled(FiHelpCircle)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconText = styled(FiAlignLeft)`
  width: 17px;
  display: block;
  color: ${(props) => props.theme.colors.darkText};
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconUp = styled(FiArrowUp)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconDown = styled(FiArrowDown)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconFilter = styled(FiFilter)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconChevronRight = styled(FiChevronRight)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconOption = styled(FiCheckSquare)`
  width: 17px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconOptions = styled(FiMoreHorizontal)`
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
  cursor: pointer;
`;

export const IconImport = styled(FiDownload)`
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
  cursor: pointer;
`;

export const IconChevronDown = styled(FiChevronDown)`
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
  font-size: 18px;
  cursor: pointer;
`;
