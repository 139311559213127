/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-nested-ternary */

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  chakra,
  Checkbox,
  Skeleton,
  SkeletonText,
  Table,
  TableColumnHeaderProps,
  TableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useControllableState,
} from '@chakra-ui/react';
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Header,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  withSelect?: boolean;
  tableProps?: TableProps;
  headerProps?: TableColumnHeaderProps;
  defaultRowSelection?: RowSelectionState;
  rowSelection?: RowSelectionState;
  isLoading?: boolean;
  renderLoading?: () => JSX.Element | JSX.Element[];
  onLoadMore?: () => void;
  setRowSelection?: (state: RowSelectionState) => void;
};

export function DataTable<Data extends object>({
  data = [],
  onLoadMore,
  columns,
  tableProps,
  withSelect,
  headerProps,
  isLoading,
  renderLoading,
  ...rest
}: DataTableProps<Data>) {
  const columnHelper = React.useRef(createColumnHelper<Data>()).current;

  const [rowSelection, setRowSelection] = useControllableState({
    defaultValue: rest.defaultRowSelection || {},
    onChange: rest.setRowSelection,
    value: rest.rowSelection,
  });

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columnsWithCheckbox = React.useMemo(() => {
    if (!withSelect) return columns;

    return [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => {
          const checked = table.getIsAllRowsSelected();
          const indeterminate = table.getIsSomeRowsSelected();
          const onChange = table.getToggleAllRowsSelectedHandler();

          return (
            <Checkbox
              size="lg"
              isChecked={checked || indeterminate}
              isIndeterminate={indeterminate}
              onChange={onChange}
            />
          );
        },
        cell: ({ row }) => {
          const checked = row.getIsSelected();
          const indeterminate = row.getIsSomeSelected();
          const onChange = row.getToggleSelectedHandler();

          return (
            <Checkbox
              size="lg"
              isChecked={checked}
              isIndeterminate={indeterminate}
              onChange={onChange}
            />
          );
        },
      }),
      ...columns,
    ] as ColumnDef<Data, any>[];
  }, [columnHelper, columns, withSelect]);

  const table = useReactTable({
    columns: columnsWithCheckbox,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      rowSelection,
    },
  });

  const renderHeader = React.useCallback(
    (header: Header<Data, unknown>) => {
      const { meta } = header.column.columnDef;
      return (
        <Th
          key={header.id}
          onClick={header.column.getToggleSortingHandler()}
          isNumeric={(meta as any)?.isNumeric}
          userSelect="none"
          borderRightWidth="thin"
          fontWeight={700}
          whiteSpace="nowrap"
          width={header.id === 'select' ? '0' : header.getSize()}
          {...headerProps}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}

          <chakra.span>
            {header.column.getIsSorted() ? (
              header.column.getIsSorted() === 'desc' ? (
                <TriangleDownIcon aria-label="sorted descending" />
              ) : (
                <TriangleUpIcon aria-label="sorted ascending" />
              )
            ) : null}
          </chakra.span>
        </Th>
      );
    },
    [headerProps],
  );

  const renderRow = React.useCallback((row: Row<Data>) => {
    return (
      <Tr
        transitionDuration="var(--chakra-transition-duration-normal)"
        transitionProperty="background"
        key={row.id}
      >
        {row.getVisibleCells().map((cell) => {
          const { meta } = cell.column.columnDef;
          return (
            <Td key={cell.id} isNumeric={(meta as any)?.isNumeric}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
      </Tr>
    );
  }, []);

  const _renderLoading = React.useCallback(() => {
    if (renderLoading) return renderLoading();

    const skeletonItems = new Array(9).fill(null);
    return skeletonItems.map((e, idx) => (
      <Tr key={idx}>
        <Td>
          <Skeleton h="6" w="6" />
        </Td>
        <Td>
          <Skeleton h="6" w="36" />
        </Td>

        {columns.map((e, i) => (
          <Td key={`${e.header}-${i}`}>
            <SkeletonText noOfLines={2} spacing="2" />
          </Td>
        ))}
      </Tr>
    ));
  }, [columns, renderLoading]);

  return (
    <Table {...tableProps}>
      <Thead borderBottomWidth="thin" position="sticky" top={0} zIndex="1">
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>{headerGroup.headers.map(renderHeader)}</Tr>
        ))}
      </Thead>
      {isLoading ? (
        <Tbody position="relative">{_renderLoading()}</Tbody>
      ) : (
        <Tbody>{table.getRowModel().rows.map(renderRow)}</Tbody>
      )}
    </Table>
  );
}
