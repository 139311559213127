import React from 'react';

import { Component, VerticalStepsProps } from './interfaces';
import {
  Container,
  ChildrenContainer,
  StepContainer,
  NumberBoll,
  BollTitle,
  LineContainer,
  Line,
  Title,
  Content,
} from './styles';

const renderChildrens = (
  component: Component,
  index: number,
  array: Component[],
) => {
  const {
    title,
    children,
    stepBackgroundColor,
    stepTextColor,
    hasNumber,
  } = component;
  return (
    <ChildrenContainer key={index}>
      <StepContainer>
        <NumberBoll bollColor={stepBackgroundColor}>
          {hasNumber && (
            <BollTitle bollColor={stepTextColor}>{index + 1}</BollTitle>
          )}
        </NumberBoll>
        {index < array.length - 1 ? (
          <LineContainer>
            <Line />
          </LineContainer>
        ) : null}
      </StepContainer>
      <Content>
        <Title>{title}</Title>
        {children}
      </Content>
    </ChildrenContainer>
  );
};

const VerticalSteps: React.FC<VerticalStepsProps> = ({
  components,
  ...props
}) => {
  return <Container {...props}>{components.map(renderChildrens)}</Container>;
};

export default VerticalSteps;
