import React from 'react';

import { Flex, Heading, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { RiSurveyFill } from 'react-icons/ri';
import useFormDetails from '../hooks/useFormDetails';

const MobileNav: React.FC = () => {
  const { formulary } = useFormDetails();
  return (
    <>
      <Flex
        position="sticky"
        top="0"
        zIndex="overlay"
        height={{ base: '12', sm: '16' }}
        shadow="md"
        bg="white"
        w="full"
        p="5"
        alignItems="center"
        justifyContent="flex-start"
      >
        {formulary?.name && (
          <HStack>
            <Icon as={() => <RiSurveyFill size={30} />} />
            <VStack alignItems="start" spacing="0">
              <Heading size="xs"> {formulary?.name}</Heading>
              <Text fontSize="xs" color="gray.400">
                Salvo 1 minuto atrás
              </Text>
            </VStack>
          </HStack>
        )}
      </Flex>
    </>
  );
};

export default MobileNav;
