import PopupButton from '@/components_v2/BoardFilters/components/PopupButton';
import { FilterIcon } from '@/components_v2/BoardHeader/Common/styles';
import FiltersListContextMenu from '@/components_v2/FiltersListContextMenu';
import { useTasks } from '@/screens/registrations/Tasks/Dashboard/hooks/tasks';
import {
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiSearch } from 'react-icons/bi';
import { FiFilter } from 'react-icons/fi';

const Search: React.FC = () => {
  const { onSearch } = useTasks();
  const { t } = useTranslation();

  return (
    <HStack gap="4">
      <InputGroup maxWidth={80}>
        <Input
          _focus={{ _focus: 'none' }}
          defaultValue=""
          onChange={(e) => onSearch(e.target.value)}
          placeholder={t('common.search')}
        />
        <InputRightElement>
          <BiSearch color="#E2E8F0" />
        </InputRightElement>
      </InputGroup>
      <PopupButton
        text={t('common.filters')}
        type="GREY_BACKGROUND"
        callback={() => null}
        manualIcon={<FilterIcon />}
        // isExpansible
        customTrigger={
          <Button size="md" leftIcon={<Icon as={FiFilter} />}>
            {t('filter.filters')}
          </Button>
        }
      >
        <FiltersListContextMenu />
      </PopupButton>
    </HStack>
  );
};

export default Search;
