import { FieldProps, registerFieldType } from '@/components_v2/HookForm';
import GeolocationInput from '@/components_v2/inputs/GeolocationInput';
import { Coordinates } from '@/components_v2/inputs/GeolocationInput/GeolocationInput.types';
import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';

type LocationInputProps = FieldProps & { value?: string };

export const LocationInput: React.FC<LocationInputProps> = (props) => {
  const { onChange = () => null, value } = props;

  const [address, setAddress] = React.useState<string | undefined>(undefined);

  const parsedValue = React.useMemo<Coordinates | undefined>(() => {
    if (value && !Array.isArray(value) && typeof value === 'string') {
      return JSON.parse(value);
    }
    return undefined;
  }, [value]);

  const handleChange = React.useCallback(
    (newValue?: Coordinates, newAddress?: string) => {
      if (newValue) {
        onChange(JSON.stringify(newValue) as any);
      } else {
        onChange(undefined as any);
      }
      setAddress(newAddress);
    },
    [onChange],
  );

  const text = React.useMemo(() => {
    if (address) return address;
    if (value)
      return `Latitude: ${parsedValue?.latitude.toFixed(
        4,
      )} Longitude: ${parsedValue?.longitude.toFixed(4)}`;
    return 'Selecione um endereço';
  }, [address, parsedValue?.latitude, parsedValue?.longitude, value]);

  return (
    <HStack>
      <Box
        display="flex"
        alignItems="center"
        h="10"
        w="full"
        rounded="md"
        borderWidth="thin"
        px="4"
      >
        <Text noOfLines={1}>{text}</Text>
      </Box>
      <GeolocationInput
        initialValue={parsedValue}
        initialAddress={address}
        onChange={handleChange}
      />
    </HStack>
  );
};

registerFieldType('geolocation', LocationInput, {
  isControlled: true,
});
