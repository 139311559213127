import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 14px;
`;

export const SubtitleContainer = styled.div`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-weight: 300;
  margin-bottom: 10px;
  font-size: 12px;
  flex-wrap: wrap;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  button {
    margin: 0px 10px;
  }
`;
