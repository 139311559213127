import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useTaskTabsNavigation() {
  const { push } = useHistory();
  const { search } = useLocation();

  const queryParams = React.useMemo(() => new URLSearchParams(search), [
    search,
  ]);

  const setTab = (tab: string) =>
    push({
      search: `?tab=${tab}`,
    });
  return {
    setTab,
    tab: queryParams.get('tab'),
  };
}
