import BaseImporterService from '@/modules/shared/data/usecases/BaseImporterService';
import ModalStack from '@/utils/ModalStack';
import Importer from '@components/Importer';
import React from 'react';

import { ImportTypeEnum } from './interfaces';
import { ModalStyles } from './styles';
import { getApiServices } from './utils';

const presentImportModal = (props: {
  importService?: BaseImporterService;
  importType?: ImportTypeEnum;
  importId?: string;
  extraParams?: Record<string, unknown>;
}): void => {
  const { importService, importType, importId, extraParams } = props;
  const service = importType ? getApiServices(importType) : importService;
  const params = {
    children: service && (
      <Importer
        extraParams={extraParams}
        apiServices={service}
        importId={importId}
      />
    ),
    key: 'importer-modal',
    onClose: () => null,
    modalStyles: ModalStyles,
  };

  ModalStack.push(params);
};

export { presentImportModal };
