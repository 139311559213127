import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  Flex,
  HStack,
  Icon,
  Link,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { FaLocationArrow } from 'react-icons/fa';

type TableCellRenderProps =
  | {
      type: 'numeric';
      content: number | null;
      mode?: 'integer' | 'float';
    }
  | { type: 'date'; content: Date | null }
  | {
      type: 'default';
      content: string;
    }
  | {
      type: 'button';
      content: string;
      buttonProps?: ButtonProps;
      onClick?: () => void;
    }
  | {
      type: 'user';
      content: string;
      src?: string;
      userId?: string;
      onClick?: () => void;
    }
  | {
      type: 'local';
      content: string;
      address?: string | null;
      onClick?: () => void;
    };

export function DefaultCell({
  content,
}: Extract<TableCellRenderProps, { type: 'default' }>) {
  return (
    <Box>
      <Text fontWeight="semibold">{content}</Text>
    </Box>
  );
}

export function DateCell(
  props: Extract<TableCellRenderProps, { type: 'date' }>,
) {
  const { content } = props;
  return <Box>{moment(content).format('LLLL')}</Box>;
}

export function NumericCell(
  props: Extract<TableCellRenderProps, { type: 'numeric' }>,
) {
  const { content, mode = 'integer' } = props;

  const parsedContent = React.useMemo(() => {
    const parsed = Number(content);
    if (Number.isNaN(parsed)) return 0;

    if (mode === 'integer') {
      return Number(content).toFixed(0);
    }
    return Number(content).toFixed(2);
  }, [content, mode]);
  return <DefaultCell type="default" content={`${parsedContent}`} />;
}

export function UserCell(
  props: Extract<TableCellRenderProps, { type: 'user' }>,
) {
  const { content, userId, onClick, src } = props;

  return (
    <Flex w="full" h="full">
      <HStack onClick={onClick}>
        <Avatar size="xs" src={src} name={content} />
        <Text
          color="mutedText"
          noOfLines={1}
          wordBreak="break-all"
          fontSize="sm"
          fontWeight="bold"
        >
          {content}
        </Text>
      </HStack>
    </Flex>
  );
}

export function ButtonCell(
  props: Extract<TableCellRenderProps, { type: 'button' }>,
) {
  const { content, buttonProps, onClick } = props;

  return (
    <Flex alignItems="center" justifyContent="center" w="full" h="full">
      <Button {...buttonProps} onClick={onClick}>
        {content}
      </Button>
    </Flex>
  );
}

export function LocalCell(
  props: Extract<TableCellRenderProps, { type: 'local' }>,
) {
  const { content, address } = props;

  return (
    <Link
      variant="unstyled"
      isExternal
      href={`https://maps.google.com/?q=${address || content}`}
    >
      <Flex alignItems="center" gap="2">
        <Icon as={FaLocationArrow} color="blue.600" />
        <Text color="mutedText">{content}</Text>
      </Flex>
    </Link>
  );
}

export function TableCell(props: TableCellRenderProps) {
  switch (props.type) {
    case 'user':
      return <UserCell {...props} />;
    case 'default':
      return <DefaultCell {...props} />;
    case 'button':
      return <ButtonCell {...props} />;
    case 'date':
      return <DateCell {...props} />;
    case 'numeric':
      return <NumericCell {...props} />;
    case 'local':
      return <LocalCell {...props} />;

    default:
      return <DefaultCell {...(props as any)} />;
  }
}
