import {
  DisplayIf,
  Field,
  FormLayout,
  HookForm,
  SubmitButton,
} from '@/components_v2/HookForm';
import {
  Button,
  chakra,
  Divider,
  Flex,
  Heading,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Task } from '../../../interfaces';
import {
  ActiveSwitch,
  DateInputs,
  FormularySelect,
  LocalSelect,
  MemberSelect,
  NameInput,
  ProfileSelect,
  TagsSelect,
  TaskAboutSelect,
} from './taskFormInputs';
import { taskSchema, TaskSchema } from './types';

type TaskFormProps = {
  defaultValues?: Task;
  isLoading?: boolean;
  onSubmit?: (payload: TaskSchema) => void;
  onDismiss?: () => void;
};
export function TaskForm(props: TaskFormProps) {
  const toast = useToast();
  const { defaultValues, isLoading, onDismiss, onSubmit } = props;
  const { t } = useTranslation();

  const formattedDefaultValues:
    | Partial<TaskSchema>
    | undefined = React.useMemo((): Partial<TaskSchema> | undefined => {
    if (!defaultValues) return undefined;

    const {
      active,
      batch_tags,
      name,
      date_end,
      date_start,
      member_id,
      local_id,
      formulary_id,
      formulary_name,
      description,
      user_profile_id,
      origin_type,
      origin_id,
    } = defaultValues;
    return {
      name,
      active,
      associateFormulary: !!formulary_id,
      member: member_id ? { label: '', value: member_id } : undefined,
      date_end: date_end ? new Date(date_end) : undefined,
      date_start: date_start ? new Date(date_start) : undefined,
      description,
      formulary:
        formulary_id && formulary_name
          ? { value: formulary_id, label: formulary_name }
          : undefined,
      local: local_id ? { label: local_id, value: local_id } : undefined,
      tags: batch_tags.map(({ color, id, name }) => ({
        label: name,
        value: id,
        color,
      })),
      postponedStart: !!date_start,
      user_profile_id,
      origin_type: origin_type ? { label: '', value: origin_type } : undefined,
      origin_id: origin_id ? { label: '', value: origin_id } : undefined,
    };
  }, [defaultValues]);

  return (
    <chakra.fieldset
      flex={1}
      overflowY="hidden"
      display="flex"
      disabled={!!isLoading}
    >
      <HookForm<TaskSchema>
        onError={(errors) => {
          toast({
            title: 'Erro',
            status: 'error',
            position: 'bottom-left',
            description: Object.values(errors)
              .map((e) => e.message)
              .join(', \n'),
          });
        }}
        onSubmit={(values) => onSubmit?.(values)}
        resolver={yupResolver(taskSchema)}
        defaultValues={formattedDefaultValues}
        display="flex"
        maxH="100%"
        overflowY="hidden"
        flexDir="column"
        w="full"
        justifyContent="space-between"
      >
        <Flex overflowY="auto" borderTopWidth="thin" p="10" flex="1">
          <FormLayout w="full" spacing={8}>
            <FormLayout>
              <Heading size="md">{t('tasks.form.general_datails')}</Heading>
              <NameInput />

              <Field<TaskSchema>
                type="textarea"
                name="description"
                label={t('tasks.form.description')}
              />

              <TaskAboutSelect />

              <DisplayIf
                name="origin_type"
                condition={(origin_type) =>
                  (origin_type as { value?: string })?.value === 'User'
                }
              >
                <MemberSelect schemaName="origin_id" />
              </DisplayIf>
            </FormLayout>

            <Divider />

            <FormLayout>
              <Heading size="md">{t('tasks.form.task_checklist')}</Heading>
              <FormularySelect />
            </FormLayout>

            <Divider />

            <FormLayout>
              <Heading size="md">{t('tasks.form.responsable_and_profile')}</Heading>
              <MemberSelect schemaName="member" />
              <ProfileSelect />
            </FormLayout>
            <Divider />
            <FormLayout>
              <Heading size="md">{t('tasks.form.date_and_hour')}</Heading>
              <DateInputs />
            </FormLayout>

            <Divider />

            <FormLayout>
              <Heading size="md">{t('tasks.form.execution_and_tags')}</Heading>
              <LocalSelect />

              <FormLayout mb="20">
                <TagsSelect />
              </FormLayout>
            </FormLayout>
          </FormLayout>
        </Flex>
        <Flex borderTopWidth="thin" p="6">
          <HStack w="full">
            <ActiveSwitch />

            <Button
              colorScheme="red"
              size="md"
              variant="outline"
              onClick={onDismiss}
            >
              {t('common.cancel')}
            </Button>
            <SubmitButton
              isLoading={isLoading}
              size="md"
              colorScheme="primary"
              translate="no"
            >
              {t('common.save')}
            </SubmitButton>
          </HStack>
        </Flex>
      </HookForm>
    </chakra.fieldset>
  );
}
