import { ScorePayload } from './interfaces';

export const getScoreColor = (score: number) => {
  if (score >= 80) return 'green';
  if (score >= 50) return 'orange';
  if (score >= 25) return 'red';
  return 'gray';
};

export const getScore = (params: Partial<ScorePayload>) => {
  const { scoreObtained, totalScore } = params;
  if (totalScore) {
    const obtained = scoreObtained || 0;

    return (obtained / totalScore) * 100;
  }
  return 0;
};

export const QUESTION_FILTERS = [
  {
    key: 'all',
    label: 'Todas',
  },
  {
    key: 'image',
    label: 'Imagens',
  },
  {
    key: 'flagged',
    label: 'Inconformes',
  },
  {
    key: 'feedbacks',
    label: 'Feedbacks',
  },
];
