import styled from 'styled-components';
import { INPUT_HEIGHT } from '../constants';

export const InputContainer = styled.div`
  height: ${INPUT_HEIGHT}px;
  padding: 4px 8px;
  margin-bottom: 5px;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  color: ${(props) => props.theme.colors.mediumText};
  font-weight: 400;
  font-size: 12px;
  margin-right: 10px;

  width: 100%;

  outline: none;

  font-family: ${(props) => props.theme.fonts.primaryFont};

  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin-right: 5px;
`;
