import React from 'react';

type ControllableStateProps<T = any> = {
  value?: T;
  onChange?: (value: T) => void;
  defaultValue?: T;
};
/** allows component to switch between controlled and uncontrolled state management */
export function useControllableState<T>(props: ControllableStateProps<T> = {}) {
  const {
    value: valueFromProps,
    onChange: onChangeFromProps,
    defaultValue: defaultValueFromProps,
  } = props;

  const isControlled = React.useMemo(
    () => typeof valueFromProps != 'undefined',
    [valueFromProps],
  );

  const hasDefaultValue = React.useMemo(
    () => typeof defaultValueFromProps != 'undefined',
    [defaultValueFromProps],
  );

  const [state, setState] = React.useState(
    hasDefaultValue ? defaultValueFromProps : undefined,
  );

  const value = React.useMemo(
    () => (isControlled ? valueFromProps : state),
    [isControlled, valueFromProps, state],
  );

  const valueRef = React.useRef(value);

  valueRef.current = value;

  const setValue = React.useCallback(
    (
      valueOrFunction:
        | unknown
        | undefined
        | ((currentState: T | undefined) => T | undefined),
    ) => {
      let nextValue;

      if (typeof valueOrFunction === 'function') {
        nextValue = valueOrFunction(valueRef.current);
      } else {
        nextValue = valueOrFunction;
      }

      if (onChangeFromProps) {
        onChangeFromProps(nextValue);
      }

      if (!isControlled) {
        setState(nextValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeFromProps],
  );

  return [value, setValue] as const;
}
