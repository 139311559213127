import StatusTag from '@/screens/monitoring/Checklists/components/Board/components/StatusTag';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { BiImage } from 'react-icons/bi';

import { useAnswerSummary } from '../../hooks/useAnswerSummary';

export const Header: React.FC = () => {
  const { answer, isLoading } = useAnswerSummary();
  const toast = useToast();

  const parsedFinishedAt = React.useMemo(
    () => (answer?.finishedAt ? new Date(answer?.finishedAt) : null),
    [answer?.finishedAt],
  );

  const downloadImage = () => {
    if (!answer?.content) {
      toast({
        title: 'Erro ao abrir imagem',
        description: 'URL da imagem não encontrada.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const link = document.createElement('a');
    link.href = `${answer.content}`;
    link.download = `${answer.id}.jpg`;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      w="full"
      minH={{ base: '32', lg: '24' }}
      bg="white"
      borderBottomWidth="thin"
      alignItems="center"
      justifyContent="flex-start"
      display="flex"
      boxShadow="sm"
      gap="4"
    >
      <Flex
        alignItems={{ base: 'flex-start', lg: 'center' }}
        p="4"
        flex="1"
        h="full"
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent="space-between"
      >
        <VStack alignItems="flex-start" spacing="2">
          <HStack alignItems="center" ms={{ base: '-2', lg: '0' }}>
            <Avatar
              display={{ base: 'none', lg: 'inherit' }}
              borderWidth="3px"
              borderColor="white"
              boxShadow="lg"
              mt="-1.5"
              name={answer?.authorName}
              translate="no"
            />
            <Box w="full">
              <Heading
                wordBreak="break-word"
                display="inline-block"
                verticalAlign="middle"
                lineHeight="0.9"
                me="2"
                size="lg"
                translate="no"
              >
                {`${answer?.parentableName}`}
              </Heading>

              <Box my="1" display="inline-block">
                <StatusTag status={answer?.status || 'pending'} />
              </Box>
            </Box>
          </HStack>
          <Flex
            h="min-content"
            justifyContent="center"
            flexDirection={{ base: 'column', lg: 'row' }}
            gap={{ base: '4', lg: '2' }}
          >
            <Text fontWeight="bold" color="mutedText" translate="no">
              {`${answer?.authorName} ${
                answer?.userProfileName ? `/ ${answer?.userProfileName}` : ''
              } / ${
                parsedFinishedAt ? moment(parsedFinishedAt).format('LLLL') : ''
              }`}
            </Text>
          </Flex>
        </VStack>

        <ButtonGroup
          mt={{ base: '4', lg: '0' }}
          size="md"
          w={{ base: '91.5%', lg: 'fit-content' }}
        >
          <Button
            flex={{ base: 1, lg: 'unset' }}
            onClick={downloadImage}
            isLoading={isLoading}
            colorScheme="primary"
            leftIcon={<BiImage fontSize="22px" />}
            translate="no"
          >
            Salvar Foto
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
  );
};
