import { Button } from '@chakra-ui/react';
import React from 'react';

import { useChecklistSummary } from '../../useChecklistSummary';

type TaskActionsProps = {
  openChecklist?: () => void;
  disabled?: boolean;
};

const TaskActions: React.FC<TaskActionsProps> = ({ disabled = false }) => {
  const { data, isLoading, handleFinish } = useChecklistSummary();

  switch (data.status) {
    case 'pending':
      return (
        <Button
          isLoading={isLoading}
          disabled={disabled}
          colorScheme="green"
          w="full"
          onClick={handleFinish}
        >
          Marcar como completa
        </Button>
      );
    default:
      return null;
  }
};

export default TaskActions;
