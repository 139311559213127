import { ItemAttachment } from '@/modules/core/domain/interfaces/ItemAttachment';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import camelToSnake from '@/utils/camelToSnake';

import { QUERY_KEYS } from '../queryKeys';

export type CreateAttachmentParams = Pick<
  ItemAttachment,
  | 'itemId'
  | 'questionId'
  | 'status'
  | 'mimeType'
  | 'localUrl'
  | 'remoteUrl'
  | 'failCount'
  | 'name'
  | 'extension'
  | 'bytes'
>;

export type CreateAttachmentPayload = {
  item: ItemAttachment;
};

export type ICreateAttachment = (
  params: CreateAttachmentParams,
) => Promise<CreateAttachmentPayload>;

export async function createAttachment(
  params: CreateAttachmentParams,
): Promise<CreateAttachmentPayload> {
  const response = await AxiosHelper.post<ItemAttachment>({
    url: QUERY_KEYS.attachments,
    body: camelToSnake(params),
  });

  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new UnexpectedError();
  }

  return {
    item: response.body as ItemAttachment,
  };
}
