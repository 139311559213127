/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-types */

import { chakra, ChakraProps } from '@chakra-ui/react';
import React, { createContext, useContext } from 'react';
import {
  AriaListBoxProps,
  mergeProps,
  OptionAria,
  useFocusRing,
  useListBox,
  useListBoxSection,
  useOption,
  useSeparator,
} from 'react-aria';
import { Item, ListState, Node, useListState } from 'react-stately';

function Root<T extends object>(
  props: AriaListBoxProps<T> & {
    listProps?: ChakraProps;
  },
) {
  const { listProps, ...restProps } = props;
  // Create state based on the incoming props
  const state = useListState(restProps);

  // Get props for the listbox element
  const ref = React.useRef(null);
  const { listBoxProps } = useListBox(restProps, state, ref);

  return (
    <chakra.ul
      {...listBoxProps}
      ref={ref}
      {...listProps}
      w="full"
      listStyleType="none"
    >
      {[...state.collection].map((item) =>
        item.type === 'section' ? (
          <ListBoxSection key={item.key} section={item} state={state} />
        ) : (
          <Option key={item.key} item={item} state={state} />
        ),
      )}
    </chakra.ul>
  );
}

const ItemContext = createContext<OptionAria>({} as any);

export function useItem() {
  const context = useContext(ItemContext);
  return context;
}

function Option<T>({ item, state }: { item: Node<T>; state: ListState<T> }) {
  // Get props for the option element
  const ref = React.useRef<HTMLLIElement>(null);
  const optionValues = useOption({ key: item.key }, state, ref);

  // Determine whether we should show a keyboard
  // focus ring for accessibility
  const { focusProps } = useFocusRing();

  const hoverStyles = {
    bg: 'blackAlpha.100',
  };

  return (
    <ItemContext.Provider value={optionValues}>
      <chakra.li
        pointerEvents={optionValues.isDisabled ? 'none' : 'unset'}
        {...mergeProps(optionValues.optionProps, focusProps)}
        transition="all 0.2s ease-in-out"
        borderBottomWidth="thin"
        p="2.5"
        fontSize="md"
        _hover={hoverStyles}
        ref={ref}
        w="full"
        userSelect="none"
      >
        {item.rendered}
      </chakra.li>
    </ItemContext.Provider>
  );
}

function ListBoxSection<T>({
  section,
  state,
}: {
  section: Node<T>;
  state: ListState<T>;
}) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  const { separatorProps } = useSeparator({
    elementType: 'li',
  });

  // If the section is not the first, add a separator element.
  // The heading is rendered inside an <li> element, which contains
  // a <ul> with the child items.
  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li {...separatorProps} />
      )}
      <li {...itemProps}>
        {section.rendered && <span {...headingProps}>{section.rendered}</span>}
        <ul {...groupProps}>
          {[...section.childNodes].map((node) => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  );
}

const ListBox = {
  Root,
  ListBoxSection,
  Option,
  Item,
};

export { ListBox };
