import BaseDropdown from '@/components_v2/inputs/BaseDropdown';
import { Option } from '@/components_v2/inputs/BaseDropdown/interfaces';
import BaseInput from '@/components_v2/inputs/BaseInput';
import { FrequencyType } from '@/modules/core/domain/interfaces/IRecurrence';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRecurrence } from '../../hooks/useRecurrence';
import {
  Column,
  IntervalLabel,
  IntervalSelectorContainer,
  RecurrenceSelectorContainer,
} from '../Common/styles';
import WeekdayPicker from '../WeekdayPicker';

const options = [
  {
    label: 'Semanas',
    value: FrequencyType.WEEKLY,
  },
  {
    label: 'Dias',
    value: FrequencyType.DAILY,
  },
  {
    label: 'Meses',
    value: FrequencyType.MONTHLY,
  },
];

const CustomRecurrence: React.FC = () => {
  const { t } = useTranslation();
  const { state, onChangeRule, onChangeInterval, viewOnly } = useRecurrence();
  const { recurrenceRule } = state;

  if (state.recurrencyTemplateType !== 'custom') return null;

  return (
    <Column>
      <RecurrenceSelectorContainer>
        <IntervalSelectorContainer>
          <IntervalLabel>{t('common.every')}</IntervalLabel>
          <BaseInput
            disabled={viewOnly}
            onChange={(e) => onChangeInterval(Number(e.target.value))}
            value={recurrenceRule ? recurrenceRule.ruleInterval : undefined}
            type="number"
          />
        </IntervalSelectorContainer>

        <div style={{ flex: 1, marginLeft: 10 }}>
          <BaseDropdown
            defaultValue={`${
              recurrenceRule
                ? recurrenceRule.typeRecurrent
                : t('common.select_value')
            }`}
            onChange={(op) =>
              !Array.isArray(op) &&
              onChangeRule((op.value as unknown) as FrequencyType)
            }
            options={(options as unknown) as Option[]}
          />
        </div>
      </RecurrenceSelectorContainer>

      {recurrenceRule && recurrenceRule.typeRecurrent === FrequencyType.WEEKLY && (
        <div style={{ marginTop: 20 }}>
          <WeekdayPicker />
        </div>
      )}
    </Column>
  );
};

export default CustomRecurrence;
