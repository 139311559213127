import { Formulary } from '@/modules/core/domain/interfaces/IFormulary';
import BaseApiServices from '@/modules/shared/data/usecases/BaseApiServices';
import { ApiObject } from '@/modules/shared/domain/interfaces';
import getApiServiceUtil from './getApiServiceUtil';
import {
  DefaultRemoteFetcherParams,
  FormSubmitMiddleware,
  IRemoteDefaultValuesFetcher,
  IRemoteTemplateFetcher,
  ISubmitHandler,
  MakeValueType,
} from './interfaces';
import LegacyFormValuesMapper from './LegacyFormValuesMapper';
import postForm from './PostForm';

const makeFormSubmitHandler = (
  middleware?: FormSubmitMiddleware,
): ISubmitHandler => async (params) => {
  const { answers, screen, itemId } = params;
  const apiService: BaseApiServices = getApiServiceUtil(
    screen,
  ) as BaseApiServices;

  const parsed =
    (middleware && middleware(answers)) || LegacyFormValuesMapper(answers);

  const { body, statusCode } = await postForm({
    apiAnswers: parsed,
    apiServices: apiService,
    itemId,
  });

  const success = statusCode >= 200 && statusCode < 300;

  return {
    body,
    success,
  };
};

const defaultTemplateFetcher: IRemoteTemplateFetcher = async (
  params: DefaultRemoteFetcherParams,
) => {
  const { screen, itemId } = params;
  const apiService: BaseApiServices = getApiServiceUtil(screen);
  const result = await apiService.getTemplate(
    itemId
      ? {
          entityId: itemId,
        }
      : undefined,
  );
  return (result as unknown) as Formulary;
};

const defaultValuesFetcher: IRemoteDefaultValuesFetcher = async (params) => {
  const { screen, itemId } = params;
  if (!itemId) throw new Error('No itemId provided!');
  const apiService: BaseApiServices = getApiServiceUtil(screen);
  const apiDefaultValues: ApiObject = await apiService.edit({ id: itemId });

  return apiDefaultValues;
};

export const makeRemoteHandlers = (
  submitMiddleware?: FormSubmitMiddleware,
): MakeValueType => {
  const formSubmitHandler = makeFormSubmitHandler(submitMiddleware);
  return {
    remoteTemplateFetcher: defaultTemplateFetcher,
    remoteDefaultValuesFetcher: defaultValuesFetcher,
    submitHandler: formSubmitHandler,
  };
};

export default {
  makeRemoteHandlers,
  makeFormSubmitHandler,
  defaultTemplateFetcher,
  defaultValuesFetcher,
};
