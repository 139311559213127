import React from 'react';
import { useSignoffContext } from '@/modules/circulabi/hooks/useSignOff';
import {
  chakra,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { parseScore } from '@/modules/circulabi/utils/parseScore';
import debounce from '@/utils/debounce';

export function SafetyStockInput() {
  const { state, dispatch, viewOnly } = useSignoffContext();

  const onChange = React.useRef(
    debounce((val: string) => {
      dispatch({ type: 'set_safety_stock', safetyStock: parseScore(val) });
    }, 500),
  ).current;

  if (!state.stockable) return null;

  return (
    <chakra.div display="flex">
      <FormControl>
        <FormLabel>Estoque de Segurança</FormLabel>

        <NumberInput
          isDisabled={viewOnly}
          min={0}
          onChange={(event) => onChange(event)}
          defaultValue={state.safetyStock?.toString()}
        >
          <NumberInputField />
        </NumberInput>
      </FormControl>
    </chakra.div>
  );
}
