import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IRouteData, RouteTypes } from '@/routes/interfaces';
import Collapsible from '@components/Collapsible';
import {
  Container,
  DropDownItem,
  List,
  SecondaryItem,
  collapsibleContentStyle,
  BackView,
  Content,
} from './styles';

export interface MobileDropDownMenuRenderProps {
  mainSelected: string;
  mainList: IRouteData[];
  setMainSelected: (selected: string) => void;
  isMainMenuOpen: boolean;
  subSelected: string;
  subList: IRouteData[];
  setSubSelected: (selected: string) => void;
}

const MobileDropDownMenu: React.FC<MobileDropDownMenuRenderProps> = ({
  mainList,
  subList,
  isMainMenuOpen,
  mainSelected,
  setMainSelected,
  setSubSelected,
}) => {
  const { t } = useTranslation();

  const renderSubList = useCallback(() => {
    const listItems = subList.map((item) => {
      return (
        <DropDownItem key={item.name} onClick={() => setSubSelected(item.name)}>
          {item.title ? t(item.title) : item.name}
        </DropDownItem>
      );
    });
    return listItems;
  }, [subList, setSubSelected, t]);

  const renderMainList = useCallback(() => {
    const listItems = mainList.map((item) => {
      if (item.type && item.type === RouteTypes.PRIMARY) {
        return (
          <Collapsible
            wrapperStyle={{ hasBorderBottom: false }}
            contentStyle={collapsibleContentStyle}
            key={item.name}
            isOpen={mainSelected === item.name}
            title={item.title ? t(item.title) : item.name}
            onClick={(alreadyOpen) =>
              setMainSelected(alreadyOpen ? '' : item.name)
            }
          >
            {renderSubList()}
          </Collapsible>
        );
      }
      return (
        <SecondaryItem onClick={() => setSubSelected(item.name)}>
          {item.title ? t(item.title) : item.name}
        </SecondaryItem>
      );
    });
    return listItems;
  }, [
    mainList,
    mainSelected,
    setMainSelected,
    setSubSelected,
    renderSubList,
    t,
  ]);

  return (
    <Container isOpen={isMainMenuOpen}>
      <BackView />
      <Content isOpen={isMainMenuOpen}>
        <List>{renderMainList()}</List>
      </Content>
    </Container>
  );
};

export default MobileDropDownMenu;
