/* eslint-disable react/react-in-jsx-scope */
import { getSubdomain } from '@/utils/getSubdomain';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, HStack } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { BiShare } from 'react-icons/bi';
import { FaShare } from 'react-icons/fa';

import { TableCell } from './components/TableCells';
import { ITemplateTasksListType } from './types';

export const templateTasksIndexKey = 'template_tasks';

const columnNames: Partial<Record<keyof ITemplateTasksListType, string>> = {
  name: 'Nome',
  memberName: 'Membro',
  localName: 'Local',
  dueIn: 'SLA (Prazo)',
  formularyName: 'Checklist',
  createdAt: 'Criado em',
  updatedAt: 'Editado em',
  author: 'Criado por',
};

const columnHelper = createColumnHelper<ITemplateTasksListType>();

type GetTableColumnsParams = {
  onDelete?: (id: string) => void;
  onView?: (id: string) => void;
  onShare?: (id: string) => void;
};

export const getTableColumns = (props?: GetTableColumnsParams) => {
  const tableCols: ColumnDef<ITemplateTasksListType, any>[] = [
    columnHelper.accessor('name', {
      header: columnNames.name,
      cell: ({ cell }) =>
        cell.getValue() ? (
          <TableCell type="default" content={cell.getValue()} />
        ) : (
          'Sem Nome'
        ),
    }),
    columnHelper.accessor('formularyName', {
      header: columnNames.formularyName,
      cell: ({ cell }) =>
        cell.getValue() ? (
          <TableCell type="default" content={cell.getValue()} />
        ) : (
          'Sem checklist'
        ),
    }),
    columnHelper.accessor('memberName', {
      header: columnNames.memberName,
      cell: ({ cell }) =>
        cell.getValue() ? (
          <TableCell type="user" content={cell.getValue()} />
        ) : (
          'Sem membro'
        ),
    }),
    columnHelper.accessor('localName', {
      header: columnNames.localName,
      cell: ({ cell }) =>
        cell.getValue() ? (
          <TableCell
            type="local"
            content={cell.getValue()}
            address={cell.row.original.localAddressName}
          />
        ) : (
          'Sem local'
        ),
    }),
    columnHelper.accessor('dueIn', {
      header: columnNames.dueIn,
      cell: ({ cell }) =>
        cell.getValue() ? (
          <TableCell
            type="default"
            content={`${moment.duration(cell.getValue()).humanize()}`}
          />
        ) : (
          'Sem SLA'
        ),
    }),
    columnHelper.accessor('createdAt', {
      header: columnNames.createdAt,
      cell: ({ cell }) =>
        cell.getValue() ? (
          <TableCell type="date" content={new Date(cell.getValue())} />
        ) : (
          '...'
        ),
    }),
    columnHelper.accessor('updatedAt', {
      header: columnNames.updatedAt,
      cell: ({ cell }) =>
        cell.getValue() ? (
          <TableCell type="date" content={new Date(cell.getValue())} />
        ) : (
          '...'
        ),
    }),
    columnHelper.accessor('author', {
      header: columnNames.author,
      cell: ({ cell }) =>
        cell.getValue() ? (
          <TableCell type="user" content={cell.getValue()} />
        ) : (
          '...'
        ),
    }),
    columnHelper.display({
      id: 'actions',
      size: 0,
      cell: ({ row }) => (
        <HStack>
          <Button
            colorScheme="brand"
            onClick={() => props?.onView?.(row.original.id)}
          >
            Visualizar
          </Button>

          <Button
            variant="outline"
            rightIcon={<ExternalLinkIcon />}
            onClick={() => props?.onShare?.(row.original.id)}
          >
            Compartilhar
          </Button>
          <Button
            colorScheme="red"
            onClick={() => props?.onDelete?.(row.original.id)}
          >
            Deletar
          </Button>
        </HStack>
      ),
    }),
  ];
  return tableCols;
};

export const downloadQrCode = (id: string) => {
  const svg = document.getElementById('qrcode');
  if (!svg) throw new Error('No svg!');
  const svgData = new XMLSerializer().serializeToString(svg!);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.onload = function () {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx!.drawImage(img, 0, 0);
    const pngFile = canvas.toDataURL('image/png');

    const downloadLink = document.createElement('a');
    downloadLink.download = `qrcode-${id}`;
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

export const getShareableLink = (id: string, auth?: boolean) => {
  const { protocol, host } = window.location;
  const baseUrl = `${protocol}//${host}`;
  const path = auth ? `/app/task/${id}` : `/external/task/${id}`;
  const url = new URL(path, baseUrl);
  return url;
};
