import { gql } from '@/gql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';

import { queryAutomationTickets } from './useFetchTickets';

export const deleteAutomationFlowMutation = gql(`#graphql
  mutation CloneAutomationTicket($input: TicketsCloneInput!) {
    cloneAutomationTicket(input: $input) {
      automationTicket {
        id
        ...AutomationTicketFragment
      }
      errors
    }
  }
`);

export function useCloneAutomationTicket() {
  const toast = useToast();
  const [mutation, payload] = useMutation(deleteAutomationFlowMutation);

  function cloneTicket({
    id,
    ticketCode,
    notCloneStepIds,
  }: {
    id: string;
    ticketCode?: string;
    notCloneStepIds: string[];
  }) {
    return mutation({
      variables: {
        input: {
          automationTicketId: id,
          notCloneStepIds,
        },
      },
      refetchQueries: [queryAutomationTickets],
      onCompleted(data) {
        if (data.cloneAutomationTicket?.automationTicket?.id) {
          toast({
            title: `Ticket ${ticketCode} clonado`,
            status: 'success',
          });
        } else {
          toast({
            title: `Erro ao clonar ticket ${ticketCode}`,
            status: 'error',
            description: data.cloneAutomationTicket?.errors
              .map((e) => e)
              .join(', '),
          });
        }
      },
      onError(error) {
        toast({
          title: `Erro ao clonar ticket ${ticketCode}`,
          status: 'error',
          description: error.message,
        });
      },
    });
  }
  return [cloneTicket, payload] as const;
}
