import { Box, Image } from '@chakra-ui/react';
import React from 'react';

type SignaturePreviewProps = {
  url: string;
};

const SignaturePreview: React.FC<SignaturePreviewProps> = ({ url }) => {
  return (
    <Box w="full" position="relative">
      <Image objectFit="contain" src={url} />
    </Box>
  );
};

export default SignaturePreview;
