import { InitialValuesMapper } from '../interfaces';

export const mapInitialValues: InitialValuesMapper = (params) => {
  if (!params) return undefined;
  if (Array.isArray(params)) {
    return params.map((el) => ({
      label: el.name,
      value: el.id,
    }));
  }
  return {
    label: params.name,
    value: params.id,
  };
};
