const validatePassword = (password: string): boolean => {
  if (password.length < 6) return false;
  if (password.includes(' ')) return false;
  return (
    /([a-z])/g.test(password) &&
    /([A-Z])/g.test(password) &&
    /([0-9])/g.test(password)
  );
};

const hidePassword = (password: string): string => {
  return password.replace(/./g, '*');
};

export default { validatePassword, hidePassword };
export { validatePassword, hidePassword };
