import TabSelector from '@/components_v2/TabSelector';
import { useAuth } from '@/hooks/Authentication/auth';
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useTasks } from '@/screens/Audit/Dashboard/hooks/tasks';
import { HStack, Icon, Stack, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillPeopleFill, BsFillPersonFill } from 'react-icons/bs';

const Filters: React.FC = () => {
  const direction = useBreakpointValue<'column' | 'row'>({
    base: 'column',
    '2xl': 'row',
  });
  const { checkPermission } = useUserPermissions();
  const { onChangeResponsible } = useTasks();
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <Stack
      direction={direction}
      overflowX="visible"
      mb={4}
      w="100%"
      justifyContent="space-between"
      spacing={4}
    >
      <HStack justifyContent="space-between" w="100%">
        <TabSelector
          variant="ios"
          onChange={(key) => {
            onChangeResponsible(key);
          }}
          initialKey="my_tasks"
          tabs={
            checkPermission('rw') || user?.read_all
              ? [
                  {
                    key: 'my_tasks',
                    label: t('tasks.owner.my_tasks'),
                    icon: <Icon as={BsFillPersonFill} mr={2} />,
                  },
                  {
                    key: 'all_tasks',
                    label: t('tasks.owner.all_tasks'),
                    icon: <Icon as={BsFillPeopleFill} mr={2} />,
                  },
                ]
              : [
                  {
                    key: 'my_tasks',
                    label: t('tasks.owner.my_tasks'),
                    icon: <Icon as={BsFillPersonFill} mr={2} />,
                  },
                ]
          }
        />
      </HStack>
    </Stack>
  );
};

export default Filters;
