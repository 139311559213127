/* eslint-disable @typescript-eslint/no-empty-function, no-case-declarations */
import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useReducer,
} from 'react';

import {
  ActionSheetContextData,
  ActionSheetProps,
  ActionSheetReducer,
  ActionSheetRefProps,
  ActionSheetState,
  OpenActionSheetProps,
} from './interfaces/actionSheet';

const ActionSheetContext = createContext<ActionSheetContextData>(
  {} as ActionSheetContextData,
);

const initialState: ActionSheetState = { stack: [] };

const reducer: ActionSheetReducer = (state, action) => {
  switch (action.type) {
    case 'open':
      if (action.props.key) {
        const index = state.stack.findIndex((item) =>
          item.actionSheetKey
            ? item.actionSheetKey === action.props.key
            : false,
        );
        if (index >= 0) {
          const updatedActionSheet = state.stack[index];
          updatedActionSheet.isOpen = true;

          return {
            stack: [
              ...state.stack.slice(0, index),
              ...state.stack.slice(index + 1),
              updatedActionSheet,
            ],
          };
        }
      }
      const actionSheet: ActionSheetProps = {
        ...action.props,
        isOpen: true,
        actionSheetKey: action.props.key
          ? action.props.key
          : new Date().toString(),
        closeWindow() {
          action.props.onClose && action.props.onClose();
        },
      };
      return { stack: [...state.stack, actionSheet] };
    case 'remove':
      const index = state.stack.findIndex(
        (item) => item.actionSheetKey === action.key,
      );
      if (index >= 0) {
        if (state.stack[index].destroyOnClose) {
          return {
            stack: [
              ...state.stack.slice(0, index),
              ...state.stack.slice(index + 1),
            ],
          };
        }

        const updatedActionSheet = state.stack[index];
        updatedActionSheet.isOpen = false;

        return {
          stack: [
            ...state.stack.slice(0, index),
            ...state.stack.slice(index + 1),
            updatedActionSheet,
          ],
        };
      }
      return state;
    case 'destroy':
      const indexDestroy = state.stack.findIndex(
        (item) => item.actionSheetKey === action.key,
      );
      if (indexDestroy >= 0) {
        return {
          stack: [
            ...state.stack.slice(0, indexDestroy),
            ...state.stack.slice(indexDestroy + 1),
          ],
        };
      }
      return state;
    case 'pop':
      const newState = { ...state };
      newState.stack.pop();
      return newState;
    default:
      return state;
  }
};

const ActionSheetProvider: React.ForwardRefRenderFunction<
  ActionSheetRefProps,
  { children: React.ReactNode }
> = ({ children }, selfRef) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openActionSheet = useCallback((data: OpenActionSheetProps) => {
    dispatch({ type: 'open', props: data });
  }, []);

  const closeActionSheet = useCallback((key: string) => {
    dispatch({ type: 'remove', key });
  }, []);

  const destroyActionSheet = useCallback((key: string) => {
    dispatch({ type: 'destroy', key });
  }, []);

  const popActionSheet = useCallback(() => {
    dispatch({ type: 'pop' });
  }, []);

  useImperativeHandle(
    selfRef,
    () => ({
      openActionSheet,
      closeActionSheet,
      destroyActionSheet,
      popActionSheet,
    }),
    [openActionSheet, closeActionSheet, destroyActionSheet, popActionSheet],
  );

  return (
    <ActionSheetContext.Provider
      value={{
        popActionSheet,
        openActionSheet,
        closeActionSheet,
        destroyActionSheet,
        state,
      }}
    >
      {children}
    </ActionSheetContext.Provider>
  );
};

export function useActionSheet(): ActionSheetContextData {
  const context = useContext(ActionSheetContext);

  if (!context) {
    throw new Error(
      'useActionSheet must be used whithin an ActionSheetProvider',
    );
  }

  return context;
}

export default forwardRef(ActionSheetProvider);
