import { DataTable } from '@/components_v2/DataTable';
import ExporterContextMenu from '@/components_v2/ExporterContextMenu';
import { useItemDownloadedState } from '@/components_v2/PDFDownloadButton';
import { useWorkspace } from '@/hooks/workspace';
import { useTasks } from '@/screens/Audit/Dashboard/hooks/tasks';
import { Task } from '@/screens/Audit/interfaces';
import { getExporter } from '@/screens/monitoring/EventReports/data/getExporter';
import { getContrastColor } from '@/utils/colors/getContrastColor';
import { CheckIcon, DownloadIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Icon,
  Link,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { FaLocationArrow } from 'react-icons/fa';

import TasksListNoItems from './TasksList.NoItems';

function TaskPDFButton({
  eventId,
  namespace,
  isEnabled,
}: {
  eventId?: string;
  namespace: string;
  isEnabled?: boolean;
}) {
  const { t } = useTranslation();
  const { hasDownloaded } = useItemDownloadedState(eventId || '', namespace);

  const label = hasDownloaded
    ? t('event_reports.downloaded_pdf')
    : t('event_reports.download_pdf');
  const icon = hasDownloaded ? <CheckIcon /> : <DownloadIcon />;

  return (
    <ExporterContextMenu
      formButtonLabel="PDF"
      placement="bottom-start"
      hasPortal={false}
      getExporter={getExporter()}
      options={{
        objectId: eventId || '',
      }}
      button={
        <Button
          disabled={!isEnabled}
          colorScheme={hasDownloaded ? 'green' : 'gray'}
          rightIcon={icon}
        >
          <Tooltip label={t('event_reports.download_pdf_description')}>
            {label}
          </Tooltip>
        </Button>
      }
      screenName="events"
      screen="audit"
    />
  );
}

const getColumns = ({ translate }: { translate: TFunction<'translation'> }) => {
  const { workspaceData } = useWorkspace();

  const isSesrj = workspaceData.name === 'SESRJ';

  const columns: ColumnDef<Task, any>[] = [
    {
      accessorKey: 'name',
      header: translate('tasks.header.name'),
      maxSize: 100,
      cell({ getValue, row }) {
        return (
          <VStack overflow="clip" w="250px" alignItems="flex-start">
            <Text noOfLines={2}>{getValue()}</Text>

            <HStack overflow="hidden">
              <Avatar
                src={row.original.member_avatar}
                size="xs"
                name={row.original.member}
              />
              <Text
                overflow="hidden"
                width="100%"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {row.original.member || translate('common.no_member')}
              </Text>
            </HStack>
          </VStack>
        );
      },
    },
    {
      header: translate('tasks.header.active'),
      accessorKey: 'active',
      cell({ getValue }) {
        const active = getValue<Task['active']>();

        return (
          <Badge variant="subtle" colorScheme={active ? 'green' : 'red'}>
            {translate(`common.${active ? 'active' : 'inactive'}`)}
          </Badge>
        );
      },
    },
    {
      accessorKey: 'formulary_name',
      header: translate('tasks.header.formulary_name'),
      cell({ getValue }) {
        const formulary_name = getValue<Task['formulary_name']>();
        return (
          <>
            {!!formulary_name && (
              <Text textAlign="center">{formulary_name}</Text>
            )}
          </>
        );
      },
    },
    {
      accessorKey: 'date_start',
      header: translate('tasks.header.date_start'),
      cell({ getValue }) {
        const date_start = getValue<Task['date_start']>();

        return (
          <Flex>
            <Text fontWeight="500">
              {date_start ? moment(date_start).format('DD/MM/YYYY') : '-'}
            </Text>
          </Flex>
        );
      },
    },
    {
      accessorKey: 'date_end',
      header: translate('tasks.header.deadline'),
      cell({ getValue }) {
        const date_end = getValue<Task['date_end']>();

        return (
          <Text textAlign="center">
            {!!date_end && (
              <Text fontWeight="500">
                {moment(date_end).format('DD/MM/YYYY')}
              </Text>
            )}
          </Text>
        );
      },
    },
    {
      accessorKey: 'finished_at',
      header: translate('tasks.header.finished_at'),
      cell({ getValue }) {
        const finished_at = getValue<Task['finished_at']>();
        return (
          <Text textAlign="center">
            {finished_at
              ? moment(finished_at).format('DD/MM/YYYY hh:mm a')
              : '-'}
          </Text>
        );
      },
    },
    {
      accessorKey: isSesrj ? 'unity' : 'local',
      header: translate('tasks.header.local_name'),
      cell({ getValue }) {
        const local = getValue<Task['local']>();
        const unity = getValue<Task['unity']>();

        return (
          <>
            {!isSesrj && !!local?.name && (
              <Link
                pointerEvents={local?.address_name ? 'inherit' : 'none'}
                variant="unstyled"
                isExternal
                href={`https://maps.google.com/?q=${local?.address_name}`}
              >
                <Flex alignItems="center" gap="2">
                  <Icon
                    as={FaLocationArrow}
                    color={local?.address_name ? 'blue.500' : 'gray'}
                  />
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    textAlign="center"
                    color="mutedText"
                  >
                    {local.name}
                  </Text>
                </Flex>
              </Link>
            )}

            {isSesrj && !!unity && (
              <Text fontSize="sm" fontWeight="500" textAlign="center">
                {unity}
              </Text>
            )}
          </>
        );
      },
    },

    {
      accessorKey: 'batch_tags',
      header: translate('tasks.header.tags'),
      cell({ getValue }) {
        const batch_tags = getValue<Task['batch_tags']>();

        if (!batch_tags.length) {
          return <p>-</p>;
        }

        return (
          <HStack>
            {batch_tags[0] && (
              <Badge
                color={getContrastColor(batch_tags[0].color)}
                bg={batch_tags[0].color}
              >
                {batch_tags[0].name}
              </Badge>
            )}
            {batch_tags.length > 1 && (
              <Text whiteSpace="nowrap">+{batch_tags.length - 1}</Text>
            )}
          </HStack>
        );
      },
    },
    {
      id: 'actions',
      header: '',
      cell({ row }) {
        return (
          <ButtonGroup>
            <TaskPDFButton
              namespace="tasks"
              isEnabled={
                ['done', 'draft'].includes(row.original.status) &&
                !!row.original.event_id
              }
              eventId={row.original.event_id}
            />
          </ButtonGroup>
        );
      },
    },
  ];

  return columns;
};

export function TaskTable() {
  const { t } = useTranslation();
  const { tasks, isLoading, rowSelection, setRowSelection } = useTasks();

  if (!tasks.length && !isLoading) {
    return (
      <Center mt="10">
        <TasksListNoItems />
      </Center>
    );
  }

  return (
    <DataTable
      isLoading={isLoading && !tasks.length}
      data={tasks}
      withSelect
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      tableProps={{
        variant: 'striped',
        size: 'md',
        background: 'white',
        fontWeight: 'semibold',
      }}
      headerProps={{
        h: 14,
        bg: 'white',
      }}
      columns={getColumns({
        translate: t,
      })}
    />
  );
}
