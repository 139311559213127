import { gql } from '@/gql';
import { GroupsCreateInput } from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

import { queryAutomationGroups } from './useFetchAutomationGroups';

export const queryCreateAutomationGroup = gql(`#graphql
  mutation createAutomationGroup($input: GroupsCreateInput!) {
    createAutomationGroup(input: $input) {
      errors
      clientMutationId
      automationGroup {
        id
        name
        createdAt
      }
    }
  }
`);

export function useCreateAutomationGroup() {
  const toast = useToast();
  const [createAutoGroup, { data, loading, error }] = useMutation(
    queryCreateAutomationGroup,
    {
      refetchQueries: [queryAutomationGroups],
    },
  );

  const handleCreateAutoFlow = useCallback(
    async (payload: GroupsCreateInput) => {
      const res = await createAutoGroup({
        variables: {
          input: payload,
        },
      });
      const { data, errors } = res;

      if (data || errors) {
        toast({
          title: errors ? 'Criação do grupo falhou' : 'Grupo criado.',
          status: errors ? 'error' : 'success',
        });
      }

      return res;
    },
    [createAutoGroup, toast],
  );

  return {
    createAutoGroup: handleCreateAutoFlow,
    loading,
    error,
    data: data?.createAutomationGroup || null,
  };
}
