import styled from 'styled-components';
import { ContextMenuContainer } from '../ContextMenu';

export const Container = styled.div`
  flex-direction: row;
  height: 34px;
  display: flex;
`;

export const DropdownButton = styled.button`
  height: 100%;
  max-width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  margin: 0 0 0 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.v2_secondary};

  :active {
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
    -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
    transform: scale(0.99);
  }

  transition: 200ms;
  border: none;
`;

export const TimePickerContainer = styled(ContextMenuContainer)`
  padding: 0px;
`;

export const TimeRow = styled.div`
  flex-direction: row;
  display: flex;
`;

export const TimeCol = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
`;

export const TimeList = styled.ol`
  list-style-type: none;
  max-height: 120px;
  overflow: auto;
`;
