import React from 'react';

import { useWebFormState, useWebFormStore } from '../data/formState';
import { AnswerStateMap, FieldStateMap } from '../interfaces';

export default function useWebForm() {
  const { getState } = useWebFormStore();
  const edited = useWebFormState((state) => state.edited);

  const setFields = React.useCallback(
    (fields: FieldStateMap = {}) => {
      const { setFields } = getState();
      setFields({
        fields,
      });
    },
    [getState],
  );

  const setInitialValues = React.useCallback(
    (initialValues: AnswerStateMap = {}) => {
      const { setAnswers } = getState();
      setAnswers(initialValues);
    },
    [getState],
  );

  const resetFields = React.useCallback(
    (initialValues: AnswerStateMap = {}) => {
      const { reset, fields } = getState();
      reset({
        answers: {},
        fields: {},
      });
      setTimeout(() => {
        reset({
          answers: initialValues,
          fields,
        });
      }, 1);
    },
    [getState],
  );

  return {
    setFields,
    resetFields,
    setInitialValues,
    edited,
  };
}

export {};
