import styled from 'styled-components';

type OptionalProps = {
  center?: boolean;
  inline?: boolean;
  highlight?: boolean;
};

export const ScreenDescription = styled.p<OptionalProps>`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  ${({ center }) => (center ? 'text-align: center;' : null)}
  font-size: 16px;
  color: ${({ theme, highlight }) =>
    highlight ? 'red;' : theme.colors.commentGrey};
  ${({ highlight }) => (highlight ? 'font-size: 13px;' : null)}
  ${({ inline }) => (inline ? 'display: inline-block;' : null)}
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
`;
