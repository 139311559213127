import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const Subtitle = styled.h1`
  color: #fff;
  font-size: 14px;
  font-weight: normal;
`;
