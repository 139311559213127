import { useInspectionStoreApi } from '@/components_v2/WebForm/context/store';
import {
  AnswerStateMap,
  ErrorStateMap,
  SubmitResult,
} from '@/modules/WebForm/interfaces';
import { getAllQuestions } from '../utils/getAllQuestions';
import {
  IFieldSerializerMap,
  PartialAnswer,
  serializeFromFieldsToAnswers,
} from '../utils/serializeFromFieldsToAnswers';

export type SerializeCallbackFunc = (params: {
  answers: PartialAnswer[];
  isValid: boolean;
  errors: ErrorStateMap;
  state: AnswerStateMap;
}) => void;

/** Submits Webform values with custom field serializing */
export default function useSerializedSubmit(
  callback: SerializeCallbackFunc,
  serializerMap?: IFieldSerializerMap<any>,
) {
  const { getState } = useInspectionStoreApi();

  return (result: SubmitResult) => {
    const { answers, ...rest } = result;
    const allQuestions = getAllQuestions(getState().formulary);
    const serializedValues = serializeFromFieldsToAnswers(allQuestions, {
      answers,
      serializerMap,
    });
    callback({ answers: serializedValues, state: answers, ...rest });
  };
}
