import React from 'react';
import { Flex } from '@chakra-ui/react';
import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import { ItemQuestion } from '@/screens/monitoring/EventReports/interfaces';
import { SignOffProvider, useSignOff } from '../../hooks/useSignOff';
import {
  LeadTimeInput,
  CriticalityLabel,
  CurrentStockInput,
  SafetyStockInput,
  ResultLabel,
  AmountLabel,
} from './components';
import { safeParseJson } from '../../utils/safeParseJson';

export const CIRCULABI_SIGNOFF_QUESTION_TYPE = 'circulabi_signoff_result';

export const ViewCirculabiSignoffResult = ({
  question,
}: {
  question: ItemQuestion;
}) => {
  const { answers } = question;

  const state = React.useMemo(() => safeParseJson(answers[0].content), [
    answers,
  ]);

  return (
    <SignOffProvider value={{ state, dispatch: () => null, viewOnly: true }}>
      <Flex w="full" p="2" flexDir="column" gap="6">
        <LeadTimeInput />
        <CriticalityLabel />
        <CurrentStockInput />
        <SafetyStockInput />

        <ResultLabel />
        <AmountLabel />
      </Flex>
    </SignOffProvider>
  );
};

const EditContent = ({ question }: { question: QuestionModel }) => {
  const signoff = useSignOff({ question });

  return (
    <SignOffProvider value={signoff}>
      <Flex w="full" p="2" flexDir="column" gap="6">
        <LeadTimeInput />
        <CriticalityLabel />
        <CurrentStockInput />
        <SafetyStockInput />

        <ResultLabel />
        <AmountLabel />
      </Flex>
    </SignOffProvider>
  );
};

export const Content: React.FC<{
  question: QuestionModel;
  viewOnly?: boolean;
}> = ({ question, viewOnly }) => {
  if (viewOnly) {
    return <></>;
  }

  return <EditContent question={question} />;
};

export const CirculabiSignoffResult = React.memo(Content, (prev, next) => {
  return prev.question.id !== next.question.id;
});
