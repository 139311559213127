/* eslint-disable array-callback-return */
import { FragmentType, gql } from '@/gql';
import { useQuery } from '@apollo/client';

export const FlaggedAnswersConnectionFragment = gql(`#graphql
  fragment FlaggedAnswersConnectionFragment on Answer {
    flaggedOptions {
      id
      name
      position
    }
    flaggedAnswers {
      id
      optionId
    }
    topics {
          id
          commentsCount
          statusType {
            id
            isDefault
            label
            lastModifiedAt
            position
            updatedAt
            createdAt
            color
          }
        }
        comment
        createdAt
        deletedAt
        question {
          id
          name
          formulary {
            id
            name
          }
          section {
            name
            id
          }
          actionPlanId
          authorId
          childrenQuestionsCount
          clonedFromId
          codeMaxChar
          conditional
          createdAt
          deletedAt
          description
          enabled
          externalCreatedAt
          externalUpdatedAt
          formularyId
          hasComments
          imageUrl
          inputMask
          lastAuthorId
          lastModifiedAt
          maxPictures
          meta
          parentOptionId
          parentQuestionId
          position
          qrCodeOnly
          questionType
          required
          scoreWeight
          sectionId
          taskButtonLabel
          taskCreate
          taskFormularyId
          tipText
          updatedAt
          validationMinChar
          validationUrl
          options {
            alertMessage
            clonedFromId
            createdAt
            deletedAt
            description
            externalCreatedAt
            externalUpdatedAt
            id
            isFlagged
            lastModifiedAt
            name
            noApplyScore
            position
            questionId
            score
            updatedAt
          }
        }
        id
        option {
          name
          id
        }
        eventId
        event {
          id
          parentableType
          finishedAt
        }
        answeredAt
        authorId
        clientVersion
        collectionItemId
        content
        deviceUuid
        externalCreatedAt
        externalUpdatedAt
        failUrlValidation
        formatContent
        hasErrorInMedia
        hasMedia
        hasRemoteValidation
        itemId
        jobMediaCalled
        lastModifiedAt
        mediaExternalProcessed
        mediaProcessed
        numeric
        optionId
        questionId
        questionType
        updatedAt
        facility
  }
`);

export type FlaggedAnswersConnectionFragmentType = FragmentType<
  typeof FlaggedAnswersConnectionFragment
>;

export const queryFlaggedAnswersConnection = gql(`#graphql
  query FlaggedAnswersConnection($first: Int, $after: String, $before: String, $last: Int, $flaggedAnswersConnectionId: ID, $questionId: ID, $search: String, $filters: JSON) {
  flaggedAnswersConnection(first: $first, after: $after, before: $before, last: $last, id: $flaggedAnswersConnectionId, questionId: $questionId, search: $search, filters: $filters) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      cursor
      node {
        ...FlaggedAnswersConnectionFragment
      }
    }
  }
}
`);

export function useFetchFlaggedAnswersConnection({
  first,
  after,
  search,
  filters,
}: {
  first: number;
  after?: string;
  search?: string;
  filters?: any;
}) {
  const { data, loading, fetchMore, refetch } = useQuery(
    queryFlaggedAnswersConnection,
    {
      variables: {
        first,
        after,
        search,
        filters,
      },
      fetchPolicy: 'network-only',
    },
  );

  return {
    data: data?.flaggedAnswersConnection.edges.map((edge) => edge.node),
    pageInfo: data?.flaggedAnswersConnection.pageInfo,
    fetchMore,
    loading,
    refetch,
  };
}
