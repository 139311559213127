import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { BiDownload } from 'react-icons/bi';
import { FiRotateCcw } from 'react-icons/fi';

import SignaturePad, { SignaturePadRef } from './SignaturePad.component';

type SignatureModalProps = {
  isOpen?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  onConfirm: (file: File) => Promise<void>;
};

const SignatureModal: React.FC<SignatureModalProps> = ({
  isOpen = false,
  onClose = () => null,
  onConfirm,
  isLoading,
}) => {
  const modalRef = React.useRef<HTMLElement>(null);
  const padRef = React.useRef<SignaturePadRef>(null);
  const modalSize = useBreakpointValue({ base: 'full', lg: '2xl' });
  const modalOrientation = useBreakpointValue({
    base: 'horizontal',
    lg: 'vertical',
  });

  const handleConfirm = React.useCallback(async () => {
    if (!padRef.current) return;
    const file = await padRef.current?.onSave();

    if (file) {
      await onConfirm(file);
    }
  }, [onConfirm]);

  return (
    <Modal
      orientation={modalOrientation as any}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      size={modalSize}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <Portal>
        <ModalContent ref={modalRef}>
          <ModalHeader>Assinatura</ModalHeader>
          <ModalCloseButton />
          <ModalBody flexDir="column" display="flex">
            <SignaturePad ref={padRef} />
          </ModalBody>

          <ModalFooter justifyContent="space-between">
            <HStack spacing="2" justifyContent="flex-start">
              <IconButton
                disabled={isLoading}
                onClick={() => padRef.current?.onClear()}
                icon={<FiRotateCcw />}
                aria-label="repeat-signature"
              />
              <IconButton
                disabled={isLoading}
                onClick={() => padRef.current?.onDownload()}
                icon={<BiDownload />}
                aria-label="download-signature"
              />
            </HStack>
            <HStack>
              <Button
                isLoading={isLoading}
                translate="no"
                onClick={onClose}
                variant="ghost"
              >
                Cancelar
              </Button>
              <Button
                isLoading={isLoading}
                colorScheme="brand"
                mr={3}
                onClick={handleConfirm}
                translate="no"
              >
                Confirmar
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Portal>
    </Modal>
  );
};

export default SignatureModal;
