import React, { useState } from 'react';

import Tooltip from '@components/Tooltip';
import { emailValidator } from '@utils/Validators';
import debounce from '@utils/debounce';
import {
  QuestionContainer,
  QuestionHeader,
  QuestionTitle,
  QuestionSubtitle,
  Input,
  InfoTooltip,
  TooltipContainer,
} from '../common';
import { EmailProps } from './interfaces';

const Email: React.FC<EmailProps> = ({
  question,
  errors,
  initialValues,
  onChange,
  viewOnly = false,
}) => {
  const [email, setEmail] = useState<string>(initialValues?.values);
  const [debouncedEmail] = useState(() =>
    debounce(async (newText: string) => {
      const parsed = newText
        .split(',')
        .filter((el) => el.length)
        .map((el) => el.trim());

      setEmail(newText);
      const allValid = parsed.some((e) => !emailValidator(e));
      if (parsed.length > 0) onChange(parsed);
      if (allValid) onChange(parsed);
    }, 200),
  );

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionTitle>{question.name}</QuestionTitle>
        {question.tipText && (
          <Tooltip description={question.tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      <Input
        disabled={viewOnly}
        type="text"
        onChange={(e) => debouncedEmail(e.target.value)}
        hasError={
          (email ? !email?.split(',').some((e) => emailValidator(e)) : false) ||
          !!errors?.errors
        }
        defaultValue={initialValues?.values}
      />
      <QuestionSubtitle hasError={!!errors}>
        {errors && errors.errors}
      </QuestionSubtitle>
    </QuestionContainer>
  );
};

export default Email;
