/* eslint-disable react/no-array-index-key */
import _ from 'lodash';

import { SectionContext, useItem, useSection } from './hooks/use-context';

function SectionHeader() {
  const section = useSection();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0',
        borderBottom: '1px solid #d1d5db',
        paddingBottom: '0.5rem',
      }}
    >
      <h2
        style={{
          fontSize: '0.875rem',
          fontWeight: 600,
        }}
      >
        {section.section_name}
      </h2>
      {!!section.section_description && (
        <p
          style={{
            fontSize: '0.875rem',
          }}
        >
          Descrição: {section.section_description}
        </p>
      )}
    </div>
  );
}

export function SectionItem({ children }: { children?: React.ReactNode }) {
  return (
    <li
      style={{
        backgroundColor: 'white',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      <SectionHeader />
      {children}
    </li>
  );
}

export function SectionsList({ children }: { children?: React.ReactNode }) {
  const { sections } = useItem();

  return (
    <ul
      style={{
        width: '100%',
        pageBreakAfter: 'always',
      }}
    >
      {_.orderBy(sections, 'position', 'asc').map((section, idx) => (
        <SectionContext value={section} key={`${section.section_name}-${idx}`}>
          <SectionItem>{children}</SectionItem>
        </SectionContext>
      ))}
    </ul>
  );
}
