import React from 'react';
import {
  FiX,
  FiImage,
  FiCopy,
  FiTrash2,
  FiCheck,
  FiShare2,
  FiEdit,
  FiChevronLeft,
  FiFileText,
  FiDownload,
  FiUpload,
  FiArrowLeft,
} from 'react-icons/fi';

import styled from 'styled-components';
import { ButtonIcon } from './interfaces';

export const BackIcon = styled(FiChevronLeft)`
  border-radius: 50%;
  color: ${(props) => props.theme.colors.lighterText};
  background-color: ${(props) => props.theme.colors.primary};
`;

const renderIcon = (
  Icon: ButtonIcon | undefined,
  color: string,
  size: number,
): JSX.Element | null => {
  switch (Icon) {
    case 'CLOSE':
      return <FiX color={color} size={size} />;
    case 'DELETE':
      return <FiTrash2 color={color} size={size} />;
    case 'DEPENDENCE':
      return <FiShare2 color={color} size={size} />;
    case 'DONE':
      return <FiCheck color={color} size={size} />;
    case 'GO_BACK':
      return <BackIcon color={color} size={size} />;
    case 'DUPLICATE':
      return <FiCopy color={color} size={size} />;
    case 'IMAGE':
      return <FiImage color={color} size={size} />;
    case 'EDIT':
      return <FiEdit color={color} size={size} />;
    case 'FILE':
      return <FiFileText color={color} size={size} />;
    case 'DOWNLOAD':
      return <FiDownload color={color} size={size} />;
    case 'UPLOAD':
      return <FiUpload color={color} size={size} />;
    case 'ARROW_LEFT':
      return <FiArrowLeft color={color} size={size} />;
    default:
      return null;
  }
};

export { renderIcon };
