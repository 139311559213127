function getDarkColor(light: string): string {
  switch (light) {
    case 'white':
      return 'gray.600';
    case 'gray.50':
      return 'gray.700';
    case 'gray.100':
      return 'gray.800';
    case 'gray.200':
      return 'gray.500';
    case 'blue.100':
      return 'red.600';
    case 'blue.200':
      return 'red.700';
    case 'orange.300':
      return 'orange.500';
    case 'green.200':
      return 'green.500';
    case 'green.300':
      return 'green.400';
    default:
      return 'black';
  }
}

export { getDarkColor };
