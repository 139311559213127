import React from 'react';
import {
  Button,
  Center,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getContrastColor } from '@/utils/colors/getContrastColor';
import { ColorPickerProps } from './ColorPicker.types';

const ColorPicker: React.FC<ColorPickerProps> = ({
  colors,
  onChange,
  initialValue,
}) => {
  const { t } = useTranslation();
  const [color, setColor] = React.useState<string | undefined>(initialValue);

  const handleChange = React.useCallback(
    (c: string) => {
      onChange(c);
      setColor(c);
    },
    [onChange],
  );

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          aria-label={color}
          background={color || 'white'}
          borderColor="block"
          borderWidth="1px"
          height="22px"
          width="22px"
          padding={0}
          minWidth="unset"
          borderRadius={3}
        />
      </PopoverTrigger>
      <PopoverContent maxWidth="unset" width="unset">
        <PopoverArrow bg={color} />
        <PopoverCloseButton color="white" />
        <PopoverHeader
          height="100px"
          backgroundColor={color}
          borderTopLeftRadius={5}
          borderTopRightRadius={5}
          color="white"
        >
          <Center color={getContrastColor(color || '#ffffff')} height="100%">
            {color || t('inputs.color_picker.select')}
          </Center>
        </PopoverHeader>
        <PopoverBody height="120px">
          <SimpleGrid columns={5} spacing={2}>
            {colors.map((c) => (
              <Button
                key={c}
                aria-label={c}
                background={c}
                height="22px"
                width="22px"
                padding={0}
                minWidth="unset"
                borderRadius={3}
                _hover={{ background: c }}
                onClick={() => handleChange(c)}
              />
            ))}
          </SimpleGrid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPicker;
