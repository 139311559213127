import { FilterProvider } from '@/hooks/Filters/filter';
import { Flex } from '@chakra-ui/react';
import React from 'react';

import Board from './components/Board';
import Header from './components/Header';
import { TasksProvider } from './hooks/tasks';

const MonitoringContent = () => {
  return (
    <FilterProvider
      initialState={{
        status: {
          single: {
            column: 'status',
            selected: 'done',
            table: 'tasks',
            type: 'single',
          },
        },
      }}
      screen="tasks"
    >
      <TasksProvider>
        <Flex
          flexDir="row"
          overflowY="hidden"
          alignItems="flex-start"
          gap={4}
          flex={1}
          w="full"
        >
          <Board />
        </Flex>
      </TasksProvider>
    </FilterProvider>
  );
};

const TaskIndex: React.FC = () => {
  return (
    <Flex
      h="100vh"
      maxH="100vh"
      px={6}
      py={4}
      bg="gray.50"
      direction="column"
      flex={1}
    >
      <Header />
      <MonitoringContent />
    </Flex>
  );
};

export default TaskIndex;
