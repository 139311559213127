import {
  Center,
  Flex,
  Image,
  Square,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { BsImage } from 'react-icons/bs';
import { useAnswerSummary } from '../../hooks/useAnswerSummary';

const ImageAnswer = ({ answer }: { answer: any }) => {
  const { content } = answer;

  return (
    <Square
      key={answer.id}
      borderWidth="thin"
      w="auto"
      h="auto"
      maxW="full"
      overflow="hidden"
      rounded="md"
    >
      {answer?.mediaProcessed ? (
        <Image
          src={content}
          alt="Imagem"
          maxW="100%"
          maxH="100%"
          objectFit="contain"
        />
      ) : (
        <Tooltip label="A mídia está processando, ou ainda está sendo carregada no dispositivo que a originou. Por favor, aguarde.">
          <Center bg="gray.50" h="full" w="full" flexDir="column">
            <Text>Processando mídia.</Text>
            <BsImage size="18" />
          </Center>
        </Tooltip>
      )}
    </Square>
  );
};

export const Content = () => {
  const { answer } = useAnswerSummary();

  return (
    <Flex
      w={{ base: 'full', md: '770px' }}
      rounded="lg"
      borderWidth="thin"
      h="fit-content"
      minH="calc(100% - 30px)"
      mt="10"
      bg="gray.100"
      flexDir="column"
      align="center"
      justifyContent="center"
    >
      {!!answer && <ImageAnswer answer={answer} />}
    </Flex>
  );
};
