import { useFeedbackQuery } from '@/hooks/Feedback/useFeedbackQuery';
import { useModals } from '@/hooks/ModalManager';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonGroup,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  Portal,
} from '@chakra-ui/react';
import React from 'react';

import { FeedbackDisplay } from './FeedbackDisplay';
import { FeedbackReviewButton } from './FeedbackReviewButton';
import { FeedbackContext } from './hooks/useCurrentFeedback';
import { StatusSelect } from './StatusSelect';

type FeedbackViewDrawerProps = {
  id: string;
  onClose?: () => void;
  isOpen?: boolean;
};

export const FeedbackViewDrawer: React.FC<FeedbackViewDrawerProps> = (
  props,
) => {
  const { isOpen = false, onClose = () => null } = props;
  return (
    <Drawer isOpen={isOpen} size="lg" placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <FeedbackDisplay
          id=""
          renderHeader={(topic) => (
            <>
              <ButtonGroup size="md" variant="outline">
                <StatusSelect key={topic?.id} feedback={topic!} />
                <FeedbackReviewButton />
                <Button leftIcon={<DeleteIcon />}>Apagar</Button>
              </ButtonGroup>
              <IconButton
                icon={<CloseIcon />}
                aria-label="close"
                variant="outline"
                size="md"
                onClick={onClose}
              />
            </>
          )}
        />
      </DrawerContent>
    </Drawer>
  );
};

/** TODO: REFACTOR */
export const FeedbackViewContextProvider: React.FC<{ id: string }> = (
  props,
) => {
  const { id, children } = props;
  const { data: feedback } = useFeedbackQuery(id!);

  if (!id) return null;

  return (
    <FeedbackContext
      value={{
        feedback,
      }}
    >
      {children}
    </FeedbackContext>
  );
};

export const useFeedbackViewDrawer = () => {
  const modals = useModals();

  const open = React.useCallback(
    (topicId: string) => {
      const modalId = `feedback-${topicId}`;
      const onClose = () => modals.close(modalId);
      modals.drawer({
        id: modalId,
        size: 'lg',
        hideCloseButton: true,
        body: (
          <FeedbackViewContextProvider id={topicId}>
            <FeedbackDisplay
              id={topicId}
              renderHeader={(topic) => (
                <>
                  <ButtonGroup size="md" variant="outline">
                    <StatusSelect key={topic?.id} feedback={topic!} />
                    <FeedbackReviewButton />
                    <Button leftIcon={<DeleteIcon />}>Apagar</Button>
                  </ButtonGroup>
                  <IconButton
                    icon={<CloseIcon />}
                    aria-label="close"
                    variant="outline"
                    size="md"
                    onClick={onClose}
                  />
                </>
              )}
            />
          </FeedbackViewContextProvider>
        ),
      });
    },
    [modals],
  );

  return { open };
};
