/* eslint-disable react/no-array-index-key */
import _ from 'lodash';

import { SectionContext, useItem } from './hooks/use-context';

export function SectionItem({ children }: { children?: React.ReactNode }) {
  return <li className="bg-white">{children}</li>;
}

export function SectionsList({ children }: { children?: React.ReactNode }) {
  const { sections } = useItem();
  return (
    <ul className="w-full">
      {_.orderBy(sections, 'position', 'asc').map((section, idx) => {
        let parsedSection = section;

        switch (section?.section_name) {
          case 'Dados Gerais':
            parsedSection = {
              ...parsedSection,
              questions: parsedSection?.questions?.filter((question) =>
                [
                  '1478f985-6bad-4fd5-ba26-a8306f85a6e5',
                  '6ccd3857-cd1b-4b94-a88b-f743c3d14576',
                ].includes(question.question_id),
              ),
            };
            break;
          case 'Manutenção':
            parsedSection = {
              ...parsedSection,
              questions: parsedSection?.questions?.filter(
                (question) =>
                  ['86657e36-9cda-4de2-9b54-8f6389f043cc'].includes(
                    question.question_id,
                  ) || question.type === 'picture',
              ),
            };
            break;
          case 'Check-in/out':
            parsedSection = {
              ...parsedSection,
              questions: parsedSection?.questions?.filter(
                (question) => !question.name.includes('Localização'),
              ),
            };
            break;
          case 'Abertura':
            parsedSection = {
              ...parsedSection,
              questions: parsedSection?.questions?.filter(
                (question) =>
                  ![
                    '4ce6089e-a978-439c-9bd0-28eb03f5d97d',
                    '1260e506-0d4f-4de3-b885-0b10eb48508f',
                    '1c5d9a37-1f7c-4435-bfa3-3ce9b22f1cb5',
                    '7c4df916-bbbc-474a-95d6-16b8ecadecc2',
                    'e38b06ea-7afe-44d1-b7c5-2c7841ff15d4',
                  ].includes(question.question_id),
              ),
            };
            break;
          default:
            parsedSection = {
              ...parsedSection,
              questions: parsedSection?.questions?.filter(
                (question) =>
                  !['Data', 'Hora', 'Observação'].includes(question.name),
              ),
            };
            break;
        }

        return (
          <SectionContext
            value={parsedSection}
            key={`${section.section_name}-${idx}`}
          >
            <SectionItem>{children}</SectionItem>
          </SectionContext>
        );
      })}
    </ul>
  );
}
