import React from 'react';
import { useParams } from 'react-router-dom';

import { Answer } from './Answer';

interface AnswerReportRouteParams {
  id: string;
}

const AnswerReportRoute: React.FC = () => {
  const params = useParams<AnswerReportRouteParams>();

  const { id } = params;
  return (
    <Answer answerId={id} />
  );
};

export default AnswerReportRoute;
