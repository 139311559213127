import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import config from '@/Constants';
import { SyncState } from '../interfaces/ISync';

async function getSyncState(itemId: string): Promise<SyncState | null> {
  const response = await AxiosHelper.get({
    url: `${config.ITEM_SYNC_ITEM_ID_URL}/${itemId}`,
  });
  if (response.statusCode === 404) return null;
  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new Error();
  }
  return response.body;
}

export { getSyncState };
