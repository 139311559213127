import { __DEV__ } from '@chakra-ui/utils';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ArrayField } from './arrayField';
import { Field, FieldProps } from './Field';
import { FieldResolver } from './fieldResolver';
import { HookForm } from './Form';
import { FormLayout } from './layout';
import { ObjectField } from './objectField';

export interface FieldsProps {
  schema: any;
  fieldResolver?: FieldResolver;
  focusFirstField?: boolean;
}

const mapNestedFields = (resolver: FieldResolver, name: string) => {
  return resolver
    .getNestedFields(name)
    ?.map(
      ({ name, type, ...nestedFieldProps }: FieldProps, i): React.ReactNode => (
        <Field key={name || i} name={name} type={type} {...nestedFieldProps} />
      ),
    );
};

export const Fields: React.FC<FieldsProps> = ({
  schema,
  fieldResolver,
  focusFirstField,
  ...props
}) => {
  const resolver = React.useMemo(
    () => fieldResolver || HookForm.getFieldResolver(schema),
    [schema, fieldResolver],
  );

  const fields = React.useMemo(() => resolver.getFields(), [resolver]);

  const form = useFormContext();

  React.useEffect(() => {
    if (focusFirstField && fields[0]?.name) {
      form.setFocus(fields[0].name);
    }
  }, [schema, fieldResolver, focusFirstField]);

  return (
    <FormLayout {...props}>
      {fields.map(
        ({
          name,
          type,
          defaultValue,
          ...fieldProps
        }: FieldProps): React.ReactNode => {
          if (type === 'array') {
            return (
              <ArrayField key={name} name={name} {...fieldProps}>
                {mapNestedFields(resolver, name)}
              </ArrayField>
            );
          }
          if (type === 'object') {
            return (
              <ObjectField key={name} name={name} {...fieldProps}>
                {mapNestedFields(resolver, name)}
              </ObjectField>
            );
          }

          return <Field key={name} name={name} type={type} {...fieldProps} />;
        },
      )}
    </FormLayout>
  );
};

Fields.displayName = 'Fields';
