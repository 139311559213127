import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false, // Set to true to see debug logs
    detection: {
      // Order and from where user language should be detected
      order: [
        'localStorage',
        'cookie',
        'navigator',
        'querystring',
        'htmlTag',
        'path',
        'subdomain',
      ],

      // Keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // Cache user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // Languages to not persist (cookie, localStorage)

      // Optional expire and domain for set cookie
      cookieMinutes: 10,
      cookieDomain: 'myDomain',

      // Optional HTMLTag with lang attribute, the default is:
      htmlTag: document.documentElement,

      // Only detect languages that are in the whitelist
      checkWhitelist: true,
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
