import config from '@/Constants';
import { HttpResponse } from '@/modules/shared/data/protocols/http';
import { makeMutationFetcher } from '@/utils/react-query-utils';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { Workflow } from '../types/Workflow';
import { CreateWorkflowEventDto } from '../types/WorkflowEvent';

export type MutationParams = {
  options?: Omit<
    UseMutationOptions<HttpResponse<any>, unknown, Partial<any>, unknown>,
    'mutationFn'
  >;
};
export function useCreateFlowEventMutation(
  flowId: string,
  params: MutationParams = {},
) {
  const { options } = params;
  const queryClient = useQueryClient();

  const mutation = useMutation(
    makeMutationFetcher<CreateWorkflowEventDto, any>(
      `${config.WORKFLOWS_URL}/${flowId}/events`,
      'post',
    ),
    {
      onSuccess: (...restParams) => {
        const [data] = restParams;
        const newFlow = data.body;
        queryClient.setQueryData<Workflow[]>(
          `${config.WORKFLOWS_URL}/${flowId}/events`,
          (oldData = []) => [newFlow, ...oldData],
        );

        options?.onSuccess?.(...restParams);
      },
    },
  );

  return mutation;
}
