const getInitials = (name: string): string => {
  let initials = '';
  const splitname = String(name).toUpperCase().split(' ');
  if (splitname.length === 1) {
    initials = splitname[0] ? splitname[0].charAt(0) : '?';
  } else {
    initials = splitname[0].charAt(0) + splitname[1].charAt(0);
  }

  return initials;
};

export { getInitials };
