import React, { useCallback } from 'react';

import Main from './Main';

import { ConfirmModalProps, OnCloseProps } from './interfaces';

const AlertModal: React.FC<ConfirmModalProps> = ({
  onClose,
  title,
  subTitle,
}) => {
  const onConfirm = useCallback(() => {
    onClose({ confirmed: true });
  }, [onClose]);

  const onCancel = useCallback(() => {
    onClose({ confirmed: false });
  }, [onClose]);

  return (
    <Main
      onConfirm={onConfirm}
      onCancel={onCancel}
      title={title}
      subTitle={subTitle}
    />
  );
};

export type { ConfirmModalProps, OnCloseProps };

export default AlertModal;
