import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@hooks/navigation';
import { colors } from '@/styles/theme';
import { SubmitButton } from '../../../components';
import { ContentContainer, Container, DoubtIcon } from './styles';

const CommonContainer: React.FC = ({ children }) => {
  const { goBack } = useNavigation();
  const { t } = useTranslation();

  return (
    <Container>
      <ContentContainer>
        <DoubtIcon />
        {children}
        <SubmitButton
          buttonContent={t('common.goback')}
          callback={goBack}
          style={{ margin: 0, marginTop: 20, backgroundColor: colors.primary }}
        />
      </ContentContainer>
    </Container>
  );
};

export default CommonContainer;
