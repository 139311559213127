import React from 'react';

import { useModal } from '@hooks/modal';

import Modal from '@components/Modal';

const ModalLayer: React.FC = () => {
  const { props, isOpen } = useModal();
  const { children, onCancel, styles, modalStyles } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      styles={styles}
      modalStyles={modalStyles}
    >
      {children}
    </Modal>
  );
};

export default ModalLayer;
