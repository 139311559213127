import React, { useState, useEffect } from 'react';

import { CollapsibleProps } from './interfaces';
import Main from './Main';

const Collapsible: React.FC<CollapsibleProps> = ({
  title,
  children,
  isOpen,
  trigger,
  onClick,
  wrapperStyle,
  isSelected,
  contentStyle,
  triggerSelectedIcon,
}) => {
  const [isOpenState, setIsOpenState] = useState(isOpen || false);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsOpenState(isOpen);
    }
  }, [isOpen]);

  return (
    <Main
      title={title}
      trigger={trigger}
      isOpen={isOpenState}
      onClick={onClick}
      wrapperStyle={wrapperStyle}
      contentStyle={contentStyle}
      isSelected={isSelected}
      triggerSelectedIcon={triggerSelectedIcon}
    >
      {children}
    </Main>
  );
};

export default Collapsible;
