/* eslint-disable no-case-declarations */
import { FormularyReducer } from '../interfaces';

export const formularyReducer: FormularyReducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        formulary: action.formulary,
      };
    case 'set_fetch_loading':
      return { ...state, isFetchLoading: action.status };
    case 'set_subimit_loading':
      return { ...state, isSubmitLoading: action.status };
    default:
      return state;
  }
};
