/* eslint-disable react/no-array-index-key */
import _ from 'lodash';

import { Badge } from '../components/badge';
import {
  QuestionContext,
  useQuestion,
  useReport,
  useSection,
} from './hooks/use-context';

function QuestionFeedback() {
  const question = useQuestion();

  if (!question?.all_feedbacks || question?.all_feedbacks.length <= 0)
    return <></>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        fontSize: '0.875rem',
        color: '#4B5563',
      }}
    >
      <span style={{ marginRight: '0.5rem' }}>Feedback: </span>
      {question?.all_feedbacks.map((feedback, index) => (
        <div
          key={`${feedback.author}_${index}`}
          className="rounded border border-slate-300 p-2 text-start"
        >
          <strong>{feedback.author}</strong>
          <span> - </span>
          <span>{feedback.content}</span>
        </div>
      ))}
    </div>
  );
}

function QuestionComment() {
  const question = useQuestion();

  if (!question?.answers?.[0]?.comment) return <></>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        fontSize: '0.875rem',
        color: '#4B5563',
      }}
    >
      <span style={{ marginRight: '0.5rem' }}>Notas:</span>
      <div
        style={{
          borderRadius: '0.375rem',
          border: '1px solid #D1D5DB',
          padding: '0.5rem',
          textAlign: 'start',
        }}
      >
        <span>{question?.answers?.[0]?.comment}</span>
      </div>
    </div>
  );
}

function QuestionScore() {
  const { header } = useReport();
  const question = useQuestion();

  if (!question.answers[0]?.score || header?.hide_score) return <></>;

  return (
    <div style={{ fontSize: '0.875rem', color: '#4B5563' }}>
      <span style={{ marginRight: '0.5rem' }}>
        Peso da pergunta: {question.weight}
      </span>
      <span>Pontuação obtida: {question.score_obtained}</span>
    </div>
  );
}

function QuestionHeader() {
  const { header } = useReport();
  const { section_name } = useSection();
  const question = useQuestion();

  const hasFlag = question.answers?.some((e) => e.red_flag);

  const isFlagged = question.answers?.some((e) => e.is_flagged);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0',
        paddingBottom: '0.5rem',
      }}
    >
      <div
        style={{
          float: 'right',
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {section_name === 'Check-in/out' && (
              <h2 style={{ fontSize: '0.875rem', fontWeight: 600 }}>
                {header?.event_date_time}
              </h2>
            )}
            <h2 style={{ fontSize: '0.875rem', fontWeight: 600 }}>
              {question?.name === 'Selecione o líder:'
                ? 'Líder:'
                : question?.name}
            </h2>
            <QuestionScore />
          </div>
          {!!question?.image && (
            <img
              src={question?.image}
              alt=""
              style={{ height: '5rem', width: '5rem' }}
            />
          )}
        </div>

        {(!!hasFlag || !!isFlagged) && (
          <Badge size="xs" colorScheme="orange">
            Sinalizada como inconforme
          </Badge>
        )}
      </div>
    </div>
  );
}

export function QuestionItem({ children }: { children?: React.ReactNode }) {
  return (
    <li
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        padding: '0.75rem',
        breakInside: 'avoid',
      }}
    >
      <QuestionHeader />
      {!!children && (
        <div
          style={{
            wordBreak: 'break-word',
            backgroundColor: '#f3f4f6',
            padding: '12px',
          }}
        >
          {children}
        </div>
      )}
      <QuestionComment />
      <QuestionFeedback />
    </li>
  );
}

export function QuestionList({ children }: { children?: React.ReactNode }) {
  const { questions } = useSection();

  return (
    <ul style={{ width: '100%', padding: '0.25rem' }}>
      {_.orderBy(questions, 'position', 'asc').map((question, idx) => (
        <QuestionContext value={question} key={`${question.name}-${idx}`}>
          <QuestionItem>{children}</QuestionItem>
        </QuestionContext>
      ))}
    </ul>
  );
}
