import * as React from 'react';

import {
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerBody,
  DrawerCloseButton,
  DrawerProps as ChakraDrawerProps,
  Portal,
} from '@chakra-ui/react';
import { DefaultDrawerProps, DrawerProps } from './types/BaseDrawer.types';

export const BaseDrawer: React.FC<DefaultDrawerProps> = (props) => {
  const {
    title,
    children,
    isOpen,
    onClose,
    hideCloseButton,
    hideOverlay,
    ...rest
  } = props;
  return (
    <ChakraDrawer isOpen={isOpen} onClose={onClose} {...rest}>
      {!hideOverlay && <DrawerOverlay />}
      <DrawerContent>
        {title && <DrawerHeader>{title}</DrawerHeader>}

        {!hideCloseButton && <DrawerCloseButton />}
        {children}
      </DrawerContent>
    </ChakraDrawer>
  );
};

export const Drawer: React.FC<DrawerProps> = (props) => {
  const { footer, children, ...rest } = props;
  return (
    <Portal>
      <BaseDrawer {...rest}>
        <DrawerBody>{children}</DrawerBody>

        {footer && <DrawerFooter>{footer}</DrawerFooter>}
      </BaseDrawer>
    </Portal>
  );
};
