import React from 'react';

import { CommentItem } from './CommentItem';
import { ActivityItemProps } from './types';

export const ActivityItem: React.FC<ActivityItemProps> = (props) => {
  const { item } = props;

  if (!item.topicActivityType || item.topicActivityType === 'comment') {
    return <CommentItem {...props} />;
  }
};
