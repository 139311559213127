/* eslint-disable react/no-children-prop */
/* eslint-disable no-underscore-dangle */

import { Portal } from '@chakra-ui/react';
import React from 'react';

import { DefaultDialog } from './DefaultDialog';
import { BaseDrawer } from './DefaultDrawer';
import { DefaultModal } from './DefaultModal';
import LoaderComponent from './Loader.component';
import { DrawerOptions } from './types/BaseDrawer.types';
import { BaseModalProps, ModalId, ModalOptions } from './types/BaseModal.types';
import { ConfirmDialogOptions } from './types/Dialog.types';
import {
  ModalConfig,
  ModalManagerContextValue,
  ModalTypes,
} from './types/ModalManager.types';

export const ModalManagerContext = React.createContext<ModalManagerContextValue | null>(
  null,
);

const initialModalState: ModalConfig = {
  id: null,
  props: null,
  type: 'modal',
};

const defaultModals = {
  alert: DefaultDialog,
  confirm: DefaultDialog,
  modal: DefaultModal,
  loader: LoaderComponent,
  drawer: BaseDrawer,
};

interface ModalsProviderProps {
  children: React.ReactNode;
  modals?: Record<string, React.FC<any>>;
}

export const ModalManagerContextProvider: React.FC<ModalsProviderProps> = ({
  children,
  modals,
}) => {
  const _instances = React.useMemo(() => new Set<ModalConfig>(), []);

  const [activeModals, setActiveModals] = React.useState<
    Record<string, ModalConfig>
  >({
    modal: initialModalState,
  });

  const setActiveModal = React.useCallback(
    (modal: ModalConfig, scope?: string) => {
      if (!scope) {
        setActiveModals({
          modal,
        });
        return;
      }
      setActiveModals((prevState) => ({
        ...prevState,
        [scope]: modal,
      }));
    },
    [],
  );

  const getModalComponent = React.useMemo(() => {
    const _modals: any = {
      ...defaultModals,
      ...modals,
    };

    return (type: ModalTypes = 'modal') => {
      const component = _modals[type] || _modals.modal;

      return component;
    };
  }, [modals]);

  const open = React.useCallback(
    <T extends ModalOptions>(
      options: T | React.FC<BaseModalProps>,
    ): ModalId => {
      if (typeof options === 'function') {
        const component: React.FC<BaseModalProps> = options;
        options = ({
          component,
        } as unknown) as T;
      }

      const {
        id = _instances.size + 1,
        type = 'modal',
        scope = 'modal',
        component,
        ...props
      } = options;

      const modal: ModalConfig<T> = {
        id,
        props: props as T,
        type,
        scope,
        component,
      };

      _instances.add(modal);
      setActiveModal(modal, scope);

      return id;
    },
    [_instances, setActiveModal],
  );

  const confirm = React.useCallback(
    (options: ConfirmDialogOptions): ModalId => {
      return open<ConfirmDialogOptions>({
        ...options,
        scope: 'alert',
        type: 'confirm',
      });
    },
    [open],
  );

  const alert = React.useCallback(
    (options: ConfirmDialogOptions): ModalId => {
      return open({
        ...options,
        scope: 'alert',
        type: 'alert',
        cancelProps: {
          display: 'none',
        },
        confirmProps: {
          label: 'OK',
        },
        leastDestructiveFocus: 'confirm',
      });
    },
    [open],
  );

  const loader = React.useCallback((): ModalId => {
    return open({
      id: 'loader-overlay',
      type: 'loader',
      scope: 'alert',
    });
  }, [open]);

  const drawer = React.useCallback(
    (options: DrawerOptions & { scope?: 'alert' }): ModalId => {
      return open<DrawerOptions & { scope?: 'alert' }>({
        ...options,
        type: 'drawer',
      });
    },
    [open],
  );

  const closeAll = React.useCallback(() => {
    _instances.forEach((modal) => modal.props?.onClose?.({ force: true }));
    _instances.clear();

    setActiveModal(initialModalState);
  }, [_instances, setActiveModal]);

  const close = React.useCallback(
    async (id?: ModalId | null, force?: boolean) => {
      const modals = [...Array.from(_instances)];
      const modal = modals.filter((modal) => modal.id === id)[0];

      if (!modal) {
        return;
      }

      const shouldClose = await modal.props?.onClose?.({ force });
      if (shouldClose === false) {
        return;
      }

      _instances.delete(modal);

      const scoped = modals.filter(({ scope }) => scope === modal.scope);

      setActiveModal(
        scoped[scoped.length - 2] || {
          id: null,
          props: null,
          type: modal.type, // Keep type same as last modal type to make sure the animation isn't interrupted
        },
        modal.scope,
      );
    },
    [_instances, setActiveModal],
  );

  const dismissLoader = React.useCallback(() => {
    close('loader-overlay', true);
  }, [close]);

  const pop = React.useCallback(async () => {
    const modals = [...Array.from(_instances)];
    const modal = modals.pop();

    if (!modal) {
      return;
    }

    const shouldClose = await modal.props?.onClose?.({ force: true });
    if (shouldClose === false) {
      return;
    }

    _instances.delete(modal);

    const scoped = modals.filter(({ scope }) => scope === modal.scope);

    setActiveModal(
      scoped[scoped.length - 2] || {
        id: null,
        props: null,
        type: modal.type, // Keep type same as last modal type to make sure the animation isn't interrupted
      },
      modal.scope,
    );
  }, [_instances, setActiveModal]);

  const content = Object.entries(activeModals).map(([scope, config]) => {
    const Component = config.component || getModalComponent(config.type);

    const { title, body, children, ...props } = config.props || {};

    return (
      <Component
        key={scope}
        title={title}
        children={body || children}
        {...props}
        isOpen={!!(config.id && _instances.size)}
        onClose={() => close(config.id)}
      />
    );
  });

  const context = {
    open,
    alert,
    confirm,
    close,
    closeAll,
    loader,
    dismissLoader,
    pop,
    drawer,
  };

  return (
    <ModalManagerContext.Provider value={context}>
      <Portal>{content}</Portal>

      {children}
    </ModalManagerContext.Provider>
  );
};

export const useModalsContext = () =>
  React.useContext(ModalManagerContext) as ModalManagerContextValue;

export const useModals = () => {
  return useModalsContext();
};
