import actionCable from 'actioncable';

import ISocketManager from '@shared/data/protocols/websocket/ISocketManager';
import ISocketClient from '@shared/data/protocols/websocket/ISocketClient';
import ActionCableClient from './ActionCableClient';

export default class ActionCableManager implements ISocketManager {
  private cable: ActionCableClient;

  public subscribe(url: string, token: string): ISocketClient {
    const cable = actionCable.createConsumer(`${url}?token=${token}`);
    cable.connect();
    const cableClient = new ActionCableClient(url, cable);
    this.cable = cableClient;
    return cableClient;
  }

  public unsubscribe(): void {
    if (this.cable) {
      this.cable.disconnect();
    }
  }
}
