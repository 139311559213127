import { FeedbackError } from '@/components/Feedback';
import config from '@/Constants';
import { HttpResponse } from '@/modules/shared/data/protocols/http';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { Task, TaskStatus } from '@/screens/registrations/Tasks/interfaces';

async function changeTaskStatus(
  task: PartialWithId<Task>,
  nextStatus: TaskStatus,
): Promise<PartialWithId<Task>> {
  if (nextStatus === task.status) task;

  const req = {
    status: nextStatus,
  };

  const {
    statusCode,
    body,
  }: HttpResponse<PartialWithId<Task>> = await AxiosHelper.patch({
    url: `${config.TASKS_URL}/${task.id}`,
    body: req,
  });

  if (statusCode < 200 || statusCode > 300 || !body) {
    const errormessage = 'Falha ao atualizar tarefa';
    FeedbackError({ mainText: errormessage });
    throw new Error(errormessage);
  }

  return body;
}

export { changeTaskStatus };
