export const numberMask = (text: string): string => {
  return text.replace(/[^\d:]/g, '');
};

export const timeMask = (text: string): string | null => {
  const matched = text.match(/\b(?:[01][0-9]|2[0-3]):[0-5][0-9]\b/g);
  if (!matched) return null;

  return matched[0];
};
