import { Dict, memoizedGet as get } from '@chakra-ui/utils';
import {
  isReadable,
  readability,
  TinyColor,
  WCAG2Parms,
} from '@ctrl/tinycolor';

/**
 * Get the color raw value from theme
 * @param theme - the theme object
 * @param color - the color path ("green.200")
 * @param fallback - the fallback color
 */
export const getColor = (theme: Dict, color: string, fallback?: string) => {
  const hex = get(theme, `colors.${color}`, color);

  const { isValid } = new TinyColor(hex);
  return isValid ? hex : fallback;
};

/**
 * Darken a specified color
 * @param color - the color in hex, rgb, or hsl
 * @param amount - the amount to darken (0-100)
 */
export const darken = (raw: string, amount: number) => {
  return new TinyColor(raw).darken(amount).toHexString();
};

/**
 * Add black to a color
 * @param color - the color in hex, rgb, or hsl
 * @param amount - the amount black to add (0-100)
 */
export const blacken = (raw: string, amount: number) => {
  return new TinyColor(raw).mix('#000', amount).toHexString();
};

/**
 * Determines if the tone of given color is "light" or "dark"
 * @param color - the color in hex, rgb, or hsl
 */
export const tone = (color: string) => {
  const isDark = new TinyColor(color).isDark();
  return isDark ? 'dark' : 'light';
};

/**
 * Determines if a color tone is "dark"
 * @param color - the color in hex, rgb, or hsl
 */
export const isDark = (color: string) => tone(color) === 'dark';

/**
 * Determines if a color tone is "light"
 * @param color - the color in hex, rgb, or hsl
 */
export const isLight = (color: string) => tone(color) === 'light';

/**
 * Make a color transparent
 * @param color - the color in hex, rgb, or hsl
 * @param opacity - the amount of opacity the color should have (0-1)
 */
export const transparentize = (color: string, opacity: number) => {
  return new TinyColor(color).setAlpha(opacity).toRgbString();
};

/**
 * Add white to a color
 * @param color - the color in hex, rgb, or hsl
 * @param amount - the amount white to add (0-100)
 */
export const whiten = (color: string, amount: number) => {
  return new TinyColor(color).mix('#fff', amount).toHexString();
};

/**
 * Checks if a color meets the Web Content Accessibility
 * Guidelines (Version 2.0) for constract ratio.
 *
 * @param fg - the foreground or text color
 * @param bg - the background color
 */
export const isAccessible = (
  textColor: string,
  bgColor: string,
  options?: WCAG2Parms,
) => isReadable(bgColor, textColor, options);

export const complementary = (color: string) =>
  new TinyColor(color).complement().toHexString();

/**
 * Checks the contract ratio of between 2 colors,
 * based on the Web Content Accessibility Guidelines (Version 2.0).
 *
 * @param fg - the foreground or text color
 * @param bg - the background color
 */
export const contrast = (fg: string, bg: string) => readability(bg, fg);
