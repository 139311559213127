import { Flex, Text } from '@chakra-ui/react';
import Tooltip from '@components/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, NotificationIcon } from './styles';

interface NotificationsRenderProps {
  onOpenNotifications: () => void;
  hasOpenNotification: boolean;
  isMobile?: boolean;
}

const Notifications: React.FC<NotificationsRenderProps> = ({
  onOpenNotifications,
  hasOpenNotification,
  isMobile,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Tooltip description={t('file.notifications')} placement="right center">
        <Container
          onClick={onOpenNotifications}
          hasOpenNotification={hasOpenNotification}
        >
          <Flex gap="0.75rem" justifyContent="center" alignItems="center">
            <NotificationIcon size={18} />
            {isMobile && <Text fontSize="md">Transferências</Text>}
          </Flex>
        </Container>
      </Tooltip>
    </>
  );
};

export default Notifications;
