import firebase from 'firebase';

import '@firebase/storage';

import path from 'path';

import ICloudStorageManager from '@shared/data/protocols/cloudstorage/ICloudStorageManager';
import { UploadFileError } from '@shared/domain/errors';
import {
  CloudStoragePathData,
  CloundStorageUploadData,
  UploadResp,
} from '@shared/domain/interfaces';
import sanitize from 'sanitize-filename';

class FirebaseStorageManager implements ICloudStorageManager {
  private app: firebase.app.App;
  private basePath: string;

  constructor(app: firebase.app.App, basePath?: string) {
    this.app = app;
    if (basePath) this.basePath = basePath;
  }

  private getUniqueName(fileName: string) {
    const uniqueString = () => {
      let text = '';
      const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 8; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };
    const filename = fileName.replace(/[^0-9  a-zA-Z.]/g, '');
    const ext = filename.substring(filename.lastIndexOf('.') + 1);
    const uniqueFileName = `${uniqueString()}-${
      filename.substring(0, filename.lastIndexOf('.')) || filename
    }.${ext}`;

    return sanitize(uniqueFileName).replace(/ /g, '_');
  }

  private getPath(params: {
    fullPath?: string;
    basePath?: string;
    fileName?: string;
  }): string | null {
    const { basePath, fileName, fullPath } = params;

    if (fullPath) return fullPath;
    if (basePath && fileName) return path.join(basePath, fileName);
    if (this.basePath && fileName) return path.join(this.basePath, fileName);
    return null;
  }

  public async uploadFile(
    params: CloundStorageUploadData,
  ): Promise<UploadResp> {
    const { file, setProgress, setFileDownloadUrl } = params;
    if (!file) throw Error('invalid file');
    const uniqueFileName = this.getUniqueName(file.name);

    const storage = this.app.storage();
    const fileRef = storage.ref(uniqueFileName).put(file);
    fileRef.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress && setProgress(progress);
      },
      () => {
        throw new UploadFileError();
      },
      () => {
        fileRef.snapshot.ref
          .getDownloadURL()
          .then(
            (downloadURL) =>
              setFileDownloadUrl && setFileDownloadUrl(downloadURL),
          );
      },
    );

    return {
      old: file.name,
      storage: uniqueFileName,
    };
  }

  public async getUrl(params: CloudStoragePathData): Promise<string> {
    const path = this.getPath(params);
    if (!path) throw Error('invalid path');

    const url = await this.app.storage().ref(path).getDownloadURL();
    return url;
  }

  public async deleteFileFromName(name: string): Promise<void> {
    return this.app
      .storage()
      .ref(name)
      .delete()
      .catch((error) => {
        throw new Error(error.code);
      });
  }

  public async deleteFileFromURL(url: string): Promise<void> {
    return this.app
      .storage()
      .refFromURL(url)
      .delete()
      .catch((error) => {
        throw new Error(error.code);
      });
  }
}

export default FirebaseStorageManager;
