/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import config from '@/Constants';
import { TopicComment } from '@/hooks/Feedback/interfaces';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import snakeToCamel from '@/utils/snakeToCamel';
import { QueryFunctionContext, useQuery } from 'react-query';

export const discussionTopicCommentsKey = config.DISCUSSION_COMMENTS_URL;

const fetcher = async (
  params: QueryFunctionContext<string[], any>,
): Promise<TopicComment[]> => {
  const { queryKey } = params;

  const [path, topicId] = queryKey;
  const url = `${path}?discussion_topic_id=${topicId}`;
  const response = await AxiosHelper.get<TopicComment[]>({
    url,
    apiV: 1,
  });

  if (response.statusCode < 200 || response.statusCode > 300) {
    throw new UnexpectedError();
  }
  const { body } = response;
  return snakeToCamel(body);
};

export function useFeedbackCommentsQuery(
  topicId: string,
  params?: {
    enabled?: boolean;
    refetchInterval: number | false | undefined;
  },
) {
  const query = useQuery([discussionTopicCommentsKey, topicId], fetcher, {
    ...params,
    refetchOnWindowFocus: false,
  });
  return query;
}
