import config from '@/Constants';
import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { QueryResponse } from '@/modules/queryfilter/domain/interfaces';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import snakeToCamel from '@/utils/snakeToCamel';
import React from 'react';
import { QueryFunctionContext, QueryKey, useInfiniteQuery } from 'react-query';

type PaginatedDiscussionTopicQuery = QueryResponse<DiscussionTopic> & {
  nextPage: number | undefined;
};

export const discussionTopicsKey = config.DISCUSSION_TOPICS_URL;

const getPageParams = (page: number, size = 30) => `?size=${size}&page=${page}`;

const fetchFeedbacks = async (
  params: QueryFunctionContext<QueryKey, any>,
): Promise<PaginatedDiscussionTopicQuery> => {
  const { queryKey, pageParam = 1 } = params;
  const [url, pageSize, queryString] = queryKey;
  const pageParams = getPageParams(pageParam, pageSize as number);
  const response = await AxiosHelper.get({
    url: `${url}${pageParams}${queryString}`,
    apiV: 2,
  });

  if (response.statusCode < 200 || response.statusCode > 300) {
    throw new UnexpectedError();
  }

  const body: PaginatedDiscussionTopicQuery = snakeToCamel(response?.body);

  return {
    ...body,
    nextPage: body?.hasNextPage ? Number(pageParam) + 1 : undefined,
  };
};

export function useFeedbacksQuery(
  params: { queryString?: string; pageSize?: number } = {},
) {
  const { pageSize = 30, queryString = '' } = params;
  const memoizedQueryKey = React.useMemo(
    () => [discussionTopicsKey, pageSize, queryString],
    [pageSize, queryString],
  );

  const query = useInfiniteQuery<PaginatedDiscussionTopicQuery>(
    memoizedQueryKey,
    fetchFeedbacks,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  );

  const { data, isLoading, fetchNextPage } = query;

  return {
    isLoading,
    data,
    fetchNextPage,
  };
}
