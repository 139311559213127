import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { createContext } from '@/utils/react-utils';

type CurrentFeedbackContextValue = {
  feedback?: DiscussionTopic;
};

const [FeedbackContext, useCurrentFeedback] = createContext<
  CurrentFeedbackContextValue
>();

export { FeedbackContext, useCurrentFeedback };
