import styled from 'styled-components';

interface VerticalStepsProps {
  maxHeight?: number;
  bollColor?: string;
}

export const Container = styled.div<VerticalStepsProps>`
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ChildrenContainer = styled.div`
  display: flex;
`;

export const StepContainer = styled.div`
  width: 20px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const NumberBoll = styled.div<VerticalStepsProps>`
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, bollColor }) =>
    bollColor || theme.colors.primary};
  border-radius: 50% 50%;
  padding: 10px;
`;

export const BollTitle = styled.h1<VerticalStepsProps>`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme, bollColor }) => bollColor || theme.colors.lighterText};
  font-size: 14px;
  font-weight: 500;
`;

export const LineContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Line = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.v2_secondary};
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme }) => theme.colors.darkerText};
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Content = styled.div`
  flex: 1;
  max-width: 90%;
  display: flex;
  flex-direction: column;
`;
