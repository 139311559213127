import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  List,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import { useQuestions } from '../../hooks/useQuestionts';
import { useScore } from '../../hooks/useScore';
import { ItemSection } from '../../interfaces';
import { QuestionItem } from '../Question/QuestionItem';

export const SectionItem: React.FC<{ section: ItemSection }> = ({
  section,
}) => {
  const { score, totalScore, scoreColor } = useScore(section);

  const orderedQuestions = useQuestions(section);

  return (
    <AccordionItem>
      <AccordionButton
        px="4"
        pe="6"
        rounded="lg"
        py={{ base: '1', md: '4' }}
        _focus={{ outline: 'none' }}
        _selected={{ outline: 'none' }}
      >
        <AccordionIcon fontSize="26px" me="2" />
        <Box
          display="flex"
          flex="1"
          textAlign="left"
          fontSize="xl"
          fontWeight="bold"
          justifyContent="space-between"
          color="mutedText"
        >
          <Flex flexDir="column">
            <Text>{section?.name}</Text>
            <Text fontSize="sm" color="muted" translate="no">
              {' '}
              Feedbacks: {section?.countFeedbacks} / {section?.countAnswers}{' '}
            </Text>
          </Flex>
          <Badge
            hidden={!totalScore}
            h="min-content"
            variant="outline"
            size="lg"
            colorScheme={scoreColor}
          >
            {`${score}%`}
          </Badge>
        </Box>
      </AccordionButton>
      <AccordionPanel pb={4}>
        <List spacing="4">
          {orderedQuestions.map((question) => (
            <QuestionItem
              key={question.id}
              question={question}
              section={section}
            />
          ))}
        </List>
      </AccordionPanel>
    </AccordionItem>
  );
};
