import { UnexpectedError } from '@/modules/shared/domain/errors';
import diagnosticError from './diagnosticError';

export function invariantViolation(
  condition: any,
  errorMessage?: string,
): void {
  if (!condition) {
    if (!errorMessage) {
      throw new UnexpectedError();
    }
    const error = diagnosticError(errorMessage);

    throw error;
  }
}
