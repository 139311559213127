import { Option } from '@/components/Dropdown/interfaces';
import BaseDropdown from '@/components_v2/inputs/BaseDropdown';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecurrence } from '../../hooks/useRecurrence';
import { generateTemplateOptions } from '../../utils/templates';
import { DropdownContainer } from '../Common/styles';

// import { Container } from './styles';

const TemplateSelector: React.FC = () => {
  const { onChangeTemplate, state, viewOnly } = useRecurrence();
  const { recurrencyTemplateType } = state;
  const { t } = useTranslation();

  const handleChangeTemplate = useCallback(
    (selectedOption: Option | Option[]) => {
      if (Array.isArray(selectedOption)) return;
      onChangeTemplate(selectedOption.value);
    },
    [onChangeTemplate],
  );

  return (
    <DropdownContainer>
      <BaseDropdown
        disabled={viewOnly}
        defaultValue={recurrencyTemplateType}
        onChange={handleChangeTemplate}
        options={generateTemplateOptions(t)}
      />
    </DropdownContainer>
  );
};

export default TemplateSelector;
