import { EventEmitter } from 'events';

import {
  NotificationsTopic,
  TopicObserverCallback,
  WCNotification,
} from '../interfaces';

const NotificationsEventEmitter = new EventEmitter();

const subscribeTopic = (
  topic: NotificationsTopic,
  callback: TopicObserverCallback,
): (() => void) => {
  NotificationsEventEmitter.addListener(topic, callback);
  return () => {
    NotificationsEventEmitter.removeListener(topic, callback);
  };
};

const unsubscribeTopic = (
  topic: NotificationsTopic,
  callback: TopicObserverCallback,
): void => {
  NotificationsEventEmitter.removeListener(topic, callback);
};

const emitEvents = (payload: WCNotification): void => {
  if (payload.data) {
    const { originType } = payload.data;
    if (originType) {
      NotificationsEventEmitter.emit(originType, payload);
    }
  }
};

export const NotificationsTopics = {
  subscribeTopic,
  unsubscribeTopic,
  emitEvents,
};
