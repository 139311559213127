import { useLocation } from 'react-router-dom';
import React, { PropsWithChildren } from 'react';
import { create, StoreApi } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { TFilter } from './types';
import { createContext } from '@/utils/react-utils';

type FilterKey = string;
type FilterValue = any;

type FilterByScreen = {
  screen: string;
  /** filtro por chave */
  byKey: Record<FilterKey, FilterValue>; // { sigla: ['a', 'b', 'c', 'd']}
  toggle: (key: FilterKey, value: string) => void;
  set: (key: FilterKey, value: any) => void;
  clearByKey: (key: FilterKey) => void;
  clearByTable: (table: string) => void;
  reset: () => void;
};
/** DOCUMENTAÇÃO */
export type FilterState = {
  /** filtro por tela */
  byScreen: Record<string, FilterByScreen>; // { [key: string]: FilterByScreen }
  create: (
    screen: string,
    defaults?: Partial<
      Record<TFilter['filterKey'], string[] | { [key: string]: string[] }>
    >,
  ) => void;
};

const createFilterSlice = (
  screen: string,
  set: (fn: (draft: FilterState) => void) => void,
  get: StoreApi<FilterState>['getState'],
  defaults?: Partial<
    Record<TFilter['filterKey'], string[] | { [key: string]: string[] }>
  >,
): FilterByScreen => {
  return {
    screen,
    byKey: defaults ? { ...defaults } : {},
    toggle(key, value) {
      const previousValue: string[] = get().byScreen[screen].byKey[key] || [];
      const idx = previousValue.indexOf(value);

      const nextValue =
        idx > -1
          ? previousValue.filter((e) => e !== value)
          : [...previousValue, value];

      set((draft) => {
        draft.byScreen[screen].byKey[key] = nextValue;
      });
    },
    set(key, value) {
      set((draft) => {
        draft.byScreen[screen].byKey[key] = value;
      });
    },
    clearByKey(key) {
      set((draft) => {
        delete draft.byScreen[screen].byKey[key];
      });
    },
    clearByTable(table) {
      set((draft) => {
        Object.keys(draft.byScreen[screen].byKey).forEach((key) => {
          if (key.split('.')[1] === table || key === table) {
            delete draft.byScreen[screen].byKey[key];
          }
        });
      });
    },
    reset() {
      set((draft) => {
        draft.byScreen[screen].byKey = {};
      });
    },
  };
};

const createFilterStore = () => {
  const useFilterStore = create<FilterState>()(
    devtools(
      immer((set, get) => ({
        byScreen: {},
        create(screen, defaults) {
          const store = get().byScreen[screen];
          if (!!store) return;

          set((draft) => {
            const newStore = createFilterSlice(screen, set, get, defaults);

            draft.byScreen[screen] = newStore;
          });
        },
      })),
    ),
  );
  return { useFilterStore };
};

export const { useFilterStore } = createFilterStore();

const [FilterContext, useFilterContext] = createContext<{ screen: string }>();

// Custom hook to get search params
function useSearchParams() {
  const location = useLocation();
  return React.useMemo(() => new URLSearchParams(location.search), [location]);
}

export function FilterScreenProvider({
  screen,
  defaults,
  children,
}: PropsWithChildren<{
  screen: string;
  defaults?: Partial<Record<TFilter['filterKey'], string[]>>;
}>) {
  const { create } = useFilterStore();
  const params = useSearchParams();
  const defaultsFromParams = {} as Partial<
    Record<TFilter['filterKey'], string[]>
  >;

  params.forEach((value, key) => {
    if (key.includes('filter-')) {
      const parsedKey = key.replace('filter-', '') as TFilter['filterKey'];
      const parsedValue = JSON.parse(value);

      if (!defaultsFromParams[parsedKey]) {
        defaultsFromParams[parsedKey] = Array.isArray(parsedValue)
          ? parsedValue.map((v) => `${v}`)
          : [value];
      } else {
        defaultsFromParams[parsedKey] = [
          ...(defaultsFromParams[parsedKey] as string[]),
          value,
        ];
      }
    }
  });

  React.useEffect(() => {
    create(screen, { ...defaults, ...defaultsFromParams });
  }, [screen, defaults, defaultsFromParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return <FilterContext value={{ screen }}>{children}</FilterContext>;
}
export { useFilterContext };

export function useFilterScreen() {
  const { screen } = useFilterContext();

  if (!screen)
    throw new Error('useScreenFilters must be inside FilterScreenProvider');

  return screen;
}

export function useFiltersByScreen() {
  const screen = useFilterScreen();

  const store = useFilterStore(
    useShallow((state) => state.byScreen[screen]),
  ) || {
    byKey: {},
    screen,
  };
  return store;
}
