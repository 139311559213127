import React from 'react';
import { HStack, Circle, Text, Icon, Square } from '@chakra-ui/react';
import { BiChevronRight } from 'react-icons/bi';

export type BoardItemProps = {
  color?: string;
  count?: number | string;
  selected?: boolean;
  onClick?: () => void;
};

export const BoardItem: React.FC<BoardItemProps> = ({
  children,
  color,
  selected,
  onClick,
}) => {
  return (
    <HStack
      onClick={onClick}
      align="center"
      px="1.5"
      w="full"
      rounded="md"
      py="2"
      mb="2"
      role="group"
      fontWeight="semibold"
      transition=".15s ease"
      _hover={{
        bg: 'blackAlpha.200',
      }}
      bg={selected ? 'blackAlpha.100' : 'inherit'}
      justify="space-between"
      fontSize="md"
      cursor="pointer"
      // color={selected ? 'inherit' : 'blackAlpha.800'}
    >
      <HStack w="full" spacing="2">
        <Square
          rounded="3px"
          size="14px"
          bg={color ? `${color}.500` : 'gray.300'}
        />
        <Text wordBreak="break-all" noOfLines={1} fontSize="sm">
          {children}
        </Text>
      </HStack>
      <Icon as={BiChevronRight} fontSize="md" />
    </HStack>
  );
};
