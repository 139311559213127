import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheck } from 'react-icons/fi';

const LanguageSwitcher = ({
  children,
  isMobile,
}: {
  children: React.ReactNode;
  isMobile?: boolean;
}) => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  const selectedLanguague = i18n?.language;

  const SelectedLanguagueBtn = () => {
    if (selectedLanguague === 'pt-BR') {
      return (
        <Flex alignItems="center" gap="0.75rem">
          <Box className="fi fi-br" />

          {isMobile && <Text fontSize="md"> Português (Brasil)</Text>}
        </Flex>
      );
    }
    if (selectedLanguague === 'en') {
      return (
        <Flex alignItems="center" gap="0.75rem">
          <Box className="fi fi-us" />

          {isMobile && <Text fontSize="md">English</Text>}
        </Flex>
      );
    }
    if (selectedLanguague === 'es') {
      return (
        <Flex alignItems="center" gap="0.75rem">
          <Box className="fi fi-es" />

          {isMobile && <Text fontSize="md">Español</Text>}
        </Flex>
      );
    }
    return <></>;
  };

  return (
    <Menu>
      <MenuButton
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="full"
        w="full"
        bg="transparent"
        p={0}
        _hover={{ background: 'transparent' }}
        _active={{ background: 'transparent' }}
        as={Button}
        color="gray.800"
        paddingInline="0.90rem"
        minHeight={isMobile ? '3rem' : '2rem'}
      >
        {/* {children} */}
        <SelectedLanguagueBtn />
      </MenuButton>
      <MenuList zIndex={100}>
        <MenuItem onClick={() => changeLanguage('pt-BR')}>
          <Flex alignItems="center" gap="0.75rem">
            <Box className="fi fi-br" />
            <Text fontSize="md">Português (Brasil)</Text>
            {selectedLanguague === 'pt-BR' && <FiCheck />}
          </Flex>
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('en')}>
          <Flex alignItems="center" gap="0.75rem">
            <Box className="fi fi-us" />
            <Text fontSize="md">English</Text>
            {selectedLanguague === 'en' && <FiCheck />}
          </Flex>
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('es')}>
          <Flex alignItems="center" gap="0.75rem">
            <Box className="fi fi-es" />
            <Text fontSize="md">Español</Text>
            {selectedLanguague === 'es' && <FiCheck />}
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default LanguageSwitcher;
