import React from 'react';
import { useWorkspace } from '@/hooks/workspace';
import { ReactComponent as WeCheckIcon } from '@assets/wc-icon.svg';
import styled from 'styled-components';

export const Icon = styled(WeCheckIcon)`
  position: relative;
  color: #ffffff;
  height: 80%;
  max-width: 60px;
  order: 1;

  @media (min-width: 768px) {
    order: 0;
    width: 42px;
    height: 19px;
  }
`;

export const IconContainer = styled.div`
  position: relative;
  background: #ffffff;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 45px;
  background-color: white;
  padding: 10px;
  order: 1;

  @media (min-width: 768px) {
    order: 0;
    width: 50px;
    height: 50px;
    transition: box-shadow 1s ease;
    transition: border-radius 300ms ease;
    :hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
    }
  }
`;

export const ImgContainer = styled.img`
  height: 100%;
  width: 100%;
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const WorkspaceAwareIcon: React.FC = () => {
  const { workspaceData } = useWorkspace();
  if (!workspaceData.icon_url) return <Icon />;

  return (
    <IconContainer>
      <ImgContainer src={workspaceData.icon_url} alt="" />
    </IconContainer>
  );
};

export default WorkspaceAwareIcon;
