import { FragmentType, gql } from '@/gql';
import { useQuery } from '@apollo/client';

export const AutomationAnswer_AnswerFragment = gql(`#graphql
  fragment AutomationAnswer_AnswerFragment on Answer {
    id
    content
    formatContent
    collectionItemId
    eventId
    itemId
    question {
      actionPlanId
      authorId
      childrenQuestionsCount
      clonedFromId
      codeMaxChar
      conditional
      createdAt
      deletedAt
      description
      enabled
      externalCreatedAt
      externalUpdatedAt
      formularyId
      hasComments
      id
      imageUrl
      inputMask
      lastAuthorId
      lastModifiedAt
      maxPictures
      meta
      name
      parentOptionId
      parentQuestionId
      position
      qrCodeOnly
      questionType
      required
      scoreWeight
      sectionId
      taskButtonLabel
      taskCreate
      taskFormularyId
      tipText
      updatedAt
      validationMinChar
      validationUrl
      options {
        alertMessage
        clonedFromId
        createdAt
        deletedAt
        description
        externalCreatedAt
        externalUpdatedAt
        id
        isFlagged
        lastModifiedAt
        name
        noApplyScore
        position
        questionId
        score
        updatedAt
      }
    }
    option {
      id
    }
  }
`);

export type AutomationAnswerFragment = FragmentType<
  typeof AutomationAnswer_AnswerFragment
>;

export const queryAutomationAnswer = gql(`#graphql
  query Answer($answerId: String!) {
    answer(id: $answerId) {
      id
      content
      question {
      actionPlanId
      authorId
      childrenQuestionsCount
      clonedFromId
      codeMaxChar
      conditional
      createdAt
      deletedAt
      description
      enabled
      externalCreatedAt
      externalUpdatedAt
      formularyId
      hasComments
      id
      imageUrl
      inputMask
      lastAuthorId
      lastModifiedAt
      maxPictures
      meta
      name
      parentOptionId
      parentQuestionId
      position
      qrCodeOnly
      questionType
      required
      scoreWeight
      sectionId
      taskButtonLabel
      taskCreate
      taskFormularyId
      tipText
      updatedAt
      validationMinChar
      validationUrl
      options {
        alertMessage
        clonedFromId
        createdAt
        deletedAt
        description
        externalCreatedAt
        externalUpdatedAt
        id
        isFlagged
        lastModifiedAt
        name
        noApplyScore
        position
        questionId
        score
        updatedAt
      }
    }
      ...AutomationAnswer_AnswerFragment
    }
  }
`);

export function useFetchAnswer({ id }: { id?: string }) {
  const { data, loading } = useQuery(queryAutomationAnswer, {
    variables: id
      ? {
          answerId: id,
        }
      : undefined,
  });

  return { data: data?.answer || null, loading };
}
