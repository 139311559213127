import moment from 'moment';

/**
 * @param Date
 * @return Diff in seconds from midnight to providade time
 */
export function milisSinceMidnight(date: Date): number {
  let calc = 0;

  const now = moment(date) /* valueOf returns epoch milisseconds */
    .startOf('day')
    .utc()
    .valueOf(); /* valueOf returns epoch milisseconds */

  const epochDate = date.getTime(); /* JS vanilla date object to epoch milisseconds */

  calc = epochDate - now; /* get diff between end and start date */

  return calc;
}
