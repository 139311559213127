import { UserPermissionsType } from '@/modules/core/domain/models/user';
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import {
  UserPermissionsState,
  UserPermissionsContextData,
} from './interfaces/userPermissions';

export const UserPermissionsContext = createContext<UserPermissionsContextData>(
  {} as UserPermissionsContextData,
);

export const UserPermissionsProvider: React.FC<UserPermissionsState> = ({
  children,
  ...initial
}) => {
  const { permissions, isOwner } = useMemo(() => {
    return initial;
  }, [initial]);

  const checkPermission = useCallback(
    (permissionNeeded: UserPermissionsType) => {
      switch (permissions) {
        case 'r':
          return permissionNeeded === 'r';
        case 'rw':
          return permissionNeeded === 'r' || permissionNeeded === 'rw';
        case 'admin':
          return true;
        default:
          return false;
      }
    },
    [permissions],
  );

  return (
    <UserPermissionsContext.Provider
      value={{
        isOwner,
        permissions,
        checkPermission,
      }}
    >
      {children}
    </UserPermissionsContext.Provider>
  );
};

export function useUserPermissions(): UserPermissionsContextData {
  const context = useContext(UserPermissionsContext);

  if (!context) {
    throw new Error(
      'useUserPermissions must be used whithin an UserPermissionsProvider',
    );
  }

  return context;
}
