import { useToast } from '@chakra-ui/react';
import React from 'react';

export function useCopyToClipboard() {
  const toast = useToast();
  const handleCopyLink = React.useCallback(
    (value: string) => {
      navigator.clipboard.writeText(value);
      toast({
        title: 'Copiado para área de transferência',
        status: 'info',
      });
    },
    [toast],
  );
  return handleCopyLink;
}
