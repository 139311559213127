import config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import snakeToCamel from '@/utils/snakeToCamel';
import { EventWithItems } from '../interfaces';

export async function getEvent(eventId: string): Promise<EventWithItems> {
  const { body } = await AxiosHelper.get<EventWithItems>({
    url: `${config.EVENTS_URL}/${eventId}`,
    apiV: 2,
  });

  const parsed: EventWithItems = snakeToCamel(body);

  return parsed;
}
