import { useTheme } from '@/hooks/theme';
import React from 'react';

import Cell from './components/Cell';
import { TableProps, Row, CellProps, CellCustomProps } from './interfaces';
import { Container, CellContainer } from './styles';

const renderCells = (cellPrams: CellCustomProps) => (
  columnParams: CellProps,
) => (
  <Cell
    {...columnParams}
    {...cellPrams}
    styleContainer={CellContainer({ ...cellPrams })}
  />
);

const renderRows = (params: CellCustomProps) => (
  currentRow: Row,
  index: number,
  rowList: Row[],
) => {
  if (!params.headerProps) return null;
  const { headerProps, rowColor } = params;

  const backgroundColor = index % 2 === 0 ? 'transparent' : rowColor;
  const hasBorder = index < rowList.length - 1;

  const columnsKeys = Object.keys(currentRow);
  const cells = columnsKeys.map((columnkey: string) =>
    renderCells({
      ...params,
      title: currentRow[columnkey],
      backgroundColor,
      hasBorder,
    })(headerProps[columnkey]),
  );
  return cells;
};

const TableMini: React.FC<TableProps> = ({
  header,
  data,
  maxHeight,
  rowColor,
  ...props
}) => {
  const { colors } = useTheme();

  const headerData = header.data;
  const headerCellsKeys = Object.keys(headerData);
  const columnsCount = headerCellsKeys.length;

  const currentHeaderColor = header.backgroundColor || colors.v2_secondary;
  const currentRowColor = rowColor || colors.v2_backgound;

  return (
    <Container columnsCount={columnsCount} maxHeight={maxHeight}>
      {headerCellsKeys.map((key: string) =>
        renderCells({
          ...header,
          backgroundColor: currentHeaderColor,
          textAling: 'center',
          textColor: undefined,
        })(headerData[key]),
      )}
      {data.map(
        renderRows({
          ...props,
          headerProps: headerData,
          rowColor: currentRowColor,
        }),
      )}
    </Container>
  );
};

export default TableMini;
