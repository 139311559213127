import React from 'react';

export const ButtonEnum = {
  DELETE: 'DELETE',
  ACTIVATE: 'ACTIVATE',
  DEACTIVATE: 'DEACTIVATE',
  CANCEL: 'CANCEL',
  OPEN_FILTERS: 'OPEN_FILTERS',
  APPLY_FILTERS: 'APPLY_FILTERS',
  FILTER: 'FILTER',
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  RETRACT: 'RETRACT',
  CONFIRM: 'CONFIRM',
  UNCHECK_ALL: 'UNCHECK_ALL',

  PRIMARY_BORDER: 'primary-border',
  WHITE_BORDER: 'white-border',
  PRIMARY_BACKGROUND: 'primary-background',
  GREY_BACKGOUND: 'GREY_BACKGOUND',

  ITEM_LIST: 'ITEM_LIST',
};

export const ButtonIconEnum = {
  IMAGE: 'IMAGE',
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE',
  CLOSE: 'CLOSE',
  DONE: 'DONE',
  DEPENDENCE: 'DEPENDENCE',
  EDIT: 'EDIT',
  FILE: 'FILE',
  GO_BACK: 'GO_BACK',
  DOWNLOAD: 'DOWNLOAD',
  UPLOAD: 'UPLOAD',
};

export type ButtonIcon = keyof typeof ButtonIconEnum;

export type ButtonType = keyof typeof ButtonEnum;

export interface ButtonProps {
  type: ButtonType;
  icon?: ButtonIcon;
  manualIcon?: JSX.Element;
  text?: string;
  negative?: boolean;
  callback: (e?: React.MouseEvent) => void;
  style?: React.CSSProperties;
  styleTitle?: React.CSSProperties;
  disabled?: boolean;
  isLoading?: boolean;
  isExpansible?: boolean;
  quantity?: number;
}
