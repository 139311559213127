import moment from 'moment';

import { Table, Td } from '../components/table';
import { useReport } from './hooks/use-context';

function ReportDetails() {
  const { header } = useReport();

  if (!header) return null;

  const {
    formulary_name,
    fantasy_name,
    company_name,
    address,
    author,
    created_at,
    updated_at,
    document: companyDocument,
  } = header;

  function formatDate(date: string) {
    try {
      const parsedDate = moment(date);
      return parsedDate.format('DD/MM/YYYY HH:mm');
    } catch (error) {
      console.error('Error parsing date:', error);
      return null;
    }
  }

  return (
    <div
      style={{
        width: '100%',
        fontSize: '0.875rem',
        fontWeight: 'bold',
      }}
    >
      <Table>
        <tbody>
          <tr>
            <Td>Nome do formulário</Td>
            <Td>{formulary_name}</Td>
          </tr>

          <tr>
            <Td>Autor</Td>
            <Td>{author ? author.email : ''}</Td>
          </tr>

          {created_at && (
            <tr>
              <Td>Criado em</Td>
              <Td>{formatDate(created_at)}</Td>
            </tr>
          )}

          {updated_at && (
            <tr>
              <Td>Última atualização</Td>
              <Td>{formatDate(updated_at)}</Td>
            </tr>
          )}

          {!(!header.company_name || !header.fantasy_name) && (
            <tr>
              <Td>Dados da empresa</Td>
              <Td>
                {fantasy_name ? ` ${fantasy_name}` : ''}
                {company_name ? ` - ${company_name}` : ''}
                {companyDocument ? `, ${companyDocument}` : ''}
                {address ? `, ${address}` : ''}
              </Td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export function FormHeader() {
  const { header } = useReport();

  if (!header) return null;

  return (
    <div
      style={{
        marginBottom: '2rem',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: '2.5rem',
        color: '#334155',
      }}
    >
      <h1
        style={{
          fontSize: '1.875rem',
          fontWeight: 500,
          lineHeight: '1.25',
        }}
      >
        {header.formulary_name}
      </h1>

      <ReportDetails />
    </div>
  );
}
