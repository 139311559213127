import React, { useState, useCallback } from 'react';
import { ActionItem } from '../ContextMenu';
import { TimePickerDropdownProps } from './interfaces';

import { TimePickerContainer, TimeRow, TimeCol, TimeList } from './styles';
import { generateHoursArr, generateMinuteArr } from './utils/TimeUtils';

const TimePickerDropdown: React.FC<TimePickerDropdownProps> = (props) => {
  const { onChange = () => null, defaultValue = ['00', '00'] } = props;
  const [state, setstate] = useState(defaultValue);

  const setMinutes = useCallback(
    (minutes: string) => {
      setstate((oldState) => {
        const newState = [...oldState];
        newState[1] = minutes;
        onChange(newState.join(':'));
        return newState;
      });
    },
    [onChange],
  );

  const setHours = useCallback(
    (hours: string) => {
      setstate((oldState) => {
        const newState = [...oldState];
        newState[0] = hours;
        onChange(newState.join(':'));
        return newState;
      });
    },
    [onChange],
  );

  return (
    <TimePickerContainer>
      <TimeRow>
        <TimeCol>
          <TimeList>
            {generateHoursArr().map((el) => (
              <ActionItem
                selected={el === state[0]}
                text={el}
                icon={<></>}
                clickable
                onClick={() => {
                  setHours(el);
                }}
              />
            ))}
          </TimeList>
        </TimeCol>
        <TimeCol>
          <TimeList>
            {generateMinuteArr().map((el) => (
              <ActionItem
                selected={el === state[1]}
                text={el}
                icon={<></>}
                clickable
                onClick={() => {
                  setMinutes(el);
                }}
              />
            ))}
          </TimeList>
        </TimeCol>
      </TimeRow>
    </TimePickerContainer>
  );
};

export default TimePickerDropdown;
