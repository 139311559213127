import React, { useState, useCallback, useEffect } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';

import Tooltip from '@components/Tooltip';
import {
  QuestionContainer,
  QuestionTitle,
  QuestionHeader,
  Input,
  QuestionSubtitle,
  InfoTooltip,
  TooltipContainer,
} from '../common';
import { InputContainer, SuggestionsContainer } from './styles';
import { AddressRenderProps } from './interfaces';
import '@utils/initializeGoogleApiScript';

const AddressSearchInput: React.FC<AddressRenderProps> = ({
  addressName,
  handleSelect,
  reset,
  title,
  errorMessage,
  tipText,
  disabled = false,
}) => {
  const [address, setAddress] = useState(addressName);
  const { t } = useTranslation();
  const hasError = Boolean(errorMessage && errorMessage.length > 0);

  useEffect(() => {
    setAddress(addressName);
  }, [addressName]);

  const handleChange = useCallback(
    (address) => {
      reset();
      setAddress(address);
    },
    [reset],
  );
  return (
    <QuestionContainer style={{ maxWidth: '100%' }}>
      <QuestionHeader>
        <QuestionTitle>{title}</QuestionTitle>
        {tipText && (
          <Tooltip description={tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      <PlacesAutocomplete
        value={address}
        debounce={250}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          types: ['establishment', 'geocode'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <InputContainer>
            <Input
              hasError={hasError}
              {...getInputProps({
                className: 'location-search-input',
              })}
              disabled={disabled}
            />
            <SuggestionsContainer
              hasContent={suggestions.length > 0 || loading}
              className="autocomplete-dropdown-container"
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </SuggestionsContainer>
          </InputContainer>
        )}
      </PlacesAutocomplete>
      <QuestionSubtitle hasError={hasError}>{errorMessage}</QuestionSubtitle>
    </QuestionContainer>
  );
};

export default AddressSearchInput;
