import { ComponentSingleStyleConfig, CSSObject, theme } from '@chakra-ui/react';
import { getDarkColor } from '../darkMode';

export const Input: ComponentSingleStyleConfig = {
  variants: {
    auth: (props): CSSObject => ({
      ...theme.components.Input.variants.filled(props),
      width: '100%',
      height: '50px',
      backgroundColor:
        props.colorMode === 'light' ? 'gray.50' : getDarkColor('gray.50'),
      border: '1px solid #f0efe6',
      borderRadius: 2,
      padding: 3,
      _hover: {
        backgroundColor:
          props.colorMode === 'light' ? 'gray.100' : getDarkColor('gray.100'),
      },
    }),
  },
};

// width: 100%;
// height: 50px;
// background: #fafafc;
// border: 1px solid #f0efe6;
// box-sizing: border-box;
// border-radius: 8px;
// padding: 12px;
// outline: none;
// margin-bottom: 30px;
