import constants from '@/Constants';
import { SESRJReport } from '@/modules/pdf/sesrj';
import { EventReportData } from '@/modules/pdf/sesrj/types';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

export const ReportSESRJ: React.FC<{
  reportId: string;
  workspaceSlug: string;
}> = ({ reportId, workspaceSlug }) => {
  const [report, setReport] = useState<EventReportData | null>(null);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const url = constants.REPORTS_API_URL || '';

        const parsedUrl = url
          .toLowerCase()
          .replace('{{workspace}}', workspaceSlug);

        const { data, status } = await axios.get<EventReportData>(
          `${parsedUrl}pdf_json_by_export_id?export_id=${reportId}`,
          {
            headers: {
              Authorization: constants.REPORTS_API_AUTHORIZATION_TOKEN,
            },
          },
        );

        if (status === 200) {
          setReport(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchReport();
  }, [reportId, workspaceSlug]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <SESRJReport
        reportData={{
          body: report?.body || [],
          header: report?.header,
        }}
      />
    </div>
  );
};
