import { gql } from '@/gql';
import { OrderNames } from '@/gql/graphql';
import { createContext } from '@/utils/react-utils';
import { useQuery } from '@apollo/client';
import React from 'react';

export const formularySelectQuery = gql(`#graphql
  query FormularySelect($orderBy: OrderNames, $withoutGroup: Boolean) {
    allFormularyGroups(orderBy: $orderBy) {
      name
      id
      formularies {
        id
        name
        position
        enabled
      }
    }
    allFormularies(withoutGroup: $withoutGroup) {
      id
      name
      enabled
    }
  }
`);

export type FormularyGroup = {
  id: string;
  name: string;
  formularies: Array<{ id: string; name: string }>;
};

export function useFormularySelect(initialValues: string[]) {
  const [selected, setSelected] = React.useState<string[]>(initialValues);
  const [search, setSearch] = React.useState('');
  const { data, ...rest } = useQuery(formularySelectQuery, {
    variables: {
      withoutGroup: true,
      orderBy: OrderNames.NameAsc,
    },
  });

  const allForms = React.useMemo(() => {
    if (!data) return [];

    return [
      ...data.allFormularies
        .filter((e) => e.enabled)
        .map((e) => ({
          id: e.id,
          name: `${e.name}`,
        })),

      ...(
        data.allFormularyGroups.map((e) =>
          (e.formularies || [])
            .filter((e) => e.enabled)
            .map((e) => ({ id: e.id, name: `${e.name}` })),
        ) || []
      ).flat(),
    ];
  }, [data]);

  const selectedString = React.useMemo(() => {
    if (!selected.length) return null;

    const names = selected.slice(0, 10).reduce((prev, selected) => {
      const item = allForms.find((e) => e.id === selected);

      if (!item) return prev;

      return [...prev, item?.name];
    }, [] as string[]);
    return names.join(', ');
  }, [allForms, selected]);

  const sectionedData = React.useMemo(() => {
    if (!data) return [];

    const withoutSections: FormularyGroup = {
      id: 'no_section',
      name: 'Sem grupo',
      formularies: data.allFormularies
        .filter((e) =>
          search
            ? e.enabled &&
              e.name?.toLocaleUpperCase()?.includes(search.toLocaleUpperCase())
            : e.enabled,
        )
        .map((e) => ({
          id: e.id,
          name: `${e.name}`,
        })),
    };

    const sections: FormularyGroup[] = data.allFormularyGroups.map((group) => ({
      id: group.id,
      name: `${group.name}`,
      formularies:
        group.formularies
          ?.filter((e) =>
            search
              ? e.enabled &&
                e.name
                  ?.toLocaleUpperCase()
                  ?.includes(search.toLocaleUpperCase())
              : e.enabled,
          )
          .map((form) => ({
            id: form.id,
            name: `${form.name}`,
          })) || [],
    }));

    return [withoutSections, ...sections];
  }, [data, search]);

  const toggleMultiple = React.useCallback(
    (groupId: string, isIndeterminate?: boolean) => {
      const group = sectionedData.find((e) => e.id === groupId);

      if (!group) return;

      setSearch('');

      const ids = group.formularies.map((e) => e.id);

      setSelected((old) => {
        const includedIds = ids.some((e) => old.includes(e));

        if (isIndeterminate || !includedIds) {
          return [...old, ...ids];
        }

        return old.filter((e) => !ids.includes(e));
      });
    },
    [sectionedData],
  );

  const toggleItem = React.useCallback((item: string) => {
    setSelected((old) => {
      const idx = old.indexOf(item);

      setSearch('');

      if (idx >= 0) {
        return old.filter((e) => e !== item);
      }

      return [...old, item];
    });
  }, []);

  return {
    search,
    selected,
    setSelected,
    setSearch,
    sectionedData,
    toggleMultiple,
    allForms,
    toggleItem,
    selectedString,
    ...rest,
  };
}

export const [
  FormularySelectProvider,
  useFormularySelectContext,
] = createContext<ReturnType<typeof useFormularySelect>>();
