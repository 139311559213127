import styled from 'styled-components';
import { FiEye, FiEyeOff } from 'react-icons/fi';

export const container = styled.div``;

export const OpenIcon = styled(FiEye)`
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 12px;
`;

export const CloseIcon = styled(FiEyeOff)`
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 12px;
`;
