import config from '@/Constants';
import { HttpStatusCode } from '@/modules/shared/data/protocols/http';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';

import snakeToCamel from '@/utils/snakeToCamel';
import { TopicComment } from '../interfaces';

const mutationKey = config.ITEM_REVIEW_URL;

export interface FeedbackReviewCreateParams {
  discussionTopicId: string;
}

export const createFeedbackReview = async (
  params: FeedbackReviewCreateParams,
): Promise<TopicComment> => {
  const { body, statusCode } = await AxiosHelper.patch({
    url: `${mutationKey}/${params.discussionTopicId}`,
    timeout: 5000,
  });

  if (statusCode === HttpStatusCode.noContent) {
    return snakeToCamel(body);
  }
  throw new UnexpectedError();
};
