import React, { useCallback, useRef, useState } from 'react';
import Popup from 'reactjs-popup';

import moment from 'moment';
import Datepicker from '@/components_v2/DatePicker';
import { ContextMenuContainer } from '@/components_v2/ContextMenu';
import { InputDiv, Div } from '@/components_v2/inputs/BaseInput/styles';
import { PopupRef } from '@/components_v2/ContextMenu/interfaces';
import { useTranslation } from 'react-i18next';
import { PopoverDatePickerProps } from './interfaces';

const PopoverDatepicker: React.FC<PopoverDatePickerProps> = (props) => {
  const {
    onChangeDate,
    maxDate,
    minDate,
    selectedDate,
    disabled = false,
  } = props;
  const [currDate, setCurrDate] = useState<Date | undefined>(selectedDate);
  const popupRef = useRef<PopupRef>(null);
  const { t } = useTranslation();

  const handleChangeDate = useCallback(
    (newDate: Date[]) => {
      const [startOfDay] = newDate;
      setCurrDate(startOfDay);
      onChangeDate(startOfDay);
      if (popupRef.current) popupRef.current.close();
    },
    [onChangeDate],
  );

  const formattedDate = currDate
    ? moment(currDate).format('MMMM Do YYYY')
    : t('common.select_value');

  return (
    <Popup
      nested
      trigger={
        <InputDiv>
          <Div>{formattedDate}</Div>
        </InputDiv>
      }
      position={['bottom center']}
      closeOnDocumentClick
      arrow
      disabled={disabled}
      on={['click']}
    >
      <ContextMenuContainer>
        <Datepicker
          maxDate={maxDate}
          minDate={minDate}
          selectedDate={currDate && [currDate]}
          type="date"
          onChangeDate={handleChangeDate}
        />
      </ContextMenuContainer>
    </Popup>
  );
};

export default PopoverDatepicker;
