import React from 'react';
import Collapsible from 'react-collapsible';

import { FiChevronRight } from 'react-icons/fi';
import { CollapsibleWrapper, CollapsibleTrigger } from './styles';
import { CollapsibleRenderProps } from './interfaces';

const Main: React.FC<CollapsibleRenderProps> = ({
  title,
  isOpen,
  children,
  onClick,
  trigger: Trigger,
  contentStyle,
  wrapperStyle,
  isSelected,
  triggerSelectedIcon,
}) => {
  return (
    <CollapsibleWrapper
      hasBorderBottom={wrapperStyle?.hasBorderBottom}
      contentStyle={contentStyle}
    >
      <Collapsible
        transitionTime={100}
        open={isOpen}
        // eslint-disable-next-line prettier/prettier
        trigger={
          Trigger ? (
            <Trigger
              onClick={onClick ? () => onClick(isOpen) : undefined}
              isOpen={isOpen}
              isSelected={isSelected}
              SelectedIcon={triggerSelectedIcon}
            >
              {title}
            </Trigger>
          ) : (
            <CollapsibleTrigger
              onClick={onClick ? () => onClick(isOpen) : undefined}
              isOpen={isOpen}
            >
              {title}
              <FiChevronRight />{' '}
            </CollapsibleTrigger>
          )
        }
      >
        {children}
      </Collapsible>
    </CollapsibleWrapper>
  );
};

export default Main;
