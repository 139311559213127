import { QuestionTypeEnum } from '@/modules/core/domain/interfaces';
import {
  Answer,
  QuestionType,
} from '@/modules/core/domain/interfaces/IFormulary';
import {
  Answer as WebFormAnswer,
  AnswerStateMap,
} from '@/modules/WebForm/interfaces';

export type IFieldSerializer<T extends Record<string, any> = any> = (
  fieldId: string,
  answer: WebFormAnswer,
) => T;

export type PartialAnswer = PartialWithId<
  Pick<
    Answer,
    | 'id'
    | 'content'
    | 'questionId'
    | 'questionType'
    | 'collectionItemId'
    | 'comment'
    | 'answeredAt'
  >
>;

export type IFieldSerializerMap<T = PartialAnswer> = Record<
  string,
  IFieldSerializer<T>
>;

const DEFAULT_SERIALIZER: IFieldSerializerMap<any> = {
  default: (fieldId, { id, answeredAt, content, collectionItemId }) => {
    return {
      id,
      answeredAt,
      content,
      collectionItemId,
      questionId: fieldId,
    };
  },
};

type SerializeAnswersPayload<T> = {
  answers: AnswerStateMap;
  serializerMap?: IFieldSerializerMap<T>;
};

export type QuestionObject = {
  id: string;
  questionType: QuestionTypeEnum | QuestionType;
};

export function serializeFromFieldsToAnswers<T>(
  questions: QuestionObject[],
  params: SerializeAnswersPayload<T>,
): PartialAnswer[] {
  const { answers, serializerMap = {} } = params;
  const entries = Object.entries(answers);
  const state: PartialAnswer[] = [];

  const serializers: IFieldSerializerMap<T> = {
    ...DEFAULT_SERIALIZER,
    ...serializerMap,
  };

  for (let i = 0; i < entries.length; i++) {
    const [id, values] = entries[i];
    const field = questions.find((el) => el.id === id);
    const fieldType = field?.questionType || 'default';
    const serializer = serializers[fieldType] || serializers.default;

    if (values) {
      if (Array.isArray(values)) {
        for (let j = 0; j < values.length; j++) {
          state.push(serializer(id, values[j]) as any);
        }
      } else {
        state.push(serializer(id, values) as any);
      }
    }
  }

  return state.filter((el) => {
    return el !== undefined;
  });
}
