/* eslint-disable array-callback-return */
import { FragmentType, gql } from '@/gql';
import { useQuery } from '@apollo/client';

export const AutomationTicketFragment = gql(`#graphql
  fragment AutomationTicketFragment on Ticket {
    id
    ticketCode
    status
    createdAt
    lastModifiedAt
    automationFlowId
    updatedAt
    customCode
    automationFlow {
      showCustomCode
    }
    author {
      fullName
    }
    automationTicketProperties {
      id
      automationPropertyId
      answer {
        id
        formatContent
        content
        answerOptions
      }
    }
    automationTicketStepsTasks {
      id
      automationStepId
      lastModifiedAt
      status
      createdAt
      updatedAt
      task {
        name
        id
        status
        finishedAt
        event {
          id
          validated
        }
        member {
          id
          fullName
        }
        formularyId
      }
    }
    event {
      id
      finishedAt
      parentableId
      author {
        id
        fullName
      }
    }
  }
`);

export type AutomationTicketFragmentType = FragmentType<
  typeof AutomationTicketFragment
>;

export const queryAutomationTickets = gql(`#graphql
  query AutomationTickets(
    $filter: AutomationTicketFilter
    $orderBy: AutomationTicketOrderBy
    $pagination: PaginationAutomationTicketEnum
    $automationFlowId: ID!
  ) {
    automationTickets(
      filter: $filter
      orderBy: $orderBy
      pagination: $pagination
    ) {
      id
      ...AutomationTicketFragment
    }
    totalTicketCount(
      automationFlowId: $automationFlowId
      filter: $filter
    )
  }
`);

export type TicketPropetyAnswer = Nullable<{
  contents: string[];
  automationPropertyId: string;
}>;

export function useFetchTickets({
  flowId,
  pagination = { size: 15, page: 1 },
  search,
  macroFilters,
}: {
  flowId?: string;
  pagination?: { page: number; size: number };
  search?: string;
  macroFilters?: any;
}) {
  const { data, loading } = useQuery(queryAutomationTickets, {
    variables: {
      filter: {
        automationFlowId: `${flowId}`,
        search,
        macroFilters
      },
      pagination,
      automationFlowId: `${flowId}`,
    },
    pollInterval: 60000,
    skip: !flowId,
  });

  return {
    data: data?.automationTickets || null,
    ticketsTotal: data?.totalTicketCount,
    loading,
  };
}
