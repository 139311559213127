import { IConditional } from '@/modules/core/domain/interfaces/IConditionals';
import moment from 'moment';

export type ComparisonParams = IConditional['comparison'];

export type IComparisonFunc = (params: {
  comparison: ComparisonParams;
  current: number;
}) => boolean;

const runChecks: IComparisonFunc = ({ comparison }) => {
  if (!Array.isArray(comparison)) throw new Error('not array');
  if (!comparison.length) throw new Error('no comparison found');

  return true;
};

const lt: IComparisonFunc = (params) => {
  runChecks(params);
  const { comparison, current } = params;
  const [val] = comparison;
  return current < val;
};

const lte: IComparisonFunc = (params) => {
  runChecks(params);
  const { comparison, current } = params;
  const [val] = comparison;
  return current <= val;
};

const gt: IComparisonFunc = (params) => {
  runChecks(params);
  const { comparison, current } = params;
  const [val] = comparison;
  return current > val;
};

const gte: IComparisonFunc = (params) => {
  runChecks(params);
  const { comparison, current } = params;
  const [val] = comparison;
  return current >= val;
};

const eq: IComparisonFunc = (params) => {
  runChecks(params);
  const { comparison, current } = params;
  const [val] = comparison;
  return current === val;
};

const between: IComparisonFunc = (params) => {
  runChecks(params);
  const { comparison, current } = params;
  const [firstVal, secondVal] = comparison;
  return current > firstVal && current < secondVal;
};

const between_eq: IComparisonFunc = (params) => {
  runChecks(params);
  const { comparison, current } = params;
  const [firstVal, secondVal] = comparison;
  return current >= firstVal && current <= secondVal;
};

const from_now_lte: IComparisonFunc = (params) => {
  runChecks(params);
  const { comparison, current } = params;
  const [comparisonValue] = comparison;
  const parsedProvidedDate = moment(new Date(current)).startOf('day');

  const todayDate = moment().startOf('day');
  const diff = parsedProvidedDate.diff(todayDate, 'days');

  return diff <= comparisonValue;
};

const from_now_gte: IComparisonFunc = (params) => {
  runChecks(params);
  const { comparison, current } = params;
  const [comparisonValue] = comparison;
  const parsedProvidedDate = moment(new Date(current)).startOf('day');

  const todayDate = moment().startOf('day');
  const diff = parsedProvidedDate.diff(todayDate, 'days');
  return diff >= comparisonValue;
};

export const Comparison = {
  lt,
  gt,
  eq,
  gte,
  lte,
  between,
  between_eq,
  from_now_gte,
  from_now_lte,
};
