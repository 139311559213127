import { useReport } from './hooks/use-context';

export function Header() {
  const { header } = useReport();

  if (!header) return null;

  const formattedTitle = header.event_name?.split(/ - #/)[0];

  return (
    <div
      style={{
        marginBottom: '1.5rem',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: '2rem',
        color: 'black',
        pageBreakAfter: 'avoid',
      }}
    >
      <h1
        style={{
          fontSize: '1.875rem',
          fontWeight: 500,
          lineHeight: '1.25',
        }}
      >
        {formattedTitle}
      </h1>
      <h2
        style={{
          fontSize: '1.5rem',
          fontWeight: 500,
          lineHeight: '1.25',
        }}
      >
        RELATÓRIO ASSISTENCIAL - COMISAAFS
      </h2>
    </div>
  );
}
