/* eslint-disable no-underscore-dangle */

import { Formulary } from '@/modules/core/domain/interfaces/IFormulary';
import { HttpResponse, IApiHelper } from '@/modules/shared/data/protocols/http';
import {
  BaseApiCreateParams,
  ExportTypeEnum,
} from '@/modules/shared/domain/interfaces';

interface ExporterServiceInstanceParams {
  api: IApiHelper;
  baseUrl: string;
  exportType: ExportTypeEnum;
}

interface IExporterService {
  getTemplate: (extraParams?: string) => Promise<Formulary>;
  execute: (params: BaseApiCreateParams) => Promise<HttpResponse<any>>;
}

class ExporterServiceUseCase implements IExporterService {
  private _api: IApiHelper;
  private _baseUrl: string;
  private _exportType: ExportTypeEnum;
  private _exporterTemplate: Formulary;
  constructor(params: ExporterServiceInstanceParams) {
    const { api, baseUrl, exportType } = params;
    this._api = api;
    this._baseUrl = baseUrl;
    this._exportType = exportType;
  }

  public async getTemplate(extraParams?: string): Promise<Formulary> {
    if (this._exporterTemplate) return this._exporterTemplate;
    const response = await this._api.get({
      url: `${this._baseUrl}/new?export_type=${this._exportType}${
        extraParams ? `${extraParams}` : ''
      }`,
    });
    this._exporterTemplate = response.body;
    return response.body;
  }

  public async execute(
    params: BaseApiCreateParams,
  ): Promise<HttpResponse<any>> {
    const response = await this._api.post({
      url: `${this._baseUrl}`,
      body: {
        export_type: this._exportType,
        ...params,
      },
    });
    return response;
  }
}

export { ExporterServiceUseCase };
export type { ExporterServiceInstanceParams, IExporterService };
