import { HttpStatusCode } from '@/modules/shared/data/protocols/http';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { useToast } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

async function exportBatchPDFS(eventIds: string[]) {
  const response = await AxiosHelper.post({
    url: 'exports_create_pdfs',
    body: {
      export_type: 'pdf',
      parentable_type: 'events',
      event_ids: eventIds,
    },
  });

  if (response.statusCode !== HttpStatusCode.noContent) {
    throw new UnexpectedError();
  }

  return response;
}

export function useBatchPDFExporter() {
  const { t } = useTranslation();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);

  const execute = React.useCallback(
    async (ids: string[]) => {
      //
      try {
        setLoading(true);
        await exportBatchPDFS(ids);
        toast({
          status: 'success',
          title: t('common.success'),
          description: 'Os downloads serão adicionados na fila',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        if (error instanceof Error) {
          toast({
            title: 'Error',
            description: error.message,
            status: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [t, toast],
  );
  //

  return [execute, { loading }] as const;
}
