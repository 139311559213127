/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import { Header } from './header';
import { ReportContext } from './hooks/use-context';
import { InitialInfos } from './initial-infos';
import { Sieving } from './sieving';
import type {
  EventReportData,
  ParsedQuestions,
  QuestionsReporData,
} from './types';

export function GencauReport(props: { reportData: EventReportData }) {
  const QUESTIONS_MAP: any = {
    'd8f10f7b-1a05-4eba-a344-4fb09f95d05f': 'date',
    '1e6582e8-940d-44b6-ab67-5ef45d5a5f4f': 'hour',
    '99b02431-04d2-49a0-a663-40258ac683cb': 'cpf',
    '7e0c8638-29fb-495d-b7dd-05adf7a4e208': 'cnpj',
    '2bc9da90-e20f-4a51-91bb-720c5cffda2d': 'note',
    '50cf47e5-f2af-4e05-8940-b45340e269ac': 'nf',
    '5ddf5e6c-b504-41bc-9c5d-4dc2a74be40d': 'purchase_order',
    '2908f5e2-0d64-44bf-966a-33c47ba03e6b': 'batch',
    'c4c50ae2-99d6-48bb-a001-8e959dfa3104': 'type',
    '0ea57cb4-6c3e-44a4-ba7b-0167c4e8016c': 'moisture',
    '88dc46d4-76b8-44d0-96f1-967042f787b4': 'bags_quantity',

    'af4106f6-1df3-4175-b926-6ebc00daae74': 'dust_images',
    'ceae36ae-0a6e-44cd-a4a1-85d84baec6dc': 'cocoa_images',
    'e4966d76-b8aa-4aa6-a2f8-2d27118c2b92': 'weighings_images',

    '8e79c8c1-c476-4fae-b97e-647c9d4965d3': 'net_weight',
    '6e917e9a-4eb1-41bc-9a3e-6eb4f7c7b171': 'gross_weight',
    'a4f7d59b-3d89-4f78-a46c-b36d8a1aaf2a': 'cocoa_weight',
    '23f88422-2eaf-47d3-ad94-baf771a47abd': 'dust_weight',

    'bbb19ea2-4a7b-4091-b8f5-d859d8b33163': 'seals',
    '384b8d23-52a9-4a8c-a303-365a997b7947': 'vehicle_image',
    '58e72e48-45a1-4f4c-bede-16c7fcccad27': 'responsible_signature',
  };

  const values = props?.reportData?.body?.reduce((acc, item) => {
    item.sections.map((section) =>
      section.questions.map((question) => {
        if (question?.question_id && QUESTIONS_MAP[question?.question_id])
          acc[QUESTIONS_MAP[question?.question_id]] = question;
      }),
    );
    return acc;
  }, [] as Array<any>);

  const parsedProps: QuestionsReporData = {
    header: props?.reportData?.header,
    body: values as ParsedQuestions,
  };
  return (
    <ReportContext value={parsedProps}>
      <div>
        <div
          style={{
            height: '90%',
            width: '100vw',
            flex: 1,
            backgroundColor: 'white',
            fontFamily: 'sans-serif',
          }}
        >
          <div
            style={{
              margin: '1rem auto',
              height: 'auto',
              maxWidth: '64rem',
            }}
          >
            <Header />
            <div
              style={{
                height: '100%',
                width: '100%',
                borderRadius: '0.5rem',
                backgroundColor: '#D0E7E2',
                padding: '0.5rem',
              }}
            >
              <h1
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  lineHeight: '1.25',
                  color: '#495163',
                }}
              >
                {props?.reportData?.header?.event_name}
              </h1>
              <div
                style={{
                  marginTop: '0.5rem',
                  width: '100%',
                  borderRadius: '0.5rem',
                  backgroundColor: '#FEF2F2',
                  padding: '0.5rem',
                }}
              >
                <InitialInfos />
                <Sieving />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReportContext>
  );
}
