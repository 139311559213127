import React from 'react';

import { useEventItems } from './useEventItems';
import { useInteractions } from './useInteractions';

// import { Container } from './styles';

export const useSelectedForm = () => {
  const { items } = useEventItems();
  const { selectedFormId } = useInteractions();

  const form = React.useMemo(() => items.find((e) => e.id === selectedFormId), [
    items,
    selectedFormId,
  ]);
  return form;
};
