import { HttpStatusCode } from '@/modules/shared/data/protocols/http';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { QUERY_KEYS } from '../queryKeys';

export type DeleteAttachmentParams = {
  id: string;
};

export async function deleteAttachment(
  params: DeleteAttachmentParams,
): Promise<boolean> {
  const response = await AxiosHelper.delete({
    url: `${QUERY_KEYS.attachments}/${params.id}`,
  });

  if (response.statusCode !== HttpStatusCode.deletedSuccessfully) {
    throw new UnexpectedError();
  }

  return response.statusCode === HttpStatusCode.deletedSuccessfully;
}
