import { gql } from '@/gql';
import { FlowsUpdateInput } from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import React from 'react';

import { queryCreateAutomationGroup } from './useCreateAutomationGroup';
import { queryAutomationFlow } from './useFetchFlow';

export const updateAutomationFlowMutation = gql(`#graphql
  mutation UpdateAutomationFlow($input: FlowsUpdateInput!) {
    updateAutomationFlow(input: $input) {
      errors
      automationFlow {
        id
      }
    }
  }
`);

export function useUpdateAutomationFlow() {
  const toast = useToast();
  const [mutate, payload] = useMutation(updateAutomationFlowMutation, {
    refetchQueries: [queryCreateAutomationGroup, queryAutomationFlow],
  });

  const updateAutomationFlow = React.useCallback(
    async (input: FlowsUpdateInput) => {
      const result = await mutate({
        variables: {
          input,
        },
      });

      const { data, errors } = result;

      if (data || errors) {
        toast({
          title: errors ? 'Criação do fluxo falhou' : 'Fluxo criado.',
          status: errors ? 'error' : 'success',
        });
      }
      return result;
    },
    [mutate, toast],
  );

  return {
    updateAutomationFlow,
    ...payload,
  };
}
