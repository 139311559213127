import { UserPermissionsType } from '@/modules/core/domain/models/user';

export enum RouteTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface IRouteData {
  title?: string;
  name: string;
  path?: string;
  type?: RouteTypes;
  recursive?: boolean;
  permissionNeeded?: UserPermissionsType;
  unfinished?: boolean;
  disabled?: boolean | false;
  hideStandardSubmenu?: boolean;
  isNew?: boolean;
  tag?: {
    label: string;
    color: string;
  };
  children?: {
    [name: string]: IRouteData;
  };
}
