import { useReport } from './hooks/use-context';

export function Header() {
  const { header } = useReport();

  if (!header) return null;

  return (
    <div
      style={{
        marginBottom: '1.5rem',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '2rem',
        color: 'black',
      }}
    >
      <h1
        style={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          lineHeight: '1.25',
          color: '#495163',
        }}
      >
        GENCAU AMAZÔNIA - RECEBIMENTO DE CACAU
      </h1>
      <img
        style={{
          height: 'auto',
          width: '11rem',
        }}
        src={header?.logo}
        alt="logo"
      />
    </div>
  );
}
