import { AuthStorageData } from '@shared/domain/interfaces';
import IAuthStorage from '@security/data/protocols/storage/IAuthStorage';

enum keys {
  token = '@TrackingTrade.WeCheck:token',
  user = '@TrackingTrade.WeCheck:user',
  workspace = '@TrackingTrade.WeCheck:workspace',
}

class LocalStorageAuthStorage implements IAuthStorage {
  public getAuthData(): AuthStorageData | null {
    const token = localStorage.getItem(keys.token);
    const user = localStorage.getItem(keys.user);
    const workspace = localStorage.getItem(keys.workspace);

    if (!token || !user || !workspace) {
      return null;
    }

    return { token, user: JSON.parse(user), workspace };
  }

  public clear(): void {
    localStorage.removeItem(keys.token);
    localStorage.removeItem(keys.user);
    localStorage.removeItem(keys.workspace);
  }

  public saveAuthData(params: AuthStorageData): void {
    localStorage.setItem(keys.token, params.token);
    localStorage.setItem(keys.user, JSON.stringify(params.user));
    localStorage.setItem(keys.workspace, params.workspace);
  }
}

export default LocalStorageAuthStorage;
