import Input from '@/components_v2/inputs/BaseInput';
import {
  QuestionContainer,
  QuestionHeader,
  QuestionSubtitle,
  QuestionTitle,
  InfoTooltip,
  TooltipContainer,
} from './styles';

export {
  Input,
  QuestionContainer,
  QuestionHeader,
  QuestionSubtitle,
  QuestionTitle,
  InfoTooltip,
  TooltipContainer,
};
