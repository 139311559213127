/* eslint-disable react/no-array-index-key */
import {
  Circle,
  Flex,
  HStack,
  Icon,
  List,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import { BiChevronRight } from 'react-icons/bi';
import { useEventItems } from '../../hooks/useEventItems';

import { useInteractions } from '../../hooks/useInteractions';
import { EventForm } from '../../interfaces';

const FormItem: React.FC<{ item: EventForm }> = ({ item }) => {
  const { selectedFormId, setCurrentForm, setCurrentItem } = useInteractions();
  const isSelected = React.useMemo(() => selectedFormId === item.id, [
    item.id,
    selectedFormId,
  ]);

  const onClick = React.useCallback(() => {
    const firstItem = item.items[0].id;
    setCurrentForm(item.id);
    setCurrentItem(firstItem);
  }, [item.id, item.items, setCurrentForm, setCurrentItem]);
  return (
    <HStack
      userSelect="none"
      onClick={onClick}
      align="center"
      p="3"
      w="full"
      rounded="md"
      role="group"
      fontWeight="semibold"
      transition=".15s ease"
      _hover={{
        bg: 'blackAlpha.100',
      }}
      bg={isSelected ? 'blackAlpha.100' : 'inherit'}
      borderBottomWidth="thin"
      justify="space-between"
      fontSize="md"
      cursor="pointer"
    >
      <VStack w="full" alignItems="flex-start">
        <VStack spacing="0" w="full" alignItems="flex-start">
          <Text wordBreak="break-all" noOfLines={1} fontSize="md">
            {item.formName}
          </Text>
          {!!item?.finishedAt && (
            <Text
              wordBreak="break-all"
              color="muted"
              noOfLines={1}
              fontSize="sm"
            >
              {moment(item?.finishedAt).format('LLLL')}
            </Text>
          )}
        </VStack>
      </VStack>
      {item?.items?.length > 1 && (
        <Circle bg="blue.500" size="7">
          <Text fontSize="xs" fontWeight="bold" color="white" pe="1px">
            {item?.items?.length >= 100 ? '+99' : item?.items?.length}
          </Text>
        </Circle>
      )}
      <Icon as={BiChevronRight} fontSize="md" />
    </HStack>
  );
};

export const SideMenu: React.FC = () => {
  const { items, isLoading } = useEventItems();

  const renderItems = React.useCallback(() => {
    if (isLoading) {
      return new Array(5)
        .fill(null)
        .map((_, idx) => (
          <Skeleton key={idx} bg="red" h="20" w="full" rounded="md" />
        ));
    }
    return items?.map((item) => <FormItem item={item} key={item.id} />);
  }, [isLoading, items]);

  return (
    <Flex
      ps="1"
      pt="6"
      h="calc(100vh - 60px)"
      overflow="auto"
      position="sticky"
      top="0"
      left="4"
      bg="white"
      w="260px"
      display={{ base: 'none', xl: 'inherit' }}
    >
      <Scrollbars autoHide>
        <List w="full" spacing={6}>
          {renderItems()}
        </List>
      </Scrollbars>
    </Flex>
  );
};
