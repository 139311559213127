import {
  collectionGroupsService,
  collectionsServices,
  dashboardsServices,
  dashboardSuperGroupsServices,
  dashboardTeamsService,
  dashboardUsersServices,
  formulariesServices,
  formularyGroupsService,
  localGroupsService,
  localsServices,
  schedulesServices,
  stubService,
  usersServices,
} from '@/modules/shared/adapters/ApiServices';
import BaseApiServices from '@/modules/shared/data/usecases/BaseApiServices';

import { ScreenEnum } from './interfaces';

const getApiServiceUtil = (screen: ScreenEnum): BaseApiServices => {
  switch (screen) {
    case 'formulary_groups':
      return formularyGroupsService;
    case 'local_groups':
      return localGroupsService;
    case 'collections':
      return collectionsServices;
    case 'collection_groups':
      return collectionGroupsService;
    case 'formularies':
      return formulariesServices;
    case 'locals':
      return localsServices;
    case 'schedules':
      return schedulesServices;
    case 'users':
      return usersServices;
    case 'dashboards':
      return dashboardsServices;
    case 'dashboard_super_groups':
      return dashboardSuperGroupsServices;
    case 'dashboard_teams':
      return dashboardTeamsService;
    case 'dashboard_users':
      return dashboardUsersServices;
    default:
      return stubService;
  }
};

export default getApiServiceUtil;
