import { IQuestionState, ISectionState } from '../types';

export const findQuestionFromSections = (
  sections: ISectionState[],
  questionId: string,
) => {
  let foundQuestion: IQuestionState | null = null;
  for (let index = 0; index < sections.length; index++) {
    const section = sections[index];
    foundQuestion =
      section.questions.find((el) => el.id === questionId) || null;

    if (foundQuestion) {
      break;
    }
  }
  return foundQuestion;
};
