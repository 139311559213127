/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-types */
import { createContext } from '@/utils/createContext';
import {
  useControllableState,
  useDisclosure,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { uniqBy } from 'lodash';
import React from 'react';
import { AriaListBoxProps } from 'react-aria';
import { AsyncListOptions, Selection, useAsyncList } from 'react-stately';

import { useTick } from './utils';

export type TBaseItem<T extends object = {}> = {
  id: string;
  name: string;
  data?: T;
};

export type UseDialogSelectProps<T extends object = {}> = Pick<
  AriaListBoxProps<T>,
  | 'selectionMode'
  | 'selectedKeys'
  | 'defaultSelectedKeys'
  | 'selectionBehavior'
  | 'disabledKeys'
> & {
  onSearch?: (value: string) => void;
  /** esta prop é relevante quando você tem um carregamento paginado de itens,
   * e os itens selecionados anteriomente só estiverem disponíveis em uma página
   * posterior.
   */
  initialOptions?: TBaseItem<T>[];
  confirmLabel?: React.ReactNode;
  onChange?: (value: TBaseItem<T>[]) => void;
  header?: React.ReactNode;
  fetchItems: AsyncListOptions<TBaseItem<T>, string>['load'];
  dialogProps?: UseDisclosureProps;
  onSelectionChange?: (keys: string[]) => void;
};

export type TRenderItem<T extends object = {}> = (
  item: TBaseItem<T>,
) => JSX.Element;

export function useDialogSelect<T extends object>(
  props: UseDialogSelectProps<T>,
) {
  /** gambiarra pra transacionar os items ao invés das keys apenas */
  const itemsCache = React.useRef(new Set<TBaseItem<T>>());
  const {
    fetchItems,
    defaultSelectedKeys,
    initialOptions,
    onChange,
    dialogProps: _dialogProps,
    onSelectionChange: _onSelectionChange,
    ...rest
  } = props;

  const [selectedKeys, setSelection] = useControllableState<Selection>({
    defaultValue: new Set(defaultSelectedKeys),
    onChange: (vals) =>
      _onSelectionChange?.(vals === 'all' ? [] : ([...vals] as string[])),
  });

  const tick = useTick();

  const _renderItem = React.useRef<TRenderItem<T>>((params) => (
    <>{params?.name}</>
  ));

  const setRenderItem = React.useCallback((fn: TRenderItem<T>) => {
    _renderItem.current = fn;
    tick();
  }, []);

  const list = useAsyncList<TBaseItem<T>>({
    load: async (state) => {
      const res = await fetchItems(state);
      if (res.items) {
        Array.from(res.items).forEach((item) => {
          itemsCache.current.add(item);
        });
      }
      return res;
    },
    initialSelectedKeys: defaultSelectedKeys,
  });

  const dialogProps = useDisclosure({
    ..._dialogProps,
    onClose() {
      list.setFilterText('');
      _dialogProps?.onClose?.();
    },
  });

  const selectedItems = React.useMemo(() => {
    const allOptions = [
      ...itemsCache.current,
      ...(initialOptions || []),
    ] as TBaseItem<T>[];
    const uniqueOptions = uniqBy(allOptions, (item) => item.id);

    if (selectedKeys === 'all') {
      return uniqueOptions;
    }

    const selectedKeysArray = Array.from(selectedKeys);
    return uniqueOptions.filter((item) => selectedKeysArray.includes(item.id));
  }, [initialOptions, selectedKeys]);

  const onSelectionChange = React.useCallback(
    (keys: Selection) => {
      setSelection(keys);
    },
    [setSelection],
  );

  const onConfirm = React.useCallback(() => {
    const listItems = [...itemsCache.current];

    const items = [...selectedKeys].reduce((prev, curr) => {
      const item = listItems.find((i) => i.id === curr);
      if (item) {
        return [...prev, item];
      }
      return prev;
    }, [] as TBaseItem<T>[]);

    onChange?.(items);

    dialogProps?.onClose();
  }, [dialogProps, onChange, selectedKeys]);

  return {
    ...rest,
    selectedKeys,
    onSelectionChange,
    list,
    setRenderItem,
    onConfirm,
    renderItem: _renderItem.current,
    selectedItems,
    dialogProps,
  };
}

export type UseDialogSelect = ReturnType<typeof useDialogSelect>;

export const [DialogSelectContext, useDialogSelectContext] = createContext<
  UseDialogSelect
>({
  name: 'DialogSelectContext',
  strict: true,
});
