import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import { Badge, Checkbox, CheckboxGroup, Flex, VStack } from '@chakra-ui/react';
import React from 'react';

import FormControlComponent from '../FormControlComponent';

const CheckboxContent: React.FC<QuestionModel> = ({ options }) => {
  const { error, onChange, fieldState } = useField();

  const value = React.useMemo(() => {
    if (fieldState) {
      if (Array.isArray(fieldState)) {
        return fieldState;
      }
      return [fieldState];
    }
    return [];
  }, [fieldState]);

  const handleChange = React.useCallback(
    (id: string, checked: boolean) => {
      if (checked) {
        onChange([...value, createAnswer(id)]);
      } else {
        onChange(value.filter((a) => a.content !== id));
      }
    },
    [onChange, value],
  );

  return (
    <Flex>
      <FormControlComponent isInvalid={error}>
        <CheckboxGroup colorScheme="brand" value={value.map((o) => o.content)}>
          <VStack alignItems="flex-start">
            {options.map((o) => {
              const isChecked = !!value.find((a) => a.content === o.id);
              return (
                <VStack alignItems="flex-start">
                  <Checkbox
                    key={o.id}
                    onChange={(e) => handleChange(o.id, e.target.checked)}
                    value={o.id}
                    isChecked={isChecked}
                  >
                    {o.name}
                  </Checkbox>
                  {o.alertMessage && isChecked && (
                    <Badge
                      rounded="md"
                      w="min-content"
                      fontSize="md"
                      fontWeight="bold"
                      colorScheme="orange"
                      variant="outline"
                    >
                      {o.alertMessage}
                    </Badge>
                  )}
                </VStack>
              );
            })}
          </VStack>
        </CheckboxGroup>
      </FormControlComponent>
    </Flex>
  );
};

export default CheckboxContent;
