/* eslint-disable no-await-in-loop */

import { FeedbackError } from '@/components/Feedback';
import Lightbox from '@/components/Lightbox';
import { formularyStorage } from '@/modules/shared/adapters/CloudStorage';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ImagePreview from './components/ImagePreview';
import UploadInput from './components/UploadInput';
import { DropzoneState, GalleryUploadProps } from './interfaces';

export const GalleryUpload: React.FC<GalleryUploadProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onChange, urls, setUrls, withPortal } = props;

  const [status, setStatus] = useState<DropzoneState>('EMPTY');
  const [progress, setProgress] = useState<number>();
  const { t } = useTranslation();
  const modalSize = useBreakpointValue({ base: 'full', lg: '2xl' });

  const setUploadProgress = useCallback((prog: number) => {
    setProgress(prog);
  }, []);

  const onUploadSuccessful = useCallback(
    (url: string) => {
      setStatus('UPLOADED');
      setUrls((prev) => {
        const newUrls = [...prev, url];
        onChange(newUrls);
        return newUrls;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  );

  const onRemove = React.useCallback(
    (index: number) => {
      setUrls((prev) => {
        const newUrls = prev
          .slice(0, index)
          .concat(prev.slice(index + 1, prev.length));
        onChange(newUrls);
        return newUrls;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  );

  const onLightbox = React.useCallback(
    (index: number) => {
      Lightbox.openLightbox(urls, index);
    },
    [urls],
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i];
        try {
          setStatus('UPLOADING');
          formularyStorage.uploadFile({
            file,
            setProgress: setUploadProgress,
            setFileDownloadUrl: (url) => onUploadSuccessful(url),
          });
        } catch (e) {
          if (e instanceof Error) {
            FeedbackError({
              mainText: t(`common.error`),
              subText: t(`error.${e.message}`),
            });
            setStatus('EMPTY');
          }
        }
      }
    },
    [t, setUploadProgress, onUploadSuccessful],
  );

  return (
    <>
      <Button colorScheme="brand" onClick={onOpen}>
        Anexar imagens
      </Button>

      <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Envie imagens</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <UploadInput
                isUploading={status === 'UPLOADING'}
                progress={progress}
                onDrop={onDrop}
                withPortal={withPortal}
              />
              <Text>Imagens na nuvem:</Text>
              <Wrap w="full">
                {urls.map((p, index) => (
                  <WrapItem>
                    <ImagePreview
                      url={p}
                      onRemove={() => onRemove(index)}
                      onOpen={() => onLightbox(index)}
                    />
                  </WrapItem>
                ))}
              </Wrap>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
