import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primaryFont};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex-direction: column;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  flex-direction: row;
  margin-top: 20px;
`;

export const DatepickerDiv = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  flex: 1;
  justify-content: center;
  margin: 0 10px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const RecurrenceSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const IntervalSelectorContainer = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const IntervalLabel = styled.div`
  margin-right: 10px;
  width: 40%;
`;
