/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { useFeedbackQuery } from '@/hooks/Feedback/useFeedbackQuery';
import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FeedbackActivities } from './Activities';
import { useCurrentFeedback } from './hooks/useCurrentFeedback';

type FeedbackDisplayProps = {
  id: string;
  renderHeader?: (topic: DiscussionTopic) => JSX.Element;
};
export const FeedbackDisplay: React.FC<FeedbackDisplayProps> = (props) => {
  const { renderHeader = () => <></> } = props;

  const { feedback } = useCurrentFeedback();

  return (
    <Flex flex="1" flexDir="column">
      <Flex
        w="full"
        borderBottomWidth="thin"
        h="20"
        alignItems="center"
        justifyContent="space-between"
        px="4"
      >
        {!!feedback && renderHeader(feedback)}
      </Flex>

      {!!feedback && <FeedbackActivities feedback={feedback!} />}
    </Flex>
  );
};
