import { toast, ToastOptions } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { Container, Subtitle, Title } from './styles';

export interface FeedbackProps {
  mainText: string;
  subText?: string;
  onClick?: () => void;
}

const toastConfig: ToastOptions = {
  position: 'bottom-left',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const renderNotification = (props: FeedbackProps) => {
  const { mainText, subText, onClick } = props;
  return (
    <Container onClick={onClick}>
      <Title>{mainText}</Title>
      {subText && <Subtitle>{subText}</Subtitle>}
    </Container>
  );
};

const FeedbackSuccess = (props: FeedbackProps): void => {
  toast.success(renderNotification(props), toastConfig);
};

const FeedbackError = (props: FeedbackProps): void => {
  toast.error(renderNotification(props), toastConfig);
};

const FeedbackInfo = (props: FeedbackProps): void => {
  toast.info(renderNotification(props), toastConfig);
};

export { FeedbackError, FeedbackInfo, FeedbackSuccess };
