import { registerFieldType } from '@/components_v2/HookForm';
import { CheckboxInput } from './CheckboxInput';
import { ChecklistInput } from './ChecklistInput';
import { DateInput } from './DateInput';
import { FileInput } from './FileInput';
import { LocationInput } from './LocationInput';
import { UserInput } from './UserInput';

const registerFields = () => {
  registerFieldType('datepicker', DateInput, {
    isControlled: true,
  });
  registerFieldType('file', FileInput, {
    isControlled: true,
  });
  registerFieldType('geolocation', LocationInput, {
    isControlled: true,
  });
  registerFieldType('user-picker', UserInput, {
    isControlled: true,
  });
  registerFieldType('react-select', CheckboxInput, {
    isControlled: true,
  });
  registerFieldType('inspection', ChecklistInput, {
    isControlled: true,
  });
};

export {
  DateInput,
  FileInput,
  LocationInput,
  UserInput,
  ChecklistInput,
  registerFields,
};
