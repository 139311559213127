/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { useActionCable } from '../actionCable';
import { useAuth } from './auth';
import {
  UserChangesListenerData,
  UserChangesListenerProviderProps,
} from './interfaces/userChangesListener';

export const UserChangesListenerContext = createContext<
  UserChangesListenerData
>({} as UserChangesListenerData);

export const UserChangesListenerProvider: React.FC<UserChangesListenerProviderProps> = ({
  children,
  userId,
}) => {
  const { updateUser } = useAuth();
  const { subscribe } = useActionCable();
  const [key, setKey] = useState(0);
  const unsubscriberRef = useRef(() => {});

  useEffect(() => {
    if (userId) {
      unsubscriberRef.current = subscribe(
        'userChanged',
        (data) => {
          setKey((previous) => ++previous);
          updateUser(data);
        },
        userId,
      );
    }
    return () => {
      if (userId) {
        unsubscriberRef.current();
      }
    };
  }, [subscribe, userId, updateUser]);

  return (
    <UserChangesListenerContext.Provider value={{ key }}>
      {children}
    </UserChangesListenerContext.Provider>
  );
};

export function useUserChangesListener(): UserChangesListenerData {
  const context = useContext(UserChangesListenerContext);

  if (!context) {
    throw new Error(
      'useUserChangesListener must be used whithin an UserChangesListenerProvider',
    );
  }

  return context;
}
