import { useCreateFormAutomationGroupModal } from '@/screens/Tickets/Dashboard/components/Forms/CreateFormAutomationGroup';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import { AddButton } from '../AddButton';
import FlowList from './FlowList';
import SectionTitle from './SectionTitle';

export function LeftDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { openForm } = useCreateFormAutomationGroupModal();

  return (
    <>
      <IconButton
        w="min-content"
        aria-label="open-menu"
        onClick={onOpen}
        icon={<Icon ml="0" as={() => <ChevronRightIcon />} />}
        display={{ base: 'flex', xl: 'none' }}
        position="absolute"
        borderRadius="15"
        zIndex={20}
        left="53"
      />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Flex flexDir="row">Fluxo de Tickets</Flex>
          </DrawerHeader>
          <Divider mt="1" mb="0" orientation="horizontal" />

          <DrawerBody>
            <Scrollbars autoHide>
              <HStack mb="3" ps="2" justify="space-between">
                <SectionTitle rightButton={<AddButton onClick={openForm} />}>
                  Grupos de fluxos
                </SectionTitle>
              </HStack>
              <FlowList />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
