import styled from 'styled-components';

export const Container = styled.div`
  padding-right: 30px;
  margin-bottom: 30px;
`;

export const Subtitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme }) => theme.colors.lightText};
  font-size: 0.75em;
  font-weight: 400;
  margin-bottom: 10px;
`;
