import _ from 'lodash';
import React from 'react';
import create from 'zustand';
import createContext from 'zustand/context';

import {
  Answer,
  AnswerStateMap,
  ErrorStateMap,
  FieldStateMap,
  IFormActions,
  IFormState,
} from '../interfaces';
import { calculateFields } from '../utils';
import { calculateNextVisible } from '../utils/calculateNextVisible';
import { invalidateFields } from '../utils/invalidateFields';
import {
  parseAllConditionals,
  parseFieldConditionals,
} from '../utils/parseFieldConditionals';
import { validateField } from '../utils/validateField';

const { Provider, useStore, useStoreApi } = createContext<
  IFormState & IFormActions
>();

type CreateStoreProps = {
  fields?: FieldStateMap;
  answers?: AnswerStateMap;
};

const createStore = ({ answers, fields }: CreateStoreProps) => () => {
  const store = create<IFormState & IFormActions>((set, get) => ({
    edited: false,
    eventFieldId: undefined,
    answers: {},
    fields: {},
    requiredFields: [],
    visibleFields: {},
    dependencyMap: {},
    alerts: {},
    errors: {},
    setFields: (payload: { fields: FieldStateMap }) => {
      const { fields } = payload;

      set((prev) => ({
        ...calculateFields(fields, prev.answers),
        fields,
        edited: false,
        answers: prev.answers,
        ...parseAllConditionals(prev),
      }));
    },
    setAnswers: (answers) => {
      set((old) => {
        const { fields, answers: oldAnswers } = old;

        const newState = {
          ...calculateFields(fields, answers),
          fields,
          answers: {
            ...oldAnswers,
            ...answers,
          },
        };

        const { alerts, errors } = parseAllConditionals(newState as IFormState);
        return {
          ...calculateFields(fields, answers),
          fields,
          alerts,
          errors,
          answers: {
            ...oldAnswers,
            ...answers,
          },
        };
      });
    },
    reset: ({ answers, fields }) => {
      set(() => ({
        ...calculateFields(fields, answers),
        fields,
        answers: {
          ...answers,
        },
      }));
    },
    clearAnswer: (fieldId: string) => {
      const state = get();

      const isRequired = state.requiredFields.some((el) => el === fieldId);

      const isValid = validateField(undefined, isRequired);

      const nextVisibleChildren = calculateNextVisible(state, {
        answer: undefined,
        currentFieldId: fieldId,
      });

      const visibleFields = {
        ...state.visibleFields,
        ...nextVisibleChildren,
      };

      const answers = _.omit(state.answers, [fieldId]);

      const invalidatedFields = invalidateFields({
        ...state,
        answers,
        visibleFields,
      });

      set(() => ({
        answers: invalidatedFields,
        visibleFields,
        edited: true,
        errors: {
          ...state.errors,
          [fieldId]: !isValid,
        },
      }));
    },
    onChangeAnswer: (fieldId: string, value: Answer | Answer[]) => {
      const state = get();

      const field = state.fields[fieldId];

      const isRequired = state.requiredFields.some((el) => el === fieldId);

      const isValid = validateField(value, isRequired);

      const nextVisibleChildren = calculateNextVisible(state, {
        answer: value,
        currentFieldId: fieldId,
      });

      const visibleFields = {
        ...state.visibleFields,
        ...nextVisibleChildren,
      };

      const answers = {
        ...state.answers,
        [fieldId]: value,
      };

      const conditionalResult = parseFieldConditionals(field, value);

      const invalidatedFields = invalidateFields({
        ...state,
        answers,
        visibleFields,
      });

      set(() => ({
        answers: invalidatedFields,
        visibleFields,
        edited: true,
        eventFieldId: fieldId,
        alerts: {
          ...state.alerts,
          [fieldId]: conditionalResult.alert,
        },
        errors: {
          ...state.errors,
          [fieldId]: !isValid || conditionalResult.error,
        },
      }));
    },
    validate: () => {
      const { visibleFields, requiredFields, answers } = get();
      const requiredAndVisible = requiredFields.filter(
        (field) => visibleFields[field],
      );

      const errors = requiredAndVisible.reduce((prev, curr) => {
        const answer = answers[curr];
        if (!validateField(answer, true)) {
          return {
            ...prev,
            [curr]: true,
          };
        }
        return prev;
      }, {} as ErrorStateMap);

      set(() => ({
        errors,
      }));
    },
  }));

  const { reset } = store.getState();

  if (fields || answers) {
    reset({
      fields: fields || {},
      answers: answers || {},
    });
  }

  return store;
};

export const WebFormProvider: React.FC<CreateStoreProps> = ({
  children,
  ...rest
}) => {
  const store = React.useMemo(() => createStore(rest), [rest]);
  return <Provider createStore={store}>{children}</Provider>;
};

export const useWebFormState = useStore;

export const useWebFormStore = useStoreApi;
