import { WebFormProvider } from '@/modules/WebForm/data/formState';
import {
  SyncStateProvider,
  useSyncState,
} from '@/modules/WebForm/hooks/useSyncState';
import useWebForm from '@/modules/WebForm/hooks/useWebForm';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import Loading from '../Loading';
import { FormView, Header, MobileNav, Sidebar } from './components';
import useCurrentSection from './hooks/useCurrentSection';
import useFormDetails from './hooks/useFormDetails';
import { useWebFormProps, WebFormPropsProvider } from './hooks/useWebFormProps';
import { WebFormProps } from './types';
import { parseFormularyToFields } from './utils/parseFormularyToFields';

const WebForm: React.FC = () => {
  const { noHeader, renderHeader } = useWebFormProps();
  const { isLoading } = useCurrentSection();
  const { isPushing: isSyncing } = useSyncState();
  const { formulary } = useFormDetails();
  const { setFields } = useWebForm();

  useEffect(() => {
    setFields(parseFormularyToFields(formulary));
  }, [formulary, setFields]);

  return (
    <Flex
      h="full"
      w="full"
      as="section"
      bg={useColorModeValue('gray.50', 'gray.700')}
      flexDir="column"
    >
      {!!renderHeader && renderHeader()}
      {!noHeader && !renderHeader && <Header />}
      <Flex flex="1" w="full" overflowY="hidden">
        <Sidebar display={{ base: 'none', xl: 'unset' }} />
        <Flex
          overflowY="auto"
          flexDir="column"
          h="full"
          w="calc(100% - 240px)"
          transition=".3s ease"
        >
          <Flex flex={1} as="main" position="relative">
            {isLoading || !formulary ? (
              <Loading />
            ) : (
              <>
                {isSyncing && <Loading position="top-right" />}
                <FormView />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <MobileNav />
    </Flex>
  );
};

export default (props: WebFormProps): JSX.Element => {
  const { itemId } = props;

  return (
    <WebFormPropsProvider {...props}>
      <WebFormProvider>
        <SyncStateProvider itemId={itemId}>
          <WebForm />
        </SyncStateProvider>
      </WebFormProvider>
    </WebFormPropsProvider>
  );
};
