import { ItemAttachment } from '@/modules/core/domain/interfaces/ItemAttachment';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import camelToSnake from '@/utils/camelToSnake';
import snakeToCamel from '@/utils/snakeToCamel';
import { QUERY_KEYS } from '../queryKeys';

export type FetchAttachmentsParams = {
  itemId: string;
  questionId?: string;
};

export type FetchAttachmentsPayload = {
  items: ItemAttachment[];
};

export type IFetchAttachments = (
  params: FetchAttachmentsParams,
) => Promise<FetchAttachmentsPayload>;

export const getFetchAttachmentsQueryKey = (params: FetchAttachmentsParams) =>
  `${QUERY_KEYS.attachments}?${new URLSearchParams(
    camelToSnake(params) as any,
  ).toString()}`;

export async function fetchAttachments(
  params: FetchAttachmentsParams,
): Promise<FetchAttachmentsPayload> {
  const queryKey = getFetchAttachmentsQueryKey(params);

  const response = await AxiosHelper.get<ItemAttachment[]>({
    url: queryKey,
  });

  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new UnexpectedError();
  }

  return {
    items: snakeToCamel(response.body),
  };
}
