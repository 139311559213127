/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-types */
import { useModals } from '@/hooks/ModalManager';
import { CustomIcons } from '@/screens/Workflow/utils/customIcons';
import { Button, chakra, IconButton } from '@chakra-ui/react';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StepSchema } from '../form-schemas';
import { AttrTable } from '../misc';
import { useActionForm } from '../sub-forms/action-form';
import { useStepFormContext } from '../use-step-form';

const actionLabel = {
  update_property: 'Atualizar propriedade do ticket',
  email_checklist: 'Enviar E-mail',
  email_user: 'Enviar e-mail para usuário',
  webhook: 'Webhook',
};

export function ActionTable() {
  const { automationFlowId } = useStepFormContext();
  const { t } = useTranslation();
  const modal = useModals();
  const { control } = useFormContext<StepSchema>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'actions',
    keyName: 'key',
  });

  const items = React.useMemo(() => fields.filter((item) => !item.destroy), [
    fields,
  ]);

  const openForm = useActionForm({
    onSubmit(value) {
      if (value.id) {
        const idx = fields.findIndex((c) => c.id === value.id);
        if (idx > -1) {
          update(idx, value as any);
        }
        return;
      }
      if (value.key) {
        const idx = fields.findIndex((c) => c.key === value.key);
        if (idx > -1) {
          update(idx, value as any);
        }
      } else {
        append({ ...value, _local: true } as any);
      }
    },
  });

  return (
    <AttrTable
      items={items}
      columns={[
        {
          label: 'Ação',
          render(item) {
            return <>{item.label}</>;
          },
        },
        {
          label: 'Tipo de ação',
          render(item) {
            return (
              <>{actionLabel[item.actionableType] || item.actionableType}</>
            );
          },
        },
        {
          label: '',
          render: (item) => (
            <chakra.div
              gap="2"
              display="flex"
              flexDir="row"
              justifyContent="flex-end"
            >
              <IconButton
                icon={<CustomIcons.TrashIcon />}
                onClick={() => {
                  modal.alert({
                    title: t('error.warning'),
                    body: t('messageDelete.ask'),
                    onConfirm() {
                      const idx = fields.findIndex((c) => c.id === item.id);

                      if (idx !== -1) {
                        if (item._local) {
                          remove(idx);
                        } else {
                          update(idx, { ...item, destroy: true });
                        }
                      }
                    },
                  });
                }}
                aria-label="delete"
              />
              <IconButton
                icon={<CustomIcons.PencilIcon />}
                onClick={() => {
                  openForm({ intitialValues: item, automationFlowId });
                }}
                aria-label="edit"
              />
            </chakra.div>
          ),
        },
      ]}
      footer={() => (
        <Button
          mt="4"
          leftIcon={<CustomIcons.AddIcon />}
          size="sm"
          float="right"
          onClick={() => openForm({ automationFlowId })}
          colorScheme="primary"
        >
          Adicionar ação
        </Button>
      )}
    />
  );
}
