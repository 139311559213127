import React from 'react';

import { useTranslation } from 'react-i18next';
import { Input } from '@chakra-ui/react';
import { ValidateCodeProps } from './localGeneric';
import { SubmitButton } from '../../components';
import { CommonContainer, Title, Description } from '../components';

const ValidateCode: React.FC<ValidateCodeProps> = ({
  handleSubmitCode,
  handleValidationCodeChange,
  validationCode,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <CommonContainer>
      <Title content={t('password_recovery.title')} />
      <Description
        content={t('password_recovery.code_validation_description')}
      />
      <Input
        variant="auth"
        value={validationCode}
        placeholder={t('password_recovery.validation_code')}
        autoCapitalize="none"
        onChange={handleValidationCodeChange}
        margin={0}
        size="lg"
      />
      <SubmitButton
        buttonContent={t('buttons.confirm')}
        callback={handleSubmitCode}
        style={{ margin: 0, marginTop: 15 }}
        disabled={!validationCode}
        isLoading={isLoading}
      />
    </CommonContainer>
  );
};

export default ValidateCode;
