import { useSurveyForm } from '@/modules/SurveyForm';
import Tooltip from '@components/Tooltip';
import debounce from '@utils/debounce';
import React, { useEffect, useState } from 'react';

import {
  InfoTooltip,
  Input,
  QuestionContainer,
  QuestionHeader,
  QuestionSubtitle,
  QuestionTitle,
  TooltipContainer,
} from '../common';
import { TextProps } from './interfaces';

const Text: React.FC<TextProps> = ({
  question,
  errors,
  initialValues,
  onChange,
  viewOnly = false,
}) => {
  const [currentText, setCurrentText] = useState(initialValues?.values || '');
  const { hasInheritedTitle } = useSurveyForm();
  const [debouncedText] = useState(() =>
    debounce(async (newText: string) => {
      onChange(newText);
    }, 200),
  );

  const hasInheritedText = hasInheritedTitle(question.id);

  const parsedText = hasInheritedText ? initialValues?.values : currentText;

  useEffect(() => {
    debouncedText(parsedText);
  }, [debouncedText, parsedText]);

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionTitle>{question.name}</QuestionTitle>
        {question.tipText && (
          <Tooltip description={question.tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      <Input
        disabled={viewOnly || hasInheritedText}
        type="text"
        onChange={(e) => {
          setCurrentText(e.target.value ?? '');
          debouncedText(e.target.value);
        }}
        hasError={!!errors}
        defaultValue={initialValues?.values}
        value={parsedText}
      />
      <QuestionSubtitle hasError={!!errors}>
        {errors && errors.errors}
      </QuestionSubtitle>
    </QuestionContainer>
  );
};

export default Text;
