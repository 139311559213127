import { useModals } from '@/hooks/ModalManager';
import { EventStatus } from '@/modules/core/domain/interfaces';
import { Button, VStack } from '@chakra-ui/react';
import React from 'react';

import { useChecklistSummary } from '../../useChecklistSummary';
import { useTranslation } from 'react-i18next';

type TaskActionsProps = {
  onClose: () => void;
  openChecklist?: () => void;
  disabled?: boolean;
};

const TaskActions: React.FC<TaskActionsProps> = ({
  openChecklist,
  disabled = false,
}) => {
  const {
    data,
    isLoading,
    handleStart,
    continueChecklist,
    handleFinish,
  } = useChecklistSummary();
  const { t } = useTranslation();
  const modals = useModals();

  if (!data) return null;

  if (data.status === 'done') {
    return (
      <Button
        isLoading={isLoading}
        onClick={openChecklist}
        colorScheme="purple"
        w="full"
      >
        {t('buttons.view_report')}
      </Button>
    );
  }

  if (!data.event) {
    return (
      <Button
        disabled={disabled || isLoading}
        isLoading={isLoading}
        colorScheme="green"
        w="full"
        onClick={handleStart}
      >
        {t('buttons.start_checklist')}
      </Button>
    );
  }

  switch (data.event?.status) {
    case EventStatus.PENDING:
      return (
        <Button
          disabled={disabled || isLoading}
          isLoading={isLoading}
          colorScheme="green"
          w="full"
          onClick={handleStart}
        >
          {t('buttons.start_checklist')}
        </Button>
      );

    case EventStatus.DRAFT:
      return (
        <Button
          disabled={disabled || isLoading}
          isLoading={isLoading}
          onClick={continueChecklist}
          colorScheme="orange"
          w="full"
        >
          {t('buttons.resume_checklist')}
        </Button>
      );

    case EventStatus.DONE:
      return (
        <VStack w="full">
          <Button
            disabled={disabled || isLoading}
            isLoading={isLoading}
            onClick={continueChecklist}
            colorScheme="purple"
            variant="outline"
            w="full"
          >
            {t('buttons.review_checklist')}
          </Button>
          <Button
            disabled={disabled || isLoading}
            isLoading={isLoading}
            onClick={() => {
              modals.confirm({
                title: 'Deseja finalizar esta tarefa?',
                body: 'Esta ação é irreversível.',
                confirmLabel: 'Sim',
                cancelLabel: 'Continuar editando',
                onConfirm: handleFinish,
              });
            }}
            colorScheme="green"
            w="full"
          >
            {t('buttons.finish_checklist')}
          </Button>
        </VStack>
      );
    default:
      return null;
  }
};

export default TaskActions;
