import { useAutomationFlow } from '@/screens/Tickets/context/automationFlow';
import { HStack, IconButton, Select, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const TicketsListPages: React.FC = () => {
  const { t } = useTranslation();
  const {
    tickets,
    ticketsTotal,
    pagination,
    prevPage,
    nextPage,
    setSize,
    isLoading,
  } = useAutomationFlow();

  const hasPrevPage = React.useMemo(() => pagination.page > 1, [
    pagination.page,
  ]);

  const hasNextPage = React.useMemo(
    () => !isLoading && tickets?.length === pagination.size,
    [isLoading, pagination.size, tickets?.length],
  );

  const pageLength = tickets ? tickets.length : 0;

  return (
    <HStack
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      px={6}
      py={2}
      bg="white"
      borderRadius="4px"
      style={{ marginTop: 'auto' }}
    >
      <HStack>
        <Text whiteSpace="nowrap">{t('tasks.lines_per_page')}</Text>
        <Select
          value={pagination.size}
          onChange={(e) => setSize(parseInt(e.target.value, 10))}
          size="sm"
        >
          <option>15</option>
          <option>30</option>
          <option>45</option>
        </Select>
      </HStack>
      <HStack>
        <Text color="gray.500">
          {isLoading
            ? t('common.loading')
            : `${
                pagination.size < pageLength
                  ? pagination.page * pagination.size - pageLength + 1
                  : pagination.page * pagination.size - pagination.size + 1
              } - ${
                ticketsTotal && pagination.page * pagination.size > ticketsTotal
                  ? ticketsTotal
                  : pagination.page * pagination.size
              } de ${ticketsTotal}`}
        </Text>
        <IconButton
          disabled={!hasPrevPage}
          cursor={hasPrevPage ? 'pointer' : 'unset'}
          size="xs"
          variant="ghost"
          aria-label="pagina anterior"
          as={FiChevronLeft}
          onClick={() => {
            if (hasPrevPage) prevPage();
          }}
        />
        <IconButton
          disabled={!hasNextPage}
          cursor={hasNextPage ? 'pointer' : 'unset'}
          size="xs"
          variant="ghost"
          aria-label="proxima pagina"
          as={FiChevronRight}
          onClick={() => {
            if (hasNextPage) nextPage();
          }}
        />
      </HStack>
    </HStack>
  );
};

export default TicketsListPages;
