import { FeedbackError } from '@/components/Feedback';
import config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { EventWithItems, Task } from '@/screens/registrations/Tasks/interfaces';
import snakeToCamel from '@/utils/snakeToCamel';

async function startTaskEvent(
  task: PartialWithId<Task>,
): Promise<EventWithItems> {
  const req = {
    parentableType: 'Task',
    parentableId: task.id,
    formularyIds: [`${task.formulary_id}`],
  };
  const { statusCode, body } = await AxiosHelper.post<EventWithItems>({
    url: `${config.EVENTS_URL}`,
    body: req,
  });

  if (statusCode < 200 || statusCode > 300 || !body) {
    FeedbackError({ mainText: 'Falha ao iniciar tarefa' });
    throw new Error('Falha ao iniciar tarefa');
  }

  const parsed: EventWithItems = snakeToCamel(body);

  return parsed;
}

export { startTaskEvent };
