import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const Modal: ComponentSingleStyleConfig = {
  sizes: {
    '90': {
      content: {
        w: '90%',

        maxWidth: '90%',
      },
    },
  },
};
