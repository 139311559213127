import * as img from '@assets/undraw_Note_list_re_r4u9.png';
import { Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

import { useEventItems } from '../../hooks/useEventItems';
import { useInteractions } from '../../hooks/useInteractions';
import { FormDetails } from './FormDetails';
import { ItemFilters } from './ItemFilters';
import { SectionList } from './SectionList';

const EmptyState: React.FC = () => {
  return (
    <Flex
      borderRadius="lg"
      flexDir="column"
      borderWidth="thin"
      background="white"
      boxShadow="md"
      p="6"
      wordBreak="break-word"
      textAlign="center"
      alignItems="center"
      h="min-content"
    >
      <Text fontSize="2xl" fontWeight="bold">
        Este checklist ainda está sendo respondido...
      </Text>
      <Text fontSize="lg" color="muted">
        Não se preocupe. Em breve você verá todos os itens deste checklist.
      </Text>
      <Image mt="4" w="52" src={img.default} />
    </Flex>
  );
};

export const Content = () => {
  const { items, isLoading } = useEventItems();
  const { selectedItemId } = useInteractions();

  const noItems = React.useMemo(() => !items.length && !isLoading, [
    isLoading,
    items.length,
  ]);

  if (noItems && !selectedItemId) {
    return (
      <Flex
        justifyContent="center"
        alignItems="flex-start"
        h="calc(100% - 30px)"
        pt="20"
      >
        <EmptyState />
      </Flex>
    );
  }

  return (
    <Flex
      w={{ base: 'full', md: '770px' }}
      rounded="lg"
      borderWidth="thin"
      h="fit-content"
      minH="calc(100% - 30px)"
      mt="10"
      bg="gray.100"
      flexDir="column"
    >
      <ItemFilters />
      <FormDetails />
      <SectionList />
    </Flex>
  );
};
