import snakeToCamel from '@/utils/snakeToCamel';
import { useMutation, useQueryClient } from 'react-query';

import {
  createAttachment,
  FetchAttachmentsPayload,
  getFetchAttachmentsQueryKey,
} from '../services';

export function useItemAttachmentMutation() {
  const queryClient = useQueryClient();
  const mutation = useMutation(createAttachment, {
    onSuccess(data, { questionId, itemId }) {
      const queryKey = getFetchAttachmentsQueryKey({ itemId, questionId });

      const newItem = snakeToCamel(data.item);

      queryClient.setQueryData<FetchAttachmentsPayload | undefined>(
        queryKey,
        (old) => {
          if (!old) return { items: [newItem] };
          return {
            items: [newItem, ...old.items],
          };
        },
      );
    },
  });

  return mutation;
}
