import { FragmentType, gql } from '@/gql';
import { useAuth } from '@/hooks/Authentication/auth';
import { NotificationsTopics } from '@/hooks/Notifications/utils/NotificationsTopics';
import { createContext } from '@/utils/react-utils';
import { useQuery } from '@apollo/client';
import React from 'react';

export const importFragment = gql(`
  fragment ImportFragment on Import {
    id
    name
    progress
    status
    token
    updatedAt
    lastModifiedAt
    fileTemporaryUrl
    importType
    importErrors
    importWithErrors
    extraParams
    createdAt
    arrayErrors
    wrongCountColumns
    exceededMaxRows
    deletedAt
    dataContent
    createOrigin
    authorId
    author {
      id
      email
      fullName
    }
  }
`);

export const importsQuery = gql(`
  query Imports($after: String, $before: String, $first: Int, $last: Int) {
    imports(after: $after, before: $before, first: $first, last: $last) {
      edges {
        node {
          id,
          ...ImportFragment
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`);

export type ImportFragmentType = FragmentType<typeof importFragment>;

export function useImportsController() {
  const { user } = useAuth();
  const { data, fetchMore, loading, refetch, variables } = useQuery(
    importsQuery,
    {
      skip: !user?.id,
      fetchPolicy: 'cache-and-network',
      variables: {
        first: 50,
      },
    },
  );

  React.useEffect(() => {
    const unsub = NotificationsTopics.subscribeTopic('Import', () => {
      refetch({
        ...variables,
      });
    });

    return () => {
      unsub();
    };
  }, [refetch, variables]);

  const pageInfo = React.useMemo(() => data?.imports.pageInfo, [
    data?.imports.pageInfo,
  ]);

  const onFetchMore = React.useCallback(() => {
    if (!pageInfo?.hasNextPage) return;

    fetchMore({
      variables: {
        after: pageInfo.endCursor,
        first: 50,
      },
    });
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage]);

  const imports = React.useMemo(
    () => data?.imports?.edges!.map((e) => e.node!) || [],
    [data?.imports?.edges],
  );

  return {
    onFetchMore,
    pageInfo,
    loading,
    imports,
  };
}

type ImportsContextvalue = ReturnType<typeof useImportsController>;

export const [ImportContext, useImports] = createContext<ImportsContextvalue>();

export function ImportsProvider({ children }: { children?: React.ReactNode }) {
  return (
    <ImportContext value={useImportsController()}>{children}</ImportContext>
  );
}
