import { Icon } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaThumbsUp } from 'react-icons/fa';
import { ChecklistSummary } from '../../Checklists/interfaces';

export function useEventValidationProps(event?: ChecklistSummary) {
  const { t } = useTranslation();
  const validationProps = React.useMemo(() => {
    if (event?.validated) {
      return {
        label: t('checklists.validated'),
        icon: <Icon as={FaCheck} />,
        colorScheme: 'green',
      };
    }
    return {
      label: t('checklists.validate'),
      icon: <Icon as={FaThumbsUp} />,
      colorScheme: 'primary',
    };
  }, [event?.validated, t]);

  return {
    validationProps,
  };
}
