import { useAuth } from '@/hooks/Authentication/auth';
import { Avatar, Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

import { ActivityItemProps } from './types';

export const CommentItem: React.FC<ActivityItemProps> = ({ item }) => {
  const { user } = useAuth();

  const flexDirection = user?.id === item.authorId ? 'row-reverse' : 'row';

  const align = user?.id === item.authorId ? 'flex-end' : 'flex-start';

  const justify = user?.id === item.authorId ? 'flex-end' : 'flex-start';

  const background = user?.id === item.authorId ? 'blackAlpha.50' : 'purple.50';

  const borderColor =
    user?.id === item.authorId ? 'blackAlpha.100' : 'purple.100';

  const borderRadius =
    user?.id === item.authorId ? '10px 10px 0 10px' : '0 10px 10px 10px';

  return (
    <Flex w="full" justify={justify}>
      <Flex gap="4" flexDir={flexDirection} alignItems={align} role="group">
        <Avatar name={item?.authorName} size="md" />
        <Flex
          mt="1"
          bg={background}
          p="3"
          flexDir="column"
          minW="150px"
          maxW="250px"
          h="fit-content"
          borderWidth="thin"
          borderColor={borderColor}
          borderRadius={borderRadius}
        >
          <Text fontWeight="bold" color="purple.500">
            {item?.authorName}
          </Text>
          <Text>{item?.content}</Text>
          <Text color="muted" mt="2">
            {moment(item.createdAt).calendar()}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
