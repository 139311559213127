import config from '@/Constants';
import { DiscussionTopicStatusType } from '@/hooks/Feedback/interfaces';
import { HttpStatusCode } from '@/modules/shared/data/protocols/http';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { arrayReorder } from '@/utils/arrayReorder';
import camelToSnake from '@/utils/camelToSnake';
import snakeToCamel from '@/utils/snakeToCamel';
import { orderBy } from 'lodash';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

export const mutationKey = config.DISCUSSION_STATUS_TYPES_URL;
const updatePositionMutationKey = config.DISCUSSION_STATUS_TYPES_POSITION_URL;

export type StatusTypeCreateParams = {
  label: string;
  color: string;
  isDefault: boolean;
  position: number;
};

type MutationFn = (
  params: StatusTypeCreateParams,
) => Promise<DiscussionTopicStatusType>;

const createStatusType: MutationFn = async (params) => {
  const { body, statusCode } = await AxiosHelper.post({
    url: mutationKey,
    body: camelToSnake(params),
    timeout: 5000,
  });

  if (statusCode === 201) {
    return snakeToCamel(body);
  }
  throw new UnexpectedError();
};

const updateStatusType = async (
  params: Partial<StatusTypeCreateParams> & { id: string },
): Promise<DiscussionTopicStatusType> => {
  const { id, ...restParams } = params;
  const { body, statusCode } = await AxiosHelper.patch({
    url: `${mutationKey}/${id}`,
    body: camelToSnake(restParams as any),
    timeout: 5000,
  });

  const hasSuccess = statusCode === HttpStatusCode.ok;

  if (hasSuccess) {
    return snakeToCamel(body);
  }

  throw new UnexpectedError();
};

const deleteStatusType = async (params: { id: string }): Promise<boolean> => {
  const { statusCode } = await AxiosHelper.delete({
    url: `${mutationKey}/${params.id}`,
    timeout: 5000,
  });
  const success = statusCode === HttpStatusCode.deletedSuccessfully;
  if (success) {
    return true;
  }
  throw new UnexpectedError();
};

export function useUpdateStatusPosition() {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, error } = useMutation(
    async (params: { ids: string[] }) => {
      const { statusCode } = await AxiosHelper.patch({
        url: updatePositionMutationKey,
        body: params,
        timeout: 5000,
      });

      if (statusCode === HttpStatusCode.ok) {
        return true;
      }
      throw new UnexpectedError();
    },
  );

  const onUpdatePosition = React.useCallback(
    async (params: { origin: number; destination: number }) => {
      const queryData =
        queryClient.getQueryData<DiscussionTopicStatusType[]>([mutationKey]) ||
        [];

      const orderedItems = orderBy(queryData, 'position', 'asc');

      const { destination, origin } = params;
      const result = arrayReorder(orderedItems, origin, destination);

      const parsedWithPosition = result.map((item, index) => ({
        ...item,
        position: index + 1,
      }));

      queryClient.setQueryData(
        [config.DISCUSSION_STATUS_TYPES_URL],
        () => parsedWithPosition,
      );

      return mutateAsync({
        ids: parsedWithPosition.map((e) => e.id),
      });
    },
    [mutateAsync, queryClient],
  );

  return {
    onUpdatePosition,
    isLoading,
    isError,
    error,
  };
}

export function useDeleteStatusType() {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteStatusType, {
    onSuccess(data, variables) {
      queryClient.setQueryData<DiscussionTopicStatusType[]>(
        mutationKey,
        (old = []) => old.filter((e) => e.id !== variables.id),
      );
    },
  });
  return mutation;
}

export function useCreateStatusType() {
  const queryClient = useQueryClient();
  const mutation = useMutation(createStatusType, {
    onSuccess(data) {
      queryClient.setQueryData<DiscussionTopicStatusType[]>(
        mutationKey,
        (old = []) => [...old, data],
      );
    },
  });
  return mutation;
}

export function useUpdateStatusType() {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateStatusType, {
    onSuccess(data, variables) {
      queryClient.setQueryData<DiscussionTopicStatusType[]>(
        mutationKey,
        (old = []) =>
          old.map((e) => {
            if (e.id === variables.id) {
              return data;
            }
            return e;
          }),
      );
      queryClient.setQueryData<DiscussionTopicStatusType>(
        [mutationKey, variables.id],
        (old) => ({
          ...old,
          ...data,
        }),
      );
      queryClient.invalidateQueries(mutationKey);
    },
  });
  return mutation;
}
