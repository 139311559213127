/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/ban-types */

import {
  Box,
  chakra,
  ChakraProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';

export function Callout({
  children,
}: ChakraProps & { children: React.ReactNode }) {
  return (
    <Box maxW="100%" borderRadius="4px" p="4" bg="gray.100">
      <chakra.p>{children}</chakra.p>
    </Box>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type AttrTableProps<T extends object[]> = {
  items: T;
  columns: {
    label: string;
    render: (item: T[number], index: number) => JSX.Element;
  }[];
  footer: () => JSX.Element;
};

export function AttrTable<T extends object[]>(props: AttrTableProps<T>) {
  const { columns, footer, items } = props;

  return (
    <chakra.div overflow="clip" w="full" display="flex" flexDir="column">
      <chakra.div w="full" overflowX="auto" overflow="hidden">
        <TableContainer>
          <Table size="sm">
            <Thead width="100%" maxW="100%">
              <Tr fontSize="sm">
                {columns.map((column) => (
                  <Th fontSize="sm" key={column.label}>
                    {column.label}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {items.map((item, idx) => (
                <Tr fontSize="md" key={idx}>
                  {columns.map((column) => (
                    <Td overflow="hidden" fontSize="md" key={column.label}>
                      {column.render(item, idx)}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </chakra.div>
      <chakra.div>{footer()}</chakra.div>
    </chakra.div>
  );
}
