/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

export function useLocalStorage<T = any>(key: string) {
  const initialized = React.useRef(false);
  const [data, setData] = React.useState<T | undefined>(undefined);

  const getValue = React.useCallback(() => {
    let actualData: T | undefined;
    try {
      const rawData = localStorage.getItem(key);
      if (rawData) {
        actualData = JSON.parse(rawData) as T;
      }
    } catch (e) {
      //
    }

    return actualData;
  }, [key]);

  React.useLayoutEffect(() => {
    if (initialized.current) return;
    const rawData = getValue();
    if (rawData) {
      setData(rawData);
    }
    initialized.current = true;
  }, [getValue]);

  const onChange = React.useCallback(
    (fnOrValue: ((currVal: T | undefined) => void) | T) => {
      const actualValue = getValue();
      const nextValue =
        // @ts-expect-error
        typeof fnOrValue === 'function' ? fnOrValue(actualValue) : fnOrValue;

      localStorage.setItem(key, JSON.stringify(nextValue));
      setData(nextValue);
    },
    [getValue, key],
  );

  return [data, onChange] as const;
}
