import { gql } from '@/gql';
import { AutomationGroupsQuery } from '@/gql/graphql';
import { useQuery } from '@apollo/client';

export const queryAutomationGroups = gql(`#graphql
  query AutomationGroups {
    automationGroups {
      id
      name
      createdAt
      authorId
      automationFlows {
        id
        name
      }
    }
  }
`);

export type AutomationGroupItem = AutomationGroupsQuery['automationGroups'][number];

export function useFetchAutomationGroups() {
  const { data, loading } = useQuery(queryAutomationGroups);

  return { data: data?.automationGroups, loading };
}
