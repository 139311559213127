/* eslint-disable jsx-a11y/anchor-is-valid */

import LanguageSwitcher from '@/components_v2/LanguageSwitcher';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  VStack,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MoonLoader from 'react-spinners/MoonLoader';

import { SubmitButton } from '../components';
import {
  Container,
  Footer,
  FooterLinks,
  Logo,
  MainContent,
  Subtitle,
  Title,
} from '../styles';
import {
  WorkspaceSignInProps,
  WorkspaceValidationStatus,
} from './localGeneric';

const WorkspaceSignInComponent: React.FC<WorkspaceSignInProps> = ({
  workspaceName,
  handleOnChange,
  validWorkspace,
  handleSubmit,
}: WorkspaceSignInProps) => {
  const { t } = useTranslation();
  const renderInvalidAlert = useCallback(() => {
    if (validWorkspace === WorkspaceValidationStatus.INVALID) {
      return (
        <Alert
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          w="full"
          key={1}
          status="error"
          color="gray.600"
        >
          <AlertIcon />
          <AlertTitle>{t('auth.workspace_not_valid')}</AlertTitle>
          <AlertDescription>{t('auth.remember_workspace')}</AlertDescription>
          <Link href="#">{t('auth.previous_workspace')}</Link>
        </Alert>
      );
    }
    return null;
  }, [validWorkspace, t]);

  return (
    <Container onSubmit={handleSubmit}>
      <MainContent>
        <Logo />
        <Title>{t('auth.workspace_title')}</Title>
        <Subtitle>{t('auth.workspace_subtitle')}</Subtitle>
        <VStack minW="300px">
          {renderInvalidAlert()}
          <InputGroup size="lg">
            <Input
              marginY={2}
              variant="filled"
              value={workspaceName}
              bg="gray.200"
              color="gray.700"
              placeholder="workspace.wecheck.app"
              autoCapitalize="none"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleOnChange(e.currentTarget.value)
              }
            />
            {validWorkspace === WorkspaceValidationStatus.VALIDATING && (
              <InputRightElement h={14} pr={4} width={2}>
                <VStack h="100%" justifyContent="center">
                  <MoonLoader size={12} />
                </VStack>
              </InputRightElement>
            )}
          </InputGroup>
          <SubmitButton
            type="submit"
            buttonContent="Continue"
            disabled={
              validWorkspace !== WorkspaceValidationStatus.VALID ||
              workspaceName.length <= 2
            }
            callback={handleSubmit}
          />
        </VStack>
      </MainContent>
      <Footer>
        <FooterLinks
          href="https://wecheckapp.com.br/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('auth.privacy_and_terms')}
        </FooterLinks>
        <LanguageSwitcher>
          <FooterLinks>{t('auth.change_region')}</FooterLinks>
        </LanguageSwitcher>
        <FooterLinks
          href="https://wa.me/message/3YU6WYDCSTLHB1?src=qr"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('auth.contact_us')}
        </FooterLinks>
      </Footer>
    </Container>
  );
};

export default WorkspaceSignInComponent;
