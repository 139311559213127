import config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';

import { SyncContentState, SyncState } from '../interfaces/ISync';

async function createSyncState(
  itemId: string,
  syncContent?: SyncContentState,
): Promise<SyncState> {
  const response = await AxiosHelper.post({
    url: config.ITEM_SYNC_STATES_URL,
    body: {
      item_id: itemId,
      content_state: syncContent,
    },
  });
  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new Error();
  }
  return response.body;
}

export { createSyncState };
