import React from 'react';
import { criticalityMap } from '@/modules/circulabi/core';
import { useSignoffContext } from '@/modules/circulabi/hooks/useSignOff';
import { getContrastColor } from '@/utils/colors/getContrastColor';
import { chakra } from '@chakra-ui/react';

function getLeadtimeLabel(leadtime: 'high' | 'low') {
  return leadtime === 'high' ? 'Leadtime alto' : 'Leadtime baixo';
}

function getStockableLabel(stockable?: boolean) {
  return stockable ? 'Estocável' : 'Não estocável';
}

export function CriticalityLabel() {
  const { state } = useSignoffContext();
  if (!state.criticality) return null;

  const leadtimeLabel =
    state.criticality.score === criticalityMap.medium.score &&
    state.leadtimeResult
      ? getLeadtimeLabel(state.leadtimeResult)
      : undefined;

  const stockableLabel = getStockableLabel(state.stockable);

  return (
    <chakra.div
      bg={state.criticality.color}
      rounded="md"
      borderWidth="thin"
      padding="3.5"
      color={getContrastColor(state.criticality.color, 150)}
      fontWeight="bold"
    >
      <chakra.span>{state.criticality.label}</chakra.span>
      {!!leadtimeLabel && <chakra.span>{` - ${leadtimeLabel}`}</chakra.span>}
      {!!stockableLabel && <chakra.span>{` - ${stockableLabel}`}</chakra.span>}
    </chakra.div>
  );
}
