import { DiscussionTopicStatusType } from '@/hooks/Feedback/interfaces';
import { useStatusTypeValue } from '@/hooks/Feedback/useFeedbackStatusTypesQuery';
import { getContrastColor } from '@/utils/colors/getContrastColor';
import { getColor } from '@/utils/colors/theme';
import { Tag, TagProps, useTheme } from '@chakra-ui/react';
import { Dict } from '@chakra-ui/utils';
import React from 'react';

export type StatusTagProps = {
  status: DiscussionTopicStatusType;
} & TagProps;

const parseColors = (theme: Dict, color: string) => {
  const colorkey = `${color}.500`;
  const hexColor = getColor(theme, colorkey, color);
  const textColor = getContrastColor(hexColor);

  return {
    backgroundColor: hexColor,
    textColor,
  };
};

export const StatusTag: React.FC<StatusTagProps> = (props: StatusTagProps) => {
  const theme = useTheme();
  const { status, ...rest } = props;
  const { data } = useStatusTypeValue({
    initialValues: status,
  });

  const { backgroundColor, textColor } = parseColors(
    theme,
    data?.color || 'gray',
  );

  return (
    <Tag
      bg={backgroundColor}
      whiteSpace="nowrap"
      color={textColor}
      variant="solid"
      {...rest}
    >
      {data?.label}
    </Tag>
  );
};
