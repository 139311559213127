/* eslint-disable react/no-array-index-key */
import _ from 'lodash';

import { ItemContext, useItem, useReport } from './hooks/use-context';

function ItemHeader() {
  const { form_name, collection_item } = useItem();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem',
        borderBottom: '1px solid',
        backgroundColor: 'white',
        paddingBottom: '0.5rem',
      }}
    >
      <h1
        style={{
          fontWeight: 600,
        }}
      >
        Formulário: {form_name}
      </h1>
      {!!collection_item?.name && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '0.875rem',
            fontWeight: 400,
            color: '#4B5563',
          }}
        >
          <p>Item: {collection_item?.name}</p>
          <p>Coleção: {collection_item?.name}</p>
          <p>Item Code: {collection_item?.code}</p>
        </div>
      )}
    </div>
  );
}

export function FormularyItem({ children }: { children?: React.ReactNode }) {
  const item = useItem();
  return (
    <li
      style={{
        marginBottom: '1rem',
        overflow: 'hidden',
        borderRadius: '0.375rem',
      }}
      key={item.form_name}
    >
      <ItemHeader />
      <div>{children}</div>
    </li>
  );
}

export function ItemsList({ children }: { children?: React.ReactNode }) {
  const { body } = useReport();

  return (
    <ul style={{ width: '100%' }}>
      {_.orderBy(body, 'position', 'asc').map((item, idx) => (
        <ItemContext value={item} key={`${item.form_name}-${idx}`}>
          <FormularyItem>{children}</FormularyItem>
        </ItemContext>
      ))}
    </ul>
  );
}
