import { Mask, MaskType } from './types';
import useMaskedInput, { UseMaskedInputProps } from './useMaskedInput';

function getMaskByType(type: MaskType): Mask[] {
  switch (type) {
    case 'number':
      return ['NONE', 'BRL_CPF', 'BRL_CNPJ', 'BRL_PHONE', 'BRL_CEP'];
    case 'text':
      return [
        'NONE',
        'BRL_CAR_PLATE',
        'MERCO_SUL_CAR_PLATE',
        'NUMERIC_TEXT',
        'NUMERIC_TEXT_SPECIAL_CHARACTERS',
        'TEXT_ONLY',
        'ALPHANUMERIC_TEXT',
        'SPECIAL_CHARACTERS_ONLY',
        'EMAIL',
      ];
    case 'all':
    default:
      return [
        'NONE',
        'BRL_CPF',
        'BRL_CNPJ',
        'BRL_PHONE',
        'BRL_CEP',
        'BRL_CAR_PLATE',
        'MERCO_SUL_CAR_PLATE',
        'NUMERIC_TEXT',
        'NUMERIC_TEXT_SPECIAL_CHARACTERS',
        'TEXT_ONLY',
        'ALPHANUMERIC_TEXT',
        'SPECIAL_CHARACTERS_ONLY',
        'EMAIL',
      ];
  }
}

export type { UseMaskedInputProps };
export { getMaskByType, useMaskedInput };
