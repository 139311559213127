/* eslint-disable react/no-array-index-key */

import { Accordion, List, ListItem, Skeleton } from '@chakra-ui/react';
import { orderBy } from 'lodash';
import React from 'react';

import { useEventItemShow } from '../../hooks/useEventItemShow';
import { SectionItem } from './SectionItem';

export const SectionList: React.FC = () => {
  const { item, isLoading } = useEventItemShow();

  const sections = React.useMemo(
    () => orderBy(item?.sections || [], 'position', 'asc'),
    [item?.sections],
  );

  const renderSkeletons = React.useCallback(() => {
    if (!isLoading || item) return null;
    return new Array(10).fill(null).map((_, key) => (
      <ListItem key={key} mb="4" mx="6" mt="4">
        <Skeleton h="28" rounded="lg" />
      </ListItem>
    ));
  }, [isLoading, item]);

  return (
    <List flex="1">
      {renderSkeletons()}
      <Accordion w="full" defaultIndex={[0]} allowMultiple>
        {sections.map((section) => (
          <SectionItem section={section} key={section.position} />
        ))}
      </Accordion>
    </List>
  );
};
