/* eslint-disable react/react-in-jsx-scope */
import { useModals } from '@/hooks/ModalManager';
import {
  Alert,
  Box,
  Button,
  chakra,
  Flex,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

type DeleteConfirmationProps = {
  description?: React.ReactNode;
  itemName: string;
  buttonLabel: React.ReactNode;
  onConfirm: () => void;
};

function DeleteConfirmation({
  buttonLabel,
  itemName,
  onConfirm,
  onClose,
  description,
}: DeleteConfirmationProps & { onClose: () => void }) {
  const [confirmation, setConfirmation] = React.useState('');
  return (
    <Flex flexDir="column" w="full">
      <Alert
        p="3"
        bg="yellow.300"
        rounded="none"
        status="warning"
        variant="subtle"
        fontSize="lg"
        borderTopColor="yellow.400"
        borderBottomColor="yellow.400"
        borderTopWidth="1.5px"
        borderBottomWidth="1.5px"
      >
        Coisas ruins inesperadas acontecerão se você não ler isso!
      </Alert>
      <VStack spacing="3" w="full" p="4" textAlign="start">
        <Box color="mutedText" w="full" textAlign="start" fontSize="lg">
          <chakra.div>
            <chakra.span>{description}</chakra.span>
          </chakra.div>
          <chakra.div mt="4">
            <chakra.span>Digite</chakra.span>
            <chakra.span display="inline" fontWeight="bold">
              {`\n ${itemName} \n`}
            </chakra.span>
            <chakra.span>para confirmar.</chakra.span>
          </chakra.div>
        </Box>

        <VStack w="full" spacing="4">
          <Input
            size="sm"
            rounded="md"
            onChange={(e) => setConfirmation(e.target.value)}
          />
          <Button
            w="full"
            variant="subtle"
            color="red"
            border="1px"
            borderColor="gray.300"
            disabled={confirmation !== itemName}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {buttonLabel}
          </Button>
        </VStack>
      </VStack>
    </Flex>
  );
}

export function useDeleteConfirmation() {
  const modals = useModals();
  return (props: DeleteConfirmationProps) => {
    const onClose = () => modals.close('confirm-delete');
    modals.open({
      id: 'confirm-delete',
      title: 'Você tem certeza absoluta?',
      size: 'lg',
      children: <DeleteConfirmation onClose={onClose} {...props} />,
    });
  };
}
