import config from '@/Constants';
import { createContext } from '@/utils/react-utils';
import React from 'react';
import { useQuery } from 'react-query';

import { EventForm } from '../interfaces';
import { useEventSummary } from './useEventSummary';
import { useInteractions } from './useInteractions';

export type EventItemsContext = {
  items: EventForm[];
  isLoading?: boolean;
};

const [Context, useEventItems] = createContext<EventItemsContext>({
  name: 'ChecklistSummaryContext',
});

const EventItemsProvider: React.FC = ({ children }) => {
  const isFirstRender = React.useRef(true);
  const { setCurrentForm, setCurrentItem } = useInteractions();
  const { event } = useEventSummary();

  const { data = [], isLoading } = useQuery<EventForm[]>(
    `${config.ITEMS_URL}?eventId=${event?.id}`,
    {
      enabled: !!event?.id,
      refetchOnWindowFocus: false,
    },
  );

  React.useEffect(() => {
    if (data?.length && isFirstRender) {
      const { id, items } = data[0];
      const firstItemId = items[0].id;
      setCurrentForm(id);
      setCurrentItem(firstItemId);

      isFirstRender.current = false;
    }
  }, [data, setCurrentForm, setCurrentItem]);

  return (
    <Context
      value={{
        items: data,
        isLoading,
      }}
    >
      {children}
    </Context>
  );
};

export { useEventItems, EventItemsProvider };
