import { useModals } from '@/hooks/ModalManager';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  IconButton,
} from '@chakra-ui/react';
import React from 'react';

import { useJustificationsContext } from './hooks/context';
import { JustificationContext } from './hooks/useCurrentJustification';
import { JustificationDisplay } from './JustificationDisplay';

type JustificationViewDrawerProps = {
  id: string;
  onClose?: () => void;
  isOpen?: boolean;
};

export const JustificationViewDrawer: React.FC<JustificationViewDrawerProps> = (
  props,
) => {
  const { isOpen = false, onClose = () => null } = props;
  return (
    <Drawer isOpen={isOpen} size="lg" placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <JustificationDisplay
          id=""
          renderHeader={() => (
            <IconButton
              icon={<CloseIcon />}
              aria-label="close"
              variant="outline"
              size="md"
              onClick={onClose}
            />
          )}
        />
      </DrawerContent>
    </Drawer>
  );
};

/** TODO: REFACTOR */
export const JustificationViewContextProvider: React.FC<{ id: string }> = (
  props,
) => {
  const { id, children } = props;
  const { items } = useJustificationsContext();

  const currentJustification = items?.find((item) => item.id === id);

  if (!id) return null;

  return (
    <JustificationContext
      value={{
        item: currentJustification,
      }}
    >
      {children}
    </JustificationContext>
  );
};

export const useJustificationViewDrawer = () => {
  const modals = useModals();

  const open = React.useCallback(
    (topicId: string) => {
      const modalId = `feedback-${topicId}`;
      const onClose = () => modals.close(modalId);
      modals.drawer({
        id: modalId,
        size: 'lg',
        hideCloseButton: true,
        body: (
          <JustificationViewContextProvider id={topicId}>
            <JustificationDisplay
              id={topicId}
              renderHeader={() => (
                <IconButton
                  icon={<CloseIcon />}
                  aria-label="close"
                  variant="outline"
                  size="md"
                  onClick={onClose}
                />
              )}
            />
          </JustificationViewContextProvider>
        ),
      });
    },
    [modals],
  );

  return { open };
};
