import config from '@/Constants';
import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';

async function fetchDiscussionTopic(
  DiscussionTopicId: string,
): Promise<DiscussionTopic | null> {
  const response = await AxiosHelper.get<DiscussionTopic>({
    url: `${config.DISCUSSION_TOPICS_URL}/${DiscussionTopicId}`,
  });

  if (response.statusCode !== 200) return null;

  return response.body;
}

export default fetchDiscussionTopic;
