import {
  FrequencyType,
  WeekDayEnum,
} from '@/modules/core/domain/interfaces/IRecurrence';
import moment from 'moment';

import { RecurrenceTemplateType } from '../interfaces/IRecurrenceTemplates';

export const recurrenceTemplates: RecurrenceTemplateType = {
  daily: ({ dateStart = new Date(), dateEnd }) => ({
    typeRecurrent: FrequencyType.DAILY,
    ruleInterval: 1,
    // count: 39,
    dateStart: dateStart || moment().toDate(),
    dateEnd: dateEnd || moment().add(30, 'days').toDate(),
    weekStart: WeekDayEnum.MO,
    byWeekday: [],
    byMonthDay: null,
    byMonth: null,
  }),
  one_off: ({ dateStart = new Date(), dateEnd }) => ({
    typeRecurrent: FrequencyType.DAILY,
    ruleInterval: 2,
    // count: 39,
    dateStart: dateStart || moment().toDate(),
    dateEnd: dateEnd || moment().add(30, 'days').toDate(),
    weekStart: WeekDayEnum.MO,
    byWeekday: [],
    byMonthDay: null,
    byMonth: null,
  }),
  weekly: ({ dateStart = new Date(), dateEnd }) => ({
    typeRecurrent: FrequencyType.WEEKLY,
    ruleInterval: 1,
    // count: 39,
    dateStart: dateStart || moment().toDate(),
    dateEnd: dateEnd || moment().add(52, 'weeks').toDate(),
    weekStart: WeekDayEnum.MO,
    byWeekday: [moment(dateStart).weekday()],
    byMonthDay: null,
    byMonth: null,
  }),
  monthly: ({ dateStart = new Date(), dateEnd }) => ({
    typeRecurrent: FrequencyType.MONTHLY,
    ruleInterval: 1,
    // count: 39,
    dateStart: dateStart || moment().toDate(),
    dateEnd: dateEnd || moment().add(24, 'months').toDate(),
    weekStart: WeekDayEnum.MO,
    byWeekday: [],
    byMonthDay: null,
    byMonth: null,
  }),
  yearly: ({
    dateStart = new Date(),
    dateEnd = moment().add(5, 'years').toDate(),
  }) => ({
    typeRecurrent: FrequencyType.YEARLY,
    ruleInterval: 1,
    // count: 39,
    dateStart: dateStart || moment().toDate(),
    dateEnd,
    weekStart: WeekDayEnum.MO,
    byWeekday: [],
    byMonthDay: null,
    byMonth: moment(dateStart).month() + 1,
  }),
  custom: ({ dateStart = new Date(), dateEnd }) => ({
    typeRecurrent: FrequencyType.DAILY,
    ruleInterval: 2,
    // count: 39,
    dateStart: dateStart || moment().toDate(),
    dateEnd: dateEnd || moment().add(30, 'days').toDate(),
    weekStart: WeekDayEnum.MO,
    byWeekday: [],
    byMonthDay: null,
    byMonth: null,
  }),
};

export const generateTemplateOptions = (
  t?: (text: string) => string,
): {
  label: string;
  value: string;
}[] => {
  return Object.keys(recurrenceTemplates).map((el) => ({
    value: el,
    label: t ? t(`date.frequency_type.${el}`) : `date.frequency_type.${el}`,
  }));
};
