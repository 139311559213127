// arr -> item 1, item 2

interface ReduceArrayToListTextDTO {
  arr: any[];
  key?: string;
}

function accessItem(item: any, key?: string): string {
  let value = item;
  if (key) {
    value = item[key];
  }
  return `${value}`;
}

function reduceArrayToListText({ arr, key }: ReduceArrayToListTextDTO): string {
  let text = '';
  for (let i = 0; i < arr.length; i++) {
    const value = accessItem(arr[i], key);
    text += `${value}, `;
  }
  text.trim();
  if (text.length > 0) {
    return text.slice(0, text.length - 2);
  }
  return text;
}

export default reduceArrayToListText;
