import { UnexpectedError } from '@/modules/shared/domain/errors';
import { TaskWithEvent } from '../interfaces';
import { getEvent } from './getEvent';
import { getTask } from './getTask';

export async function getTaskAndEvent(taskId: string): Promise<TaskWithEvent> {
  const task = await getTask(taskId);

  if (!task) throw new UnexpectedError();

  if (!task.event_id) {
    return {
      ...task,
    };
  }

  const event = await getEvent(task.event_id);

  return {
    ...task,
    event,
  };
}
