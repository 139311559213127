import styled from 'styled-components';

type ContainerProps = {
  isContained?: boolean;
};

export const Container = styled.div<ContainerProps>`
  pointer-events: none;
  position: ${({ isContained }) => (isContained ? 'relative' : 'absolute')};
  background-color: ${({ isContained }) =>
    isContained ? null : 'rgba(255, 255, 255, 0.5)'};
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ isContained }) => (isContained ? '50px' : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  overflow: overlay;
`;
