import { gql } from '@/gql';
import { AnswerUpdateInput } from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import React from 'react';

import { queryAutomationAnswer } from './useFetchAnswer';

export const updateAnswerMutation = gql(`#graphql
  mutation UpdateAnswer($input: AnswerUpdateInput!) {
    updateAnswer(input: $input) {
      errors
    }
  }
`);

export function useUpdateAnswer() {
  const toast = useToast();
  const [mutate, payload] = useMutation(updateAnswerMutation, {
    refetchQueries: [queryAutomationAnswer],
  });

  const updateAnswer = React.useCallback(
    async (input: AnswerUpdateInput) => {
      const result = await mutate({
        variables: {
          input,
        },
      });

      const { data, errors } = result;

      if (data || errors) {
        toast({
          title: errors ? 'Edição da resposta falhou.' : 'Resposta alterada.',
          status: errors ? 'error' : 'success',
        });
      }
      return result;
    },
    [mutate, toast],
  );

  return {
    updateAnswer,
    ...payload,
  };
}
