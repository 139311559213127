import { ApiData } from '@/components/Importer/interfaces';
import {
  QueryParams,
  QueryResponse,
} from '@/modules/queryfilter/domain/interfaces';
import { IApiHelper } from '@shared/data/protocols/http/IApiHelper';
import {
  ApiObject,
  BaseApiCreateParams,
  BaseApiFetchOptionsParams,
  BaseApiTemplateParams,
  ImportTypeEnum,
} from '@shared/domain/interfaces';
import IApiServices from '@shared/domain/usecases/IApiServices';

import { HttpResponse } from '../protocols/http';

// TODO - CRIAR NOVA EXTENSAO DE IAPISERVICES
class ImporterService implements IApiServices {
  private api: IApiHelper;
  private url: string;
  private importType: ImportTypeEnum;

  constructor(apiHelper: IApiHelper, url: string, importType: ImportTypeEnum) {
    this.api = apiHelper;
    this.url = url;
    this.importType = importType;
  }

  public async fetch(params: QueryParams): Promise<QueryResponse<any>> {
    throw new Error('Method not implemented.');
  }

  public async getProperties<F>(): Promise<F> {
    throw new Error('Method not implemented.');
  }

  public async getTemplate(params?: BaseApiTemplateParams): Promise<ApiData> {
    const url = params?.optionsUrl
      ? `${this.url}/${params?.optionsUrl}`
      : `${this.url}/new?import_type=${this.importType}`;
    const response = await this.api.get({ url });
    return response.body;
  }

  public async create(params: BaseApiCreateParams): Promise<HttpResponse<any>> {
    const response = await this.api.post({
      url: `${this.url}`,
      body: {
        import_type: this.importType,
        ...params,
      },
    });
    return response;
  }

  public async edit(): Promise<ApiObject> {
    throw new Error('Not implemented');
  }

  public async update(): Promise<HttpResponse<any>> {
    throw new Error('Not implemented');
  }

  public async delete(): Promise<boolean> {
    throw new Error('Not implemented');
  }

  public async fetchOptions(params: BaseApiFetchOptionsParams): Promise<any> {
    const response = await this.api.get({ url: params.url });
    return response.body;
  }
}

export default ImporterService;
