import { gql } from '@/gql';
import { InternalRefetchQueriesInclude, useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const deleteBatchPublicTasks = gql(`#graphql
  mutation DeleteInBatchPublicTasks($input: DeleteInBatchPublicTasksInput!) {
    deleteInBatchPublicTasks(input: $input) {
      ids
    }
  }
`);

export function useDeletePublicTasksBatch({
  refetchQueries,
}: {
  refetchQueries?: InternalRefetchQueriesInclude;
} = {}) {
  const { t } = useTranslation();
  const toast = useToast();
  const [mutate, payload] = useMutation(deleteBatchPublicTasks, {
    refetchQueries,
  });

  const deleteTasks = React.useCallback(
    (ids: string[]) => {
      return mutate({
        variables: {
          input: {
            ids,
          },
        },
        onError(error) {
          toast({
            status: 'error',
            title: t('common.error'),
            description: error.message,
          });
        },
      });
    },
    [mutate, t, toast],
  );
  return [deleteTasks, payload] as const;
}
