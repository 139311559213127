import AxiosHelper from '@shared/infra/protocols/http/AxiosHelper';
import { AxiosResponse } from 'axios';

class ManageInterceptors {
  static create(
    onResponse?: (response: AxiosResponse<any>) => AxiosResponse<any>,
    onReject?: (error: any) => any,
  ): number {
    const id = AxiosHelper.createInterceptor(onResponse, onReject);
    return id;
  }

  static remove(id: number): void {
    AxiosHelper.removeInterceptor(id);
  }
}

export default ManageInterceptors;
