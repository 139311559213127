import React from 'react';

import { CellProps } from './interfaces';
import { Container, Title } from './styles';

const Cell: React.FC<CellProps> = (props) => {
  const { title, styleContainer } = props;
  return (
    <Container {...props} style={styleContainer}>
      <Title {...props}>{title}</Title>
    </Container>
  );
};

export default Cell;
