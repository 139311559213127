import config from '@/Constants';
import { createContext } from '@/utils/react-utils';
import React from 'react';
import { useQuery } from 'react-query';

import { ChecklistSummary } from '../../Checklists/interfaces';

type SummaryProps = {
  answerId: string;
};

export type AnswerSummaryProps = {
  answer?: ChecklistSummary;
  isLoading: boolean;
};

const [Context, useAnswerSummary] = createContext<AnswerSummaryProps>({
  name: 'AnswerSummaryContext',
});

const AnswerSummaryProvider: React.FC<SummaryProps> = (props) => {
  const { answerId, children } = props;
  const { data, isLoading } = useQuery<ChecklistSummary>(
    `${config.ANSWERS_URL}/${answerId}`,
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Context
      value={{
        answer: data,
        isLoading,
      }}
    >
      {children}
    </Context>
  );
};

export { useAnswerSummary, AnswerSummaryProvider };
