import { orderBy } from 'lodash';
import React from 'react';

import { ItemSection } from '../interfaces';
import { useInteractions } from './useInteractions';

export function useQuestions(section: ItemSection) {
  const { questions } = section;
  const { filter } = useInteractions();

  const orderedQuestions = React.useMemo(
    () =>
      orderBy(questions || [], 'position', 'asc').filter(
        (e) => e.questionType !== 'action_plan',
      ),
    [questions],
  );

  const filteredQuestions = React.useMemo(() => {
    if (filter === 'all') return orderedQuestions;
    if (filter === 'image')
      return orderedQuestions.filter((e) =>
        ['picture', 'gallery', 'signature'].includes(e.questionType),
      );
    if (filter === 'flagged')
      return orderedQuestions.filter((e) => e.answers.some((e) => e.isFlagged));
    if (filter === 'feedbacks')
      return orderedQuestions.filter((e) => !!e.discussionTopic?.id);

    return orderedQuestions;
  }, [filter, orderedQuestions]);

  return filteredQuestions;
}
