import React from 'react';

import { useTranslation } from 'react-i18next';
import { Input } from '@chakra-ui/react';
import { MainProps } from './localGeneric';
import { SubmitButton } from '../../components';
import { CommonContainer, Title, Description } from '../components';

const ChangePassword: React.FC<MainProps> = ({
  handlePasswordChange,
  handlePasswordConfirmationChange,
  handleUpdatePassword,
  password,
  passwordConfirmation,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <CommonContainer>
      <Title content={t('password_recovery.title')} />
      <Description content={t('password_recovery.change_password_description')}>
        <Description
          highlight
          content={t('password_recovery.change_password_alert')}
        />
      </Description>
      <Input
        value={password}
        placeholder={t('auth.password')}
        autoCapitalize="none"
        type="password"
        onChange={handlePasswordChange}
        m={0}
        size="lg"
      />
      <Input
        value={passwordConfirmation}
        placeholder={t('password_recovery.confirm_password')}
        autoCapitalize="none"
        type="password"
        onChange={handlePasswordConfirmationChange}
        mt={5}
        variant="auth"
        size="lg"
      />
      <SubmitButton
        buttonContent={t('buttons.confirm')}
        callback={handleUpdatePassword}
        style={{ margin: 0, marginTop: 15 }}
        disabled={!password || !passwordConfirmation}
        isLoading={isLoading}
      />
    </CommonContainer>
  );
};

export default ChangePassword;
