import { Criticality, criticalityMap } from '../core';
import { getLeadtimeResult } from './getLeadtimeResult';

export function getStockable(criticality: Criticality, leadtime?: number) {
  const { stockable } = criticality;

  if (criticality === criticalityMap.medium) {
    return typeof leadtime !== 'undefined'
      ? getLeadtimeResult(leadtime) === 'high'
      : false; // estocável se o leadtime for alto
  }

  return !!stockable;
}
