import { FeedbackError } from '@/components/Feedback';
import config from '@/Constants';
import { Answer } from '@/modules/core/domain/interfaces/IFormulary';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { PartialAnswer } from '../types';

export async function updateItem(
  itemId: string,
  answers: PartialAnswer[],
): Promise<boolean> {
  const url = `${config.ITEMS_URL}/${itemId}`;

  const response = await AxiosHelper.patch({
    url,
    body: {
      answers,
    },
  });
  //
  const { statusCode } = response;

  if (statusCode < 200 || statusCode >= 300) {
    throw new UnexpectedError();
  }

  return true;
}
