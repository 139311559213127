import React from 'react';

import { LoaderProps } from './localGeneric';
import { LoaderComponent } from './styles';

const Loader: React.FC<LoaderProps> = (props) => {
  return <LoaderComponent {...props} />;
};

export default Loader;
