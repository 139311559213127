import styled from 'styled-components';

type WithBackgroundColor = {
  backgroundColor: string;
};

type WithColor = {
  color: string;
};

export const TagContainer = styled.div<WithBackgroundColor & WithColor>`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 15px;
  margin-right: 5px;
  height: 22px;
  padding: 2px 10px;
  white-space: nowrap;
`;
