import { renderIcon } from '@/utils/button';
import { useTheme } from '@hooks/theme';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonProps } from './interfaces';
import { ButtonText, Container, IconLoading, IconX, Quantity } from './styles';

const Button: React.FC<ButtonProps> = ({
  callback,
  type,
  style,
  styleTitle,
  text,
  icon,
  disabled,
  isLoading,
  manualIcon,
  isExpansible,
  quantity,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const getLoadingIconColor = useCallback(() => {
    switch (type) {
      case 'PRIMARY_BACKGROUND':
        return colors.lighterText;
      case 'GREY_BACKGOUND':
        return colors.primary;
      case 'ITEM_LIST':
        return colors.darkText;
      default:
        return 'transparent';
    }
  }, [type, colors]);

  const renderContent = useCallback(() => {
    const typeText = t(`buttons.${type.valueOf().toLowerCase()}`);
    switch (type) {
      case 'ITEM_LIST':
        return (
          <Container
            style={{ whiteSpace: 'nowrap', ...style }}
            buttonType={type}
          >
            <ButtonText
              style={{ ...styleTitle, marginRight: 4 }}
              buttonType={type}
            >
              {text ? t(text) : typeText}
            </ButtonText>
            {isLoading ? (
              <IconLoading size={3} color={getLoadingIconColor()} loading />
            ) : (
              <IconX color="#333" onClick={() => callback()} />
            )}
          </Container>
        );
      default:
        return (
          <Container
            style={style}
            buttonType={type}
            onClick={(e) => (!isLoading ? callback(e) : null)}
            disabled={disabled}
            isExpansible={isExpansible}
          >
            {isLoading ? (
              <IconLoading size={3} color={getLoadingIconColor()} loading />
            ) : (
              <>
                {manualIcon || renderIcon(icon, '#333', 15)}
                <ButtonText
                  style={styleTitle}
                  buttonType={type}
                  className="visible-on-hover"
                >
                  {text ? t(text) : typeText}
                </ButtonText>
              </>
            )}
            {quantity && <Quantity className="quantity">({quantity})</Quantity>}
          </Container>
        );
    }
  }, [
    type,
    text,
    styleTitle,
    icon,
    style,
    disabled,
    isLoading,
    callback,
    getLoadingIconColor,
    t,
    manualIcon,
    isExpansible,
    quantity,
  ]);

  return renderContent();
};

export default Button;
