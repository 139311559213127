import { useToast } from '@chakra-ui/react';
import { orderBy } from 'lodash';
import React from 'react';
import create from 'zustand';
import createContext from 'zustand/context';

import { InspectionStore } from '../types';
import { useFetchFormulary } from './useFetchFormulary';

const { Provider, useStore, useStoreApi } = createContext<InspectionStore>();

const createStore = () =>
  create<InspectionStore>((set, get) => ({
    currentSection: '',
    formulary: undefined,
    isLoading: true,
    setSection: (sectionId) =>
      set(() => ({
        currentSection: sectionId,
      })),
    setForm(formulary) {
      const { formulary: oldForm } = get();
      if (oldForm?.id) return;
      if (formulary?.id) {
        formulary.sections = orderBy(
          formulary.sections,
          'position',
          'asc',
        ).filter((section) => section.enabled);
        set((draft) => {
          if (!formulary?.id) {
            return {
              ...draft,
              isLoading: false,
            };
          }

          const firstSection = formulary?.sections[0]?.id || '';

          return {
            ...draft,
            isLoading: false,
            currentSection: firstSection,
            formulary,
          };
        });
      }
    },
    setLoading(payload) {
      set(() => ({
        isLoading: payload,
      }));
    },
  }));

const WrappedProvider: React.FC<{ formularyId: string }> = ({
  children,
  formularyId,
}) => {
  const toast = useToast();
  const { data, isLoading, isError } = useFetchFormulary(formularyId);
  const { setForm, setLoading } = useStore(
    React.useCallback(
      ({ setLoading, setForm }) => ({
        setLoading,
        setForm,
      }),
      [],
    ),
  );

  React.useEffect(() => {
    if (data?.id && !isLoading) {
      setForm(data);
    }
  }, [data, isLoading, setForm]);

  React.useEffect(() => {
    setLoading(isLoading);
  }, [data, isLoading, setForm, setLoading]);

  React.useEffect(() => {
    if (isError) {
      toast({
        title: 'Ocorreu um erro...',
        description: 'Tente novamente.',
        status: 'error',
      });
    }
  }, [isError, toast]);

  return <>{children}</>;
};

export const InspectionStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <Provider createStore={createStore}>{children}</Provider>;
};

export const InspectionProvider: React.FC<{ formularyId: string }> = ({
  children,
  formularyId,
}) => {
  return (
    <InspectionStoreProvider>
      <WrappedProvider formularyId={formularyId}>{children}</WrappedProvider>
    </InspectionStoreProvider>
  );
};

export const useInspectionStore = useStore;

export const useInspectionStoreApi = useStoreApi;
