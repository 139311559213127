import constants from '@/Constants';
import { EventReport } from '@/modules/pdf/event-report';
import { EventReportResponse } from '@/modules/pdf/event-report/types';
import { GencauReport } from '@/modules/pdf/gencau';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

export const ReportGencau: React.FC<{
  reportId: string;
  workspaceSlug: string;
}> = ({ reportId, workspaceSlug }) => {
  const [report, setReport] = useState<EventReportResponse | null>(null);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const url = constants.REPORTS_API_URL || '';

        const parsedUrl = url
          .toLowerCase()
          .replace('{{workspace}}', workspaceSlug);

        const { data, status } = await axios.get<EventReportResponse>(
          `${parsedUrl}pdf_json_by_export_id?export_id=${reportId}`,
          {
            headers: {
              Authorization: constants.REPORTS_API_AUTHORIZATION_TOKEN,
            },
          },
        );

        if (status === 200) {
          setReport(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchReport();
  }, [reportId, workspaceSlug]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {report?.body?.[0]?.form_name ===
      'RECEBIMENTO DE CACAU - GENCAU AMAZÔNIA' ? (
        <GencauReport
          reportData={{
            body: report?.body,
            header: report?.header,
          }}
        />
      ) : (
        <EventReport
          reportData={{
            body: {
              items: report?.body || [],
            },
            header: report?.header,
          }}
        />
      )}
    </div>
  );
};
