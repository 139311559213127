import { FieldStateMap, IField } from '../interfaces';

export const filterFields = (
  allFields: FieldStateMap,
  predicate: (field: IField) => boolean,
): FieldStateMap => {
  return Object.entries(allFields).reduce((prev, [key, value]) => {
    let nextState = { ...prev };

    if (predicate(value)) {
      nextState = {
        ...nextState,
        [key]: value,
      };
    }

    return nextState;
  }, {});
};
