import React from 'react';

import {
  Box,
  Text,
  Square,
  HStack,
  List,
  ListItem,
  Image,
  chakra,
  IconButton,
  Icon,
  CircularProgressLabel,
  CircularProgress,
} from '@chakra-ui/react';
import prettyBytes from 'pretty-bytes';
import 'react-datepicker/dist/react-datepicker.css';
import '@/components_v2/WCDatePicker/date-picker.css';
import { FieldProps, registerFieldType } from '@/components_v2/HookForm';
import Scrollbars from 'react-custom-scrollbars';
import {
  BatchItem,
  FILE_STATES,
  useBatchAddListener,
  useBatchFinishListener,
  useBatchStartListener,
  useItemFinishListener,
  useItemProgressListener,
  useUploady,
} from '@rpldy/uploady';
import {
  CustomIcons,
  FILE_TYPE_ICONS,
} from '@/screens/Workflow/utils/customIcons';
import { Dropzone } from '@/components_v2/Dropzone';
import { withUploadProvider } from '@/hooks/upload/uploadProvider';
import { workflowStorage } from '@/modules/shared/adapters/CloudStorage';
import { useUploadStatus } from '../useUploadStatus';

const CUIScrollbars = chakra(Scrollbars);

type FileItemProps = {
  item: BatchItem;
  handleRemove?: (id: string) => void;
};

const imageMimetypes = ['image/*'];

const fileMimeTypes = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/csv',
  'application/x-csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/x-csv',
  'application/vnd.apple.numbers',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.apple.pages',
  ...imageMimetypes,
];

const FilePreview: React.FC<{ file: File }> = ({ file }) => {
  const getFileContent = React.useCallback(() => {
    switch (file.type) {
      case 'application/pdf':
        return <Icon as={FILE_TYPE_ICONS.pdf} fontSize="xl" />;
      case 'application/vnd.ms-excel':
      case 'application/csv':
      case 'application/x-csv':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'text/x-csv':
      case 'application/vnd.apple.numbers':
        return <Icon as={FILE_TYPE_ICONS.excel} fontSize="xl" />;
      case 'text/plain':
        return <Icon as={FILE_TYPE_ICONS.text} fontSize="xl" />;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      case 'application/vnd.apple.pages':
        return <Icon as={FILE_TYPE_ICONS.word} fontSize="xl" />;
      default:
        return <CustomIcons.FileIcon />;
    }
  }, [file]);

  if (file.type.startsWith('image/')) {
    return (
      <Image
        borderRadius="md"
        w="full"
        h="full"
        src={URL.createObjectURL(file)}
      />
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="full"
      w="full"
      ps="0.5"
    >
      {getFileContent()}
    </Box>
  );
};

const FileItem: React.FC<FileItemProps> = (props) => {
  const [itemState, setState] = React.useState<FILE_STATES>(
    FILE_STATES.PENDING,
  );
  const { item, handleRemove } = props;
  const { id, file } = item;

  const batchProgress = useItemProgressListener(item.id);

  useItemFinishListener((e) => {
    setState(e.state);
  }, item.id);

  return (
    <Box
      display="flex"
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
      w="full"
      color="muted"
    >
      <HStack flex="1">
        <Square size="10" bg="gray.50" borderRadius="md" borderWidth="thin">
          <FilePreview file={file as File} />
        </Square>
        <Box>
          <Text fontWeight="bold" fontSize="sm" noOfLines={1}>
            {file.name}
          </Text>
          <Text fontSize="sm">{prettyBytes(file.size)}</Text>
        </Box>
      </HStack>
      <Box>
        <IconButton
          colorScheme="red"
          variant="ghost"
          onClick={(e) => {
            e.stopPropagation();
            handleRemove?.(id);
          }}
          isDisabled={itemState === FILE_STATES.UPLOADING}
          icon={<CustomIcons.TrashIcon />}
          aria-label="delete"
        />
        <CircularProgress
          value={batchProgress?.loaded || 0}
          color="green.400"
          size="30px"
        >
          <CircularProgressLabel fontSize="10px">
            {Math.round(batchProgress?.loaded || 0)}
          </CircularProgressLabel>
        </CircularProgress>
      </Box>
    </Box>
  );
};

type FileListProps = {
  files: BatchItem[];
  handleRemove?: (id: string) => void;
};
const FileList: React.FC<FileListProps> = ({ files, handleRemove }) => {
  return (
    <Box h="44" w="full" overflow="auto">
      <CUIScrollbars autoHide size={1}>
        <List w="full" spacing="2" pe="3">
          {files.map((f) => (
            <ListItem key={f.id}>
              <FileItem item={f} handleRemove={handleRemove} />
            </ListItem>
          ))}
        </List>
      </CUIScrollbars>
    </Box>
  );
};

type FileInputProps = FieldProps & {
  hasError?: boolean;
  acceptMode: 'files' | 'images';
};

export const FileInputContent: React.FC<FileInputProps> = ({
  ...props
}): JSX.Element => {
  const { onChange, acceptMode = 'images' } = props;
  const { setIsBusy } = useUploadStatus();

  const urls = React.useRef<string[]>([]);

  const uploady = useUploady();
  const [files, setFiles] = React.useState<BatchItem[]>([]);

  useBatchAddListener((batch) => {
    setFiles((items) => items.concat(batch.items));
    onChange?.(undefined as any);
  });

  useBatchStartListener(() => {
    setIsBusy(true);
  });

  useBatchFinishListener((batch) => {
    const { items } = batch;
    urls.current = [...urls.current, ...items.map((e) => e.uploadResponse)];
    onChange?.(urls.current as any);
    setIsBusy(false);
  });

  const handleDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      uploady.upload(acceptedFiles);
    },
    [uploady],
  );

  const handleRemove = React.useCallback((id: string) => {
    setFiles((old) => old.filter((e) => e.id !== id));
  }, []);
  return (
    <Dropzone
      onDrop={handleDrop}
      hasFiles={!!files.length}
      accept={acceptMode === 'images' ? imageMimetypes : fileMimeTypes}
    >
      <FileList files={files} handleRemove={handleRemove} />
    </Dropzone>
  );
};

export const FileInput = withUploadProvider(workflowStorage)(FileInputContent);

registerFieldType('file', FileInput, {
  isControlled: true,
});
