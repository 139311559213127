import ExporterForm from '@/components_v2/Formularies/ExporterForm';
import { BaseExportData } from '@/hooks/RemoteFilesListener/interfaces';
import { FormularyAnswers } from '@/modules/core/domain/interfaces/IFormulary';
import { IExporterService } from '@/services/exporter';
import { ErrorHandler } from '@/services/formHandle';
import {
  Heading,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useToast,
} from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotificationToast } from '../WCNotificationToast/WCNotificationToast';

const withPortal = (children: React.ReactNode, enabled = true) => {
  if (!enabled) return children;
  return <Portal>{children}</Portal>;
};

interface ExporterContextMenuProps {
  button: JSX.Element | ((isOpen: boolean) => JSX.Element) | undefined;
  screenName: string;
  getExporter: (screenName: string) => IExporterService;
  options?: Record<string, string | boolean>;
  placement?: PlacementWithLogical;
  waitForExporter?: boolean;
  hasPortal?: boolean;
  onSuccess?: (data: BaseExportData & { token: string }) => void;
  formButtonLabel?: 'PDF';
  screen?: string;
}

export function useSimpleExporter({
  screenName,
  getExporter,
  options,
  onSuccess,
  exportType = 'pdf',
  objectId = null,
  payload = {},
}: {
  screenName: string;
  objectId?: string | undefined | null;
  payload?: Record<string, any>;
  exportType: 'pdf' | 'xlsx' | 'csv' | 'zip';
  onSuccess?: (data: BaseExportData & { token: string }) => void;
  getExporter: (screenName: string) => IExporterService;
  options?: Record<string, string | boolean>;
}) {
  const { t } = useTranslation();
  const notify = useNotificationToast();
  const toast = useToast();

  const exporter = useMemo(() => getExporter(screenName), [
    getExporter,
    screenName,
  ]);

  const onExport = useCallback(async () => {
    const response = await exporter.execute({
      ...payload,
      parentable_type: screenName,
      export_type: exportType,
      options: {
        objectId,
        ...options,
      },
    });

    const { body, statusCode } = response;

    if (body && body.downloadFileUrl) {
      toast.closeAll();

      setTimeout(() => {
        notify({
          id: body.downloadFileUrl,
          duration: 8000,
          title: t('notification.export_success'),
          notificationType: 'file',
          onClick: () => window.open(body?.downloadFileUrl),
        });
      }, 1);
    } else if (statusCode >= 200 && statusCode < 300) {
      toast({
        status: 'info',
        title: t('common.success'),
        description: 'Exportação irá iniciar em instantes...',
      });
    } else {
      const formattedError: string = ErrorHandler(body.error_messages);
      toast({
        status: 'error',
        title: t('error.warning'),
        description: t(formattedError),
      });
    }
    onSuccess?.(response.body);
  }, [
    exporter,
    payload,
    screenName,
    exportType,
    objectId,
    options,
    onSuccess,
    toast,
    notify,
    t,
  ]);

  return { onExport };
}

const ExporterContextMenu: React.FC<ExporterContextMenuProps> = ({
  button,
  screenName,
  getExporter,
  options,
  onSuccess,
  placement,
  hasPortal = true,
  formButtonLabel,
  screen,
}) => {
  const { t } = useTranslation();
  const exporter = useMemo(() => getExporter(screenName), [
    getExporter,
    screenName,
  ]);

  const onSubmit = useCallback(
    (onClose: () => void) => async (data: FormularyAnswers) => {
      const params = Object.entries(data).reduce((prev, curr) => {
        const [key, value] = curr;
        return {
          ...prev,
          [key]: value.values,
        };
      }, {});
      const response = await exporter.execute({
        ...params,
        parentable_type: screenName,
        options,
      });
      onClose();
      onSuccess?.(response.body);
      return response;
    },
    [exporter, screenName, options, onSuccess],
  );

  const templateFetcher = useCallback(async () => {
    const template = await exporter.getTemplate(
      `&parentable_type=${screenName}${screen ? `&screen=${screen}` : ''}`,
    );
    return template;
  }, [exporter, screenName, screen]);

  return (
    <Popover closeOnBlur={false} trigger="click" isLazy placement={placement}>
      {({ onClose, isOpen }) => (
        <>
          <PopoverTrigger>{button}</PopoverTrigger>
          {isOpen && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 3,
              }}
              onClick={onClose}
              aria-hidden="true"
            />
          )}

          {withPortal(
            <PopoverContent maxWidth="400px" minWidth="300px" zIndex={999}>
              <PopoverCloseButton />
              <PopoverArrow />
              <PopoverHeader>
                <Heading size="md">{t('common.export')}</Heading>
              </PopoverHeader>
              <PopoverBody maxH="400px" overflowY="auto">
                <ExporterForm
                  buttonLabel={formButtonLabel}
                  submitHandler={onSubmit(onClose)}
                  fetchTemplate={templateFetcher}
                />
              </PopoverBody>
            </PopoverContent>,
            hasPortal,
          )}
        </>
      )}
    </Popover>
  );
};

export default ExporterContextMenu;
