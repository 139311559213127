/* eslint-disable no-nested-ternary */
import React from 'react';
import useField from '@/modules/WebForm/hooks/useField';
import { Flex, Text, HStack, VStack, Button } from '@chakra-ui/react';
import { createAnswer } from '@/modules/WebForm/utils';
import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useTimer, { formatTime } from '@/utils/useTimer';
import FormControlComponent from '../FormControlComponent';

const ChronometerContent: React.FC<QuestionModel> = () => {
  const { error, onChange, fieldState, clear } = useField();
  const {
    handlePause,
    timer,
    handleReset,
    handleResume,
    handleStart,
    isActive,
    isPaused,
  } = useTimer(
    fieldState &&
      !Array.isArray(fieldState) &&
      typeof fieldState.content === 'number'
      ? fieldState.content
      : 0,
  );

  const handleChange = React.useCallback(
    (newValue?: number) => {
      onChange(createAnswer(newValue));
    },
    [onChange],
  );

  return (
    <FormControlComponent isInvalid={error}>
      <Flex>
        <VStack>
          <Text>{formatTime(timer)}</Text>
          <HStack>
            {!isActive && !isPaused ? (
              <Button colorScheme="brand" onClick={handleStart}>
                Começar
              </Button>
            ) : isPaused ? (
              <Button
                onClick={() => {
                  handlePause();
                  handleChange(timer);
                }}
              >
                Pausar
              </Button>
            ) : (
              <Button onClick={handleResume}>Continuar</Button>
            )}
            <Button
              onClick={() => {
                handleReset();
                clear();
              }}
              colorScheme="orange"
              disabled={!isActive}
            >
              Resetar
            </Button>
          </HStack>
        </VStack>
      </Flex>
    </FormControlComponent>
  );
};

export default ChronometerContent;
