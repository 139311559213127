import Loading from '@/components_v2/Loading';
import {
  Flex,
  FlexProps,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiCaretRight, BiSearch } from 'react-icons/bi';

import useSections from '../hooks/useSections';

const NavItem: React.FC<
  FlexProps & {
    isSelected?: boolean;
    onClick: () => void;
  }
> = (props) => {
  const color = useColorModeValue('gray.600', 'gray.300');
  const { children, onClick, isSelected, ...rest } = props;
  return (
    <Flex
      onClick={onClick}
      align="center"
      px="4"
      py="3"
      cursor="pointer"
      rounded="lg"
      color={isSelected ? 'inherit' : 'gray.400'}
      bg={isSelected ? 'gray.200' : 'transparent'}
      _hover={{
        bg: useColorModeValue('gray.100', 'gray.900'),
        color: useColorModeValue('gray.900', 'gray.200'),
      }}
      role="group"
      fontWeight="semibold"
      fontSize="sm"
      transition=".15s ease"
      justify="space-between"
      {...rest}
    >
      {children}
      <Icon
        mx="2"
        boxSize="4"
        _groupHover={{
          color,
        }}
        as={BiCaretRight}
      />
    </Flex>
  );
};

const FormPages: React.FC<{ onSelect?: () => void }> = ({
  onSelect = () => null,
}) => {
  const { t } = useTranslation();
  const { isLoading, sections, currentSection, setSection } = useSections();
  return (
    <VStack
      flex="1"
      spacing="8"
      align="flex-start"
      padding="4"
      direction="column"
      overflowY="hidden"
    >
      <VStack spacing="4" w="full" px="4" align="flex-start">
        <Text fontWeight="500" fontSize="xl">
          {t('form_builder.pages.pages')}
        </Text>
        <InputGroup>
          <Input placeholder={t('form_builder.pages.search_placeholder')} />
          <InputRightElement>
            <BiSearch color="#E2E8F0" />
          </InputRightElement>
        </InputGroup>
      </VStack>
      {isLoading ? (
        <Loading />
      ) : (
        <List overflowY="auto" w="full">
          {sections.map((section) => (
            <ListItem
              key={section.id}
              width="100%"
              maxWidth="100%"
              marginBottom="4"
            >
              <NavItem
                isSelected={currentSection === section.id}
                onClick={() => {
                  setSection(section.id);
                  onSelect();
                }}
              >
                {section.name}
              </NavItem>
            </ListItem>
          ))}
        </List>
      )}
    </VStack>
  );
};

export default FormPages;
