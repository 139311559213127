import React, { useContext } from 'react';
import { SurveyFormContext } from '../context/SurveyFormContext';
import { SurveyFormContextData } from '../domain/usecases';

export function useSurveyForm(): SurveyFormContextData {
  const context = useContext(SurveyFormContext);

  if (!context) {
    throw new Error('useSurveyForm must be used whithin an SurveyFormProvider');
  }

  return context;
}
