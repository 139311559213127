import { useMutation, useQueryClient } from 'react-query';

import { cancelFeedbackReview } from './services/cancelFeedbackReview';
import { discussionTopicCommentsKey } from './useFeedbackCommentsQuery';
import { discussionTopicsKey } from './useFeedbackQuery';

export function useCancelFeedbackReview() {
  const queryClient = useQueryClient();
  const mutation = useMutation(cancelFeedbackReview, {
    async onSuccess(data, variables) {
      if (!data) return;

      await queryClient.refetchQueries([
        discussionTopicCommentsKey,
        variables.discussionTopicId,
      ]);

      await queryClient.refetchQueries([
        discussionTopicsKey,
        variables.discussionTopicId,
      ]);
    },
  });

  return mutation;
}
