/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { FormHeader } from './form-header';
import { ReportContext } from './hooks/use-context';
import { QuestionList } from './question-list';
import { SectionsList } from './sections-list';
import type { FormReportData } from './types';

export function FormReport(props: { report: FormReportData }) {
  return (
    <ReportContext value={props.report}>
      <div className="h-screen w-screen flex-1 bg-white font-sans md:p-2">
        <div className="mx-auto h-auto max-w-4xl">
          <FormHeader />
          <SectionsList>
            <QuestionList />
          </SectionsList>
        </div>
      </div>
    </ReportContext>
  );
}
