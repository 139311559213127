import React from 'react';

import { useModalStack } from '@/hooks/modalStack/modal';

import Modal from '@/components_v2/modals/Modal';

const ModalStackLayer: React.FC = () => {
  const {
    state: { stack },
    closeModal,
  } = useModalStack();
  return (
    <div>
      {stack.map(({ cancelable, onClose, children, key, ...rest }) => (
        <Modal
          {...rest}
          key={key || new Date().toString()}
          isOpen
          onRequestClose={cancelable ? () => closeModal(key) : undefined}
        >
          {children}
        </Modal>
      ))}
    </div>
  );
};

export default ModalStackLayer;
