/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql } from '@/gql';
import { QuestionTypes } from '@/gql/types';
import { getApolloInstance } from '@/modules/graphql';
import { useLazyQuery } from '@apollo/client';

export const queryQuestions = gql(`#graphql
  query Questions(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $questionsId: ID
    $formularyId: ID
    $questionType: QuestionTypes
    $questionTypes: [QuestionTypes!]
    $parentOptionId: ID,
    $parentQuestionId: ID
    $orderBy: OrderNames
    $search: String
    $triggerIds: [String!]
  ) {
    questions(
      after: $after
      before: $before
      first: $first
      last: $last
      id: $questionsId
      formularyId: $formularyId
      questionType: $questionType
      questionTypes: $questionTypes
      orderBy: $orderBy
      search: $search
      parentOptionId: $parentOptionId
      parentQuestionId: $parentQuestionId
      triggerIds: $triggerIds
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          actionPlanId
          authorId
          formulary {
            id
            name
          }
          childrenQuestionsCount
          clonedFromId
          codeMaxChar
          conditional
          createdAt
          deletedAt
          description
          enabled
          externalCreatedAt
          externalUpdatedAt
          formularyId
          hasComments
          validationUrl
          validationMinChar
          updatedAt
          tipText
          taskFormularyId
          taskCreate
          taskButtonLabel
          sectionId
          scoreWeight
          required
          questionType
          qrCodeOnly
          position
          parentQuestionId
          parentOptionId
          name
          meta
          maxPictures
          lastModifiedAt
          lastAuthorId
          inputMask
          imageUrl
        }
      }
    }
  }
`);

export const fetchQuestions = async (params: {
  after?: string;
  search?: string;
  first?: number;
  formularyId?: string;
  questionTypes?: QuestionTypes[];
  parentOptionId?: string;
  parentQuestionId?: string;
  triggerIds?: string[];
}) => {
  const apollo = getApolloInstance();
  const { data } = await apollo.query({
    query: queryQuestions,
    variables: { first: 20, ...params },
    fetchPolicy: 'network-only',
  });

  const items = data.questions.edges.map((edge) => edge.node!);

  return {
    items,
    cursor: data?.questions?.pageInfo?.endCursor || undefined,
  };
};

export const useFetchQuestions = () => {
  const [fetch, { data, loading, error }] = useLazyQuery(queryQuestions);
  let parsedQuestions;

  if (data && data.questions) {
    const { questions } = data;
    parsedQuestions = questions?.edges?.map((item) => ({
      label: `${item?.node?.name}`,
      value: item?.node?.id,
    }));
  }

  return { data: parsedQuestions || null, loading, fetch };
};
