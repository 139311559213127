/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql } from '@/gql';
import { OrderNames } from '@/gql/graphql';
import { getApolloInstance } from '@/modules/graphql';
import { useLazyQuery } from '@apollo/client';

import {
  parsedValuesToOptions,
  ParsedValuesToOptionsProps,
} from '../utils/parsedValuesToOptions';

const queryFormularies = gql(`#graphql
  query Formularies(
    $after: String
    $before: String
    $last: Int
    $first: Int
    $formulariesId: ID
    $orderBy: OrderNames
    $search: String
    $triggerIds: [String!]
  ) {
    formularies(
      after: $after
      before: $before
      last: $last
      first: $first
      id: $formulariesId
      orderBy: $orderBy
      search: $search
      triggerIds: $triggerIds
    ) {
      edges {
        cursor
        node {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const fetchFormularies = async (params: {
  after?: string;
  search?: string;
  first?: number;
  triggerIds?: string[];
}) => {
  const apollo = getApolloInstance();
  const { data } = await apollo.query({
    query: queryFormularies,
    variables: { first: 20, ...params, orderBy: OrderNames.NameAsc },
    fetchPolicy: 'network-only',
  });

  const items = data.formularies?.edges?.map((edge) => edge?.node!) || [];

  return {
    items,
    cursor: data?.formularies?.pageInfo?.endCursor || undefined,
  };
};

export const useFetchFormularies = () => {
  const [fetch, { data, loading }] = useLazyQuery(queryFormularies);
  let parsedFormularies;

  if (data && data.formularies) {
    const { formularies } = data;
    parsedFormularies = parsedValuesToOptions(
      formularies?.edges?.map((e) => e?.node) as ParsedValuesToOptionsProps,
    );
  }

  return { data: parsedFormularies || null, loading, fetch };
};
