import { tabsAnatomy } from '@chakra-ui/anatomy';
import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode, PartsStyleFunction } from '@chakra-ui/theme-tools';

const variantSegmentedControl: PartsStyleFunction<typeof tabsAnatomy> = (
  props,
) => {
  const { colorScheme: c } = props;
  return {
    tab: {
      rounded: 'md',
      py: '1',
      px: '4',
      fontSize: 'lg',
      _selected: {
        bg: 'white',
        boxShadow: 'base',
      },
    },
    tablist: {
      padding: '0.5',
      bg: 'gray.50',
      rounded: 'md',
      borderWidth: '1px',
    },
  };
};

export default {
  parts: tabsAnatomy.keys,
  variants: {
    'segmented-control': variantSegmentedControl,
  },
} as ComponentStyleConfig;
