import {
  QuestionModel,
  Section,
} from '@/modules/core/domain/interfaces/IFormulary';
import { IFieldRegistry, SurveyFormProvider } from '@/modules/SurveyForm';
import {
  FormProviderRef,
  OnSubmitResults,
} from '@/modules/SurveyForm/domain/usecases';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { BaseFormRef, FormularyProps } from './localGeneric';

/**
 *
 * @param formulary: The fields that will be injected in the form state
 * @param defaultValues?: Default values for your form
 * @param validator?: Custom validator for value output
 * @param selfRef: A ref that exposes "submit" imperative handle
 *
 * This is an implementation of SurveyForm Provider with all questionMounting logic.
 */
const BaseFormProvider: React.ForwardRefRenderFunction<
  BaseFormRef,
  FormularyProps
> = (props, selfRef) => {
  const formRef = useRef<FormProviderRef>();
  const [state, setState] = useState<IFieldRegistry[]>([]);

  const {
    formulary,
    defaultValues,
    validator,
    children,
    viewOnly,
    setTitleText,
  } = props;
  const { sections } = formulary;

  const getSerializedQuestions = useCallback((currentSections: Section[]) => {
    const allQuestions: QuestionModel[] = [];
    currentSections?.forEach((section: Section) =>
      allQuestions.push(...section.questions.filter((q) => q.enabled)),
    );
    return allQuestions;
  }, []);

  const getRequiredQuestions = (
    questions: QuestionModel[],
  ): IFieldRegistry[] => {
    const requiredFields = questions.filter((q) => !q.parentQuestionId);

    return requiredFields.map(({ id, required }) => ({ id, required }));
  };

  const mountAllQuestions = useCallback(() => {
    const allQuestions: QuestionModel[] = getSerializedQuestions(sections);
    const requiredQuestions: IFieldRegistry[] = getRequiredQuestions(
      allQuestions,
    );

    setState(requiredQuestions);
  }, [sections, getSerializedQuestions]);

  useEffect(() => {
    mountAllQuestions();
  }, [mountAllQuestions]);

  const submitForm = useCallback(async (): Promise<
    OnSubmitResults | undefined
  > => {
    const result = await formRef?.current?.onSubmit();
    return result;
  }, []);

  const setTextCallback = useCallback(
    (value: any) => {
      setTitleText?.(value);
    },
    [setTitleText],
  );

  useImperativeHandle(
    selfRef,
    () => ({
      submit: submitForm,
    }),
    [submitForm],
  );

  if (!state.length) return null;

  return (
    <SurveyFormProvider
      ref={formRef}
      fields={state}
      initialValues={defaultValues}
      validator={validator}
      viewOnly={viewOnly}
      setTextCallback={setTextCallback}
    >
      {children}
    </SurveyFormProvider>
  );
};

export default forwardRef(BaseFormProvider);
