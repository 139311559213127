import styled, { css } from 'styled-components';

import constants from '../../constants';

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  top: ${constants.navbarHeight};
  width: 100vw;
  padding-bottom: 30px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  z-index: 9;

  /* background-color: ${(props) =>
    props.isOpen ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.0)'}; */

  transition: transform 200ms ease-in-out, visibility 200ms ease-in-out,
    background-color 200ms ease-in-out;
  animation-fill-mode: both;
  visibility: ${(props) => {
    return props.isOpen ? 'visible' : 'hidden';
  }};
`;

export const Content = styled.div<ContainerProps>`
  background-color: ${(props) => props.theme.colors.secondaryContent};

  box-shadow: 0px 10px 48px -2px rgba(0, 0, 0, 0.25);

  transition: transform 200ms ease-in-out;
  transform: ${(props) =>
    props.isOpen ? 'translateY(0%)' : 'translateY(-100%)'};
`;

export const BackView = styled.div`
  flex: 1;
`;

export const List = styled.ul``;

export const DropDownItem = styled.div`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 15px;
  font-weight: 400;
`;

export const SecondaryItem = styled.div`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  padding: 15px 26px 0px;
  color: ${(props) => props.theme.colors.darkText};
  font-size: 15px;
  font-weight: 400;
`;

export const collapsibleContentStyle = css`
  div:first-child {
    margin-top: 10px;
  }
  div:last-child {
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
  }
  div {
    padding: 0px 26px 6px;
  }
  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
