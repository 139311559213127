import styled from 'styled-components';
import { IoIosHelpCircleOutline } from 'react-icons/io';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: go-front 1s;
  width: 398px;

  @keyframes go-front {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

export const DoubtIcon = styled(IoIosHelpCircleOutline)`
  font-size: 25px;
  color: ${({ theme }) => theme.colors.mediumText};
  transition: 0.2s;
  opacity: 0.5;
  cursor: pointer;
  margin-left: auto;

  :hover {
    opacity: 1;
  }
`;
