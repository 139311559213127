import config from '@/Constants';
import {
  DiscussionTopic,
  DiscussionTopicStatusType,
} from '@/hooks/Feedback/interfaces';
import { useFeedbackStatusTypesQuery } from '@/hooks/Feedback/useFeedbackStatusTypesQuery';
import { useUpdateTopic } from '@/hooks/Feedback/useUpdateTopic';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { useQueryClient } from 'react-query';

import { StatusTag } from './StatusTag';

type StatusSelectProps = {
  feedback: DiscussionTopic;
  callback?: () => void;
};

export const StatusSelect: React.FC<StatusSelectProps> = (props) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const statusTypes = useFeedbackStatusTypesQuery();
  const updateTopic = useUpdateTopic();
  const { feedback, callback } = props;
  const [statusType, setstatusType] = React.useState(feedback.statusType);

  const onSelect = React.useCallback(
    async (item: DiscussionTopicStatusType) => {
      if (statusType?.id === item.id) return;
      setstatusType(item);
      queryClient.setQueryData<DiscussionTopic>(
        [config.DISCUSSION_TOPICS_URL, feedback.id],
        (old) =>
          ({
            ...old,
            statusType: item,
          } as any),
      );
      const result = await updateTopic.mutateAsync({
        id: feedback.id,
        discussionStatusTypeId: item.id,
      });

      if (result) {
        callback?.();
        toast({ title: 'Status atualizado', status: 'success' });
      }
    },
    [callback, feedback.id, queryClient, statusType?.id, toast, updateTopic],
  );

  const renderItem = React.useCallback((item: DiscussionTopicStatusType) => {
    return <StatusTag status={item} size="lg" />;
  }, []);

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={Button}
            variant="outline"
            rightIcon={<ChevronDownIcon />}
            px="2"
          >
            <StatusTag status={statusType} size="lg" />
          </MenuButton>
          <MenuList>
            {statusTypes.data?.map((item) => (
              <MenuItem key={item.id} onClick={() => onSelect(item)}>
                {renderItem(item)}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
