import { hslToHex } from './hslToHex';

/* eslint-disable no-bitwise */
type StringToColorParams = {
  str: string;
  saturation: number;
  luminance: number;
  mode?: 'hsl' | 'hex';
};

export function stringToColor(params: StringToColorParams): string {
  const { str, saturation, luminance, mode = 'hsl' } = params;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = hash % 360;

  if (mode === 'hex') {
    return hslToHex(hue, saturation, luminance);
  }

  return `hsl(${hue}, ${saturation}%, ${luminance}%)`;
}
