/*
4, 2
a, b, c, d, e, f
0, 1, 2, 3, 4, 5
a, b, e, c, d, f

a, b, c 0/1
0, 1, 2
b, a, c
*/

function arrayReorder<T>(arr: T[], src: number, dest: number): T[] {
  const newArr: T[] = [];
  if (dest - src === 1 || src - dest === 1) {
    for (let i = 0; i < arr.length; i++) {
      if (i === dest) {
        newArr.push(arr[src]);
      } else if (i === src) {
        newArr.push(arr[dest]);
      } else {
        newArr.push(arr[i]);
      }
    }
    return newArr;
  }
  if (dest > src) {
    let srcArrived = false;
    let destArrived = false;
    for (let i = 0; i < arr.length; i++) {
      if (i === src) {
        srcArrived = true;
      }
      if (destArrived) {
        newArr[i] = arr[i];
      }
      if (i === dest) {
        srcArrived = false;
        destArrived = true;
        newArr[i] = arr[src];
      }
      if (srcArrived) {
        newArr[i] = arr[i + 1];
      }
      if (!destArrived && !srcArrived) {
        newArr[i] = arr[i];
      }
    }
    return newArr;
  }
  if (dest < src) {
    let srcArrived = false;
    let destArrived = false;
    for (let i = 0; i < arr.length; i++) {
      if (destArrived) {
        newArr[i] = arr[i - 1];
      }
      if (i === dest) {
        newArr[i] = arr[src];
        destArrived = true;
      }
      if (srcArrived) {
        newArr[i] = arr[i];
      }
      if (i === src) {
        srcArrived = true;
        newArr[i] = arr[i - 1];
      }
      if (!destArrived && !srcArrived) {
        newArr[i] = arr[i];
      }
    }
    return newArr;
  }
  return arr;
}

export { arrayReorder };
