import { Colors } from '@chakra-ui/react';

export const colors: Colors = {
  brand: {
    500: '#0794B2',
    100: '#4FDA91',
  },
  black: '#16151b',
  gray: {
    '50': '#faf9fa',
    '100': '#f1f1f2',
    '200': '#e7e7e8',
    '300': '#d4d4d6',
    '400': '#adacb0',
    '500': '#7f7e85',
    '600': '#55535c',
    '700': '#373540',
    '800': '#201f27',
    '900': '#1a191f',
  },
  cyan: {
    '50': '#f5fbfc',
    '100': '#d6edf2',
    '200': '#c3e5ec',
    '300': '#aedce6',
    '400': '#6dc1d2',
    '500': '#4db3c8',
    '600': '#27a3bc',
    '700': '#0687a1',
    '800': '#056f84',
    '900': '#045667',
  },
  blue: {
    '50': '#f2f6fb',
    '100': '#cfe0f0',
    '200': '#acc9e5',
    '300': '#86b0d9',
    '400': '#6298ce',
    '500': '#4182c4',
    '600': '#1e6bb9',
    '700': '#06509b',
    '800': '#05427f',
    '900': '#043668',
  },
  purple: {
    '50': '#f8f6fc',
    '100': '#e3dbf4',
    '200': '#cfc0ec',
    '300': '#b19ae0',
    '400': '#9c7fd7',
    '500': '#815ccc',
    '600': '#6e43c5',
    '700': '#5b2abd',
    '800': '#4710b5',
    '900': '#33068e',
  },
  pink: {
    '50': '#fcf6f9',
    '100': '#f4dae7',
    '200': '#eabcd4',
    '300': '#de94b9',
    '400': '#d576a6',
    '500': '#c84e8b',
    '600': '#be2c75',
    '700': '#a70757',
    '800': '#840545',
    '900': '#630434',
  },
  red: {
    '50': '#fcf6f6',
    '100': '#f4dada',
    '200': '#eababa',
    '300': '#dd9292',
    '400': '#d67a7a',
    '500': '#cb5858',
    '600': '#c13636',
    '700': '#ac0707',
    '800': '#930606',
    '900': '#6d0404',
  },
  orange: {
    '50': '#fdfaf7',
    '100': '#f6ebe1',
    '200': '#ebd5bf',
    '300': '#dcb58e',
    '400': '#ce9760',
    '500': '#c17c36',
    '600': '#b3600b',
    '700': '#904b06',
    '800': '#723c04',
    '900': '#5e3104',
  },
  yellow: {
    '50': '#fefefd',
    '100': '#fbf9f1',
    '200': '#f2edd5',
    '300': '#e8e0b5',
    '400': '#dacd88',
    '500': '#bfa730',
    '600': '#9d8406',
    '700': '#7b6705',
    '800': '#5c4d04',
    '900': '#4c4003',
  },
  green: {
    '50': '#f7fcfa',
    '100': '#d4f2e3',
    '200': '#a4e3c4',
    '300': '#6cd19f',
    '400': '#2bbd75',
    '500': '#06a456',
    '600': '#058847',
    '700': '#046a37',
    '800': '#03572d',
    '900': '#034825',
  },
  teal: {
    '50': '#f3fbfb',
    '100': '#cbefef',
    '200': '#9de1e1',
    '300': '#62cece',
    '400': '#0bb3b3',
    '500': '#069999',
    '600': '#057c7c',
    '700': '#046060',
    '800': '#035050',
    '900': '#034242',
  },
  primary: {
    '50': '#f1f9fb',
    '100': '#c5e6ed',
    '200': '#91d0dd',
    '300': '#4eb3c8',
    '400': '#26a1bc',
    '500': '#0688a4',
    '600': '#05738b',
    '700': '#045d6f',
    '800': '#044e5e',
    '900': '#033944',
  },
};
