/* eslint-disable radix */
const numberPad = (number: number | string) => `0${number}`.slice(-2);

export const generateMinuteArr = (): string[] => {
  const arr = [];
  for (let index = 0; index < 60; index += 5) {
    arr.push(numberPad(index));
  }

  return arr;
};

export const generateHoursArr = (): string[] => {
  const arr = [];
  for (let index = 0; index < 24; index++) {
    arr.push(`${numberPad(index)}`);
  }

  return arr;
};

export function parseTimeString(t: string): Date | null {
  const d = new Date();
  const time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
  if (!time) return null;
  d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
  d.setMinutes(parseInt(time[2]) || 0);
  return d;
}

export function parseDateMillisSinceMidnight(millis: number): Date | null {
  const d = new Date();
  d.setHours(0, 0, 0, millis);
  return d;
}

export function dateToTimeString(date: Date): string {
  return `${numberPad(date.getHours())}:${numberPad(date.getMinutes())}`;
}
