import React from 'react';
import { useInspectionStore } from '../context/store';

export default function useFormDetails() {
  const { formulary, isLoading } = useInspectionStore(
    React.useCallback(
      ({ formulary, isLoading }) => ({ formulary, isLoading }),
      [],
    ),
  );

  const formAttributes = React.useMemo(() => formulary, [formulary]);

  return {
    isLoading,
    formulary: formAttributes,
  };
}
