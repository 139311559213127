import React from 'react';

import { renderLoading } from '@components/Loading';
import { useLoading } from '@hooks/loading';

const LoadingLayer: React.FC = () => {
  const { isLoading } = useLoading();

  return isLoading ? renderLoading() : <></>;
};

export default LoadingLayer;
