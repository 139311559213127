import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useModals } from '@/hooks/ModalManager';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import React from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';

import { useChecklistSummary } from '../useChecklistSummary';

export const TaskOptions: React.FC<{
  onClose: () => void;
  handleDisableTask?: (taskId: string, hideTask: boolean) => void;
}> = ({ onClose, handleDisableTask }) => {
  const { handleRestart, handleDelete, data } = useChecklistSummary();
  const { checkPermission } = useUserPermissions();
  const modals = useModals();
  const updateStatusMessage =
    data?.status === 'disabled'
      ? {
          title: `Tem certeza que deseja ativar esta tarefa?`,
          body: 'Os tickets vão aparecer aos responsáveis no mobile',
        }
      : {
          title: `Tem certeza que deseja inativar esta tarefa?`,
          body: 'Os tickets vão parar de aparecer aos responsáveis no mobile',
        };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<BiDotsVerticalRounded />}
        variant="outline"
      />
      <MenuList>
        <MenuItem
          isDisabled={data?.status !== 'draft'}
          onClick={() => {
            modals.confirm({
              title: 'Tem certeza que deseja cancelar esta tarefa?',
              body: 'Esta ação não pode ser desfeita',
              onConfirm: handleRestart,
              confirmProps: {
                colorScheme: 'brand',
              },
            });
          }}
        >
          Cancelar tarefa
        </MenuItem>
        <MenuItem
          isDisabled={
            data?.status === 'done'
              ? !checkPermission('admin')
              : !checkPermission('rw')
          }
          onClick={() => {
            modals.confirm({
              title: 'Tem certeza que deseja apagar esta tarefa?',
              body: 'Esta ação não pode ser desfeita',
              onConfirm: async () => {
                handleDelete?.();
                onClose();
              },
              confirmProps: {
                colorScheme: 'brand',
              },
            });
          }}
        >
          Apagar tarefa
        </MenuItem>
        {handleDisableTask && data?.id && data?.status !== 'done' && (
          <MenuItem
            onClick={() => {
              modals.confirm({
                ...updateStatusMessage,
                onConfirm: async () => {
                  handleDisableTask?.(data.id, data.status !== 'disabled');
                  onClose();
                },
                confirmProps: {
                  colorScheme: 'brand',
                },
              });
            }}
          >
            {data?.status !== 'disabled' ? 'Inativar tarefa' : 'Ativar tarefa'}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};
