import styled from 'styled-components';
import Color from 'color';

export const Container = styled.div`
  margin-bottom: 0px;
`;

export const CalendarWrapper = styled.div`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  color: ${(props) => props.theme.colors.darkText};

  .react-calendar {
    max-width: 100%;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    user-select: none;
  }
  .react-calendar button {
    margin: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 20px;
  }
  .react-calendar__navigation__label {
    height: 20px;
    text-transform: uppercase;
  }
  .react-calendar__navigation button {
    min-width: 40px;
    background: none;
    border: 0;
  }

  .react-calendar__navigation__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-calendar__navigation__arrow .react-calendar__navigation__prev2-button {
    order: 4;
  }
  .react-calendar__navigation__arrow .react-calendar__navigation__prev3-button {
    order: 3;
  }
  .react-calendar__navigation__label {
    order: -1;
  }
  .react-calendar__navigation__arrow .react-calendar__navigation__next2-button {
    order: 2;
  }
  .react-calendar__navigation__arrow .react-calendar__navigation__next2-button {
    order: 1;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 12px;
    color: ${(props) => props.theme.colors.darkText};

    abbr {
      text-decoration: none;
    }
  }
  .react-calendar__month-view__days {
    border: 1px solid #dfdfdf;
  }
  .react-calendar__year-view {
    border: 1px solid #dfdfdf;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  /* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
} */
  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${(props) => props.theme.colors.lightText};
  }
  .react-calendar__navigation__label__labelText {
    color: ${(props) => props.theme.colors.darkText};
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    font-size: 12px;

    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;

    border: 1px solid #dfdfdf;
  }

  .react-calendar__tile:disabled {
    background-color: ${(props) => props.theme.colors.secondaryContent};
  }

  .react-calendar__tile:enabled:focus,
  .react-calendar__tile:enabled:hover {
    background-color: ${(props) => props.theme.colors.terciaryContent};
  }

  .react-calendar__tile--now {
    background-color: ${(props) => props.theme.colors.secondary};
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background-color: ${(props) =>
      Color(props.theme.colors.secondary).darken(0.1).hex()};
  }
  .react-calendar__tile--hasActive {
    background-color: ${(props) =>
      Color(props.theme.colors.primary).lighten(0.1).hex()};
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.lighterText};
    abbr {
      font-weight: 500;
    }
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: ${(props) =>
      Color(props.theme.colors.primary).darken(0.1).hex()};
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: ${(props) => props.theme.colors.terciaryContent};
  }
`;
