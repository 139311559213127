import styled from 'styled-components';

interface ToggleStyle {
  state: boolean;
  width: number;
  height: number;
  color?: string;
}

export const Container = styled.div<ToggleStyle>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: 100px;
  background-color: ${({ color, state, theme }) =>
    state ? color || theme.colors.primary : theme.colors.v2_secondary};
  display: flex;
  align-items: center;
  transition: background-color 150ms ease-in-out;
`;

export const ToogleBall = styled.div<ToggleStyle>`
  height: ${({ height }) => height - 2}px;
  width: ${({ height }) => height - 2}px;
  border-radius: 50%;
  background-color: ${({ color, state, theme }) =>
    !state ? color || theme.colors.primary : theme.colors.v2_secondary};
  transition: background-color 150ms ease-in-out;

  animation: ${({ state }) => (state ? 'toggleTrue' : 'toggleFalse')} 200ms
    forwards;
  @keyframes toggleTrue {
    from {
      transform: translateX(2px);
    }
    to {
      transform: ${({ width, height }) => `translateX(${width - height}px)`};
    }
  }

  @keyframes toggleFalse {
    from {
      transform: ${({ width, height }) => `translateX(${width - height}px)`};
    }
    to {
      transform: translateX(2px);
    }
  }
`;
