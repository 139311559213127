import React, { useEffect, useState, useCallback } from 'react';

/* eslint-disable no-restricted-syntax */
import { useFilter } from '@/hooks/Filters/filter';

import Datepicker from '@/components_v2/DatePicker';
import { DateFilterProps } from './interfaces';

const DateFilter: React.FC<DateFilterProps> = ({ column, type, table }) => {
  const { selectOption, getSelectedOptions } = useFilter();

  const getValue = useCallback(
    (current?: Date[]) => {
      const selected = getSelectedOptions({ column, type, table });
      if (selected) {
        if (selected.type === 'date') {
          if (!current || selected.selected !== current[0]) {
            return [selected.selected, selected.selected];
          }
        }
        if (selected.type === 'date_range') {
          if (
            !current ||
            selected.selected.dateStart !== current[0] ||
            selected.selected.dateEnd !== current[1]
          ) {
            return [selected.selected.dateStart, selected.selected.dateEnd];
          }
        }
      }
      return undefined;
    },
    [column, getSelectedOptions, type, table],
  );

  const [selectedDate, setSelectedDate] = useState<Date[] | undefined>(() =>
    getValue(),
  );

  useEffect(() => {
    const newDifferentValue = getValue(selectedDate);
    if (newDifferentValue) {
      setSelectedDate(newDifferentValue);
    }
  }, [selectedDate, getValue]);

  const onChangeDate = useCallback(
    (newDate: Date[]) => {
      if (type === 'date_range') {
        setSelectedDate(newDate);
        selectOption({
          table,
          column,
          type,
          selected: { dateStart: newDate[0], dateEnd: newDate[1] },
        });
      }
      if (type === 'date') {
        setSelectedDate(newDate);
        selectOption({
          table,
          column,
          type,
          selected: newDate[0],
        });
      }
    },
    [type, selectOption, column, table],
  );

  return (
    <Datepicker
      type={type}
      selectedDate={selectedDate}
      onChangeDate={onChangeDate}
    />
  );
};

export default DateFilter;
