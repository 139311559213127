import styled from 'styled-components';

interface CellStyleProps {
  backgroundColor?: string;
  textColor?: string;
  textAling?: string;
  textSize?: number;
}

export const Container = styled.div<CellStyleProps>`
  flex: 1;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  display: flex;
  justify-content: ${({ textAling }) => textAling || 'flex-start'};
  align-items: center;
`;

export const Title = styled.h3<CellStyleProps>`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme, textColor }) => textColor || theme.colors.darkerText};
  font-size: ${({ textSize }) => `${textSize}em` || '0.8em'};
  font-weight: 400;
`;
