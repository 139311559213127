import {
  Section,
  QuestionModel,
} from '@/modules/core/domain/interfaces/IFormulary';

export function orderSection(section: Section): Section {
  const aux: QuestionModel[] = [];
  for (let i = 0; i < section.questions.length; i++) {
    const question = section.questions[i];
    if (!(question.position === undefined || question.position === null)) {
      aux[question.position] = question;
    }
  }
  for (let i = 0; i < section.questions.length; i++) {
    const question = section.questions[i];
    if (question.position === undefined || question.position === null) {
      for (let j = 0; j <= aux.length; j++) {
        if (aux.length === j || !aux[j]) {
          aux[j] = question;
          break;
        }
      }
    }
  }
  return { ...section, questions: aux };
}
