import config from '@/Constants';
import { User } from '@/modules/core/domain/models/user';
import { Formulary } from '@/screens/registrations/Formularies/Index/hooks/interfaces/formularies';
import { Local } from '@/screens/registrations/Locals/hooks/interfaces/locals';
import { Schedule } from '@/screens/registrations/Schedules/hooks/interfaces/schedules';
import BaseApiServices from '@shared/data/usecases/BaseApiServices';
import AxiosHelper from '@shared/infra/protocols/http/AxiosHelper';

export const stubService = new BaseApiServices(AxiosHelper, '');

export const schedulesServices = new BaseApiServices<Schedule>(
  AxiosHelper,
  config.SCHEDULES_URL,
);
export const usersServices = new BaseApiServices<User>(
  AxiosHelper,
  config.USERS_URL,
);
export const dashboardsServices = new BaseApiServices(
  AxiosHelper,
  config.DASHBOARDS_URL,
);
export const outDashboardsServices = new BaseApiServices(
  AxiosHelper,
  config.OUT_DASHBOARDS_URL,
);
export const dashboardSuperGroupsServices = new BaseApiServices(
  AxiosHelper,
  config.DASHBOARD_SUPER_GROUPS,
);
export const dashboardTeamsService = new BaseApiServices(
  AxiosHelper,
  config.DASHBOARD_TEAMS,
);
export const dashboardUsersServices = new BaseApiServices<User>(
  AxiosHelper,
  config.DASHBOARD_USERS_URL,
);
export const dashboardUsersGroupsServices = new BaseApiServices<User>(
  AxiosHelper,
  config.DASHBOARD_OUT_USERS_URL,
);
export const usersGroupsServices = new BaseApiServices<User>(
  AxiosHelper,
  config.OUT_USERS_URL,
);
export const formulariesServices = new BaseApiServices<Formulary>(
  AxiosHelper,
  config.FORMULARIES_URL,
);
export const localsServices = new BaseApiServices(
  AxiosHelper,
  config.LOCALS_URL,
);

export const checklistServices = new BaseApiServices(
  AxiosHelper,
  config.EVENTS_URL,
  '&[filter][events][parentable_type][]=Schedule',
);

export const actionPlanServices = new BaseApiServices(
  AxiosHelper,
  config.EVENTS_URL,
  '&[filter][events][parentable_type][]=ActionPlan',
);

export const collectionsServices = new BaseApiServices(
  AxiosHelper,
  config.COLLECTIONS_URL,
);

export const collectionGroupsService = new BaseApiServices(
  AxiosHelper,
  config.COLLECTIONS_GROUPS_URL,
);

export const outFormulariesServices = new BaseApiServices<Formulary>(
  AxiosHelper,
  config.OUT_FORMULARIES_URL,
);

export const outLocalsServices = new BaseApiServices<Local>(
  AxiosHelper,
  config.OUT_LOCALS_URL,
);

export const formularyGroupsService = new BaseApiServices(
  AxiosHelper,
  config.FORMULARY_GROUPS_URL,
);

export const localGroupsService = new BaseApiServices(
  AxiosHelper,
  config.LOCAL_GROUPS_URL,
);
