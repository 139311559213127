import { StepTypes, TriggerableTypes } from '@/gql/graphql';
import { array, boolean, InferType, mixed, number, object, string } from 'yup';

const optionSchema = object()
  .shape({
    id: string().required(),
    name: string().optional(),
  })
  .required();

const stepType = () => mixed<StepTypes>().oneOf(Object.values(StepTypes));

const triggerableType = () =>
  mixed<TriggerableTypes>().oneOf(Object.values(TriggerableTypes));

const actionableType = () =>
  mixed<
    'email_checklist' | 'email_user' | 'webhook' | 'update_property'
  >().oneOf(['email_checklist', 'webhook', 'update_property', 'email_user']);

export const attributeSchema = object({
  id: string().nullable(),
  label: string().min(2, 'Deve ter no mínimo dois caracteres').required(),
  destroy: boolean().nullable(),
  _local: boolean().nullable(),
  attributeQuestionValue: array()
    .min(1, 'Adicione uma pergunta')
    .of(optionSchema)
    .required(),
}).required();

export type AttributeSchema = InferType<typeof attributeSchema>;

export const triggerSchema = object({
  id: string().nullable(),
  label: string().min(2).required(),
  triggerableType: triggerableType().required().label('type'),
  triggerable: array().min(1).of(optionSchema).required(),
  delayType: mixed<'immediate' | 'delay'>().oneOf(['immediate', 'delay']),
  dateQuestion: array()
    .of(optionSchema)
    .when('delayType', (delayType) => {
      if (delayType === 'delay') {
        return array()
          .of(optionSchema)
          .min(1, 'Selecione uma pergunta')
          .required('Campo obrigatório');
      }
      return mixed().notRequired();
    }),
  destroy: boolean().nullable(),
  _local: boolean().nullable(),
}).required();

export type TriggerSchema = InferType<typeof triggerSchema>;

export const actionSchema = object({
  id: string().nullable(),
  label: string().min(2).required(),
  actionableType: actionableType().required().label('type'),
  sendChecklistOpenFlow: boolean().nullable(),
  destroy: boolean().nullable(),
  _local: boolean().nullable(),
  emailAttribute: array()
    .of(optionSchema)
    .when('actionableType', (actionableType) => {
      if (actionableType === 'email_checklist') {
        return array()
          .of(optionSchema)
          .min(1, 'Configure no mínimo um e-email')
          .required('E-mail é obrigatório');
      }
      return mixed().notRequired();
    }),
  user: array()
    .of(optionSchema)
    .when('actionableType', (actionableType) => {
      if (actionableType === 'email_user') {
        return array()
          .of(optionSchema)
          .min(1, 'Selecione no mínimo um e-mail')
          .required('E-mail é obrigatório');
      }
      return mixed().notRequired();
    }),
  webhookUrl: string()
    .url()
    .when('actionableType', (actionableType) => {
      if (actionableType === 'webhook') {
        return string().url().required('Url é obrigatória');
      }
      return mixed().notRequired();
    }),
  attribute: array()
    .of(optionSchema)
    .when('actionableType', (actionableType) => {
      if (actionableType === 'update_property') {
        return array()
          .of(optionSchema)
          .min(1, 'Selecione um atributo')
          .required('Atributo é obrigatório');
      }
      return mixed().notRequired();
    }),
  question: array()
    .of(optionSchema)
    .when('actionableType', (actionableType) => {
      if (actionableType === 'update_property') {
        return array()
          .of(optionSchema)
          .min(1, 'Selecione uma pergunta')
          .required('Pergunta é obrigatória');
      }
      return mixed().notRequired();
    }),
}).required();

export type ActionSchema = InferType<typeof actionSchema>;

const stepSchema = object({
  id: string(),
  name: string()
    .min(2, 'Título precisa ter no mínimo 2 caracteres')
    .required('Título é obrigatório'),
  stepType: stepType().required('Selecione o tipo da etapa'),
  triggers: array()
    .of(triggerSchema)
    .when('stepType', (stepType) => {
      if (stepType === 'canceling') {
        return mixed().notRequired();
      }
      return array()
        .of(triggerSchema)
        .min(1, 'Crie no mínimo um gatilho')
        .required();
    }),
  position: number().nullable(),
  actions: array().of(actionSchema).nullable(),
  taskForm: array().of(optionSchema),
  assignType: mixed<
    'member_task' | 'user_id' | 'none' | 'member_question' | 'last_member_task'
  >()
    .oneOf([
      'member_task',
      'user_id',
      'none',
      'member_question',
      'last_member_task',
    ])
    .required()
    .default('none'),
  /** tipo de associação de local */
  localType: mixed<
    'local_opening_task' | 'local_ticket_property' | 'local_id' | 'none'
  >()
    .oneOf(['local_opening_task', 'local_ticket_property', 'local_id', 'none'])
    .required()
    .default('none'),
  taskAttributes: array().of(attributeSchema).nullable(),
  members: array().of(optionSchema),
  memberQuestions: array().of(optionSchema),
  /** id do local diretamente */
  localSet: array()
    .of(optionSchema)
    .when('localType', (actionableType) => {
      if (actionableType === 'local_id') {
        return array()
          .of(optionSchema)
          .min(1, 'Configure um local para a tarefa')
          .required('Local é obrigatório');
      }
      return mixed().notRequired();
    }),
  /** local derivado de um atributo */
  localTicketProperty: array()
    .of(optionSchema)
    .when('localType', (actionableType) => {
      if (actionableType === 'local_ticket_property') {
        return array()
          .of(optionSchema)
          .min(1, 'Configure um local para a tarefa')
          .required('Configure um local a partir de uma propriedade');
      }
      return mixed().notRequired();
    }),
  autoFinish: boolean().optional(),
}).required();
export type StepSchema = InferType<typeof stepSchema>;

export function makeStepFormSchema() {
  // TODO Edit schema
  return stepSchema;
}
