/* eslint-disable no-nested-ternary */
import { useFragment } from '@/gql';
import { AnswerUpdateAttributes, AnswerUpdateInput } from '@/gql/graphql';
import { useAnswerUpdateInBatch } from '@/screens/Tickets/hooks/useAnswerUpdateInBatch';
import {
  AutomationAnswer_AnswerFragment,
  AutomationAnswerFragment,
  useFetchAnswer,
} from '@/screens/Tickets/hooks/useFetchAnswer';
import { useUpdateAnswer } from '@/screens/Tickets/hooks/useUpdateAnswer';
import { createContext } from '@/utils/react-utils';
import React from 'react';

import { PropertySchema } from './form-schemas';

const getFragmentData = useFragment;

export type PropertyFormParams = {
  propertyId: string;
  onClose?: () => void;
};

const propertyFormService = (
  params: PropertyFormParams & {
    type?: string | null;
    itemId?: string | null;
    questionId?: string | null;
    eventId?: string | null;
  },
) => {
  const { propertyId, type, itemId, questionId, eventId } = params;

  const beforePost = (formValues: PropertySchema): AnswerUpdateInput => {
    const { content, collectionItemId } = formValues;

    return {
      id: propertyId,
      content: `${content}`,
      collectionItemId,
      optionId: ['radio', 'checkbox'].includes(type || '') ? `${content}` : '',
    };
  };

  const beforePostInBatch = (
    formValues: PropertySchema,
  ): AnswerUpdateAttributes => {
    const { content, id } = formValues;

    return {
      id,
      content: `${content}`,
      optionId: ['radio', 'checkbox'].includes(type || '') ? `${content}` : '',
      questionType: type,
      itemId,
      questionId,
      eventId,
    };
  };

  const serialize = (fragment: AutomationAnswerFragment): PropertySchema => {
    const { id, content, collectionItemId } = getFragmentData(
      AutomationAnswer_AnswerFragment,
      fragment,
    );

    return {
      id,
      collectionItemId,
      content: content || '',
    };
  };

  return {
    beforePost,
    beforePostInBatch,
    serialize,
  };
};

export function usePropertyForm(params: PropertyFormParams) {
  const { propertyId, onClose } = params;
  const { updateAnswer, loading: updating, error } = useUpdateAnswer();
  const {
    answerUpdateInBatch,
    loading: updatingInBatch,
    error: errorInBatch,
  } = useAnswerUpdateInBatch();
  const { data, loading: loadingPropertyData } = useFetchAnswer({
    id: propertyId,
  });

  const question =
    data && Array.isArray(data) ? data[0].question : data?.question;

  const defaultValue = React.useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.map((answer) =>
        propertyFormService({
          ...params,
          type: question?.questionType,
        }).serialize(answer),
      );
    }

    return data
      ? propertyFormService({
          ...params,
          type: question?.questionType,
        }).serialize(data)
      : undefined;
  }, [data, params]);

  const onUpdate = React.useCallback(
    async (values: PropertySchema) => {
      await updateAnswer(
        propertyFormService({
          ...params,
          type: question?.questionType,
        }).beforePost(values),
      );
      if (!error) onClose?.();
    },
    [question?.questionType, error, onClose, params, updateAnswer],
  );

  const onUpdateInBatch = React.useCallback(
    async (values: PropertySchema[]) => {
      await answerUpdateInBatch({
        answers: values.map((answer) =>
          propertyFormService({
            ...params,
            type: question?.questionType,
            questionId: question?.id,
            eventId: data && Array.isArray(data) ? data[0]?.eventId : null,
            itemId: data && Array.isArray(data) ? data[0]?.itemId : null,
          }).beforePostInBatch(answer),
        ),
      });
      if (!errorInBatch) onClose?.();
    },
    [question?.questionType, errorInBatch, onClose, params, updateAnswer],
  );

  const onSubmit = ['checkbox'].includes(`${question?.questionType}`)
    ? onUpdateInBatch
    : onUpdate;

  return {
    defaultValue,
    loadingPropertyData,
    onSubmit,
    loading: updating || updatingInBatch,
    question: question,
    ...params,
  };
}

type UsePropertyForm = ReturnType<typeof usePropertyForm>;

export const [PropertyFormProvider, usePropertyFormContext] = createContext<
  UsePropertyForm
>();
