import React from 'react';
import {
  IconCheckedCheckbox,
  IconCheckbox,
  IconRadio,
  IconCheckedRadio,
} from '@/components_v2/ContextMenu/Icons';

import { MultiSelectionTypes } from '../interfaces';

function renderIcon(
  type: MultiSelectionTypes,
  isSelected: boolean,
): JSX.Element {
  switch (type) {
    case 'multi':
      return isSelected ? <IconCheckedCheckbox /> : <IconCheckbox />;
    case 'single':
      return isSelected ? <IconCheckedRadio /> : <IconRadio />;
    default:
      return <> </>;
  }
}

export default renderIcon;
