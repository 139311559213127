import { BaseField, FormLayout, HookForm } from '@/components_v2/HookForm';
import { useModals } from '@/hooks/ModalManager';
import { useCloneAutomationTicket } from '@/screens/Tickets/hooks/useCloneAutomationTicket';
import { Box, Button, ButtonGroup, Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { AutomationStepsSelect } from './Selects';

const cloneTicketFormSchema = yup
  .object({
    stepIds: yup.array().of(yup.string().required()),
  })
  .required();

type CloneTicketFormSchema = yup.InferType<typeof cloneTicketFormSchema>;

type TicketFormProps = {
  id: string;
  automationFlowId: string;
  ticketCode: string;
};

type FieldType = {
  field: { value: { id: string }[]; onChange: (keys: any) => void };
};

function Fields({ automationFlowId }: { automationFlowId?: string }) {
  const form = useFormContext<CloneTicketFormSchema>();

  return (
    <FormLayout>
      <Controller
        name={'stepIds' as never}
        control={form.control}
        shouldUnregister
        render={({ field }: FieldType) => {
          return (
            <BaseField name="stepIds" label="Etapas pendentes">
              <AutomationStepsSelect
                selectionMode="multiple"
                automationFlowId={automationFlowId || ''}
                value={field.value?.map((e: { id: string }) => e?.id) as any}
                initialOptions={(field.value || []) as any}
                onChange={(keys) => {
                  const stepIds = keys.map((step) => step.id);
                  field.onChange(stepIds);
                }}
              />
            </BaseField>
          );
        }}
      />
    </FormLayout>
  );
}

const WrappedForm = (props: { ticketData?: TicketFormProps }) => {
  const { ticketData } = props;
  const modals = useModals();
  const [clone] = useCloneAutomationTicket();

  const onClose = React.useCallback(() => modals.close('clone-ticket-form'), [
    modals,
  ]);

  const handleSubmit = React.useCallback(
    async (values: CloneTicketFormSchema) => {
      clone({
        id: `${ticketData?.id}`,
        ticketCode: `${ticketData?.ticketCode}`,
        notCloneStepIds: values.stepIds || [],
      });
      onClose();
    },
    [clone, onClose, ticketData?.id, ticketData?.ticketCode],
  );

  return (
    <fieldset>
      <HookForm<CloneTicketFormSchema>
        px="6"
        pb="4"
        autoComplete="off"
        id="step-form"
        resolver={yupResolver(cloneTicketFormSchema)}
        schema={cloneTicketFormSchema}
        onSubmit={handleSubmit}
      >
        <Fields automationFlowId={ticketData?.automationFlowId} />

        <Flex justifyContent="flex-end">
          <ButtonGroup mt="4">
            <Button
              variant="outline"
              onClick={() => {
                modals.close('clone-ticket-form');
              }}
            >
              Cancelar
            </Button>
            <Button type="submit" colorScheme="primary">
              Clonar
            </Button>
          </ButtonGroup>
        </Flex>
      </HookForm>
    </fieldset>
  );
};

export function useCloneTicketForm() {
  const modals = useModals();

  const openForm = React.useCallback(
    (ticketData?: TicketFormProps) => {
      modals.open({
        id: 'clone-ticket-form',
        title: 'Clonar ticket',
        body: (
          <Box flex="1">
            <WrappedForm ticketData={ticketData} />
          </Box>
        ),
      });
    },
    [modals],
  );

  return { openForm };
}
