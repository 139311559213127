import { FeedbackInfo } from '@components/Feedback';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { filtersReducer, serializeFilters } from './data';
import {
  FilterContextData,
  FilterContextProps,
  FilterCreateData,
  OptionData,
  OrderData,
  SelectedFilter,
  SelectedFilterBase,
} from './interfaces';
import fetchFilters from './services/fetchFilters';
import fetchOptions from './services/fetchOptions';

function useFilterContext({
  screen,
  initialFilters,
  initialState,
}: FilterContextProps) {
  const [state, dispatch] = useReducer(filtersReducer, {
    columns: initialState || {},
  });
  const [filters, setFilters] = useState<FilterCreateData[]>([]);

  const handleError = useCallback((error: Error) => {
    FeedbackInfo({
      mainText: 'Atenção!',
      subText: error.message,
    });
  }, []);

  const getQueryString = useCallback(() => {
    return serializeFilters(state.columns, state.order);
  }, [state]);

  const getFilters = useCallback(
    async (
      screen: string,
      initialFilters: Record<string, any>[] | null = null,
    ): Promise<void> => {
      try {
        const filtersData = await fetchFilters(screen);
        for (let i = 0; i < filtersData.length; i++) {
          const filter = filtersData[i];
          const initialFilter =
            initialFilters &&
            initialFilters.find(
              (item) =>
                item.column === filter.column && item.type === filter.type,
            );
          if (initialFilter) {
            dispatch({
              type: 'select_option',
              props: {
                column: filter.column,
                type: filter.type,
                selected: initialFilter.selected,
                table: filter.table,
              },
            });
          }
        }
        setFilters(filtersData);
      } catch (err) {
        if (err instanceof Error) handleError(err);
      }
    },
    [handleError],
  );

  useEffect(() => {
    getFilters(screen, initialFilters);
  }, [getFilters, screen, initialFilters]);

  const getOptions = useCallback(
    async (column: string): Promise<OptionData[]> => {
      try {
        const filter = filters.find((filter) => filter.column === column);
        if (!filter) throw new Error();
        const optionsData = await fetchOptions(filter.url, getQueryString());
        return optionsData;
      } catch (err) {
        if (err instanceof Error) handleError(err);
        return [];
      }
    },
    [handleError, filters, getQueryString],
  );

  const selectOption = useCallback(
    (data: SelectedFilter) => {
      if (data.type !== 'search') {
        const childFilters = filters.filter((filter) =>
          filter.parentFields.includes(data.column),
        );
        for (let i = 0; i < childFilters.length; i++) {
          const { column, type, table } = childFilters[i];
          if (state.columns[column][type]) {
            dispatch({ type: 'clear_option', props: { column, type, table } });
          }
        }
      }
      dispatch({ type: 'select_option', props: data });
    },
    [filters, state.columns],
  );

  const clearFilters = useCallback(() => {
    dispatch({ type: 'clear_all' });
  }, []);

  const getSelectedOptions = useCallback(
    ({ column, type }: SelectedFilterBase) => {
      if (state.columns[column]) {
        if (state.columns[column][type]) {
          return state.columns[column][type];
        }
      }
      return undefined;
    },
    [state.columns],
  );

  const clearOption = useCallback((filterData: SelectedFilterBase) => {
    dispatch({ type: 'clear_option', props: filterData });
  }, []);

  const setOrder = useCallback((orderData: OrderData) => {
    dispatch({ type: 'set_order', props: orderData });
  }, []);

  return {
    getOptions,
    selectOption,
    clearOption,
    getQueryString,
    clearFilters,
    filters,
    getSelectedOptions,
    order: state.order,
    selectedOptions: state.columns,
    setOrder,
  };
}

export type UseFilterContext = typeof useFilterContext;

const FilterContext = createContext<FilterContextData>({} as FilterContextData);

type FilterProviderProps = {
  useFilterImpl?: UseFilterContext;
} & FilterContextProps;

export const FilterProvider: React.FC<FilterProviderProps> = ({
  children,
  screen,
  initialFilters,
  initialState,
  useFilterImpl = useFilterContext,
}) => {
  const value = useFilterImpl({ screen, initialFilters, initialState });

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export function useFilter(): FilterContextData {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used whithin an FilterProvider');
  }

  return context;
}
