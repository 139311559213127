import { Answer, IField, IFormState } from '../interfaces';
import { Comparison } from './comparison';

export function parseFieldConditionals(
  field: IField,
  value: Answer | Answer[],
): { error: boolean; alert: string | undefined } {
  if (!field.conditional || !field.conditional.actions || Array.isArray(value))
    return { error: false, alert: undefined };

  const { conditional } = field;
  const generateError = conditional.actions.find(
    (e) => e.type === 'prevent_default',
  );

  const numericValue = parseFloat(
    `${value.content}`.replace('.', '').replace(',', '.'),
  );

  if (Number.isNaN(numericValue)) return { error: false, alert: undefined };

  const alert = conditional.actions.find((e) => e.type === 'alert');

  const comparison = Comparison[conditional.type];

  const isValid = comparison({
    comparison: conditional.comparison,
    current: numericValue,
  });

  return {
    error: Boolean(!isValid && generateError),
    alert: !isValid && alert ? alert.message : undefined,
  };
}

export function parseAllConditionals(state: IFormState) {
  const { fields, answers } = state;

  const alerts: Record<string, string | undefined> = {};
  const errors: Record<string, boolean> = {};

  const fieldsArr = Object.entries(fields);

  for (let index = 0; index < fieldsArr.length; index++) {
    const [key, entry] = fieldsArr[index];

    const answer = answers[key];

    if (answer) {
      const conditional = parseFieldConditionals(entry, answer);

      alerts[key] = conditional.alert;
      errors[key] = conditional.error;
    }
  }

  return { alerts, errors };
}
