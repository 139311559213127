import {
  GridItem,
  GridItemProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import React from 'react';

const GI = (props: GridItemProps): JSX.Element => (
  <GridItem
    maxWidth="100%"
    minWidth="0"
    display="flex"
    alignItems="center"
    justifyContent="center"
    px={2}
    {...props}
  />
);

function ShowContentPopover({
  answerOptions,
  formatContent,
}: {
  answerOptions: string[];
  formatContent?: string | null;
}) {
  return (
    <Popover>
      <PopoverTrigger>
        <Text
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="pre-wrap"
          fontSize="lg"
          width="100%"
          noOfLines={2}
          fontWeight={500}
          textAlign="center"
          _hover={{
            bgColor: 'gray.100',
            borderRadius: 6,
            padding: 1,
          }}
        >
          {formatContent || '-'}
        </Text>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          {answerOptions?.map((option) => (
            <div>{option}</div>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export { GI, ShowContentPopover };
