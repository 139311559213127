import debounce from '@/utils/debounce';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { BiSearch } from 'react-icons/bi';

import { DebouncedInputProps } from './DebouncedInput.types';

const DebouncedInput: React.FC<DebouncedInputProps> = ({
  onChange,
  initialValue,
  ...rest
}) => {
  const [value, setValue] = useState<string>(initialValue || '');

  const valueRef = useRef<string>();
  const [debounced] = useState(() => {
    return debounce(async () => {
      if (valueRef.current !== undefined) {
        onChange(valueRef.current);
      }
    }, 200);
  });

  useEffect(() => {
    valueRef.current = value;
    debounced.cancel();
    debounced();
  }, [debounced, value]);

  return (
    <InputGroup>
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        {...rest}
      />
      <InputRightElement>
        <BiSearch color="#E2E8F0" />
      </InputRightElement>
    </InputGroup>
  );
};

export default DebouncedInput;
