import { DisplayIf, Field, FormLayout, Option } from '@/components_v2/HookForm';
import WCDatePicker from '@/components_v2/WCDatePicker';
import config from '@/Constants';
import { UserProfile } from '@/screens/registrations/Users/types/user';
import {
  Avatar,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { AsyncSelect, OptionProps, Select } from 'chakra-react-select';
import moment from 'moment';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { InnerRef } from 'react-select';

import { TagsSelectCreate } from './tagsCreate';
import { TaskSchema } from './types';
import {
  fetchFormularies,
  fetchLocals,
  fetchTags,
  fetchUsers,
} from './utils/fetchers';

export function NameInput() {
  const form = useFormContext<TaskSchema>();
  const { t } = useTranslation();
  const { setValue } = form;

  const nameDisabled = form.watch('inheritChecklistName');

  const formulary = form.watch('formulary');

  React.useEffect(() => {
    if (formulary?.label && nameDisabled) {
      setValue('name', formulary?.label);
    }
  }, [formulary, nameDisabled, setValue]);

  return (
    <Field<TaskSchema>
      isDisabled={!!nameDisabled}
      name="name"
      label={`${t('tasks.form.name')} *`}
      placeholder="Task name"
    />
  );
}

export function FormularySelect() {
  const form = useFormContext<TaskSchema>();
  const { t } = useTranslation();

  const { field, fieldState } = useController({
    control: form.control,
    shouldUnregister: true,
    name: 'formulary',
  });

  return (
    <FormLayout>
      <Field<TaskSchema>
        name="associateFormulary"
        size="lg"
        type="switch"
        label={t('tasks.form.associate_checklist')}
      />

      <DisplayIf<TaskSchema>
        name="associateFormulary"
        condition={(associate) => !!associate}
      >
        <FormLayout>
          <FormControl isInvalid={fieldState.invalid}>
            <FormLabel>{t('tasks.form.checklist')}</FormLabel>
            <AsyncSelect
              isClearable
              cacheOptions
              defaultOptions
              placeholder={t('tasks.form.checklist_placeholder')}
              chakraStyles={{
                container: (provided) => ({
                  ...provided,
                  flex: 1,
                }),
              }}
              isMulti={false}
              tagVariant="solid"
              loadOptions={fetchFormularies}
              {...field}
            />
          </FormControl>

          <Field<TaskSchema>
            name="inheritChecklistName"
            type="switch"
            size="lg"
            label={t('tasks.form.inherit_checklist_name')}
          />
        </FormLayout>
      </DisplayIf>
    </FormLayout>
  );
}

export function TaskAboutSelect() {
  const form = useFormContext<TaskSchema>();
  const { t } = useTranslation();

  const { field, fieldState } = useController({
    control: form.control,
    name: 'origin_type',
  });

  return (
    <FormLayout>
      <FormControl isInvalid={fieldState.invalid}>
        <FormLabel>{t('tasks.form.task_type_select')}</FormLabel>
        <Select
          isClearable
          defaultValue={null}
          placeholder={t('tasks.form.task_type_select')}
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              flex: 1,
            }),
          }}
          isMulti={false}
          tagVariant="solid"
          options={[
            {
              label: 'Usuário',
              value: 'User',
            },
          ]}
          {...field}
        />
      </FormControl>
    </FormLayout>
  );
}

const UserSelectOption: React.FC<
  { innerRef: InnerRef } & OptionProps<Option, false>
> = ({ innerRef, innerProps, data }) => {
  return (
    <HStack
      _hover={{ bg: 'gray.100', cursor: 'pointer' }}
      py={1}
      px={2}
      {...innerProps}
      ref={innerRef}
    >
      <Avatar name={data.label} size="sm" />
      <Text>{data.label}</Text>
    </HStack>
  );
};

export function MemberSelect({
  schemaName,
}: {
  schemaName: 'member' | 'origin_id';
}) {
  const form = useFormContext<TaskSchema>();
  const { t } = useTranslation();

  const { field, fieldState } = useController({
    control: form.control,
    name: schemaName,
  });

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FormLabel>
        {schemaName === 'member'
          ? `${t('tasks.form.responsible')} *`
          : t('tasks.form.task_user_select')}{' '}
      </FormLabel>
      <AsyncSelect
        isClearable
        cacheOptions
        isInvalid={fieldState.invalid}
        defaultOptions
        placeholder={t('tasks.form.user_placeholder')}
        chakraStyles={{
          container: (provided) => ({
            ...provided,
            flex: 1,
          }),
        }}
        isMulti={false}
        tagVariant="solid"
        components={{
          Option: UserSelectOption as any,
        }}
        loadOptions={fetchUsers}
        {...field}
      />
      <FormErrorMessage>Membro é obrigatório</FormErrorMessage>
    </FormControl>
  );
}

function useProfilesQuery(userId: string) {
  const query = useQuery<UserProfile[]>(
    `${config.USER_PROFILES}?user_id=${userId}`,
    {
      enabled: !!userId,
    },
  );

  return query;
}

export function ProfileSelect() {
  const formMethods = useFormContext<TaskSchema>();
  const { watch, control } = formMethods;
  const { field } = useController({ control, name: 'user_profile_id' });

  const member = watch('member');

  const memberId = member?.value;

  const { data, isLoading } = useProfilesQuery(memberId!);

  if (!memberId) return <></>;

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (!data?.length) return <></>;
  return (
    <FormControl>
      <FormLabel>Perfil do usuário</FormLabel>
      <RadioGroup value={field?.value} onChange={field.onChange}>
        <Stack spacing={5} direction="row" maxW={450} overflow="scroll">
          {data?.map((profile) => (
            <Radio size="lg" value={profile.id} key={profile.id}>
              {profile.name}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  );
}

export function DateInputs() {
  const formMethods = useFormContext<TaskSchema>();
  const { control } = formMethods;
  const endDateField = useController({ control, name: 'date_end' });
  const startDateField = useController({ control, name: 'date_start' });
  const { t } = useTranslation();

  const start = startDateField?.field?.value;

  const end = endDateField?.field?.value;

  const onChangeEndDate = (value: Date) => {
    endDateField.field.onChange(value);
    startDateField.field.onChange(undefined);
  };

  return (
    <FormLayout>
      <FormControl isInvalid={endDateField.fieldState.invalid}>
        <FormLabel>{t('tasks.form.end_date')}</FormLabel>
        <WCDatePicker
          onChange={onChangeEndDate}
          selected={end}
          showTimeSelect
          dateFormat="dd/MM/yyyy h:mm aa"
          minDate={start ? new Date(start) : new Date()}
        />
        <FormErrorMessage>
          {endDateField.fieldState.error?.message}
        </FormErrorMessage>
      </FormControl>

      <Field<TaskSchema>
        type="switch"
        size="lg"
        name="postponedStart"
        label={t('tasks.form.postpone_start')}
      />

      <DisplayIf<TaskSchema>
        name="postponedStart"
        condition={(value) => !!value}
      >
        <FormControl isInvalid={startDateField.fieldState.invalid}>
          <FormLabel>{t('tasks.form.start_date')}</FormLabel>
          <WCDatePicker
            onChange={startDateField.field.onChange}
            selected={start}
            showTimeSelect
            dateFormat="dd/MM/yyyy h:mm aa"
            minDate={new Date()}
            maxDate={end ? new Date(end) : undefined}
            maxTime={
              moment(start).isSame(moment(end), 'day')
                ? moment(endDateField.field.value)
                    .subtract(30, 'minutes')
                    .toDate()
                : undefined
            }
            minTime={
              moment(start).isSame(moment(end), 'day')
                ? moment().set({ hour: 0, minute: 0 }).toDate()
                : undefined
            }
          />
          <FormErrorMessage>
            {startDateField.fieldState.error?.message}
          </FormErrorMessage>
        </FormControl>
      </DisplayIf>
    </FormLayout>
  );
}

export function LocalSelect() {
  const form = useFormContext<TaskSchema>();
  const { t } = useTranslation();

  const { field, fieldState } = useController({
    control: form.control,
    name: 'local',
  });

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FormLabel>{t('tasks.form.local')}</FormLabel>
      <AsyncSelect
        isClearable
        cacheOptions
        defaultOptions
        placeholder={t('tasks.form.local_placeholder')}
        chakraStyles={{
          container: (provided) => ({
            ...provided,
            flex: 1,
          }),
        }}
        isMulti={false}
        tagVariant="solid"
        loadOptions={fetchLocals}
        {...field}
      />
    </FormControl>
  );
}

export function TagsSelect() {
  const [renderKey, render] = React.useState(new Date().getTime());
  const form = useFormContext<TaskSchema>();
  const { t } = useTranslation();

  const { field, fieldState } = useController({
    control: form.control,
    name: 'tags',
  });

  return (
    <FormLayout>
      <FormControl isInvalid={fieldState.invalid}>
        <FormLabel>{t('tasks.form.tags')}</FormLabel>
        <HStack alignItems="center">
          <AsyncSelect
            key={renderKey}
            isClearable
            cacheOptions
            menuPlacement="top"
            defaultOptions
            menuShouldScrollIntoView
            loadOptions={fetchTags}
            chakraStyles={{
              container: (provided) => ({
                ...provided,
                flex: 1,
              }),
              multiValue: (provided, state) => ({
                ...provided,
                backgroundColor: state.data?.color,
              }),
            }}
            tagVariant="solid"
            isMulti
            {...field}
          />
          <TagsSelectCreate
            onSubmit={(tag) => {
              field.onChange([
                ...(field.value || []),
                { label: tag.name, value: tag.id, color: tag.color },
              ]);
              render(new Date().getTime());
            }}
          />
        </HStack>
      </FormControl>
    </FormLayout>
  );
}

export function ActiveSwitch() {
  const form = useFormContext<TaskSchema>();
  const { t } = useTranslation();
  const { setValue } = form;

  const nameDisabled = form.watch('inheritChecklistName');

  const formulary = form.watch('formulary');

  React.useEffect(() => {
    if (formulary?.label && nameDisabled) {
      setValue('name', formulary?.label);
    }
  }, [formulary, nameDisabled, setValue]);

  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel fontWeight={600} mb="0">
        {t('common.active')}
      </FormLabel>
      <Switch
        defaultChecked
        colorScheme="brand"
        size="lg"
        {...form.register('active')}
      />
    </FormControl>
  );
}
