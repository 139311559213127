import React from 'react';
import { useSignoffContext } from '@/modules/circulabi/hooks/useSignOff';
import { getAmount } from '@/modules/circulabi/utils/getAmount';
import { chakra } from '@chakra-ui/react';

function pluralize(str: string, amount: number) {
  if (amount === 1) {
    return `${amount} ${str}`;
  }
  return `${amount} ${str}s`;
}

export function AmountLabel() {
  const { state } = useSignoffContext();
  if (!state.result) return null;

  const amount = getAmount({
    current: state.currentStock,
    safety: state.safetyStock,
  });

  if (amount <= 0) return null;

  return (
    <chakra.div display="flex" flexDir="column" gap="2">
      <chakra.p fontSize="md">Quantidade liberada:</chakra.p>
      <chakra.p fontWeight="bold" fontSize="lg">
        {pluralize('unidade', amount)}
      </chakra.p>
    </chakra.div>
  );
}
