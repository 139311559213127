/* eslint-disable radix */
import TextInput from '@/components_v2/inputs/BaseInput';
import debounce from '@/utils/debounce';
import Popup from 'reactjs-popup';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { numberMask, timeMask } from '@/utils/Text/masks';
import { Container, DropdownButton } from './styles';
import TimePickerRenderer from './TimePickerDropdown';
import { IconChevronDown } from '../ContextMenu/Icons';
import { dateToTimeString, parseTimeString } from './utils/TimeUtils';
import { TimePickerProps } from './interfaces';

const TimePicker: React.FC<TimePickerProps> = (props: TimePickerProps) => {
  const { onChange, initialValues, hasError = false, disabled = false } = props;

  const parsedInitialValues = useMemo(() => {
    return initialValues ? dateToTimeString(initialValues) : '';
  }, []);

  const [state, setState] = useState(parsedInitialValues);

  const textRef = useRef<HTMLInputElement>(null);

  const [debouncedOnChange] = useState(() => {
    return debounce((func) => {
      func();
    }, 500);
  });

  const setUncontrolledInput = useCallback((val: string) => {
    if (textRef?.current) textRef.current.value = val;
  }, []);

  const handleInputChange = useCallback(
    (time: string) => {
      const maskedValue = numberMask(time);
      setUncontrolledInput(maskedValue);
      setState(`${maskedValue}`);
    },
    [setUncontrolledInput],
  );

  const onConfirm = useCallback(() => {
    setState((oldValue) => {
      const newValue = timeMask(oldValue) || '00:00';
      setUncontrolledInput(newValue);
      return newValue;
    });
  }, []);

  useEffect(() => {
    debouncedOnChange(() => {
      onChange(parseTimeString(state));
    });
  }, [state, debouncedOnChange, onChange]);

  const arrTime = useMemo(() => state.split(':'), [state]);

  return (
    <Container>
      <TextInput
        disabled={disabled}
        ref={textRef}
        onBlur={onConfirm}
        hasError={hasError}
        defaultValue={parsedInitialValues}
        type="TEXT"
        onChange={(e) => handleInputChange(e.target.value)}
      />
      <Popup
        nested
        trigger={
          <DropdownButton>
            <IconChevronDown />
          </DropdownButton>
        }
        position={['bottom right']}
        disabled={disabled}
        closeOnDocumentClick
        on={['click']}
      >
        <TimePickerRenderer
          defaultValue={arrTime}
          onChange={handleInputChange}
        />
      </Popup>
    </Container>
  );
};

export default TimePicker;
