import { useFiltersByScreen } from '@/components_v2/Filtersv3/filter-store';
import { FlaggedAnswersConnectionFragmentFragment } from '@/gql/graphql';
import { filterObject, reduceNestedFilters } from '@/utils/filters';
import { createContext } from '@/utils/react-utils';
import React, { useCallback } from 'react';

import { JustificationContextValue } from '../types';
import { useFetchFlaggedAnswersConnection } from './useFetchFlaggedAnswers';

const [JustificationsContext, useJustificationsContext] = createContext<
  JustificationContextValue
>();

const JustificationsProvider: React.FC = ({ children }) => {
  const [selectedJustificationId, setSelectedJustificationId] = React.useState<
    string | undefined
  >();
  const [search, setSearch] = React.useState('');

  const { byKey } = useFiltersByScreen();

  const reduced = reduceNestedFilters(byKey);

  const answersFilters = filterObject(reduced);

  const answersRangeFilter = answersFilters.updated_at
    ? JSON.parse(answersFilters.updated_at)
    : {};

  delete answersFilters['updated_at'];

  const eventsRangeFilter = answersFilters.finished_at
    ? JSON.parse(answersFilters.finished_at)
    : {};

  delete answersFilters['finished_at'];

  const filters = {
    answers: { ...answersFilters, ...answersRangeFilter },
    events: { ...eventsRangeFilter },
  };

  const {
    data,
    loading: isLoading,
    fetchMore,
    pageInfo,
    refetch,
  } = useFetchFlaggedAnswersConnection({
    first: 10,
    search,
    filters,
  });

  const items: FlaggedAnswersConnectionFragmentFragment[] = React.useMemo(
    () => data as FlaggedAnswersConnectionFragmentFragment[],
    [data],
  );

  React.useEffect(() => {
    setSelectedJustificationId((old) => {
      if (old) return old;

      return items?.[0]?.id;
    });
  }, [items]);

  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: {
        search,
        after: pageInfo?.endCursor,
        filters,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          flaggedAnswersConnection: {
            ...fetchMoreResult.flaggedAnswersConnection,
            edges: [
              ...prev.flaggedAnswersConnection.edges,
              ...fetchMoreResult.flaggedAnswersConnection.edges,
            ],
          },
        };
      },
    });
  }, [fetchMore, pageInfo?.endCursor, search, filters]);

  const currentIndex = React.useMemo(
    () => items?.findIndex((e) => e?.id === selectedJustificationId),
    [items, selectedJustificationId],
  );

  const lastIndex = React.useMemo(() => (items?.length || 0) - 1, [items]);

  const getNext = React.useCallback(async () => {
    if (!selectedJustificationId?.length || currentIndex === undefined) return;

    if (lastIndex === currentIndex && pageInfo?.hasNextPage) {
      await fetchNextPage();
    }

    const nextItem = items?.[currentIndex + 1];

    if (!nextItem) return;

    setSelectedJustificationId(nextItem.id);
  }, [
    currentIndex,
    pageInfo?.hasNextPage,
    items,
    fetchNextPage,
    lastIndex,
    selectedJustificationId?.length,
  ]);

  const getPrev = React.useCallback(() => {
    if (
      !selectedJustificationId?.length ||
      !currentIndex ||
      currentIndex - 1 < 0
    )
      return;

    const prevItem = items?.[currentIndex - 1];

    if (!prevItem) return;

    setSelectedJustificationId(prevItem.id);
  }, [currentIndex, items, selectedJustificationId?.length]);

  return (
    <JustificationsContext
      value={{
        getNext,
        getPrev,
        currentIndex,
        items: items as FlaggedAnswersConnectionFragmentFragment[],
        isLoading,
        hasNextPage: !!pageInfo?.hasNextPage,
        fetchNextPage,
        selectedJustificationId,
        setSearch,
        selectJustification: setSelectedJustificationId,
        refetch,
      }}
    >
      {children}
    </JustificationsContext>
  );
};

export { JustificationsProvider, useJustificationsContext };
