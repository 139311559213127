import { IApiHelper } from '@shared/data/protocols/http/IApiHelper';
import {
  BaseApiEditParams,
  BaseApiCreateParams,
  BaseApiUpdateParams,
  BaseApiDeleteParams,
  BaseApiFetchOptionsParams,
  ApiObject,
  BaseApiTemplateParams,
} from '@shared/domain/interfaces';
import { FetchFormularyResponse } from '@/modules/core/domain/interfaces';
import IApiServices from '@shared/domain/usecases/IApiServices';
import { Formulary } from '@/modules/core/domain/interfaces/IFormulary';
import {
  QueryParams,
  QueryResponse,
} from '@/modules/queryfilter/domain/interfaces';
import { HttpResponse } from '../protocols/http';

class ApiEdit<ApiScreenGeneric = any> implements IApiServices {
  private api: IApiHelper;
  private url: string;
  private defaultFilters: undefined | string;

  constructor(apiHelper: IApiHelper, url: string, defaultFilters?: string) {
    this.api = apiHelper;
    this.url = url;
    this.defaultFilters = defaultFilters;
  }

  public async getProperties<F>(): Promise<F> {
    let url = `${this.url}_columns?`;
    url += this.defaultFilters?.substr(1);
    const response = await this.api.get({
      url: `${url}`,
    });
    return response.body;
  }

  public async fetch(
    params: QueryParams,
  ): Promise<QueryResponse<ApiScreenGeneric>> {
    let url = `${this.url}?page=${params.page}&size=${params.size}`;
    if (params.order && params.orderBy)
      url += `&orderBy=${params.orderBy}&orderType=${params.order}`;
    if (params.filters) url += params.filters;
    if (this.defaultFilters) url += `${this.defaultFilters}`;

    const response = await this.api.get({ url });

    const data: QueryResponse<ApiScreenGeneric> = response.body;

    return data;
  }

  public async getTemplate(
    params?: BaseApiTemplateParams,
  ): Promise<FetchFormularyResponse | Formulary> {
    const entityId = params?.entityId;
    let url = `${this.url}/new${entityId ? `?entity_id=${entityId}` : ''}`;
    if (this.defaultFilters) url += `?${this.defaultFilters}`;
    const response = await this.api.get({ url });
    return response.body;
  }

  public async create(params: BaseApiCreateParams): Promise<HttpResponse<any>> {
    let { url } = this;
    if (this.defaultFilters) url += `?${this.defaultFilters}`;
    const response = await this.api.post({ url, body: params });
    return response;
  }

  public async edit(params: BaseApiEditParams): Promise<ApiObject> {
    let { url } = this;
    if (this.defaultFilters) url += `?${this.defaultFilters}`;
    const response = await this.api.get({
      url: `${url}/${params.id}/edit`,
    });
    return response.body;
  }

  public async update(params: BaseApiUpdateParams): Promise<HttpResponse<any>> {
    let response: HttpResponse<any>;
    let { url } = this;
    if (params.updateAll) {
      if (this.defaultFilters) url += `this.defaultFilters`;
      response = await this.api.patch({
        url,
        body: { update_all: params.updateAll, ...params.data },
      });
    } else if (params.ids.length === 1) {
      url = `${url}/${params.ids[0]}`;
      if (this.defaultFilters) url += `?${this.defaultFilters}`;
      response = await this.api.patch({
        url,
        body: {
          ...params.data,
        },
      });
    } else {
      if (this.defaultFilters) url += `?${this.defaultFilters}`;
      response = await this.api.patch({
        url,
        body: { ids: params.ids, ...params.data },
      });
    }
    return response;
  }

  public async delete(params: BaseApiDeleteParams): Promise<boolean> {
    let response: HttpResponse<any>;
    let { url } = this;
    if (params.ids.length === 1) {
      url = `${url}/${params.ids[0]}`;
      if (this.defaultFilters) url += `?${this.defaultFilters}`;
      response = await this.api.delete({ url });
    } else {
      if (this.defaultFilters) url += `?${this.defaultFilters}`;
      response = await this.api.delete({
        url,
        body: { ids: params.ids },
      });
    }
    return response.statusCode >= 200 && response.statusCode < 300;
  }

  public async fetchOptions(params: BaseApiFetchOptionsParams): Promise<any> {
    const response = await this.api.get({ url: params.url });
    return response.body;
  }
}

export default ApiEdit;
