import ExporterContextMenu from '@/components_v2/ExporterContextMenu';
import { fetchUser } from '@/hooks/Authentication/services/fetchUser';
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useModals } from '@/hooks/ModalManager';
import { getExporter } from '@/screens/monitoring/EventReports/data/getExporter';
import {
  fetchLocals,
  fetchUsers,
} from '@/screens/registrations/Tasks/Dashboard/components/TaskForm/utils/fetchers';
import { Tag as TagInferface } from '@/screens/registrations/Tasks/interfaces';
import { updateTaskEvent } from '@/screens/registrations/Tasks/services/updateTaskEvent';
import { updateTaskService } from '@/screens/registrations/Tasks/services/updateTaskService';
import { Card } from '@/screens/Workflow/Dashboard/components/misc';
import { getContrastColor } from '@/utils/colors/getContrastColor';
import { openInNewTab } from '@/utils/openInNewTab';
import snakeToCamel from '@/utils/snakeToCamel';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  DrawerCloseButton,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Tag,
  Text,
  useToast,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { AsyncSelect, Select } from 'chakra-react-select';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { BiDownload } from 'react-icons/bi';
import { FaCheck, FaLocationArrow, FaThumbsUp } from 'react-icons/fa';
import { FiCalendar, FiExternalLink, FiUser } from 'react-icons/fi';
import { RiDiscussFill, RiErrorWarningFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import StatusTag from './componets/StatusTag';
import TaskActions from './componets/TaskActions';
import { TaskOptions } from './componets/TaskView.options';
import { useAddCommentModal } from './hooks/useAddCommentModal';
import {
  Checklist,
  ChecklistSummary as ChecklistSummaryType,
  FlaggedAnswer,
} from './interfaces';
import {
  ChecklistSummaryProvider,
  useChecklistSummary,
} from './useChecklistSummary';

type FlagItemProps = {
  item: FlaggedAnswer;
  event: ChecklistSummaryType;
  refresh?: () => void;
  flagged?: boolean;
};

const FlagItem = React.memo(({ item, flagged, refresh }: FlagItemProps) => {
  const {
    comment,
    formulary_name,
    options,
    question_name,
    section_name,
    discussion_topic,
  } = item;

  const { lastComment } = snakeToCamel(discussion_topic);

  const { openModal } = useAddCommentModal(refresh);

  return (
    <Card p="4" lineHeight="short">
      <Box mb="2">
        <Flex w="full" justifyContent="space-between">
          <Text
            mb="2"
            fontWeight="bold"
            color="muted"
            fontSize="sm"
            noOfLines={1}
          >
            {`${formulary_name} / ${section_name}`}
          </Text>
        </Flex>
        <Text fontSize="sm">{question_name}</Text>
      </Box>
      <VStack w="full" spacing="2" alignItems="flex-start">
        {options.map(
          (optionName) =>
            optionName && (
              <Tag
                key={optionName}
                size="lg"
                variant="solid"
                colorScheme={flagged ? 'orange' : 'gray'}
              >
                {optionName}
              </Tag>
            ),
        )}
      </VStack>

      <Flex flexDir="column" gap="1" mt="4" hidden={!comment}>
        <Text fontWeight="bold">Notas:</Text>
        <HStack>
          <Flex
            p="2"
            textAlign="start"
            minH="10"
            rounded="lg"
            w="full"
            bg="gray.100"
            overflow="auto"
          >
            {comment}
          </Flex>
        </HStack>
      </Flex>

      <Flex flexDir="column" mt="4" hidden={!lastComment}>
        <Text fontWeight="bold" color="darkText">
          Último comentário:
        </Text>
        <HStack>
          <Text fontWeight="bold" color="muted">
            {lastComment?.authorName}
          </Text>
          <Text color="muted">
            {moment(new Date(lastComment?.createdAt * 1000)).format(
              'DD/MM/YYYY HH:mm',
            )}
          </Text>
        </HStack>
        <HStack>
          <Text maxH={20} overflowY="auto" color="muted">
            {lastComment?.content}
          </Text>
        </HStack>
      </Flex>

      <Button
        onClick={() => openModal(item, flagged)}
        mt="4"
        size="xs"
        colorScheme="blue"
        variant="link"
      >
        <Flex alignItems="center">
          <Text fontSize="md">Adicionar justificativa</Text>
        </Flex>
      </Button>
    </Card>
  );
});

const ChecklistHeader = () => {
  const { t } = useTranslation();

  const modals = useModals();
  const { push } = useHistory();
  const { isLoading, data, type } = useChecklistSummary();

  const isLoaded = React.useMemo(() => !!data && !isLoading, [data, isLoading]);

  const openExternal = React.useCallback(() => {
    openInNewTab(
      `${window.location.origin}/external/event/${
        type === 'event' ? data?.id : data?.event_id
      }`,
    );
  }, [data?.event_id, data?.id, type]);

  return (
    <Flex p="6" pt="0" flexDir="column" gap="4">
      <SkeletonCircle size="16" isLoaded={isLoaded}>
        <Avatar
          borderWidth="3px"
          borderColor="white"
          boxShadow="lg"
          size="lg"
          name={data?.author_name}
          translate="no"
        />
      </SkeletonCircle>
      <SkeletonText
        w="full"
        mt="4"
        noOfLines={2}
        spacing="3"
        isLoaded={isLoaded}
      >
        <Flex flexDir="column">
          <Flex flexDir="row" justifyContent="space-between">
            <Heading fontSize="1.5rem" lineHeight={1}>
              {data?.parentable_name}
            </Heading>
            {!!data?.status && (
              <Flex minW="min-content">
                <StatusTag status={data?.status} type={type} />
              </Flex>
            )}
          </Flex>
          <Text fontWeight="bold" color="mutedText" fontSize="lg">
            {`${data?.author_name ? data?.author_name : ''}${
              data?.execution_date
                ? ` / ${moment(data?.execution_date).format('LL')}`
                : ''
            }`}
          </Text>
        </Flex>
      </SkeletonText>
      {(data?.event_id || type === 'event') && (
        <Flex gap="2" w="full">
          <ButtonGroup flex="1" isAttached size="md">
            <Button
              isDisabled={!isLoaded || data?.status === 'rescheduled'}
              onClick={() => {
                modals.pop();
                push(
                  `/app/reports/event/${
                    type === 'event' ? data?.id : data?.event_id
                  }`,
                );
              }}
              w="full"
              colorScheme="primary"
            >
              {t('common.view')}
            </Button>

            <IconButton
              onClick={openExternal}
              variant="outline"
              aria-label="external-link"
              icon={<Icon as={FiExternalLink} fontSize="17px" />}
            />
          </ButtonGroup>
          <ExporterContextMenu
            formButtonLabel="PDF"
            placement="bottom-start"
            hasPortal={false}
            getExporter={getExporter()}
            options={{
              objectId: (type === 'event' ? data?.id : data?.event_id) || '',
            }}
            button={
              <Button
                color="mutedText"
                leftIcon={<BiDownload fontSize="22px" />}
                flex="0.5"
                size="md"
                variant="outline"
                isDisabled={!isLoaded || data?.status === 'rescheduled'}
              >
                PDF
              </Button>
            }
            screenName="events"
          />
        </Flex>
      )}
    </Flex>
  );
};

export const FlaggedItemsList = () => {
  const { isLoading, data, revalidate } = useChecklistSummary();
  const [collapsed, setCollapsed] = React.useState(true);

  const isLoaded = React.useMemo(() => !!data && !isLoading, [data, isLoading]);

  const items = data?.flagged_answers || [];

  const commentItems = data?.unflagged_answers_with_comments || [];

  if (!items.length && !commentItems.length && isLoaded) return null;

  let commentsCount = 0;
  [...items, ...commentItems].forEach((item) => {
    if (item.comment) commentsCount++;
  });

  return (
    <>
      <Box mx="6" mt="4" display="flex" justifyContent="space-between">
        <Skeleton isLoaded={isLoaded}>
          <Flex gap="4">
            <HStack>
              <Icon as={RiErrorWarningFill} color="red.500" fontSize="22px" />
              <Text fontSize="xl" fontWeight="bold">
                {`${data?.flagged_answers?.length} itens sinalizados`}
              </Text>
            </HStack>
            <HStack>
              <Icon as={RiDiscussFill} color="grey" fontSize="22px" />
              <Text fontSize="xl" fontWeight="bold">
                {`${commentsCount} itens com notas`}
              </Text>
            </HStack>
          </Flex>
        </Skeleton>
      </Box>
      <Skeleton isLoaded={isLoaded} mx="6" mt="2" rounded="md">
        {!!data && !collapsed && (
          <List spacing="4">
            {items.map((e: FlaggedAnswer) => (
              <FlagItem
                item={e}
                key={e.id}
                event={data}
                refresh={revalidate}
                flagged
              />
            ))}
            {commentItems.map((e: FlaggedAnswer) => (
              <FlagItem item={e} key={e.id} event={data} refresh={revalidate} />
            ))}
          </List>
        )}
      </Skeleton>
      <Box w="full" px="8" py="3">
        <Button
          onClick={() => setCollapsed(!collapsed)}
          variant="link"
          fontSize="lg"
          colorScheme="blue"
        >
          Ver {collapsed ? 'mais' : 'menos'}
        </Button>
      </Box>
    </>
  );
};

const DetailItem: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <ListItem>
      <Flex gap="2" w="full" flexDir="column">
        <Text fontSize="md" fontWeight="bold" color="gray.500">
          {label}
        </Text>
        {children}
      </Flex>
    </ListItem>
  );
};

export const ChecklistDetails = () => {
  const { checkPermission } = useUserPermissions();
  const { isLoading, data, type, revalidate } = useChecklistSummary();
  const [editLocal, setEditLocal] = useState(false);
  const [editMember, setEditMember] = useState(false);
  const [editOriginType, setEditOriginType] = useState(false);
  const [editOriginId, setEditOriginId] = useState(false);
  const [selectedLocal, setSelectedLocal] = useState<string | undefined>(
    undefined,
  );
  const [selectedMember, setSelectedMember] = useState<string | undefined>(
    undefined,
  );
  const [selectedOriginType, setSelectedOriginType] = useState<
    string | undefined
  >(undefined);
  const [selectedOriginId, setSelectedOriginId] = useState<string | undefined>(
    undefined,
  );
  const [currentOriginUser, setCurrentOriginUser] = useState<any>(null);
  const { t } = useTranslation();
  const toast = useToast();

  const isLoaded = React.useMemo(() => !!data && !isLoading, [data, isLoading]);

  const getUser = useCallback(async () => {
    const user = await fetchUser(data?.origin_id);
    setCurrentOriginUser(user);
  }, [data]);

  useEffect(() => {
    if (data?.origin_id) getUser();
  }, [data, getUser]);

  const flaggedAnswersCount = React.useMemo(
    () => data?.flagged_answers?.length || 0,
    [data?.flagged_answers?.length],
  );

  const flaggedBadgeColor = React.useMemo(() => {
    if (flaggedAnswersCount === 0) return 'green';
    if (flaggedAnswersCount <= 3) return 'orange';
    return 'red';
  }, [flaggedAnswersCount]);

  const parsedFinishedAt = React.useMemo(
    () => (data?.finished_at ? moment(data?.finished_at).format('LLLL') : null),
    [data?.finished_at],
  );

  const parsedExecution = React.useMemo(() => {
    if (!data?.execution_date || !data?.execution_time) return null;

    const [year, month, day] = data.execution_date.split('-');
    const [hours, minutes, seconds] = data.execution_time.split(':');

    const formatedDate = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds,
    );

    return moment(formatedDate).format('LLLL');
  }, [data?.execution_date, data?.execution_time]);

  const parsedStartDate = React.useMemo(
    () =>
      data?.start_date ? moment(data?.start_date * 1000).format('LLLL') : null,
    [data?.start_date],
  );

  const updateLocal = async (localId?: string) => {
    if (localId) {
      const success = await updateTaskService({
        id: `${data?.id}`,
        local_id: localId,
      });

      if (success) {
        revalidate && revalidate();
        setEditLocal(false);
        setSelectedLocal(undefined);

        toast({
          status: 'success',
          title: t('common.success'),
          description: t('messageEdit.locals'),
        });
      }
    }
  };

  const updateMember = async (memberId?: string) => {
    if (memberId) {
      const success =
        type === 'event'
          ? await updateTaskEvent({
              eventId: `${data?.id}`,
              memberId,
              maintainExecution: true,
            })
          : await updateTaskService({
              id: `${data?.id}`,
              member_id: memberId,
            });

      if (success) {
        revalidate && revalidate();
        setEditMember(false);
        setSelectedMember(undefined);

        toast({
          status: 'success',
          title: t('common.success'),
        });
      }
    }
  };

  const updateOriginType = async (originType?: any) => {
    if (originType) {
      const success = await updateTaskService({
        id: `${data?.id}`,
        origin_type: originType,
      });

      if (success) {
        revalidate && revalidate();
        setEditOriginType(false);
        setSelectedOriginType(undefined);

        toast({
          status: 'success',
          title: t('common.success'),
        });
      }
    }
  };

  const updateOriginId = async (originId?: any) => {
    if (originId) {
      const success = await updateTaskService({
        id: `${data?.id}`,
        origin_id: originId,
      });

      if (success) {
        revalidate && revalidate();
        setEditOriginId(false);
        setSelectedOriginId(undefined);

        toast({
          status: 'success',
          title: t('common.success'),
        });
      }
    }
  };

  return (
    <>
      <Skeleton isLoaded={isLoaded} mx="6" mt="4">
        <Box display="flex" justifyContent="space-between">
          <Flex gap="1">
            <Text fontSize="xl" fontWeight="bold">
              {t('checklists.table.details')}
            </Text>
          </Flex>
        </Box>
      </Skeleton>
      <Skeleton mx="6" mt="2" rounded="md" isLoaded={isLoaded}>
        <Box>
          <Card p="4" lineHeight="short">
            <List spacing="6" px="2" w="full">
              <DetailItem label={t('checklists.summary.responsible')}>
                {editMember ? (
                  <HStack>
                    <AsyncSelect
                      cacheOptions
                      isClearable
                      defaultOptions
                      placeholder={t('tasks.form.user_placeholder')}
                      chakraStyles={{
                        container: (provided) => ({
                          ...provided,
                          flex: 1,
                        }),
                      }}
                      isMulti={false}
                      tagVariant="solid"
                      loadOptions={fetchUsers}
                      onChange={(e) => setSelectedMember(e?.value)}
                    />
                    {selectedMember && (
                      <IconButton
                        onClick={() => updateMember(selectedMember)}
                        icon={
                          <Icon
                            as={CheckIcon}
                            fontSize="16px"
                            color="green.600"
                          />
                        }
                        aria-label=""
                      />
                    )}
                    <IconButton
                      onClick={() => {
                        setEditMember(false);
                        setSelectedMember(undefined);
                      }}
                      icon={
                        <Icon as={CloseIcon} fontSize="14px" color="red.600" />
                      }
                      aria-label=""
                    />
                  </HStack>
                ) : (
                  <>
                    <HStack>
                      <HStack>
                        <Avatar
                          translate="no"
                          size="sm"
                          name={data?.author_name}
                        />
                        <Text fontSize="lg">{data?.author_name}</Text>
                      </HStack>
                      {!!checkPermission('admin') && (
                        <IconButton
                          onClick={() => setEditMember(true)}
                          icon={<Icon as={EditIcon} fontSize="14px" />}
                          aria-label=""
                        />
                      )}
                    </HStack>

                    {data?.user_profile_name && (
                      <HStack>
                        <Avatar
                          translate="no"
                          size="sm"
                          name={data?.user_profile_name}
                        />
                        <Text fontSize="lg">{data?.user_profile_name}</Text>
                      </HStack>
                    )}
                  </>
                )}
              </DetailItem>

              {data?.origin_type && (
                <DetailItem label={t('tasks.form.task_type_select')}>
                  {editOriginType ? (
                    <HStack>
                      <Select
                        isClearable
                        placeholder={t('tasks.form.task_type_select')}
                        chakraStyles={{
                          container: (provided) => ({
                            ...provided,
                            flex: 1,
                          }),
                        }}
                        isMulti={false}
                        tagVariant="solid"
                        onChange={(e) => setSelectedOriginType(e?.value)}
                        options={[
                          {
                            label: t('task.form.User'),
                            value: 'User',
                          },
                        ]}
                      />
                      {selectedOriginType && (
                        <IconButton
                          onClick={() => updateOriginType(selectedOriginType)}
                          icon={
                            <Icon
                              as={CheckIcon}
                              fontSize="16px"
                              color="green.600"
                            />
                          }
                          aria-label=""
                        />
                      )}
                      <IconButton
                        onClick={() => {
                          setEditOriginType(false);
                          setSelectedOriginType(undefined);
                        }}
                        icon={
                          <Icon
                            as={CloseIcon}
                            fontSize="14px"
                            color="red.600"
                          />
                        }
                        aria-label=""
                      />
                    </HStack>
                  ) : (
                    <HStack>
                      <HStack>
                        <Text fontSize="lg">
                          {t(`tasks.form.${data?.origin_type}`)}
                        </Text>
                      </HStack>
                      {!!checkPermission('admin') && (
                        <IconButton
                          onClick={() => setEditOriginType(true)}
                          icon={<Icon as={EditIcon} fontSize="14px" />}
                          aria-label=""
                        />
                      )}
                    </HStack>
                  )}
                </DetailItem>
              )}

              {data?.origin_id && currentOriginUser && (
                <DetailItem label={t('tasks.form.task_user_select')}>
                  {editOriginId ? (
                    <HStack>
                      <AsyncSelect
                        cacheOptions
                        isClearable
                        defaultOptions
                        placeholder={t('tasks.form.user_placeholder')}
                        chakraStyles={{
                          container: (provided) => ({
                            ...provided,
                            flex: 1,
                          }),
                        }}
                        isMulti={false}
                        tagVariant="solid"
                        loadOptions={fetchUsers}
                        onChange={(e) => setSelectedOriginId(e?.value)}
                      />
                      {selectedOriginId && (
                        <IconButton
                          onClick={() => updateOriginId(selectedOriginId)}
                          icon={
                            <Icon
                              as={CheckIcon}
                              fontSize="16px"
                              color="green.600"
                            />
                          }
                          aria-label=""
                        />
                      )}
                      <IconButton
                        onClick={() => {
                          setEditOriginId(false);
                          setSelectedOriginId(undefined);
                        }}
                        icon={
                          <Icon
                            as={CloseIcon}
                            fontSize="14px"
                            color="red.600"
                          />
                        }
                        aria-label=""
                      />
                    </HStack>
                  ) : (
                    <HStack>
                      <HStack>
                        <Avatar
                          translate="no"
                          size="sm"
                          name={currentOriginUser.full_name}
                        />
                        <Text fontSize="lg">{currentOriginUser.full_name}</Text>
                      </HStack>
                      {!!checkPermission('admin') && (
                        <IconButton
                          onClick={() => setEditOriginId(true)}
                          icon={<Icon as={EditIcon} fontSize="14px" />}
                          aria-label=""
                        />
                      )}
                    </HStack>
                  )}
                </DetailItem>
              )}

              {type === 'task' && !!data?.origin_property && (
                <DetailItem label={t('task.show.associated_to')}>
                  <HStack>
                    <Icon as={FiUser} />
                    <Text fontWeight={600}>{data?.origin_property}</Text>
                  </HStack>
                </DetailItem>
              )}

              <DetailItem label="Status">
                <Box>
                  <StatusTag status={data?.status || 'delayed'} type={type} />
                </Box>
              </DetailItem>

              <DetailItem label={t('checklists.summary.flag_items')}>
                <Badge
                  rounded="sm"
                  w="min-content"
                  fontSize="md"
                  fontWeight="bold"
                  colorScheme={flaggedBadgeColor}
                  variant="outline"
                >
                  {`${flaggedAnswersCount} ${t(
                    'checklists.summary.flag_items',
                  )}`}
                </Badge>
              </DetailItem>

              {!!data?.formularies?.length && (
                <DetailItem label={type === 'task' ? 'Checklist' : 'Templates'}>
                  <Wrap>
                    {data?.formularies.map((form: string) => (
                      <Tag colorScheme="purple" borderWidth="1.5px" key={form}>
                        {form}
                      </Tag>
                    ))}
                  </Wrap>
                </DetailItem>
              )}

              <DetailItem label={t('checklists.summary.location')}>
                {editLocal ? (
                  <Flex alignItems="center" gap="2">
                    <AsyncSelect
                      cacheOptions
                      isClearable
                      defaultOptions
                      placeholder={t('tasks.form.local_placeholder')}
                      chakraStyles={{
                        container: (provided) => ({
                          ...provided,
                          flex: 1,
                        }),
                      }}
                      isMulti={false}
                      tagVariant="solid"
                      loadOptions={fetchLocals}
                      onChange={(e) => setSelectedLocal(e?.value)}
                    />
                    {selectedLocal && (
                      <IconButton
                        onClick={() => updateLocal(selectedLocal)}
                        variant="unstyled"
                        icon={
                          <Icon
                            as={CheckIcon}
                            fontSize="16px"
                            color="green.600"
                          />
                        }
                        aria-label=""
                      />
                    )}
                    <IconButton
                      onClick={() => {
                        setEditLocal(false);
                        setSelectedLocal(undefined);
                      }}
                      variant="unstyled"
                      icon={
                        <Icon as={CloseIcon} fontSize="14px" color="red.600" />
                      }
                      aria-label=""
                    />
                  </Flex>
                ) : (
                  <Flex alignItems="center" gap="2">
                    <Link
                      pointerEvents={
                        data?.local_address_name ? 'inherit' : 'none'
                      }
                      variant="unstyled"
                      isExternal
                      href={`https://maps.google.com/?q=${data?.local_address_name}`}
                    >
                      <Flex alignItems="center" gap="2">
                        <Icon
                          as={FaLocationArrow}
                          color={data?.local_name ? 'blue.500' : 'gray'}
                        />
                        <Text color="mutedText">
                          {data?.local_name
                            ? data?.local_name
                            : `- ${t('checklists.summary.no_location')}`}
                        </Text>
                      </Flex>
                    </Link>
                    {type === 'task' && data?.status !== 'done' && (
                      <IconButton
                        onClick={() => setEditLocal(true)}
                        variant="unstyled"
                        icon={<Icon as={EditIcon} fontSize="14px" />}
                        aria-label=""
                      />
                    )}
                  </Flex>
                )}
              </DetailItem>

              {type === 'event' && (
                <DetailItem label={t('checklists.summary.answered_items')}>
                  <Box>
                    <Text fontSize="lg">{`${data?.collected_items_count} / ${data?.formularies?.length}`}</Text>
                  </Box>
                </DetailItem>
              )}

              {!!parsedStartDate && (
                <DetailItem label={t('checklists.summary.started_at')}>
                  <Box>
                    <Text fontSize="lg">{parsedStartDate}</Text>
                  </Box>
                </DetailItem>
              )}

              {parsedExecution && (
                <DetailItem label={t('checklists.summary.answered_at')}>
                  <Box>
                    <Text fontSize="lg">{parsedExecution}</Text>
                  </Box>
                </DetailItem>
              )}

              {!!parsedFinishedAt && (
                <DetailItem label={t('checklists.summary.finished_at')}>
                  <Box>
                    <Text fontSize="lg">{parsedFinishedAt}</Text>
                  </Box>
                </DetailItem>
              )}

              {data?.status !== 'started' && (
                <DetailItem label={t('checklists.summary.duration')}>
                  <Box>
                    <Text fontSize="lg">{data?.duration} hs</Text>
                  </Box>
                </DetailItem>
              )}

              {!!data?.last_modified_at && (
                <DetailItem label={t('checklists.summary.last_update')}>
                  <Box>
                    <Text fontSize="lg">
                      {moment(data?.last_modified_at).format('LLLL')}
                    </Text>
                  </Box>
                </DetailItem>
              )}

              {type === 'task' && (
                <>
                  {!!data?.date_end && (
                    <DetailItem label="Prazo">
                      <HStack>
                        <Icon as={FiCalendar} />
                        <Text fontWeight={600}>
                          {t('task.show.deliver_till')}:{' '}
                          {moment(data?.date_end).format('LL')}
                        </Text>
                      </HStack>
                    </DetailItem>
                  )}

                  {!!data?.batch_tags?.length && (
                    <DetailItem label="tags">
                      <Wrap>
                        {data?.batch_tags.map((tag: TagInferface) => (
                          <Tag
                            color={getContrastColor(tag.color)}
                            bg={tag.color}
                            borderWidth="1.5px"
                            key={tag.id}
                          >
                            {tag.name}
                          </Tag>
                        ))}
                      </Wrap>
                    </DetailItem>
                  )}
                </>
              )}
            </List>
          </Card>
        </Box>
      </Skeleton>
    </>
  );
};

export const Footer = () => {
  const { t } = useTranslation();
  const { data, onUpdate, type } = useChecklistSummary();
  const modals = useModals();
  const { push } = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);

  const event = type === 'event' ? data : data?.event;

  const onOpenChecklist = () => {
    modals.pop();
    push(`/app/reports/event/${event?.id}`);
  };

  const handleUpdate = React.useCallback(async () => {
    if (!onUpdate || !event) return;
    try {
      setIsLoading(true);
      await onUpdate({
        id: event.id,
        validated: !event.validated,
      });
      modals.pop();
      setIsLoading(false);
    } catch (e) {
      throw new Error();
    } finally {
      setIsLoading(false);
    }
  }, [event, modals, onUpdate]);

  const validationProps = React.useMemo(() => {
    if (event?.validated) {
      return {
        label: t('checklists.validated'),
        icon: <Icon as={FaCheck} />,
        colorScheme: 'green',
      };
    }
    return {
      label: t('checklists.validate'),
      icon: <Icon as={FaThumbsUp} />,
      colorScheme: 'primary',
    };
  }, [event?.validated, t]);

  return (
    <Flex
      alignItems="center"
      w="full"
      bg="white"
      borderTopWidth="thin"
      p="4"
      flexDir="column"
    >
      {type === 'task' && (
        <ButtonGroup w="full" size="md" marginBottom={5}>
          <TaskActions
            openChecklist={onOpenChecklist}
            onClose={() => modals.pop()}
          />
        </ButtonGroup>
      )}
      <ButtonGroup w="full" size="md">
        <Button flex="1" variant="outline" onClick={modals.pop}>
          {t('buttons.goBack')}
        </Button>
        {(event?.id || type === 'event') && (
          <Button
            onClick={handleUpdate}
            colorScheme={validationProps.colorScheme}
            flex="1.4"
            rightIcon={validationProps.icon}
            isLoading={isLoading}
          >
            {validationProps?.label}
          </Button>
        )}
      </ButtonGroup>
    </Flex>
  );
};

type ChecklistSummaryProps = {
  id: string;
  type: 'event' | 'task';
  onUpdate?: (data: PartialWithId<Checklist>) => Promise<void>;
  refresh?: () => void;
  handleDisableTask?: (taskId: string, hideTask: boolean) => void;
};

export const ChecklistSummary: React.FC<ChecklistSummaryProps> = (props) => {
  const { checkPermission } = useUserPermissions();
  const modals = useModals();
  const { type, handleDisableTask } = props;

  return (
    <ChecklistSummaryProvider {...props}>
      <Flex pt="4" flex="1" flexDir="column" position="relative">
        {type === 'task' && checkPermission('rw') && (
          <Box display="flex" justifyContent="end" mr={16}>
            <TaskOptions
              onClose={() => modals.pop()}
              handleDisableTask={handleDisableTask}
            />
          </Box>
        )}
        <DrawerCloseButton />
        <ChecklistHeader />
        <Scrollbars style={{ background: 'var(--chakra-colors-gray-100)' }}>
          <Flex flex="1" flexDir="column" paddingBottom={5}>
            <FlaggedItemsList />
            <ChecklistDetails />
          </Flex>
        </Scrollbars>
        <Footer />
      </Flex>
    </ChecklistSummaryProvider>
  );
};

export function useChecklistSummaryDrawer() {
  const modals = useModals();

  const openDrawer = React.useCallback(
    (props: ChecklistSummaryProps) => {
      modals.drawer({
        id: 'checklist-summary',
        hideCloseButton: true,
        size: 'lg',
        body: <ChecklistSummary {...props} />,
      });
    },
    [modals],
  );

  const closeDrawer = React.useCallback(() => {
    modals.close('checklist-summary');
  }, [modals]);

  return { openDrawer, closeDrawer };
}
