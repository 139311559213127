import { IRouteData } from '@/routes/interfaces';
// import h from '@/h';
import routesData from '@/routes/routesData';
import React, { createContext, useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { NavigationContextData } from './interfaces/navigation';

const NavigationContext = createContext<NavigationContextData>(
  {} as NavigationContextData,
);

export const NavigationProvider: React.FC = ({ children }) => {
  const { push, goBack: back, replace, go } = useHistory();
  const { pathname } = useLocation();
  const getMainRoute = useCallback(
    (routePath = pathname) => {
      const route = routesData.findMainRoute(routePath);
      return route;
    },
    [pathname],
  );

  const getRouteData = useCallback(
    (routePath: string = pathname) => {
      const route = routesData.findRoute(routePath);
      return route;
    },
    [pathname],
  );

  const getAppRouteData = useCallback(() => {
    const route = getRouteData('/app');
    return route;
  }, [getRouteData]);

  const navigate = useCallback(
    (path: string, state?: Record<string, any>) => {
      push(path, state);
    },
    [push],
  );

  const goTo = useCallback(
    (path: string) => {
      replace('');
      replace(path);
    },
    [replace],
  );

  const goBack = useCallback(() => {
    back();
  }, [back]);

  const setAppRoutes = useCallback((routes: { [name: string]: IRouteData }) => {
    routesData.setAppRoutes(routes);
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        navigate,
        goBack,
        getRouteData,
        getAppRouteData,
        setAppRoutes,
        getMainRoute,
        pathname,
        goTo,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export function useNavigation(): NavigationContextData {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error('useNavigation must be used whithin an NavigationProvider');
  }

  return context;
}
