import Lightbox from '@/components/Lightbox';
import { GalleryUpload } from '@/components_v2/inputs/GalleryUpload';
import ImageCapture from '@/components_v2/inputs/ImageCapture';
import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import { HStack, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';

import FormControlComponent from '../FormControlComponent';
import ImagePreview from './Image.Preview';

const ImageContent: React.FC<QuestionModel> = () => {
  const { error, onChange, fieldState, clear } = useField();
  const [urls, setUrls] = React.useState<string[]>([]);

  const value = React.useMemo(() => {
    if (fieldState) {
      if (Array.isArray(fieldState)) {
        setUrls(fieldState.map((v) => v.content));
        return fieldState;
      }
      setUrls([fieldState].map((v) => v.content));
      return [fieldState];
    }
    setUrls([]);
    return [];
  }, [fieldState]);

  const handleChange = React.useCallback(
    (newValue: string[]) => {
      if (newValue.length === 0) {
        clear();
      } else {
        // Seleciona apenas os valores novos
        const diff = newValue.filter(
          (n) => !value.find((v) => v.content === n),
        );
        // Filtra os valores anteriores para remover as imagens apagadas
        const remains = value.filter((v) => {
          return newValue.includes(v.content);
        });
        onChange([...remains, ...diff.map((v) => createAnswer(v))]);
      }
    },
    [onChange, clear, value],
  );

  const onLightbox = React.useCallback(
    (index: number) => {
      Lightbox.openLightbox(
        value.map((v) => v.content),
        index,
      );
    },
    [value],
  );

  return (
    <FormControlComponent isInvalid={error}>
      <HStack>
        <Wrap>
          <WrapItem alignItems="center">
            <ImageCapture
              urls={urls}
              setUrls={setUrls}
              onChange={handleChange}
            />
            <GalleryUpload
              urls={urls}
              setUrls={setUrls}
              onChange={handleChange}
            />
          </WrapItem>
          {value.map((v, index) => (
            <WrapItem key={v.id}>
              <ImagePreview onOpen={() => onLightbox(index)} url={v.content} />
            </WrapItem>
          ))}
        </Wrap>
      </HStack>
    </FormControlComponent>
  );
};

export default ImageContent;
