import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { IRouteData, RouteTypes } from '@/routes/interfaces';
import { Tag } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowIcon, Container, List, ListItemContainer } from './styles';

export interface MainMenuRenderProps {
  list: IRouteData[];
  selected: string;
  setSelected: (selected: string) => void;
  isMainMenuOpen: boolean;
  setSubList?: (arr: IRouteData[]) => void;
}

const MainMenuBar: React.FC<MainMenuRenderProps> = ({
  list,
  selected,
  setSelected,
  isMainMenuOpen,
  setSubList,
}) => {
  const { t } = useTranslation();
  const { checkPermission } = useUserPermissions();

  const renderList = useCallback(() => {
    const listItems = list.map((item) => {
      return (
        <ListItemContainer
          key={item.name}
          disabled={
            item.disabled ||
            (item.permissionNeeded && !checkPermission(item.permissionNeeded))
          }
          onClick={() => {
            setSelected(item.name);
            if (!item.children && setSubList) setSubList([]);
          }}
          isSelected={item.name === selected}
          className="visibility-parent"
          style={{ alignItems: 'center' }}
        >
          <p>{item.title ? t(item.title).toLowerCase() : item.name} </p>
          {!!item?.tag && (
            <span>
              <Tag
                mt="1"
                size="sm"
                variant="solid"
                colorScheme={item.tag?.color || 'brand'}
              >
                {item.tag?.label}
              </Tag>
            </span>
          )}
          <ArrowIcon className="visible-on-hover" />
        </ListItemContainer>
      );
    });
    return listItems;
  }, [list, checkPermission, selected, t, setSelected]);

  return (
    <Container isMainMenuOpen={isMainMenuOpen}>
      <List>{renderList()}</List>
    </Container>
  );
};

export default MainMenuBar;
