import { IconSearch } from '@/components_v2/ContextMenu/Icons';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInputRenderProps } from './interfaces';
import { Input, InputContainer, SearchIconContainer } from './styles';

const SearchInput: React.FC<SearchInputRenderProps> = ({
  onChange,
  value,
  placeholder,
  containerStyle,
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.blur();
    }
  }, []);

  return (
    <InputContainer style={containerStyle}>
      <SearchIconContainer>
        <IconSearch />
      </SearchIconContainer>
      <Input
        ref={searchInputRef}
        type="search"
        placeholder={placeholder || t('common.search')}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    </InputContainer>
  );
};

export default SearchInput;
