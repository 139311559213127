import VerticalSteps from '@/components/VerticalSteps';
import { Component } from '@/components/VerticalSteps/interfaces';
import EmptyListItem from '@components/EmptyListItem';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadFile, Footer, TableExemple, UploadFile } from './components';
import { ImportFileMainProps } from './interfaces';
import {
  Container,
  ContainerContent,
  Content,
  Header,
  HeaderTitle,
} from './styles';

const ImportFile: React.FC<ImportFileMainProps> = ({
  status,
  apiData,
  uploadLoading,
  onCancel,
  onUpload,
  onChangeStatus,
  setFileDownloadUrl,
}) => {
  const { t } = useTranslation();

  const renderGetComponents = useCallback(() => {
    if (!apiData || !status) return null;

    const components: Component[] = apiData.steps.map((step) => {
      const { title, componentType, options } = step;

      const getComponent = () => {
        switch (componentType) {
          case 'download_example':
            return <DownloadFile {...step} />;
          case 'table':
            return <TableExemple {...step} />;
          case 'upload_file':
            return (
              <UploadFile
                {...step}
                status={status}
                onChangeStatus={onChangeStatus}
                registerCount={apiData.registerCount}
                setFileDownloadUrl={setFileDownloadUrl}
                accept=".csv, .xlsx"
              />
            );
          default:
            return null;
        }
      };
      const verticalStepsProps: Component = {
        title,
        children: getComponent(),
        hasNumber: true,
        ...options,
      };
      return verticalStepsProps;
    });

    return <VerticalSteps components={components} />;
  }, [status, apiData, setFileDownloadUrl, onChangeStatus]);

  if (apiData)
    return (
      <Container>
        <Header>
          <HeaderTitle>{apiData.title}</HeaderTitle>
        </Header>
        <ContainerContent>
          <Content>{renderGetComponents()}</Content>
          <Footer
            status={status}
            isLoading={uploadLoading}
            onCancel={onCancel}
            onUpload={onUpload}
            buttonLabel={apiData.finishButtonLabel}
          />
        </ContainerContent>
      </Container>
    );
  return (
    <EmptyListItem
      text={t(`error.common`)}
      styles={{ flex: 1, flexDirection: 'column' }}
    />
  );
};

export default ImportFile;
