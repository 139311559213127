import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonContainer, Title, Description } from '../components';

const InvalidTokenInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CommonContainer>
      <Title content={t('password_recovery.title')} />
      <Description content={t('password_recovery.invalid_token_description')} />
    </CommonContainer>
  );
};

export default InvalidTokenInfo;
