import { useTasks } from '@/screens/Audit/Dashboard/hooks/tasks';
import { DownloadIcon } from '@chakra-ui/icons';
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown } from 'react-icons/fi';

const ActionsMenu: React.FC = () => {
  const { t } = useTranslation();
  const { selectedIds, exportBatchTasks } = useTasks();

  return (
    <Menu>
      <MenuButton
        colorScheme="brand"
        color="white"
        rightIcon={<Icon as={FiChevronDown} />}
        as={Button}
        isDisabled={selectedIds?.length === 0}
      >
        {t('common.actions')}
      </MenuButton>
      <MenuList zIndex={20}>
        <MenuItem
          disabled={!!selectedIds.length}
          icon={<DownloadIcon />}
          onClick={() => exportBatchTasks()}
        >
          {t('tasks.actions.export_batch')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ActionsMenu;
