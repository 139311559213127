import { createContext } from '@/utils/react-utils';
import React from 'react';

import { QuestionFilterType } from '../interfaces';

type CallbackFn = (id: string) => void;

export type InteractionsContext = {
  selectedFormId: string | null;
  selectedItemId: string | null;
  setCurrentForm: CallbackFn;
  setCurrentItem: CallbackFn;
  filter: QuestionFilterType;
  setFilter: (filter: QuestionFilterType) => void;
};

const [Context, useInteractions] = createContext<InteractionsContext>({
  name: 'ChecklistSummaryContext',
});

const InteractionsProvider: React.FC = ({ children }) => {
  const [filter, setFilter] = React.useState<QuestionFilterType>('all');
  const [selectedFormId, setSelectedForm] = React.useState<string | null>(null);
  const [selectedItemId, setSelectedItem] = React.useState<string | null>(null);

  const setCurrentForm: CallbackFn = React.useCallback((id) => {
    setSelectedForm(id);
  }, []);

  const setCurrentItem: CallbackFn = React.useCallback((id) => {
    // const query = { formId: selectedFormId, itemId: id };
    // const searchString = qs.stringify(query);
    // window.history.replaceState(null, 'Event', searchString);
    setSelectedItem(id);
  }, []);

  return (
    <Context
      value={{
        selectedFormId,
        setFilter,
        filter,
        selectedItemId,
        setCurrentForm,
        setCurrentItem,
      }}
    >
      {children}
    </Context>
  );
};

export { useInteractions, InteractionsProvider };
