import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Spinner,
  Portal,
} from '@chakra-ui/react';
import { BaseModalProps } from './types/BaseModal.types';

export interface LoaderOptions extends Omit<BaseModalProps, 'children'> {
  [key: string]: any;
}

const Loader: React.FC<LoaderOptions> = (props) => {
  return (
    <Modal {...props} isCentered closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
        zIndex="99999"
      />
      <Portal>
        <ModalContent w="unset" bg="transparent" shadow="none">
          <Spinner thickness="5px" color="brand.500" size="xl" />
        </ModalContent>
      </Portal>
    </Modal>
  );
};

export default Loader;
