import { Styles } from 'react-modal';

import { ModalStyleProps } from './interfaces';

export const CommonModalStyle: Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    boxShadow: '0px 2px 100px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    padding: 0,
    border: 0,
    display: 'flex',
    overflow: 'hidden',
  },
  overlay: {
    zIndex: 10,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
};

export const ExpandedModalStyle: Styles = {
  content: {
    top: '50%',
    left: '50%',
    width: '65%',
    right: 'auto',
    bottom: 'auto',
    maxHeight: '90%',
    marginRight: '-50%',
    boxShadow: '0px 4px 10px 5px rgba(149, 149, 149, 0.25)',
    borderRadius: '5px',
  },
  overlay: {
    zIndex: 10,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
};

export function generateModalStyle(
  props: ModalStyleProps | undefined,
  modalStyles?: Styles,
): Styles {
  if (modalStyles) return modalStyles;
  if (!props) {
    return CommonModalStyle;
  }
  const { width, expanded } = props;
  if (width) {
    return {
      ...CommonModalStyle,
      content: {
        ...CommonModalStyle.content,
        maxWidth: width,
        width,
        padding: 0,
        borderWidth: 0,
      },
    };
  }
  if (expanded) {
    return ExpandedModalStyle;
  }
  return CommonModalStyle;
}
