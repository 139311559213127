import moment from 'moment';

interface TimestampAttributes {
  executionDate: string;
  executionTime: string;
  executionTimezone: string;
}

const getExecutionTimestamps = (): TimestampAttributes => {
  const now = moment();
  const executionDate = now.format('YYYY-MM-DD');
  const executionTime = now.format('HH:mm:ss');
  const executionTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return {
    executionDate,
    executionTime,
    executionTimezone,
  };
};
export default getExecutionTimestamps;
