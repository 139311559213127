import React, { useCallback, useState } from 'react';

import ToggleButton from '@/components_v2/Buttons/Toggle';
import { QuestionContainer, QuestionTitle, QuestionSubtitle } from '../common';
import { ToggleProps } from './interfaces';
import { ToggleContainer } from './styles';

const Toggle: React.FC<ToggleProps> = ({
  question,
  initialValues,
  onChange,
  viewOnly,
}) => {
  const parsedInitialValues = initialValues?.values
    ? initialValues?.values
    : false;
  const [state, setState] = useState(
    typeof parsedInitialValues === 'boolean'
      ? parsedInitialValues
      : Boolean(parsedInitialValues === 'true'),
  );

  const handleChange = useCallback(() => {
    const value = Boolean(state);
    setState(!value);
    onChange(`${!value}`);
  }, [state, onChange]);

  return (
    <QuestionContainer>
      <ToggleContainer>
        <QuestionTitle>{question.name}</QuestionTitle>
        <ToggleButton
          disabled={viewOnly}
          state={state}
          onChange={handleChange}
        />
      </ToggleContainer>
    </QuestionContainer>
  );
};

export default Toggle;
