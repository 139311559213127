import React, { useCallback, useState } from 'react';
import debounce from '@utils/debounce';
import { useWorkspace } from '@/hooks/workspace';
import WorkspaceSignInComponent from './Workspace';
import { WorkspaceValidationStatus } from './localGeneric';

const WorkspaceSignIn: React.FC = () => {
  const [workspaceName, setWorkspaceName] = useState('');
  const { setSubdomain, getRemoteWorkspace } = useWorkspace();
  const [validWorkspace, setWorkspaceValidationStatus] = useState(
    WorkspaceValidationStatus.IDLE,
  );
  const [debouncedCheckWorkspace] = useState(() => {
    return debounce(async (val: string) => {
      const response = await getRemoteWorkspace(val);
      if (response.id) {
        setWorkspaceValidationStatus(WorkspaceValidationStatus.VALID);
      } else {
        setWorkspaceValidationStatus(WorkspaceValidationStatus.INVALID);
      }
    }, 200);
  });
  const handleOnChange = useCallback((val: string) => {
    setWorkspaceName(val);
    if (val.length <= 2) return;
    if (validWorkspace !== WorkspaceValidationStatus.VALIDATING) {
      setWorkspaceValidationStatus(WorkspaceValidationStatus.VALIDATING);
    }
    debouncedCheckWorkspace(val);
  }, []);

  const handleSubmit = useCallback(() => {
    if (validWorkspace === WorkspaceValidationStatus.VALID) {
      setSubdomain(workspaceName);
    }
  }, [validWorkspace, workspaceName, setSubdomain]);

  return (
    <WorkspaceSignInComponent
      handleOnChange={handleOnChange}
      workspaceName={workspaceName}
      validWorkspace={validWorkspace}
      handleSubmit={handleSubmit}
    />
  );
};

export default WorkspaceSignIn;
