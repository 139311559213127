import { Button } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

interface CameraProps {
  onCapture: (imageSrc: string) => void;
}

function isMobileiOS(): boolean {
  const { userAgent } = navigator;

  // Check for iOS mobile devices
  return /iPad|iPhone|iPod/.test(userAgent);
}

const WebcamCapture: React.FC<CameraProps> = ({ onCapture }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [availableCameras, setAvailableCameras] = React.useState<
    MediaDeviceInfo[]
  >([]);
  const [selectedCameraIndex, setSelectedCameraIndex] = React.useState<number>(
    0,
  );
  const [stream, setStream] = React.useState<MediaStream | null>(null);

  useEffect(() => {
    const fetchCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(
          (device) => device.kind === 'videoinput',
        );
        setAvailableCameras(cameras);
      } catch (err) {
        console.error('Error fetching cameras:', err);
      }
    };

    const handlePermissions = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        await fetchCameras();
      } catch (err) {
        console.error('Error accessing camera:', err);
      }
    };

    isMobileiOS() ? handlePermissions() : fetchCameras();
  }, []);

  useEffect(() => {
    const currentVideoRef = videoRef.current;

    const cleanup = () => {
      if (currentVideoRef && currentVideoRef.srcObject) {
        const stream = currentVideoRef.srcObject as MediaStream;
        stream.getTracks().forEach((track) => track.stop());
      }
    };

    return cleanup;
  }, [videoRef]);

  useEffect(() => {
    startCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCameraIndex]);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  const startCamera = async () => {
    try {
      const constraints: MediaStreamConstraints = {
        video: {
          deviceId: {
            exact: availableCameras[selectedCameraIndex]?.deviceId,
          },
        },
      };
      const newStream = await navigator.mediaDevices.getUserMedia(constraints);
      setStream(newStream);
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  const captureImage = async () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext('2d')
        ?.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData = canvas.toDataURL('image/jpeg');
      onCapture(imageData);
    }
  };

  const switchCamera = () => {
    if (availableCameras.length > 1) {
      setSelectedCameraIndex(
        (prevIndex) => (prevIndex + 1) % availableCameras.length,
      );
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: 8 }}>
        <Button type="button" mr={2} colorScheme="brand" onClick={captureImage}>
          Capturar Imagem
        </Button>
        <Button
          type="button"
          colorScheme="brand"
          onClick={switchCamera}
          disabled={availableCameras.length <= 1}
        >
          Trocar Câmera
        </Button>
      </div>
      {availableCameras.length > 1 && (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: '8px',
              fontWeight: 'bold',
            }}
          >
            <div style={{ marginRight: '8px' }}>Câmeras:</div>
            {availableCameras.map((camera, index) => (
              <div
                key={`camera_${camera.label}`}
                style={{
                  marginRight: '4px',
                  color: 'black',
                  textDecoration:
                    selectedCameraIndex === index ? 'underline' : 'none',
                }}
              >
                {index + 1}
              </div>
            ))}
          </div>
          <div
            style={{ marginBottom: 8, textAlign: 'center', color: 'GrayText' }}
          >
            (Clique em &quot;Trocar Câmera&quot; para mudar entre as variações
            disponíveis)
          </div>
        </div>
      )}
      <video ref={videoRef} autoPlay playsInline>
        <track kind="captions" src="#" />
      </video>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default WebcamCapture;
