/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import config from '@/Constants';
import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import snakeToCamel from '@/utils/snakeToCamel';
import { QueryFunctionContext, useQuery } from 'react-query';

export const discussionTopicsKey = config.DISCUSSION_TOPICS_URL;

const fetchTopic = async (
  params: QueryFunctionContext<string[], any>,
): Promise<DiscussionTopic> => {
  const { queryKey } = params;

  const url = queryKey.join('/');
  const response = await AxiosHelper.get<DiscussionTopic>({
    url,
    apiV: 1,
  });

  if (response.statusCode < 200 || response.statusCode > 300) {
    throw new UnexpectedError();
  }
  const { body } = response;
  return snakeToCamel(body);
};

export function useFeedbackQuery(
  id: string,
  params?: {
    initialData?: DiscussionTopic;
    enabled?: boolean;
  },
) {
  const query = useQuery([discussionTopicsKey, id], fetchTopic, {
    ...params,
    structuralSharing: true,
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
  return query;
}
