import React, { InputHTMLAttributes, useState } from 'react';

import { Input as GenericInput, InputDiv, InputProps } from './styles';

const Input: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & InputProps
> = ({ hasError, warning, children, name, ...rest }, ref) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleBlur = React.useCallback((evt) => {
    setIsFocused(false);
    if (rest.onBlur) rest.onBlur(evt);
  }, []);

  const handleFocus = React.useCallback(() => {
    setIsFocused(true);
  }, []);

  return (
    <InputDiv hasError={hasError} warning={warning} onFocusEvent={isFocused}>
      <GenericInput
        {...rest}
        name={name}
        ref={ref}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {children}
    </InputDiv>
  );
};

export default React.forwardRef(Input);
