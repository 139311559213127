import RecurrenceGenerator from '@/components_v2/RecurrenceGenerator';
import { RecurrenceGeneratorState } from '@/components_v2/RecurrenceGenerator/interfaces/IRecurrenceGeneratorReducer';
import { dateToUnixEpoch } from '@/utils/Date/dateToUnixEpoch';
import Tooltip from '@components/Tooltip';
import debounce from '@utils/debounce';
import React, { useMemo, useState } from 'react';

import {
  InfoTooltip,
  QuestionContainer,
  QuestionHeader,
  QuestionSubtitle,
  QuestionTitle,
  TooltipContainer,
} from '../common';
import { RecurrenceProps, RemoteRecurrenceState } from './interfaces';
import { ErrorContainer } from './styles';
import { mapInitialValues } from './utils/mapInitialValues';

const handleDateFormats = (values: RecurrenceGeneratorState) => {
  const { recurrenceRule, recurrencyTemplateType } = values;

  if (recurrenceRule) {
    const res = Object.entries(recurrenceRule).reduce((prev, curr) => {
      const [key, val] = curr;

      const parsedVal = val instanceof Date ? dateToUnixEpoch(val) : val;
      return {
        ...prev,
        [key]: parsedVal,
      };
    }, {});
    return { recurrenceRule: res, recurrencyTemplateType };
  }
  return { recurrenceRule: {}, recurrencyTemplateType };
};

const Recurrence: React.FC<RecurrenceProps> = ({
  question,
  errors,
  initialValues,
  onChange,
  viewOnly = false,
}) => {
  const [debouncedOnChange] = useState(() =>
    debounce(async (values: RecurrenceGeneratorState) => {
      onChange(handleDateFormats(values));
    }, 200),
  );

  const parsedInitialValues = useMemo(() => {
    const recurrenceRule = initialValues?.values?.recurrenceRule;

    return {
      recurrencyTemplateType: initialValues?.values?.recurrencyTemplateType,
      recurrenceRule: {
        ...recurrenceRule,
        dateStart: recurrenceRule?.dateStart
          ? new Date(recurrenceRule?.dateStart * 1000)
          : null,
        dateEnd: recurrenceRule?.dateEnd
          ? new Date(recurrenceRule?.dateEnd * 1000)
          : null,
      },
    };
  }, [initialValues?.values]);

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionTitle>{question.name}</QuestionTitle>
        {question.tipText && (
          <Tooltip description={question.tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      <ErrorContainer hasError={!!errors}>
        <RecurrenceGenerator
          viewOnly={viewOnly}
          initialValues={parsedInitialValues}
          onChange={debouncedOnChange}
        />
      </ErrorContainer>
      <QuestionSubtitle hasError={!!errors}>
        {errors && errors.errors}
      </QuestionSubtitle>
    </QuestionContainer>
  );
};

export default Recurrence;
