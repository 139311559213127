import React from 'react';

import { Container, Progress } from './styles';

interface ProgressBarProps {
  height?: number;
  color?: string;
  progress?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  return (
    <Container {...props}>
      <Progress {...props} />
    </Container>
  );
};

export default ProgressBar;
