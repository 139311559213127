/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { SurveyFormContext } from '../context/SurveyFormContext';
import { SurveyForm } from '../data/usecases/SurveyForm/SurveyForm';
import {
  ErrorResults,
  FormProviderRef,
  IAnswerState,
  IFieldRegistry,
  OnSubmitResults,
  SurveyFormprops,
} from '../domain/usecases';

const Provider: React.ForwardRefRenderFunction<
  FormProviderRef,
  SurveyFormprops
> = (
  {
    children,
    fields,
    initialValues,
    validator,
    viewOnly = false,
    setTextCallback,
  },
  selfRef,
) => {
  const formInstance = useRef<SurveyForm>({} as SurveyForm);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<ErrorResults>({});

  useEffect(() => {
    function initialize() {
      formInstance.current = new SurveyForm(fields, initialValues, validator);
    }
    initialize();
    setLoading(false);
  }, [fields, initialValues, validator]);

  const unsubscribeAll = useCallback(() => {
    formInstance.current?.unsubscribeAll();
  }, []);

  const onChange = useCallback((fieldId: string, values: IAnswerState) => {
    formInstance.current?.onChange(fieldId, values);
  }, []);

  const removeEntry = useCallback((fieldId: string) => {
    formInstance.current?.removeEntry(fieldId);
  }, []);

  const getValues = useCallback((fieldId: string): IAnswerState | undefined => {
    return formInstance.current?.getValues(fieldId);
  }, []);

  const unregisterField = useCallback((fieldId: string): void => {
    formInstance.current?.unregisterField(fieldId);
  }, []);

  const registerField = useCallback(
    ({ id, required }: IFieldRegistry): void => {
      formInstance.current?.registerFields({ id, required });
    },
    [],
  );

  const subscribe = useCallback(
    (
      fieldId: string,
      callback: (value: IAnswerState) => void,
    ): (() => void) => {
      const unsub = formInstance.current?.subscribe(fieldId, callback);

      return unsub;
    },
    [],
  );

  const unsubscribe = useCallback(
    (fieldId: string, callback: (value: IAnswerState) => void): void => {
      formInstance.current?.unsubscribe(fieldId, callback);
    },
    [],
  );

  const onSubmit = useCallback(async (): Promise<OnSubmitResults> => {
    const results = await formInstance?.current?.onSubmit();
    results.errors && setErrors(results.errors);
    return results;
  }, []);

  const onChangeTitleField = useCallback(
    (value: IAnswerState) => {
      const results = formInstance?.current?.changeTitleField(value);
      setTextCallback?.(value.values || null);
      return results;
    },
    [setTextCallback],
  );

  const hasInheritedTitle = useCallback((id: string) => {
    const results = formInstance?.current?.hasInheritedTemplateName(id);
    return results;
  }, []);

  const setInheritedTitle = useCallback((value: boolean) => {
    formInstance?.current?.setInheritedTemplateName(value);
  }, []);

  useImperativeHandle(
    selfRef,
    () => ({
      onSubmit() {
        return onSubmit();
      },
      unsubscribeAll() {
        return unsubscribeAll();
      },
    }),
    [onSubmit, unsubscribeAll],
  );

  if (loading) return null;

  return (
    <SurveyFormContext.Provider
      value={{
        subscribe,
        unsubscribe,
        onChange,
        getValues,
        onSubmit,
        registerField,
        unregisterField,
        removeEntry,
        errors,
        viewOnly,
        onChangeTitleField,
        hasInheritedTitle,
        setInheritedTitle,
      }}
    >
      {children}
    </SurveyFormContext.Provider>
  );
};

export const SurveyFormProvider = forwardRef(Provider) as typeof Provider;
