import React, { useState, useCallback, useEffect } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';

import { RawAddress, ProcessedAddress } from '@services/address/interfaces';
import processAddress from '@services/address/processAddress';
import { FeedbackError } from '@/components/Feedback';
import Main from './Main';
import { AddressProps } from './interfaces';

const Address: React.FC<AddressProps> = ({
  initialValues,
  onChange,
  question,
  errors,
  viewOnly = false,
}) => {
  const [address, setAddress] = useState<ProcessedAddress>(
    initialValues?.values,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (newAddress: RawAddress) => {
      const processedAddres = processAddress(newAddress);
      setAddress(processedAddres);
      onChange(processedAddres);
    },
    [onChange],
  );

  const handleSelect = useCallback(
    async (address) => {
      if (address) {
        setIsLoading(true);
        try {
          const results = await geocodeByAddress(address);

          setIsLoading(false);
          handleChange(results[0]);
        } catch (e) {
          setIsLoading(false);
          FeedbackError({
            mainText: 'TODO address error',
            subText: 'TODO address error',
          });
        }
      } else {
        FeedbackError({
          mainText: 'TODO address error',
          subText: 'TODO address error',
        });
      }
    },
    [handleChange],
  );

  useEffect(() => {
    if (initialValues?.values?.address_name) {
      handleSelect(initialValues?.values?.address_name);
    } else if (typeof initialValues?.values === 'string') {
      handleSelect(initialValues?.values);
    }
  }, [initialValues, handleSelect]);

  return (
    <Main
      disabled={viewOnly}
      title={question.name}
      addressName={
        isLoading ? t('common.loading') : address?.address_name || ''
      }
      handleSelect={handleSelect}
      required={question.required}
      errorMessage={errors?.errors}
      reset={() => onChange({})}
      tipText={question.tipText}
    />
  );
};

export default Address;
