export const semanticTokens = {
  colors: {
    'app.border': {
      default: '#DCDDE0',
      _dark: '#1A1A23',
    },
    divider: {
      default: '#25262E',
      _dark: '#FDFDFD',
    },
    muted: {
      default: 'gray.500',
      _dark: 'gray.400',
    },
    mutedText: {
      default: '#595B6A',
      _dark: '#FDFDFD',
    },
    darkText: {
      default: '#373F51',
      _dark: 'white',
    },
    'combobox.hover': {
      default: 'blackAlpha.100',
      _dark: 'blackAlpha.300',
    },
    lightHover: {
      default: 'blackAlpha.200',
      _dark: 'whiteAlpha.200',
    },
    'combobox.background': {
      default: 'white',
      _dark: '#34373F',
    },
  },
};
