import React, { useState, useCallback } from 'react';
import Main from './Main';
import { NameInputProps } from './interfaces';

const NameInput: React.FC<NameInputProps> = ({
  initialName,
  onSubmit,
  placeholder,
  onChange,
  isEditable,
}) => {
  const [name, setName] = useState<string | undefined>(initialName);

  const handleSubmit = useCallback(() => {
    if (name) {
      onSubmit(name);
    }
  }, [onSubmit, name]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setName(newValue);
      onChange && onChange(newValue);
    },
    [onChange],
  );

  return (
    <Main
      name={name || ''}
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={placeholder}
      isEditable={isEditable}
    />
  );
};

export default NameInput;
