import { Button, Icon } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineEdit, AiOutlineWarning } from 'react-icons/ai';

import { useCurrentFeedback } from './hooks/useCurrentFeedback';
import { useFeedbackReview } from './hooks/useFeedbackReview';

export const FeedbackReviewButton = () => {
  const { feedback } = useCurrentFeedback();
  const { handleReviewRequest, isLoading } = useFeedbackReview();

  const hasReview = React.useMemo(() => feedback?.reviewOn, [
    feedback?.reviewOn,
  ]);

  return (
    <Button
      isLoading={isLoading}
      variant="solid"
      onClick={handleReviewRequest}
      colorScheme={hasReview ? 'orange' : 'purple'}
      rightIcon={<Icon as={hasReview ? AiOutlineWarning : AiOutlineEdit} />}
    >
      {hasReview ? 'Cancelar revisão' : 'Solicitar Revisão'}
    </Button>
  );
};
