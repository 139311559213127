import config from '@/Constants';
import { useAuth } from '@/hooks/Authentication/auth';
import { DiscussionTopic, TopicComment } from '@/hooks/Feedback/interfaces';

import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { v4 as uuidV4 } from 'uuid';
import {
  createFeedbackComment,
  TopicCommentPostData,
} from './services/createFeedbackComment';
import { discussionTopicsKey } from './useFeedbackQuery';

const mutationKey = config.DISCUSSION_COMMENTS_URL;

export function useFeedbackCommentMutation() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation(createFeedbackComment, {
    onSettled(data, error, variables) {
      const queryKey = [mutationKey, variables.discussionTopicId];
      queryClient.invalidateQueries(queryKey);
    },
    onMutate(variables) {
      const { content, discussionTopicId } = variables;
      const optimisticComment = {
        authorId: `${user?.id}`,
        authorName: `${user?.full_name}`,
        content,
        id: `${uuidV4()}-temp`,
        createdAt: new Date(),
      };
      const queryKey = [mutationKey, discussionTopicId];

      queryClient.setQueryData<TopicComment[]>(queryKey, (old = []) => [
        ...old,
        optimisticComment,
      ]);
    },
  });

  const createComment = React.useCallback(
    async (params: TopicCommentPostData) => {
      await mutation.mutateAsync(
        {
          ...params,
        },
        {
          onSuccess(data, variables) {
            const queryData = queryClient.getQueryData<DiscussionTopic>([
              discussionTopicsKey,
              variables.discussionTopicId,
            ]);

            if (queryData) {
              queryClient.setQueryData<DiscussionTopic>(
                [discussionTopicsKey, variables.discussionTopicId],
                {
                  ...queryData,
                  lastComment: data,
                  commentsCount: queryData.commentsCount + 1,
                },
              );
            }
          },
        },
      );
    },
    [mutation, queryClient],
  );

  const { isError, isLoading, data, reset, isSuccess, error } = mutation;
  return {
    createComment,
    isError,
    isLoading,
    data,
    reset,
    isSuccess,
    error,
  };
}
