import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const Flex: ComponentSingleStyleConfig = {
  variants: {
    'sidemenu-item-container': ({ colorMode }) => ({
      borderBottom: '1px',
      borderBottomColor: colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300',
      padding: '5px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      w: '100%',
    }),
  },
};
