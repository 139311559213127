export function filterObject(obj: Record<string, any>): Record<string, any> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      acc[key] = value;
    } else if (!Array.isArray(value)) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);
}

export const reduceNestedFilters = (byKey: Record<string, any>) => {
  const entries = Object.entries(byKey);

  const reduced = entries.reduce((prev, curr) => {
    const [key, value] = curr;

    const [filterKey, table] = key.split('.');

    const isEmpty = (Array.isArray(value) && !value.length) || !value;

    if (!filterKey || isEmpty) return prev;

    if (!table) {
      return {
        ...prev,
        [filterKey]: value,
      };
    }

    const prevTable = prev[table] || {};

    const nextValue = value;

    return {
      ...prev,
      [table]: {
        ...prevTable,
        [filterKey]: nextValue,
      },
    };
  }, {} as Record<string, Record<string, any>>);
  return filterObject(reduced);
};
