import React from 'react';

import { HandleQuestionProps } from './interfaces';
import {
  AccessSettings,
  Address,
  Checkbox,
  DatePicker,
  Email,
  FormularySelect,
  Number,
  Password,
  Phone,
  Radio,
  Recurrence,
  RemoteSelect,
  Text,
  Toggle,
} from './type';
import TimePicker from './type/TimePicker';

const renderQuestion = (props: HandleQuestionProps) => {
  const {
    question: { questionType },
  } = props;

  switch (questionType) {
    case 'access_settings':
      return <AccessSettings {...props} />;
    case 'picker_date':
    case 'date':
      return <DatePicker type="date" {...props} />;
    case 'date_range':
      return <DatePicker type="date_range" {...props} />;
    case 'phone':
      return <Phone {...props} />;
    case 'password':
      return <Password {...props} />;
    case 'radio':
      return <Radio {...props} />;
    case 'text':
      return <Text {...props} />;
    case 'toggle':
    case 'boolean':
      return <Toggle {...props} />;
    case 'email':
      return <Email {...props} />;
    case 'number':
      return <Number {...props} />;
    case 'address':
      return <Address {...props} />;
    case 'recurrence':
      return <Recurrence {...props} />;
    case 'checkbox':
      return <Checkbox {...props} />;
    case 'remote_multi_select':
      return <RemoteSelect questionProps={props} isMulti />;
    case 'remote_single_select':
      return <RemoteSelect questionProps={props} />;
    case 'time_picker':
      return <TimePicker {...props} />;
    case 'formulary_select':
      return <FormularySelect {...props} />;
    default:
      return null;
  }
};

const Questions: React.FC<HandleQuestionProps> = (props) => {
  return <>{renderQuestion(props)}</>;
};

export default Questions;
