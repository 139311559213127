import ILocalAuthentication from '@security/domain/usecases/ILocalAuthentication';
import IAuthStorage from '@security/data/protocols/storage/IAuthStorage';
import { AuthStorageData } from '@shared/domain/interfaces';

class LocalAuthentication implements ILocalAuthentication {
  private storage: IAuthStorage;

  public constructor(storage: IAuthStorage) {
    this.storage = storage;
  }

  public load(): AuthStorageData | null {
    const data = this.storage.getAuthData();
    return data;
  }

  public save(params: AuthStorageData): void {
    this.storage.saveAuthData(params);
  }

  public clear(): void {
    this.storage.clear();
  }
}

export default LocalAuthentication;
