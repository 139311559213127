import { ChangePasswordReducer, ChangePasswordState } from '../localGeneric';

export const InitialState: ChangePasswordState = {
  email: '',
  workspace: '',
  password: '',
  passwordConfirmation: '',
  isTokenValid: false,
  isSubmiting: false,
  isLoadingData: true,
};

export const changePasswordReducer: ChangePasswordReducer = (state, action) => {
  switch (action.type) {
    case 'onchange_password':
      return { ...state, password: action.payload };
    case 'onchange_password_confirmation':
      return { ...state, passwordConfirmation: action.payload };
    case 'handle_token_state':
      return { ...state, isTokenValid: action.payload };
    case 'handle_loading_data':
      return { ...state, isLoadingData: action.payload };
    case 'handle_submiting':
      return { ...state, isSubmiting: action.payload };
    case 'set_account_data':
      return {
        ...state,
        email: action.payload.email,
        workspace: action.payload.workspace,
        isTokenValid: true,
      };
    default:
      return state;
  }
};
