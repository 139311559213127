import React from 'react';
import 'react-image-lightbox/style.css';
import ReactLightbox from 'react-image-lightbox';
import { LightboxMainProps } from './interfaces';

const Lightbox: React.FC<LightboxMainProps> = (props) => {
  const {
    images,
    onCloseRequest,
    onPreviousRequest,
    onNextRequest,
    currentIndex,
  } = props;

  const mainIdx = images[currentIndex];
  const nextIdx = images[(currentIndex + 1) % images.length];
  const previousIdx =
    images[(currentIndex + images.length - 1) % images.length];

  return (
    <ReactLightbox
      reactModalStyle={{
        overlay: {
          zIndex: 100000,
        },
        content: {
          zIndex: 900000,
          top: '50%',
          left: '50%',
          width: '100%',
          height: '100%',
        },
      }}
      mainSrc={mainIdx}
      nextSrc={nextIdx}
      prevSrc={previousIdx}
      onCloseRequest={onCloseRequest}
      onMovePrevRequest={onPreviousRequest}
      onMoveNextRequest={onNextRequest}
    />
  );
};

export default Lightbox;
