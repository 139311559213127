/* eslint-disable react/no-array-index-key */
import _ from 'lodash';

import { Badge } from '../components/badge';
import {
  QuestionContext,
  useQuestion,
  useReport,
  useSection,
} from './hooks/use-context';

function QuestionComment() {
  const question = useQuestion();

  if (!question?.answers?.[0]?.comment) return <></>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        fontSize: '0.875rem',
        color: '#475569',
      }}
    >
      <span style={{ marginRight: '0.5rem' }}>Notas:</span>
      <div
        style={{
          borderRadius: '0.375rem',
          border: '1px solid #D1D5DB',
          padding: '0.5rem',
          textAlign: 'left',
        }}
      >
        <span>{question?.answers?.[0]?.comment}</span>
      </div>
    </div>
  );
}

function QuestionScore() {
  const { header } = useReport();
  const question = useQuestion();

  if (!question.answers[0]?.score || header?.hide_score) return <></>;

  return (
    <div
      style={{
        fontSize: '0.875rem',
        color: '#475569',
      }}
    >
      <span style={{ marginRight: '0.5rem' }}>
        Peso da pergunta: {question.weight}
      </span>
      <span>Pontuação obtida: {question.score_obtained}</span>
    </div>
  );
}

function QuestionHeader() {
  const question = useQuestion();
  const section = useSection();

  const hasFlag = question.answers?.some((e) => e.red_flag);

  const isFlagged = question.answers?.some((e) => e.is_flagged);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0',
        paddingBottom: '0.5rem',
      }}
    >
      <div
        style={{
          float: 'right',
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                fontSize: '0.625rem',
                color: '#9CA3AF',
              }}
            >
              {section.section_name}
            </p>
            <h2
              style={{
                fontSize: '0.875rem',
                fontWeight: 600,
              }}
            >
              {question.name}
            </h2>
            <QuestionScore />
          </div>
          {!!question.image && (
            <img src={question.image} alt="" className="h-20 w-20" />
          )}
        </div>

        {(!!hasFlag || !!isFlagged) && (
          <Badge size="xs" colorScheme="orange">
            Sinalizada como inconforme
          </Badge>
        )}
      </div>
    </div>
  );
}

export function QuestionItem({ children }: { children?: React.ReactNode }) {
  return (
    <li
      style={{
        marginBottom: '0.25rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        borderBottom: '1px solid',
        padding: '0.5rem',
        breakInside: 'avoid',
      }}
    >
      <QuestionHeader />
      {!!children && (
        <div
          style={{
            wordBreak: 'break-word',
            backgroundColor: '#F3F4F6',
            padding: '0.75rem',
          }}
        >
          {children}
        </div>
      )}
      <QuestionComment />
    </li>
  );
}

export function QuestionList({ children }: { children?: React.ReactNode }) {
  const { questions } = useSection();

  return (
    <ul
      style={{
        width: '100%',
        padding: '0.25rem',
      }}
    >
      {_.orderBy(questions, 'position', 'asc').map((question, idx) => (
        <QuestionContext value={question} key={`${question.name}-${idx}`}>
          <QuestionItem>{children}</QuestionItem>
        </QuestionContext>
      ))}
    </ul>
  );
}
