import snakeToCamel from '@/utils/snakeToCamel';
import { QueryClient, QueryClientConfig, QueryOptions } from 'react-query';
import { UnexpectedError } from '../shared/domain/errors';
import { AxiosHelper } from '../shared/infra/protocols/http';

export const queryClientFactory = (
  config?: QueryClientConfig | undefined,
): QueryClient => {
  const defaultQueryFn: QueryOptions['queryFn'] = async ({ queryKey }) => {
    const response = await AxiosHelper.get({ url: `${queryKey.join('/')}` });

    if (response.statusCode < 200 || response.statusCode > 300) {
      throw new UnexpectedError();
    }

    const body = snakeToCamel(response?.body);

    return body;
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
      },
    },
    ...config,
  });

  return queryClient;
};
