import { useMutation, useQueryClient } from 'react-query';
import { createFeedbackReview } from './services/createFeedbackReview';

import { discussionTopicCommentsKey } from './useFeedbackCommentsQuery';
import { discussionTopicsKey } from './useFeedbackQuery';

export function useCreateFeedbackReview() {
  const queryClient = useQueryClient();
  const mutation = useMutation(createFeedbackReview, {
    async onSuccess(data, variables) {
      if (!data) return;

      await queryClient.refetchQueries({
        queryKey: [discussionTopicCommentsKey, variables.discussionTopicId],
      });

      await queryClient.refetchQueries({
        queryKey: [discussionTopicsKey, variables.discussionTopicId],
      });
    },
  });

  return mutation;
}
