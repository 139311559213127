import { gql } from '@/gql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';

import { queryAutomationGroups } from './useFetchAutomationGroups';

export const deleteAutomationGroupMutation = gql(`#graphql
  mutation DeleteAutomationGroup($input: GroupsDeleteInput!) {
    deleteAutomationGroup(input: $input) {
      automationGroup {
        id
        name
        position
        deletedAt
        createdAt
        updatedAt
      }
    }
  }
`);

export function useDeleteAutomationGroup() {
  const toast = useToast();
  return useMutation(deleteAutomationGroupMutation, {
    refetchQueries: [queryAutomationGroups],
    awaitRefetchQueries: true,
    onCompleted(data) {
      if (data.deleteAutomationGroup?.automationGroup) {
        toast({
          title: 'Grupo excluído',
          status: 'success',
        });
      }
    },
  });
}
