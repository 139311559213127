import debounce from '@/utils/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { NameInputProps } from './interfaces';
import Main from './Main';

const SearchBar: React.FC<NameInputProps> = ({
  initialName,
  onSubmit,
  fieldLabel,
  onChange,
  onDebouce,
  debounceTime,
}) => {
  const [value, setValue] = useState<string | undefined>(initialName);

  const valueRef = useRef<string>();
  const initialized = useRef<boolean>(false);
  const [debounced] = useState(() => {
    return debounce(async () => {
      if (valueRef.current !== undefined) {
        onSubmit(valueRef.current);
      }
    }, debounceTime || 200);
  });

  useEffect(() => {
    if (onDebouce && initialized.current) {
      valueRef.current = value;
      debounced.cancel();
      debounced();
    }
  }, [debounced, value, onDebouce]);

  useEffect(() => {
    initialized.current = true;
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(value || '');
  }, [onSubmit, value]);

  const handleChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      onChange && onChange(newValue);
    },
    [onChange],
  );

  return (
    <Main
      name={value || ''}
      onChange={handleChange}
      onSubmit={handleSubmit}
      fieldLabel={fieldLabel}
    />
  );
};

export default SearchBar;
