import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HStack justifyContent="space-between" w="100%" mb={6}>
      <VStack spacing="0" alignItems="flex-start">
        <Text color="black" fontSize="4xl" fontWeight={600}>
          {t('tasks.screen_header.title')}
        </Text>
        <Text fontSize="lg" color="gray.600">
          {t('tasks.screen_header.subtitle')}
        </Text>
      </VStack>
    </HStack>
  );
};

export default Header;
