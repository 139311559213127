import { createContext, ReactNode, useContext, useState } from 'react';

import {
  DashMenuPathProps,
  StateDashMenuProps,
  UseStateDashMenuProps,
} from '../interfaces';

const DashboardContext = createContext({} as any);

const DashboardProvider = ({ children }: { children: ReactNode }) => {
  const [dashId, setDashId] = useState<string>('');
  const dashMenuPath: DashMenuPathProps = {
    path: dashId,
    setPath: setDashId,
  };
  const [stateDashMenu, setStateDashMenu] = useState<StateDashMenuProps>({
    groupName: '',
    selected: {
      name: '',
      path: '',
    },
    show: true,
  });

  const useDashMenu: UseStateDashMenuProps = {
    stateDashMenu,
    state: stateDashMenu,
    setState: setStateDashMenu,
    setStateDashMenu,
  };

  const value = {
    useDashMenu,
    dashMenuPath,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

function useDashboardContext() {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error(
      'useDashboardContext deve ser usado dentro de um componente fornecido com o DashboardContext.Provider',
    );
  }

  return context;
}

export { DashboardProvider, useDashboardContext };
