import { ActionCableProvider } from '@/hooks/actionCable';
import React from 'react';

import { ExportsProvider } from './useExports';
import { ImportsProvider } from './useImports';

export const composeProviders = (
  providers: React.FC<{ children: React.ReactNode }>[],
) =>
  providers.reduce((Prev, Curr) => ({ children }) =>
    Prev ? (
      <Prev>
        <Curr>{children}</Curr>
      </Prev>
    ) : (
      <Curr>{children}</Curr>
    ),
  );

export const FileTransferProviders = composeProviders([
  ActionCableProvider,
  ExportsProvider,
  ImportsProvider,
]);
