/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

interface SuggestionsContainerProps {
  hasContent: boolean;
}

export const InputContainer = styled.div`
  flex: 1;
  max-width: 100%;
`;

export const SuggestionsContainer = styled.div<SuggestionsContainerProps>`
  font-size: 0.9em;

  ${(props) => {
    if (props.hasContent) {
      return css`
        padding: 8px 0px;
        background-color: ${(props) => props.theme.colors.secondaryContent};
        border: 1px solid ${(props) => props.theme.colors.border};
        border-radius: 5px;
      `;
    }
  }}

  & > div {
    cursor: pointer;
    padding: 5px 15px;

    :hover {
      background-color: ${({ theme }) => theme.colors.v2_secondary};
    }

    & > span {
      font-family: ${({ theme }) => theme.fonts.primaryFont};
      color: ${(props) => props.theme.colors.darkerText};
      font-size: 12px;
    }
  }

  & > div:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.border};
    padding-top: 2px;
  }
`;
