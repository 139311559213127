import config from '@/Constants';
import { DiscussionTopicStatusType } from '@/hooks/Feedback/interfaces';
import { useQuery } from 'react-query';

export const queryKey = config.DISCUSSION_STATUS_TYPES_URL;

export type StatusTypesQueryResult = DiscussionTopicStatusType[];

export function useFeedbackStatusTypesQuery() {
  const query = useQuery<StatusTypesQueryResult>([queryKey], {
    refetchOnWindowFocus: false,
  });
  return query;
}

export function useStatusTypeValue(params: {
  initialValues: DiscussionTopicStatusType;
}) {
  const query = useQuery<DiscussionTopicStatusType>(
    [queryKey, params?.initialValues?.id],
    {
      enabled: false,
      initialData: params?.initialValues,
      refetchOnWindowFocus: false,
    },
  );
  return query;
}
