import { List } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { orderBy } from 'lodash';
import React from 'react';

import useCurrentSection from '../hooks/useCurrentSection';
import { QuestionItem } from './QuestionItem';

const QuestionList: React.FC = () => {
  const { currentSection } = useCurrentSection();

  const orderedQuestions = React.useMemo(() => {
    return orderBy(currentSection?.questions || [], 'position', 'asc');
  }, [currentSection?.questions]);

  return (
    <AnimatePresence
      key={currentSection ? currentSection.id : 'empty'}
      exitBeforeEnter
    >
      <motion.div
        key={currentSection ? currentSection.id : 'empty'}
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 20 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.1 }}
      >
        <List flex="1">
          {orderedQuestions.map((question) => (
            <QuestionItem key={question.id} question={question} />
          ))}
        </List>
      </motion.div>
    </AnimatePresence>
  );
};

export default QuestionList;
