import config from '@/Constants';
import { DiscussionTopic, FeedbackType } from '@/hooks/Feedback/interfaces';
import { HttpResponse } from '@/modules/shared/data/protocols/http';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { useFeedbackViewDrawer } from '@/screens/monitoring/Feedbacks/FeedbackViewDrawer';
import { createContext } from '@/utils/react-utils';
import { ChatIcon } from '@chakra-ui/icons';
import { Button, ButtonGroup, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useInteractions } from '../../hooks/useInteractions';

import { ItemQuestion } from '../../interfaces';

function getFeedbackCount(
  topic: DiscussionTopic,
  current: FeedbackType,
): { likes: number; dislikes: number } {
  let likes = topic.countLikes || 0;
  let dislikes = topic.countDislikes || 0;
  const previous = topic.voteCurrentUser;
  if (previous === 'like' && current !== previous) {
    likes--;
  }
  if (previous === 'dislike' && current !== previous) {
    dislikes--;
  }
  if (current === 'like' && current !== previous) {
    likes++;
  }
  if (current === 'dislike' && current !== previous) {
    dislikes++;
  }
  return { likes, dislikes };
}

export const createService = <T, R>(url: string) => async (
  params: T,
): Promise<HttpResponse<R>> => {
  const response = await AxiosHelper.post({
    url,
    body: params,
  });

  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new UnexpectedError();
  }

  const body: R = response?.body;

  return { ...response, body };
};

type CreateTopicParams = {
  question_id: string;
  item_id: string;
};

const useCreateTopic = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    createService<CreateTopicParams, DiscussionTopic>(
      `${config.DISCUSSION_TOPICS_URL}`,
    ),
    {
      onSuccess(data) {
        const {
          body: { id },
        } = data;
        queryClient.setQueryData<DiscussionTopic>(
          `${config.DISCUSSION_TOPICS_URL}/${id}`,
          {
            ...data.body,
          },
        );
      },
    },
  );
  return mutation;
};

type CreateFeedbackParams = {
  discussion_topic_id: string;
  vote: FeedbackType;
};

const useCreateFeedback = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    createService<CreateFeedbackParams, DiscussionTopic>(
      `${config.DISCUSSION_LIKES_URL}`,
    ),
    {
      onMutate({ discussion_topic_id, vote }) {
        const queryKey = `${config.DISCUSSION_TOPICS_URL}/${discussion_topic_id}`;
        const previousTopicData = queryClient.getQueryData<DiscussionTopic>(
          queryKey,
        );

        if (!previousTopicData) return;
        const feedbackCount = getFeedbackCount(previousTopicData, vote);
        const updatedTopic: DiscussionTopic = {
          ...previousTopicData,
          voteCurrentUser: vote,
          countLikes: feedbackCount.likes,
          countDislikes: feedbackCount.dislikes,
        };

        queryClient.setQueryData<DiscussionTopic>(queryKey, (old) => ({
          ...old,
          ...updatedTopic,
        }));
      },
      onSettled(data, error, variables, context) {
        queryClient.invalidateQueries(
          `${config.DISCUSSION_TOPICS_URL}/${variables.discussion_topic_id}`,
        );
      },
    },
  );
  return mutation;
};

type DiscussionTopicContextValue = {
  isLoading: boolean;
  mutatingFeedbacks: boolean;
  topic: DiscussionTopic | undefined;
  onLike: () => void;
  onDislike: () => void;
  onComment: () => void;
};

const [DiscussionTopicContext, useDiscussionTopic] = createContext<
  DiscussionTopicContextValue
>({
  name: 'DiscussionTopicContext',
});

const DiscussionTopicProvider: React.FC<{
  topicId?: string;
  questionId: string;
  itemId: string;
  initialValue?: DiscussionTopic;
}> = ({ children, questionId, itemId, initialValue, ...rest }) => {
  const feedbackDrawer = useFeedbackViewDrawer();

  const [topicId, setTopicId] = React.useState(
    rest.topicId || initialValue?.id,
  );

  const {
    mutateAsync: mutateLikeAsync,
    isLoading: mutatingFeedback,
  } = useCreateFeedback();
  const {
    mutateAsync: mutateTopicAsync,
    isLoading: mutatingTopic,
  } = useCreateTopic();

  const { data, isLoading } = useQuery<DiscussionTopic>(
    `${config.DISCUSSION_TOPICS_URL}/${topicId}`,
    {
      enabled: !!topicId,
      initialData: initialValue,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const getTopic = React.useCallback(
    async (noState: boolean = false) => {
      if (!data?.id) {
        const newTopic = await mutateTopicAsync({
          item_id: itemId,
          question_id: questionId,
        });
        if (newTopic.body.id) {
          if (!noState) setTopicId(newTopic.body.id);
          return newTopic.body;
        }
      }
      return data;
    },
    [data, itemId, mutateTopicAsync, questionId],
  );

  const onFeedback = React.useCallback(
    async (feedbackType: FeedbackType) => {
      const topic = await getTopic(true);

      if (topic) {
        await mutateLikeAsync({
          discussion_topic_id: topic.id,
          vote: feedbackType,
        });

        setTimeout(() => {
          setTopicId(topic.id);
        }, 1);
      }
    },
    [getTopic, mutateLikeAsync],
  );

  const onDislike = React.useCallback(() => {
    if (data?.voteCurrentUser === 'dislike') {
      onFeedback('none');
      return;
    }
    onFeedback('dislike');
  }, [data?.voteCurrentUser, onFeedback]);

  const onLike = React.useCallback(() => {
    if (data?.voteCurrentUser === 'like') {
      onFeedback('none');
      return;
    }
    onFeedback('like');
  }, [data?.voteCurrentUser, onFeedback]);

  const onComment = React.useCallback(async () => {
    const topic = await getTopic();

    if (topic) {
      feedbackDrawer.open(topic.id);
    }
  }, [feedbackDrawer, getTopic]);

  return (
    <DiscussionTopicContext
      value={{
        onLike,
        onDislike,
        onComment,
        isLoading,
        mutatingFeedbacks: mutatingFeedback || mutatingTopic,
        topic: data,
      }}
    >
      {children}
    </DiscussionTopicContext>
  );
};

export const Content: React.FC<{ question: ItemQuestion }> = ({ question }) => {
  const {
    topic,
    onLike,
    onDislike,
    mutatingFeedbacks,
    onComment,
  } = useDiscussionTopic();

  const userReaction = topic?.voteCurrentUser;

  const likesAmount = React.useMemo(() => topic?.countLikes || 0, [
    topic?.countLikes,
  ]);
  const dislikesAmount = React.useMemo(() => topic?.countDislikes || 0, [
    topic?.countDislikes,
  ]);
  const commentsAmount = React.useMemo(() => topic?.commentsCount || 0, [
    topic?.commentsCount,
  ]);

  return (
    <ButtonGroup size="md" variant="outline">
      <Button
        onClick={onLike}
        disabled={mutatingFeedbacks}
        leftIcon={<Icon as={FiThumbsUp} fontSize="15px" />}
        aria-label="like"
        colorScheme={userReaction === 'like' ? 'green' : 'gray'}
        variant={userReaction === 'like' ? 'subtle' : 'outline'}
      >
        {likesAmount}
      </Button>
      <Button
        onClick={onDislike}
        disabled={mutatingFeedbacks}
        leftIcon={<Icon as={FiThumbsDown} fontSize="15px" />}
        aria-label="dislike"
        colorScheme={userReaction === 'dislike' ? 'red' : 'gray'}
        variant={userReaction === 'dislike' ? 'subtle' : 'outline'}
      >
        {dislikesAmount}
      </Button>
      <Button leftIcon={<ChatIcon />} aria-label="dislike" onClick={onComment}>
        {commentsAmount}
      </Button>
    </ButtonGroup>
  );
};

const QuestionFeedbackComponent: React.FC<{ question: ItemQuestion }> = ({
  question,
}) => {
  const { selectedItemId } = useInteractions();
  if (!selectedItemId) return null;
  return (
    <DiscussionTopicProvider
      key={selectedItemId}
      initialValue={question?.discussionTopic}
      topicId={question?.discussionTopic?.id}
      questionId={question.id}
      itemId={selectedItemId}
    >
      <Content question={question} />
    </DiscussionTopicProvider>
  );
};

export const QuestionFeedback = React.memo(QuestionFeedbackComponent);
