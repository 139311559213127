import { Mask } from '@/utils/Masks/types';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import React from 'react';

type ValidateTypes = Partial<Record<Mask, RegExp>>;

const FormControlComponent: React.FC<{
  children: React.ReactElement;
  isInvalid?: boolean;
  mask?: Mask;
}> = ({ children, isInvalid, mask }) => {
  const validateTypes: ValidateTypes = {
    EMAIL: /\w+@\w+\.\w+/,
  };

  const regexValidate =
    mask && RegExp(validateTypes[mask] as RegExp).test(children?.props?.value);

  const invalidValue = !!mask && children?.props?.value && !regexValidate;

  return (
    <FormControl isInvalid={isInvalid || invalidValue}>
      {children}
      <FormErrorMessage>
        {invalidValue ? `${mask} inválido` : 'Este campo é obrigatório'}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormControlComponent;
