import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ErrorObject, FieldProps, IAnswerState } from '../domain/usecases';
import { useSurveyForm } from '../hooks/useSurveyForm';

function usePrevious(value: any) {
  const ref = useRef(value);
  return ref;
}

export function Field<T>({
  question,
  render: Render,
  onSubmit,
}: FieldProps<T>): JSX.Element | null {
  const {
    getValues,
    subscribe,
    registerField,
    onChange,
    unregisterField,
    errors,
    viewOnly,
    onChangeTitleField,
  } = useSurveyForm();

  const { id, required, parentQuestionId } = question;
  const previousValues = getValues(question.id);
  let initialValues = previousValues;
  if (initialValues === undefined && question.initValue) {
    initialValues = { answeredAt: new Date(), values: question.initValue };
  }
  const [visible, setVisible] = useState<boolean>(!parentQuestionId);
  const [error, setError] = useState<ErrorObject | undefined>(undefined);
  const prevVisible = usePrevious(!parentQuestionId);

  useEffect(() => {
    if (errors[question.id]) {
      setError(errors[question.id]);
    }
  }, [errors, question.id]);

  const callback = useCallback(
    (value: IAnswerState) => {
      const { parentOptionId } = question;

      const isVisible = Array.isArray(value?.values)
        ? value?.values.includes(parentOptionId)
        : value?.values === parentOptionId;

      if (isVisible && !prevVisible.current) {
        prevVisible.current = isVisible;
        setVisible(isVisible);
        registerField({ id, required });
        return;
      }
      if (!isVisible && prevVisible.current) {
        prevVisible.current = isVisible;
        setVisible(false);
        unregisterField(id);
      }
    },
    [question, id, prevVisible, required, registerField, unregisterField],
  );

  const onChangeCallback = useCallback(
    (value: IAnswerState) => {
      setError(undefined);
      onChange(id, value);
    },
    [id, onChange],
  );

  const onChangeTitleFieldCallback = useCallback(
    (value: IAnswerState) => {
      setError(undefined);
      onChangeTitleField(value);
    },
    [onChangeTitleField],
  );

  useEffect(() => {
    let unsub: () => void;
    function initialize() {
      if (parentQuestionId) {
        unsub = subscribe(parentQuestionId, callback);
      }
    }
    initialize();
    return () => {
      unsub && unsub();
    };
  }, [parentQuestionId, callback, subscribe]);

  useEffect(() => {
    if (!previousValues && initialValues && visible) {
      onChangeCallback(initialValues);
    }
  }, [onChangeCallback, previousValues, initialValues, visible]);

  if (!visible) return null;

  return (
    <>
      <Render
        initialValues={initialValues}
        onChange={onChangeCallback}
        onChangeScheduleTitle={onChangeTitleFieldCallback}
        question={question}
        onSubmit={onSubmit}
        viewOnly={viewOnly}
        errors={error}
      />
    </>
  );
}
