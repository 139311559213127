import WorkspaceAwareIcon from '@/components/WorkspaceAwareIcon';
import { useNotifications } from '@/hooks/Notifications/notifications';
import { usePresentation } from '@/hooks/Presentation';
import {
  Badge,
  Button,
  Divider,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useAuth } from '@hooks/Authentication/auth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdExitToApp } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';

import ChangeLanguage from './components/ChangeLanguage';
import FileNotifications from './components/FileNotifications';
import Notifications from './components/Notifications';
import {
  BottomContainer,
  Container,
  HamburguerContainer,
  HamburguerIcon,
  MobileSecondaryButtonsContainer,
  ProfileContainer,
  TopContainer,
} from './styles';

export interface NavbarRenderProps {
  setMainMenuOpen: (state: boolean) => void;
  isMainMenuOpen: boolean;
  menuDisabled: boolean;
}

const Main: React.FC<NavbarRenderProps> = ({
  setMainMenuOpen,
  isMainMenuOpen,
  menuDisabled = false,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { signOut, user } = useAuth();
  const { t } = useTranslation();
  const { isPresenting } = usePresentation();
  const { pendingNotifications } = useNotifications();

  const username = user?.full_name?.substr(0, 1);

  const ROLE_COLOR: any = {
    r: '#E1D6F8',
    rw: '#E8D5CE',
    admin: '#FFBCBC',
  };

  const UserMenu = () => (
    <Popover placement="right-start">
      <PopoverTrigger>
        <ProfileContainer>{username}</ProfileContainer>
      </PopoverTrigger>
      <PopoverContent p={3} w="-webkit-fit-content" ml={3}>
        <Text fontSize={15} fontWeight="bold">
          {user?.full_name}
        </Text>
        <Badge
          borderRadius="full"
          px="2"
          py="1"
          bg={ROLE_COLOR[user?.user_type || '']}
          w="-webkit-fit-content"
          color="gray.600"
        >
          {t(`permissions.${user?.user_type}`)}
        </Badge>
        <Text>{user?.email}</Text>
        <Button
          mt={4}
          bg="withe"
          color="red"
          onClick={() => signOut()}
          width="-webkit-fit-content"
        >
          <MdExitToApp size={18} />
          Sair
        </Button>
      </PopoverContent>
    </Popover>
  );

  const UserMenuMobile = () => (
    <Popover placement="right-start">
      <PopoverTrigger>
        <ProfileContainer hasOpenNotification={pendingNotifications > 0}>
          {username}
        </ProfileContainer>
      </PopoverTrigger>
      <PopoverContent p={3} w="200px" ml={3} gap={1}>
        <Text fontSize="lg" fontWeight="bold">
          {user?.full_name}
        </Text>
        <Text>{user?.email}</Text>
        <Badge
          borderRadius="full"
          px="2"
          py="1"
          bg={ROLE_COLOR[user?.user_type || '']}
          w="-webkit-fit-content"
          color="gray.600"
        >
          {t(`permissions.${user?.user_type}`)}
        </Badge>

        <Divider />
        <Flex gap={2} flexDir="column">
          <FileNotifications isMobile />
          <Notifications isMobile />
          <ChangeLanguage isMobile />
        </Flex>
        <Divider />
        <Button
          gap="0.75rem"
          bg="withe"
          color="red"
          onClick={() => signOut()}
          width="-webkit-fit-content"
        >
          <MdExitToApp size={18} />
          <Text fontSize="md">Sair</Text>
        </Button>
      </PopoverContent>
    </Popover>
  );

  return (
    <Container>
      {isMobile ? (
        <>
          <WorkspaceAwareIcon />
          {!menuDisabled && !user?.read_all && !user?.dashboard_only && (
            <HamburguerContainer
              isMainMenuOpen={isMainMenuOpen}
              onMouseEnter={() => {
                if (!isMobile) setMainMenuOpen(true);
              }}
              onClick={() => {
                if (isMobile) setMainMenuOpen(!isMainMenuOpen);
              }}
            >
              <HamburguerIcon />
            </HamburguerContainer>
          )}
          <MobileSecondaryButtonsContainer>
            <UserMenuMobile />
          </MobileSecondaryButtonsContainer>
        </>
      ) : (
        <>
          <TopContainer>
            <WorkspaceAwareIcon />
            {!menuDisabled && !user?.read_all && !user?.dashboard_only && (
              <HamburguerContainer
                isMainMenuOpen={isMainMenuOpen}
                onMouseEnter={() => {
                  setMainMenuOpen(true);
                }}
              >
                <HamburguerIcon />
              </HamburguerContainer>
            )}
          </TopContainer>
          <BottomContainer>
            {!isPresenting && (
              <>
                <FileNotifications />
                <Notifications />
                <ChangeLanguage />
                <UserMenu />
              </>
            )}
          </BottomContainer>
        </>
      )}
    </Container>
  );
};

export default Main;
