/* eslint-disable react/no-array-index-key */
import _ from 'lodash';

import { Badge } from '../components/badge';
import {
  QuestionContext,
  useQuestion,
  useReport,
  useSection,
} from './hooks/use-context';

function QuestionFeedback() {
  const question = useQuestion();

  if (!question?.all_feedbacks || question?.all_feedbacks.length <= 0)
    return <></>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        fontSize: '0.875rem',
        color: '#475569',
      }}
    >
      <span
        style={{
          marginRight: '0.5rem',
        }}
      >
        Feedback:{' '}
      </span>
      {question?.all_feedbacks.map((feedback, index) => (
        <div
          key={`${feedback.author}_${index}`}
          style={{
            borderRadius: '0.375rem',
            border: '1px solid #d1d5db',
            padding: '0.5rem',
            textAlign: 'left',
          }}
        >
          <strong>{feedback.author}</strong>
          <span> - </span>
          <span>{feedback.content}</span>
        </div>
      ))}
    </div>
  );
}

function QuestionComment() {
  const question = useQuestion();

  if (!question?.answers?.[0]?.comment) return <></>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        fontSize: '0.875rem',
        color: '#475569',
      }}
    >
      <span
        style={{
          marginRight: '0.5rem',
        }}
      >
        Notas:
      </span>
      <div
        style={{
          borderRadius: '0.375rem',
          border: '1px solid #d1d5db',
          padding: '0.5rem',
          textAlign: 'left',
        }}
      >
        <span>{question?.answers?.[0]?.comment}</span>
      </div>
    </div>
  );
}

function QuestionReaction(props: { type: 'like' | 'dislike'; count: number }) {
  const { count = 0, type } = props;
  const label = type === 'dislike' ? 'Dislikes' : 'Likes';

  const variants = {
    like: {
      borderColor: '#4ade80',
      backgroundColor: '#86efac',
      color: '#1f2937',
    },
    dislike: {
      borderColor: '#fca5a1',
      backgroundColor: '#fca5a1',
      color: '#991b1b',
    },
  };

  return (
    <span
      style={{
        marginRight: '0.5rem',
        borderRadius: '0.375rem',
        border: `1px solid ${variants[type].borderColor}`,
        padding: '0.25rem 0.625rem',
        fontSize: '0.875rem',
        fontWeight: 500,
        backgroundColor: variants[type].backgroundColor,
        color: variants[type].color,
      }}
    >{`${count} ${label}`}</span>
  );
}

function QuestionReactions() {
  const question = useQuestion();

  if (!question.likes && !question.dislikes) return <></>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
      }}
    >
      <QuestionReaction count={question.likes || 0} type="like" />
      <QuestionReaction count={question.dislikes || 0} type="dislike" />
    </div>
  );
}

function QuestionScore() {
  const { header } = useReport();
  const question = useQuestion();

  if (!question.answers[0]?.score || header?.hide_score) return <></>;

  return (
    <div
      style={{
        fontSize: '0.875rem',
        color: '#475569',
      }}
    >
      <span
        style={{
          marginRight: '0.5rem',
        }}
      >
        Peso da pergunta: {question.weight}
      </span>
      <span>Pontuação obtida: {question.score_obtained}</span>
    </div>
  );
}

function QuestionHeader() {
  const question = useQuestion();
  const section = useSection();

  const hasFlag = question.answers?.some((e) => e.red_flag);

  const isFlagged = question.answers?.some((e) => e.is_flagged);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0',
        paddingBottom: '0.5rem',
      }}
    >
      <div
        style={{
          float: 'right',
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: '0.5rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '4rem',
          }}
        >
          <span>
            <p
              style={{
                marginBottom: '1rem',
                fontSize: '0.625rem',
                color: '#9ca3af',
              }}
            >
              {section.section_name}
            </p>
            <h2
              style={{
                fontSize: '0.875rem',
                fontWeight: 600,
              }}
            >
              {question.name}
            </h2>
            <QuestionScore />
          </span>
          {!!question.image && (
            <img
              src={question.image}
              alt=""
              style={{
                height: '5rem',
                width: '5rem',
              }}
            />
          )}
        </div>

        {(!!hasFlag || !!isFlagged) && (
          <Badge size="xs" colorScheme="orange">
            Sinalizada como inconforme
          </Badge>
        )}
      </div>
    </div>
  );
}

export function QuestionItem({ children }: { children?: React.ReactNode }) {
  return (
    <li
      style={{
        marginBottom: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        gap: '1rem',
        borderBottom: '1px solid',
        padding: '0.5rem',
        pageBreakInside: 'avoid',
      }}
    >
      <QuestionHeader />
      {!!children && (
        <div
          style={{
            wordBreak: 'break-word',
            backgroundColor: '#f7fafc',
            padding: '0.75rem',
          }}
        >
          {children}
        </div>
      )}
      <QuestionReactions />
      <QuestionComment />
      <QuestionFeedback />
    </li>
  );
}

export function QuestionList({ children }: { children?: React.ReactNode }) {
  const { questions } = useSection();

  return (
    <ul
      style={{
        width: '100%',
        padding: '0.25rem',
      }}
    >
      {_.orderBy(questions, 'position', 'asc').map((question, idx) => (
        <QuestionContext value={question} key={`${question.name}-${idx}`}>
          <QuestionItem>{children}</QuestionItem>
        </QuestionContext>
      ))}
    </ul>
  );
}
