/* Questions */

// TODO - APAGAR INTERFACES NÃO UTILIZADAS

export type QuestionType =
  | 'checkbox'
  | 'radio'
  | 'radio_combo'
  | 'checkbox_combo'
  | 'picture'
  | 'gallery'
  | 'collections'
  | 'geolocation'
  | 'date'
  | 'signature'
  | 'action_plan'
  | 'dropdown'
  | 'text'
  | 'week'
  | 'remote_multi_select'
  | 'remote_single_select'
  | 'picker_date'
  | 'recurrence'
  | 'status_box'
  | 'address'
  | 'password'
  | 'date_range'
  | 'hour'
  | 'emails_for_send'
  | 'barcode'
  | 'money'
  | 'nested_form'
  | 'chronometer'
  | 'decimal'
  | 'toggle'
  | 'integer'
  | 'number'
  | 'code_generator'
  | 'user';

export enum QuestionTypeEnum {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  RADIO_COMBO = 'radio_combo',
  CHECKBOX_COMBO = 'checkbox_combo',
  PICTURE = 'picture',
  GALLERY = 'gallery',
  COLLECTIONS = 'collections',
  GEOLOCATION = 'geolocation',
  DATE = 'date',
  DROPDOWN = 'dropdown',
  SIGNATURE = 'signature',
  ACTION_PLAN = 'action_plan',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  WEEK = 'week',
  ADDRESS = 'address',
  PARTICIPANTS = 'participants_list',
  REMOTE_MULTI_SELECT = 'remote_multi_select',
  REMOTE_SINGLE_SELECT = 'remote_single_select',
  PICKER_DATE = 'picker_date',
  RECURRENCE = 'recurrence',
  STATUS_BOX = 'status_box',
  PASSWORD = 'password',
  INTEGER = 'integer',
  DECIMAL = 'decimal',
  HOUR = 'hour',
  MONEY = 'money',
  BARCODE = 'barcode',
  EMAIL_INPUT = 'emails_for_send',
  DATE_MULTI = 'date_multi',
  DATE_RANGE = 'date_range',
  NESTED_FORM = 'nested_form',
  CHRONOMETER = 'chronometer',
  NUMBER = 'number',
  TOGGLE = 'toggle',
  PHONE = 'phone',
  EMAIL = 'email',
  CODE_GENERATOR = 'code_generator',
  USER = 'user',
}

export const MediaQuestionTypes = ['picture', 'gallery'];

/* Events */

export enum EventParentableTypes {
  INSPECTION = 'Inspection',
  ACTION_PLAN = 'ActionPlan',
  SCHEDULE = 'Schedule',
  TASK = 'Task',
  INCIDENT = 'Incident',
}

export enum EventStatus {
  PENDING = 'pending',
  DELAYED = 'delayed',
  DRAFT = 'draft',
  DONE = 'done',
  INCOMPLETE = 'incomplete',
  RESCHEDULED = 'rescheduled',
}

export type EventStatusType =
  | 'pending'
  | 'done'
  | 'started'
  | 'incomplete'
  | 'delayed'
  | 'draft'
  | 'flagged'
  | 'rescheduled'
  | 'deleted'
  | 'without_answers'
  | 'deleted_without_answers';

export interface CreateEventData {
  parentableId: string;
  parentableType: EventParentableTypes;
}

export interface CreateItemData {
  formularyId: string;
  eventId: string;
}

/* Answers */

export interface CreateAnswerData {
  itemId: string;
  eventId: string;
  questionType: QuestionType;
  content: string;
  questionId: string;
  optionId?: string;
}

export type MediaParentType = 'answer';

export interface CreateMediaItemData {
  parentId: string;
  parentType: MediaParentType;
  value: string;
}

export enum RecurrencyTypes {
  default = 'default',
}

/* Serialization */

export interface SerializeDTO {
  parentType: EventParentableTypes;
  parentId: string;
  formularyId: string;
  answers: FormularyAnswers;
  eventId: string;
}

export interface ReducedAnswer {
  answeredAt: Date;
  questionType: QuestionType;
  values: any;
}

export interface SerializedAnswer {
  answered_at: Date;
  questionType: QuestionType;
  value: string;
}

export interface ActionPlanDTO extends ReducedAnswer {
  value: SerializeDTO;
}

export interface ReducedAnswers {
  [id: string]: ReducedAnswer;
}

export interface FormularyAnswers {
  [id: string]: ReducedAnswer | ActionPlanDTO;
}

export interface SerializeFormularyData {
  formularyId: string;
  eventId: string;
}

export interface SerializeActionPlanData {
  formularyId: string;
  actionPlanId: string;
}

/* Formulary */

export enum DateFormatType {
  UNIX_TIMESTAMP = 'unix_timestamp',
  DEFAULT_TIMESTAMP = 'default_timestamp',
}

export interface QuestionAttributes {
  id: string;
  name: string;
}

export interface QuestionModel {
  id: string;
  name: string;
  formularyId?: string;
  sectionId?: string;
  questionType: QuestionTypeEnum | QuestionType;
  parentQuestionId: string;
  parentOptionId?: string;
  actionPlanId?: string;
  required: boolean;
  enabled: boolean;
  optionsUrl: string | null;
  optionKey: string;
  optionName: string;
  options: any[];
  position?: number;
  config: any;
  dateFormat?: DateFormatType;
  attributesMatch?: string;
  attributes?: QuestionAttributes[];
}
export interface Section {
  id: string;
  name: string;
  questions: QuestionModel[];
}

export interface Page {
  id: string;
  name: string;
  sections: Section[];
}

export interface FetchFormularyResponse {
  id: string;
  pages: Page[];
}

export interface Option {
  id: string;
  name: string;
  position: number;
  score?: string;
  deleted_at?: Date;
  questionId?: string;
  _temporary?: boolean | false;
  _destroy?: boolean | false;
}
