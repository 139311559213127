import { CustomIcons } from '@/screens/Tickets/utils/customIcons';
import { MenuItem } from '@chakra-ui/react';
import React from 'react';

import { usePropertyMenu } from '.';
import { useUpdatePropertyForm } from '../../../Forms/UpdateProperty';

function EditProperty() {
  const openForm = useUpdatePropertyForm();
  const { property } = usePropertyMenu();

  return (
    <MenuItem
      fontSize="lg"
      onClick={() => openForm({ propertyId: property.id })}
      icon={<CustomIcons.PencilIcon fontSize="lg" />}
    >
      Editar propriedade
    </MenuItem>
  );
}

export const PropertyOptions = {
  EditProperty,
};
