import React from 'react';

import { AnswerContent } from './answer-content';
import { Header } from './header';
import { ReportContext } from './hooks/use-context';
import { ItemsList } from './items-list';
import { QuestionList } from './question-list';
import { SectionsList } from './sections-list';
import type { EventReportData } from './types';

export function EventReport(props: { reportData: EventReportData }) {
  const { reportData } = props;
  return (
    <ReportContext value={reportData}>
      <div
        style={{
          height: '100vh',
          width: '100vw',
          flex: 1,
          backgroundColor: '#ffffff',
          fontFamily: 'sans-serif',
          padding: '0.5rem',
        }}
      >
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            height: 'auto',
            maxWidth: '64rem',
          }}
        >
          <Header />
          <ItemsList>
            <SectionsList>
              <QuestionList>
                <AnswerContent />
              </QuestionList>
            </SectionsList>
          </ItemsList>
        </div>
        {!!reportData?.header?.ticket_custom_code && (
          <footer>Contrato: {reportData?.header?.ticket_custom_code}</footer>
        )}
      </div>
    </ReportContext>
  );
}
