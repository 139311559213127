import React from 'react';
import { AnswerStateMap } from '@/modules/WebForm/interfaces';
import { SerializeCallbackFunc } from '@/screens/ItemEdit/hooks/useSerializedSubmit';
import { CustomIcons } from '@/screens/Workflow/utils/customIcons';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { WorkflowInspection } from '../../WorkflowInspection/WorkflowInspection';

type ChecklistInputProps = {
  onChange?: (payload: any) => void;
  formularyId: string;
  formularyName: string;
  defaultValues?: AnswerStateMap;
};

const ChecklistInputDrawer: React.FC<
  ChecklistInputProps & {
    isOpen?: boolean;
    onClose?: () => void;
  }
> = (props) => {
  const {
    isOpen = false,
    onClose = () => null,
    defaultValues,
    onChange = () => null,
    formularyId = '',
    formularyName,
  } = props;
  return (
    <Drawer size="xl" isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Checklist - {formularyName}</DrawerHeader>

        <DrawerBody bg="red" p="0">
          <WorkflowInspection
            defaultValues={defaultValues}
            formularyId={formularyId}
            onSubmit={onChange}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export const ChecklistInput: React.FC<ChecklistInputProps> = (props) => {
  const toast = useToast();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    defaultValues,
    onChange = () => null,
    formularyId = '',
    formularyName,
  } = props;

  const [answers, setAnswers] = React.useState<AnswerStateMap | undefined>(
    defaultValues,
  );

  const handleSubmit: SerializeCallbackFunc = React.useCallback(
    (result) => {
      if (result.answers && result.isValid) {
        onClose();
        toast({
          title: 'Checklist respondido.',
        });
        setAnswers(result.state);
        onChange(result.answers);
      }
    },
    [onChange, onClose, toast],
  );

  const renderTag = React.useCallback(() => {
    const label = answers ? 'Respondido' : 'Pendente';
    const color = answers ? 'green' : 'gray';
    return (
      <Tag colorScheme={color} variant="solid">
        {label}
      </Tag>
    );
  }, [answers]);

  const renderButton = React.useCallback(() => {
    const label = answers ? 'Revisar' : 'Responder';
    const color = answers ? 'primary' : 'green';
    return (
      <Button
        onClick={() => onOpen()}
        colorScheme={color}
        rightIcon={
          !answers ? <CustomIcons.PlayIcon fontSize="16px" /> : undefined
        }
      >
        {label}
      </Button>
    );
  }, [answers, onOpen]);

  return (
    <>
      <ChecklistInputDrawer
        isOpen={isOpen}
        onClose={onClose}
        defaultValues={answers}
        onChange={handleSubmit}
        formularyId={formularyId}
        formularyName={formularyName}
      />
      <Flex w="full">
        <Flex
          cursor="default"
          w="full"
          gap="4"
          h="12"
          p="2"
          alignItems="center"
          transitionProperty="background-color"
          transitionDuration="var(--chakra-transition-duration-normal)"
          _hover={{
            bg: 'gray.100',
          }}
          rounded="md"
          flexDir="row"
          borderWidth="thin"
          justifyContent="space-between"
        >
          <Flex flexDir="row" alignItems="center" gap="2">
            {renderTag()}
            <Text
              color="muted"
              mt="0.5"
              fontWeight="semibold"
              fontSize="sm"
              isTruncated
              noOfLines={1}
            >
              {formularyName}
            </Text>
          </Flex>
          {renderButton()}
        </Flex>
      </Flex>
    </>
  );
};
