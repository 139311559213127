import { gql } from '@/gql';
import { FlowsCreateInput } from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

import { queryAutomationGroups } from './useFetchAutomationGroups';

const mutationCreateAutomationFlow = gql(`#graphql
  mutation CreateAutomationFlow($input: FlowsCreateInput!) {
    createAutomationFlow(input: $input) {
      errors
      clientMutationId
      automationFlow {
        id
        name
        createdAt
      }
    }
  }
`);

export function useCreateAutomationFlow() {
  const toast = useToast();
  const [createAutoFlow, { data, loading, error }] = useMutation(
    mutationCreateAutomationFlow,
    {
      refetchQueries: [queryAutomationGroups],
    },
  );

  const createAutomationFlow = useCallback(
    async (payload: FlowsCreateInput) => {
      const result = await createAutoFlow({
        variables: {
          input: payload,
        },
      });

      const { data, errors } = result;

      if (data || errors) {
        toast({
          title: errors ? 'Criação do fluxo falhou' : 'Fluxo criado.',
          status: errors ? 'error' : 'success',
        });
      }

      return result;
    },
    [createAutoFlow, toast],
  );

  return {
    createAutomationFlow,
    loading,
    error,
    data: data?.createAutomationFlow || null,
  };
}
