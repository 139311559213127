import { gql } from '@/gql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';

import { queryAutomationGroups } from './useFetchAutomationGroups';

export const deleteAutomationFlowMutation = gql(`#graphql
mutation DeleteAutomationFlow($input: FlowsDeleteInput!) {
  deleteAutomationFlow(input: $input) {
    automationFlow {
      id
      deletedAt
      createdAt
      name
      position
      updatedAt
    }
  }
}
`);

export function useDeleteAutomationFlow() {
  const toast = useToast();
  return useMutation(deleteAutomationFlowMutation, {
    refetchQueries: [queryAutomationGroups],
    awaitRefetchQueries: true,
    onCompleted(data) {
      if (data.deleteAutomationFlow?.automationFlow) {
        toast({
          title: 'Fluxo excluído',
          status: 'success',
        });
      }
    },
  });
}
