import { DashboardProvider } from '@/screens/Dashboard/hooks/useDashboard';
import Layout from '@/screens/utils/AppLayout';
import React, { Suspense } from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

const Dashboard = React.lazy(() => import('@/screens/Dashboard'));

const DashboardWithProvider = () => {
  return (
    <DashboardProvider>
      <Dashboard />
    </DashboardProvider>
  );
};

const DashboardRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Suspense fallback={null}>
        <Switch>
          <Route path={`${path}`} component={DashboardWithProvider} exact />
          <Route
            path={`${path}/:dashStartId`}
            component={DashboardWithProvider}
          />
          <Redirect to={`${path}`} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default DashboardRoutes;
