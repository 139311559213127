import { DateFormatType } from '@/modules/core/domain/interfaces';
import { Layouts } from 'react-grid-layout';

/* Filter */

export enum FilterType {
  SINGLE = 'single',
  MULTI = 'multi',
  SEARCH = 'search',
  DATE = 'date',
  DATE_RANGE = 'date_range',
  SLIDER = 'slider',
}

export interface FilterData {
  table: string;
  column: string;
  title: string;
  type: FilterType;
  url: string;
  dateFormat: DateFormatType;
  parentFields: string[];
  maxSelection?: number;
}

export interface FilterCreateData {
  column: string;
  title: string;
  type: FilterType;
  dateFormat: DateFormatType;
  parentFields: string[];
  sliderType?: 'mark' | 'range' | 'default';
  defaultValue?: number | readonly number[];
  maxValue?: number;
  minValue?: number;
  maxSelection?: number;
}

export type FiltersData = FilterData[];

export type FiltersCreateData = FilterCreateData[];

export interface SelectedFilterBase {
  column: string;
  type: FilterType;
}

export interface SelectedDateFilter extends SelectedFilterBase {
  type: FilterType.DATE;
  selected: Date | null;
}

export interface SelectedDateRangeFilter extends SelectedFilterBase {
  type: FilterType.DATE_RANGE;
  selected: null | {
    dateStart: Date;
    dateEnd: Date;
  };
}

export interface SelectedSingleFilter extends SelectedFilterBase {
  type: FilterType.SINGLE;
  selected: null | string | number | boolean;
}

export interface SelectedMultiFilter extends SelectedFilterBase {
  type: FilterType.MULTI;
  selected: null | (string | number)[];
}

export interface SelectedSearchFilter extends SelectedFilterBase {
  type: FilterType.SEARCH;
  selected: null | string;
}

export interface SelectedSliderFilter extends SelectedFilterBase {
  type: FilterType.SLIDER;
  selected: null | readonly number[] | number;
}

export type SelectedFilter =
  | SelectedSingleFilter
  | SelectedMultiFilter
  | SelectedDateRangeFilter
  | SelectedSearchFilter
  | SelectedDateFilter
  | SelectedSliderFilter;

export interface SelectedFilters {
  table: string;
  filters: SelectedFilter[];
}

export interface FetchFilterData {
  table: string;
  column: string;
  title: string;
  type: FilterType;
  url: string;
  dateFormat: DateFormatType;
  parentFields: string[];
  sliderType?: 'mark' | 'range' | 'default';
  defaultValue?: number | readonly number[];
  maxValue?: number;
  minValue?: number;
  maxSelection?: number;
}

export type FetchFiltersResponse = FetchFilterData[];

export interface FetchOptionsParams {
  path: string;
  query?: string;
  page?: number;
  searchValue?: string;
  title?: string;
}

export interface OptionData {
  key: string;
  value: any;
}

export type FetchOptionsResponse = {
  options: OptionData[];
  hasNext: boolean;
};

/* Request */

export interface QueryParams {
  columns?: string[];
  size: number;
  page: number;
  order?: 'asc' | 'desc';
  orderBy?: string;
  filters?: string;
  apiVersion?: number;
}

export interface Item {
  id: string;
  [column: string]: any;
}

export interface QueryResponse<T = Item> {
  hasNextPage: boolean;
  totalItems: number;
  items: T[];
}

export interface QueryDashParams {
  filters?: string;
}

export interface DashCard {
  id: string;
  type: string;
  title?: string;
  subtitle?: string;
  url: string;
}

export interface DashCardMap {
  [column: string]: DashCard;
}

export interface InitialFiltersData {
  [key: string]: any;
}

export type DashActions = {
  canTakeScreenshot?: boolean;
  canPresent?: boolean;
};

export interface QueryDashResponse {
  id: string;
  superGroupId: string;
  name: string;
  urlPictureLogo?: string;
  filterEndpoint: string;
  cards: DashCardMap;
  layouts: Layouts;
  rowHeight?: number;
  hiddenFilter?: boolean;
  initialFilters?: InitialFiltersData[];
  compactType?: 'vertical' | 'horizontal' | null;
  cols?: { [P: string]: number };
  isDraggable?: boolean;
  isResizable?: boolean;
  isDroppable?: boolean;
  isBounded?: boolean;
  enabled?: boolean;
  createApi?: boolean;
  filterPanel?: boolean;
  navigationPanel?: boolean;
  refreshCardTimout?: Nullable<number>;
  filterLayout?: string;
  dashActions?: DashActions;
}

export interface QueryCardDataParams {
  filters?: string;
  page?: string;
  limit?: number;
  search?: string;
  donuts_info?: (string | number)[];
}

export interface QueryCardDataResponse {
  page?: string;
  limit?: number;
  content: any;
  filters: { [column: string]: string[] };
}

export interface QuerySupergroupsResponse {
  id: string;
  name: string;
}
