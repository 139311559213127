/* eslint-disable react/react-in-jsx-scope */
import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ExternalTask } from '../components/ExternalTask';

function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function ExternalTaskPage() {
  const { id } = useParams<{ id: string }>();
  const query = useQueryParams();

  if (!id) {
    return (
      <Flex flex={1}>
        <Text fontSize="4xl">Not found</Text>
      </Flex>
    );
  }

  return (
    <ExternalTask
      email={query.get('email')?.toString()}
      templateTaskUrlId={id}
    />
  );
}
