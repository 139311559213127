import styled, { css } from 'styled-components';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import constants from '../../constants';

interface OptionalProps {
  isCollapsed?: boolean;
}

interface ListItemProps {
  isSelected: boolean;
  disabled?: boolean | false;
}

interface SubMenuBarProps {
  isMainMenuOpen: boolean;
  isCollapsedMode: boolean;
}

export const Container = styled.div<SubMenuBarProps>`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  transition: left 200ms ease-in-out;
  z-index: 9;
  padding-left: 30px;

  @media (min-width: 768px) {
    height: 100vh;
    position: absolute;
    width: ${constants.subMenuBarWidth};
    ${(props) => {
      if (props.isMainMenuOpen) {
        return css`
          left: calc(
            ${constants.mainMenuBarWidth} + ${constants.navbarWidth} - 5px
          );
        `;
      }
      return css`
        left: ${constants.navbarWidth};
      `;
    }}
  }

  ${(props) => {
    if (props.isCollapsedMode && !props.isMainMenuOpen) {
      return 'transform: translateX(-90%); transition: transform 200ms ease-in-out;';
    }
    return 'transform: translateX(0%); transition: transform 200ms ease-in;';
  }}

  border-right: 1.5px solid ${(props) => props.theme.colors.border};
`;

export const MainRouteTitle = styled.p`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
`;

export const ListItem = styled.li<ListItemProps>`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 15px;
  margin-bottom: 8px;
  font-weight: 400;
  transition: transform 200ms;
  color: ${(props) =>
    props.isSelected
      ? props.theme.colors.primary
      : props.theme.colors.darkText};

  ${(props) => {
    if (props.disabled) return `pointer-events: none; opacity: 0.3;`;
    return '';
  }}

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

export const List = styled.ul`
  list-style-type: none;
  /* padding-left: 30px; */
`;

export const CollapseButton = styled.div<OptionalProps>`
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #fff;
  position: absolute;
  right: -13px;
  top: 33px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 200ms;

  ${({ isCollapsed }) => {
    if (isCollapsed) {
      return 'transform: rotate(180deg);';
    }
    return 'transform: rotate(0deg);';
  }}
`;

export const CollapseIcon = styled(FaAngleDoubleLeft)`
  color: ${(props) => props.theme.colors.darkText};
`;
