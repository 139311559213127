import * as yup from 'yup';

export const optionSchema = yup.object().default(null).nullable().shape({
  label: yup.string().required(),
  value: yup.string().required(),
});

export const tagSchema = optionSchema.shape({
  color: yup.string(),
});

export const taskSchema = yup
  .object({
    name: yup.string().when('inheritChecklistName', (val) => {
      if (val === true) {
        return yup.string().notRequired();
      }
      return yup
        .string()
        .min(2, 'Nome deve ter no mínimo 2 caracteres')
        .required('Nome é obrigatório');
    }),
    description: yup.string(),
    origin_id: optionSchema.notRequired().nullable(),
    origin_type: optionSchema.notRequired().nullable(),
    formulary: optionSchema.when('associateFormulary', (value) => {
      if (value) {
        return optionSchema.required('Formulário é obrigatório');
      }
      return optionSchema.notRequired();
    }),
    member: optionSchema.required('Membro é obrigatório'),
    user_profile_id: yup.string().notRequired(),
    tags: yup.array().of(tagSchema),
    local: optionSchema,
    date_start: yup.date().when('postponedStart', (val) => {
      if (val) {
        return yup.date().required('Data de início é obrigatória');
      }
      return yup.date().notRequired();
    }),
    date_end: yup.date().notRequired(),
    active: yup.boolean().default(true),
    postponedStart: yup.boolean().default(false),
    inheritChecklistName: yup.boolean().default(false),
    associateFormulary: yup.boolean().default(false),
  })
  .required();

export type TaskSchema = yup.InferType<typeof taskSchema>;
