import { useMutation, useQueryClient } from 'react-query';
import {
  deleteAttachment,
  FetchAttachmentsParams,
  FetchAttachmentsPayload,
  getFetchAttachmentsQueryKey,
} from '../services';

export function useDeleteAttachmentMutation(params: FetchAttachmentsParams) {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteAttachment, {
    onSuccess(data, variables) {
      if (!data) return;
      const queryKey = getFetchAttachmentsQueryKey({
        itemId: params.itemId,
        questionId: params.questionId,
      });
      queryClient.setQueryData<FetchAttachmentsPayload | undefined>(
        queryKey,
        (old) => {
          if (!old) return undefined;
          return {
            items: old.items.filter((e) => e.id !== variables.id),
          };
        },
      );
    },
  });

  return mutation;
}
