import { useReport } from './hooks/use-context';

export function InitialInfos() {
  const { body } = useReport();

  return (
    <div>
      <h2
        style={{
          fontSize: '1.25rem',
          fontWeight: 'bold',
          lineHeight: '1.25',
          color: '#495163',
        }}
      >
        INFORMAÇÕES INICIAIS
      </h2>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '0.5rem',
          paddingTop: '0.5rem',
        }}
      >
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Data</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#1E40AF',
            }}
          >
            {body?.date?.answers[0]?.content || '-'}
          </p>
        </span>
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Hora</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#1E40AF',
            }}
          >
            {body?.hour?.answers[0]?.content || '-'}
          </p>
        </span>
        <span
          style={{
            gridColumn: 'span 2',
            display: 'flex',
            height: '2.5rem',
            width: 'auto',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>CNPJ/CPF</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#1E40AF',
            }}
          >
            {body?.cpf?.answers[0]?.content ||
              body?.cnpj?.answers[0]?.content ||
              '-'}
          </p>
        </span>
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Pedido de Compra</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#1E40AF',
            }}
          >
            {body?.purchase_order?.answers[0]?.content || '-'}
          </p>
        </span>
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Nota Fiscal</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#1E40AF',
            }}
          >
            {body?.note?.answers[0]?.content || '-'}
          </p>
        </span>
        <span
          style={{
            gridColumn: 'span 2',
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Observação de NF</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#1E40AF',
            }}
          >
            {body?.nf?.answers[0]?.content || '-'}
          </p>
        </span>
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Lote</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#1E40AF',
            }}
          >
            {body?.batch?.answers[0]?.content || '-'}
          </p>
        </span>
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Tipo</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#1E40AF',
            }}
          >
            {body?.type?.answers[0]?.option || '-'}
          </p>
        </span>
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Umidade</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#1E40AF',
            }}
          >
            {body?.moisture?.answers[0]?.content || '-'}
          </p>
        </span>
        <span
          style={{
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '0.25rem',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: '#495163',
          }}
        >
          <p>Qntd. Sacos</p>
          <p
            style={{
              marginRight: '0.25rem',
              marginTop: '-0.75rem',
              textAlign: 'end',
              fontSize: '1.125rem',
              fontWeight: 500,
              color: '#1E40AF',
            }}
          >
            {body?.bags_quantity?.answers[0]?.content || '-'}
          </p>
        </span>
      </div>
    </div>
  );
}
