import {
  ACTION_ITEM_HEIGHT,
  ITEM_MARGIN,
} from '@/components_v2/ContextMenu/constants';
import { MINIMUM_ITEMS_BREAKPOINT, MAX_LIST_HEIGHT } from '../data';

function getSelectedListHeight(selected: number, unselected: number): number {
  let height = 0;
  if (unselected < MINIMUM_ITEMS_BREAKPOINT && selected > 0) {
    height = MAX_LIST_HEIGHT - unselected * (ITEM_MARGIN + ACTION_ITEM_HEIGHT);
  } else {
    height =
      (ACTION_ITEM_HEIGHT + ITEM_MARGIN) *
      Math.min(selected, MINIMUM_ITEMS_BREAKPOINT);
  }
  return Math.min(height, MAX_LIST_HEIGHT);
}

export default getSelectedListHeight;
