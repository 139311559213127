import config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { EventWithItems } from '@/screens/registrations/Tasks/interfaces';

export async function getEvent(eventId: string): Promise<EventWithItems> {
  const { body } = await AxiosHelper.get<EventWithItems>({
    url: `${config.EVENTS_URL}/${eventId}`,
    apiV: 1,
  });
  return body;
}
