import styled from 'styled-components';

export const ScreenTitle = styled.h1`
  color: ${({ theme }) => theme.colors.darkText};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  opacity: 0.8;
  font-size: 22px;
  font-weight: 700;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
