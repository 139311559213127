/* eslint-disable no-nested-ternary */
import {
  BaseField,
  DisplayIf,
  Field,
  FormLayout,
  HookForm,
} from '@/components_v2/HookForm';
import { QuestionTypes, StepTypes } from '@/gql/graphql';
import { useModals } from '@/hooks/ModalManager';
import { useCurrentAutoFlow } from '@/screens/Tickets/hooks/useCurrentAutoFlow';
import { Button, chakra, Divider, Spinner, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  AutomationPropertySelect,
  FormularySelect,
  LocalSelect,
  QuestionSelect,
  UserSelect,
} from '../Selects';
import { makeStepFormSchema, StepSchema } from './form-schemas';
import { ActionTable, TriggerTable } from './inputs';
import { Callout } from './misc';
import {
  StepFormParams,
  StepFormProvider,
  useStepForm,
  useStepFormContext,
} from './use-step-form';

function Fields() {
  const form = useFormContext<StepSchema>();
  const { automationFlowId } = useStepFormContext();

  return (
    <FormLayout spacing={5} flex="1">
      <Field<StepSchema> name="name" label="Nome" type="text" />

      <Field<StepSchema>
        type="native-select"
        name="stepType"
        defaultValue={undefined}
        label="Tipo da etapa"
        options={[
          { label: '', value: '' },
          { label: 'Abertura', value: StepTypes.Opening },
          { label: 'Progresso', value: StepTypes.Progressing },
          { label: 'Finalização', value: StepTypes.Finishing },
          { label: 'Criação de ticket', value: StepTypes.CreateTicket },
          { label: 'Cancelamento', value: StepTypes.Canceling },
        ]}
      />

      <Field<StepSchema>
        type="switch"
        name="autoFinish"
        label="Finalizar etapa automaticamente no final do dia"
        size="lg"
        defaultValue=""
      />

      <FormLayout spacing={5} flex="1">
        <Callout>
          As ativações são as maneiras que uma etapa pode ser iniciada
        </Callout>

        <BaseField name="triggers" flexGrow={0}>
          <TriggerTable automationFlowId={automationFlowId} />
        </BaseField>
      </FormLayout>

      <Callout>Ações servem para ativar ações secundárias a etapa</Callout>

      <BaseField name="actions" flexGrow={0}>
        <ActionTable />
      </BaseField>

      <DisplayIf<StepSchema>
        name="stepType"
        condition={(value) =>
          !!value &&
          ![
            StepTypes.Canceling,
            StepTypes.Finishing,
            StepTypes.CreateTicket,
          ].includes(`${value}` as StepTypes)
        }
      >
        <FormLayout spacing={5} flex="1">
          <Callout>
            A pergunta que ativa a etapa vai fazer com que essa etapa seja
            iniciada quando a pergunta for respondida com uma das opções da
            pergunta. Você também pode adicionar atributos na tarefa a partir do
            checklist da pergunta que ativa a etapa.
          </Callout>
          {/* Checklist da etapa */}
          <Controller
            name="taskForm"
            control={form.control}
            shouldUnregister
            render={({ field }) => (
              <BaseField
                name="taskForm"
                label="Checklist da etapa"
                flexGrow={0}
              >
                <FormularySelect
                  selectionMode="single"
                  value={field.value?.map((e) => e!.id)}
                  initialOptions={(field.value || []) as any}
                  onChange={(keys) => {
                    setTimeout(() => {
                      field.onChange(keys?.length ? keys : undefined);
                    }, 1);
                  }}
                />
              </BaseField>
            )}
          />

          {/* Tipo do responsável */}
          <Field
            type="radio"
            name="assignType"
            label="Responsável pela etapa"
            size="lg"
            defaultValue=""
            options={[
              { value: 'none', label: 'Indefinido' },
              {
                value: 'member_task',
                label: 'Responsável pela abertura do ticket',
              },
              { value: 'user_id', label: 'Pessoa específica' },
              { value: 'member_question', label: 'Herdar usuário de pergunta' },
              {
                value: 'last_member_task',
                label: 'Herdar encarregado da tarefa anterior',
              },
            ]}
          />

          {/* Responsável */}
          <DisplayIf<StepSchema>
            name="assignType"
            condition={(value) => value === 'user_id'}
          >
            <Controller
              name="members"
              control={form.control}
              shouldUnregister
              render={({ field }) => (
                <BaseField
                  name="members"
                  label="Pessoa responável pela etapa"
                  flexGrow={0}
                >
                  <UserSelect
                    selectionMode="single"
                    value={field.value?.map((e) => e!.id)}
                    initialOptions={(field.value || []) as any}
                    onChange={(keys) => {
                      field.onChange(keys?.length ? keys : undefined);
                    }}
                  />
                </BaseField>
              )}
            />
          </DisplayIf>

          {/* Herdar de pergunta */}
          <DisplayIf<StepSchema>
            name="assignType"
            condition={(value) => value === 'member_question'}
          >
            <Controller
              name="memberQuestions"
              control={form.control}
              shouldUnregister
              render={({ field }) => (
                <BaseField
                  name="memberQuestions"
                  label="Herdar usuário da pergunta"
                  flexGrow={0}
                >
                  <QuestionSelect
                    selectionMode="single"
                    questionTypes={[QuestionTypes.User]}
                    value={field.value?.map((e) => e!.id)}
                    initialOptions={(field.value || []) as any}
                    onChange={(keys) => {
                      field.onChange(keys?.length ? keys : undefined);
                    }}
                  />
                </BaseField>
              )}
            />
          </DisplayIf>

          {/* Tipo de local */}
          <DisplayIf<StepSchema>
            name="taskForm"
            isExact
            condition={(value) => {
              return !!value;
            }}
          >
            <Field<StepSchema>
              type="radio"
              name="localType"
              defaultValue={undefined}
              label="Local do checklist"
              size="lg"
              options={[
                { label: 'Nenhum', value: 'none' },
                {
                  label: 'Herdar local da abertura do ticket',
                  value: 'local_opening_task',
                },
                {
                  label: 'Herdar local de um atributo do ticket',
                  value: 'local_ticket_property',
                },
                { label: 'Selecionar local específico', value: 'local_id' },
              ]}
            />
          </DisplayIf>

          {/* Local do checklist */}
          <DisplayIf<StepSchema>
            name="localType"
            isExact
            condition={(value) => {
              return value === 'local_id';
            }}
          >
            <>
              <Controller
                name="localSet"
                control={form.control}
                shouldUnregister
                render={({ field }) => (
                  <BaseField
                    name="localSet"
                    label="Local de execução do checklist"
                    flexGrow={0}
                  >
                    <LocalSelect
                      selectionMode="single"
                      value={field.value?.map((e) => e!.id)}
                      initialOptions={(field.value || []) as any}
                      onChange={(keys) => {
                        field.onChange(keys?.length ? keys : undefined);
                      }}
                    />
                  </BaseField>
                )}
              />
              <Divider />
            </>
          </DisplayIf>

          {/* Local a partir de atributo */}
          <DisplayIf<StepSchema>
            name="localType"
            condition={(value) => value === 'local_ticket_property'}
          >
            <Controller
              name="localTicketProperty"
              control={form.control}
              shouldUnregister
              render={({ field }) => (
                <BaseField
                  name="localTicketProperty"
                  label="Selecione local a partir de um atributo"
                  flexGrow={0}
                >
                  <AutomationPropertySelect
                    selectionMode="single"
                    automationFlowId={automationFlowId}
                    value={field.value?.map((e) => e!.id)}
                    initialOptions={(field.value || []) as any}
                    onChange={(keys) => {
                      field.onChange(keys?.length ? keys : undefined);
                    }}
                  />
                </BaseField>
              )}
            />
          </DisplayIf>
        </FormLayout>
      </DisplayIf>
    </FormLayout>
  );
}

export function CreateStepForm({
  automationFlowId,
  stepId,
  position,
}: StepFormParams) {
  const toast = useToast();

  const hook = useStepForm({
    automationFlowId,
    stepId,
    position,
  });
  const { defaultValue, loadingStepData, onSubmit, loading } = hook;

  if (loadingStepData)
    return (
      <chakra.div
        display="flex"
        h="container.sm"
        flex="1"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner />
      </chakra.div>
    );

  return (
    <StepFormProvider value={hook}>
      <chakra.fieldset flex="1" display="flex" disabled={loading}>
        <HookForm<StepSchema>
          autoComplete="off"
          position="relative"
          id="step-form"
          w="full"
          resolver={yupResolver(makeStepFormSchema())}
          schema={makeStepFormSchema()}
          onError={(error) => {
            const errorArray = Object.values(error);

            toast({
              title: 'Verifique o formulário',
              description: (
                <>
                  {errorArray
                    .map((error) => error.message)
                    .map((e) => (
                      <p>{e}</p>
                    ))}
                </>
              ),
              status: 'warning',
            });
          }}
          defaultValues={defaultValue}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          <chakra.div
            display="flex"
            justifyContent="space-between"
            flexDir="column"
            w="full"
            overflow="visible"
            maxH="100%"
            h="full"
          >
            <chakra.div maxW="100%" gap="6" p="6" flexDir="column">
              <Fields />
            </chakra.div>
          </chakra.div>
        </HookForm>
      </chakra.fieldset>
    </StepFormProvider>
  );
}

export function useCreateStepForm() {
  const modals = useModals();
  const { id } = useCurrentAutoFlow();

  const openForm = (params: Omit<StepFormParams, 'automationFlowId'>) => {
    modals.open({
      id: 'board-create-form',
      title: `Etapa`,
      size: '2xl',
      closeOnEsc: false,
      closeOnOverlayClick: false,

      footer: (
        <chakra.div h="auto" w="full" position="sticky">
          <Button
            type="submit"
            form="step-form"
            w="full"
            colorScheme="primary"
            size="lg"
          >
            Salvar
          </Button>
        </chakra.div>
      ),
      body: <CreateStepForm {...{ ...params, automationFlowId: id }} />,
    });
  };
  return openForm;
}
