import * as Yup from 'yup';

import { SignInParams } from '@shared/domain/interfaces';
import { FeedbackInfo } from '@components/Feedback';

export const validateWorkspace = async (workspace?: string): Promise<void> => {
  const workspaceSchema = Yup.object().shape({
    workspace: Yup.string().required('Workspace obrigatório'),
  });
  await workspaceSchema.validate({ workspace });
};
export const validateEmail = async (email?: string): Promise<void> => {
  const emailSchema = Yup.object().shape({
    email: Yup.string().required('E-mail obrigatório'),
  });
  await emailSchema.validate({ email });
};

export const validatePassword = async (password?: string): Promise<void> => {
  const passwordSchema = Yup.object().shape({
    password: Yup.string().required('Senha obrigatória'),
  });
  await passwordSchema.validate({ password });
};

export default async function validateSignIn(
  params: SignInParams,
): Promise<boolean> {
  try {
    const { email, password, workspace } = params;
    await validateWorkspace(workspace);
    await validateEmail(email);
    await validatePassword(password);
    return true;
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      FeedbackInfo({
        mainText: err.errors[0],
        subText: 'verifique o campo e tente novamente',
      });
    }
    return false;
  }
}
