import React, { useCallback, useEffect, useReducer, useState } from 'react';

import Tooltip from '@components/Tooltip';
import debounce from '@utils/debounce';
import { passwordReducer } from './utils';
import {
  QuestionContainer,
  QuestionHeader,
  QuestionTitle,
  QuestionSubtitle,
  Input,
  InfoTooltip,
  TooltipContainer,
} from '../common';
import { OpenIcon, CloseIcon } from './styles';
import { PasswordProps, PasswordData } from './interfaces';

const Password: React.FC<PasswordProps> = ({
  question,
  errors,
  onChange,
  initialValues,
  viewOnly,
}) => {
  const disabled = !!viewOnly;
  const [state, dispatch] = useReducer(passwordReducer, {
    confirm: initialValues?.values || '',
    password: initialValues?.values || '',
    isEquals: true,
    isValid: true,
  } as PasswordData);
  const [debouncedPassword] = useState(() =>
    debounce(async (password: string, isValid: boolean) => {
      dispatch({ type: 'hide-password' });

      if (isValid) {
        onChange(password);
      }
    }, 200),
  );

  useEffect(() => {
    debouncedPassword(state.password, state.isEquals && state.isValid);
  }, [debouncedPassword, state.password, state.isEquals, state.isValid]);

  const onChangeInput = useCallback(
    (type: 'set-password' | 'set-confirm', newPassword: string) => {
      dispatch({ type, newPassword });
    },
    [],
  );

  const onShowPassword = useCallback(
    (type: 'show-password' | 'show-confirm', showPassword: boolean) => {
      dispatch({ type, showPassword });
    },
    [],
  );

  const onPaste = useCallback(
    (type: 'paste-password' | 'paste-confirm', newPassword: string) => {
      dispatch({ type, newPassword });
    },
    [],
  );

  return (
    <>
      <QuestionContainer>
        <QuestionHeader>
          <QuestionTitle>Senha</QuestionTitle>
          {question.tipText && (
            <Tooltip description={question.tipText} placement="top right">
              <TooltipContainer>
                <InfoTooltip />
              </TooltipContainer>
            </Tooltip>
          )}
        </QuestionHeader>
        <Input
          disabled={disabled}
          type="text"
          value={state.showPassword ? state.password : state.hiddenPassword}
          onChange={(e) => onChangeInput('set-password', e.target.value)}
          hasError={!state.isValid || !!errors}
          warning={!state.isEquals}
          onPaste={(e) =>
            onPaste('paste-password', e.clipboardData.getData('text'))
          }
        >
          {state.showPassword ? (
            <CloseIcon onClick={() => onShowPassword('show-password', false)} />
          ) : (
            <OpenIcon onClick={() => onShowPassword('show-password', true)} />
          )}
        </Input>
      </QuestionContainer>
      <QuestionContainer>
        <QuestionHeader>
          <QuestionTitle>Confirmar Senha</QuestionTitle>
        </QuestionHeader>
        <Input
          disabled={disabled}
          type="text"
          value={state.showConfirm ? state.confirm : state.hiddenConfirm}
          onChange={(e) => onChangeInput('set-confirm', e.target.value)}
          hasError={!state.isValid || !!errors}
          warning={!state.isEquals}
          onPaste={(e) =>
            onPaste('paste-confirm', e.clipboardData.getData('text'))
          }
        >
          {state.showConfirm ? (
            <CloseIcon onClick={() => onShowPassword('show-confirm', false)} />
          ) : (
            <OpenIcon onClick={() => onShowPassword('show-confirm', true)} />
          )}
        </Input>
        <QuestionSubtitle hasError={!!errors}>
          {errors && errors.errors}
        </QuestionSubtitle>
      </QuestionContainer>
    </>
  );
};

export default Password;
