/* eslint-disable react/no-array-index-key */
import _ from 'lodash';

import { ItemContext, useItem, useReport } from './hooks/use-context';

function ItemHeader() {
  const { header } = useReport();

  const {
    form_name,
    collection_item,
    percent_score,
    score_obtained,
    total_score,
  } = useItem();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem',
        borderBottom: '1px solid #d1d5db',
        backgroundColor: '#ffffff',
        padding: '0.5rem',
        paddingBottom: '0.5rem',
      }}
    >
      <h1
        style={{
          fontWeight: 600,
        }}
      >
        Formulário: {form_name}
      </h1>
      {!!collection_item?.name && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '0.75rem',
            fontWeight: 400,
            color: '#4b5563',
          }}
        >
          <p>Item: {collection_item?.name}</p>
          <p>Coleção: {collection_item?.name}</p>
          <p>Item Code: {collection_item?.code}</p>
        </div>
      )}

      {!header?.hide_score && score_obtained > 0 && (
        <p
          style={{
            fontSize: '0.75rem',
            fontWeight: 400,
            color: '#4b5563',
          }}
        >
          Pontuação do Formulário: {score_obtained} / {total_score} -{' '}
          {percent_score}%
        </p>
      )}
    </div>
  );
}

export function FormularyItem({ children }: { children?: React.ReactNode }) {
  const item = useItem();
  return (
    <li
      style={{
        marginBottom: '1.5rem',
        overflow: 'hidden',
        backgroundColor: '#ffffff',
      }}
      key={item.form_name}
    >
      <ItemHeader />
      <div className="p-2">{children}</div>
    </li>
  );
}

export function ItemsList({ children }: { children?: React.ReactNode }) {
  const { body } = useReport();

  return (
    <ul className="w-full">
      {_.orderBy(body.items, 'position', 'asc').map((item, idx) => (
        <ItemContext value={item} key={`${item.form_name}-${idx}`}>
          <FormularyItem>{children}</FormularyItem>
        </ItemContext>
      ))}
    </ul>
  );
}
