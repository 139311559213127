import config from '@/Constants';
import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { useFeedbackCommentMutation } from '@/hooks/Feedback/useFeedbackComment';
import { useModals } from '@/hooks/ModalManager';
import { createService } from '@/screens/monitoring/EventReports/components/Question/QuestionFeedback';
import { Card } from '@/screens/Workflow/Dashboard/components/misc';
import fetchDiscussionTopic from '@/services/feedback/fetchDiscussionTopic';
import snakeToCamel from '@/utils/snakeToCamel';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  ModalBody,
  ModalFooter,
  Tag,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { FlaggedAnswer } from '../interfaces';

type CreateTopicParams = {
  question_id: string;
  item_id: string;
};

export const FormWrapper: React.FC<{
  handleSubmit: (content: string) => void;
  discussionTopic: DiscussionTopic;
}> = (props) => {
  const modals = useModals();
  const { handleSubmit, discussionTopic } = props;
  const { lastComment } = discussionTopic;
  const [content, setContent] = React.useState<string>('');

  const onSubmit = React.useCallback(async () => {
    if (!content.length) return;

    handleSubmit(content);
    setContent('');
  }, [content, handleSubmit]);

  const handleOnkeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    },
    [onSubmit],
  );

  return (
    <Flex flexDir="column" flex="1">
      <ModalBody>
        <Flex flexDir="column" w="full" color="mutedText">
          <Box me="2" flex="1">
            <Textarea
              placeholder="Responda aqui"
              resize="none"
              variant="unstyled"
              value={content}
              onKeyDown={handleOnkeyDown}
              onChange={(e) => setContent(e.target.value)}
            />
          </Box>
          <Box>
            <Flex flexDir="column" hidden={!lastComment}>
              <Text fontWeight="bold" color="darkText">
                Último comentário:
              </Text>
              <HStack>
                <Text fontWeight="bold" color="muted">
                  {lastComment?.authorName}
                </Text>
                <Text color="muted">
                  {moment(lastComment?.createdAt).format('DD/MM/YYYY HH:mm')}
                </Text>
              </HStack>
              <HStack>
                <Text maxH={20} overflowY="auto" color="muted">
                  {lastComment?.content}
                </Text>
              </HStack>
            </Flex>
          </Box>
        </Flex>
      </ModalBody>

      <ModalFooter>
        <Flex py="4" w="full" justify="flex-end">
          <ButtonGroup w="full" size="md">
            <Button onClick={modals.pop} variant="outline" flex="1">
              Cancelar
            </Button>
            <Button flex="1" onClick={() => onSubmit()} colorScheme="primary">
              Responder
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalFooter>
    </Flex>
  );
};

export function useAddCommentModal(refresh?: () => void) {
  const modals = useModals();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { createComment, isError } = useFeedbackCommentMutation();

  const addComment = React.useCallback(
    async (comment: string, discussionTopicId: string): Promise<void> => {
      await createComment({
        discussionTopicId,
        content: comment,
      });

      modals.pop();
      refresh?.();
      if (!isError) {
        toast({
          status: 'success',
          description: 'Resposta enviada com sucesso',
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [createComment, isError, modals, refresh, toast],
  );

  const useCreateTopic = () => {
    const mutation = useMutation(
      createService<CreateTopicParams, DiscussionTopic>(
        `${config.DISCUSSION_TOPICS_URL}`,
      ),
      {
        onSuccess(data) {
          const {
            body: { id },
          } = data;
          queryClient.setQueryData<DiscussionTopic>(
            `${config.DISCUSSION_TOPICS_URL}/${id}`,
            {
              ...data.body,
            },
          );
        },
      },
    );
    return mutation;
  };

  const { mutateAsync: mutateTopicAsync } = useCreateTopic();

  const getTopic = React.useCallback(
    async (data: FlaggedAnswer) => {
      if (!data?.discussion_topic.id) {
        const newTopic = await mutateTopicAsync({
          item_id: `${data.item_id}`,
          question_id: data.id,
        });
        if (newTopic.body.id) {
          refresh?.();
          return newTopic.body;
        }
      }
      const topic = await fetchDiscussionTopic(data.discussion_topic.id);
      return topic;
    },
    [mutateTopicAsync, refresh],
  );

  const openModal = React.useCallback(
    async (item: FlaggedAnswer, flagged?: boolean) => {
      const {
        comment,
        formulary_name,
        options,
        question_name,
        section_name,
      } = item;

      const topic: DiscussionTopic | null = await getTopic(item);

      modals.open({
        id: 'flow-event-form',
        title: 'Nova Justificativa',
        scope: 'alert',
        size: 'xl',
        hideCloseButton: true,
        closeOnEsc: false,
        body: (
          <Box w="full" overflow="auto">
            <Box p="4">
              <Card p="4" lineHeight="short">
                <Box mb="2">
                  <Flex w="full" justifyContent="space-between">
                    <Text
                      mb="2"
                      fontWeight="bold"
                      color="muted"
                      fontSize="sm"
                      noOfLines={1}
                    >
                      {`${formulary_name} / ${section_name}`}
                    </Text>
                  </Flex>
                  <Text fontSize="sm">{question_name}</Text>
                </Box>
                <VStack w="full" spacing="2" alignItems="flex-start">
                  {options.map(
                    (optionName) =>
                      optionName && (
                        <Tag
                          key={optionName}
                          size="lg"
                          variant="solid"
                          colorScheme={flagged ? 'orange' : 'gray'}
                        >
                          {optionName}
                        </Tag>
                      ),
                  )}
                </VStack>

                <Flex flexDir="column" gap="1" mt="4" hidden={!comment}>
                  <Text fontWeight="bold">Notas:</Text>
                  <HStack>
                    <Flex
                      p="2"
                      textAlign="start"
                      minH="10"
                      rounded="lg"
                      w="full"
                      bg="gray.100"
                      overflow="auto"
                    >
                      {comment}
                    </Flex>
                  </HStack>
                </Flex>
              </Card>
            </Box>
            {topic && (
              <FormWrapper
                handleSubmit={(content) => addComment(content, topic.id)}
                discussionTopic={snakeToCamel(topic)}
              />
            )}
          </Box>
        ),
      });
    },
    [addComment, getTopic, modals],
  );

  return { openModal };
}
