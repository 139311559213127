const handleApiError = (errors: string[]): string => {
  const FIRST: number = 0;
  if (!!errors && !!errors[FIRST]) {
    const errorMessage: string = errors[FIRST];
    const initAt: number = errorMessage.indexOf('[');
    const endAt: number = errorMessage.indexOf(']') + 1;
    const receivedErrorsString: string = errorMessage.slice(initAt, endAt);
    const receivedErrors: string[] = JSON.parse(receivedErrorsString);
    const formattedError: string = `formulary.errors.${receivedErrors[FIRST]}`;
    return formattedError;
  }
  return 'errors.unexpectedError';
};

export default handleApiError;
