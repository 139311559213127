import { Answer, FieldVisibilityMap, IFormState } from '../interfaces';
import { getComparisonHandler } from './getComparisonHandler';

type ParamType = {
  answer: Answer | Answer[] | undefined;
  currentFieldId: string;
};

export function calculateNextVisible(
  state: Omit<IFormState, 'edited'>,
  params: ParamType,
): Record<string, boolean> {
  const { currentFieldId, answer } = params;
  const { fields, dependencyMap, answers } = state;

  const childrenIds = dependencyMap[currentFieldId] || [];

  const childrenFields = Object.values(fields).filter((el) =>
    childrenIds.includes(el.id),
  );

  // para cada filho da pergunta acima
  const nextVisible = childrenFields.reduce((previousChild, currentChild) => {
    const comparisonHandler = getComparisonHandler(currentChild);
    const isNextVisible = comparisonHandler(answer);

    const currentChildAnswer = answers[currentChild.id];
    // checar se os filhos dessa pergunta filha estarão visíveis
    // se isNextVisible for falso, as demais filhas não poderão ser visíveis
    const inner = calculateNextVisible(state, {
      answer: isNextVisible ? currentChildAnswer : undefined,
      currentFieldId: currentChild.id,
    });

    return {
      ...previousChild,
      [currentChild.id]: isNextVisible,
      ...inner,
    };
  }, {} as FieldVisibilityMap);

  return { ...nextVisible };
}
