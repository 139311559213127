import { FeedbackError } from '@/components/Feedback';
import config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { Task } from '../interfaces';

async function deleteTaskEvent(task: PartialWithId<Task>): Promise<boolean> {
  if (!task.event_id) return true;
  const { statusCode } = await AxiosHelper.delete({
    url: `${config.EVENTS_URL}/${task.event_id}`,
  });
  if (statusCode < 200 || statusCode > 300) {
    FeedbackError({ mainText: 'Falha ao iniciar tarefa' });
    throw new Error('Falha ao iniciar tarefa');
  }

  return true;
}

export { deleteTaskEvent };
