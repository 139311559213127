import styled from 'styled-components';

export interface InputProps {
  hasError?: boolean;
  warning?: boolean;
  onFocusEvent?: boolean;
}

export const InputDiv = styled.div<InputProps>`
  width: 100%;
  outline: none;
  padding: 8px 10px;
  border: 0;
  background-color: ${({ theme }) => theme.colors.v2_secondary};
  border-radius: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme, hasError, warning }) => {
    if (warning) return `box-shadow: 0 0 0 1px ${theme.colors.warning};`;
    if (hasError) return `box-shadow: 0 0 0 1px ${theme.colors.error};`;
    return null;
  }}

  & > svg {
    display: none;
  }

  transition: 200ms;
  :hover {
    ${({ theme, hasError, warning }) => {
      if (warning) return `box-shadow: 0 0 0 1px ${theme.colors.warning};`;
      if (hasError) return `box-shadow: 0 0 0 1px ${theme.colors.error};`;
      return `box-shadow: 0 0 0 1px ${theme.colors.primary};`;
    }}
    background-color: ${({ theme }) => theme.colors.lighterText};
    & > svg {
      display: block;
    }
  }

  ${({ onFocusEvent }) => onFocusEvent && '& > svg { display: block; }'}

  ${({ theme, onFocusEvent, hasError, warning }) => {
    if (onFocusEvent) {
      if (warning)
        return `box-shadow: 0 0 0 1px ${theme.colors.warning}; background-color: ${theme.colors.lighterText};`;
      if (hasError)
        return `box-shadow: 0 0 0 1px ${theme.colors.error}; background-color: ${theme.colors.lighterText};`;
      return `box-shadow: 0 0 0 1px ${theme.colors.primary}; background-color: ${theme.colors.lighterText};`;
    }
    return null;
  }}
`;

export const Input = styled.input`
  width: 100%;
  outline: none;
  border: 0;
  padding: 0;
  background-color: transparent;

  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 12px;
  font-weight: 400;
`;

export const Div = styled.div`
  width: 100%;
  width: 100%;
  outline: none;
  border: 0;
  padding: 0;
  background-color: transparent;

  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 12px;
  font-weight: 400;
`;
