/* eslint-disable no-underscore-dangle */
import { AlertModalProps } from '@/components/AlertModalContent';
import { ConfirmModalProps } from '@/components/ConfirmModalContent';
import { ModalContextRefProps, OpenModalProps } from '@/hooks/interfaces/modal';

export default class AppModal {
  static _ref: ModalContextRefProps;

  static setRef(ref: ModalContextRefProps | null): void {
    if (ref) {
      AppModal._ref = ref;
    }
  }

  static getRef(): ModalContextRefProps {
    return AppModal._ref;
  }

  static openModal(props: OpenModalProps): void {
    AppModal._ref.openModal(props);
  }

  static closeModal(): void {
    AppModal._ref.closeModal();
  }

  static openConfirmationModal(props: ConfirmModalProps): void {
    AppModal._ref.openConfirmationModal(props);
  }

  static openAlertModal(props: AlertModalProps): void {
    AppModal._ref.openAlertModal(props);
  }
}
