import React from 'react';
import { TabList, Tab, Tabs } from '@chakra-ui/react';
import {
  TabsDefaultVariant,
  TabSelectorRenderProps,
} from './TabSelector.types';

function TabSelectorChakra<T extends string = string>({
  initialKey,
  onChange,
  tabs,
  variant,
  tabsComponentProps,
}: TabSelectorRenderProps<T> & { variant: TabsDefaultVariant }): JSX.Element {
  const [index, setIndex] = React.useState(() => {
    if (initialKey) return tabs.findIndex((tab) => tab.key === initialKey);
    return 0;
  });

  const handleChange = React.useCallback(
    (newIndex: number) => {
      setIndex(newIndex);
      onChange(tabs[newIndex].key, newIndex);
    },
    [onChange, tabs],
  );

  return (
    <Tabs
      variant={variant}
      index={index}
      onChange={handleChange}
      {...tabsComponentProps}
    >
      <TabList>
        {tabs.map((tab) => {
          return <Tab translate="no" isDisabled={tabs.length === 1}>{tab.label}</Tab>;
        })}
      </TabList>
    </Tabs>
  );
}

export default TabSelectorChakra;
