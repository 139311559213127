import { useFragment } from '@/gql';
import { ImportFragmentFragment, ImportStatus } from '@/gql/graphql';
import { useActionCable } from '@/hooks/actionCable';
import { BaseImportData } from '@/hooks/RemoteFilesListener/interfaces';
import { createContext } from '@/utils/react-utils';
import { useApolloClient } from '@apollo/client';
import React from 'react';

import { importFragment, ImportFragmentType } from './useImports';

export function useImportItemController({
  importItem,
}: {
  importItem: ImportFragmentType;
}) {
  const client = useApolloClient();
  const { subscribe } = useActionCable();
  const importItemFragment = useFragment(importFragment, importItem);

  const [state, setState] = React.useState<ImportFragmentFragment>(
    importItemFragment,
  );

  React.useEffect(() => {
    let sub = () => {
      //
    };

    if (
      [ImportStatus.Pending, ImportStatus.Processing].includes(
        importItemFragment.status!,
      )
    ) {
      sub = subscribe(
        'import',
        (values: BaseImportData) => {
          const nextData = {
            progress: values.progress,
            status: values.status,
          };
          setState(
            (old) =>
              ({
                ...old,
                ...nextData,
              } as ImportFragmentFragment),
          );

          client.cache.updateFragment(
            {
              id: importItemFragment.id,
              fragment: importFragment,
            },
            (data) => {
              return {
                ...data,
                ...nextData,
              } as ImportFragmentFragment;
              //
            },
          );
        },
        `${importItemFragment.token}`,
      );
    }

    return () => {
      sub();
    };
  }, [
    client.cache,
    importItemFragment.id,
    importItemFragment.status,
    importItemFragment.token,
    subscribe,
  ]);

  return {
    importItem: state,
  };
}

export const [ImportItemContext, useImportItem] = createContext<
  ReturnType<typeof useImportItemController>
>();

export function ImportItemProvider({
  children,
  importItem,
}: {
  children: React.ReactNode;
  importItem: ImportFragmentType;
}) {
  return (
    <ImportItemContext value={useImportItemController({ importItem })}>
      {children}
    </ImportItemContext>
  );
}
