/* eslint-disable consistent-return */
import styled, {
  css,
  FlattenInterpolation,
  ThemeProps,
  DefaultTheme,
} from 'styled-components';

export interface CollapsibleTriggerProps {
  isOpen: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  SelectedIcon?: React.FC<{ isSelected: boolean }>;
}

export interface CollapsibleWrapperProps {
  hasBorderBottom?: boolean;
  contentStyle?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
}

export const CollapsibleTrigger = styled.div<CollapsibleTriggerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.secondaryContent};
  padding: 10px 15px;

  border-bottom: 1px solid ${(props) => props.theme.colors.border};

  svg {
    size: 26px;
    color: ${(props) => props.theme.colors.darkerText};
    transition: transform 300ms;
    ${(props) => {
      if (props.isOpen) {
        return css`
          transform: rotateZ(90deg);
        `;
      }
    }}
  }
`;

export const CollapsibleWrapper = styled.div<CollapsibleWrapperProps>`
  .Collapsible {
    display: block;
    font-weight: 400;
    font-size: 16px;
    font-family: ${(props) => props.theme.fonts.primaryFont};
    text-decoration: none;
    color: ${(props) => props.theme.colors.darkText};
    position: relative;

    .Collapsible__contentInner {
      ${(props) => props.contentStyle};
    }

    .Collapsible__trigger {
      transition: font-size 200ms ease-in-out;
      &.is-open {
        color: ${(props) => props.theme.colors.primary};
        font-size: 17px;
      }
    }
  }
`;
