import config from '@/Constants';
import { HttpStatusCode } from '@/modules/shared/data/protocols/http';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import camelToSnake from '@/utils/camelToSnake';
import snakeToCamel from '@/utils/snakeToCamel';
import { DiscussionTopic } from '../interfaces';

export const mutationKey = config.DISCUSSION_TOPICS_URL;

type UpdateTopicPayload = Partial<DiscussionTopic> & {
  id: string;
};

export const updateTopic = async (
  payload: UpdateTopicPayload,
): Promise<DiscussionTopic> => {
  const { id, ...rest } = payload;
  const { statusCode, body } = await AxiosHelper.patch({
    url: `${mutationKey}/${id}`,
    body: camelToSnake(rest as any),
    timeout: 5000,
  });

  if (statusCode === HttpStatusCode.ok) {
    return snakeToCamel(body);
  }
  throw new UnexpectedError();
};
