import {
  useDeleteAttachmentMutation,
  useItemAttachmentMutation,
  useItemAttachmentsQuery,
} from '@/modules/attachments';
import { CreateAttachmentParams } from '@/modules/attachments/services';
import React from 'react';

import { UseQuestionAttachmentUploader } from './QuestionAttachments';

export function useDefaultAttachmentsHandler(params: {
  questionId: string;
  itemId: string;
  queryEnabled?: boolean;
}): ReturnType<UseQuestionAttachmentUploader> {
  const { itemId, questionId, queryEnabled } = params;

  const { data, isLoading } = useItemAttachmentsQuery(
    {
      itemId,
      questionId,
    },
    queryEnabled,
  );

  const deleteAttachmentMutation = useDeleteAttachmentMutation({
    itemId,
    questionId,
  });

  const createMutation = useItemAttachmentMutation();

  const onDelete = React.useCallback(
    (id: string) => {
      deleteAttachmentMutation.mutateAsync({ id });
    },
    [deleteAttachmentMutation],
  );

  const onCreate = React.useCallback(
    async (payload: Omit<CreateAttachmentParams, 'itemId' | 'questionId'>) => {
      createMutation.mutateAsync({ ...payload, itemId, questionId });
    },
    [createMutation, itemId, questionId],
  );

  return {
    items: data?.items || [],
    isLoading,
    onCreate,
    onDelete,
    onUploadStart: () => null,
  };
}
