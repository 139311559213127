import { Button } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import RRule from 'rrule';
import { useRecurrence } from '../../hooks/useRecurrence';

import { Container, WeekItemContainer } from './styles';

const options: { label: string; value: number }[] = [
  {
    label: 'date.weekDaysAbbrev.mon',
    value: RRule.MO.weekday,
  },
  {
    label: 'date.weekDaysAbbrev.tues',
    value: RRule.TU.weekday,
  },
  {
    label: 'date.weekDaysAbbrev.wed',
    value: RRule.WE.weekday,
  },
  {
    label: 'date.weekDaysAbbrev.thurs',
    value: RRule.TH.weekday,
  },
  {
    label: 'date.weekDaysAbbrev.fri',
    value: RRule.FR.weekday,
  },
  {
    label: 'date.weekDaysAbbrev.sat',
    value: RRule.SA.weekday,
  },
  {
    label: 'date.weekDaysAbbrev.sun',
    value: RRule.SU.weekday,
  },
];

const WeekdayPicker: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { state, onChangeWeekdays, viewOnly } = useRecurrence();

  const renderList = useCallback(() => {
    const collection = state.recurrenceRule?.byWeekday || [];
    return options.map((el) => {
      const isSlected = collection.indexOf(el.value) !== -1;
      return (
        <WeekItemContainer key={el.value}>
          <Button
            onMouseDown={() =>
              viewOnly ? undefined : onChangeWeekdays(el.value)
            }
            isActive={isSlected}
            type="button"
            _active={{ bg: colors.primary }}
            w="full"
          >
            {t(el.label)}
          </Button>
        </WeekItemContainer>
      );
    });
  }, [
    colors.primary,
    onChangeWeekdays,
    state.recurrenceRule?.byWeekday,
    t,
    viewOnly,
  ]);

  return <Container>{renderList()}</Container>;
};

export default WeekdayPicker;
