import { UserSettingsReducer } from './interfaces';

const key = '@TrackingTrade.WeCheck:settings';

export const userSettingsReducer: UserSettingsReducer = (state, action) => {
  switch (action.type) {
    case 'fetch_local':
      const settings = localStorage.getItem(key);
      if (settings) {
        const parsed = JSON.parse(settings);
        return parsed;
      }
      return state;
    case 'set':
      const newData = { ...state, ...action.props };
      localStorage.setItem(key, JSON.stringify(newData));
      return newData;
    default:
      return state;
  }
};
