/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
import {
  ReducedAnswer,
  ActionPlanDTO,
  FormularyAnswers,
} from '@/modules/core/domain/interfaces/IFormulary';

function getParsedFormValues(formAnswer: ReducedAnswer | ActionPlanDTO): any {
  let output: any = null;
  if (!formAnswer.questionType) return null;

  switch (formAnswer.questionType) {
    case 'password':
      output = {
        password: formAnswer.values,
        password_confirmation: formAnswer.values,
      };
      break;
    case 'nested_form':
      if (!Array.isArray(formAnswer.values)) return null;

      const newListAnswers = formAnswer.values.reduce(
        (acc, answer, currentPosition) => {
          const isEditingField: boolean = !!answer.id;
          if (isEditingField) {
            const newContent: string = answer.content;
            const newName: string = answer.name;
            const newDestroyState: boolean = answer._destroy;
            const updatedList = [
              ...acc,
              {
                id: answer.id,
                content: newContent,
                name: newName,
                _destroy: newDestroyState,
              },
            ];
            return updatedList;
          }
          const sameAnswer = formAnswer.values[currentPosition];
          return [...acc, sameAnswer];
        },
        [],
      );
      output = newListAnswers;
      break;
    default:
      output = formAnswer.values;
      break;
  }

  return output;
}

const reduceAnswers = (answers: FormularyAnswers): any => {
  let output: any = {};

  output = Object.entries(answers).reduce((acc, [key, val]) => {
    if (!val.questionType) {
      return { ...acc };
    }
    return {
      ...acc,
      [key]: getParsedFormValues(val),
    };
  }, {});
  return output;
};

export default { reduceAnswers };
