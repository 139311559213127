import config from '@/Constants';
import { createContext } from '@/utils/react-utils';
import React from 'react';
import { useQuery } from 'react-query';

import { ItemWithSections } from '../interfaces';
import { useInteractions } from './useInteractions';

export type EventItemShowContext = {
  item: ItemWithSections | undefined;
  refetch: () => void;
  isLoading?: boolean;
};

const [Context, useEventItemShow] = createContext<EventItemShowContext>({
  name: 'EventItemShowContext',
});

const EventItemShowProvider: React.FC = ({ children }) => {
  const { selectedItemId } = useInteractions();

  const { data, isLoading, refetch } = useQuery<ItemWithSections | undefined>(
    `${config.ANSWERS_URL}?item_id=${selectedItemId}`,
    {
      enabled: !!selectedItemId,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Context
      value={{
        refetch,
        item: data,
        isLoading,
      }}
    >
      {children}
    </Context>
  );
};

export { useEventItemShow, EventItemShowProvider };
