import Button from '@/components_v2/Buttons/Button';
import React from 'react';
import Popup from 'reactjs-popup';

import { PopupButtonProps } from './interfaces';

const PopupButton: React.FC<PopupButtonProps> = ({
  children,
  popupProps,
  customTrigger,
  ...props
}) => {
  return (
    <Popup
      nested
      trigger={
        <div style={{ margin: 'auto' }}>
          {customTrigger || <Button {...props} />}
        </div>
      }
      position={[
        'right top',
        'left top',
        'bottom center',
        'right center',
        'left center',
      ]}
      closeOnDocumentClick
      arrow={false}
      on={['click']}
      {...popupProps}
    >
      {children}
    </Popup>
  );
};

export default PopupButton;
