import React from 'react';
import config from '@/Constants';
import { useQuery } from 'react-query';
import { orderBy } from 'lodash';

export type QuestionActionItem = {
  flowId: string;
  eventId: string;
  title: string;
  createdAt: Date;
  step: {
    name: string;
    color: string;
  };
};

export function useQuestionActions(questionId: string, eventId: string) {
  const { data, isLoading, refetch } = useQuery<QuestionActionItem>(
    `${config.EVENTS_URL}/${eventId}/flows/${questionId}`,
  );

  const questionActions = React.useMemo(
    () => orderBy(data || [], 'createdAt', 'desc'),
    [data],
  );

  return { questionActions, isLoading, refetch };
}
