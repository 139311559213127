import React from 'react';
import useField from '@/modules/WebForm/hooks/useField';
import { Textarea } from '@chakra-ui/react';
import { createAnswer } from '@/modules/WebForm/utils';
import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import debounce from '@/utils/debounce';
import FormControlComponent from '../FormControlComponent';

const TextAreaContent: React.FC<QuestionModel> = () => {
  const { onChange, fieldState, error } = useField();
  const debouncedOnChange = React.useMemo(() => debounce(onChange, 300), [
    onChange,
  ]);

  const [value, setValue] = React.useState(() => {
    if (fieldState && !Array.isArray(fieldState)) {
      return fieldState.content;
    }
    return '';
  });

  const handleChange = React.useCallback(
    (newValue: string) => {
      setValue(newValue);
      debouncedOnChange(createAnswer(newValue));
    },
    [debouncedOnChange],
  );

  return (
    <FormControlComponent isInvalid={error}>
      <Textarea
        w="full"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      />
    </FormControlComponent>
  );
};

export default TextAreaContent;
