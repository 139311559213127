import config from '@/Constants';
import app from '@shared/adapters/FirebaseApp';
import CloudStorage from '@shared/data/usecases/CloudStorage';
import FirebaseStorageManager from '@shared/infra/protocols/cloudstorage/FirebaseStorageManager';

const cloudStorage = new CloudStorage(new FirebaseStorageManager(app));
const collectionStorage = new CloudStorage(
  new FirebaseStorageManager(app, config.COLLECTION_STORAGE),
);

const formularyStorage = new CloudStorage(
  new FirebaseStorageManager(app, config.QUESTION_IMAGES_STORAGE),
);

const workflowStorage = new CloudStorage(
  new FirebaseStorageManager(app, config.QUESTION_IMAGES_STORAGE),
);

const attachmentsStorage = new CloudStorage(
  new FirebaseStorageManager(app, config.ITEM_ATTACHMENTS_STORAGE),
);

export default cloudStorage;
export {
  collectionStorage,
  formularyStorage,
  workflowStorage,
  attachmentsStorage,
};
