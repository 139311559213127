import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Button';
import { Container } from './styles';
import { FooterProps } from './interfaces';

const Footer: React.FC<FooterProps> = ({
  status,
  buttonLabel,
  isLoading,
  onUpload,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Button
        text={t('buttons.cancel')}
        callback={() => onCancel()}
        type="GREY_BACKGOUND"
        styleTitle={{ fontSize: '12px' }}
        style={{ marginRight: 5 }}
      />
      <Button
        text={buttonLabel}
        callback={() => onUpload()}
        type="PRIMARY_BACKGROUND"
        styleTitle={{ fontSize: '12px' }}
        style={{ width: (buttonLabel?.length || 12.3) * 9.3 }}
        isLoading={isLoading}
        disabled={!(status === 'UPLOAD' || status === 'ERROR')}
      />
    </Container>
  );
};

export default Footer;
