import { useModalStack } from '@/hooks/modalStack/modal';
import { actionSheetNotificationsConfig } from '@/screens/utils/AppLayout/components/Navbar/components/Notifications';
import { FeedbackError, FeedbackInfo } from '@components/Feedback';
import { useActionSheet } from '@hooks/actionSheet';
import { useLoading } from '@hooks/loading';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiData, ImportFileProps, StatusEnum } from './interfaces';
import Main from './Main';

const ImportFile: React.FC<ImportFileProps> = ({
  apiServices,
  importId,
  extraParams,
}) => {
  const [status, setStatus] = useState<StatusEnum>();
  const [apiData, setApiData] = useState<ApiData>();
  const [fileDownloadUrl, setFileDownloadUrl] = useState<string>();
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const { openActionSheet } = useActionSheet();
  const { t } = useTranslation();
  const { pop } = useModalStack();
  const { isLoading, addLoading, removeLoading } = useLoading();

  const fetchData = useCallback(
    async (importId?: string) => {
      const loadingKey = 'fetch-data';
      addLoading(loadingKey);
      const getTemplate = async () => {
        if (importId) {
          const resp = await apiServices.getTemplate({
            optionsUrl: `generate_errors?import_id=${importId}`,
          });
          return resp;
        }
        const resp = await apiServices.getTemplate();
        return resp;
      };
      const resp = await getTemplate();
      if (resp?.type) {
        setApiData(resp);
        setStatus(resp.type);
      }
      removeLoading(loadingKey);
    },
    [apiServices, addLoading, removeLoading],
  );

  useEffect(() => {
    fetchData(importId);
  }, [importId, fetchData]);

  const onChangeStatus = useCallback((newStatus: StatusEnum) => {
    setStatus(newStatus);
  }, []);

  const onCancel = useCallback(() => {
    pop();
  }, [pop]);

  const openFileList = useCallback(() => {
    openActionSheet(actionSheetNotificationsConfig);
  }, [openActionSheet]);

  const uploadFile = useCallback(async () => {
    if (!fileDownloadUrl) return;
    setUploadLoading(true);
    const resp = await apiServices.create({
      file_temporary_url: fileDownloadUrl,
      ...(extraParams ? { extra_params: extraParams } : {}),
    });
    if (resp.statusCode >= 200 && resp.statusCode < 300) {
      FeedbackInfo({
        mainText: t(`file.sent`),
        subText: t(`file.sentMessage`),
        onClick: openFileList,
      });
      pop();
    } else {
      FeedbackError({
        mainText: t(`common.error`),
        subText: t(`error.common`),
      });
    }
    setUploadLoading(false);
  }, [fileDownloadUrl, apiServices, t, pop, openFileList, extraParams]);

  const onUpload = useCallback(async () => {
    switch (status) {
      case 'ERROR':
        fetchData();
        break;
      case 'UPLOAD':
        uploadFile();
        break;
      default:
    }
  }, [status, fetchData, uploadFile]);

  if (isLoading) return null;
  return (
    <Main
      status={status}
      apiData={apiData}
      uploadLoading={uploadLoading}
      onUpload={onUpload}
      onCancel={onCancel}
      onChangeStatus={onChangeStatus}
      setFileDownloadUrl={setFileDownloadUrl}
    />
  );
};

export default ImportFile;
