import React from 'react';
import { LoadingProps } from './Loading.types';
import TopRightLoading from './TopRightLoading';
import CenterLoading from './CenterLoading';

const Loading: React.FC<LoadingProps> = ({ position = 'center' }) => {
  switch (position) {
    case 'top-right':
      return <TopRightLoading />;
    case 'center':
    default:
      return <CenterLoading />;
  }
};

export default Loading;
