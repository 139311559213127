import config from '@/Constants';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import camelToSnake from '@/utils/camelToSnake';
import snakeToCamel from '@/utils/snakeToCamel';
import { DiscussionTopic, FeedbackType } from '../interfaces';

export type CreateFeedbackParams = {
  discussionTopicId: string;
  vote: FeedbackType;
};

export const mutationKey = config.DISCUSSION_LIKES_URL;

export const createFeedback = async (
  params: CreateFeedbackParams,
): Promise<DiscussionTopic> => {
  const response = await AxiosHelper.post({
    url: mutationKey,
    body: camelToSnake(params),
  });

  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new UnexpectedError();
  }

  const body: DiscussionTopic = snakeToCamel(response?.body);

  return body;
};

export function getFeedbackCount(
  topic: DiscussionTopic,
  current: FeedbackType,
): { likes: number; dislikes: number } {
  let likes = topic.countLikes || 0;
  let dislikes = topic.countDislikes || 0;
  const previous = topic.voteCurrentUser;
  if (previous === 'like' && current !== previous) {
    likes--;
  }
  if (previous === 'dislike' && current !== previous) {
    dislikes--;
  }
  if (current === 'like' && current !== previous) {
    likes++;
  }
  if (current === 'dislike' && current !== previous) {
    dislikes++;
  }
  return { likes, dislikes };
}
