import React from 'react';
import { useFlowSelectModal } from '@/components_v2/FlowsList/FlowsList';
import { useFlowEventForm } from '@/screens/Workflow/hooks/useFlowEventForm';

export function useCreateAction() {
  const { open } = useFlowSelectModal();
  const { openModal: openFlowEventForm } = useFlowEventForm();

  const create = React.useCallback(
    (params: { questionId: string; eventId: string }, cb?: () => void) => {
      const onSelect = (id: string) => {
        const { eventId, questionId } = params;
        if (!eventId || !questionId) return;
        setTimeout(() => {
          openFlowEventForm(
            id,
            {
              eventableId: questionId,
              eventableType: 'Question',
              externalId: eventId,
            },
            cb,
          );
        }, 50);
      };
      open({
        onSelect,
      });
    },
    [open, openFlowEventForm],
  );

  return create;
}
