import { HStack, Text } from '@chakra-ui/react';
import React from 'react';

export type SectionTitleProps = {
  rightButton?: JSX.Element;
};

const SectionTitle: React.FC<SectionTitleProps> = ({
  children,
  rightButton,
}) => {
  return (
    <HStack w="full" ps="2" justify="space-between">
      {!!children && typeof children === 'string' && (
        <Text color="muted" fontSize="lg" fontWeight="semibold">
          {children}
        </Text>
      )}

      {!!children && typeof children !== 'string' && <>{children}</>}
      {!!rightButton && rightButton}
    </HStack>
  );
};

export default SectionTitle;
