import {
  CIRCULABI_SIGNOFF_QUESTION_TYPE,
  CirculabiSignoffResult,
} from '@/modules/circulabi';
import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import { Badge } from '@chakra-ui/react';
import React from 'react';

import Checkbox from './Checkbox';
import CheckboxCombo from './CheckboxCombo';
import Chronometer from './Chronometer';
import Collections from './Collections';
import { CollectionsCombo } from './CollectionsCombo';
import Currency from './Currency';
import Date from './Date';
import DateTime from './DateTime';
import Geolocation from './Geolocation';
import Image from './Image';
import Number from './Number';
import Radio from './Radio';
import RadioCombo from './RadioCombo';
import SignatureContent from './Signature';
import Text from './Text';
import TextArea from './TextArea';
import Time from './Time';
import User from './User';

const QuestionContent: React.FC<QuestionModel> = (question) => {
  switch (question.questionType) {
    case 'collections':
      return <Collections {...question} />;
    case 'money':
      return <Currency {...question} />;
    case 'time_picker':
    case 'time':
    case 'hour':
      return <Time {...question} />;
    case 'date':
      return <Date {...question} />;
    case 'datetime':
      return <DateTime {...question} />;
    case 'integer':
      return <Number {...question} isInteger />;
    case 'geolocation':
      return <Geolocation {...question} />;
    case 'gallery':
    case 'picture':
      return <Image {...question} />;
    case 'decimal':
      return <Number {...question} />;
    case 'number':
      return <Number {...question} isInteger />;
    case 'textarea':
      return <TextArea {...question} />;
    case 'text':
      return <Text {...question} />;
    case 'paragraph':
      return <Text {...question} isParagraph />;
    case 'radio_combo':
      return <RadioCombo {...question} />;
    case 'chronometer':
      return <Chronometer {...question} />;
    case 'radio':
    case 'date_sla':
      return <Radio {...question} />;
    case 'checkbox_combo':
      return <CheckboxCombo {...question} />;
    case 'checkbox':
      return <Checkbox {...question} />;
    case 'signature':
      return <SignatureContent {...question} />;
    case 'barcode':
      return (
        <Badge colorScheme="orange">
          Este campo só está disponível no App Mobile
        </Badge>
      );
    case 'collections_combo':
      return <CollectionsCombo {...question} />;
    case 'user':
      return <User {...question} />;
    case CIRCULABI_SIGNOFF_QUESTION_TYPE as any:
      return <CirculabiSignoffResult question={question} />;
    default:
      return <></>;
  }
};

export default QuestionContent;
