/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useReducer,
} from 'react';

import {
  LoadingContextData,
  LoadingReducer,
  LoadingState,
} from './interfaces/loading';

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData,
);

const initialState: LoadingState = { stack: [] };

const reducer: LoadingReducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return { stack: [...state.stack, action.key] };
    case 'remove':
      const index = state.stack.findIndex((item) => item === action.key);
      if (index >= 0) {
        return {
          stack: [
            ...state.stack.slice(0, index),
            ...state.stack.slice(index + 1),
          ],
        };
      }
      return { stack: state.stack };
    default:
      return state;
  }
};

export const LoadingProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [loadingStack, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (loadingStack.stack.length > 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loadingStack.stack]);

  const addLoading = useCallback((key: string) => {
    dispatch({ type: 'add', key });
  }, []);

  const removeLoading = useCallback((key: string) => {
    dispatch({ type: 'remove', key });
  }, []);

  return (
    <LoadingContext.Provider
      value={{
        addLoading,
        removeLoading,
        isLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading must be used whithin an LoadingProvider');
  }

  return context;
}
