import config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';

import { Task } from '../interfaces';

export async function getTask(taskId: string): Promise<Task> {
  const url = `${config.TASKS_URL}/${taskId}`;
  const response = await AxiosHelper.get({ url });
  return response.body;
}
