/* eslint-disable @typescript-eslint/no-empty-function */

import config, { DOMAIN_PLACEHOLDER } from '@/Constants';
import { getSubdomain } from '@/utils/getSubdomain';
import { FeedbackInfo } from '@components/Feedback';
import {
  CreateRemoteAuthentication,
  GetDeviceID,
  LocalAuthentication,
  ManageToken,
} from '@security/adapters';
import { ManageInterceptors, Socket } from '@shared/adapters';
import { SignInParams, UserModel } from '@shared/domain/interfaces';
import AxiosHelper from '@shared/infra/protocols/http/AxiosHelper';
import checkToken from '@utils/tokenValidator';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AuthContextData, AuthState, LoginDataModel } from './interfaces/auth';
import { fetchUser } from './services/fetchUser';

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>({} as AuthState);
  const [loginData, setLoginData] = useState<LoginDataModel>(
    {} as LoginDataModel,
  );
  const [loading, setLoading] = useState(true);
  const [interceptorId, setInterceptorId] = useState(-1);
  const [subdomain, setsubdomain] = useState(getSubdomain());
  const { replace } = useHistory();
  const { pathname, state } = useLocation();
  const { user } = data;
  const userId = user ? user.id : undefined;

  const handleError = (error: Error) => {
    FeedbackInfo({
      mainText: 'Atenção!',
      subText: error.message,
    });
  };

  const signOut = useCallback(async () => {
    replace('/auth');
    setLoginData({} as LoginDataModel);
    LocalAuthentication.clear();
    ManageToken.clear();
    AxiosHelper.setDomain(DOMAIN_PLACEHOLDER);
    setData({} as AuthState);
    Socket.disconnect();
    if (interceptorId > 0) ManageInterceptors.remove(interceptorId);
  }, [interceptorId, replace]);

  const checkWebAccess = useCallback(
    (updatedUser: UserModel) => {
      if (updatedUser && !updatedUser.web) {
        signOut();
        FeedbackInfo({
          mainText: 'Atenção!',
          subText: 'Você foi desconectado. Efetue login novamente.',
        });
        return false;
      }
      return true;
    },
    [signOut],
  );

  const updateUser = useCallback(
    (user: UserModel) => {
      if (!checkWebAccess(user)) return;
      setData((previous) => {
        LocalAuthentication.save({ ...previous, user });
        return { ...previous, user };
      });
    },
    [checkWebAccess],
  );

  const handlePreviousLoginData = (params: LoginDataModel) => {
    setLoginData(params);
  };

  const authSuccess = useCallback(
    ({ token, user, workspace }: AuthState) => {
      setData({ token, user, workspace });
      LocalAuthentication.save({ token, user, workspace });
      ManageToken.set(token);
      const id = ManageInterceptors.create((response) => {
        if (response.status === 401) {
          signOut();
        }
        return response;
      });
      setInterceptorId(id);
      if (subdomain.length === 0) {
        setsubdomain(workspace);
      }
      Socket.connect(
        config.WEBSOCKET_URL.replace(DOMAIN_PLACEHOLDER, workspace),
        token,
      );
      if (pathname.startsWith('/auth')) {
        replace('/app', state);
      }
    },
    [signOut, replace, pathname, subdomain],
  );

  const signIn = useCallback(
    async (params: SignInParams) => {
      setLoading(true);
      const { workspace } = params;
      AxiosHelper.setDomain(workspace);
      const remoteAuthentication = CreateRemoteAuthentication();
      try {
        const {
          auth_token: token,
          user_infos: user,
        } = await remoteAuthentication.auth({
          uuid: GetDeviceID.get(),
          origin: 'web',
          ...params,
        });
        authSuccess({ token, user, workspace });
      } catch (error) {
        if (error instanceof Error) {
          handleError(error);
        }
      }
      setLoading(false);
    },
    [authSuccess],
  );

  const fetchUpdates = useCallback(
    async (id: string) => {
      const updatedUser = await fetchUser(id);
      if (!checkWebAccess(updatedUser)) return;
      setData((previous) => ({
        ...previous,
        user: { ...previous.user, ...updatedUser },
      }));
    },
    [checkWebAccess],
  );

  useEffect(() => {
    if (userId && !loading) {
      fetchUpdates(userId);
    }
  }, [userId, fetchUpdates, loading]);

  useEffect(() => {
    async function loadStoredData(): Promise<void> {
      const localData = LocalAuthentication.load();

      if (localData) {
        const { token, user, workspace } = localData;
        if (checkToken(token)) {
          authSuccess({ token, user, workspace });
          AxiosHelper.setDomain(workspace);
        }
      }
      setLoading(false);
    }
    if (!data.token) {
      loadStoredData();
    } else {
      setLoading(false);
    }
  }, [authSuccess, data.token, signOut]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        updateUser,
        signIn,
        signOut,
        loading,
        token: data.token,
        previousLoginData: loginData,
        handlePreviousLoginData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used whithin an AuthProvider');
  }

  return context;
}
