import { gql } from '@/gql';
import { useMutation } from '@apollo/client';
import React from 'react';

import { queryAutomationFlow } from './useFetchFlow';

const mutationUpdateAutomationStep = gql(`#graphql
  mutation UpdateAutomationStep($input: StepsUpdateInput!) {
    updateAutomationStep(input: $input) {
      automationStep {
        id
        name
        position
      }
      errors
    }
  }
`);

export function useUpdateAutomationStep() {
  const [updateAutoStep, { data, loading, error }] = useMutation(
    mutationUpdateAutomationStep,
    {
      refetchQueries: [queryAutomationFlow],
      awaitRefetchQueries: true,
    },
  );

  return {
    updateAutoStep,
    loading,
    error,
    data: data?.updateAutomationStep || null,
  };
}

export function useReorderStep() {
  const { updateAutoStep, ...rest } = useUpdateAutomationStep();
  const reorder = ({
    id,
    nextPosition,
  }: {
    id: string;
    nextPosition: number;
  }) => {
    updateAutoStep({
      variables: {
        input: {
          id,
          position: nextPosition,
        },
      },
    });
  };

  return {
    reorder,
    ...rest,
  };
}
