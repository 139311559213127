import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import { fetchUsers } from '@/screens/registrations/Tasks/Dashboard/components/TaskForm/utils/fetchers';
import { Flex } from '@chakra-ui/react';
import { AsyncSelect } from 'chakra-react-select';
import React from 'react';

import FormControlComponent from '../FormControlComponent';

type UserAnswer = {
  id: string;
  name: string;
};

type UserSelect = {
  value: string;
  label: string;
};

const UserContent: React.FC<QuestionModel> = () => {
  const { error, onChange, fieldState } = useField();

  const parsedState: UserSelect | undefined = React.useMemo(() => {
    if (fieldState && !Array.isArray(fieldState) && fieldState.content) {
      const content: UserAnswer = JSON.parse(fieldState?.content);
      return { value: content.id, label: content.name };
    }
    return undefined;
  }, [fieldState]);

  const handleChange = React.useCallback(
    (newValue: UserSelect) => {
      const nextValue = newValue
        ? { id: newValue.value, name: newValue.label }
        : undefined;
      onChange(createAnswer(nextValue ? JSON.stringify(nextValue) : null));
    },
    [onChange],
  );

  return (
    <Flex w="full" flexDir="column">
      <FormControlComponent isInvalid={error}>
        <AsyncSelect
          cacheOptions
          isClearable
          defaultOptions
          value={parsedState}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 1600 }),
          }}
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              flex: 1,
              maxWidth: '300px',
            }),
          }}
          isMulti={false}
          tagVariant="solid"
          loadOptions={fetchUsers}
          onChange={handleChange}
        />
      </FormControlComponent>
    </Flex>
  );
};

export default UserContent;
