import React from 'react';
import { Flex } from '@chakra-ui/react';

import { AutoForm } from '@/components_v2/HookForm';

import { registerFields } from './inputs';
import { getFormSchema } from './utils';
import { WorkflowAttribute } from '../../types/Attribute';
import { IFormSchema } from './types';

type EventFormProps = {
  attributes: WorkflowAttribute[];
  formulary?: {
    formularyId: string;
    formularyName: string;
  };
  onSubmit: (params: {
    payload: Record<string, any>;
    schema: IFormSchema;
  }) => void;
};

registerFields();

export const EventForm: React.FC<EventFormProps> = (props) => {
  const { attributes, onSubmit, formulary } = props;
  const formSchema = React.useRef(getFormSchema({ attributes, formulary }))
    .current;

  const handleSubmit = React.useCallback(
    (payload: Record<string, any>) => {
      onSubmit({
        payload,
        schema: formSchema,
      });
    },
    [formSchema, onSubmit],
  );

  return (
    <Flex flexDir="column" flex="1">
      <AutoForm
        id="event-form"
        schema={formSchema}
        onSubmit={handleSubmit}
        submitLabel={null}
        w="full"
      />
    </Flex>
  );
};
