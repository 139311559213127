import React, { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import config, { VALIDATION_CODE_PLACEHOLDER } from '@/Constants';
import { useParams } from 'react-router-dom';
import { useNavigation } from '@hooks/navigation';
import { FeedbackInfo } from '@/components/Feedback';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { HttpStatusCode } from '@/modules/shared/data/protocols/http';
import { useWorkspace } from '@/hooks/workspace';
import Loading from '@/components/Loading';
import { ReceivedParams } from './localGeneric';
import InvalidTokenInfo from './InvalidTokenInfo';
import {
  changePasswordReducer,
  InitialState,
} from './utils/changePasswordReducer';
import Main from './ChangePassword';

const ChangePassword: React.FC = () => {
  const [state, dispatch] = useReducer(changePasswordReducer, InitialState);
  const {
    isLoadingData,
    workspace,
    email,
    isSubmiting,
    isTokenValid,
    password,
    passwordConfirmation,
  } = state;
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const { token } = useParams<ReceivedParams>();
  const { setSubdomain, workspaceData } = useWorkspace();

  const handlePasswordChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const currentPassword: string = event.currentTarget.value;
      dispatch({ type: 'onchange_password', payload: currentPassword });
    },
    [],
  );

  const handlePasswordConfirmationChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const currentPasswordConfirmation: string = event.currentTarget.value;
      dispatch({
        type: 'onchange_password_confirmation',
        payload: currentPasswordConfirmation,
      });
    },
    [],
  );

  const checkEquality = useCallback(() => {
    const passwordsMatch: boolean = password === passwordConfirmation;
    if (!passwordsMatch) {
      FeedbackInfo({
        mainText: t('error.warning'),
        subText: t('error.unmatchPasswords'),
      });
      return false;
    }
    return true;
  }, [password, passwordConfirmation, t]);

  const handleProccessState = useCallback(
    (statusCode: HttpStatusCode) => {
      if (statusCode === HttpStatusCode.ok) {
        if (!workspaceData.subdomain) setSubdomain(workspace);
        FeedbackInfo({
          mainText: t('common.success'),
          subText: t('password_recovery.password_updated'),
        });
        return true;
      }
      FeedbackInfo({
        mainText: t('error.warning'),
        subText: t('error.unexpectedError'),
      });
      return false;
    },
    [workspaceData.subdomain, workspace, t, setSubdomain],
  );

  const handleApiProccess = useCallback(async () => {
    AxiosHelper.setDomain(workspace);
    const { statusCode } = await AxiosHelper.post({
      url: `${config.PASSWORD_RECOVERY_URL}/change_password`,
      body: {
        token,
        email,
        password,
        passwordConfirmation,
      },
    });

    return statusCode;
  }, [password, passwordConfirmation, token, email, workspace]);

  const handleUpdatePassword = useCallback(async () => {
    dispatch({ type: 'handle_submiting', payload: true });
    const isValid: boolean = checkEquality();
    if (!isValid) {
      dispatch({ type: 'handle_submiting', payload: false });
      return;
    }
    const statusCode: HttpStatusCode = await handleApiProccess();
    const hasSuccess: boolean = handleProccessState(statusCode);
    dispatch({ type: 'handle_submiting', payload: false });
    if (hasSuccess) navigate('/auth');
  }, [handleApiProccess, handleProccessState, checkEquality, navigate]);

  const getDataByToken = useCallback(async () => {
    const url: string = config.PASSWORD_RECOVERY_VALIDATE_URL.replace(
      VALIDATION_CODE_PLACEHOLDER,
      token,
    );
    const { body, statusCode } = await AxiosHelper.get({ url });
    if (statusCode === HttpStatusCode.ok) {
      dispatch({
        type: 'set_account_data',
        payload: { email: body.email, workspace: body.workspace },
      });
    } else {
      FeedbackInfo({
        mainText: t('error.warning'),
        subText: body?.error
          ? t(`password_recovery.errors.${body.error}`)
          : t('error.unexpectedError'),
      });
    }
    dispatch({ type: 'handle_loading_data', payload: false });
  }, [token, t]);

  useEffect(() => {
    if (!token) return;
    getDataByToken();
  }, [token]);

  if (isLoadingData) return <Loading />;

  if (!isTokenValid) return <InvalidTokenInfo />;

  return (
    <Main
      handlePasswordChange={handlePasswordChange}
      handlePasswordConfirmationChange={handlePasswordConfirmationChange}
      handleUpdatePassword={handleUpdatePassword}
      password={password}
      passwordConfirmation={passwordConfirmation}
      isLoading={isSubmiting}
    />
  );
};

export default ChangePassword;
