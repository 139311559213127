/* eslint-disable no-restricted-globals */
import React from 'react';
import SignaturePad from 'react-signature-canvas';
import { Alert, AlertIcon, Box, Flex, Text } from '@chakra-ui/react';
import { v4 } from 'uuid';
import { downloadURI } from '@/utils/downloadUri';
import { blobToFile } from '@/utils/blobToFile';
import { readFile } from '@/utils/readFile';

export type SignaturePadRef = {
  onClear: () => void;
  onDownload: () => void;
  onSave: () => Promise<File | null>;
};

const SignaturePadComponent: React.ForwardRefRenderFunction<SignaturePadRef> = (
  props,
  selfRef,
) => {
  const [isEmpty, setEmpty] = React.useState(false);
  const containerRef = React.useRef(null);
  const canvasRef = React.useRef<SignaturePad>(null);

  const handleDownload = React.useCallback(() => {
    const uri = `signature-${v4()}.png`;
    const canvas = canvasRef.current?.getTrimmedCanvas();
    if (canvasRef.current?.isEmpty()) return;
    const dataUrl = canvas?.toDataURL('image/png');
    if (dataUrl) {
      downloadURI(dataUrl, uri);
    }
  }, []);

  const handleSave = React.useCallback(async () => {
    const isEmpty = canvasRef.current?.isEmpty();
    setEmpty(!!isEmpty);
    if (isEmpty) return null;
    const uri = `signature-${v4()}.png`;
    const canvas = canvasRef.current?.getTrimmedCanvas();

    if (!canvas) return null;

    const dataUrl = canvas?.toDataURL('image/png');
    if (dataUrl) {
      const result = await readFile(dataUrl);
      const file = await blobToFile(result, uri);

      return file;
    }
    return null;
  }, []);

  React.useImperativeHandle(selfRef, () => ({
    onClear: () => canvasRef.current?.clear(),
    onDownload: handleDownload,
    onSave: handleSave,
  }));

  return (
    <>
      {isEmpty && (
        <Alert mb="5" status="error">
          <AlertIcon />
          Nenhuma assinatura detectada
        </Alert>
      )}
      <Flex position="relative" w="full" flex="1">
        <Flex
          flexDir="column"
          left="0"
          right="0"
          bottom="0"
          position="absolute"
          zIndex="overlay"
          alignItems="center"
        >
          <Box position="relative" h="0.5" w="96" bg="gray.500" />
          <Text>Assine Aqui</Text>
        </Flex>
        <Box
          display="flex"
          ref={containerRef}
          w="full"
          borderWidth="1px"
          flex="1"
        >
          <SignaturePad
            ref={canvasRef}
            clearOnResize
            canvasProps={{
              style: {
                height: '100%',
                width: '100%',
              },
            }}
          />
        </Box>
      </Flex>
    </>
  );
};

export default React.forwardRef(SignaturePadComponent);
