import { FiX } from 'react-icons/fi';
import styled from 'styled-components';

export const ClearFilterOuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
`;

export const ClearFilterContainer = styled.div`
  padding: 2px 10px;
  background-color: ${({ theme }) => theme.colors.v2_secondary};
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const ClearFilterText = styled.p`
  color: ${(props) => props.theme.colors.darkText};
  font-size: 14px;
`;

export const CloseIcon = styled(FiX)`
  width: 17px;
  height: 17px;
  color: ${(props) => props.theme.colors.darkText};
  margin-left: 5px;
`;
