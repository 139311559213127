import { InspectionProvider } from '@/components_v2/WebForm/context/store';
import { WebFormPropsProvider } from '@/components_v2/WebForm/hooks/useWebFormProps';
import { useModals } from '@/hooks/ModalManager';
import { EventStatus } from '@/modules/core/domain/interfaces';
import { WebFormProvider } from '@/modules/WebForm/data/formState';
import { syncContentStateToAnswers } from '@/modules/WebForm/utils/parseAnswersSync';
import { Flex, Spinner } from '@chakra-ui/react';
import React from 'react';
import { Prompt } from 'react-router-dom';
import useSWR from 'swr';

import { updateTaskEvent } from '../registrations/Tasks/services/updateTaskEvent';
import Content from './components/Content';
import { Context } from './context/ItemContext';
import useSerializedSubmit from './hooks/useSerializedSubmit';
import useUpdateItem from './hooks/useUpdateItem';
import { fetchItem, ItemWithAnswers } from './services/fetchItem';
import { PartialAnswer } from './types';
import { BrlDate, CollectionItem } from './utils/fieldParsers';

type ItemEditScreenProps = {
  formularyId: string;
  onSubmit: () => Promise<void> | void;
  itemId: string;
};

const ItemEditScreen: React.FC<ItemEditScreenProps> = (props) => {
  const modals = useModals();
  const { isLoading, updateItem } = useUpdateItem();
  const { itemId, onSubmit } = props;

  const { data: item, isValidating } = useSWR<ItemWithAnswers>(
    `item-${itemId}`,
    async () => fetchItem(itemId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const didSubmit = React.useCallback(
    async (answers: PartialAnswer[]) => {
      if (item?.id) {
        await updateItem(item?.id, answers);

        if (item?.eventId) {
          modals.confirm({
            title: 'Deseja submeter estas respostas?',
            body: 'Esta ação é irreversível.',
            confirmLabel: 'Sim',
            cancelLabel: 'Apenas Salvar Respostas',
            onCancel: async () => {
              await onSubmit();
            },
            onConfirm: async () => {
              await updateTaskEvent({
                eventId: item?.eventId,
                finishedAt: Math.floor(new Date().getTime() / 1000),
                status: EventStatus.DONE,
                processItemSyncState: false,
              });

              await onSubmit();
            },
          });
        }
      }
    },
    [item?.id, onSubmit, updateItem],
  );

  const handleSubmit = React.useCallback(
    useSerializedSubmit(
      (params) => {
        if (!params.isValid) {
          modals.confirm({
            title: 'Este checklist contém respostas pendentes ou inválidas!',
            body: 'Deseja salvar mesmo assim? Esta ação é irreversível.',
            closeOnConfirm: true,
            onConfirm: async () => {
              await didSubmit(params.answers);
            },
          });
        } else {
          modals.confirm({
            title: 'Deseja aplicar todas as mudanças?',
            body: 'Esta ação é irreversível.',
            confirmLabel: 'Sim',
            cancelLabel: 'Continuar editando',
            onConfirm: async () => {
              await didSubmit(params.answers);
            },
          });
        }
      },
      {
        collections: CollectionItem,
      },
    ),
    [didSubmit, modals],
  );

  const answers = React.useMemo(
    () => (item?.answers ? syncContentStateToAnswers(item?.answers) : {}),
    [item?.answers],
  );

  if (isValidating || !item)
    return (
      <Flex alignItems="center" justifyContent="center" flex="1">
        <Spinner />
      </Flex>
    );

  return (
    <Context.Provider key={item.id} value={{ isLoading: isValidating, item }}>
      <WebFormPropsProvider
        key={item.id}
        {...props}
        initialValues={answers}
        onSubmit={handleSubmit}
      >
        <WebFormProvider>
          <Flex flex="1">
            {isLoading && (
              <Flex
                position="absolute"
                top="0"
                zIndex="overlay"
                alignItems="center"
                justifyContent="center"
                w="full"
                bg="blackAlpha.300"
                h="full"
                flex="1"
              >
                <Spinner />
              </Flex>
            )}
            <Content isLoading={isLoading} onSubmit={handleSubmit} />
          </Flex>
        </WebFormProvider>
      </WebFormPropsProvider>
    </Context.Provider>
  );
};

const Wrapper: React.FC<ItemEditScreenProps> = (props) => {
  const { formularyId, itemId } = props;
  React.useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = 'prevent_leave';
  };
  return (
    <>
      <InspectionProvider key={itemId} formularyId={formularyId}>
        <Prompt when message="Are you sure you want to leave?" />
        <ItemEditScreen {...props} />
      </InspectionProvider>
    </>
  );
};

export default Wrapper;
