import React from 'react';

import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { Button } from '@chakra-ui/react';
import { PermissionButtonProps } from './PermissionButton.types';

const PermissionButton: React.FC<PermissionButtonProps> = ({
  permissionNeeded = 'rw',
  hideButton = false,
  externalPermission = false,
  ...rest
}) => {
  const { checkPermission } = useUserPermissions();

  const disabled = !checkPermission(permissionNeeded) && !externalPermission;
  if (hideButton && disabled) return null;

  return <Button {...rest} isDisabled={disabled} />;
};

export default PermissionButton;
