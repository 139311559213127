import { Box, Spacer, VStack } from '@chakra-ui/react';
import React from 'react';

import TasksListPages from './TasksList.Pages';
import { TaskTable } from './TaskTable';

const TasksList: React.FC = () => {
  return (
    <VStack borderWidth="thin" rounded="md" overflow="hidden" h="100%" flex={1}>
      <Box pr={1} overflowY="auto" overflowX="auto" w="100%">
        <TaskTable />
      </Box>
      <Spacer />
      <TasksListPages />
    </VStack>
  );
};

export default TasksList;
