import React from 'react';
import { IconButton, Image, Box } from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';
import { ImagePreviewProps } from './ImagePreview.types';

const ImagePreview: React.FC<ImagePreviewProps> = ({
  url,
  onRemove,
  onOpen,
}) => {
  const handleDelete = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      onRemove();
    },
    [onRemove],
  );

  const handleOpen = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onOpen();
    },
    [onOpen],
  );

  return (
    <Box
      cursor="pointer"
      onClick={handleOpen}
      w="32"
      h="32"
      position="relative"
    >
      <IconButton
        size="xs"
        position="absolute"
        right="1"
        top="1"
        aria-label="remover imagem"
        icon={<FiX />}
        onClick={handleDelete}
      />
      <Image
        bg="gray.50"
        borderRadius="md"
        w="32"
        h="32"
        borderWidth="1px"
        borderStyle="dashed"
        borderColor="gray.200"
        objectFit="contain"
        src={url}
      />
    </Box>
  );
};

export default ImagePreview;
