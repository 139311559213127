/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import AsyncSelect from 'react-select/async';
import debounce from '@/utils/debounce';
import { StylesConfig } from 'react-select';
import { useTheme } from '@/hooks/theme';
import { AsyncDropdownProps } from '../interfaces';
import { DropdownWrapper } from '../Common/styles/styles';
import {
  asyncDropdownReducer,
  initialState,
} from './utils/asyncDropdownReducer';
import { AsyncDropdownReducer } from '../interfaces/IAsyncDropdown';

const AsyncDropdown: React.FC<AsyncDropdownProps> = (props) => {
  const { colors } = useTheme();
  const {
    defaultValue,
    isMulti,
    onChange,
    optionsLoader,
    hasError,
    isDisabled = false,
  } = props;
  const [debouncedOnQuery] = useState(() => {
    return debounce(async (text: string, callback) => {
      const { current: remoteOptions } = remoteFetcher;
      const { options: newOptions } = await remoteOptions({
        page: 0,
        searchString: text,
      });
      callback(newOptions);
    }, 200);
  });
  const [state, dispatch] = useReducer<AsyncDropdownReducer>(
    asyncDropdownReducer,
    initialState,
  );
  const { page, options, isLoading } = state;

  const remoteFetcher = useRef(optionsLoader);

  const fetchData = useCallback(async (page: number) => {
    try {
      const { current: remoteOptions } = remoteFetcher;
      const { options: newOptions, hasNext } = await remoteOptions({
        page,
      });
      dispatch({
        type: 'fetch_success',
        props: {
          options: newOptions,
          hasNext,
        },
      });
    } catch (e) {
      dispatch({
        type: 'fetch_fail',
      });
    }
  }, []);

  const onScrollToBottom = useCallback(() => {
    dispatch({
      type: 'fetch_start',
    });
  }, []);

  useEffect(() => {
    fetchData(page);
  }, [page, fetchData]);

  const handleOnChange = (opt: any) => {
    onChange && onChange(opt);
  };

  const generateSelectStyles = useCallback((): StylesConfig => {
    return {
      option: (base, { isFocused, isSelected, isDisabled }) => ({
        ...base,
        backgroundColor: isDisabled
          ? '#fff'
          : isSelected
          ? colors.primary
          : isFocused
          ? colors.v2_secondary
          : '#fff',
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
        fontFamily: 'Nunito Sans',
      }),
    };
  }, [colors]);

  return (
    <DropdownWrapper hasError={hasError}>
      <AsyncSelect
        isLoading={isLoading}
        menuPortalTarget={document.querySelector('body')}
        styles={generateSelectStyles()}
        cacheOptions
        menuPlacement="auto"
        isMulti={isMulti}
        onChange={handleOnChange}
        loadOptions={debouncedOnQuery}
        defaultOptions={options}
        defaultValue={defaultValue}
        isClearable
        onMenuScrollToBottom={onScrollToBottom}
        classNamePrefix="simple_dropdown"
        isDisabled={isDisabled}
      />
    </DropdownWrapper>
  );
};

export default AsyncDropdown;
