import React from 'react';
import { Box, Image } from '@chakra-ui/react';

const ImagePreview: React.FC<{ url: string; onOpen: () => void }> = ({
  url,
  onOpen,
}) => {
  const handleOpen = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onOpen();
    },
    [onOpen],
  );

  return (
    <Box
      cursor="pointer"
      onClick={handleOpen}
      w="16"
      h="16"
      position="relative"
    >
      <Image
        bg="gray.50"
        borderRadius="md"
        w="16"
        h="16"
        borderWidth="1px"
        borderStyle="dashed"
        borderColor="gray.200"
        objectFit="cover"
        src={url}
      />
    </Box>
  );
};

export default ImagePreview;
