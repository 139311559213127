import { useSignoffContext } from '@/modules/circulabi/hooks/useSignOff';
import { chakra } from '@chakra-ui/react';
import React from 'react';

import { SignoffResult } from '../types';

function getResultLabel(result?: SignoffResult) {
  switch (result) {
    case 'release':
      return 'Liberado';
    case 'not_release':
      return 'Estoque não liberado';

    case 'release_exceeding':
      return 'Liberada quantidade excedente';
    default:
      return '';
  }
}

export function ResultLabel() {
  const { state } = useSignoffContext();
  if (!state.result) {
    return (
      <chakra.div padding="10" borderWidth="thin" rounded="md">
        <chakra.p textAlign="center" fontWeight="bold">
          Aguardando preenchimento...
        </chakra.p>
      </chakra.div>
    );
  }

  return (
    <chakra.div display="flex" flexDir="column" gap="2">
      <chakra.div display="flex" flexDir="column" gap="2">
        <chakra.p fontSize="md"> Resultado:</chakra.p>
        <chakra.p fontWeight="bold" fontSize="lg">
          {getResultLabel(state.result)}
        </chakra.p>
      </chakra.div>
    </chakra.div>
  );
}
