import { useCallback, useEffect, useState } from 'react';

import { useDebouncedSearch } from './useDebouncedSearch';

type PaginationParams = {
  page: number;
  size: number;
};

export type PaginationHookPayload = {
  nextPage: () => void;
  prevPage: () => void;
  setPage: (page: number) => void;
  setSize: (page: number) => void;
  pagination: PaginationParams;
  handleSearch: (text: string) => void;
  search: string;
};

type PaginationHookReturnParams = [PaginationHookPayload, () => void];

export type PaginationHookParams = {
  options?: {
    debounceRate?: number;
    initialPage: number;
    initialSize: number;
  };
};

export function usePagination(
  params?: PaginationHookParams,
): PaginationHookReturnParams {
  const {
    options: { initialPage, initialSize, debounceRate } = {
      initialPage: 0,
      initialSize: 10,
      debounceRate: 300,
    },
  } = params || {
    options: { initialPage: 1, initialSize: 10 },
    data: [],
  };

  const [currPage, setCurrPage] = useState(initialPage);
  const [currSize, setCurrSize] = useState(initialSize);

  const [search, setSearch] = useDebouncedSearch({
    debounceRate,
  });

  const handleSearch = useCallback((text: string) => {
    setSearch(text);
  }, []);

  const reset = useCallback(() => {
    setCurrPage(1);
  }, []);

  const nextPage = useCallback(() => {
    setCurrPage((oldState) => {
      return oldState + 1;
    });
  }, []);

  const prevPage = useCallback(() => {
    setCurrPage((oldState) => {
      return oldState - 1;
    });
  }, []);

  const setPage = useCallback((page: number) => {
    setCurrPage(page);
  }, []);

  const setSize = useCallback((size: number) => {
    setCurrSize(size);
  }, []);

  return [
    {
      nextPage,
      prevPage,
      setPage,
      setSize,
      pagination: { page: currPage, size: currSize },
      search,
      handleSearch,
    },
    reset,
  ];
}
