import styled from 'styled-components';

type OptionalProps = {
  backgroundColor?: string;
  loadColor?: string;
  width?: number | string;
  height?: number | string;
};

export const LoaderComponent = styled.div<OptionalProps>`
  border: 6px solid ${({ backgroundColor }) => backgroundColor || '#e5e5e5'};
  border-top-color: ${({ loadColor }) => loadColor || '#51d4db'};
  height: ${({ height }) => height || '50px;'};
  width: ${({ width }) => width || '50px;'};
  border-radius: 50%;
  animation: rotate 1s infinite;

  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }
`;
