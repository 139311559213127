/* eslint-disable no-nested-ternary */

import {
  Action,
  SignoffState,
} from '../components/CirculabiSignoffResult/types';
import { getLeadtimeResult } from './getLeadtimeResult';
import { getResult } from './getResult';
import { getStockable } from './getStockable';
import { criticalityMap } from '../core';

const INITIAL_STATE = {
  criticality: undefined,
  result: undefined,
  leadtime: undefined,
  // currentStock: undefined,
  stockable: undefined,
  leadtimeResult: undefined,
  hasImpact: undefined,
  obsolete: undefined,
  safetyStock: undefined,
};

export function signoffReducer(
  state: SignoffState,
  action: Action,
): SignoffState {
  switch (action.type) {
    case 'set_state': {
      const { criticality, hasNoImpact, obsolete } = action;

      if (obsolete || hasNoImpact) {
        return {
          ...INITIAL_STATE,
          criticality: criticalityMap.nonCritical,
          hasNoImpact,
          obsolete,
          stockable: false,
          result: undefined,
        };
      }

      const criticalityWithFallback = criticality;

      const stockableResult = criticalityWithFallback
        ? getStockable(criticalityWithFallback, state.leadtime)
        : undefined;

      const newState = {
        ...state,
        criticality: criticalityWithFallback,
        stockable: stockableResult,
      };

      const result = getResult(newState);

      return {
        ...newState,
        safetyStock: undefined,
        result, // resultado instantaneo caso item não for estocável
      };
    }
    case 'set_leadtime': {
      const { leadtime } = action;

      const leadtimeResult =
        typeof leadtime !== 'undefined'
          ? getLeadtimeResult(leadtime)
          : undefined;

      const stockableResult = getStockable(state.criticality!, leadtime);

      const newState = {
        ...state,
        leadtime,
        leadtimeResult,
        safetyStock: undefined,
        stockable: stockableResult,
      };

      return {
        ...newState,
        result: getResult(newState),
      };
    }

    case 'set_current_stock': {
      const newState = {
        ...state,
        currentStock: action.currentStock,
      };
      return {
        ...newState,
        result: getResult(newState),
      };
    }

    case 'set_safety_stock': {
      const newState = {
        ...state,
        safetyStock: action.safetyStock,
      };

      return {
        ...newState,
        result: getResult(newState),
      };
    }

    default:
      return state;
  }
}
