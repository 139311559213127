import config from '@/Constants';
import { HttpStatusCode } from '@/modules/shared/data/protocols/http';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';

const mutationKey = config.DISCUSSION_COMMENTS_URL;

export interface TopicCommentDeleteData {
  id: string;
}

export const deleteTopicComment = async (
  params: TopicCommentDeleteData,
): Promise<boolean> => {
  const { statusCode } = await AxiosHelper.delete({
    url: `${mutationKey}/${params.id}`,
    timeout: 5000,
  });

  if (statusCode === HttpStatusCode.deletedSuccessfully) {
    return true;
  }
  throw new UnexpectedError();
};
