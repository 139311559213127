import {
  FieldStateMap,
  AnswerStateMap,
  FieldVisibilityMap,
  RequiredFields,
  FieldDependencyMap,
} from '../interfaces';
import { filterFields } from './filterFields';
import { buildDependencyMap } from './buildDependencyMap';
import { calculateNextVisible } from './calculateNextVisible';

export const calculateFields = (
  allFields: FieldStateMap,
  answers: AnswerStateMap,
): {
  visibleFields: FieldVisibilityMap;
  requiredFields: RequiredFields;
  dependencyMap: FieldDependencyMap;
} => {
  let visibleFields: FieldVisibilityMap = {};
  const parentlessFields: FieldVisibilityMap = Object.entries(allFields).reduce(
    (prev, [key, value]) => {
      return {
        ...prev,
        [key]: !value.parentFieldId || answers[key] !== undefined,
      };
    },
    {},
  );

  const requiredFields = Object.keys(
    filterFields(allFields, ({ required }) => required),
  );

  const dependencyMap = buildDependencyMap(allFields);

  const entries = Object.entries(parentlessFields);
  for (let i = 0; i < entries.length; i++) {
    const [key, value] = entries[i];
    visibleFields[key] = value;

    if (answers[key]) {
      visibleFields = {
        ...visibleFields,
        ...calculateNextVisible(
          {
            answers,
            dependencyMap,
            requiredFields,
            errors: {},
            alerts: {},
            fields: allFields,
            visibleFields,
          },
          { answer: answers[key], currentFieldId: key },
        ),
      };
    }
  }

  return {
    visibleFields,
    requiredFields,
    dependencyMap,
  };
};
