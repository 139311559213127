import React from 'react';
import { ItemWithAnswers } from '../services/fetchItem';

export type ItemDataContexPayload = {
  item?: ItemWithAnswers;
  isLoading: boolean;
};

const Context = React.createContext<ItemDataContexPayload>(
  {} as ItemDataContexPayload,
);

function useItemContext() {
  const context = React.useContext(Context);

  return context;
}

export { Context, useItemContext };
