import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useAutomationFlowForm } from '@/screens/Tickets/Dashboard/components/Forms/AutomationFlowForm';
import { useCurrentAutoFlow } from '@/screens/Tickets/hooks/useCurrentAutoFlow';
import {
  AutomationGroupItem,
  useFetchAutomationGroups,
} from '@/screens/Tickets/hooks/useFetchAutomationGroups';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { orderBy } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';

import { useCreateFormAutomationGroupModal } from '../../Dashboard/components/Forms';
import { useDeleteAutomationGroup } from '../../hooks/useDeleteAutomationGroup';
import { CustomIcons } from '../../utils/customIcons';
import { BoardItem } from '../BoardItem';
import { useDeleteConfirmation } from '../DeleteConfirmation';

const GroupMenu = ({ groupItem }: { groupItem: AutomationGroupItem }) => {
  const deleteConfirmation = useDeleteConfirmation();

  const { openForm } = useCreateFormAutomationGroupModal();
  const [deleteAutoGroup] = useDeleteAutomationGroup();
  const { removeFlow } = useCurrentAutoFlow();
  const { onToggle, ...rest } = useDisclosure();

  const onDeleteGroup = React.useCallback(() => {
    deleteConfirmation({
      description: (
        <span>
          Esta ação não pode ser desfeita. Isso excluirá permanentemente o grupo{' '}
          <strong>{groupItem.name}</strong>, e os fluxos filhos, assim como
          todos os gatilhos e ações secundárias.
        </span>
      ),
      itemName: groupItem.name || 'grupo',
      buttonLabel: 'Eu entendo as consequências, exclua este grupo',
      async onConfirm() {
        await deleteAutoGroup({
          variables: {
            input: {
              id: groupItem?.id,
            },
          },
        });
        removeFlow();
      },
    });
  }, [
    deleteConfirmation,
    groupItem.name,
    groupItem?.id,
    removeFlow,
    deleteAutoGroup,
  ]);

  return (
    <Menu {...rest}>
      <MenuButton
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onToggle();
        }}
        as={IconButton}
        variant="ghost"
        icon={<CustomIcons.DotsHorizontal fontSize="lg" />}
      />
      <MenuList>
        <MenuItem onClick={onDeleteGroup} icon={<CustomIcons.TrashIcon />}>
          Excluir
        </MenuItem>
        <MenuItem
          onClick={() => openForm(groupItem)}
          icon={<CustomIcons.PencilIcon />}
        >
          Editar
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const FlowList: React.FC = () => {
  const { checkPermission } = useUserPermissions();
  const { data: autoGroups = [], loading } = useFetchAutomationGroups();
  const { id, setFlow } = useCurrentAutoFlow();
  const openForm = useAutomationFlowForm();
  const { t } = useTranslation();

  const renderFlowItem = React.useCallback(
    (flow) => {
      const isSelected = id === flow.id;
      const navigate = () => setFlow(flow.id);

      return (
        <Tooltip key={flow.id} label={flow.name}>
          <BoardItem
            onClick={navigate}
            selected={isSelected}
            color={flow.color}
            key={flow.id}
          >
            {flow.name}
          </BoardItem>
        </Tooltip>
      );
    },
    [id, setFlow],
  );

  const AddFlowButton = useCallback(
    ({ groupId }) => (
      <Button onClick={() => openForm({ groupId })} width="full" mb={2}>
        {t('tickets.addFlow')}
        <BiPlus fontSize={13} style={{ paddingBottom: 1, marginLeft: 1 }} />
      </Button>
    ),
    [openForm, t],
  );

  const renderFlowGroup = React.useCallback(
    (group: AutomationGroupItem) => {
      return (
        <Tooltip key={group.id} label={group.name} openDelay={1000}>
          <AccordionItem>
            <AccordionButton
              px="1"
              py="4"
              mb="1"
              _expanded={{ border: '0' }}
              _focus={{ outline: 'none' }}
            >
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {group.name}
              </Box>
              {checkPermission('admin') && <GroupMenu groupItem={group} />}
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel p={0}>
              {group?.automationFlows
                ?.filter((e) => e.name)
                .map(renderFlowItem)}
              <AddFlowButton groupId={group.id} />
            </AccordionPanel>
          </AccordionItem>
        </Tooltip>
      );
    },
    [AddFlowButton, renderFlowItem],
  );

  const sortedList = React.useMemo(
    () => orderBy(autoGroups, 'createdAt', 'desc'),
    [autoGroups],
  );

  const groupSelected = React.useMemo(() => {
    return sortedList.reduce((prev, curr, currentIndex) => {
      const flowSelected = curr.automationFlows?.find((flow) => flow.id === id);

      if (flowSelected) {
        return [...prev, currentIndex];
      }

      return prev;
    }, [] as number[]);
  }, [id, sortedList]);

  if (!autoGroups.length && loading) {
    return (
      <Stack spacing="3">
        <Skeleton height="8" rounded="lg" />
        <Skeleton height="8" rounded="lg" />
        <Skeleton height="8" rounded="lg" />
        <Skeleton height="8" rounded="lg" />
      </Stack>
    );
  }

  return (
    <Accordion
      fontSize="sm"
      allowMultiple
      allowToggle
      defaultIndex={groupSelected}
      overflow="auto"
    >
      {sortedList.filter((e) => e.name).map(renderFlowGroup)}
    </Accordion>
  );
};

export default FlowList;
