/* eslint-disable @typescript-eslint/ban-types */
import CloudStorage from '@/modules/shared/data/usecases/CloudStorage';
import { OnProgress, SendMethod } from '@rpldy/sender';
import { FILE_STATES, UploadData } from '@rpldy/shared';
import Uploady, { BatchItem } from '@rpldy/uploady';
import React from 'react';

type UploadProviderProps = {
  storage: CloudStorage;
  accept?: string;
};

const getRequestData = (items: BatchItem[]) => {
  if (items.length > 1) {
    throw new Error(
      `Firebase storage accepts only single file upload,
       while ${items.length} received.`,
    );
  }
  if (items.length === 0) {
    throw new Error('No upload items found.');
  }
  return items[0].file;
};

const makeRequest = (storage: CloudStorage) => {
  const sendRequest = (
    items: BatchItem[],
    onProgress?: OnProgress,
  ): Promise<UploadData> => {
    return new Promise((resolve) => {
      try {
        storage.uploadFile({
          file: getRequestData(items) as File,
          setProgress: (progress) => {
            onProgress?.(
              {
                total: 100,
                loaded: progress,
              },
              [{}],
            );
          },
          setFileDownloadUrl: (url) => {
            resolve({
              status: 1,
              state: FILE_STATES.FINISHED,
              response: url,
            });
          },
        });
      } catch (e) {
        resolve({
          status: 0,
          state: FILE_STATES.ERROR,
          response: e,
        });
      }
    });
  };
  return sendRequest;
};

const makeSender = (storage: CloudStorage): SendMethod => {
  const sendRequest = makeRequest(storage);

  return (items, _, __, onProgress) => {
    const request = sendRequest(items, onProgress);

    return {
      request,
      abort: () => false,
      senderType: 'FIREBASE_CLOUD_STORAGE',
    };
  };
};

const UploadProvider: React.FC<UploadProviderProps> = ({
  children,
  storage,
  accept,
}) => {
  const sender = React.useMemo(() => makeSender(storage), [storage]);
  return (
    <Uploady accept={accept} send={sender} concurrent maxConcurrent={4}>
      <>{children}</>
    </Uploady>
  );
};

export const makeUploadProvider = (
  storage: CloudStorage,
  accept?: string,
): React.FC => {
  const Wrapped: React.FC = ({ children }) => {
    return (
      <UploadProvider storage={storage} accept={accept}>
        {children}
      </UploadProvider>
    );
  };
  return Wrapped;
};

export const withUploadProvider = (storage: CloudStorage, accept?: string) => {
  const Provider = makeUploadProvider(storage, accept);
  const WithProvider = <P extends object>(Component: React.FC<P>) => {
    const Wrapped: React.ComponentType<P> = (props) => {
      return (
        <Provider>
          <Component {...props} />
        </Provider>
      );
    };

    return Wrapped;
  };

  return WithProvider;
};
