import { isObject } from 'lodash';

const camelCase = (str: string) =>
  str
    .toLowerCase()
    .replace(/([_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    );

export default function snakeToCamel(obj: any): any {
  if (isObject(obj) && !Array.isArray(obj) && typeof obj !== 'function') {
    const n = {} as Record<string, any>;

    Object.keys(obj).forEach((k) => {
      const currentKey = camelCase(k);
      const currentObj = obj[k as never];
      n[currentKey] = snakeToCamel(currentObj);
    });

    return n;
  }
  if (Array.isArray(obj)) {
    return obj.map((i) => {
      return snakeToCamel(i);
    });
  }

  return obj;
}
