import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { useMutation } from 'react-query';

export function useUpdateQuestionNote() {
  const mutation = useMutation(
    async (data: { item_id: string; question_id: string; note: string }) => {
      const response = await AxiosHelper.patch({
        url: 'answers/update_note',
        body: {
          ...data,
        },
      });

      if (response.statusCode < 200 || response.statusCode >= 300) {
        throw new UnexpectedError();
      }

      const { body } = response;

      return body;
    },
  );
  return mutation;
}
