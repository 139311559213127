import styled from 'styled-components';

interface ProgressBarStyle {
  height?: number;
  color?: string;
  progress?: number;
}

export const Container = styled.div<ProgressBarStyle>`
  width: 100%;
  height: ${({ height }) => height || '10px'};
  border-radius: ${({ height }) => (height ? height * 10 : '100px')};
  background-color: ${({ theme }) => theme.colors.v2_secondary};
  display: flex;
  justify-content: flex-start;
`;

export const Progress = styled.div<ProgressBarStyle>`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background-color: ${({ color, theme }) => color || theme.colors.primary};
  border-radius: ${({ height }) => (height ? height * 100 : '100px')};
  transition: width 100ms ease-in-out;
`;
