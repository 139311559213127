import { Step } from '@/gql/types';
import { createContext } from '@/utils/react-utils';
import { Menu, MenuList, Portal, useControllableState } from '@chakra-ui/react';
import React from 'react';

import { ActionsOption } from './ActionsOptions';

export type StepActionsMenuProps = {
  step: PartialWithId<Step>;
  children?: React.ReactNode;
};

export function useStepActionsMenuHook(props: StepActionsMenuProps) {
  const [step, setStep] = useControllableState({
    value: props.step,
    defaultValue: props.step,
  });

  return {
    step,
    setStep,
  };
}

export type UseStepActionsMenu = ReturnType<typeof useStepActionsMenuHook>;

const [StepActionsMenuProvider, useStepActionsMenu] = createContext<
  UseStepActionsMenu
>();

export { StepActionsMenuProvider, useStepActionsMenu };

export function StepActionsMenu(props: StepActionsMenuProps) {
  const { children } = props;
  return (
    <StepActionsMenuProvider value={useStepActionsMenuHook(props)}>
      <Menu isLazy lazyBehavior="keepMounted">
        {children}
        <Portal>
          <MenuList minWidth="240px">
            <ActionsOption.EditStep />

            <ActionsOption.AddStep />
            <ActionsOption.MoveStepLeft />
            <ActionsOption.MoveStepRight />
            <ActionsOption.DeleteStep />
          </MenuList>
        </Portal>
      </Menu>
    </StepActionsMenuProvider>
  );
}
