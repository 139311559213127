import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClientFactory } from './queryClientFactory';

export const ReactQueryClientProvider: React.FC = ({ children }) => {
  const queryClient = React.useMemo(() => queryClientFactory(), []);
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      {children}
    </QueryClientProvider>
  );
};
