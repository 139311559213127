import { Flex } from '@chakra-ui/react';
import React from 'react';

import ListView from './components/ListView';

const Board: React.FC = () => {
  return (
    <Flex h="100%" flexDirection="column" flex={2}>
      <ListView />
    </Flex>
  );
};

export default Board;
