import React from 'react';

import ActionSheet from '@/screens/utils/ActionSheets/ActionSheet';

const ActionSheetLayer: React.FC = () => {
  return (
    <>
      <ActionSheet side="left" />
      <ActionSheet side="right" />
    </>
  );
};

export default ActionSheetLayer;
