import { Option } from '@/components_v2/inputs/BaseDropdown/interfaces';
import { Select } from '@chakra-ui/react';
import Tooltip from '@components/Tooltip';
import React from 'react';

import {
  InfoTooltip,
  QuestionContainer,
  QuestionHeader,
  QuestionSubtitle,
  QuestionTitle,
  TooltipContainer,
} from '../common';
import { RadioProps } from './interfaces';

const Radio: React.FC<RadioProps> = ({
  question,
  errors,
  initialValues,
  onChange,
  viewOnly,
}) => {
  const disabled = !!viewOnly;
  const options = question.options.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const onSubumit = (selected: Option[] | Option) => {
    Array.isArray(selected)
      ? onChange(selected.map(({ value }) => value))
      : onChange(selected.value);
  };

  React.useEffect(() => {
    if (question.defaultValue !== undefined)
      onChange(
        options.find(({ value }) => value === question.defaultValue.toString())
          ?.value,
      );
  }, [onChange, options, question]);

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionTitle>{question.name}</QuestionTitle>
        {question.tipText && (
          <Tooltip description={question.tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      <Select
        disabled={disabled}
        isInvalid={!!errors}
        onChange={(event) => {
          const val = event.target.value;
          const opt = options.find(({ value }) => value === val);
          if (opt) onSubumit(opt);
        }}
        defaultValue={
          options.find(({ value }) => value === initialValues?.values)?.value ||
          options.find(
            ({ value }) => value === question?.defaultValue?.toString(),
          )?.value
        }
        variant="filled"
        placeholder="Selecionar opção"
      >
        {options?.map((opt) => (
          <option value={opt.value} key={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>
      <QuestionSubtitle hasError={!!errors}>
        {errors && errors.errors}
      </QuestionSubtitle>
    </QuestionContainer>
  );
};

export default Radio;
