import styled from 'styled-components';
import { BsCheckAll } from 'react-icons/bs';

type WithBackgroundColor = {
  backgroundColor: string;
};

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const IconContainer = styled.div`
  height: 100%;
  margin-right: 10px;
`;

export const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TagsContainer = styled.div`
  display: flex;
  margin-top: 8px;
  flex-direction: row;
`;

export const Text = styled.p`
  font-size: 14px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  color: ${(props) => props.theme.colors.mediumText};
  span {
    font-weight: bold;
    color: ${(props) => props.theme.colors.darkerText};
  }
`;

export const SentTime = styled.p`
  font-size: 12px;
  margin-top: 4px;
  color: ${(props) => props.theme.colors.darkText};
`;

export const Icon = styled.div<WithBackgroundColor>`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  margin: 8px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.theme.colors.lighterText};
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const AvatarInitials = styled.p`
  font-weight: 600;
  font-size: 16;
  color: ${(props) => props.color};
`;

export const CheckIconContainer = styled.div`
  height: 20px;
  margin: 10px 4px 0 8px;
  width: 20px;
  opacity: 0;
`;

export const CheckIcon = styled(BsCheckAll)`
  color: ${(props) => props.theme.colors.primaryV2};
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 10px;

  &:hover {
    ${CheckIconContainer} {
      opacity: 1;
    }
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.08);
  }
`;
