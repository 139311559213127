/* eslint-disable no-nested-ternary */
import { FormLayout } from '@/components_v2/HookForm';
import { QuestionModel } from '@/components_v2/Questions/interfaces';
import QuestionContent from '@/components_v2/WebForm/components/QuestionContent';
import { WebFormPropsProvider } from '@/components_v2/WebForm/hooks/useWebFormProps';
import { useModals } from '@/hooks/ModalManager';
import Field from '@/modules/WebForm/context/Field';
import { WebFormProvider } from '@/modules/WebForm/data/formState';
import useSubmitForm from '@/modules/WebForm/hooks/useSubmitForm';
import { Button, chakra, Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

import { PropertySchema } from './form-schemas';
import {
  PropertyFormParams,
  PropertyFormProvider,
  usePropertyForm,
} from './use-property-form';

function DynamicField({
  question,
  onSubmit,
}: {
  question: QuestionModel;
  onSubmit: (values: PropertySchema | PropertySchema[]) => Promise<void>;
}) {
  const submitForm = useSubmitForm((result) =>
    onSubmit(result.answers[question.id] as PropertySchema),
  );

  return (
    <FormLayout spacing={5} flex="1">
      <Field fieldId={question.id}>
        <Flex w="full">
          <QuestionContent {...question} />
        </Flex>
      </Field>
      <chakra.div h="auto" w="full" position="sticky">
        <Button
          type="submit"
          onClick={submitForm}
          w="full"
          colorScheme="primary"
          size="lg"
        >
          Salvar
        </Button>
      </chakra.div>
    </FormLayout>
  );
}

export function UpdatePropertyForm({
  propertyId,
  onClose,
}: PropertyFormParams) {
  const hook = usePropertyForm({
    propertyId,
    onClose,
  });
  const {
    defaultValue,
    loadingPropertyData,
    onSubmit,
    loading,
    question,
  } = hook;

  if (loadingPropertyData)
    return (
      <chakra.div
        display="flex"
        h="container.sm"
        flex="1"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner />
      </chakra.div>
    );

  return (
    <PropertyFormProvider value={hook}>
      <chakra.fieldset flex="1" display="flex" disabled={loading}>
        <chakra.div
          display="flex"
          justifyContent="space-between"
          flexDir="column"
          w="full"
          overflow="visible"
          maxH="100%"
          h="full"
        >
          <chakra.div maxW="100%" gap="6" p="6" flexDir="column">
            <WebFormPropsProvider
              initialValues={{
                [propertyId]:
                  defaultValue && Array.isArray(defaultValue)
                    ? defaultValue.map((answer) => {
                        return {
                          id: answer.id,
                          content: answer?.content,
                          answeredAt: new Date(),
                        };
                      })
                    : {
                        id: propertyId,
                        content: defaultValue?.content,
                        answeredAt: new Date(),
                      },
              }}
              formularyId=""
              itemId=""
            >
              <WebFormProvider
                answers={{
                  [`${question?.id}`]:
                    defaultValue && Array.isArray(defaultValue)
                      ? defaultValue.map((answer) => {
                          return {
                            id: answer.id,
                            content: answer?.content,
                            answeredAt: new Date(),
                          };
                        })
                      : {
                          id: propertyId,
                          content: defaultValue?.content,
                          answeredAt: new Date(),
                        },
                }}
                fields={{
                  [`${question?.id}`]: {
                    id: question?.id || '',
                    name: question?.name || '',
                    options: question?.options as Option[],
                    required: true,
                  },
                }}
              >
                <DynamicField
                  question={question as QuestionModel}
                  onSubmit={onSubmit}
                />
              </WebFormProvider>
            </WebFormPropsProvider>
          </chakra.div>
        </chakra.div>
      </chakra.fieldset>
    </PropertyFormProvider>
  );
}

export function useUpdatePropertyForm() {
  const modals = useModals();

  const openForm = (params: PropertyFormParams) => {
    modals.open({
      id: 'board-update-property-form',
      title: `Propriedade`,
      size: '2xl',
      closeOnEsc: false,
      closeOnOverlayClick: false,
      body: (
        <UpdatePropertyForm
          {...params}
          onClose={() => modals.close('board-update-property-form')}
        />
      ),
    });
  };
  return openForm;
}
