import config from '@/Constants';
import { FlaggedAnswersConnectionFragmentFragment } from '@/gql/graphql';
import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { useFeedbackCommentMutation } from '@/hooks/Feedback/useFeedbackComment';
import { useFeedbackCommentsQuery } from '@/hooks/Feedback/useFeedbackCommentsQuery';
import { createService } from '@/screens/monitoring/EventReports/components/Question/QuestionFeedback';
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  List,
  ListItem,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { orderBy } from 'lodash';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { BsInboxFill } from 'react-icons/bs';
import { useMutation, useQueryClient } from 'react-query';

import { useJustificationsContext } from '../hooks/context';
import { ActivityItem } from './ActivityItem';
import JustificationQuestionPreview from './JustificationQuestionPreview';

type JustificationActivitiesProps = {
  item: FlaggedAnswersConnectionFragmentFragment;
};

type CreateTopicParams = {
  question_id: string;
  item_id: string;
};

const ComentCompose: React.FC<{
  handleSubmit: (content: string) => void;
}> = (props) => {
  const { handleSubmit } = props;
  const [content, setContent] = React.useState<string>('');

  const onSubmit = React.useCallback(async () => {
    if (!content.length) return;

    setContent('');
    handleSubmit(content);
  }, [content, handleSubmit]);

  const handleOnkeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    },
    [onSubmit],
  );

  return (
    <Flex
      zIndex="2"
      borderWidth="thin"
      borderColor="gray.200"
      rounded="lg"
      bg="white"
      flexDir="row"
      h="28"
      p="3"
      boxShadow="2xl"
    >
      <Box me="2" flex="1">
        <Textarea
          placeholder="Comente aqui"
          resize="none"
          variant="unstyled"
          value={content}
          onKeyDown={handleOnkeyDown}
          onChange={(e) => setContent(e.target.value)}
        />
      </Box>
      <Button
        onClick={onSubmit}
        alignSelf="flex-end"
        colorScheme="brand"
        opacity="1"
      >
        Comentar
      </Button>
    </Flex>
  );
};

export const JustificationActivities: React.FC<JustificationActivitiesProps> = ({
  item,
}) => {
  const scrollRef = React.useRef<Scrollbars>(null);
  const [topicId, setTopicId] = React.useState(item?.topics?.[0]?.id);
  const { refetch } = useJustificationsContext();

  React.useEffect(() => {
    setTopicId(item?.topics?.[0]?.id);
  }, [item?.topics]);

  const query = useFeedbackCommentsQuery(`${topicId}`, {
    refetchInterval: 30000,
    enabled: !!topicId,
  });

  const { createComment } = useFeedbackCommentMutation();

  const useCreateTopic = () => {
    const queryClient = useQueryClient();
    const mutation = useMutation(
      createService<CreateTopicParams, DiscussionTopic>(
        `${config.DISCUSSION_TOPICS_URL}`,
      ),
      {
        onSuccess(data) {
          const {
            body: { id },
          } = data;
          queryClient.setQueryData<DiscussionTopic>(
            `${config.DISCUSSION_TOPICS_URL}/${id}`,
            {
              ...data.body,
            },
          );
        },
      },
    );
    return mutation;
  };

  const { mutateAsync: mutateTopicAsync } = useCreateTopic();

  const getTopic = React.useCallback(
    async (data: FlaggedAnswersConnectionFragmentFragment) => {
      if (!(data.itemId && data.questionId)) return;

      const newTopic = await mutateTopicAsync({
        item_id: data.itemId,
        question_id: data.questionId,
      });

      if (newTopic.body.id) {
        // eslint-disable-next-line consistent-return
        return newTopic.body;
      }
    },
    [mutateTopicAsync],
  );

  const addComment = React.useCallback(
    async (comment: string): Promise<void> => {
      if (!topicId) {
        const topic: DiscussionTopic | undefined = await getTopic(item);

        if (!topic?.id) return;

        setTopicId(topic?.id);

        await createComment({
          content: comment,
          discussionTopicId: topic.id,
        });

        query.refetch();
        refetch();
      } else {
        await createComment({
          content: comment,
          discussionTopicId: topicId,
        });
        refetch();
      }
    },
    [createComment, getTopic, item, query, refetch, topicId],
  );

  const parsedItems = React.useMemo(() => {
    if (!query.data?.length) return [];

    return orderBy(query.data, 'createdAt', 'asc');
  }, [query.data]);

  React.useEffect(() => {
    if (parsedItems.length) {
      scrollRef.current?.scrollToBottom();
    }
  }, [parsedItems.length]);

  const Placeholder = () => {
    return (
      <Center flex="1">
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Icon as={BsInboxFill} fontSize="150px" color="muted" />
          <Text fontSize="lg">Sem comentários</Text>
        </Flex>
      </Center>
    );
  };

  return (
    <Flex flex="1" overflow="hidden" flexDir="column" bg="gray.50">
      <Scrollbars ref={scrollRef} reversed>
        <List
          px={{ base: 4, lg: 10 }}
          w="full"
          spacing="10"
          flex="1"
          overflowY="auto"
        >
          <ListItem />
          <JustificationQuestionPreview item={item} />

          {parsedItems.length > 0 ? (
            parsedItems?.map((comment) => (
              <ListItem key={comment.id}>
                <ActivityItem item={comment} topicId={`${topicId}`} />
              </ListItem>
            ))
          ) : (
            <Placeholder />
          )}

          <ListItem />
        </List>
      </Scrollbars>
      <Box h="32" px="6" mb="4">
        <ComentCompose handleSubmit={addComment} />
      </Box>
    </Flex>
  );
};
