/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import ConfirmModal, {
  ConfirmModalProps,
} from '@/components/ConfirmModalContent';

import AlertModal, { AlertModalProps } from '@/components/AlertModalContent';

import {
  ModalContextData,
  ModalProps,
  OpenModalProps,
  OnCloseProps,
  ModalContextRefProps,
} from './interfaces/modal';

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

const ModalProvider: React.ForwardRefRenderFunction<
  ModalContextRefProps,
  { children: React.ReactNode }
> = ({ children }, selfRef) => {
  const [isOpen, setIsOpen] = useState(false);
  const [props, setProps] = useState<ModalProps>({
    onCancel: () => {},
  });

  const openModal = useCallback((data: OpenModalProps) => {
    setIsOpen(true);

    setProps({
      children: data.children,
      styles: data.styles,
      modalStyles: data.modalStyles,
      onCancel: () => {
        setIsOpen(false);
        data.onClose && data.onClose({ confirmed: false, value: undefined });
      },
    });
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openConfirmationModal = useCallback(
    (props: ConfirmModalProps) => {
      const handleClose = (closeProps: OnCloseProps) => {
        closeModal();
        props.onClose(closeProps);
      };
      openModal({
        children: (
          <ConfirmModal {...props} title={props.title} onClose={handleClose} />
        ),
        onClose: handleClose,
      });
    },
    [closeModal, openModal],
  );

  const openAlertModal = useCallback(
    (props: AlertModalProps) => {
      const handleClose = () => {
        closeModal();
        props.onClose();
      };
      openModal({
        children: (
          <AlertModal {...props} title={props.title} onClose={handleClose} />
        ),
        onClose: handleClose,
      });
    },
    [closeModal, openModal],
  );

  useImperativeHandle(
    selfRef,
    () => ({
      openConfirmationModal,
      openAlertModal,
      closeModal,
      openModal,
    }),
    [openConfirmationModal, openAlertModal, closeModal, openModal],
  );

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        openConfirmationModal,
        openAlertModal,
        props,
        isOpen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export function useModal(): ModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used whithin an ModalProvider');
  }

  return context;
}

export default forwardRef(ModalProvider);
