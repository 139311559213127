import { SignoffState } from '../components/CirculabiSignoffResult/types';
import { criticalityMap } from '../core';
import { getStockResult } from './getStockResult';

export function getResult(
  params: SignoffState,
): 'release' | 'not_release' | 'release_exceeding' | undefined {
  const {
    criticality,
    stockable,
    leadtime,
    currentStock,
    safetyStock,
    leadtimeResult,
  } = params;

  if (!criticality) return undefined;

  // apenas para criticidade média
  if (criticality === criticalityMap.medium) {
    if (!leadtime) return undefined;

    if (leadtimeResult === 'low') {
      return 'release';
    }
    return getStockResult({
      current: currentStock,
      safety: safetyStock,
    });
  }

  if (!stockable) {
    return 'release';
  }

  if (!safetyStock) {
    return undefined;
  }
  return getStockResult({
    current: currentStock,
    safety: safetyStock,
  });
}
