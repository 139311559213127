import config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import UnexpectedError from '@shared/domain/errors/UnexpectedError';
import { FilterCreateData } from '../interfaces';

async function fetchFilters(screen: string): Promise<FilterCreateData[]> {
  const response = await AxiosHelper.get({
    url: screen.includes('api/')
      ? screen
      : `${config.FILTERS_URL}?screen=${screen}`,
  });
  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new UnexpectedError();
  }
  const data: FilterCreateData[] = response.body;
  return data;
}

export default fetchFilters;
