import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClearFilterProps } from './interfaces';

import {
  ClearFilterContainer,
  ClearFilterOuterContainer,
  ClearFilterText,
  CloseIcon,
} from './styles';

const ClearFilter: React.FC<ClearFilterProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <ClearFilterOuterContainer>
      <ClearFilterContainer onClick={onClick}>
        <ClearFilterText>{t('filter.clear_filter')}</ClearFilterText>
        <CloseIcon />
      </ClearFilterContainer>
    </ClearFilterOuterContainer>
  );
};

export default ClearFilter;
