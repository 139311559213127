import { RawAddress, ProcessedAddress } from './interfaces';

function processAddress(rawAddress: RawAddress): ProcessedAddress {
  let city: string = '';
  let state: string = '';
  let country: string = '';

  for (let i = 0; i < rawAddress.address_components.length; i++) {
    const component = rawAddress.address_components[i];
    if (component.types.includes('administrative_area_level_2')) {
      city = component.long_name;
    } else if (component.types.includes('administrative_area_level_1')) {
      state = component.short_name;
    } else if (component.types.includes('country')) {
      country = component.short_name;
    }
  }

  const address = rawAddress.formatted_address;

  const latitude = rawAddress.geometry.location.lat();
  const longitude = rawAddress.geometry.location.lng();

  return {
    address_name: address,
    city,
    country,
    latitude,
    longitude,
    state,
  } as ProcessedAddress;
}

export default processAddress;
