/* eslint-disable no-underscore-dangle */
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';

import {
  ExporterServiceInstanceParams,
  ExporterServiceUseCase,
} from './usecases/ExporterServiceUseCase';

class ExporterServiceFactory {
  private _instances: { [hash: string]: ExporterServiceUseCase };

  private findInstance(instanceHash: string): Nullable<ExporterServiceUseCase> {
    const instance = this._instances && this._instances[instanceHash];
    return instance;
  }

  private cacheInstance(
    instanceHash: string,
    instance: ExporterServiceUseCase,
  ): void {
    this._instances = {
      ...this._instances,
      [instanceHash]: instance,
    };
  }

  public getInstance(
    params: { screen: string } & Omit<ExporterServiceInstanceParams, 'api'>,
  ): ExporterServiceUseCase {
    return new ExporterServiceUseCase({
      ...params,
      api: AxiosHelper,
    });
  }
}

const ExporterServiceFactoryInstance = new ExporterServiceFactory();

export default ExporterServiceFactoryInstance;
