import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import { formularyStorage } from '@/modules/shared/adapters/CloudStorage';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import {
  Button,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React, { ChangeEventHandler } from 'react';
import { BiChevronDown, BiLinkAlt, BiPencil, BiTrashAlt } from 'react-icons/bi';

import FormControlComponent from '../FormControlComponent';
import SignatureModal from './SignatureModal';
import SignaturePreview from './Singature.preview';

const SignatureContent: React.FC<QuestionModel> = () => {
  const { onChange, fieldState, error } = useField();
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const [isLoading, setLoading] = React.useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const value = React.useMemo(() => {
    if (!Array.isArray(fieldState)) return fieldState;

    return undefined;
  }, [fieldState]);

  const handleChange = React.useCallback(
    (downloadUrl: string) => {
      onChange(createAnswer(downloadUrl));
    },
    [onChange],
  );

  const handleRemove = React.useCallback(() => {
    onChange(undefined as any);
  }, [onChange]);

  const handleConfirm = React.useCallback(
    async (file: File) => {
      try {
        setLoading(true);
        await formularyStorage.uploadFile({
          file,
          setFileDownloadUrl: (downloadUrl) => {
            handleChange(downloadUrl);
            setLoading(false);
            onClose();
          },
        });
      } catch (e) {
        setLoading(false);
      }
    },
    [handleChange, onClose],
  );

  const handlePickFile: ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (event) => {
      const file = event?.target?.files?.[0];
      if (file) {
        handleConfirm(file);
      }
    },
    [handleConfirm],
  );

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <SignatureModal
        isLoading={isLoading}
        onConfirm={handleConfirm}
        isOpen={isOpen}
        onClose={onClose}
      />
      <FormControlComponent isInvalid={error}>
        <VStack spacing="6">
          <input
            type="file"
            multiple={false}
            hidden
            accept="image/*"
            ref={inputRef}
            onChange={handlePickFile}
          />
          {value?.content ? (
            <VStack alignItems="flex-start">
              <SignaturePreview url={value?.content} />
              <HStack>
                <Button
                  onClick={onOpen}
                  size="xs"
                  colorScheme="brand"
                  rightIcon={<BiPencil size={18} />}
                >
                  Assinar novamente
                </Button>
                <Button
                  onClick={handleRemove}
                  size="xs"
                  rightIcon={<BiTrashAlt size={18} />}
                >
                  Remover
                </Button>
              </HStack>
              <Divider />
            </VStack>
          ) : (
            <Wrap>
              <WrapItem alignItems="center">
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        size="sm"
                        isActive={isOpen}
                        as={Button}
                        isLoading={isLoading}
                        colorScheme="brand"
                        rightIcon={<BiChevronDown />}
                      >
                        Adicionar assinatura
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={handleClick}
                          icon={<BiLinkAlt size={18} />}
                        >
                          Anexar
                        </MenuItem>
                        <MenuItem
                          onClick={onOpen}
                          icon={<BiPencil size={18} />}
                        >
                          Criar nova assinatura
                        </MenuItem>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </WrapItem>
            </Wrap>
          )}
        </VStack>
      </FormControlComponent>
    </>
  );
};

export default SignatureContent;
