import { VStack, Text, Icon, theme } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FiUploadCloud } from 'react-icons/fi';

const { colors } = theme;
const DropzonePlaceholder: React.FC = () => {
  const { t } = useTranslation();

  return (
    <VStack m={5} alignItems="center">
      <Icon color="blue.400" w={10} h={10} as={FiUploadCloud} />
      <Text fontSize="xl" cursor="pointer">
        <Trans i18nKey="common.dnd_upload">
          {{ fileType: t('common.image') }}
          <span color={colors.blue[500]} />
        </Trans>
      </Text>
    </VStack>
  );
};

export default DropzonePlaceholder;
