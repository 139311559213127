import { transparentize } from '@chakra-ui/theme-tools';

import { colors } from './colors';

const createOutline = (colorScheme = 'gray', color?: string) =>
  `0 0 0 2px ${transparentize(color || `${colorScheme}.500`, 0.9)({ colors })}`;

export const shadows = (color?: string) => {
  return {
    outline: createOutline('brand', color),
    'outline-brand': createOutline('brand'),
    'outline-gray': createOutline('gray'),
    'outline-success': createOutline('green'),
    'outline-warning': createOutline('orange'),
    'outline-error': createOutline('red'),
    comboboxShadow:
      'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;',
  };
};
