import { FlaggedAnswersConnectionFragmentFragment } from '@/gql/graphql';
import { createContext } from '@/utils/react-utils';

type CurrentJustificationContextValue = {
  item?: FlaggedAnswersConnectionFragmentFragment;
};

const [JustificationContext, useCurrentJustification] = createContext<
  CurrentJustificationContextValue
>();

export { JustificationContext, useCurrentJustification };
