import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { useFeedbackCommentMutation } from '@/hooks/Feedback/useFeedbackComment';
import { useFeedbackCommentsQuery } from '@/hooks/Feedback/useFeedbackCommentsQuery';
import { Box, Button, Flex, List, ListItem, Textarea } from '@chakra-ui/react';
import { orderBy } from 'lodash';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { ActivityItem } from './ActivityItem';
import FeedbackQuestionPreview from './FeedbackQuestionPreview';

type FeedbackActivitiesProps = {
  feedback: DiscussionTopic;
};

const ComentCompose: React.FC<{
  handleSubmit: (content: string) => void;
}> = (props) => {
  const { handleSubmit } = props;
  const [content, setContent] = React.useState<string>('');

  const onSubmit = React.useCallback(async () => {
    if (!content.length) return;

    setContent('');
    handleSubmit(content);
  }, [content, handleSubmit]);

  const handleOnkeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    },
    [onSubmit],
  );

  return (
    <Flex
      zIndex="2"
      borderWidth="thin"
      borderColor="gray.200"
      rounded="lg"
      bg="white"
      flexDir="row"
      h="28"
      p="3"
      boxShadow="2xl"
    >
      <Box me="2" flex="1">
        <Textarea
          placeholder="Comente aqui"
          resize="none"
          variant="unstyled"
          value={content}
          onKeyDown={handleOnkeyDown}
          onChange={(e) => setContent(e.target.value)}
        />
      </Box>
      <Button
        onClick={onSubmit}
        alignSelf="flex-end"
        colorScheme="brand"
        opacity="1"
      >
        Comentar
      </Button>
    </Flex>
  );
};

export const FeedbackActivities: React.FC<FeedbackActivitiesProps> = ({
  feedback,
}) => {
  const scrollRef = React.useRef<Scrollbars>(null);

  const query = useFeedbackCommentsQuery(feedback.id, {
    refetchInterval: 30000,
    enabled: true,
  });

  const { createComment } = useFeedbackCommentMutation();

  const addComment = React.useCallback(
    async (comment: string): Promise<void> => {
      createComment({
        content: comment,
        discussionTopicId: feedback.id,
      });
    },
    [createComment, feedback],
  );

  const parsedItems = React.useMemo(() => {
    if (!query.data?.length) return [];

    return orderBy(query.data, 'createdAt', 'asc');
  }, [query.data]);

  React.useEffect(() => {
    if (parsedItems.length) {
      scrollRef.current?.scrollToBottom();
    }
  }, [parsedItems.length]);

  return (
    <Flex flex="1" overflow="hidden" flexDir="column" bg="gray.50">
      <Scrollbars ref={scrollRef} reversed>
        <List
          px={{ base: 4, lg: 10 }}
          w="full"
          spacing="10"
          flex="1"
          overflowY="auto"
        >
          <ListItem />
          <FeedbackQuestionPreview feedback={feedback} />

          {parsedItems?.map((comment) => (
            <ListItem key={comment.id}>
              <ActivityItem item={comment} topicId={feedback.id} />
            </ListItem>
          ))}
          <ListItem />
        </List>
      </Scrollbars>
      <Box h="32" px="6" mb="4">
        <ComentCompose handleSubmit={addComment} />
      </Box>
    </Flex>
  );
};
