import { DeviceUUID } from 'device-uuid';

import IDeviceInfo from '@security/data/protocols/device/IDeviceInfo';

class DeviceInfo implements IDeviceInfo {
  getUniqueId(): string {
    const du = new DeviceUUID().parse();
    const dua = [
      du.language,
      du.platform,
      du.os,
      du.cpuCores,
      du.isAuthoritative,
      du.silkAccelerated,
      du.isChrome,
      du.isMobile,
      du.version,
      du.isIE,
      du.isDesktop,
      du.isMobile,
      du.isTablet,
      du.isWindows,
      du.isLinux,
      du.isLinux64,
      du.isMac,
      du.isiPad,
      du.isiPhone,
      du.isOpera,
      du.pixelDepth,
      du.isTouchScreen,
    ];
    const uuid = du.hashMD5(dua.join(':'));
    return uuid;
  }
}

export default DeviceInfo;
