import { WorkspaceProvider } from '@hooks/workspace';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <WorkspaceProvider>
      <App />
    </WorkspaceProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
