import type { ItemData, SectionData } from '../types';

export function orderItems(items: ItemData[]) {
  const hasSectionDate = (section: SectionData) => {
    return (
      section.section_name === 'DADOS GERAIS' &&
      section.questions.some((question) => question.name === 'Data:')
    );
  };

  const getDateQuestion = (section: SectionData) => {
    const question =
      section.section_name === 'DADOS GERAIS' &&
      section.questions.find((question) => question.name === 'Data:');

    if (question && question.answers.length > 0) {
      return question?.answers?.[0] && question.answers[0].content
        ? question.answers[0].content
        : null;
    }
    return null;
  };

  items?.sort((a, b) => {
    const sectionA = a.sections.find(hasSectionDate);
    const sectionB = b.sections.find(hasSectionDate);

    const dateA = sectionA ? getDateQuestion(sectionA) : null;
    const dateB = sectionB ? getDateQuestion(sectionB) : null;

    if (dateA && dateB) {
      const [day1, month1, year1] = dateA.split('/').map(Number);
      const [day2, month2, year2] = dateB.split('/').map(Number);

      if (year1 && year2 && year1 !== year2) return year1 - year2;
      if (month1 && month2 && month1 !== month2) return month1 - month2;
      if (day1 && day2) return day1 - day2;
      return 0;
    }

    if (dateA) return -1;

    if (dateB) return 1;

    return 0;
  });

  return items;
}
