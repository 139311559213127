import { useNotificationToast } from '@/components_v2/WCNotificationToast/WCNotificationToast';
import config from '@/Constants';
import { ImportTypeEnum } from '@/modules/shared/domain/interfaces';
import { useFileTransferDrawer } from '@/screens/FileTransfer/components/FileTransferDrawer';
import { useFeedbackViewDrawer } from '@/screens/monitoring/Feedbacks/FeedbackViewDrawer';
import { actionSheetNotificationsConfig } from '@/screens/utils/AppLayout/components/Navbar/components/Notifications';
import { removei18ntags } from '@/utils/Text/removei18ntags';
import { presentImportModal } from '@components/ImporterModal';
import { useActionCable } from '@hooks/actionCable';
import { useActionSheet } from '@hooks/actionSheet';
import { useAuth } from '@hooks/Authentication/auth';
import { useNavigation } from '@hooks/navigation';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import {
  NotificationActionType,
  NotificationsContextData,
  WCNotification,
} from './interfaces';
import { markAsReadService } from './services';
import { NotificationsTopics } from './utils/NotificationsTopics';
import { useChecklistSummaryDrawer } from '@/components_v2/ChecklistSummary';

const NotificationsContext = createContext<NotificationsContextData>(
  {} as NotificationsContextData,
);

export const NotificationsProvider: React.FC = ({ children }) => {
  const fileTransferDrawer = useFileTransferDrawer();
  const toast = useNotificationToast();
  const feedbackDrawer = useFeedbackViewDrawer();
  const [pendingNotifications, setPendingNotifications] = useState(0);
  const { user, loading } = useAuth();
  const { subscribe } = useActionCable();
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const { openActionSheet, popActionSheet } = useActionSheet();
  const { openDrawer } = useChecklistSummaryDrawer();

  const userId = useMemo(() => {
    return user ? user.id : undefined;
  }, [user]);

  const setPendingNotificationsCount = useCallback((count: number) => {
    setPendingNotifications(count);
  }, []);

  const { data: dataInitialNotifications } = useQuery<number>(
    `${config.PENDING_NOTIFICATIONS_URL}`,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const onInitialNotifications = useCallback(
    (count: number) => {
      if (count === 0) return;
      toast({
        id: 'initial-notification',
        position: 'bottom-start',
        notificationType: 'initial',
        title: `Você tem ${count} notificações não lidas`,
        description: 'Clique para vê-las',
        onClick: () => openActionSheet(actionSheetNotificationsConfig),
      });
    },
    [openActionSheet, toast],
  );

  useEffect(() => {
    if (dataInitialNotifications && dataInitialNotifications > 0) {
      onInitialNotifications(dataInitialNotifications);
      setPendingNotificationsCount(dataInitialNotifications);
    }
  }, [
    dataInitialNotifications,
    onInitialNotifications,
    setPendingNotificationsCount,
  ]);

  const openImportModal = useCallback(
    (
      importId: string,
      importerType: ImportTypeEnum,
      action: NotificationActionType,
    ) => {
      switch (action) {
        case 'failure':
          presentImportModal({ importType: importerType, importId });
          break;
        default:
          fileTransferDrawer();
      }
    },
    [fileTransferDrawer],
  );

  const handleExportDownload = useCallback(
    (url: string) => window.open(url),
    [],
  );

  const getOnClickFeedback = useCallback(
    ({ data, action, body }: WCNotification) => {
      const { originType } = data;
      return () => {
        switch (originType) {
          case 'Discussion::Comment':
          case 'Discussion::Like':
          case 'Discussion::Topic':
            if (
              data.linkTo &&
              data.linkTo?.params &&
              'discussionTopicId' in data.linkTo?.params
            ) {
              feedbackDrawer.open(data.linkTo?.params.discussionTopicId);
            }
            break;
          case 'Export':
            if (data.linkTo && data.linkTo.url) {
              handleExportDownload(data.linkTo.url);
            } else {
              fileTransferDrawer();
            }
            break;
          case 'Import':
            if (data.linkTo && data.linkTo.id && data.linkTo.model) {
              popActionSheet();
              openImportModal(
                data.linkTo.id,
                data.linkTo.model as ImportTypeEnum,
                action,
              );
            }
            break;
          case 'Answer':
            if (body?.message?.messageData?.task_id) {
              popActionSheet();
              openDrawer({
                id: body.message.messageData.task_id,
                type: 'task',
              });
            }
            break;
          default:
            if (data.linkTo && data.linkTo.url) {
              popActionSheet();
              goTo(data.linkTo.url);
            }
            break;
        }
      };
    },
    [
      feedbackDrawer,
      handleExportDownload,
      popActionSheet,
      openImportModal,
      goTo,
    ],
  );

  const getBodyText = useCallback(
    (notification: WCNotification): string => {
      try {
        return t(
          notification.body.message.messageKey,
          notification.body.message.messageData,
        );
      } catch (e) {
        return '';
      }
    },
    [t],
  );

  const handleFeedback = useCallback(
    (notification: WCNotification) => {
      const { action, notificationUserId } = notification;
      const message = removei18ntags(getBodyText(notification));
      function handleClick() {
        markAsReadService(notificationUserId);
        getOnClickFeedback(notification)();
      }
      const feedbackData = {
        id: notification.id,
        title: message,
        description: 'Clique para ver detalhes...',
        duration: 10000,
        onClick: handleClick,
      };
      switch (action) {
        case 'success':
          toast({
            notificationType: 'user',
            ...feedbackData,
          });
          break;
        case 'failure':
          toast({
            notificationType: 'user',
            ...feedbackData,
          });
          break;
        case 'info':
          toast({
            notificationType: 'user',
            ...feedbackData,
          });
          break;
        default:
          toast({
            notificationType: 'user',
            ...feedbackData,
          });
      }
    },
    [getBodyText, getOnClickFeedback, toast],
  );

  const handleUpdate = useCallback((payload: WCNotification) => {
    NotificationsTopics.emitEvents(payload);
    setPendingNotifications((previous) => previous + 1);
    handleFeedback(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId && !loading) {
      const unsubscribe = subscribe('notification', handleUpdate, userId);
      return () => {
        unsubscribe();
      };
    }
    return undefined;
  }, [userId, handleUpdate, subscribe, loading]);

  return (
    <NotificationsContext.Provider
      value={{
        pendingNotifications,
        setPendingNotificationsCount,
        getOnClickFeedback,
        getBodyText,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export function useNotifications(): NotificationsContextData {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      'useNotifications must be used whithin an NotificationsProvider',
    );
  }

  return context;
}
