import React from 'react';

import { TitleProps } from './localGeneric';
import { ScreenTitle } from './styles';

const Title: React.FC<TitleProps> = ({ content }) => {
  return <ScreenTitle>{content.toUpperCase()}</ScreenTitle>;
};

export default Title;
