import { isArray, isEmpty } from 'lodash';

import {
  ErrorResults,
  IAnswerState,
  IFieldRegistry,
  IReducedAnswerState,
  IStateObserverCollection,
  ISurveyForm,
  IValidator,
  OnSubmitResults,
} from '../../../domain/usecases';

export class SurveyForm implements ISurveyForm {
  private formState: IReducedAnswerState = {};
  private registeredFields: IFieldRegistry[] = [];
  private readonly initialValues?: IReducedAnswerState;
  private stateObservers: IStateObserverCollection = {};
  private validator: IValidator | undefined = undefined;
  private inheritedTemplateName: boolean;

  constructor(
    fields: IFieldRegistry[],
    initialValues?: IReducedAnswerState,
    validator?: IValidator,
  ) {
    this.registeredFields = this.getUniqueFields(fields);
    if (initialValues) {
      this.formState = initialValues;
      this.initialValues = initialValues;
      this.inheritedTemplateName = false;
    }
    this.validator = validator;
  }
  public getValues(fieldId: string): IAnswerState | undefined {
    return this.formState[fieldId] || undefined;
  }

  public getSubscribers(): IStateObserverCollection {
    return this.stateObservers;
  }

  public getRegisteredFields(): IFieldRegistry[] {
    return this.registeredFields;
  }

  public getFormState(): IReducedAnswerState {
    return this.formState;
  }

  public removeEntry(fieldId: string): void {
    const newState = { ...this.formState };
    delete newState[fieldId];
    this.formState = newState;
    this.notifyObservers(fieldId);
  }

  public onChange(fieldId: string, value: IAnswerState): void {
    const exists: boolean = !!this.registeredFields.find(
      (i) => i.id === fieldId,
    );
    if (exists) {
      this.formState = {
        ...this.formState,
        [`${fieldId}`]: value,
      };
      this.notifyObservers(fieldId);
    }
  }

  public subscribe(
    fieldId: string,
    callback: (value: IAnswerState) => void,
  ): () => void {
    if (fieldId.length > 0) {
      if (this.stateObservers[fieldId]) {
        this.stateObservers[fieldId].push(callback);
      } else {
        this.stateObservers[fieldId] = [callback];
      }
    }

    callback(this.formState[fieldId]);

    return () => {
      this.unsubscribe(fieldId, callback);
    };
  }

  public unsubscribe(
    fieldId: string,
    callback: (value: IAnswerState) => void,
  ): void {
    if (!this.stateObservers[fieldId] || !this.stateObservers[fieldId].length) {
      return;
    }

    const index = this.stateObservers[fieldId].indexOf(callback);

    if (index > -1) {
      this.stateObservers[fieldId].splice(index, 1);
    }
  }

  public unsubscribeAll(): void {
    this.stateObservers = {};
  }

  checkRequiredFields = (
    registeredFields: IFieldRegistry[],
    formState: IReducedAnswerState,
  ): string[] => {
    const requiredFields = registeredFields.filter((r) => r.required);
    const invalidFields = [];

    for (let index = 0; index < requiredFields.length; index++) {
      const field = requiredFields[index].id;

      if (
        !formState[field] ||
        !formState[field]?.values ||
        (isArray(formState[field]?.values) &&
          isEmpty(formState[field]?.values)) ||
        (field === 'recurrence' &&
          !formState[field].values.recurrencyTemplateType)
      ) {
        invalidFields.push(field);
      }
    }
    return invalidFields;
  };

  public async triggerValidation(): Promise<ErrorResults> {
    let errors: ErrorResults = {};
    const { registeredFields, formState } = this;
    const unansweredFields = this.checkRequiredFields(
      registeredFields,
      formState,
    );

    if (unansweredFields.length) {
      unansweredFields.forEach((e) => {
        errors[e] = { errors: ['Campo obrigatório'] };
      });
    }

    if (this.validator) {
      const validatorErrors = await this.validator.validate(formState);
      errors = { ...errors, ...validatorErrors };
    }

    return errors;
  }

  public registerFields(fields: IFieldRegistry[] | IFieldRegistry): void {
    if (Array.isArray(fields)) {
      this.registeredFields = this.getUniqueFields([
        ...this.registeredFields,
        ...fields,
      ]);
    } else {
      this.registeredFields = this.getUniqueFields([
        ...this.registeredFields,
        fields,
      ]);
    }
  }

  public unregisterField(fieldId: string): void {
    const newState = { ...this.formState };
    this.registeredFields = this.registeredFields.filter(
      (e) => e.id !== fieldId,
    );
    delete newState[fieldId];
    this.formState = newState;

    this.notifyObservers(fieldId);
  }

  notifyObservers(fieldId: string): void {
    if (this.stateObservers[fieldId]) {
      const callbacks = this.stateObservers[fieldId];
      const value = this.formState[fieldId];

      for (let index = 0; index < callbacks.length; index++) {
        const callback = callbacks[index];
        callback(value);
      }
    }
  }

  public async onSubmit(): Promise<OnSubmitResults> {
    const submitResults: OnSubmitResults = {
      errors: {},
      valid: true,
      answers: {},
    } as OnSubmitResults;
    const errors = await this.triggerValidation();

    if (Object.keys(errors).length > 0) {
      submitResults.valid = false;
      submitResults.errors = errors;
    }

    submitResults.answers = this.formState;
    return submitResults;
  }

  public changeTitleField(value: IAnswerState): void {
    const exists = this.registeredFields.find((i) => i.id === 'title');
    if (exists) {
      this.formState = {
        ...this.formState,
        title: value?.values?.length > 0 ? value : this.formState.title,
      };
      this.inheritedTemplateName = !!value.values && exists.id === 'title';
      this.notifyObservers('title');
    }
  }

  public hasInheritedTemplateName(id: string): boolean {
    return this.inheritedTemplateName && id === 'title';
  }

  public setInheritedTemplateName(value: boolean): void {
    this.inheritedTemplateName = value;
  }

  getUniqueFields(array: IFieldRegistry[]): IFieldRegistry[] {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i].id === a[j].id) a.splice(j--, 1);
      }
    }
    return a;
  }
}
