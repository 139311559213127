import ISocketClient from '../protocols/websocket/ISocketClient';
import ISocketManager from '../protocols/websocket/ISocketManager';

class SocketManager {
  private manager: ISocketManager;
  public socket: ISocketClient | undefined;
  public channels = {
    export: 'ExportChannel',
    import: 'ImportChannel',
    notification: 'NotificationChannel',
    userChanged: 'UserChangedChannel',
    newTaskActivity: 'new_task_activity_channel',
  };

  constructor(manager: ISocketManager) {
    this.manager = manager;
  }

  connect(url: string, token: string): ISocketClient {
    this.socket = this.manager.subscribe(url, token);
    return this.socket;
  }

  disconnect(): void {
    this.manager.unsubscribe();
    this.socket = undefined;
  }
}

export default SocketManager;
