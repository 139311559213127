import { useModals } from '@/hooks/ModalManager';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  Input,
  useBreakpointValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import GoogleMapReact from 'google-map-react';
import React from 'react';
import { RiMapPin2Fill } from 'react-icons/ri';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

import { GeolocationInputProps } from './GeolocationInput.types';

import '@/utils/initializeGoogleApiScript';

export const GeolocationInputModal: React.FC<GeolocationInputProps> = (
  props,
) => {
  const toast = useToast();
  const modals = useModals();
  const { onChange, initialAddress = '', initialValue } = props;

  const [map, setMap] = React.useState<any>();
  const [address, setAddress] = React.useState<string>(initialAddress);
  const [value, setValue] = React.useState(initialValue);
  const mapRef = React.useRef<GoogleMapReact>(null);

  const handleSearch = React.useCallback((newAddress: string) => {
    setAddress(newAddress);
  }, []);

  const handleMapClick = React.useCallback(
    (e: GoogleMapReact.ClickEventValue) => {
      setValue({ latitude: e.lat, longitude: e.lng });
      setAddress('');
    },
    [],
  );

  const handleSearchSelect = React.useCallback(
    async (newAddress: string) => {
      const geocode = await geocodeByAddress(newAddress); // CAREFUL
      if (geocode.length === 0 || !map) return;
      const place = geocode[0];
      setAddress(newAddress);
      const { lat, lng } = place.geometry.location;
      setValue({ latitude: lat(), longitude: lng() });
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }
    },
    [map],
  );

  const handleGetLocation = React.useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (e) => {
        setValue({
          latitude: e.coords.latitude,
          longitude: e.coords.longitude,
        });
        setAddress('');
        map.setCenter({ lat: e.coords.latitude, lng: e.coords.longitude });
        map.setZoom(15);
      },
      () => {
        toast({
          status: 'error',
          title: 'Falha ao requisitar localização',
        });
      },
    );
  }, [map, toast]);

  const handleApply = React.useCallback(() => {
    onChange(value, address.length > 0 ? address : undefined);
    setTimeout(() => {
      modals.close('geolocation');
    }, 1);
  }, [onChange, value, address, modals]);

  React.useEffect(() => {
    if (initialValue && map) {
      map.setCenter({
        lat: initialValue.latitude,
        lng: initialValue.longitude,
      });
      map.setZoom(10);
    }
  }, [initialValue, map]);

  return (
    <Flex flex="1" px="6" pb="6" gap="6" w="full" flexDirection="column">
      <Flex w="full" flex="1">
        <VStack w="full" spacing={4}>
          <HStack w="full">
            <PlacesAutocomplete
              value={address}
              onSelect={handleSearchSelect}
              onChange={handleSearch}
              searchOptions={{
                types: ['establishment', 'geocode'],
                componentRestrictions: { country: ['br', 'cl'] as unknown as string },
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <Flex position="relative" flex={1}>
                  <Input
                    {...getInputProps({
                      className: 'location-search-input',
                    })}
                  />
                  <Box
                    display={
                      suggestions.length > 0 || loading ? 'initial' : 'none'
                    }
                    position="absolute"
                    top="100%"
                    zIndex={10}
                    className="autocomplete-dropdown-container"
                    bg="gray.100"
                    borderRadius="md"
                    w="full"
                  >
                    {loading && (
                      <Box p={4} w="full">
                        Carregando...
                      </Box>
                    )}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      return (
                        <Box
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                          _hover={{
                            bg: 'gray.200',
                            cursor: 'pointer',
                            borderRadius: 'md',
                          }}
                          p={2}
                          fontSize="sm"
                        >
                          <span>{suggestion.description}</span>
                        </Box>
                      );
                    })}
                  </Box>
                </Flex>
              )}
            </PlacesAutocomplete>
            <Button
              size="md"
              colorScheme="primary"
              minWidth="fit-content"
              onClick={handleGetLocation}
            >
              Me encontrar
            </Button>
          </HStack>
          <Box overflow="clip" rounded="lg" w="100%" h="72">
            <GoogleMapReact
              ref={mapRef}
              defaultCenter={{ lat: -14.235, lng: -51.9253 }}
              onClick={handleMapClick}
              defaultZoom={2}
              onGoogleApiLoaded={({ map }) => setMap(map)}
              yesIWantToUseGoogleMapApiInternals
            >
              {value && (
                <Box {...{ lat: value.latitude, lng: value.longitude }}>
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    justifyContent="flex-start"
                    width="3em"
                    height="3em"
                    position="relative"
                  >
                    <Icon
                      position="absolute"
                      top="-100%"
                      left="-50%"
                      as={RiMapPin2Fill}
                      color="red.500"
                      width="3em"
                      height="3em"
                    />
                  </Flex>
                </Box>
              )}
            </GoogleMapReact>
          </Box>
        </VStack>
      </Flex>

      <Flex w="full">
        <ButtonGroup w="full" size="md">
          <Button flex="1" onClick={() => modals.close('geolocation')}>
            Fechar
          </Button>
          <Button flex="1.4" colorScheme="brand" onClick={handleApply}>
            Salvar e aplicar
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  );
};

export function useGeolocationModal() {
  const modals = useModals();
  const modalSize = useBreakpointValue({ base: 'full', lg: '2xl' });

  const onOpen = React.useCallback(
    (props: GeolocationInputProps) => {
      modals.open({
        id: 'geolocation',
        scope: 'alert',
        size: modalSize,
        title: 'Selecione uma localização',
        body: <GeolocationInputModal {...props} />,
      });
    },
    [modalSize, modals],
  );
  return { onOpen };
}
