import { useNotificationsList } from '@/hooks/Notifications/notificationsList';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

const ReadAll: React.FC = () => {
  const { t } = useTranslation();
  const { readAll, notifications } = useNotificationsList();

  return notifications.length > 0 ? (
    <Container onClick={readAll}>{t('notifications.clear_all')}</Container>
  ) : (
    <></>
  );
};

export default ReadAll;
