import {
  Divider,
  Heading,
  HStack,
  ListItem,
  SimpleGrid,
  SimpleGridProps,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

export type FileTransferItemProps = {
  title: string;
  subtitle: string;
  status: 'pending' | 'done' | 'failure' | 'processing' | 'validating';
  body?: React.ReactNode;
  footer?: React.ReactNode;
};

export function KVTable(props: SimpleGridProps) {
  return <SimpleGrid px="2.5" w="100%" columns={2} spacing={1} {...props} />;
}

export function KVItem({
  label,
  value,
}: {
  label: string;
  value: string | React.ReactNode;
}) {
  return (
    <>
      <Text color="mutedText" fontWeight="normal">
        {label}
      </Text>
      <Text textAlign="end" fontWeight="semibold">
        {value}
      </Text>
    </>
  );
}

export function FileTransferItem({
  status,
  subtitle,
  title,
  body,
  footer,
}: FileTransferItemProps) {
  const statusProps = React.useMemo(() => {
    switch (status) {
      case 'done':
        return ['green', 'Concluído'] as const;
      case 'failure':
        return ['red', 'Erro'] as const;
      case 'pending':
        return ['gray', 'Pendente'] as const;
      case 'processing':
        return ['blue', 'Processando'] as const;
      case 'validating':
        return ['blue', 'Validando'] as const;

      default:
        return ['gray', 'Pendente'] as const;
    }
  }, [status]);

  return (
    <ListItem shadow="sm" rounded="md" borderWidth="thin" bg="gray.50">
      <VStack flex="1" alignItems="flex-start" spacing="2.5">
        <HStack p="2.5" w="full" justifyContent="space-between">
          <VStack alignItems="flex-start" spacing="-0.5">
            <Heading size="md">{title}</Heading>
            <Text>{subtitle}</Text>
          </VStack>
          <Tag size="lg" colorScheme={statusProps[0]}>
            {statusProps[1]}
          </Tag>
        </HStack>

        {body}

        <Divider />

        <HStack w="full" px="2.5" pb="2.5">
          {footer}
        </HStack>
      </VStack>
    </ListItem>
  );
}
