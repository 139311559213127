export const statTypes = [
  'pending',
  'draft',
  'done',
  'late',
  'flagged',
  'deleted',
  'without_answers',
  'deleted_without_answers',
] as const;
export type StatType = typeof statTypes[number];

export type StatData = { value: number; description: Nullable<string> };
