import Loading from '@/components_v2/Loading';
import {
  FormView,
  MobileNav,
  Sidebar,
} from '@/components_v2/WebForm/components';
import useCurrentSection from '@/components_v2/WebForm/hooks/useCurrentSection';
import useFormDetails from '@/components_v2/WebForm/hooks/useFormDetails';
import { useWebFormProps } from '@/components_v2/WebForm/hooks/useWebFormProps';
import { parseFormularyToFields } from '@/components_v2/WebForm/utils/parseFormularyToFields';
import useWebForm from '@/modules/WebForm/hooks/useWebForm';
import { SubmitResult } from '@/modules/WebForm/interfaces';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import Header from './Header';

const Content: React.FC<{
  onSubmit: (result: SubmitResult) => void;
  isLoading: boolean;
}> = (props) => {
  const { initialValues } = useWebFormProps();
  const { isLoading } = useCurrentSection();
  const { formulary } = useFormDetails();
  const { setFields, setInitialValues } = useWebForm();

  useEffect(() => {
    setFields(parseFormularyToFields(formulary));
  }, [formulary, setFields]);

  useEffect(() => {
    setInitialValues(initialValues);
  }, [initialValues, setInitialValues]);
  return (
    <Flex
      h="full"
      w="full"
      as="section"
      bg={useColorModeValue('gray.50', 'gray.700')}
      flexDir="column"
    >
      <Header {...props} />
      <Flex flex="1" overflow="hidden">
        <Sidebar display={{ base: 'none', xl: 'unset' }} />

        <Flex
          flexDir="column"
          h="full"
          flex="1"
          transition=".3s ease"
          overflowY="auto"
        >
          <Flex flex="1" as="main" position="relative">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <FormView />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <MobileNav />
    </Flex>
  );
};

export default Content;
