import React from 'react';
import { RemoveExtraContainerProps } from './interfaces';

import { Container, Text, RemoveIcon } from './styles';

const RemoveExtraContainer: React.FC<RemoveExtraContainerProps> = ({
  onClick,
  text,
}) => {
  return (
    <Container>
      <Text>{text}</Text>
      <RemoveIcon onClick={onClick} />
    </Container>
  );
};

export default RemoveExtraContainer;
