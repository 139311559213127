import { FiX } from 'react-icons/fi';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 2px 8px;

  background: ${(props) => props.theme.colors.v2_secondary};
  border-radius: 100px;

  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  cursor: pointer;
`;

export const RemoveIcon = styled(FiX)`
  color: ${(props) => props.theme.colors.darkText};
  width: 12px;
  height: 12px;
  margin-left: 2px;
`;

export const Text = styled.p`
  color: ${(props) => props.theme.colors.darkText};
  font-size: 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
