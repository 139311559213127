/* eslint-disable consistent-return */

import { IconBaseProps } from 'react-icons';
import { FiBell } from 'react-icons/fi';
import styled, { css } from 'styled-components';

interface ContainerProps {
  hasOpenNotification: boolean;
}

export const Container = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  background-color: white;
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  padding: 0;
  border: none;
  cursor: pointer;
  :hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  ${(props) => {
    if (props.hasOpenNotification) {
      return css`
        ::after {
          position: absolute;
          z-index: 10;
          content: '';
          border-radius: 50%;
          width: 10px;
          height: 10px;
          right: 10%;
          top: 0%;
          background-color: ${(props) => props.theme.colors.notification};
        }
      `;
    }
  }}

  @media (max-width: 768px) {
    width: 100%;
    justify-content: start;
    padding: var(--chakra-space-3) 0;
    padding-inline-start: var(--chakra-space-3);
    padding-inline-end: var(--chakra-space-3);
    border-radius: 0;
    background-color: transparent;
    height: 24px;

    ${(props) => {
      if (props.hasOpenNotification) {
        return css`
          ::after {
            position: absolute;
            z-index: 10;
            content: '';
            border-radius: 50%;
            width: 10px;
            height: 10px;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.colors.notification};
          }
        `;
      }
    }}
  }
`;

export const NotificationIcon = styled(FiBell)<IconBaseProps>`
  color: ${(props) => props.theme.colors.darkText};
`;
