import ModalLayer from '@/screens/utils/Modal/ModalLayer';
import ModalStackLayer from '@/screens/utils/Modal/ModalStackLayer';
import GlobalProvider from '@hooks/index';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './Routes';

const AppRouter: React.FC = () => {
  return (
    <Router>
      <GlobalProvider>
        <ModalLayer />
        <ModalStackLayer />
        <Routes />
      </GlobalProvider>
    </Router>
  );
};

export default AppRouter;
