import React from 'react';
import { FiX } from 'react-icons/fi';
import { TagProps } from './interfaces';

import { TagContainer } from './styles';

const Tag: React.FC<TagProps> = ({
  backgroundColor,
  color,
  children,
  closable,
}) => {
  return (
    <TagContainer backgroundColor={backgroundColor} color={color}>
      {children}
      {closable && <FiX color="black" style={{ marginLeft: '3px' }} />}
    </TagContainer>
  );
};

export default Tag;
