import React, { useCallback } from 'react';

import { HandleQuestionProps } from './interfaces';
import Main from './Main';

const Questions: React.FC<HandleQuestionProps> = (props) => {
  const {
    question: { questionType },
    onChange,
    onChangeScheduleTitle,
  } = props;

  const onChangeTitleCallback = useCallback(
    (value: any) => {
      const vals = {
        values: value,
        answeredAt: new Date(),
        questionType: 'text',
      };
      onChangeScheduleTitle(vals);
    },
    [onChangeScheduleTitle],
  );

  const onChangeCallback = useCallback(
    (value: any) => {
      const vals = {
        values: value,
        answeredAt: new Date(),
        questionType,
      };
      onChange(vals);
    },
    [onChange, questionType],
  );

  return (
    <Main
      {...props}
      onChange={onChangeCallback}
      onChangeScheduleTitle={onChangeTitleCallback}
    />
  );
};

export default Questions;
