import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  Portal,
  PortalProps,
} from '@chakra-ui/react';
import React from 'react';

export interface DefaultDialogProps
  extends Omit<AlertDialogProps, 'leastDestructiveRef'> {
  /**
   * The dialog title
   */
  title?: React.ReactNode;
  /**
   * The cancel button label
   */
  cancelLabel?: React.ReactNode;
  /**
   * The confirm button label
   */
  confirmLabel?: React.ReactNode;
  /**
   * The cancel button props
   */
  cancelProps?: ButtonProps;
  /**
   * The confirm button props
   */
  confirmProps?: ButtonProps;
  /**
   * The button group props
   */
  buttonGroupProps?: ButtonGroupProps;
  /**
   * Close the dialog on cancel
   * @default true
   */
  closeOnCancel?: boolean;
  /**
   * Close the dialog on confirm
   * @default true
   */
  closeOnConfirm?: boolean;
  /**
   * Defines which button gets initial focus
   * https://www.w3.org/TR/wai-aria-practices/#alertdialog
   */
  leastDestructiveFocus?: 'cancel' | 'confirm';
  /**
   * Function that's called when cancel is clicked
   */
  onCancel?: () => void;
  /**
   * Function that's called when confirm is clicked
   */
  onConfirm?: () => void;

  /**
   * Customize portal Behavior
   */
  portalProps?: PortalProps;
}

export const DefaultDialog: React.FC<DefaultDialogProps> = (props) => {
  const {
    title,
    cancelLabel = 'Cancelar',
    confirmLabel = 'Confimar',
    cancelProps,
    confirmProps,
    buttonGroupProps,
    isOpen,
    closeOnCancel = true,
    closeOnConfirm = true,
    leastDestructiveFocus = 'cancel',
    onClose,
    onCancel,
    onConfirm,
    children,
    portalProps,
    ...rest
  } = props;

  const cancelRef = React.useRef(null);
  const confirmRef = React.useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      {...rest}
      leastDestructiveRef={
        leastDestructiveFocus === 'cancel' ? cancelRef : confirmRef
      }
    >
      <AlertDialogOverlay>
        <Portal {...portalProps}>
          <AlertDialogContent>
            <AlertDialogHeader>{title}</AlertDialogHeader>

            <AlertDialogBody>{children}</AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup {...buttonGroupProps}>
                <Button
                  ref={cancelRef}
                  {...cancelProps}
                  onClick={() => {
                    onCancel?.();

                    closeOnCancel && onClose();
                  }}
                >
                  {cancelLabel}
                </Button>
                <Button
                  ref={confirmRef}
                  colorScheme="brand"
                  {...confirmProps}
                  onClick={() => {
                    onConfirm?.();

                    closeOnConfirm && onClose();
                  }}
                >
                  {confirmLabel}
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </Portal>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
