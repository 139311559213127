import React from 'react';
import PopoverDatepicker from '@/components_v2/inputs/PopoverDatepicker';
import { useTranslation } from 'react-i18next';
import { useRecurrence } from '../../hooks/useRecurrence';
import { DatepickerDiv, Row } from '../Common/styles';

const DatePicker: React.FC = () => {
  const { t } = useTranslation();
  const { onChangeDate, state, viewOnly } = useRecurrence();

  return (
    <Row>
      <PopoverDatepicker
        disabled={viewOnly}
        selectedDate={
          state.recurrenceRule
            ? (state.recurrenceRule.dateStart as Date)
            : undefined
        }
        onChangeDate={(date: Date) => onChangeDate({ dateStart: date })}
      />
      <DatepickerDiv>
        <span>{t('date.until')}</span>
      </DatepickerDiv>
      <PopoverDatepicker
        minDate={
          state.recurrenceRule
            ? (state?.recurrenceRule?.dateStart as Date)
            : undefined
        }
        selectedDate={
          state.recurrenceRule
            ? (state?.recurrenceRule?.dateEnd as Date)
            : undefined
        }
        onChangeDate={(date: Date) => onChangeDate({ dateEnd: date })}
        disabled={viewOnly}
      />
    </Row>
  );
};

export default DatePicker;
