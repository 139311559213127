import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

import useCurrentSection from '../hooks/useCurrentSection';
import DebuggerPannel from './DebuggerPannel';
import Pagination from './Pagination';
import QuestionList from './QuestionList';

const FormView: React.FC<
  FlexProps & {
    formDescription?: string | null | undefined;
  }
> = ({ formDescription, ...props }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { currentSection } = useCurrentSection();

  return (
    <>
      {/* <DebuggerPannel isOpen={isOpen} onToggle={onToggle} /> */}
      <Flex
        bg="white"
        w="full"
        direction="column"
        alignItems="center"
        marginRight={isOpen ? 80 : 'none'}
        transition="margin-right 0.1s ease-in-out"
        {...props}
      >
        <Flex
          mx="auto"
          bg="gray.100"
          rounded="lg"
          borderWidth="thin"
          w={{ base: 'full', md: '770px' }}
          flexDirection="column"
          marginTop="8"
          marginBottom="8"
        >
          <Flex w="full" p="4">
            <Flex
              position="relative"
              bg="white"
              p="4"
              w="full"
              rounded="xl"
              flexDir="column"
              borderWidth="thin"
            >
              <Heading color="gray.700">{currentSection?.name}</Heading>
              <Text size="sm">{currentSection?.description}</Text>
            </Flex>
          </Flex>
          <Flex hidden={!formDescription?.length} w="full" p="4">
            <Flex
              position="relative"
              bg="white"
              p="4"
              w="full"
              rounded="xl"
              flexDir="column"
              borderWidth="thin"
            >
              <Text whiteSpace="pre-wrap" fontSize="lg" fontWeight="semibold">
                {formDescription}
              </Text>
            </Flex>
          </Flex>
          <Flex
            paddingBottom={4}
            px={{ base: 'none', md: '4' }}
            borderRadius="20px"
            direction="column"
          >
            <Box
              borderBottom="
        1.5px solid #edf2f7"
              w="100%"
              marginBottom="2"
            />

            <QuestionList />
          </Flex>
        </Flex>
        <Flex w={{ base: 'full', md: '770px' }} mx="auto" marginBottom="20">
          <Pagination />
        </Flex>
      </Flex>
    </>
  );
};

export default FormView;
