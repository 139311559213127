import { useNavigation } from '@/hooks/navigation';
import React from 'react';
import { useParams } from 'react-router-dom';

export function useCurrentAutoFlow() {
  const { id } = useParams<{ id: string }>();
  const { navigate } = useNavigation();

  const removeFlow = React.useCallback(() => {
    navigate(`/app/tickets/`);
  }, [navigate]);
  const setFlow = React.useCallback(
    (nextId: string) => {
      navigate(`/app/tickets/${nextId}`);
    },
    [navigate],
  );

  return {
    id: React.useMemo(() => id, [id]),
    setFlow,
    removeFlow,
  };
}
