/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/react-in-jsx-scope */

import { FormLayout } from '@/components_v2/HookForm';
import TabSelector from '@/components_v2/TabSelector';
import {
  TemplateTaskUrlEmailFragmentFragment,
  TemplateTaskUrlEmailFragmentFragmentDoc,
  TemplateTaskUrlFragmentFragmentDoc,
  TemplateUrlTypes,
  UpdateTemplateTaskUrlMutationVariables,
} from '@/gql/graphql';
import { useModals } from '@/hooks/ModalManager';
import {
  CopyIcon,
  ExternalLinkIcon,
  LockIcon,
  UnlockIcon,
} from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  List,
  ListItem,
  Tag,
  Text,
  Textarea,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FaQrcode } from 'react-icons/fa';
import QRCode from 'react-qr-code';
import * as yup from 'yup';

import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import {
  getFragmentData,
  useCreateTemplateTaskEmail,
  useDeleteTemplateTaskUrl,
  useDeleteTemplateTaskUrlEmail,
  useResendTemplateTaskUrlEmail,
  useTemplateTaskUrlQuery,
  useUpdateTemplateTaskUrl,
} from '../hooks/useShareTemplateTask';
import { downloadQrCode, getShareableLink } from '../utils';

const emailSendFormSchema = yup
  .object({
    email: yup.string().email('Email inválido').required('Digite um email'),
  })
  .required();

type ShareViewProps = {
  templateTaskUrlId: string;
};

type SendItemProps = {
  item: TemplateTaskUrlEmailFragmentFragment;
  auth?: boolean;
};
const SendItem = ({ item, auth }: SendItemProps) => {
  const copyToClipboard = useCopyToClipboard();
  const [resendEmail, resendPayload] = useResendTemplateTaskUrlEmail();
  const [deleteEmail, deletePayload] = useDeleteTemplateTaskUrlEmail();

  const shareableLink = item.templateTaskUrlId
    ? `${getShareableLink(item.templateTaskUrlId, auth)}?email=${item.email}`
    : undefined;

  return (
    <ListItem alignItems="center" display="flex">
      <HStack flex="1">
        <Avatar
          bg="gray.200"
          color="gray.700"
          name={item.email || ''}
          h="32px"
          w="32px"
        />
        <Box>
          <Text lineHeight="shorter" fontWeight="semibold" fontSize="md">
            {item.email}
          </Text>
          <Text lineHeight="shorter" fontSize="sm">
            atualizado em {moment(item.createdAt).format('LLL')}
          </Text>
        </Box>
      </HStack>

      <Flex alignItems="center" gap="3">
        <Tag colorScheme={!item.sentAt ? 'red' : 'green'}>
          {item.sentAt ? 'Enviado' : 'Pendente'}
        </Tag>
        <ButtonGroup size="sm" isDisabled={deletePayload.loading}>
          <Button
            colorScheme="red"
            onClick={() =>
              deleteEmail({
                id: item.id,
                templateTaskUrlId: `${item.templateTaskUrlId}`,
              })
            }
          >
            Deletar
          </Button>
          <Button
            isLoading={resendPayload.loading}
            onClick={() => {
              resendEmail({
                id: item.id,
                templateTaskUrlId: `${item.templateTaskUrlId}`,
                email: `${item.email}`,
              });
            }}
            variant="outline"
          >
            Reenviar
          </Button>
          <IconButton
            onClick={() => copyToClipboard(`${shareableLink}`)}
            icon={<CopyIcon />}
            variant="outline"
            aria-label="share"
          />
        </ButtonGroup>
      </Flex>
    </ListItem>
  );
};

function SendMailForm({ templateTaskUrlId }: ShareViewProps) {
  const [sendEmail, payload] = useCreateTemplateTaskEmail();
  const submitRef = React.useRef<HTMLButtonElement>(null);
  const form = useForm<yup.InferType<typeof emailSendFormSchema>>({
    resolver: yupResolver(emailSendFormSchema),
  });

  return (
    <Flex
      as="form"
      onSubmit={form.handleSubmit(async (values) => {
        await sendEmail({
          email: values.email,
          templateTaskUrlId,
        });

        form.reset();
      })}
    >
      <FormControl
        isInvalid={!!form.formState.errors.email?.message}
        position="sticky"
        top={0}
      >
        <FormLabel fontWeight="bold">Enviar para e-mail</FormLabel>
        <InputGroup w="full" size="lg">
          <Input
            variant="filled"
            {...form.register('email')}
            disabled={payload.loading}
            size="lg"
            _placeholder={{
              color: 'gray.700',
            }}
            placeholder="jon@doe.com"
          />
          <InputRightElement w="auto" pe="1">
            <Button
              isLoading={payload.loading}
              type="submit"
              variant="outline"
              size="md"
              bg="white"
            >
              Enviar link
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
          {form.formState.errors.email?.message}
        </FormErrorMessage>
      </FormControl>
      <button type="submit" hidden ref={submitRef} />
    </Flex>
  );
}

function ShareDetails(props: ShareViewProps) {
  const modals = useModals();
  const copyToClipboard = useCopyToClipboard();
  const { templateTaskUrlId } = props;
  const { data } = useTemplateTaskUrlQuery({ id: templateTaskUrlId });
  const [description, setDescription] = React.useState('');
  const [auth, setAuth] = React.useState(false);
  const [updateShare, { loading }] = useUpdateTemplateTaskUrl();
  const [deleteShare, { loading: deleting }] = useDeleteTemplateTaskUrl();

  const fragmentData = getFragmentData(
    TemplateTaskUrlFragmentFragmentDoc,
    data?.templateTaskUrl,
  );

  React.useEffect(() => {
    if (fragmentData?.description) {
      setDescription(fragmentData?.description);
    }
  }, [fragmentData]);

  const handleUpdate = React.useCallback(
    (payload: Omit<UpdateTemplateTaskUrlMutationVariables['input'], 'id'>) => {
      updateShare({
        variables: {
          input: {
            id: templateTaskUrlId,
            ...payload,
          },
        },
      });
    },
    [templateTaskUrlId, updateShare],
  );

  const shareableLink = useMemo(
    () =>
      data?.templateTaskUrl
        ? getShareableLink(templateTaskUrlId, auth)
        : undefined,
    [data?.templateTaskUrl, auth],
  );

  const renderQrCode = React.useCallback(() => {
    if (!shareableLink?.href) {
      return <FaQrcode color="var(--chakra-colors-gray-300)" size={200} />;
    }
    return <QRCode id="qrcode" size={200} value={shareableLink?.href} />;
  }, [shareableLink]);

  return (
    <Flex
      as="fieldset"
      disabled={loading}
      flex={1}
      px={6}
      pb={4}
      flexDir="column"
      gap="4"
      overflow="auto"
    >
      <chakra.p fontSize="lg" mb={2} fontWeight="semibold">
        Crie links públicos para abertura no navegador para compartilhamento
      </chakra.p>

      <FormLayout>
        <Flex flexDir="column" gap="4" w="full">
          <VStack gap="1.5s">
            {renderQrCode()}
            <div>
              <Button
                disabled={!shareableLink}
                variant="outline"
                w="full"
                size="sm"
                onClick={() => downloadQrCode(templateTaskUrlId || '')}
              >
                Salvar QRCode
              </Button>
            </div>
          </VStack>

          <Flex flexDir="column" flex={1} gap={3}>
            <Flex gap={3} flexDir="row">
              <InputGroup w="full" size="lg">
                <InputLeftElement>
                  <Tooltip
                    label={auth ? 'Com autenticação' : 'Sem autenticação'}
                  >
                    <IconButton
                      size="lg"
                      icon={
                        auth ? (
                          <LockIcon color="red.500" />
                        ) : (
                          <UnlockIcon color="green.500" />
                        )
                      }
                      aria-label="link"
                      onClick={() => setAuth(!auth)}
                    />
                  </Tooltip>
                </InputLeftElement>
                <Input
                  variant="filled"
                  size="lg"
                  type="tel"
                  pointerEvents="none"
                  _placeholder={{
                    color: 'gray.700',
                  }}
                  placeholder={
                    shareableLink?.href ||
                    'https://station.wecheckstation.com.br/external/deep_link'
                  }
                />
                <InputRightElement>
                  <Tooltip label="Visualizar URL">
                    <Link isExternal href={shareableLink?.href}>
                      <IconButton
                        size="lg"
                        icon={<ExternalLinkIcon />}
                        aria-label="link"
                      />
                    </Link>
                  </Tooltip>
                </InputRightElement>
              </InputGroup>
              <Tooltip label="Copiar para área de transferência">
                <IconButton
                  variant="outline"
                  size="lg"
                  icon={<CopyIcon />}
                  boxShadow="md"
                  onClick={() => copyToClipboard(shareableLink?.href || '')}
                  aria-label="copy"
                />
              </Tooltip>
            </Flex>
            <Flex mb="2">
              <FormControl>
                <FormLabel fontWeight="bold">Descrição (opcional)</FormLabel>
                <Textarea
                  disabled={loading}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  onBlur={() => {
                    handleUpdate({
                      description,
                    });
                  }}
                  placeholder="Descrição breve da tarefa"
                  variant="filled"
                  resize="none"
                />
              </FormControl>
            </Flex>

            <Flex
              p="3"
              rounded="md"
              bg="blue.50"
              borderWidth="thin"
              alignItems="flex-start"
              flexDir="column"
              gap="2"
            >
              <TabSelector
                key={fragmentData?.id || 'tab-selector'}
                variant="ios"
                isDisabled={loading}
                onChange={(key) => {
                  handleUpdate({
                    urlType: key,
                  });
                }}
                initialKey={fragmentData?.urlType || TemplateUrlTypes.OnlyUse}
                tabs={[
                  {
                    key: TemplateUrlTypes.OnlyUse,
                    label: 'Uso único',
                  },
                  {
                    key: TemplateUrlTypes.Recurrency,
                    label: 'Recorrente',
                  },
                ]}
              />
              <Text fontSize="sm" fontWeight="semibold">
                {fragmentData?.urlType === TemplateUrlTypes.OnlyUse
                  ? 'O link de compartilhamento único será desativado após o primeiro uso.'
                  : 'O link recorrente poderá ser usado quantas vezes deseja, desde que esteja ativo'}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <AnimatePresence>
          {fragmentData?.urlType === TemplateUrlTypes.Recurrency && (
            <motion.div
              style={{
                display: 'flex',
                gap: 16,
                flexDirection: 'column',
                overflow: 'hidden',
              }}
              initial={{ height: 1, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 1, opacity: 0 }}
            >
              <Divider />
              <Flex flexDir="column" w="full" gap="10" position="relative">
                <SendMailForm templateTaskUrlId={templateTaskUrlId} />

                {!fragmentData?.templateTaskUrlEmails?.length ? (
                  <Center
                    minH="100"
                    bg="gray.100"
                    rounded="lg"
                    borderWidth="thin"
                    flex="1"
                  >
                    <Text>Nenhum e-mail enviado</Text>
                  </Center>
                ) : (
                  <List flex="1" spacing={4}>
                    {fragmentData?.templateTaskUrlEmails?.map((item) => {
                      const data = getFragmentData(
                        TemplateTaskUrlEmailFragmentFragmentDoc,
                        item,
                      );
                      return <SendItem key={data.id} item={data} auth={auth} />;
                    })}
                  </List>
                )}
              </Flex>
            </motion.div>
          )}
        </AnimatePresence>
      </FormLayout>

      <Flex pt="10" justifyContent="space-between">
        <Button
          isLoading={deleting}
          onClick={() =>
            deleteShare(templateTaskUrlId, () => {
              modals.close('share_view_modal');
            })
          }
          size="lg"
          variant="outline"
          colorScheme="red"
        >
          Deletar
        </Button>
        <Button
          onClick={() => modals.close('share_view_modal')}
          size="lg"
          colorScheme="brand"
        >
          Voltar
        </Button>
      </Flex>
    </Flex>
  );
}

export function useShareViewModal() {
  const modals = useModals();

  return (templateTaskUrlId: string) => {
    const id = 'share_view_modal';
    modals.open({
      id,
      title: 'Compartilhar Checklist',
      closeOnEsc: false,
      closeOnOverlayClick: false,
      size: '2xl',
      body: <ShareDetails templateTaskUrlId={templateTaskUrlId} />,
    });
  };
}
