import Loading from '@/components/Loading';
import { getDifferenceInDays } from '@/utils/Date/parseDayDate';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import NotificationItem from '../NotificationItem';
import NotificationsFilters from './components/NotificationsFilters';
import SectionDivider from './components/SectionDivider';
import { NotificationsListRenderProps } from './interfaces';
import { Container, EmptyItem, List, TagsList } from './styles';

const NotificationsList: React.FC<NotificationsListRenderProps> = ({
  isValidating,
  notifications,
}) => {
  const { t } = useTranslation();

  const renderItems = useCallback(() => {
    let olderReached = false;
    let todayReached = false;
    let yesterdayReached = false;
    return notifications.map((notification, index) => {
      const component = (
        <NotificationItem
          key={`${index}-${notification.id}`}
          notification={notification}
        />
      );
      const diff = getDifferenceInDays(
        notification.sentTime,
        new Date().getTime(),
      );
      if (diff < 1 && !todayReached) {
        todayReached = true;
        return (
          <React.Fragment key={`frag-today-${notification?.id}`}>
            <SectionDivider key="today">{t('time.today')}</SectionDivider>
            {component}
          </React.Fragment>
        );
      }
      if (diff >= 1 && diff < 2 && !yesterdayReached) {
        yesterdayReached = true;
        return (
          <React.Fragment key={`frag-yesterday-${notification?.id}`}>
            <SectionDivider key="yesterday">
              {t('time.yesterday')}
            </SectionDivider>
            {component}
          </React.Fragment>
        );
      }
      if (diff >= 2 && !olderReached) {
        olderReached = true;
        return (
          <React.Fragment key={`frag-older-${notification?.id}`}>
            <SectionDivider key="older">{t('time.older')}</SectionDivider>
            {component}
          </React.Fragment>
        );
      }
      return component;
    });
  }, [notifications, t]);

  return (
    <Container>
      <TagsList>
        <div
          // className="no-scrollbar"
          style={{
            display: 'flex',
            padding: '0.25rem 0 ',
            overflowX: 'auto',
          }}
        >
          <NotificationsFilters />
        </div>
      </TagsList>
      <List>
        {notifications.length === 0 && isValidating && <Loading />}
        {notifications.length > 0 ? (
          renderItems()
        ) : (
          <EmptyItem>{t('notifications.no_notifications')}</EmptyItem>
        )}
      </List>
    </Container>
  );
};

export default NotificationsList;
