import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { Answer } from '@/modules/WebForm/interfaces';
import { createAnswer } from '@/modules/WebForm/utils';
import { parseFieldConditionals } from '@/modules/WebForm/utils/parseFieldConditionals';
import debounce from '@/utils/debounce';
import { useMaskedInput } from '@/utils/Masks';
import createNumberMask from '@/utils/Masks/createNumberMask';
import { parseStringToFloat } from '@/utils/parseStringToFloat';
import { Input, Tag, Text } from '@chakra-ui/react';
import React from 'react';

import FormControlComponent from '../FormControlComponent';

const numberMask = (hasPrefix: boolean, decimalPrecision?: number) => {
  return createNumberMask({
    ...(hasPrefix ? { prefix: ['R', '$', ' '] } : {}),
    delimiter: '.',
    separator: ',',
    precision: decimalPrecision || 2,
  });
};

const NumberContent: React.FC<
  QuestionModel & {
    isInteger?: boolean;
  }
> = ({ isInteger, decimalPrecision }) => {
  const { error, onChange, fieldState, alert, field, clear } = useField();
  const debouncedOnChange = React.useMemo(() => debounce(onChange, 300), [
    onChange,
  ]);

  const [value, setValue] = React.useState(() => {
    if (fieldState && !Array.isArray(fieldState)) {
      if (typeof fieldState.content === 'string') {
        return fieldState.content;
      }
      return `${fieldState.content}`;
    }
    return '';
  });

  const maskedInputProps = useMaskedInput({
    value,
    onChangeText: (newValue: string) => {
      setValue(newValue);
      const answer = createAnswer(newValue);
      debouncedOnChange(answer);
      update.current(answer);
    },
    mask: numberMask(false, decimalPrecision),
  });

  const update = React.useRef(
    debounce((value: Answer | Answer[]) => {
      const conditionalResult = parseFieldConditionals(field, value);
      const isInvalid = conditionalResult.error;

      if (isInvalid) {
        clear();
        setValue('');
      }
    }, 500),
  );

  const inputProps = React.useMemo(() => {
    if (isInteger) {
      return {
        onChangeText: (nextVal: string) => {
          const parsedNumber = parseStringToFloat(nextVal);
          const parsed = Number.isNaN(parsedNumber)
            ? ''
            : `${parseStringToFloat(nextVal)}`;
          setValue(`${parsed}`);
          const answer = createAnswer(parsed);
          debouncedOnChange(answer);
          update.current(answer);
        },
        value,
      };
    }
    return maskedInputProps;
  }, [debouncedOnChange, isInteger, maskedInputProps, value]);

  return (
    <FormControlComponent isInvalid={error}>
      <Input
        value={inputProps.value}
        onChange={(e) => inputProps.onChangeText(e.target.value)}
      />
      <Tag size="lg" colorScheme="red" mt="4" hidden={!alert}>
        {alert}
      </Tag>
    </FormControlComponent>
  );
};

export default NumberContent;
