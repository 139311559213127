import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { forwardRef, Input, InputGroup, InputProps } from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';
import React, { useState } from 'react';

import { InputRightButton } from './inputRightButton';

interface PasswordOptions {
  viewIcon?: React.ReactNode;
  viewOffIcon?: React.ReactNode;
}

export interface PasswordInputProps extends InputProps, PasswordOptions {}

export const PasswordInput = forwardRef<PasswordInputProps, 'div'>(
  ({ viewIcon, viewOffIcon, autoComplete, ...props }, ref) => {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const label = show ? 'Hide password' : 'Show password';

    let icon;
    if (show) {
      icon = viewIcon || <ViewIcon />;
    } else {
      icon = viewOffIcon || <ViewOffIcon />;
    }

    return (
      <InputGroup>
        <Input
          {...props}
          ref={ref}
          type={show ? 'text' : 'password'}
          // autoComplete={show ? 'off' : autoComplete}
        />

        <InputRightButton
          onClick={handleClick}
          aria-label={label}
          variant="ghost"
        >
          {icon}
        </InputRightButton>
      </InputGroup>
    );
  },
);

if (__DEV__) {
  PasswordInput.displayName = 'PasswordInput';
}
