import { StatType } from './Stats.types';

export const typeColorMap: Record<StatType, string> = {
  done: 'green.300',
  pending: 'gray.300',
  late: 'red.300',
  draft: 'orange.300',
  flagged: 'red.400',
  deleted: 'blue.50',
  without_answers: 'yellow.500',
  deleted_without_answers: 'pink.300',
};
