/* eslint-disable react/react-in-jsx-scope */

import { DataTable } from '@/components_v2/DataTable';
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  HStack,
  Input,
} from '@chakra-ui/react';
import React from 'react';
import { BiPlus } from 'react-icons/bi';

import { useTemplateTasks } from './hooks';

export function TemplateTasks() {
  const {
    columns,
    data,
    isLoading,
    setSearchTerm,
    openForm,
  } = useTemplateTasks();

  return (
    <Flex overflow="0" w="full" flexDir="column" gap="4">
      <Flex gap="4" flexDir="column">
        <Alert rounded="md" status="info">
          <AlertIcon />
          Crie templates com QRCodes para que seus usuários possam inciar
          tarefas com facilidade.
        </Alert>
        <HStack bg="gray.200" p="1.5" rounded="md" spacing={4}>
          <Input
            variant="filled"
            borderWidth="thin"
            borderColor="gray.300"
            placeholder="Buscar"
            w="xl"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            h="full"
            colorScheme="brand"
            rightIcon={<BiPlus />}
            onClick={() => openForm()}
            whiteSpace="break-spaces"
          >
            Criar novo template
          </Button>
        </HStack>
      </Flex>

      <Flex
        overflowY="auto"
        display="block"
        flex="1"
        rounded="md"
        borderWidth="thin"
      >
        <DataTable
          isLoading={isLoading}
          tableProps={{
            variant: 'striped',
            size: 'lg',
          }}
          headerProps={{
            h: 14,
            bg: 'white',
          }}
          columns={columns}
          data={data}
        />
      </Flex>
    </Flex>
  );
}
