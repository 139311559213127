import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import {
  AsyncProps,
  GroupBase,
  OptionProps,
  Select,
} from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { InnerRef } from 'react-select';
import { Avatar, Box, HStack, Tag, TagLabel, Text } from '@chakra-ui/react';
import config from '@/Constants';
import { useQuery } from 'react-query';
import { registerFieldType } from '@/components_v2/HookForm';

export type UserOption = {
  label: string;
  avatarUrl?: string;
  value: string;
};

type UserSelectProps = {
  initialUser?: UserOption;
} & UseFormRegisterReturn &
  Omit<AsyncProps<UserOption, false, GroupBase<UserOption>>, 'onChange'>;

const UserSelectOption: React.FC<
  { innerRef: InnerRef } & OptionProps<UserOption, false>
> = ({ innerRef, innerProps, data }) => {
  return (
    <Box {...innerProps} ref={innerRef} px="4" py="1">
      <HStack _hover={{ bg: 'gray.100', cursor: 'pointer' }}>
        <Avatar size="xs" name={data.label} ml={-1} mr={2} />
        <Text>{data.label}</Text>
      </HStack>
    </Box>
  );
};

export const UserInput: React.FC<UserSelectProps> = ({ onChange, ...rest }) => {
  const { data } = useQuery<{ items: any[] }>(config.USERS_URL);
  const { t } = useTranslation();

  return (
    <Select
      cacheOptions
      onChange={(e) => onChange(e as any)}
      {...rest}
      placeholder={t('tasks.form.user_placeholder')}
      isClearable
      options={data?.items.map((e) => ({
        value: e.id,
        label: e.fullName,
        avatarUrl: '',
        variant: 'outline',
      }))}
      isMulti={false}
      components={{
        Option: UserSelectOption as any,
      }}
      menuPortalTarget={document.body}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          rounded: 'lg',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          bg: 'transparent',
          px: 2,
          cursor: 'inherit',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
      }}
      styles={{
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 1900,
        }),
      }}
    />
  );
};

registerFieldType('user-picker', UserInput, {
  isControlled: true,
});
