import { useState } from 'react';

import debounce, { DebouncedFunction } from '../debounce';

type DebouncedSearchPayload = [
  string,
  DebouncedFunction<(text: string) => void>,
];

export type DebouncedSearcParams = {
  debounceRate?: number;
};

export function useDebouncedSearch(
  params?: DebouncedSearcParams,
): DebouncedSearchPayload {
  const { debounceRate } = params || { debounceRate: 200 };
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSetSearchText] = useState(() => {
    return debounce((text: string) => setSearchText(text), debounceRate);
  });

  return [searchText, debouncedSetSearchText];
}
