/* eslint-disable react/no-array-index-key */
import _ from 'lodash';

import { SectionContext, useReport, useSection } from './hooks/use-context';

function SectionHeader() {
  const section = useSection();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0',
        borderBottom: '1px solid',
        paddingBottom: '8px',
      }}
    >
      <h2 style={{ fontSize: '0.875rem', fontWeight: 600 }}>
        Seção: {section.name}
      </h2>
      {!!section.description && (
        <p style={{ fontSize: '0.875rem' }}>Descrição: {section.description}</p>
      )}
    </div>
  );
}

export function SectionItem({ children }: { children?: React.ReactNode }) {
  return (
    <li
      style={{
        backgroundColor: '#ffffff',
        paddingTop: '16px',
        paddingBottom: '16px',
      }}
    >
      <SectionHeader />
      {children}
    </li>
  );
}

export function SectionsList({ children }: { children?: React.ReactNode }) {
  const { body } = useReport();

  return (
    <ul style={{ width: '100%', listStyleType: 'none' }}>
      {_.orderBy(body.items, 'position', 'asc').map((section, idx) => (
        <SectionContext value={section} key={`${section.name}-${idx}`}>
          <SectionItem>{children}</SectionItem>
        </SectionContext>
      ))}
    </ul>
  );
}
