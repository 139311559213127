/* eslint-disable array-callback-return */
import { gql } from '@/gql';
import { getApolloInstance } from '@/modules/graphql';

export const queryLocals = gql(`#graphql
  query Locals {
    locals {
      id
      name
    }
  }
`);

export const fetchLocals = async () => {
  const apollo = getApolloInstance();
  const { data } = await apollo.query({
    query: queryLocals,
    fetchPolicy: 'network-only',
  });

  const items = data?.locals || [];

  return {
    items,
    cursor: undefined,
  };
};
