import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

const TopRightLoading: React.FC = () => {
  return (
    <Flex position="absolute" top={2} right={2}>
      <Spinner size="lg" />
    </Flex>
  );
};

export default TopRightLoading;
