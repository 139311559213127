import { ChakraProvider } from '@chakra-ui/react';
import { useWorkspace } from '@hooks/workspace';
import moment from 'moment';
import esLocale from 'moment/locale/es';
import ptBrLocale from 'moment/locale/pt-br';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';
import GlobalStyle from './styles/global';

import 'react-grid-layout/css/styles.css';

import Lightbox from './components/Lightbox';
import theme from './theme';

function App() {
  const { i18n } = useTranslation();
  const { workspaceData } = useWorkspace();
  const primaryColor = workspaceData?.theme?.colors?.primary;
  React.useEffect(() => {
    if (i18n.language) {
      moment.locale(i18n.language.toLowerCase(), [ptBrLocale, esLocale]);
    }
  }, [i18n.language]);

  return (
    <ChakraProvider theme={theme({ primaryColor })} portalZIndex={40}>
      <Lightbox.LightboxComponent ref={Lightbox.setLightboxRef} />
      <GlobalStyle />
      <ToastContainer />
      <Routes />
    </ChakraProvider>
  );
}

export default App;
