import TabSelector from '@/components_v2/TabSelector';
import { useAuth } from '@/hooks/Authentication/auth';
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useFilter } from '@/hooks/Filters/filter';
import { useTasks } from '@/screens/registrations/Tasks/Dashboard/hooks/tasks';
import { HStack, Icon, Stack, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillPeopleFill, BsFillPersonFill } from 'react-icons/bs';

import ActionsMenu from '../../../ActionsMenu';

const Filters: React.FC = () => {
  const direction = useBreakpointValue<'column' | 'row'>({
    base: 'column',
    '2xl': 'row',
  });
  const { checkPermission } = useUserPermissions();
  const { onChangeResponsible } = useTasks();
  const { t } = useTranslation();
  const { selectOption } = useFilter();
  const { user } = useAuth();

  return (
    <Stack
      direction={direction}
      overflowX="visible"
      mb={4}
      w="100%"
      justifyContent="space-between"
      spacing={4}
    >
      <HStack justifyContent="space-between" w="100%">
        <TabSelector
          variant="ios"
          onChange={(key) => {
            onChangeResponsible(key);
          }}
          initialKey="all_tasks"
          tabs={
            checkPermission('rw') || user?.read_all
              ? [
                  {
                    key: 'my_tasks',
                    label: t('tasks.owner.my_tasks'),
                    icon: <Icon as={BsFillPersonFill} mr={2} />,
                  },
                  {
                    key: 'all_tasks',
                    label: t('tasks.owner.all_tasks'),
                    icon: <Icon as={BsFillPeopleFill} mr={2} />,
                  },
                ]
              : [
                  {
                    key: 'my_tasks',
                    label: t('tasks.owner.my_tasks'),
                    icon: <Icon as={BsFillPersonFill} mr={2} />,
                  },
                ]
          }
        />
        <TabSelector
          variant="ios"
          onChange={(key) =>
            selectOption({
              column: 'status',
              selected: key,
              table: 'tasks',
              type: 'single',
            })
          }
          initialKey="pending"
          tabs={[
            {
              key: 'pending',
              label: t('tasks.status.pending'),
            },
            {
              key: 'draft',
              label: t('tasks.status.draft'),
              color: 'orange.300',
            },
            { key: 'done', label: t('tasks.status.done'), color: 'green.300' },
            { key: 'late', label: t('tasks.status.late'), color: 'red.300' },
          ]}
        />
        <ActionsMenu />
      </HStack>
    </Stack>
  );
};

export default Filters;
