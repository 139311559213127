import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@components/Button';
import { ConfirmModalRenderProps } from './interfaces';
import {
  ContentContainer,
  Title,
  ButtonContainer,
  SubtitleContainer,
} from './styles';

const ConfirmModal: React.FC<ConfirmModalRenderProps> = ({
  onCancel,
  onConfirm,
  title,
  subTitle,
}) => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <Title>{t(title)}</Title>
      <SubtitleContainer>{subTitle}</SubtitleContainer>
      <ButtonContainer>
        <Button type="CONFIRM" callback={onConfirm} />
        <Button type="CANCEL" callback={onCancel} />
      </ButtonContainer>
    </ContentContainer>
  );
};

export default ConfirmModal;
