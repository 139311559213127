import {
  SelectedFilters,
  SelectedFilter,
  FiltersCreateData,
  FetchOptionsResponse,
  FiltersData,
} from '@queryfilter/domain/interfaces';

import UnexpectedError from '@shared/domain/errors/UnexpectedError';

import { IQueryFilter } from '@queryfilter/domain/usecases';
import { QueryFilterClient } from '@queryfilter/data/protocols';
import serializeFilter from '@services/query/serializeFilters';

class QueryFilter implements IQueryFilter {
  filters: FiltersData = [];
  selectedFilters: SelectedFilters;
  client: QueryFilterClient;

  constructor(client: QueryFilterClient) {
    this.client = client;
  }

  private getOptionPath(key: string, page?: number): string {
    const filter = this.filters.find((filter) => filter.column === key);
    let url = filter?.url ?? '';
    if (!filter) return '';
    if (page) url += `&page=${page}`;
    return url;
  }

  serializeFilter(): string {
    return this.selectedFilters ? serializeFilter(this.selectedFilters) : '';
  }

  selectedOptions() {
    if (!this.selectedFilters || !this.selectedFilters.filters) return [];
    return this.selectedFilters.filters
      .map(({ column, selected }) => {
        if (!selected) return [];

        const filterKey = this.filters.find(
          (filter) => filter.column === column,
        );

        const title = filterKey && filterKey.title ? filterKey.title : '';

        if (Array.isArray(selected)) {
          const selectedArray = selected as [];
          return selectedArray.map((value) => ({ title, value }));
        }

        return { title, value: selected };
      })
      .flat();
  }

  async getFilters(screen: string): Promise<FiltersCreateData> {
    try {
      const filters = await this.client.fetchFilters(screen);
      this.filters = filters;

      const screenFilters: FiltersCreateData = [];
      for (let i = 0; i < filters.length; i++) {
        screenFilters.push({
          column: filters[i].column,
          parentFields: filters[i].parentFields,
          title: filters[i].title,
          type: filters[i].type,
          dateFormat: filters[i].dateFormat,
          defaultValue: filters[i].defaultValue,
          maxValue: filters[i].maxValue,
          minValue: filters[i].minValue,
          sliderType: filters[i].sliderType,
          maxSelection: filters[i].maxSelection,
        });
      }
      this.selectedFilters = {
        table: filters[0] ? filters[0].table : '',
        filters: [],
      };
      return screenFilters;
    } catch (err) {
      throw new UnexpectedError();
    }
  }

  updateFilters(data: SelectedFilter[]): void {
    this.selectedFilters.filters = data;
  }

  async getOptions(
    key: string,
    page?: number,
    searchValue?: string,
    title?: string,
  ): Promise<FetchOptionsResponse> {
    try {
      const options = await this.client.fetchOptions({
        page,
        searchValue,
        title,
        path: this.getOptionPath(key),
        query:
          Object.keys(this.selectedFilters.filters).length > 0
            ? this.serializeFilter()
            : undefined,
      });
      return options;
    } catch (err) {
      throw new UnexpectedError();
    }
  }
}

export default QueryFilter;
