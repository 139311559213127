import { orderBy } from 'lodash';
import React from 'react';
import { useInspectionStore } from '../context/store';

export default function useSections() {
  const {
    formulary,
    isLoading,
    setSection,
    currentSection,
  } = useInspectionStore(
    React.useCallback(
      ({ formulary, isLoading, setSection, currentSection }) => ({
        formulary,
        isLoading,
        setSection,
        currentSection,
      }),
      [],
    ),
  );

  return {
    sections: orderBy(formulary?.sections || [], 'position', 'asc'),
    setSection,
    currentSection,
    isLoading,
  };
}
