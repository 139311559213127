import { useAutomationFlow } from '@/screens/Tickets/context/automationFlow';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiSearch } from 'react-icons/bi';

const Search: React.FC = () => {
  const { onSearch } = useAutomationFlow();
  const { t } = useTranslation();

  return (
    <>
      <InputGroup maxWidth={400}>
        <Input
          _focus={{ _focus: 'none' }}
          defaultValue=""
          onChange={(e) => onSearch(e.target.value)}
          placeholder={t('common.search')}
          backgroundColor="white"
        />
        <InputRightElement>
          <BiSearch color="#E2E8F0" />
        </InputRightElement>
      </InputGroup>
    </>
  );
};

export default Search;
