import { useContext } from 'react';
import { ThemeContext, DefaultTheme } from 'styled-components';

export function useTheme(): DefaultTheme {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used whithin an AuthProvider');
  }

  return context;
}
