/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';
import { AiFillCamera } from 'react-icons/ai';
import {
  BiBuildings,
  BiChevronLeft,
  BiChevronRight,
  BiDotsHorizontalRounded,
  BiLink,
  BiPlus,
  BiText,
} from 'react-icons/bi';
import {
  BsFileText,
  BsFillCalendarFill,
  BsFillLockFill,
  BsFillPersonFill,
  BsFillPlayFill,
  BsLayersFill,
  BsPersonCheckFill,
  BsSearch,
} from 'react-icons/bs';
import {
  FaCog,
  FaFileExcel,
  FaFilePdf,
  FaFileWord,
  FaParagraph,
  FaTrash,
} from 'react-icons/fa';
import { FiCheck, FiChevronDown, FiFile, FiX } from 'react-icons/fi';
import { GrDrag } from 'react-icons/gr';
import { MdModeEdit } from 'react-icons/md';
import { RiCheckboxFill } from 'react-icons/ri';

export const ATTRIBUTE_ICONS = {
  text: BiText,
  location: BiBuildings,
  picture: AiFillCamera,
  date: BsFillCalendarFill,
  user: BsFillPersonFill,
  userCheck: BsPersonCheckFill,
  checkbox: RiCheckboxFill,
  paragraph: FaParagraph,
};

export const FILE_TYPE_ICONS = {
  pdf: FaFilePdf,
  excel: FaFileExcel,
  word: FaFileWord,
  text: BsFileText,
};

export const DRAG_HANDLE = GrDrag;
export const TRASH_ICON = FaTrash;
export const PENCIL_ICON = MdModeEdit;
export const ADD_ICON = BiPlus;
export const LOCK_ICON = BsFillLockFill;
export const LINK_ICON = BiLink;
export const CHEVRON_DOWN_ICON = FiChevronDown;
export const GO_BACK_ICON = BiChevronLeft;
export const RIGHT_ICON = BiChevronRight;
export const SEARCH_ICON = BsSearch;
export const GROUPING_ICON = BsLayersFill;
export const CLOSE_ICON = FiX;
export const CHECK_ICON = FiCheck;
export const CONFIG_ICON = FaCog;
export const FILE_ICON = FiFile;
export const PLAY_ICON = BsFillPlayFill;
export const DOTS_HORIZONTAL = BiDotsHorizontalRounded;

export const CustomIcons = {
  TextIcon: (props?: IconProps) => (
    <Icon as={ATTRIBUTE_ICONS.text} {...props} />
  ),
  LocationIcon: (props?: IconProps) => (
    <Icon as={ATTRIBUTE_ICONS.location} {...props} />
  ),
  PictureIcon: (props?: IconProps) => (
    <Icon as={ATTRIBUTE_ICONS.picture} {...props} />
  ),
  DateIcon: (props?: IconProps) => (
    <Icon as={ATTRIBUTE_ICONS.date} {...props} />
  ),
  UserIcon: (props?: IconProps) => (
    <Icon as={ATTRIBUTE_ICONS.user} {...props} />
  ),
  UserCheckIcon: (props?: IconProps) => (
    <Icon as={ATTRIBUTE_ICONS.userCheck} {...props} />
  ),
  DragHandleIcon: (props?: IconProps) => <Icon as={DRAG_HANDLE} {...props} />,
  TrashIcon: (props?: IconProps) => <Icon as={TRASH_ICON} {...props} />,
  PencilIcon: (props?: IconProps) => <Icon as={PENCIL_ICON} {...props} />,
  AddIcon: (props?: IconProps) => <Icon as={ADD_ICON} {...props} />,
  LockIcon: (props?: IconProps) => <Icon as={LOCK_ICON} {...props} />,
  ChevronDownIcon: (props?: IconProps) => (
    <Icon as={CHEVRON_DOWN_ICON} {...props} />
  ),
  LinkIcon: (props?: IconProps) => <Icon as={LINK_ICON} {...props} />,
  GoBackIcon: (props?: IconProps) => <Icon as={GO_BACK_ICON} {...props} />,
  SearchIcon: (props?: IconProps) => <Icon as={SEARCH_ICON} {...props} />,
  GroupingIcon: (props?: IconProps) => <Icon as={GROUPING_ICON} {...props} />,
  CloseIcon: (props?: IconProps) => <Icon as={CLOSE_ICON} {...props} />,
  CheckIcon: (props?: IconProps) => <Icon as={CHECK_ICON} {...props} />,
  CheckboxIcon: (props?: IconProps) => (
    <Icon as={ATTRIBUTE_ICONS.checkbox} {...props} />
  ),
  ParagraphIcon: (props?: IconProps) => (
    <Icon as={ATTRIBUTE_ICONS.paragraph} {...props} />
  ),
  ConfigIcon: (props?: IconProps) => <Icon as={CONFIG_ICON} {...props} />,
  FileIcon: (props?: IconProps) => <Icon as={FILE_ICON} {...props} />,
  PlayIcon: (props?: IconProps) => <Icon as={PLAY_ICON} {...props} />,
  RightIcon: (props?: IconProps) => <Icon as={RIGHT_ICON} {...props} />,
  DotsHorizontal: (props?: IconProps) => (
    <Icon as={DOTS_HORIZONTAL} {...props} />
  ),
};
