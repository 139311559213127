import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import UnexpectedError from '@shared/domain/errors/UnexpectedError';
import { OptionData } from '../interfaces';

async function fetch(path: string, query?: string): Promise<OptionData[]> {
  let url = path;
  if (query) url += query;
  const response = await AxiosHelper.get({
    url,
  });
  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new UnexpectedError();
  }
  const data: OptionData[] = response.body;
  return data;
}

async function fetchOptions(
  column: string,
  query?: string,
): Promise<OptionData[]> {
  try {
    const options = await fetch(column, query);
    return options;
  } catch (err) {
    throw new UnexpectedError();
  }
}

export default fetchOptions;
