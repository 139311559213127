import { gql } from '@/gql';
import { useMutation } from '@apollo/client';

export const AutomationStepFragment = gql(`#graphql
  fragment AutomationStepFragment on Step {
    id
    lastModifiedAt
    name
    position
    stepType
    updatedAt
    deletedAt
    createdAt
    automationFlowId
    authorId
    position
    automationStepActions {
      updatedAt
      metadata
      lastModifiedAt
      id
      deletedAt
      createdAt
      automationStepId
      actionType
    }
  }
`);

const mutationCreateAutomationStep = gql(`#graphql
  mutation CreateAutomationStep($input: StepsCreateInput!) {
    createAutomationStep(input: $input) {
      errors
      automationStep {
        ...AutomationStepFragment
      }
    }
  }
`);

export function useCreateAutomationStep() {
  const [createAutoStep, { data, loading, error }] = useMutation(
    mutationCreateAutomationStep,
  );

  return {
    createAutoStep,
    loading,
    error,
    data: data?.createAutomationStep || null,
  };
}
