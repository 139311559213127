import config from '@/Constants';
import { ExporterServiceFactory, IExporterService } from '@/services/exporter';

function getExporter(screenName: string): IExporterService {
  const baseUrl = config.EXPORTER_URL;
  return ExporterServiceFactory.getInstance({
    baseUrl,
    exportType: 'xlsx',
    screen: screenName,
  });
}

export { getExporter };
