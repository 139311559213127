import {
  ExportTypeEnum,
  ImportTypeEnum,
} from '@/modules/shared/domain/interfaces';

export const notificationTopics = [
  'Discussion::Topic',
  'Discussion::Comment',
  'Discussion::Like',
  'EndSLA',
  'Import',
  'Export',
  'Event',
  'Answer',
  'default',
] as const;
export type NotificationsTopic = typeof notificationTopics[number];

export type LinkToProps = {
  id?: string;
  model?: ImportTypeEnum | ExportTypeEnum;
  url?: string;
  params?: any;
};
export interface NotificationData {
  originId?: string;
  originType?: NotificationsTopic;
  linkTo?: LinkToProps;
}

export interface NotificationsPayload {
  title: string;
  body: string;
}

export const notificationTypes = [
  'end_sla',
  'topic_comment',
  'topic_like',
  'topic_dislike',
  'export_finished',
  'import_finished',
  'event_finished',
  'event_started',
  'note',
] as const;
export type NotificationType = typeof notificationTypes[number];
export type NotificationActionType = 'success' | 'failure' | 'info';

interface BaseNotification {
  id: string;
  type: NotificationType;
  action: NotificationActionType;
  sentTime: number;
  notificationUserId: string;
  data: NotificationData;
}

type MessageNotificationBody = {
  user: {
    id: string;
    name: string;
    avatarUrl: string;
  };
  message: {
    messageKey: string;
    messageData: Record<string, any>;
  };
  title?: string;
};

type LinkNotificationBody = {
  message: {
    messageKey: string;
    messageData: Record<string, any>;
  };
  title?: string;
};

export interface MessageNotification extends BaseNotification {
  type: 'topic_dislike' | 'topic_like' | 'topic_comment';
  body: MessageNotificationBody;
}

export interface LinkNotification extends BaseNotification {
  type:
    | 'note'
    | 'end_sla'
    | 'export_finished'
    | 'import_finished'
    | 'event_finished'
    | 'event_started';
  body: LinkNotificationBody;
}

export type WCNotification = LinkNotification | MessageNotification;

function isLinkNotification(
  notification: WCNotification,
): notification is LinkNotification {
  return (
    (notification as LinkNotification).type === 'export_finished' ||
    (notification as LinkNotification).type === 'import_finished'
  );
}

export { isLinkNotification };

// Provider

export interface NotificationsContextData {
  pendingNotifications: number;
  setPendingNotificationsCount: (count: number) => void;
  getBodyText: (notification: WCNotification) => string;
  getOnClickFeedback: (notification: WCNotification) => () => void;
}

export interface NotificationsListProviderProps {
  read: boolean;
}
export interface NotificationsListContextData {
  notifications: WCNotification[];
  isValidating: boolean;
  read: boolean;
  addOrRemoveFilters: (type: NotificationType[]) => void;
  filters: NotificationType[];
  markAsRead: (notification: WCNotification) => void;
  readAll: () => void;
}

// Observer

export type TopicObserverCallback = (notification: WCNotification) => void;

export type TopicObservers = {
  [topic in NotificationsTopic]: Array<TopicObserverCallback>;
};
