import { Box, Button, Spinner } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

const getOs = () => {
  const os = ['Windows', 'Linux', 'Mac', 'iPhone', 'Android']; // add your OS values
  return os.find((v) => navigator.platform.indexOf(v) >= 0) || '';
};

const getfallbackLink = (os: string) => {
  switch (os) {
    case 'iPhone':
      return 'https://apps.apple.com/br/app/wecheck/id1547304093?l=en';

    case 'Linux':
    case 'Android':
      return 'https://play.google.com/store/apps/details?id=com.trackingtrade.wecheck';
    default:
      return 'https://wecheckapp.com.br/';
  }
};

export const DeeplinkScreen: React.FC = () => {
  const [loading, setLoading] = React.useState(getOs() === 'iPhone');
  const { search } = useLocation();

  const onOpenApp = React.useCallback(() => {
    const os = getOs();
    const fallbackLink = getfallbackLink(os);

    const query = new URLSearchParams(search);

    const url = query.get('callbackUrl');

    if (!url) throw new Error('Invalid URL');

    const valid = url.startsWith('wecheckapp://');

    if (!valid) throw new Error('Invalid URL');

    try {
      window.location.href = url;
    } catch {
      window.location.href = fallbackLink;
    } finally {
      setLoading(false);
    }
  }, [search]);

  React.useEffect(() => {
    if (getOs() === 'iPhone') {
      onOpenApp();
    }
  }, [onOpenApp]);

  return (
    <Box
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      w="full"
    >
      {loading ? (
        <Spinner size="lg" />
      ) : (
        <Button
          size="lg"
          variant="solid"
          colorScheme="brand"
          onClick={onOpenApp}
        >
          Abrir no WeCheck
        </Button>
      )}
    </Box>
  );
};
