export function Table({ children }: { children: React.ReactNode }) {
  return (
    <table
      style={{
        width: '100%',
        borderCollapse: 'collapse',
      }}
    >
      {children}
    </table>
  );
}

export function Td({ children }: { children: React.ReactNode }) {
  return (
    <td
      style={{
        width: '50%',
        border: '1px solid #cbd5e0',
        padding: '0.5rem',
        textAlign: 'start',
      }}
    >
      {children}
    </td>
  );
}
