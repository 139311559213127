import React, { useCallback } from 'react';

import { DownloadFileProps } from './interfaces';
import Main from './Main';

const DownloadFile: React.FC<DownloadFileProps> = (props) => {
  const { optionsUrl } = props;

  const onDownload = useCallback(() => {
    window.open(optionsUrl);
  }, [optionsUrl]);

  return <Main onDownload={onDownload} {...props} />;
};

export default DownloadFile;
