import { useModals } from '@/hooks/ModalManager';
import { useDeleteAutomationStep } from '@/screens/Tickets/hooks/useDeleteAutomationStep';
import { useReorderStep } from '@/screens/Tickets/hooks/useUpdateAutomationStep';
import { CustomIcons } from '@/screens/Tickets/utils/customIcons';
import { MenuItem } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStepActionsMenu } from '.';
import { useCreateStepForm } from '../../../Forms/CreateStep';

function EditStep() {
  const openForm = useCreateStepForm();
  const { step } = useStepActionsMenu();

  return (
    <MenuItem
      onClick={() => openForm({ stepId: step.id })}
      icon={<CustomIcons.ConfigIcon mx="1" fontSize="lg" />}
    >
      Configurar etapa
    </MenuItem>
  );
}

function AddTask() {
  return (
    <MenuItem
      icon={<CustomIcons.AddIcon fontSize="2xl" />}
      onClick={() => null}
    >
      Adicionar tarefa
    </MenuItem>
  );
}

function AddStep() {
  const openForm = useCreateStepForm();
  const { step } = useStepActionsMenu();

  return (
    <MenuItem
      icon={<CustomIcons.AddIcon fontSize="2xl" />}
      onClick={() =>
        openForm({
          position: step.position ? step.position + 1 : undefined,
        })
      }
    >
      Adicionar etapa à direita
    </MenuItem>
  );
}

function MoveStepLeft() {
  const { step } = useStepActionsMenu();
  const { reorder } = useReorderStep();
  return (
    <MenuItem
      icon={<CustomIcons.GoBackIcon fontSize="2xl" />}
      onClick={() => {
        reorder({ id: step.id, nextPosition: (step.position || 1) - 1 });
      }}
    >
      Mover etapa à esquerda
    </MenuItem>
  );
}

function MoveStepRight() {
  const { step } = useStepActionsMenu();
  const { reorder } = useReorderStep();
  return (
    <MenuItem
      icon={<CustomIcons.RightIcon fontSize="2xl" />}
      onClick={() => {
        reorder({ id: step.id, nextPosition: (step.position || 1) + 1 });
      }}
    >
      Mover etapa à direita
    </MenuItem>
  );
}

function DeleteStep() {
  const { step } = useStepActionsMenu();
  const { t } = useTranslation();
  const modals = useModals();
  const { deleteAutomationStep } = useDeleteAutomationStep();
  return (
    <MenuItem
      icon={<CustomIcons.TrashIcon fontSize="lg" />}
      onClick={() => {
        modals.confirm({
          title: t('error.warning'),
          body: t('messageDelete.ask'),
          onConfirm() {
            deleteAutomationStep(step.id);
          },
        });
      }}
    >
      Apagar etapa
    </MenuItem>
  );
}

export const ActionsOption = {
  EditStep,
  AddStep,
  MoveStepLeft,
  MoveStepRight,
  DeleteStep,
};
