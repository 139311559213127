import { useModals } from '@/hooks/ModalManager';
import { useSyncState } from '@/modules/WebForm/hooks/useSyncState';
import {
  Flex,
  Heading,
  HStack,
  Icon,
  VStack,
  Text,
  CloseButton,
  Badge,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { RiSurveyFill } from 'react-icons/ri';

const Header: React.FC<{
  formularyName?: string;
  taskName?: string;
  taskStatus?: 'pending' | 'draft' | 'done';
}> = ({ formularyName, taskStatus }) => {
  const { close, confirm } = useModals();
  const { lastPushAt, isPushing } = useSyncState();

  // const visibleFieldsCount = React.useMemo(
  //   () => Object.values(visibleFields).filter((e) => e).length,
  //   [visibleFields],
  // );

  // const answersCount = React.useMemo(
  //   () => Object.values(answers).filter((e) => e).length,
  //   [answers],
  // );

  const statusColor = React.useMemo(() => {
    switch (taskStatus) {
      case 'done':
        return 'green';
      case 'draft':
        return 'orange';
      case 'pending':
        return 'gray';
      default:
        return undefined;
    }
  }, [taskStatus]);

  return (
    <Flex
      position="sticky"
      top="0"
      zIndex="overlay"
      height={{ base: '12', sm: '20' }}
      shadow="md"
      bg="white"
      w="full"
      p="5"
      alignItems="center"
      justifyContent="flex-start"
    >
      <HStack w="full" justifyContent="space-between">
        <HStack alignItems="center" spacing="2">
          <Icon as={() => <RiSurveyFill size={30} />} />
          <VStack spacing="1" alignItems="start">
            <HStack>
              <Heading size="xs">{formularyName}</Heading>
              <Badge colorScheme={statusColor}>{taskStatus}</Badge>
            </HStack>
            {/* <HStack alignItems="center">
              <Text
                paddingStart="0"
                fontWeight="bold"
                fontSize="xs"
                color="gray.400"
              >
                Progresso: {answersCount} / {visibleFieldsCount}
              </Text>
              <Progress
                minW="40"
                hasStripe
                value={(answersCount / visibleFieldsCount) * 100}
                rounded="md"
              />
            </HStack> */}
            {isPushing && (
              <HStack>
                <Text fontSize="xs">Salvando...</Text>
              </HStack>
            )}
            {!isPushing && lastPushAt && (
              <HStack>
                <Text fontSize="xs">
                  Última sincronização {moment(lastPushAt).fromNow()}
                </Text>
              </HStack>
            )}
          </VStack>
        </HStack>

        <CloseButton
          onClick={() => {
            confirm({
              title: 'Tem certeza que deseja sair do checklist?',
              body:
                'Um rascunho será salvo, e você poderá continuar mais tarde.',
              onConfirm: () => close('task-checklist'),
              confirmProps: {
                colorScheme: 'brand',
              },
            });
          }}
        />
      </HStack>
    </Flex>
  );
};

export default Header;
