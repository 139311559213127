import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { makeMutationFetcher } from '@/utils/react-query-utils';
import snakeToCamel from '@/utils/snakeToCamel';
import React from 'react';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import {
  ITemplateTasksListType,
  TemplateTask,
  TemplateTaskCreateParams,
} from '../types';
import { templateTasksIndexKey } from '../utils';

/** Creates Template task and refetches index query */
export function useCreateTemplateTask(props?: UseMutationOptions) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    makeMutationFetcher<Partial<TemplateTaskCreateParams>, TemplateTask>(
      templateTasksIndexKey,
      'post',
    ),
    {
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries([templateTasksIndexKey]);
        props?.onSuccess?.(data, variables as any, context);
      },
    },
  );

  return mutation;
}

/** Updates Template task and refetches index query */
export function useUpdateTemplateTask(id: string, props?: UseMutationOptions) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    makeMutationFetcher<Partial<TemplateTaskCreateParams>, TemplateTask>(
      `${templateTasksIndexKey}/${id}`,
      'patch',
    ),
    {
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries([templateTasksIndexKey]);
        props?.onSuccess?.(data, variables as any, context);
      },
    },
  );

  return mutation;
}

/** Deletes Template tasks and removes from index query */
export function useDeleteTemplateTask() {
  const queryClient = useQueryClient();

  const mutation = useMutation(async (params: { id: string }) => {
    const response = await AxiosHelper.delete({
      url: `${templateTasksIndexKey}/${params.id}`,
    });

    if (response.statusCode < 200 || response.statusCode >= 300) {
      throw new UnexpectedError();
    }

    const body: any = snakeToCamel(response?.body);

    return { ...response, body };
  });

  const mutateAsync = React.useCallback(
    (id: string, props?: UseMutationOptions) => {
      return mutation.mutateAsync(
        { id },
        {
          ...(props as any),
          onSuccess: (data, variables, context) => {
            queryClient.setQueryData<ITemplateTasksListType[]>(
              [templateTasksIndexKey],
              (oldData = []) => {
                return oldData.filter((e) => e.id !== id);
              },
            );
            props?.onSuccess?.(data, variables as any, context);
          },
        },
      );
    },
    [mutation, queryClient],
  );

  return {
    ...mutation,
    mutateAsync,
  };
}
