import { FilterItem, FilterList } from '@/components_v2/Filtersv3/filter-item';
import { FilterCalendar } from '@/components_v2/Filtersv3/reactive-filter-calendar';
import { MacroColumnFilter, MacroTableFilter } from '@/gql/types';
import { useFetchOptions } from '@/hooks/useFetchOptions';
import React from 'react';

export default function Filters() {
  const statusQuery = useFetchOptions({
    column: MacroColumnFilter.Status,
    table: MacroTableFilter.AutomationTickets,
    filters: {},
  });

  const usersQuery = useFetchOptions({
    column: MacroColumnFilter.FullName,
    table: MacroTableFilter.Users,
    first: 50,
    filters: {},
  });

  const localsQuery = useFetchOptions({
    column: MacroColumnFilter.Name,
    table: MacroTableFilter.Locals,
    first: 50,
    filters: {},
  });

  const onSearchUserNameFilter = React.useCallback((search: string) => {
    usersQuery.refetch({
      search: search,
    });
  }, []);

  const onSearchLocalNameFilter = React.useCallback((search: string) => {
    localsQuery.refetch({
      search: search,
    });
  }, []);

  const fetchMoreUserNameFilter = React.useCallback(() => {
    usersQuery.fetchMore({
      variables: {
        after:
          usersQuery.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          macroFilters: {
            ...fetchMoreResult.macroFilters,
            edges: [
              ...prev.macroFilters.edges,
              ...fetchMoreResult.macroFilters.edges,
            ],
          },
        };
      },
    });
  }, [usersQuery]);

  const fetchMoreLocalNameFilter = React.useCallback(() => {
    localsQuery.fetchMore({
      variables: {
        after:
          localsQuery.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          macroFilters: {
            ...fetchMoreResult.macroFilters,
            edges: [
              ...prev.macroFilters.edges,
              ...fetchMoreResult.macroFilters.edges,
            ],
          },
        };
      },
    });
  }, [localsQuery]);

  return (
    <FilterList>
      <FilterItem
        label={'Status'}
        filterKey={'status'}
        loading={statusQuery.loading}
        options={statusQuery.options}
      />
      <FilterItem
        label={'Usuário'}
        filterKey={'full_name'}
        loading={usersQuery.loading}
        options={usersQuery.options}
        onSearch={onSearchUserNameFilter}
        hasMore={usersQuery.pageInfo?.hasNextPage}
        fetchMore={fetchMoreUserNameFilter}
      />
      <FilterItem
        label={'Local'}
        filterKey={'name'}
        loading={localsQuery.loading}
        options={localsQuery.options}
        onSearch={onSearchLocalNameFilter}
        hasMore={localsQuery.pageInfo?.hasNextPage}
        fetchMore={fetchMoreLocalNameFilter}
      />
      <FilterCalendar label={'Data de criação'} filterKey={'created_at'} />
    </FilterList>
  );
}
