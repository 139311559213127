import { AnswerStateMap, IFormState } from '../interfaces';

/** remove answer with undefined value or not visible */
export function invalidateFields(state: IFormState): AnswerStateMap {
  const { visibleFields, answers } = state;

  return Object.entries(visibleFields).reduce((prev, [k, v]) => {
    if (v && answers[k]) {
      return {
        ...prev,
        [k]: answers[k],
      };
    }

    return prev;
  }, {} as AnswerStateMap);
}
