import WCDatePicker from '@/components_v2/WCDatePicker';
import { parseDateToDay } from '@/utils/Date/parseDayDate';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';

import { QuestionTitle } from '../common';
import { DatePickerProps } from './interfaces';

const DatePicker: React.FC<DatePickerProps> = ({
  question,
  errors,
  initialValues,
  onChange,
  type,
  viewOnly,
}) => {
  const disabled = !!viewOnly;
  const [date, setDate] = useState<[Date | null, Date | null] | undefined>(
    initialValues?.values,
  );

  const handleChangeDate = useCallback(
    (newDate: [Date | null, Date | null]) => {
      if (type === 'date_range') {
        onChange(newDate);
      } else if (newDate[0]) {
        onChange(parseDateToDay(newDate[0]));
      }
      setDate(newDate);
    },
    [onChange, type],
  );

  return (
    <FormControl isInvalid={!!errors?.errors}>
      <QuestionTitle>{question.name}</QuestionTitle>
      <WCDatePicker
        onChange={handleChangeDate}
        selected={date?.[0]}
        startDate={date?.[0]}
        endDate={date?.[1]}
        disabled={disabled}
        selectsRange={type === 'date_range'}
      />
      <FormHelperText>{question.tipText}</FormHelperText>
      <FormErrorMessage>{errors && errors.errors}</FormErrorMessage>
    </FormControl>
  );
};

export default DatePicker;
