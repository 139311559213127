import { ButtonIcon, ButtonIconEnum } from '@components/Button/interfaces';
import React from 'react';
import {
  FiCheck,
  FiChevronLeft,
  FiCopy,
  FiDownload,
  FiEdit,
  FiFileText,
  FiImage,
  FiShare2,
  FiTrash2,
  FiUpload,
  FiX,
} from 'react-icons/fi';
import styled from 'styled-components';

export const BackIcon = styled(FiChevronLeft)`
  border-radius: 50%;
  color: ${(props) => props.theme.colors.lighterText};
  background-color: ${(props) => props.theme.colors.primary};
`;

const renderIcon = (
  Icon: ButtonIcon | undefined,
  color: string,
  size: number,
): JSX.Element | null => {
  switch (Icon) {
    case ButtonIconEnum.CLOSE:
      return <FiX color={color} size={size} />;
    case ButtonIconEnum.DELETE:
      return <FiTrash2 color={color} size={size} />;
    case ButtonIconEnum.DEPENDENCE:
      return <FiShare2 color={color} size={size} />;
    case ButtonIconEnum.DONE:
      return <FiCheck color={color} size={size} />;
    case ButtonIconEnum.GO_BACK:
      return <BackIcon color={color} size={size} />;
    case ButtonIconEnum.DUPLICATE:
      return <FiCopy color={color} size={size} />;
    case ButtonIconEnum.IMAGE:
      return <FiImage color={color} size={size} />;
    case ButtonIconEnum.EDIT:
      return <FiEdit color={color} size={size} />;
    case ButtonIconEnum.FILE:
      return <FiFileText color={color} size={size} />;
    case ButtonIconEnum.DOWNLOAD:
      return <FiDownload color={color} size={size} />;
    case ButtonIconEnum.UPLOAD:
      return <FiUpload color={color} size={size} />;
    default:
      return null;
  }
};

export { renderIcon };
