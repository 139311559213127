import i18n from '@/i18n';
import { EventStatusType } from '@/modules/core/domain/interfaces';
import { Tag, TagLabel } from '@chakra-ui/react';
import React from 'react';
import { statusCorrelation } from '../utils';

// import { Container } from './styles';

const StatusTag: React.FC<{ status: EventStatusType }> = ({ status }) => {
  const { bg, color, label } = statusCorrelation[status];
  return (
    <Tag rounded="md" size="md" borderColor={color} bgColor={bg}>
      <TagLabel fontWeight="semibold" fontSize="13px" color={color}>
        {i18n.t(label)}
      </TagLabel>
    </Tag>
  );
};

export default StatusTag;
