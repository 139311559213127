import { ImportStatus, ImportTypes } from '@/gql/graphql';
import {
  Button,
  Collapse,
  Flex,
  HStack,
  Progress,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

import { useImportItem } from '../hooks/useImportItem';
import { FileTransferItem, KVItem, KVTable } from './FileTransferItem';

function Body() {
  const {
    importItem: { author },
  } = useImportItem();

  return (
    <KVTable>
      <KVItem label="Requisitado por:" value={author?.fullName || ''} />
    </KVTable>
  );
}

function Footer() {
  const [collapsed, setCollapsed] = React.useState(true);
  const {
    importItem: {
      progress,
      status,
      arrayErrors,
      fileTemporaryUrl,
      importWithErrors,
    },
  } = useImportItem();

  return (
    <VStack w="full">
      <HStack w="full" px="2" py="1">
        <Flex gap="2" flex={1}>
          <Button
            onClick={() => {
              window.open(`${fileTemporaryUrl}`);
            }}
            size="sm"
            variant="outline"
          >
            Baixar arquivo
          </Button>
          {!!importWithErrors && (
            <Button
              onClick={() => setCollapsed((old) => !old)}
              size="sm"
              colorScheme="red"
            >
              Ver Erros
            </Button>
          )}
        </Flex>
        <Flex flex="0.5">
          {[
            ImportStatus.Processing,
            ImportStatus.Pending,
            ImportStatus.Validating,
          ].includes(status!) && (
            <Progress
              borderWidth="thin"
              w="full"
              size="lg"
              rounded="md"
              value={progress || 0}
              isIndeterminate={[
                ImportStatus.Pending,
                ImportStatus.Validating,
              ].includes(status!)}
            />
          )}
        </Flex>
      </HStack>
      <Collapse style={{ width: '100%' }} in={!collapsed}>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Problema</Th>
                <Th>Coluna</Th>
                <Th isNumeric>Linha</Th>
              </Tr>
            </Thead>
            <Tbody>
              {arrayErrors.errors?.map(
                ({
                  line,
                  column,
                  problem,
                }: {
                  problem: string;
                  column: string;
                  line: number;
                }) => (
                  <Tr>
                    <Td>{problem}</Td>
                    <Td>{column}</Td>
                    <Td isNumeric>{line}</Td>
                  </Tr>
                ),
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Collapse>
    </VStack>
  );
}

export function ImportItem() {
  //
  const { importItem } = useImportItem();

  const { importType, createdAt, status } = importItem;

  const title = React.useMemo(() => {
    switch (importType) {
      case ImportTypes.Collection:
        return 'Importação de Coleções';
      case ImportTypes.Formulary:
        return 'Importação de Formulários';
      case ImportTypes.Local:
        return 'Importação de Locais';
      case ImportTypes.Schedule:
        return 'Importação de Agendamentos';
      case ImportTypes.Task:
        return 'Importação de Tarefas';
      case ImportTypes.User:
        return 'Importação de Usuários';
      case ImportTypes.Ticket:
        return 'Importação de Tickets';
      default:
        return '';
    }
  }, [importType]);

  const parsedDate = moment(createdAt).format('LLLL');

  return (
    <FileTransferItem
      title={title}
      subtitle={parsedDate}
      status={status || 'pending'}
      body={<Body />}
      footer={<Footer />}
    />
  );
}
