import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

const CenterLoading: React.FC = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      position="relative"
      h="full"
      w="full"
      flex="1"
    >
      <Spinner size="lg" />
    </Flex>
  );
};

export default CenterLoading;
