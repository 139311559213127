import PopoverAccessSettings from '@/components_v2/inputs/PopoverAccessSettings';
import Tooltip from '@components/Tooltip';
import React from 'react';

import {
  InfoTooltip,
  QuestionContainer,
  QuestionHeader,
  QuestionSubtitle,
  QuestionTitle,
  TooltipContainer,
} from '../common';
import { AccessSettingsProps } from './interfaces';

const AccessSettings: React.FC<AccessSettingsProps> = ({
  question,
  errors,
  initialValues,
  onChange,
  viewOnly,
}) => {
  const disabled = !!viewOnly;
  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionTitle>{question.name}</QuestionTitle>
        {question.tipText && (
          <Tooltip description={question.tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      <PopoverAccessSettings
        disabled={disabled}
        teamsUrl={question.config?.teamsUrl as string}
        usersUrl={question.config?.usersUrl as string}
        onChange={onChange}
        initialValues={initialValues?.values}
      />
      <QuestionSubtitle hasError={!!errors}>
        {errors && errors.errors}
      </QuestionSubtitle>
    </QuestionContainer>
  );
};

export default AccessSettings;
