import { getContrastColor } from '@/utils/colors/getContrastColor';
import { Tab, TabList, Tabs } from '@chakra-ui/react';
import React from 'react';

import { TabSelectorRenderProps } from './TabSelector.types';

function TabSelectorIos<T extends string = string>({
  initialKey,
  onChange,
  tabs,
  isDisabled,
  tabsComponentProps,
}: TabSelectorRenderProps<T>): JSX.Element {
  const [index, setIndex] = React.useState(() => {
    if (initialKey) return tabs.findIndex((tab) => tab.key === initialKey);
    return 0;
  });

  const handleChange = React.useCallback(
    (newIndex: number) => {
      setIndex(newIndex);
      onChange(tabs[newIndex].key, newIndex);
    },
    [onChange, tabs],
  );

  return (
    <Tabs
      variant="unstyled"
      index={index}
      onChange={handleChange}
      {...tabsComponentProps}
    >
      <TabList
        bg="gray.100"
        borderRadius="6px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="gray.200"
        py={1}
      >
        {tabs.map((tab) => {
          return (
            <Tab
              mx={1}
              py={1}
              px={4}
              whiteSpace="nowrap"
              isDisabled={tabs.length === 1 || isDisabled}
              _selected={{
                bg: tab.color || 'white',
                borderRadius: '6px',
                color: getContrastColor(tab.color || '#ffffff'),
              }}
              _focus={{ boxShadow: 'none' }}
              outline="none"
            >
              {tab.icon}
              {tab.label}
            </Tab>
          );
        })}
      </TabList>
    </Tabs>
  );
}

export default TabSelectorIos;
