import styled from 'styled-components';
import makeAnimated from 'react-select/animated';

makeAnimated();

export const DropdownWrapper = styled.div<{ hasError?: boolean }>`
  font-size: 12px;

  .simple_dropdown__control {
    min-height: 0;
    width: 100%;
    padding: 6px 10px;
    border: 0;
    box-shadow: none;
    border-radius: 5px;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.v2_secondary};

    font-family: ${({ theme }) => theme.fonts.primaryFont};
    color: ${({ theme }) => theme.colors.darkText};
    font-size: 12px;
    font-weight: 400;

    ${({ theme, hasError }) => {
      if (hasError) return `box-shadow: 0 0 0 1px ${theme.colors.error};`;
      return null;
    }}

    transition: 300ms;
    :hover {
      ${({ theme, hasError }) => {
        if (hasError) return `box-shadow: 0 0 0 1px ${theme.colors.error};`;
        return `box-shadow: 0 0 0 1px ${theme.colors.primary};`;
      }}
      background-color: ${({ theme }) => theme.colors.lighterText};

      .simple_dropdown__multi-value__remove {
        transition: fill 1000ms ease;
        & > svg {
          fill: hsl(0, 0%, 40%);
        }
      }
    }
  }
  .simple_dropdown__control--is-focused {
    ${({ theme, hasError }) => {
      if (hasError) return `box-shadow: 0 0 0 1px ${theme.colors.error};`;
      return `box-shadow: 0 0 0 1px ${theme.colors.primary};`;
    }}
    background-color: ${({ theme }) => theme.colors.lighterText};

    .simple_dropdown__multi-value__remove {
      transition: fill 200ms ease;
      & > svg {
        fill: hsl(0, 0%, 40%);
      }
    }
  }
  .simple_dropdown__value-container {
    padding: 1px 0px;
    & > * {
      padding: 0;
      border: 0;
      margin: 0;
    }
  }
  .simple_dropdown__menu {
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    color: ${({ theme }) => theme.colors.darkText};
    font-size: 12px;
    font-weight: 400;
  }
  .simple_dropdown__input {
    & > input {
      font-family: ${({ theme }) => theme.fonts.primaryFont};
      color: ${({ theme }) => theme.colors.darkText};
      font-size: 12px;
      font-weight: 400;
    }
  }
  .simple_dropdown__option--is-focused {
    background-color: ${({ theme }) => theme.colors.v2_secondary};
  }
  .simple_dropdown__option--is-selected {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .simple_dropdown__indicators {
    padding: 4.2px 0px;
  }
  .simple_dropdown__indicator-separator {
    display: none;
  }
  .simple_dropdown__dropdown-indicator {
    padding: 0px;
  }
  .simple_dropdown__multi-value {
    margin: 0px 2px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.v2_secondary};
  }
  .simple_dropdown__clear-indicator {
    padding: 0px 1px 0px 0px;
  }
  .simple_dropdown__multi-value__remove {
    cursor: pointer;
    padding: 0px 3px 0px 0px;
    & > svg {
      fill: hsl(0, 0%, 80%);
    }
    :hover {
      background-color: transparent;
      opacity: 0.7;
    }
  }
  svg {
    height: 14px;
    width: 14px;
  }
`;
