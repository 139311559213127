import { OptionData } from '@hooks/Filters/interfaces';
import {
  INPUT_HEIGHT,
  ITEM_MARGIN,
} from '@/components_v2/ContextMenu/constants';

export const CHECK_ALL_KEY = 'all';
export const checkAllOption: OptionData = {
  id: CHECK_ALL_KEY,
  label: CHECK_ALL_KEY,
};
export const CONTAINER_HEIGHT = 320;
export const MAX_LIST_HEIGHT = CONTAINER_HEIGHT - INPUT_HEIGHT - ITEM_MARGIN;
export const MINIMUM_ITEMS_BREAKPOINT = 5;
