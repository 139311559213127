import { useNavigation } from '@hooks/navigation';
import React from 'react';

import { MainMenuBarProps } from '../../interfaces';
import Main from './Main';

const MainMenuBar: React.FC<MainMenuBarProps> = ({
  mainSelected,
  mainList,
  setMainSelected,
  isMainMenuOpen,
  setSubList,
}) => {
  const { getMainRoute } = useNavigation();

  const selected = React.useMemo(() => {
    if (mainSelected.length > 1) return mainSelected;
    const route = getMainRoute();
    if (route) return route.name;
    return '';
  }, [getMainRoute, mainSelected]);

  return (
    <Main
      isMainMenuOpen={isMainMenuOpen}
      list={mainList}
      selected={selected}
      setSelected={setMainSelected}
      setSubList={setSubList}
    />
  );
};

export default MainMenuBar;
