import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TopicComment } from './interfaces';
import { deleteTopicComment } from './services/deleteTopicComment';
import { discussionTopicCommentsKey } from './useFeedbackCommentsQuery';
import { discussionTopicsKey } from './useFeedbacksQuery';

type DeleteTopicCommentParams = {
  topicId: string;
  commentId: string;
};

export function useDeleteTopicComment() {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteTopicComment);

  const mutateAsync = React.useCallback(
    async (params: DeleteTopicCommentParams) => {
      const { commentId, topicId } = params;

      const result = await mutation.mutateAsync({
        id: commentId,
      });

      if (result) {
        queryClient.setQueryData<TopicComment[]>(
          [discussionTopicCommentsKey, topicId],
          (old = []) => old.filter((e) => e.id !== commentId),
        );
        queryClient.invalidateQueries([discussionTopicsKey]);
      }

      return result;
    },
    [mutation, queryClient],
  );

  return {
    ...mutation,
    mutateAsync,
  };
}
