import { useWorkspace } from '@hooks/workspace';
import { SignIn, Workspace } from '@screens/Authentication';
import React from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import PasswordRecovery from './groups/passwordRecovery.routes';

const AuthRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();
  const { validWorkspace, loading: isLoading } = useWorkspace();
  const { state } = useLocation();

  if (isLoading) return null;

  return (
    <Switch>
      <Route path={`${path}/workspace`} component={Workspace} />
      <Route path={`${path}/signin`} component={SignIn} />
      <Route path={`${path}/recovery`} component={PasswordRecovery} />
      <Redirect
        to={{
          pathname: validWorkspace ? `${path}/signin` : `${path}/workspace`,
          state: state?.from ? { from: state?.from } : undefined,
        }}
      />
    </Switch>
  );
};

export default AuthRoutes;
