import { FormularyAnswers } from '@/modules/core/domain/interfaces/IFormulary';
import { ApiObject } from '@/modules/shared/domain/interfaces';
import flattenObject from '@/utils/flattenObject';
import LegacyApiFieldsAdapter from './LegacyApiFieldsAdapter';

/**
 * LEGACY IMPLEMENTATION
 */
const LegacyFormValuesMapper = (answers: FormularyAnswers): ApiObject => {
  const apiAnswers: ApiObject = flattenObject(
    LegacyApiFieldsAdapter.reduceAnswers(answers),
  ) as ApiObject;
  return apiAnswers;
};
export default LegacyFormValuesMapper;
