import React from 'react';
import Main from './Main';

export interface NavbarProps {
  setMainMenuOpen: (state: boolean) => void;
  isMainMenuOpen: boolean;
  menuDisabled: boolean;
}

const Navbar: React.FC<NavbarProps> = ({
  setMainMenuOpen,
  isMainMenuOpen,
  menuDisabled = false,
}) => {
  return (
    <Main
      menuDisabled={menuDisabled}
      setMainMenuOpen={setMainMenuOpen}
      isMainMenuOpen={isMainMenuOpen}
    />
  );
};

export default Navbar;
