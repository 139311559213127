import {
  Box,
  Button,
  Container,
  Heading,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { BiPlus } from 'react-icons/bi';

import { useCreateStepForm } from './Forms/CreateStep';

export const CtaCard = ({ id }: { id: string }) => {
  const openForm = useCreateStepForm();

  return (
    <Box
      as="section"
      bg="white"
      w="fit-content"
      borderRadius={15}
      px={20}
      m="auto"
    >
      <Container py={{ base: '16', md: '24' }}>
        <Stack spacing={{ base: '8', md: '10' }}>
          <Stack spacing={{ base: '4', md: '5' }} align="center">
            <Heading fontSize="2xl">Sem fluxo configurado</Heading>
            <Text color="muted" maxW="2xl" textAlign="center" fontSize="xl">
              Este fluxo ainda não contém configuração.
            </Text>
          </Stack>
          <Stack
            spacing="3"
            direction={{ base: 'column', sm: 'row' }}
            justify="center"
          >
            <Button
              size="lg"
              variant="solid"
              colorScheme="brand"
              onMouseDown={() => openForm({ stepId: id })}
              rightIcon={<Icon as={() => <BiPlus fontSize="16px" />} />}
            >
              Iniciar configuração
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
