import { Field, FormLayout, HookForm } from '@/components_v2/HookForm';
import { useModals } from '@/hooks/ModalManager';
import { useCreateAutomationGroup } from '@/screens/Tickets/hooks/useCreateAutomationGroup';
import { AutomationGroupItem } from '@/screens/Tickets/hooks/useFetchAutomationGroups';
import { useUpdateAutomationGroup } from '@/screens/Tickets/hooks/useUpdateAutomationGroup';
import { Box, Button, ButtonGroup, Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';

const groupFormSchema = yup
  .object({
    name: yup
      .string()
      .min(2, 'Mínimo de dois caracteres')
      .required('Nome é obrigatório'),
  })
  .required();

type GroupFormSchema = yup.InferType<typeof groupFormSchema>;

const WrappedForm = (props: { groupItem?: AutomationGroupItem }) => {
  const { groupItem } = props;
  const modals = useModals();
  const { createAutoGroup, loading } = useCreateAutomationGroup();
  const {
    updateAutomationGroup,
    loading: updating,
  } = useUpdateAutomationGroup();

  const onClose = React.useCallback(
    () => modals.close('create-automation-group-modal'),
    [modals],
  );

  const handleSubmit = React.useCallback(
    async (values: GroupFormSchema) => {
      let hasError = false;
      if (groupItem?.id) {
        const res = await updateAutomationGroup({
          id: groupItem?.id,
          name: values.name,
        });
        hasError = !!res.errors?.length;
      } else {
        const res = await createAutoGroup({
          name: values.name,
        });
        hasError = !!res.errors?.length;
      }

      if (!hasError) {
        onClose();
      }
    },
    [createAutoGroup, groupItem?.id, onClose, updateAutomationGroup],
  );

  const isLoading = updating || loading;

  return (
    <fieldset disabled={isLoading}>
      <HookForm<GroupFormSchema>
        px="6"
        pb="4"
        autoComplete="off"
        id="step-form"
        resolver={yupResolver(groupFormSchema)}
        schema={groupFormSchema}
        defaultValues={{
          name: groupItem?.name || '',
        }}
        onSubmit={handleSubmit}
      >
        <FormLayout>
          <Field<GroupFormSchema> name="name" label="Etiqueta" />
        </FormLayout>

        <Flex justifyContent="flex-end">
          <ButtonGroup mt="4">
            <Button
              isDisabled={isLoading}
              variant="outline"
              onClick={() => {
                modals.close('create-automation-group-modal');
              }}
            >
              Cancelar
            </Button>
            <Button isLoading={isLoading} type="submit" colorScheme="primary">
              {groupItem?.id ? 'Editar' : 'Criar'}
            </Button>
          </ButtonGroup>
        </Flex>
      </HookForm>
    </fieldset>
  );
};

export function useCreateFormAutomationGroupModal() {
  const modals = useModals();

  const openForm = React.useCallback(
    (groupItem?: AutomationGroupItem) => {
      modals.open({
        id: 'create-automation-group-modal',
        title: groupItem?.id
          ? 'Editar grupo de automações'
          : 'Criar novo grupo de automações',
        body: (
          <Box flex="1">
            <WrappedForm groupItem={groupItem} />
          </Box>
        ),
      });
    },
    [modals],
  );

  return { openForm };
}
