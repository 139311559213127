import { useTheme } from '@hooks/theme';
import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

import { LoadingProps } from './localGeneric';
import { Container } from './styles';

const Loading: React.FC<LoadingProps> = ({ isContained, size, color }) => {
  const { colors } = useTheme();

  return (
    <Container isContained={isContained}>
      <SyncLoader size={size || 20} color={color || colors.primary} loading />
    </Container>
  );
};

const renderLoading = (props?: LoadingProps): JSX.Element => {
  return <Loading {...props} />;
};

export default Loading;
export { renderLoading };
