import { useAuth } from '@/hooks/Authentication/auth';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import React from 'react';

import { createApolloClient } from '../utils';

function useApolloWrapper() {
  const { token } = useAuth();
  const [client, setApolloClient] = React.useState<ApolloClient<
    NormalizedCacheObject
  > | null>(null);

  const init = React.useCallback(async () => {
    const client = createApolloClient();
    setApolloClient(client);
  }, []);

  React.useEffect(() => {
    init();
  }, [init, token]);

  return { client };
}

export function ApolloWrapper({ children }: { children: React.ReactNode }) {
  const { client } = useApolloWrapper();

  if (!client) return null;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
