import config from '@/Constants';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { Task } from '@/screens/registrations/Tasks/interfaces';

async function createTaskService(task: Omit<Task, 'id'>): Promise<boolean> {
  const { statusCode } = await AxiosHelper.post({
    url: config.TASKS_URL,
    body: task,
  });
  if (statusCode < 200 || statusCode > 300) {
    throw new UnexpectedError();
  }
  return true;
}

export { createTaskService };
