/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { ArrowDownIcon, ArrowUpIcon, CloseIcon } from '@chakra-ui/icons';
import { ButtonGroup, IconButton } from '@chakra-ui/react';
import React from 'react';

import { StatusSelect } from '../monitoring/Feedbacks/StatusSelect';
import { useJustificationsContext } from './hooks/context';
import {
  JustificationContext,
  useCurrentJustification,
} from './hooks/useCurrentJustification';
import { JustificationDisplay } from './JustificationDisplay';

const JustificationView: React.FC = () => {
  const {
    selectJustification,
    currentIndex,
    getPrev,
    getNext,
    refetch,
  } = useJustificationsContext();
  const { item } = useCurrentJustification();

  const topicId = item?.topics?.[0]?.id;

  return (
    <JustificationDisplay
      renderHeader={() => (
        <>
          <ButtonGroup size="md" variant="outline">
            <IconButton
              icon={<ArrowUpIcon />}
              aria-label=""
              disabled={currentIndex === 0}
              onClick={getPrev}
            />
            <IconButton
              icon={<ArrowDownIcon />}
              aria-label=""
              onClick={getNext}
            />
          </ButtonGroup>
          <ButtonGroup size="md" variant="outline">
            {topicId && (
              <StatusSelect
                key={topicId}
                feedback={
                  {
                    id: topicId,
                    statusType: item.topics?.[0]?.statusType,
                  } as DiscussionTopic
                }
                callback={refetch}
              />
            )}
            <IconButton
              icon={<CloseIcon />}
              aria-label="close"
              onClick={() => selectJustification('')}
            />
          </ButtonGroup>
        </>
      )}
      id=""
    />
  );
};

export default () => {
  const { selectedJustificationId, items } = useJustificationsContext();

  const currentJustification = items?.find(
    (item) => item.id === selectedJustificationId,
  );

  if (!selectedJustificationId) return null;

  return (
    <JustificationContext
      value={{
        item: currentJustification,
      }}
    >
      <JustificationView />
    </JustificationContext>
  );
};
