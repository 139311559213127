import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Input } from '@chakra-ui/react';
import { colors } from '@/styles/theme';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@hooks/Authentication/auth';
import { FeedbackInfo } from '@/components/Feedback';
import { HttpStatusCode } from '@/modules/shared/data/protocols/http';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import config from '@/Constants';
import {
  CommonContainer,
  Title,
  Description,
  SubmitButton,
} from './components';

const AccessReactivation: React.FC = () => {
  const [validationCode, setValidationCode] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const { replace } = useHistory();
  const { signOut } = useAuth();
  const { t } = useTranslation();

  const sendEmail = useCallback(async () => {
    setLoading(true);

    await AxiosHelper.post({
      url: `${config.DASHBOARD_USERS_URL}/resets`,
    });

    FeedbackInfo({
      mainText: t('access_reactivation.validation_code'),
      subText: t('access_reactivation.send_code'),
    });

    setLoading(false);
  }, [t]);

  useEffect(() => {
    sendEmail();
  }, [sendEmail, t]);

  const handleValidationCodeChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const token: string = event.currentTarget.value;
      setValidationCode(token);
    },
    [],
  );

  const handleSubmitCode = useCallback(async () => {
    setLoading(true);

    const { body, statusCode } = await AxiosHelper.post({
      url: `${config.DASHBOARD_USERS_URL}/resets/confirm_token`,
      body: {
        token: validationCode,
      },
    });

    setLoading(false);

    if (statusCode === HttpStatusCode.ok) {
      return replace('/app/dashboard');
    }

    FeedbackInfo({
      mainText: t('error.warning'),
      subText: body?.error
        ? t(`access_reactivation.errors.${body.error}`)
        : t('error.unexpectedError'),
    });
  }, [replace, t, validationCode]);

  return (
    <CommonContainer>
      <Title content={t('access_reactivation.title')} />
      <Description content={t('access_reactivation.description')} />
      <Input
        variant="auth"
        value={validationCode}
        placeholder={t('access_reactivation.validation_code')}
        autoCapitalize="none"
        onChange={handleValidationCodeChange}
        margin={0}
        size="lg"
      />
      <SubmitButton
        buttonContent={t('buttons.confirm')}
        callback={handleSubmitCode}
        style={{ margin: 0, marginTop: 15, backgroundColor: colors.primary }}
        isLoading={isLoading}
      />
      <SubmitButton
        buttonContent={t('access_reactivation.code_resend')}
        callback={sendEmail}
        style={{ margin: 0, marginTop: 20, backgroundColor: colors.lightGrey }}
      />
      <SubmitButton
        buttonContent={t('access_reactivation.exit')}
        callback={signOut}
        style={{ margin: 0, marginTop: 20, backgroundColor: colors.lightGrey }}
      />
    </CommonContainer>
  );
};

export default AccessReactivation;
