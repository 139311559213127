import { AttachmentItem } from '@/components_v2/AttachmentItem/AttachmentItem';
import { Dropzone } from '@/components_v2/Dropzone';
import { useModals } from '@/hooks/ModalManager';
import { CreateAttachmentParams } from '@/modules/attachments/services';
import { ItemAttachment } from '@/modules/core/domain/interfaces/ItemAttachment';
import { Flex, List, ListItem, Spinner } from '@chakra-ui/react';
import { BatchItem, FILE_STATES } from '@rpldy/shared';
import {
  useBatchAddListener,
  useItemFinishListener,
  useUploady,
} from '@rpldy/uploady';
import React from 'react';

export type UseQuestionAttachmentUploader = () => {
  onDelete: (id: string) => void;
  onCreate: (
    payload: Omit<CreateAttachmentParams, 'itemId' | 'questionId'>,
  ) => Promise<void>;
  onUploadStart: () => void;
  items: ItemAttachment[];
  isLoading: boolean;
};

type QuestionAttachmentsProps = {
  useUploader: UseQuestionAttachmentUploader;
};

export const QuestionAttachments: React.FC<QuestionAttachmentsProps> = ({
  useUploader,
}) => {
  const modals = useModals();

  const { items, isLoading, onCreate, onDelete, onUploadStart } = useUploader();

  const uploady = useUploady();

  const [files, setFiles] = React.useState<BatchItem[]>([]);

  useBatchAddListener((batch) => {
    setFiles((items) => items.concat(batch.items));
  });

  useItemFinishListener(async (item) => {
    if (item.state !== FILE_STATES.FINISHED) return;

    const fileExt = item.file.name.split('.').pop();
    await onCreate({
      bytes: item.file.size,
      name: item.file.name.replace(/ /g, '_'),
      extension: fileExt || '',
      mimeType: item.file.type,
      failCount: 0,
      localUrl: '',
      remoteUrl: `${item.uploadResponse}`,
      status: 'done',
    });
    setFiles((old) => old.filter((f) => f.id !== item.id));
  });

  const confirmDelete = React.useCallback(
    async (id: string) => {
      modals.confirm({
        title: 'Tem certeza que deseja deletar este anexo?',
        body: 'A ação não pode ser desfeita',
        portalProps: {
          appendToParentPortal: false,
        },
        onConfirm: () => {
          onDelete(id);
        },
      });
    },
    [modals, onDelete],
  );

  const renderUploadingItem = React.useCallback((file: BatchItem) => {
    return (
      <ListItem key={file.id}>
        <AttachmentItem
          bytes={file.file.size}
          id={file.id}
          extension={file.file.type}
          isUploading
          name={file.file.name}
          mimeType={file.file.type}
          url={URL.createObjectURL(file.file as File)}
        />
      </ListItem>
    );
  }, []);

  const renderItem = React.useCallback(
    (item: ItemAttachment) => {
      return (
        <ListItem key={item.id}>
          <a href={item.remoteUrl} target="_blank" rel="noopener noreferrer">
            <AttachmentItem
              flexProps={{ bg: 'white' }}
              bytes={item.bytes}
              id={item.id}
              extension={item.extension}
              name={item.name}
              mimeType={item.mimeType}
              onDelete={() => confirmDelete(item.id)}
              url={item.remoteUrl}
            />
          </a>
        </ListItem>
      );
    },
    [confirmDelete],
  );

  const handleDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      uploady.upload(acceptedFiles);
      onUploadStart?.();
    },
    [onUploadStart, uploady],
  );

  const uploadingItems = React.useMemo(
    () => files.filter((e) => e.state !== FILE_STATES.FINISHED),
    [files],
  );

  return (
    <Flex p="1" w="full" flexDir="column" gap="4">
      {isLoading && <Spinner m="5" mx="auto" />}
      <Dropzone
        onDrop={handleDrop}
        maxFiles={5}
        placeholderProps={{
          title: 'Arraste e solte arquivos aqui',
          subtitle:
            'Anexe quantos arquivos você desejar. Cada arquivo não deve exceder 10mb',
        }}
      />
      <List spacing="2" hidden={!uploadingItems.length}>
        {uploadingItems.map(renderUploadingItem)}
      </List>
      <List spacing="2" hidden={!items.length}>
        {items.map(renderItem)}
      </List>
    </Flex>
  );
};
