import React from 'react';
import { useCreateAction } from '@/hooks/useCreateAction';
import { ChevronRightIcon, AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Tag,
  Collapse,
  List,
  Text,
  Box,
  Skeleton,
  Link,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEventId } from '../../hooks/useEventId';
import { useQuestionContext } from './QuestionItem';
import {
  QuestionActionItem,
  useQuestionActions,
} from '../../hooks/useQuestionActions';

const ActionsList = () => {
  const eventId = useEventId();
  const { question } = useQuestionContext();
  const { questionActions, isLoading, refetch } = useQuestionActions(
    question.id,
    eventId,
  );

  const createAction = useCreateAction();

  const handleActionButton = React.useCallback(() => {
    createAction(
      {
        questionId: question.id,
        eventId,
      },
      () => {
        refetch();
      },
    );
  }, [createAction, eventId, question.id, refetch]);

  const renderSkeletons = React.useCallback(() => {
    if (!isLoading) return null;
    return (
      <>
        <Skeleton w="full" h="14" />
        <Skeleton w="full" h="14" />
      </>
    );
  }, [isLoading]);

  const renderActionItem = React.useCallback((item: QuestionActionItem) => {
    const { color, name } = item?.step || {
      color: 'gray',
      name: 'Pendente',
    };
    const { flowId, eventId } = item;
    return (
      <Flex
        cursor="pointer"
        as={Link}
        variant="unstyled"
        href={`/app/workflow/${flowId}/event/${eventId}`}
        isExternal
        _hover={{ bg: 'blackAlpha.50' }}
        key={item.title}
        borderWidth="thin"
        w="full"
        bg="white"
        rounded="lg"
        py="3"
        px="0"
      >
        <Flex
          px="3"
          w="full"
          alignItems="center"
          justifyContent="space-between"
          h="full"
        >
          <Flex flexDir="column">
            <Flex flexDir="row" gap="2">
              <Text fontWeight="bold" fontSize="lg">
                {item.title}
              </Text>
              <Tag colorScheme={color} size="md">
                {name}
              </Tag>
            </Flex>
            <Text>aberto em: {moment(item.createdAt).format('LLLL')}</Text>
          </Flex>
          <ChevronRightIcon fontSize="22px" />
        </Flex>
      </Flex>
    );
  }, []);

  return (
    <Flex borderTopWidth="thin" p="3.5" bg="gray.50" flexDir="column" gap="2">
      <Text fontSize="lg">Ações</Text>

      <List spacing="3">
        {renderSkeletons()}
        {questionActions.map(renderActionItem)}
      </List>
      <Button
        size="md"
        mt="3"
        alignSelf="flex-start"
        colorScheme="cyan"
        variant="link"
        onClick={handleActionButton}
        rightIcon={<AddIcon />}
      >
        Nova Ação
      </Button>
    </Flex>
  );
};

export const QuestionActions: React.FC = () => {
  const { paneOpen } = useQuestionContext();

  return (
    <Collapse in={paneOpen === 'actions'}>
      {paneOpen === 'actions' ? <ActionsList /> : <Box h="12" w="full" />}
    </Collapse>
  );
};
