/* eslint-disable react-hooks/rules-of-hooks */
import { FeedbackError } from '@/components/Feedback';
import { useChecklistSummaryDrawer } from '@/components_v2/ChecklistSummary';
import { formatTaskData } from '@/components_v2/ChecklistSummary/useChecklistSummary';
import { DataTable } from '@/components_v2/DataTable';
import { useWorkspace } from '@/hooks/workspace';
import { useTasks } from '@/screens/registrations/Tasks/Dashboard/hooks/tasks';
import { Task } from '@/screens/registrations/Tasks/interfaces';
import { changeTaskStatus } from '@/screens/registrations/Tasks/services/changeTaskStatus';
import { startTaskEvent } from '@/screens/registrations/Tasks/services/startTaskEvent';
import { getContrastColor } from '@/utils/colors/getContrastColor';
import { openInNewTab } from '@/utils/openInNewTab';
import {
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { FaLocationArrow } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';

import TasksListNoItems from './TasksList.NoItems';
import { topicoPrimeAdminIds } from '@/components_v2/ChecklistSummary/componets/TaskActions';
import { useAuth } from '@/hooks/Authentication/auth';
import PDFDownloadButton from '@/components_v2/PDFDownloadButton';

const getStatusColor = (status: 'pending' | 'draft' | 'done') => {
  switch (status) {
    case 'done':
      return 'green';
    case 'draft':
      return 'orange';
    case 'pending':
      return 'gray';
    default:
      return undefined;
  }
};

const getColumns = ({
  translate,
  openTask,
}: {
  translate: TFunction<'translation'>;
  openTask: (id: string) => void;
}) => {
  const { workspaceData } = useWorkspace();
  const { user } = useAuth();

  const isSesrj = workspaceData.name === 'SESRJ';
  const isRrembalagens = workspaceData.name === 'rrembalagens';
  const isNovatrafo = workspaceData.subdomain === 'novatrafo';

  const isTopicoPrimeAdmin = user && topicoPrimeAdminIds.includes(user.id); // NOTE - gambiarra braba

  const handleStart = async (task: any) => {
    const formatedData = formatTaskData(task);
    try {
      await changeTaskStatus(formatedData, 'draft');
      const res = await startTaskEvent(formatedData);
      openInNewTab(`${window.location.origin}/external/event/${res.id}`);
    } catch (e) {
      FeedbackError({ mainText: 'Unexpected Error' });
    }
  };

  const handleOpenExternal = (task: any) => {
    if (task?.event_id) {
      openInNewTab(`${window.location.origin}/external/event/${task.event_id}`);
    } else {
      handleStart(task);
    }
  };

  const columns: ColumnDef<Task>[] = [
    {
      accessorKey: 'name',
      header: translate('tasks.header.name'),
      maxSize: 100,
      cell({ getValue, row }) {
        return (
          <VStack overflow="clip" w="250px" alignItems="flex-start">
            <Text noOfLines={2}>{getValue()}</Text>

            <HStack overflow="hidden">
              <Avatar
                src={row.original.member_avatar}
                size="xs"
                name={row.original.member}
              />
              <Text
                overflow="hidden"
                width="100%"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {row.original.member || translate('common.no_member')}
              </Text>
            </HStack>
          </VStack>
        );
      },
    },
    {
      header: translate('tasks.header.active'),
      accessorKey: 'active',
      cell({ getValue }) {
        const active = getValue<Task['active']>();

        return (
          <Badge variant="subtle" colorScheme={active ? 'green' : 'red'}>
            {translate(`common.${active ? 'active' : 'inactive'}`)}
          </Badge>
        );
      },
    },
    {
      accessorKey: 'formulary_name',
      header: translate('tasks.header.formulary_name'),
      cell({ getValue }) {
        const formulary_name = getValue<Task['formulary_name']>();
        return (
          <>
            {!!formulary_name && (
              <Text textAlign="center">{formulary_name}</Text>
            )}
          </>
        );
      },
    },
    {
      accessorKey: 'date_start',
      header: translate('tasks.header.date_start'),
      cell({ getValue, row }) {
        const date_start = getValue<Task['date_start']>();

        return (
          <Flex>
            <Text fontWeight="500">
              {date_start ? moment(date_start).format('DD/MM/YYYY') : '-'}
            </Text>
          </Flex>
        );
      },
    },
    {
      accessorKey: 'date_end',
      header: translate('tasks.header.deadline'),
      cell({ getValue, row }) {
        const date_end = getValue<Task['date_end']>();

        return (
          <Text textAlign="center">
            {!!date_end && (
              <Text fontWeight="500">
                {moment(date_end).format('DD/MM/YYYY')}
              </Text>
            )}
          </Text>
        );
      },
    },
    {
      accessorKey: 'finished_at',
      header: translate('tasks.header.finished_at'),
      cell({ getValue }) {
        const finished_at = getValue<Task['finished_at']>();
        return (
          <Text textAlign="center">
            {finished_at
              ? moment(finished_at).format('DD/MM/YYYY hh:mm a')
              : '-'}
          </Text>
        );
      },
    },
    {
      accessorKey: isSesrj ? 'unity' : 'local',
      header: translate('tasks.header.local_name'),
      cell({ getValue }) {
        const local = getValue<Task['local']>();
        const unity = getValue<Task['unity']>();

        return (
          <>
            {!isSesrj && !!local?.name && (
              <Link
                pointerEvents={local?.address_name ? 'inherit' : 'none'}
                variant="unstyled"
                isExternal
                href={`https://maps.google.com/?q=${local?.address_name}`}
              >
                <Flex alignItems="center" gap="2">
                  <Icon
                    as={FaLocationArrow}
                    color={local?.address_name ? 'blue.500' : 'gray'}
                  />
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    textAlign="center"
                    color="mutedText"
                  >
                    {local.name}
                  </Text>
                </Flex>
              </Link>
            )}

            {isSesrj && unity && (
              <Text fontSize="sm" fontWeight="500" textAlign="center">
                {unity}
              </Text>
            )}
          </>
        );
      },
    },
    {
      accessorKey: 'batch_tags',
      header: translate('tasks.header.tags'),
      cell({ getValue }) {
        const batch_tags = getValue<Task['batch_tags']>();

        if (!batch_tags.length) {
          return <p>-</p>;
        }

        return (
          <HStack>
            {batch_tags[0] && (
              <Badge
                color={getContrastColor(batch_tags[0].color)}
                bg={batch_tags[0].color}
              >
                {batch_tags[0].name}
              </Badge>
            )}
            {batch_tags.length > 1 && (
              <Text whiteSpace="nowrap">+{batch_tags.length - 1}</Text>
            )}
          </HStack>
        );
      },
    },
    {
      id: 'number_seq',
      accessorKey: 'number_seq',
      header: translate('tasks.header.number_seq'),
      cell({ getValue }) {
        const number_seq = getValue<Task['number_seq']>();

        return (
          <Flex>
            <Text w="full" h="full" textAlign="center" fontWeight="500">
              {number_seq || '-'}
            </Text>
          </Flex>
        );
      },
    },
    {
      id: 'actions',
      header: '',
      cell({ row }) {
        const disabled =
          (row.original?.member_id &&
            user?.id !== row.original?.member_id &&
            !isTopicoPrimeAdmin) ||
          !row.original?.active;

        return (
          <ButtonGroup>
            <Button
              onClick={() => {
                openTask(row.original.id);
              }}
              colorScheme="brand"
            >
              {translate('common.view')}
            </Button>
            <IconButton
              onClick={() => handleOpenExternal(row.original)}
              variant="outline"
              aria-label="external-link"
              colorScheme={row?.original?.event_id || disabled ? '' : 'blue'}
              icon={<Icon as={FiExternalLink} fontSize="17px" />}
              disabled={!row?.original?.event_id && disabled}
            />
            <PDFDownloadButton
              namespace="tasks"
              screenName='events'
              isEnabled={
                ['done', 'draft'].includes(row.original.status) &&
                !!row.original.event_id
              }
              objectId={row.original.event_id}
            />
          </ButtonGroup>
        );
      },
    },
  ];

  let parseColumns = columns;

  if (!isRrembalagens && !isNovatrafo)
    parseColumns = columns.filter((item) => item.id !== 'number_seq');

  return parseColumns;
};

export function TaskTable() {
  const { t } = useTranslation();
  const {
    tasks,
    isLoading,
    refresh,
    rowSelection,
    setRowSelection,
  } = useTasks();

  //

  const { openDrawer } = useChecklistSummaryDrawer();

  if (!tasks.length && !isLoading) {
    return (
      <Center mt="10">
        <TasksListNoItems />
      </Center>
    );
  }

  return (
    <DataTable
      isLoading={isLoading && !tasks.length}
      data={tasks}
      withSelect
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      tableProps={{
        variant: 'striped',
        size: 'md',
        background: 'white',
        fontWeight: 'semibold',
      }}
      headerProps={{
        h: 14,
        bg: 'white',
      }}
      columns={getColumns({
        translate: t,
        openTask: (id) => {
          openDrawer({
            id,
            refresh,
            type: 'task',
          });
        },
      })}
    />
  );
}
