import React, { useRef } from 'react';
import { NameInputRenderProps } from './interfaces';
import {
  Container,
  InputContainer,
  Input,
  SearchIcon,
  IconContainer,
  Label,
} from './styles';

const NameInput: React.FC<NameInputRenderProps> = ({
  name,
  onSubmit,
  onChange,
  fieldLabel,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      inputRef.current?.blur();
      onSubmit();
    }
  };

  return (
    <Container>
      <IconContainer>
        <SearchIcon />
        {!!fieldLabel && <Label>{fieldLabel}</Label>}
      </IconContainer>
      <InputContainer>
        <Input
          value={name}
          ref={inputRef}
          onChange={(e) => onChange(e.currentTarget.value)}
          onKeyPress={handleKeyDown}
        />
      </InputContainer>
    </Container>
  );
};

export default NameInput;
