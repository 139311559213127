import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { SubMenuRenderProps } from '../../interfaces';
import {
  Container,
  List,
  ListItem,
  MainRouteTitle,
  CollapseButton,
  CollapseIcon,
} from './styles';

const SubMenuBar: React.FC<SubMenuRenderProps> = ({
  list,
  selected,
  isMainMenuOpen,
  setSelected,
  mainTitle,
  collapse = () => ({}),
  isCollapsed = false,
}) => {
  const { t } = useTranslation();
  const { checkPermission } = useUserPermissions();

  const renderList = useCallback(() => {
    const listItems = list.map((item) => {
      return (
        <ListItem
          key={item.name}
          disabled={
            item.disabled ||
            (item.permissionNeeded && !checkPermission(item.permissionNeeded))
          }
          onClick={() => setSelected(item.name)}
          isSelected={item.name === selected}
        >
          {item.title ? t(item.title) : item.name}
        </ListItem>
      );
    });
    return listItems;
  }, [list, selected, t, setSelected]);

  return (
    <Container isCollapsedMode={isCollapsed} isMainMenuOpen={isMainMenuOpen}>
      <CollapseButton onClick={collapse} isCollapsed={isCollapsed}>
        <CollapseIcon />
      </CollapseButton>
      {mainTitle && <MainRouteTitle>{t(mainTitle)}</MainRouteTitle>}
      <List>{renderList()}</List>
    </Container>
  );
};

export default SubMenuBar;
