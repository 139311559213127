/* eslint-disable @typescript-eslint/no-empty-function */
import useWindowFocus from '@/utils/useWindowFocus';
import React, { createContext, useContext } from 'react';

import { useWebFormState } from '../data/formState';
import { createSyncStateStore } from '../data/syncState';
import { IFormActions, IFormState } from '../interfaces';
import {
  SyncStateContextData,
  SyncStateProviderProps,
  SyncStore,
} from '../interfaces/ISync';
import { timerManager } from '../utils/timerManager';

const SyncStateContext = createContext<SyncStateContextData>(
  {} as SyncStateContextData,
);

const stateSelector = ({
  isPushing,
  push,
  pulledAnswers,
  initialize,
  lastPushAt,
}: SyncStore) => ({
  isPushing,
  push,
  pulledAnswers,
  initialize,
  lastPushAt,
});

const webFormSelector = ({
  answers,
  setAnswers,
}: IFormState & IFormActions) => ({ answers, setAnswers });

export const SyncStateProvider: React.FC<SyncStateProviderProps> = ({
  children,
  itemId,
}) => {
  const useSyncStore = React.useMemo(() => {
    return createSyncStateStore(itemId);
  }, [itemId]);

  const {
    isPushing,
    pulledAnswers,
    lastPushAt,
    push,
    initialize,
  } = useSyncStore(stateSelector);

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  // Answers logic:

  const { answers, setAnswers } = useWebFormState(webFormSelector);

  const answersRef = React.useRef(answers);

  React.useEffect(() => {
    answersRef.current = answers;
  }, [answers]);

  React.useEffect(() => {
    if (pulledAnswers) {
      setAnswers(pulledAnswers);
    }
  }, [pulledAnswers, setAnswers]);

  // Sync logic:

  const prevSyncingRef = React.useRef(false);

  const toggleSync = React.useCallback(
    (on: boolean) => {
      if (on !== prevSyncingRef.current) {
        if (on) {
          timerManager.add(itemId, () => {
            push(answersRef.current);
          });
        } else {
          timerManager.remove(itemId);
        }
        prevSyncingRef.current = on;
      }
    },
    [itemId, push],
  );

  React.useEffect(() => {
    toggleSync(true);

    return () => {
      toggleSync(false);
    };
  }, [toggleSync]);

  const forceSync = React.useCallback(() => {
    push(answersRef.current);
  }, [push]);

  return (
    <SyncStateContext.Provider
      value={{ isPushing, lastPushAt, forceSync, toggleSync }}
    >
      {children}
    </SyncStateContext.Provider>
  );
};

export function useSyncState(): SyncStateContextData {
  const context = useContext(SyncStateContext);

  if (!context) {
    throw new Error('useSyncState must be used whithin an SyncStateProvider');
  }

  return context;
}
