import styled from 'styled-components';
import React from 'react';

type OptionalProps = {
  style?: React.CSSProperties;
  disabled?: boolean;
};

export const Button = styled.button<OptionalProps>`
  outline: none;
  width: 100%;
  height: 48px;
  color: #ffff;
  font-size: 18px;
  font-weight: 500;
  background: ${({ theme: { colors }, style }) => {
    if (style?.backgroundColor) return '';
    return `linear-gradient(254.09deg, ${colors.primary} 8.52%, ${colors.secondary} 91.11%);`;
  }};
  background-color: ${({ style }) => style?.backgroundColor};
  border: 0px solid #f0efe6;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.primaryFont};
  cursor: pointer;
  ${({ disabled, theme }) =>
    disabled ? `background: ${theme.colors.pendingGrey}; cursor: none` : ''};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;

  :hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  ::after {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  :hover::after {
    opacity: 1;
  }

  :active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: opacity 0.3s ease-in-out;
  }
  margin-bottom: 20px;
`;

export const TextContent = styled.h1`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 1em;
  color: ${(props) => props.theme.colors.lighterText};
`;
