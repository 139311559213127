import ICloudStorageManager from '@shared/data/protocols/cloudstorage/ICloudStorageManager';
import {
  CloudStoragePathData,
  CloundStorageUploadData,
  UploadResp,
} from '@shared/domain/interfaces';

class CloudStorage {
  private cloudStorageManager: ICloudStorageManager;

  constructor(cloudStorageManager: ICloudStorageManager) {
    this.cloudStorageManager = cloudStorageManager;
  }

  public async uploadFile(data: CloundStorageUploadData): Promise<UploadResp> {
    const resp = await this.cloudStorageManager.uploadFile(data);
    return resp;
  }

  public async getUrl(data: CloudStoragePathData): Promise<string> {
    const url = await this.cloudStorageManager.getUrl(data);
    return url;
  }

  public async deleteFileFromName(name: string): Promise<void> {
    return this.cloudStorageManager.deleteFileFromName(name);
  }

  public async deleteFileFromURL(url: string): Promise<void> {
    return this.cloudStorageManager.deleteFileFromURL(url);
  }
}

export default CloudStorage;
