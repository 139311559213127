import useFormDetails from '@/components_v2/WebForm/hooks/useFormDetails';
import { useModals } from '@/hooks/ModalManager';
import {
  Flex,
  Text,
  HStack,
  IconButton,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useSubmitForm from '@/modules/WebForm/hooks/useSubmitForm';
import useWebForm from '@/modules/WebForm/hooks/useWebForm';
import { useWebFormProps } from '@/components_v2/WebForm/hooks/useWebFormProps';
import { SubmitResult } from '@/modules/WebForm/interfaces';
import { useItemContext } from '../context/ItemContext';

const formatDate = (date: Date) =>
  moment(date).format('MMMM Do YYYY, h:mm:ss a');

const Header: React.FC<{
  onSubmit: (result: SubmitResult) => void;
  isLoading: boolean;
}> = ({ onSubmit, isLoading }) => {
  const { initialValues } = useWebFormProps();
  const { resetFields, edited } = useWebForm();
  const { t } = useTranslation();
  const { item } = useItemContext();
  const { formulary } = useFormDetails();
  const modals = useModals();

  const handleSubmit = useSubmitForm(onSubmit);

  const handleReset = React.useCallback(() => {
    modals.confirm({
      title: 'Atenção',
      body: 'Esta ação irá descartar todas as mudanças atuais.',
      confirmLabel: 'Descartar alterações',
      cancelLabel: 'Continuar editando',
      cancelProps: {
        variant: 'outline',
      },
      confirmProps: {
        colorScheme: 'red',
        variant: 'outline',
      },
      onConfirm: () => resetFields(initialValues),
    });
  }, [initialValues, modals, resetFields]);

  const handleGoBack = React.useCallback(() => {
    modals.confirm({
      title: 'Deseja descartar as mudanças?',
      confirmLabel: 'Sim',
      cancelLabel: 'Continuar editando',
      onConfirm: () => {
        modals.close('item-edit');
      },
    });
  }, [modals]);

  return (
    <Flex
      shadow="md"
      bg="white"
      minHeight="20"
      position="sticky"
      top="0"
      zIndex="overlay"
      alignItems="center"
      w="full"
      p="5"
      justifyContent="space-between"
    >
      <HStack spacing="4">
        <IconButton
          variant="ghost"
          size="lg"
          aria-label="back"
          disabled={isLoading}
          icon={<BiArrowBack size={22} />}
          onClick={handleGoBack}
        />

        <Flex flexDir="column" flex="1">
          <Text fontSize="md" fontWeight="bold">
            {formulary?.name}
          </Text>
          {!!item?.collectible && (
            <Text fontSize="sm">
              Item: {item?.collectible.collectionItemName}
            </Text>
          )}
          {!!item?.createdAt && (
            <Text fontSize="sm" fontWeight="thin">
              {`${t('common.answered_at')}: ${formatDate(item?.createdAt)}`}
            </Text>
          )}
        </Flex>
      </HStack>
      <ButtonGroup size="sm">
        <Button
          onClick={handleReset}
          colorScheme="orange"
          isLoading={isLoading}
          isDisabled={!edited}
        >
          Descartar alterações
        </Button>
        <Button
          onClick={handleSubmit}
          colorScheme="brand"
          isLoading={isLoading}
          isDisabled={!edited}
        >
          Salvar
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default Header;
