/* eslint-disable no-case-declarations */
import { hidePassword, validatePassword } from '@utils/Validators/Password';
import { PasswordReducer } from '../interfaces';

const onValidatePassword = (password: string) => {
  if (password === '') return false;
  return validatePassword(password);
};

const onPasteHandle = (oldPassword: string, password: string) => {
  return oldPassword + password.substring(0, password.length - 1);
};

const getPassword = (oldPassword: string, hiddenPassword: string) => {
  if (hiddenPassword === '') return '';
  if (oldPassword.length < hiddenPassword.length)
    return oldPassword + hiddenPassword[hiddenPassword.length - 1];
  return oldPassword.substring(0, hiddenPassword.length);
};

const hideSubstring = (password: string) => {
  if (password === '') return '';
  if (password.length < 1) return password;
  const hiddenPassword =
    hidePassword(password.substring(0, password.length - 1)) +
    password[password.length - 1];
  return hiddenPassword;
};

export const passwordReducer: PasswordReducer = (state, action) => {
  const { confirm, password } = state;
  switch (action.type) {
    case 'set-password':
      const newPassword = getPassword(password, action.newPassword);
      return {
        ...state,
        password: newPassword,
        hiddenPassword: hideSubstring(action.newPassword),
        isValid: onValidatePassword(newPassword),
        isEquals: newPassword === confirm,
      };
    case 'set-confirm':
      const newConfirm = getPassword(confirm, action.newPassword);
      return {
        ...state,
        confirm: newConfirm,
        hiddenConfirm: hideSubstring(action.newPassword),
        isValid: onValidatePassword(newConfirm),
        isEquals: newConfirm === password,
      };
    case 'paste-password':
      const pastePassword = onPasteHandle(state.password, action.newPassword);
      return {
        ...state,
        password: pastePassword,
        hiddenPassword: hidePassword(pastePassword),
        isValid: onValidatePassword(pastePassword),
        isEquals: pastePassword === confirm,
      };
    case 'paste-confirm':
      const pasteConfirm = onPasteHandle(state.confirm, action.newPassword);
      return {
        ...state,
        confirm: pasteConfirm,
        hiddenConfirm: hidePassword(pasteConfirm),
        isValid: onValidatePassword(pasteConfirm),
        isEquals: pasteConfirm === confirm,
      };
    case 'hide-password':
      return {
        ...state,
        hiddenPassword: hidePassword(password),
        hiddenConfirm: hidePassword(confirm),
      };
    case 'show-password':
      return {
        ...state,
        showPassword: action.showPassword,
      };
    case 'show-confirm':
      return {
        ...state,
        showConfirm: action.showPassword,
      };
    default:
      return state;
  }
};
