import { Flex } from '@chakra-ui/react';
import React from 'react';

import Search from './components/Search';
import TasksList from './components/TasksList';
import Filters from './components/Filters';

const ListView: React.FC = () => {
  return (
    <Flex overflow="hidden" direction="column" w="100%">
      <Flex
        mb={4}
        justifyContent="space-between"
        alignItems="center"
        flexDir="row"
      >
        <Search />
      </Flex>
      <Filters />
      <TasksList />
    </Flex>
  );
};

export default ListView;
