import React from 'react';
import {
  Switch,
  RouteProps,
  useRouteMatch,
  Redirect,
  Route,
} from 'react-router-dom';

import {
  ChangePassword,
  ValidateCode,
  InformEmail,
  InformCodeSent,
} from '@screens/Authentication';

const PasswordRecoveryRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/inform_email`} component={InformEmail} />
      <Route path={`${path}/code_sent`} component={InformCodeSent} />
      <Route path={`${path}/validate`} component={ValidateCode} />
      <Route
        path={`${path}/change_password/:token`}
        component={ChangePassword}
      />
      <Redirect to={`${path}/inform_email`} />
    </Switch>
  );
};

export default PasswordRecoveryRoutes;
