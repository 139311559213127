import CollectionItemInput from '@/components_v2/inputs/CollectionItemInput';
import { CollectionItemValue } from '@/components_v2/inputs/CollectionItemInput/CollectionItemInput.types';
import config from '@/Constants';
import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import React from 'react';

import FormControlComponent from '../FormControlComponent';

const CollectionsContent: React.FC<QuestionModel> = () => {
  const { error, onChange, fieldState, clear, field } = useField();

  const [collection, setCollection] = React.useState<Option | undefined>(
    undefined,
  );
  const [item, setItem] = React.useState<Option | undefined>(undefined);

  const prevValue = React.useMemo(() => {
    if (
      fieldState &&
      !Array.isArray(fieldState) &&
      fieldState.collectionItemId &&
      typeof fieldState.content === 'string'
    ) {
      return { name: fieldState.content, id: fieldState.collectionItemId };
    }
    return undefined;
  }, [fieldState]);

  const onSelectCollection = React.useCallback(
    (value?: CollectionItemValue) => {
      if (!value) {
        clear();
      } else {
        setCollection(value.collection);
        setItem(value.item);
        onChange(createAnswer(value.item.name, value.item.id));
      }
    },
    [onChange, clear],
  );

  const collectionsQueryKey = React.useMemo(
    () => `${config.QUESTIONS_URL}/${field.id}/collections`,
    [field.id],
  );

  return (
    <FormControlComponent isInvalid={error}>
      <CollectionItemInput
        initialItem={prevValue}
        queryKey={collectionsQueryKey}
        onChange={onSelectCollection}
        questionId={field.id}
      />
    </FormControlComponent>
  );
};

export default CollectionsContent;
