import { Flex } from '@chakra-ui/react';
import React from 'react';

import { Content } from './components/Content/Content';
import { Header } from './components/Header/Header';
import { SideMenu } from './components/SideMenu/SideMenu';
import { EventItemsProvider } from './hooks/useEventItems';
import { EventItemShowProvider } from './hooks/useEventItemShow';
import { EventSummaryProvider } from './hooks/useEventSummary';
import { InteractionsProvider } from './hooks/useInteractions';
import { EventReportsProps, EventReportsRenderProps } from './interfaces';

const Reports: React.FC<EventReportsRenderProps> = ({
  standaloneMode,
  onClose,
}) => {
  return (
    <Flex bg="white" flex="1" flexDir="column">
      <Header standaloneMode={standaloneMode} onClose={onClose} />

      <Flex overflow="auto" flex="1" position="relative">
        <SideMenu />
        <Flex ms={{ base: 'none', xl: '-40' }} flex="1" justifyContent="center">
          <Content />
        </Flex>
      </Flex>
    </Flex>
  );
};

export const EventReport: React.FC<EventReportsProps> = ({
  eventId,
  onClose,
  standaloneMode = false,
}) => {
  return (
    <InteractionsProvider>
      <EventSummaryProvider eventId={eventId}>
        <EventItemsProvider>
          <EventItemShowProvider>
            <Reports onClose={onClose} standaloneMode={standaloneMode} />
          </EventItemShowProvider>
        </EventItemsProvider>
      </EventSummaryProvider>
    </InteractionsProvider>
  );
};
