/* eslint-disable react/react-in-jsx-scope */
import { AsyncProps, AsyncSelect, GroupBase } from 'chakra-react-select';

export type RemoteSelectOption = Record<string, string> & {
  label: string;
  value: string;
};

export type RemoteSelectProps = {
  fetchOptions: (input: string) => Promise<RemoteSelectOption[]>;
} & AsyncProps<RemoteSelectOption, false, GroupBase<RemoteSelectOption>>;

export function RemoteSelect(props: RemoteSelectProps) {
  const { fetchOptions } = props;
  return (
    <AsyncSelect
      isMulti={false}
      tagVariant="solid"
      size="md"
      cacheOptions
      defaultOptions
      {...props}
      isClearable
      loadOptions={(input) => fetchOptions(input)}
    />
  );
}
