import React, { useCallback } from 'react';

import {
  ContextMenuContainer,
  SectionTitle,
} from '@/components_v2/ContextMenu';
import { useFilter } from '@hooks/Filters/filter';
import { useTranslation } from 'react-i18next';
import FilterTrigger from './components/FilterTrigger';

const FiltersListContextMenu: React.FC = () => {
  const { filters } = useFilter();
  const { t } = useTranslation();

  const renderTriggers = useCallback(() => {
    return filters.map((filter) => {
      return (
        <FilterTrigger
          key={`${filter.column}+${filter.title}`}
          filter={filter}
        />
      );
    });
  }, [filters]);

  return (
    <ContextMenuContainer>
      <SectionTitle isFirst>{t('filter.filters').toUpperCase()}</SectionTitle>
      {renderTriggers()}
    </ContextMenuContainer>
  );
};

export default FiltersListContextMenu;
