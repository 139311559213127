/* eslint-disable import/no-extraneous-dependencies */
import { getChildOfType, getValidChildren } from '@/utils/react-utils';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  StackProps,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';
import React from 'react';
import { BiCog } from 'react-icons/bi';

export type CardHeaderProps = {
  icon?: (() => JSX.Element) | undefined;
};

export const CardHeader: React.FC<CardHeaderProps & StackProps> = ({
  children,
  icon,
  ...rest
}) => {
  return (
    <HStack mb="1" alignItems="center" spacing="2" color="gray.600" {...rest}>
      {!!icon && <Icon as={icon} />}
      <Text fontWeight="bold">{children}</Text>
    </HStack>
  );
};

export type CardProps = FlexProps & {
  collapsible?: boolean;
};

export const Card: React.FC<CardProps> = ({
  children,
  collapsible,
  ...rest
}) => {
  const { onToggle, isOpen } = useDisclosure({
    defaultIsOpen: !collapsible,
  });
  const validChildren = getValidChildren(children);
  const header = getChildOfType(validChildren, CardHeader);
  const otherChildren = (React.Children.toArray(
    validChildren,
  ) as React.ReactElement[]).filter((child) => child.type !== CardHeader);

  return (
    <Box
      borderWidth="1px"
      bg="gray.50"
      rounded="lg"
      p="4"
      flexDir="column"
      alignItems="flex-start"
      {...rest}
    >
      <Flex direction="row" justifyContent="space-between">
        {header}
        {collapsible && (
          <IconButton
            icon={isOpen ? <CloseIcon /> : <BiCog size={22} />}
            variant="ghost"
            size="sm"
            onClick={onToggle}
            aria-label="toggle"
          />
        )}
      </Flex>
      <Collapse in={isOpen}>{otherChildren}</Collapse>
    </Box>
  );
};
