import { useWebFormStore } from '../data/formState';
import { SubmitResult } from '../interfaces';

export type OnSubmitFunc = (result: SubmitResult) => void;

export default function useSubmitForm(callback: OnSubmitFunc) {
  const { getState } = useWebFormStore();

  return () => {
    const { validate } = getState();
    validate();
    const { errors, answers } = getState();

    const filteredErrors = Object.values(errors).filter((error) => error);

    callback({
      answers,
      errors,
      isValid: filteredErrors.length === 0,
    });
  };
}
