import React from 'react';
import { IconChevronRight } from '../Icons';

import {
  ActionContainer,
  ActionIcon,
  ActionText,
  ItemName,
  ExtraContainer,
  ChevronContainer,
} from '../styles';
import { ActionItemProps } from './interfaces';

const ActionItem: React.FC<ActionItemProps> = ({
  onClick,
  icon,
  text,
  extra,
  clickable,
  hasChevron,
  selected,
  disabled,
}) => {
  return (
    <ActionContainer
      clickable={!disabled && (!!clickable || !!onClick)}
      selected={selected}
      onClick={!disabled ? onClick : undefined}
      hasChevron
      disabled={!!disabled}
    >
      <ActionIcon>{icon}</ActionIcon>
      <ActionText selected={selected}>
        <ItemName>{text}</ItemName>
        {extra && <ExtraContainer>{extra}</ExtraContainer>}
      </ActionText>
      {hasChevron && (
        <ChevronContainer>
          <IconChevronRight />
        </ChevronContainer>
      )}
    </ActionContainer>
  );
};

export default ActionItem;
