/* eslint-disable no-underscore-dangle */
import { useModals } from '@/hooks/ModalManager';
import { CustomIcons } from '@/screens/Workflow/utils/customIcons';
import { Button, chakra, IconButton } from '@chakra-ui/react';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StepSchema } from '../form-schemas';
import { AttrTable } from '../misc';
import { useTriggerForm } from '../sub-forms/trigger-form';

export function TriggerTable({
  automationFlowId,
}: {
  automationFlowId: string;
}) {
  const { t } = useTranslation();
  const modal = useModals();
  const { control } = useFormContext<StepSchema>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'triggers', // unique name for your Field Array
    keyName: 'key',
  });

  const items = React.useMemo(() => fields.filter((item) => !item.destroy), [
    fields,
  ]);

  const openForm = useTriggerForm({
    automationFlowId,
    onSubmit(value) {
      if (value.id) {
        const idx = fields.findIndex((c) => c.id === value.id);
        if (idx > -1) {
          update(idx, value as any);
        }
        return;
      }
      append({ ...value, _local: true } as any);
    },
  });

  return (
    <AttrTable
      items={items}
      columns={[
        {
          label: 'Ativação',
          render(item) {
            return <>{item.label}</>;
          },
        },
        {
          label: 'Tipo de ativação',
          render(item) {
            return <>{item.triggerableType}</>;
          },
        },
        {
          label: '',
          render: (item) => (
            <chakra.div
              gap="2"
              display="flex"
              flexDir="row"
              justifyContent="flex-end"
            >
              <IconButton
                icon={<CustomIcons.TrashIcon />}
                onClick={() => {
                  modal.alert({
                    title: t('error.warning'),
                    body: t('messageDelete.ask'),
                    onConfirm() {
                      const idx = fields.findIndex((c) => c.id === item.id);
                      if (idx !== -1) {
                        if (item._local) {
                          remove(idx);
                        } else {
                          update(idx, { ...item, destroy: true });
                        }
                      }
                    },
                  });
                }}
                aria-label="delete"
              />
              <IconButton
                icon={<CustomIcons.PencilIcon />}
                onClick={() => {
                  openForm(item);
                }}
                aria-label="edit"
              />
            </chakra.div>
          ),
        },
      ]}
      footer={() => (
        <Button
          mt="4"
          leftIcon={<CustomIcons.AddIcon />}
          size="sm"
          float="right"
          onClick={() => openForm()}
          colorScheme="primary"
        >
          Adicionar ativação
        </Button>
      )}
    />
  );
}
