/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TFilterOption } from '@/components_v2/Filtersv3/types';
import { gql } from '@/gql';
import { MacroColumnFilter, MacroTableFilter } from '@/gql/types';
import { useQuery } from '@apollo/client';

type Option = TFilterOption;

const MacroFilterItems = gql(`#graphql
  query MacroFilters($column: MacroColumnFilter!, $table: MacroTableFilter!, $filters: JSON!, $search: String, $after: String, $before: String, $first: Int, $last: Int) {
  macroFilters(column: $column, table: $table, filters: $filters, search: $search, after: $after, before: $before, first: $first, last: $last) {
    edges {
      node {
        key
        name
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
`);

export const useFetchOptions = (params: {
  column: MacroColumnFilter;
  table: MacroTableFilter;
  filters: Record<string, any>;
  first?: number;
  search?: string;
  skip?: boolean;
}) => {
  const { data, loading, refetch, fetchMore } = useQuery(MacroFilterItems, {
    variables: {
      column: params.column,
      table: params.table,
      filters: params.filters,
      search: params.search,
      first: params.first,
    },
    fetchPolicy: params.skip ? 'cache-only' : 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError: () => {},
  });

  const options: Option[] =
    data?.macroFilters?.edges.map((e) => ({
      value: `${e.node?.key}`,
      label: `${e.node?.name}`,
    })) || [];

  return {
    options,
    loading,
    refetch,
    fetchMore,
    pageInfo: data?.macroFilters.pageInfo,
  };
};
