/* eslint-disable react/destructuring-assignment */
import { Table } from './table';
import type { EventReportTableData } from './types';

export function PdfDashboardSesrjReport(props: {
  report?: EventReportTableData;
}) {
  return (
    <div>
      <div
        style={{
          height: '100%',
          width: '100vw',
          flex: 1,
          backgroundColor: 'white',
          fontFamily: 'sans-serif',
        }}
      >
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            height: 'auto',
          }}
        >
          <Table report={props.report} />
        </div>
      </div>
    </div>
  );
}
