import { createContext } from '@/utils/react-utils';
import { useControllableState } from '@/utils/use-controllable-state';
import {
  Badge,
  Box,
  Button,
  Divider,
  Kbd,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { add, format, sub } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { BiPlusCircle } from 'react-icons/bi';
import { useShallow } from 'zustand/react/shallow';

import Datepicker from '../DatePicker';
import { useFilterContext, useFilterStore } from './filter-store';
import { TFilter } from './types';

function formatDate(date: Date) {
  return format(new Date(date), 'dd MMM yyyy', {
    locale: ptBR,
  });
}

function useFilterItemController(props: TFilter) {
  const { filterKey } = props;
  const { screen } = useFilterContext();
  const [open, setOpen] = useControllableState({
    value: props.open,
    onChange: props.setOpen,
  });

  if (!screen) throw new Error('No Filter store attached!');

  const store = useFilterStore(
    useShallow((state) => ({
      state: state?.byScreen?.[screen]?.byKey?.[filterKey],
      setState: (value: string) => state.byScreen[screen].set(filterKey, value),
    })),
  );

  const setState = (value?: Date[]) => {
    if (!value?.[0] && !value?.[1]) {
      store.setState('');
      return;
    }

    const endDate = new Date(
      Date.UTC(
        value[1].getFullYear(),
        value[1].getMonth(),
        value[1].getDate(),
        23,
        59,
        59,
        999,
      ),
    ); // Set time to 23:59:59.999 UTC

    store.setState(
      JSON.stringify({
        [filterKey]: [
          value?.[0].toDateString() || '',
          endDate.toISOString() || '',
        ],
      }),
    );
  };

  const state = useMemo(() => {
    if (store.state) {
      const raw = JSON.parse(store.state) as {
        [key: string]: string[];
      };

      return [
        raw[filterKey]?.[0] ? new Date(raw[filterKey][0]) : undefined,
        raw[filterKey]?.[1] ? new Date(raw[filterKey][1]) : undefined,
      ];
    }

    return undefined;
  }, [store.state]);

  return {
    setState,
    state,
    open,
    setOpen,
    ...props,
  };
}

const [FilterItemContext, useFilterItemContext] = createContext<
  ReturnType<typeof useFilterItemController>
>();

function FilterItemContent() {
  const { label, state, setState, open, setOpen } = useFilterItemContext();
  const [from, to] = (state || []) as Date[];

  const [dateRange, setDateRange] = useState<(Date | undefined)[] | undefined | null>(undefined);

  const invalid = !from && !to;

  useEffect(() => {
    setDateRange((prev) => {
      if(!state) {
        return null
      }
      if (prev?.[0] !== state?.[0] || prev?.[1] !== state?.[1]) {
        return state;
      }
      return prev;
    });
  }, [state]);

  return (
    <Popover isOpen={open} onClose={() => setOpen(false)}>
      <PopoverTrigger>
        <Button
          onClick={() => setOpen(true)}
          variant="outline"
          size="sm"
          height="32px"
          borderStyle="dashed"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <BiPlusCircle
            style={{ marginRight: '8px', height: '16px', width: '16px' }}
          />
          {label}
          {from && to && (
            <>
              <Divider orientation="vertical" marginX="8px" height="16px" />
              <Box display={{ base: 'none', lg: 'flex' }}>
                <Badge
                  variant="solid"
                  borderRadius="4px"
                  paddingX="4px"
                  fontWeight="normal"
                >
                  {from ? formatDate(from) : '_'} - {to ? formatDate(to) : '_'}
                </Badge>
              </Box>
            </>
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent>
        <Box display="flex" flexDirection="column" gap="2" height="full" p="2">
          <Box display="flex" justifyContent="space-between">
            <Text fontSize="sm" color="gray.500">
              {label}
            </Text>

            <Box
              borderWidth="1px"
              borderRadius="md"
              paddingX="1"
              paddingY="0.5"
              fontSize="xs"
              fontWeight="medium"
              color={!invalid && !!from && !!to ? 'green.600' : 'red.400'}
            >
              {!invalid ? (
                <Text>
                  {from ? formatDate(from) : '_'} - {to ? formatDate(to) : '_'}
                </Text>
              ) : (
                <Text>Selecione um período</Text>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="1"
            overflow="hidden"
          >
            <Box maxHeight="43vh" width="full" overflowY="auto">
              <Datepicker
                minDate={sub(new Date(), { years: 1 })}
                maxDate={add(new Date(), { years: 1 })}
                selectedDate={dateRange}
                type="date_range"
                onChangeDate={(range) => {
                  if (range) {
                    setDateRange(range);
                    if (range[0] && range[1]) {
                      setState(range);
                    }
                  } else {
                    setState([]);
                    setDateRange([]);
                  }
                }}
              />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap="2"
              paddingX="2"
              paddingY="2"
            >
              <Button
                isDisabled={invalid}
                width="full"
                variant="solid"
                colorScheme="red"
                onClick={() => {
                  setOpen(false);
                  setState([]);
                  setDateRange([]);
                }}
              >
                Limpar Seleção
              </Button>
            </Box>
          </Box>
        </Box>
      </PopoverContent>
    </Popover>
  );
}

export function FilterCalendar(props: TFilter) {
  const hook = useFilterItemController(props);

  return (
    <FilterItemContext value={hook}>
      <FilterItemContent />
    </FilterItemContext>
  );
}
