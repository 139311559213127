import moment from 'moment';
import { IFieldSerializer } from './serializeFromFieldsToAnswers';

export const BrlDate: IFieldSerializer = (
  fieldId,
  { answeredAt, content, id },
) => {
  const parsed = parseInt(content, 10);
  if (Number.isNaN(parsed)) {
    return {
      id,
      answeredAt,
      questionId: fieldId,
      content,
    };
  }

  const date = new Date(parsed * 1000);
  const brlDate = moment(date).format('DD/MM/YYYY');

  return {
    id,
    answeredAt,
    questionId: fieldId,
    content: brlDate,
  };
};

export const CollectionItem: IFieldSerializer = (
  fieldId,
  { answeredAt, content, id, collectionItemId },
) => {
  return {
    id,
    answeredAt,
    questionId: fieldId,
    content,
    collectionItemId,
  };
};
