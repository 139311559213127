import React from 'react';
import {
  Flex,
  Icon,
  Text,
  Image,
  Box,
  IconButton,
  Square,
  Spinner,
  FlexProps,
} from '@chakra-ui/react';
import { BsFileText } from 'react-icons/bs';
import { FaFilePdf, FaFileExcel, FaFileWord } from 'react-icons/fa';
import { FiFile } from 'react-icons/fi';
import prettyBytes from 'pretty-bytes';
import { DeleteIcon } from '@chakra-ui/icons';

export const FILE_TYPE_ICONS = {
  pdf: FaFilePdf,
  excel: FaFileExcel,
  word: FaFileWord,
  text: BsFileText,
  default: FiFile,
};

export type AttachmentItemProps = {
  id: string;
  mimeType: string;
  url?: string;
  name: string;
  extension: string;
  bytes: number;
  isUploading?: boolean;
  onDelete?: () => void;
  flexProps?: FlexProps;
};

export function Thumbnail(
  props: Pick<AttachmentItemProps, 'mimeType' | 'url'>,
) {
  const { mimeType, url } = props;
  const getFileContent = React.useCallback(() => {
    switch (mimeType) {
      case 'application/pdf':
        return <Icon as={FILE_TYPE_ICONS.pdf} fontSize="22" />;
      case 'application/vnd.ms-excel':
      case 'application/csv':
      case 'application/x-csv':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'text/x-csv':
      case 'application/vnd.apple.numbers':
        return <Icon as={FILE_TYPE_ICONS.excel} fontSize="22" />;
      case 'text/plain':
        return <Icon as={FILE_TYPE_ICONS.text} fontSize="22" />;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      case 'application/vnd.apple.pages':
        return <Icon as={FILE_TYPE_ICONS.word} fontSize="22" />;
      default:
        return <Icon as={FILE_TYPE_ICONS.default} fontSize="22" />;
    }
  }, [mimeType]);

  if (mimeType?.startsWith('image/')) {
    return (
      <Square size="10" borderWidth="thin" borderRadius="md">
        <Image
          borderRadius="md"
          w="full"
          h="full"
          src={url}
          objectFit="cover"
        />
      </Square>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="10"
      w="10"
      ps="0.5"
    >
      {getFileContent()}
    </Box>
  );
}

export function AttachmentItem(props: AttachmentItemProps) {
  const { name, bytes, onDelete, isUploading, flexProps } = props;
  return (
    <Flex
      borderRadius="md"
      borderWidth="thin"
      p="2"
      flexDir="row"
      justify="space-between"
      gap="2"
      {...flexProps}
    >
      <Flex
        flexDir="row"
        justifyContent="flex-stat"
        alignItems="center"
        gap="2"
      >
        <Thumbnail {...props} />
        <Flex flexDir="column">
          <Text fontWeight="bold" fontSize="md" noOfLines={1}>
            {name}
          </Text>
          <Text fontSize="sm">{prettyBytes(bytes)}</Text>
        </Flex>
      </Flex>
      {isUploading && (
        <Spinner mx="4" alignSelf="center" colorScheme="primary" />
      )}
      {!!onDelete && !isUploading && (
        <IconButton
          alignSelf="center"
          colorScheme="red"
          variant="ghost"
          size="md"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onDelete?.();
          }}
          icon={<DeleteIcon />}
          aria-label="delete"
        />
      )}
    </Flex>
  );
}
