import { LocalAuthentication } from '@/modules/security/adapters';
import {
  createHttpLink as _createHttpLink,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';

import { AxiosHelper } from '../../shared/infra/protocols/http';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

function createCache() {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          publicTasks: relayStylePagination(['$search', '$status']),
          imports: relayStylePagination(),
          exports: relayStylePagination(),
        },
      },
    },
  });
}

function createHttpLink() {
  const uri = AxiosHelper.getFullPath().replace('/api', '/graphql');

  const fetch = buildAxiosFetch(AxiosHelper.instance);
  const token = LocalAuthentication.load()?.token;
  const header = token
    ? { Authorization: token, 'Content-type': 'application/json' }
    : undefined;

  return _createHttpLink({
    uri,
    fetchOptions: {
      mode: 'no-cors',
    },
    headers: header,
    fetch,
  });
}

export function createApolloClient() {
  const httpLink = createHttpLink();

  const cache = createCache();

  const client = new ApolloClient({
    name: 'wecheck-web',
    version: '1',
    link: httpLink,
    cache,
  });

  apolloClient = client;

  return apolloClient;
}

export function getApolloInstance() {
  if (apolloClient) {
    return apolloClient;
  }
  return createApolloClient();
}
