/* eslint-disable @typescript-eslint/ban-types */

import { FilterScreenProvider } from '@/components_v2/Filtersv3/filter-store';
import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Sidebar } from './Sidebar';

export const TicketLayout: React.FC = ({ children }) => {
  const { path } = useRouteMatch();

  return (
    <Flex flexDir="row" h="full" bg="#F7F8F9">
      {path.includes('tickets') && <Sidebar />}
      <Flex
        width="full"
        height="100vh"
        maxHeight="100vh"
        overflow="hidden"
        flex={1}
        flexDir="column"
      >
        {children}
      </Flex>
    </Flex>
  );
};

export const withTicketLayout = <T extends object>(Component: React.FC<T>) => {
  return (props: T) => (
    <FilterScreenProvider screen="tickets">
      <TicketLayout>
        <Component {...props} />
      </TicketLayout>
    </FilterScreenProvider>
  );
};
