import React from 'react';

import Main from './Main';

import { MobileDropDownMenuProps } from '../../interfaces';

const MobileDropDownMenu: React.FC<MobileDropDownMenuProps> = ({
  isMainMenuOpen,
  mainList,
  setSubSelected,
  mainSelected,
  setMainSelected,
  subList,
  subSelected,
}) => {
  return (
    <Main
      isMainMenuOpen={isMainMenuOpen}
      mainList={mainList}
      subList={subList}
      setSubSelected={setSubSelected}
      setMainSelected={setMainSelected}
      mainSelected={mainSelected}
      subSelected={subSelected}
    />
  );
};

export default MobileDropDownMenu;
