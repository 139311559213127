import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import { getDateSinceMidnight } from '@/utils/Date/getDateSinceMidnight';
import { milisSinceMidnight } from '@/utils/Date/milisSinceMidnight';
import { Input } from '@chakra-ui/react';
import React from 'react';

import FormControlComponent from '../FormControlComponent';

function convertTimeStringToDate(timeString: string) {
  const [hours, minutes] = timeString.split(':').map(Number);

  const now = new Date();
  now.setHours(hours);
  now.setMinutes(minutes);
  now.setSeconds(0);

  return now;
}

const TimeContent: React.FC<QuestionModel> = () => {
  const { error, onChange, fieldState } = useField();

  const value = React.useMemo(() => {
    if (fieldState && !Array.isArray(fieldState)) {
      return getDateSinceMidnight(fieldState.content);
    }
    return undefined;
  }, [fieldState]);

  const handleChange = React.useCallback(
    (newValue: string) => {
      const formattedDate = convertTimeStringToDate(newValue);
      onChange(
        createAnswer(
          newValue
            ? Math.round(milisSinceMidnight(formattedDate) / 1000)
            : undefined,
        ),
      );
    },
    [onChange],
  );

  return (
    <FormControlComponent isInvalid={error}>
      <Input
        type="time"
        onChange={(e) => handleChange(e.target.value)}
        value={value?.toLocaleTimeString('pt-BR', {
          hour: '2-digit',
          minute: '2-digit',
        })}
      />
    </FormControlComponent>
  );
};

export default TimeContent;
