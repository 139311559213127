const funcs: Record<string, () => void> = {};

class TimerManager {
  add(id: string, func: () => void) {
    funcs[id] = func;
  }

  remove(id: string) {
    if (funcs[id]) {
      delete funcs[id];
    }
  }

  run() {
    if (!funcs) return;
    const funcsToCall = Object.values(funcs);
    for (let i = 0; i < funcsToCall.length; i++) {
      funcsToCall[i]();
    }
  }

  initializeTimer() {
    setTimeout(() => {
      this.run();
      this.initializeTimer();
    }, 10000);
  }
}

const timerManager = new TimerManager();
timerManager.initializeTimer();

export { timerManager };
