import _ from 'lodash';

import {
  FiltersReducer,
  FilterType,
  OrderData,
  ScreenSelectedOptions,
} from './interfaces';

export const filtersReducer: FiltersReducer = (state, action) => {
  switch (action.type) {
    case 'clear_all': {
      return { columns: {} };
    }
    case 'set_order': {
      if (state.order && _.isEqual(action.props, state.order)) {
        return {
          columns: state.columns,
        };
      }
      return {
        columns: state.columns,
        order: action.props,
      };
    }
    case 'select_option': {
      if (state.columns[action.props.column]) {
        const column = state.columns[action.props.column];
        return {
          order: state.order,
          columns: {
            ...state.columns,
            [action.props.column]: {
              ...column,
              [action.props.type]: action.props,
            },
          },
        };
      }
      const res = {
        order: state.order,
        columns: {
          ...state.columns,
          [action.props.column]: { [action.props.type]: action.props },
        },
      };
      return res;
    }
    case 'clear_option': {
      if (state.columns[action.props.column]) {
        const column = state.columns[action.props.column];
        const newColumn = _.omit(column, [action.props.type]);
        const keys = Object.keys(newColumn);
        if (keys.length === 0) {
          const newColumns = _.omit(state.columns, action.props.column);
          return { order: state.order, columns: newColumns };
        }
        return {
          order: state.order,
          columns: {
            ...state.columns,
            [action.props.column]: newColumn,
          },
        };
      }
      return state;
    }
    case 'clear_column': {
      const newColumns = _.omit(state.columns, [action.props]);
      return {
        order: state.order,
        columns: newColumns,
      };
    }
    default: {
      return state;
    }
  }
};

export function serializeFilters(
  filters: ScreenSelectedOptions,
  order?: OrderData,
): string {
  let filterString = '';
  if (order) {
    filterString += `&orderBy=${order.column}&orderType=${order.type}`;
  }
  const columns = Object.keys(filters);
  for (let i = 0; i < columns.length; i++) {
    const types: FilterType[] = Object.keys(
      filters[columns[i]],
    ) as FilterType[];
    for (let j = 0; j < types.length; j++) {
      const filter = filters[columns[i]][types[j]];
      if (filter) {
        const baseQuery = `&[filter][${filter.table}][${filter.column}]`;
        if (filter.selected !== null) {
          switch (filter.type) {
            case 'date_range':
              filterString += `${baseQuery}[date_start]=${filter.selected.dateStart}`;
              filterString += `${baseQuery}[date_end]=${filter.selected.dateEnd}`;
              break;
            case 'date':
              filterString += `${baseQuery}[date_start]=${filter.selected}`;
              filterString += `${baseQuery}[date_end]=${filter.selected}`;
              break;
            case 'multi':
              for (let i = 0; i < filter.selected.length; i++) {
                filterString += `${baseQuery}[]=${filter.selected[i]}`;
              }
              break;
            case 'search':
              filterString += `&[filter][${filter.column}][search]=${filter.selected}`;
              break;
            default:
              filterString += `${baseQuery}=${filter.selected}`;
              break;
          }
        }
      }
    }
  }

  return filterString;
}
