import { QuestionAttachments } from '@/components_v2/QuestionAttachments/QuestionAttachments';
import { useDefaultAttachmentsHandler } from '@/components_v2/QuestionAttachments/useDefaultAttachmentsHandler';
import React from 'react';

import { useWebFormProps } from '../../hooks/useWebFormProps';
import { useQuestionItem } from './context';

const makeAttachmentsHook = (payload: {
  questionId: string;
  itemId: string;
}) => {
  return () => useDefaultAttachmentsHandler(payload);
};

export const QuestionAttachmentPane: React.FC = () => {
  const { question } = useQuestionItem();
  const { itemId } = useWebFormProps();

  const useUploader = React.useRef(
    makeAttachmentsHook({ itemId: `${itemId}`, questionId: question.id }),
  ).current;

  return <QuestionAttachments useUploader={useUploader} />;
};
