import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ProgressBar from '@components/ProgressBar';
import Button from '@/components/Button';
import { UploadMainProps } from './interfaces';
import { Container, TextWarning, StatusContainer, InputButton } from './styles';

const UploadFile: React.FC<UploadMainProps> = ({
  status,
  registerCount,
  loadingProgress,
  fileName,
  loadingDelete,
  options,
  onAttach,
  onDelete,
  accept,
}) => {
  const { t } = useTranslation();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };
  const handleChange = useCallback(
    (event: any) => {
      const fileUploaded = event.target.files[0];
      onAttach(fileUploaded);
    },
    [onAttach],
  );

  const render = useCallback(() => {
    switch (status) {
      case 'ATTACH':
        return (
          <StatusContainer>
            <InputButton
              type="file"
              accept={accept}
              ref={hiddenFileInput}
              onChange={handleChange}
            />
            <Button
              text={options?.buttonLabel}
              callback={() => handleClick()}
              icon="UPLOAD"
              type="GREY_BACKGOUND"
              styleTitle={{ fontSize: '0.8em' }}
            />
          </StatusContainer>
        );
      case 'LOADING':
        return <ProgressBar progress={loadingProgress} />;
      case 'UPLOAD':
        return (
          <StatusContainer>
            <TextWarning>{t(`file.attached`)}</TextWarning>
            <Button
              text={fileName}
              callback={() => onDelete()}
              type="ITEM_LIST"
              styleTitle={{ fontSize: '0.75em' }}
              style={{ margin: '0px 5px', padding: '2px 5px' }}
              isLoading={loadingDelete}
            />
          </StatusContainer>
        );
      default:
        return null;
    }
  }, [
    t,
    status,
    loadingProgress,
    fileName,
    loadingDelete,
    options?.buttonLabel,
    onDelete,
    handleChange,
  ]);

  return (
    <Container>
      {render()}
      <TextWarning>
        {t(`subtitle.importerWarning`, { registerCount })}
      </TextWarning>
    </Container>
  );
};

export default UploadFile;
