import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const Avatar: ComponentSingleStyleConfig = {
  sizes: {
    'sm-md': {
      container: {
        w: 10,
        h: 10,
        fontSize: 'calc(2.5rem / 2.5)',
      },
      excessLabel: {
        width: 10,
        height: 10,
      },
      label: {
        fontSize: 'calc(2.5rem / 2.5)',
        lineHeight: '2.5rem',
      },
    },
  },
};
