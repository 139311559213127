import React, { useCallback, useState, useEffect } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { defaultTheme } from '@/styles/theme';
import { useWorkspace } from './workspace';
import { Workspace } from './interfaces/workspace';

const mergeTheme = (oldTheme: any, newTheme: any) =>
  Object.keys(oldTheme).reduce((prev, current) => {
    const curr = oldTheme[current];

    const updatedValue =
      typeof curr === 'object'
        ? {
            ...curr,
            ...newTheme[current],
          }
        : curr;
    return {
      ...prev,
      [current]: updatedValue,
    };
  }, {});

export const WorkspaceAwareThemeProvider: React.FC = ({ children }) => {
  const [currentTheme, setTheme] = useState<DefaultTheme>(defaultTheme);
  const { workspaceData } = useWorkspace();

  const changeTheme = useCallback((workspaceData: Workspace) => {
    const { theme } = workspaceData;
    if (theme) {
      const newTheme = mergeTheme(defaultTheme, theme);
      setTheme(newTheme as DefaultTheme);
    } else {
      setTheme(defaultTheme);
    }
  }, []);

  useEffect(() => {
    changeTheme(workspaceData);
  }, [workspaceData, changeTheme]);

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
};
