import React from 'react';

import { DescriptionProps } from './localGeneric';
import { ScreenDescription } from './styles';

const Description: React.FC<DescriptionProps> = ({
  content,
  center,
  inline,
  highlight,
  children,
}) => {
  return (
    <ScreenDescription center={center} inline={inline} highlight={highlight}>
      {content}
      {children}
    </ScreenDescription>
  );
};

export default Description;
