import styled from 'styled-components';
import { FiInbox } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled(FiInbox)<IconBaseProps>`
  /* width: 22px;
  height: 18px; */
  /* size: 22px; */
  color: ${(props) => props.theme.colors.darkText};
  padding-right: 8px;
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkText};
  font-size: 14px;
`;
