import { useCreateFormAutomationGroupModal } from '@/screens/Tickets/Dashboard/components/Forms/CreateFormAutomationGroup';
import { Divider, Flex, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AddButton } from '../AddButton';
import FlowList from './FlowList';
import SectionTitle from './SectionTitle';

export const Sidebar: React.FC = () => {
  const { openForm } = useCreateFormAutomationGroupModal();
  const { t } = useTranslation();

  return (
    <>
      <Flex
        display={{ base: 'none', xl: 'flex' }}
        h="full"
        w="96"
        bg="white"
        borderRightWidth="1.5px"
        flexDir="column"
        p="4"
      >
        <Text px="4" fontSize="xl" fontWeight="bold">
          {t('tickets.sidebar.automation')}
        </Text>
        <Divider mt="2" mb="4" orientation="horizontal" />
        <HStack mb="3" ps="2" justify="space-between">
          <SectionTitle rightButton={<AddButton onClick={() => openForm()} />}>
            {t('tickets.sidebar.groupFlows')}
          </SectionTitle>
        </HStack>
        <FlowList />
      </Flex>
    </>
  );
};
