/* eslint-disable consistent-return */

import { GiHamburgerMenu } from 'react-icons/gi';
import styled, { css } from 'styled-components';

import constants from '../../constants';

export interface HamburguerContainerProps {
  isMainMenuOpen: boolean;
}

export const Container = styled.div`
  grid-area: navbar;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 10;
  max-height: 100vh;

  background: ${({ theme: { colors } }) =>
    `linear-gradient(270deg, ${colors.primary} 8.12%, ${colors.secondary} 71.62%);`}

    background: ${({ theme: { colors } }) =>
      `linear-gradient(270deg, ${colors.primary} 8.12%, ${colors.secondary} 71.62%);`}

  @media (min-width: 768px) {
    width: ${constants.navbarWidth};
    background: ${({ theme: { colors } }) =>
      `linear-gradient(180deg, ${colors.primary} 8.12%, ${colors.secondary} 71.62%);`}
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 0;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex: 4.5;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.75rem;
`;

export const MobileSecondaryButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  order: 2;
`;

export const HamburguerContainer = styled.div<HamburguerContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  box-sizing: border-box;
  padding: 0;
  height: 28px;
  width: 28px;
  margin: 0;
  border: none;
  order: 0;

  @media (min-width: 768px) {
    order: 1;
    height: 45px;
    width: 45px;
    background-color: ${(props) => props.theme.colors.secondaryContent};
    border-radius: 50%;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
    ${(props) => {
      if (props.isMainMenuOpen) {
        return css`
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        `;
      }
    }}
  }
`;

export const HamburguerIcon = styled(GiHamburgerMenu)`
  color: #fafafa;
  font-size: 26px;

  @media (min-width: 768px) {
    color: ${(props) => props.theme.colors.darkText};
    font-size: 28px;
  }
`;

interface ContainerProps {
  hasOpenNotification?: boolean;
}

export const ProfileContainer = styled.button<ContainerProps>`
  position: relative;
  border-radius: 50%;
  background-color: white;
  box-sizing: border-box;
  height: 38px;
  width: 38px;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  font-size: 16px;
  :hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  ${(props) => {
    if (props.hasOpenNotification) {
      return css`
        ::after {
          position: absolute;
          z-index: 10;
          content: '';
          border-radius: 50%;
          width: 10px;
          height: 10px;
          right: -1px;
          top: -1px;
          background-color: ${(props) => props.theme.colors.notification};
        }
      `;
    }
  }}
`;
