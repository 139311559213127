/* eslint-disable @typescript-eslint/ban-types */
import { createContext } from '@/utils/react-utils';
import React from 'react';

type UploadContextValue = {
  isBusy?: boolean;
  setIsBusy: (isBusy: boolean) => void;
};

const [UploadStatusContext, useUploadStatusContext] = createContext<
  UploadContextValue
>({
  name: 'UploadStatusContext',
});

export const UplodStatusProvider: React.FC = ({ children }) => {
  const [isBusy, setIsBusy] = React.useState(false);
  return (
    <UploadStatusContext
      value={{
        isBusy,
        setIsBusy,
      }}
    >
      {children}
    </UploadStatusContext>
  );
};

export const withUploadStatus = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  const Wrapped: React.ComponentType<P> = (props) => {
    return (
      <UplodStatusProvider>
        <Component {...props} />
      </UplodStatusProvider>
    );
  };

  return Wrapped;
};

const useUploadStatus = () => {
  const context = useUploadStatusContext();

  return (
    context || {
      isBusy: false,
      setIsBusy: () => null,
    }
  );
};

export { useUploadStatus };
