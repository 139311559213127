import { presentImportModal } from '@/components/ImporterModal';
import ExporterContextMenu from '@/components_v2/ExporterContextMenu';
import config from '@/Constants';
import { useFragment } from '@/gql';
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useModals } from '@/hooks/ModalManager';
import { ticketsImportService } from '@/modules/shared/adapters/ImporterService';
import { LeftDrawer } from '@/screens/Tickets/Common/Sidebar/LeftDrawer';
import { useAutomationFlow } from '@/screens/Tickets/context/automationFlow';
import { useDeleteAutomationTickets } from '@/screens/Tickets/hooks/useDeleteAutomationTickets';
import { AutomationFlowPropsFrag } from '@/screens/Tickets/hooks/useFetchFlow';
import { CustomIcons } from '@/screens/Workflow/utils/customIcons';
import { ExporterServiceFactory } from '@/services/exporter';
import {
  Button,
  Collapse,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineExpandAlt } from 'react-icons/ai';
import { BiDownload, BiUpload } from 'react-icons/bi';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

import { useAutomationFlowForm } from '../Forms';
import { useCreateStepForm } from '../Forms/CreateStep';
import Search from '../Search';
import Filters from './Filters';

function getExporter(screenName: string) {
  const baseUrl = config.EXPORTER_URL;
  return ExporterServiceFactory.getInstance({
    baseUrl,
    exportType: 'xlsx',
    screen: screenName,
  });
}

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const { checkPermission } = useUserPermissions();
  const modals = useModals();
  const {
    flow,
    selected,
    onDeselect,
    setHideProperties,
    hideProperties,
  } = useAutomationFlow();
  const flowFragment = useFragment(AutomationFlowPropsFrag, flow);
  const { data, deleteAutoTicketsInBatch } = useDeleteAutomationTickets();
  const openAutomationFlowForm = useAutomationFlowForm();
  const openForm = useCreateStepForm();

  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
  });

  return (
    <Flex flexDir="column" w="full">
      <Flex
        alignItems="center"
        minH={isOpen ? '24' : '12'}
        transition="all 0.2s ease-in-out"
        bg="white"
        w="full"
        px="6"
        justifyContent="space-between"
        borderBottomWidth="1.5px"
      >
        <Flex h="full" flexDir="row" alignItems="center">
          <HStack pr="4" spacing="4">
            <LeftDrawer />
            <VStack spacing="0" alignItems="start" style={{ marginLeft: '0' }}>
              <Heading
                transition="all 0.2s ease-in-out"
                wordBreak="break-all"
                noOfLines={1}
                mt={isOpen ? 0 : 0.5}
                fontSize={isOpen ? '2xl' : 'lg'}
                color="darkText"
              >
                {`${flowFragment?.name || 'Carregando'}`}
              </Heading>
              {!!flowFragment && (
                <Text
                  h={isOpen ? 'min-content' : '0px'}
                  opacity={isOpen ? '1' : '0'}
                  transition="all 0.2s ease-in-out"
                  fontSize="md"
                  fontWeight="bold"
                  color="muted"
                  mt="1"
                >
                  {`${t('tickets.header.subtitle')} ${flowFragment?.name}`}
                </Text>
              )}
            </VStack>
          </HStack>
        </Flex>

        <Flex py="2" h="full" flexDir="row">
          <HStack>
            {checkPermission('admin') && (
              <>
                <Button
                  colorScheme="primary"
                  rounded="lg"
                  variant="solid"
                  lineHeight="2rem"
                  leftIcon={
                    <CustomIcons.AddIcon
                      fontSize="15px"
                      style={{ paddingBottom: 1, marginLeft: 1 }}
                    />
                  }
                  onMouseDown={() => openForm({})}
                >
                  Adicionar Etapa
                </Button>

                <Button
                  w="min-content"
                  aria-label="settings"
                  variant="elevated"
                  borderWidth="thin"
                  onClick={() =>
                    flowFragment?.id
                      ? openAutomationFlowForm({ id: flowFragment.id })
                      : undefined
                  }
                  disabled={!flowFragment?.id}
                  leftIcon={
                    <Icon ml="0" as={() => <CustomIcons.ConfigIcon />} />
                  }
                >
                  Configurações do Fluxo
                </Button>
              </>
            )}

            <ExporterContextMenu
              placement="start-start"
              getExporter={getExporter}
              screenName="tickets"
              button={
                <Button
                  colorScheme="primary"
                  rounded="lg"
                  variant="outline"
                  lineHeight="2rem"
                  rightIcon={
                    <BiDownload
                      fontSize="15px"
                      style={{ paddingBottom: 1, marginLeft: 1 }}
                    />
                  }
                >
                  {t('tickets.reports')}
                </Button>
              }
            />

            <IconButton
              onMouseDown={onToggle}
              w="min-content"
              fontSize="lg"
              borderWidth="thin"
              icon={<Icon as={AiOutlineExpandAlt} fontSize="14px" />}
              aria-label="expand"
            />
          </HStack>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        borderBottomWidth="1.5px"
        bg="white"
        flex={1}
        w="full"
        h="12"
      >
        <Collapse in={isOpen} style={{ width: '100%' }}>
          <>
            <Flex
              flexWrap="wrap"
              w="full"
              justify="space-between"
              py="2.5"
              px="6"
            >
              <Flex
                gap="2"
                align="baseline"
                alignItems="center"
                flex="1"
              >
                <Search />
                <Filters />
              </Flex>
              <Flex
                gap="2"
                align="baseline"
                alignItems="center"
                w="fit-content"
              >
                <Button
                  onClick={() => setHideProperties((old) => !old)}
                  fontSize="lg"
                  borderWidth="thin"
                  leftIcon={
                    <Icon as={hideProperties ? BsEyeFill : BsEyeSlashFill} />
                  }
                  aria-label="expand"
                >
                  {hideProperties ? 'Mostrar' : 'Esconder'} Propriedades
                </Button>

                {checkPermission('admin') && (
                  <>
                    <Button
                      disabled={!flow?.id}
                      fontSize="lg"
                      borderWidth="thin"
                      onClick={() => {
                        presentImportModal({
                          importService: ticketsImportService,
                          extraParams: {
                            flow_id: `${flow?.id}`,
                          },
                        });
                      }}
                      rightIcon={<BiUpload />}
                    >
                      Importar Ticket
                    </Button>

                    <Button
                      aria-label="delete"
                      margin="auto"
                      disabled={!selected?.length}
                      onClick={() => {
                        modals.confirm({
                          title: t('error.warning'),
                          body: t('messageDelete.ask'),
                          onConfirm() {
                            deleteAutoTicketsInBatch(selected);
                          },
                        });
                        if (data?.errors.length === 0) onDeselect('all');
                      }}
                      leftIcon={
                        <Icon m="0" as={() => <CustomIcons.TrashIcon />} />
                      }
                    >
                      Deletar Ticket(s)
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          </>
        </Collapse>
      </Flex>
    </Flex>
  );
};
