import { FragmentType, gql } from '@/gql';
import { useAuth } from '@/hooks/Authentication/auth';
import { NotificationsTopics } from '@/hooks/Notifications/utils/NotificationsTopics';
import { createContext } from '@/utils/react-utils';
import { useQuery } from '@apollo/client';
import React from 'react';

export const exportFragment = gql(`
  fragment ExportFragment on Export {
    id
    progress
    titleEvent
    status
    updatedAt
    exportType
    dateStart
    dateEnd
    parentableType,
    url,
    author {
      id
      email
      fullName
    }
    exportWithErrors
    exportErrors
    token
    pivoted
    createdAt
    exportColumns
    emailsForSend
  }
`);

export const exportQuery = gql(`
  query Exports($after: String, $before: String, $first: Int, $last: Int) {
    exports(after: $after, before: $before, first: $first, last: $last) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          ...ExportFragment
        }
      }
    }
  }
`);

export type ExportFragmentType = FragmentType<typeof exportFragment>;

export function useExportsController() {
  const { user } = useAuth();
  const { data, fetchMore, loading, refetch, variables } = useQuery(
    exportQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        first: 50,
      },
      skip: !user?.id,
    },
  );

  React.useEffect(() => {
    const unsub = NotificationsTopics.subscribeTopic('Export', () => {
      refetch(variables);
    });

    return () => {
      unsub();
    };
  }, [refetch, variables]);

  const pageInfo = React.useMemo(() => data?.exports.pageInfo, [
    data?.exports.pageInfo,
  ]);

  const onFetchMore = React.useCallback(() => {
    if (!pageInfo?.hasNextPage) return;

    fetchMore({
      variables: {
        after: pageInfo.endCursor,
        first: 50,
      },
    });
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage]);

  const exports = React.useMemo(
    () => data?.exports?.edges!.map((e) => e.node!) || [],
    [data?.exports?.edges],
  );

  return {
    onFetchMore,
    pageInfo,
    loading,
    exports,
  };
}

type ExportContextvalue = ReturnType<typeof useExportsController>;

export const [ExportContext, useExports] = createContext<ExportContextvalue>();

export function ExportsProvider({ children }: { children?: React.ReactNode }) {
  return (
    <ExportContext value={useExportsController()}>{children}</ExportContext>
  );
}
