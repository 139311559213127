import { createContext } from '@/utils/react-utils';
import { useControllableState } from '@/utils/use-controllable-state';
import { CheckIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  Divider,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { BiPlusCircle } from 'react-icons/bi';
import { useShallow } from 'zustand/react/shallow';

import {
  useFilterContext,
  useFiltersByScreen,
  useFilterStore,
} from './filter-store';
import { TFilter } from './types';

function useFilterItemController(props: TFilter) {
  const { filterKey } = props;

  const [open, setOpen] = useControllableState({
    value: props.open,
    onChange: props.setOpen,
  });
  const { screen } = useFilterContext();

  if (!screen) throw new Error('No Filter store attached!');

  const { setState, state } = useFilterStore(
    useShallow((state) => {
      return {
        state: state?.byScreen?.[screen]?.byKey?.[filterKey],
        setState: (value: string) =>
          state.byScreen[screen].toggle(filterKey, value),
      };
    }),
  );

  return {
    setState,
    state,
    open,
    setOpen,
    ...props,
  };
}

const [FilterItemContext, useFilterItemContext] = createContext<
  ReturnType<typeof useFilterItemController>
>();

function FilterItemContent() {
  const {
    label,
    options = [],
    state = [],
    hasMore,
    loading,
    setState,
    onSearch,
    fetchMore,
    open,
    setOpen,
  } = useFilterItemContext();

  return (
    <Menu isOpen={open} onClose={() => setOpen(false)}>
      <MenuButton
        as={Button}
        variant="outline"
        size="sm"
        style={{
          height: '32px',
          borderStyle: 'dashed',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
        onClick={() => setOpen(true)}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <BiPlusCircle
            style={{ marginRight: '8px', height: '16px', width: '16px' }}
          />
          {label}
          {state?.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Divider
                orientation="vertical"
                style={{ margin: '0 8px', height: '16px' }}
              />
              <Badge
                variant="solid"
                style={{
                  borderRadius: '4px',
                  padding: '0 4px',
                  fontWeight: 'normal',
                }}
                className="lg:hidden"
              >
                {state.length}
              </Badge>
            </div>
          )}
        </div>
      </MenuButton>
      <MenuList
        style={{
          width: '300px',
          maxHeight: '300px',
          overflowY: 'auto',
          padding: '0',
        }}
      >
        <Stack spacing="4" p="4">
          {onSearch && (
            <Input
              variant="outline"
              size="sm"
              placeholder={label}
              onChange={(e) => onSearch(e.target.value)}
              autoFocus={true}
            />
          )}
          {loading ? (
            <Stack alignItems="center">
              <Spinner size="sm" />
              <Text fontSize="sm">Carregando...</Text>
            </Stack>
          ) : options.length > 0 ? (
            options.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => setState(option.value)}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack spacing="2" direction="row" alignItems="center">
                  {state.includes(option.value) && (
                    <CheckIcon style={{ height: '16px', width: '16px' }} />
                  )}
                  <span>{option.label}</span>
                </Stack>
                {option.count && (
                  <Badge variant="subtle" colorScheme="blue" size="sm">
                    {option.count}
                  </Badge>
                )}
              </MenuItem>
            ))
          ) : (
            <Text fontSize="sm">Sem resultados</Text>
          )}
          {fetchMore && hasMore && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                fetchMore();
              }}
            >
              <Stack spacing="2" direction="row" alignItems="center">
                <Text fontSize="sm">Carregar mais...</Text>
              </Stack>
            </Button>
          )}
        </Stack>
      </MenuList>
    </Menu>
  );
}

export function FilterList({ children }: { children: React.ReactNode }) {
  const { reset, byKey } = useFiltersByScreen();
  const [open, setOpen] = useControllableState({ defaultValue: false });

  return (
    <Menu isOpen={open} onClose={() => setOpen(false)}>
      <MenuButton as={Button} onClick={() => setOpen(true)}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <BiPlusCircle
            style={{ marginRight: '6px', height: '14px', width: '14px' }}
          />
          <div>Filtros</div>
        </div>
      </MenuButton>
      <MenuList style={{ padding: '0', maxHeight: '400px', overflowY: 'auto' }}>
        <Stack spacing="4" p="4">
          {children}
          {Object.values(byKey).some((filter) => filter.length > 0) && (
            <>
              <MenuDivider />
              <Button
                onClick={() => {
                  reset();
                }}
                variant="link"
                size="sm"
              >
                Limpar todos
              </Button>
            </>
          )}
        </Stack>
      </MenuList>
    </Menu>
  );
}

export function FilterItem(props: TFilter) {
  const hook = useFilterItemController(props);

  return (
    <FilterItemContext value={hook}>
      <FilterItemContent />
    </FilterItemContext>
  );
}
