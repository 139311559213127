import { useFragment } from '@/gql';
import { ExportFragmentFragment, ExportStatus } from '@/gql/graphql';
import { useActionCable } from '@/hooks/actionCable';
import { BaseExportData } from '@/hooks/RemoteFilesListener/interfaces';
import { createContext } from '@/utils/react-utils';
import { useApolloClient } from '@apollo/client';
import React from 'react';

import { exportFragment, ExportFragmentType } from './useExports';

export function useExportItemController({
  exportItem,
}: {
  exportItem: ExportFragmentType;
}) {
  const client = useApolloClient();
  const { subscribe } = useActionCable();
  const exportItemFragment = useFragment(exportFragment, exportItem);

  const [state, setState] = React.useState<ExportFragmentFragment>(
    exportItemFragment,
  );

  React.useEffect(() => {
    let sub = () => {
      //
    };

    if (
      [ExportStatus.Pending, ExportStatus.Processing].includes(
        exportItemFragment.status!,
      )
    ) {
      sub = subscribe(
        'export',
        (values: BaseExportData) => {
          const nextData = {
            progress: values.progress,
            status: values.status,
            url: values.downloadFileUrl,
          };
          setState(
            (old) =>
              ({
                ...old,
                ...nextData,
              } as ExportFragmentFragment),
          );

          client.cache.updateFragment(
            {
              id: exportItemFragment.id,
              fragment: exportFragment,
            },
            (data) => {
              return {
                ...data,
                ...nextData,
              } as ExportFragmentFragment;
              //
            },
          );
        },
        `${exportItemFragment.token}`,
      );
    }

    return () => {
      sub();
    };
  }, [
    client.cache,
    exportItemFragment.id,
    exportItemFragment.status,
    exportItemFragment.token,
    subscribe,
  ]);

  return {
    exportItem: state,
  };
}

export const [ExportItemContext, useExportItem] = createContext<
  ReturnType<typeof useExportItemController>
>();

export function ExportItemProvider({
  children,
  exportItem,
}: {
  children: React.ReactNode;
  exportItem: ExportFragmentType;
}) {
  return (
    <ExportItemContext value={useExportItemController({ exportItem })}>
      {children}
    </ExportItemContext>
  );
}
