import { IRouteData, RouteTypes } from './interfaces';

class RoutesData {
  public initialRoutesData: IRouteData = {
    path: '',
    name: 'root',
    title: 'root',
    children: {
      signin: {
        name: 'signin',
        path: '/auth',
        title: 'screens.signin',
      },
      app: {
        path: '/app',
        name: 'app',
        title: 'screens.app',
        children: {
          dashboard: {
            name: 'dashboard',
            title: 'screens.dashboard',
            path: '/dashboard',
            recursive: true,
            type: RouteTypes.PRIMARY,
          },
          dash_management: {
            unfinished: true,
            disabled: true,
            name: 'dash_management',
            title: 'screens.dash_management',
            path: '/dash_management',
            type: RouteTypes.PRIMARY,
            children: {
              visions: {
                name: 'screens',
                title: 'screens.visions',
                path: '/visions',
              },
              users: {
                name: 'users',
                title: 'screens.users',
                path: '/users',
              },
            },
          },
          monitoring: {
            name: 'monitoring',
            title: 'screens.monitoring',
            path: '/monitoring',
            type: RouteTypes.PRIMARY,
            children: {
              checklists: {
                name: 'checklists',
                title: 'screens.checklists',
                path: '/checklists',
              },
              feedbacks: {
                name: 'feedbacks',
                title: 'screens.feedbacks',
                path: '/feedbacks',
              },
            },
          },
          registrations: {
            name: 'registrations',
            title: 'screens.registrations',
            path: '/registrations',
            type: RouteTypes.PRIMARY,
            children: {
              schedules: {
                name: 'schedules',
                title: 'screens.schedules',
                path: '/schedules',
                hideStandardSubmenu: true,
              },
              formularies: {
                name: 'formularies',
                title: 'screens.formularies',
                path: '/templates',
              },
              users: {
                name: 'users',
                title: 'screens.users',
                path: '/users',
                hideStandardSubmenu: true,
              },
              locals: {
                name: 'locals',
                title: 'screens.locals',
                path: '/locals',
                hideStandardSubmenu: true,
              },
              collections: {
                name: 'collections',
                title: 'screens.collections',
                path: '/collections',
                hideStandardSubmenu: true,
              },
            },
          },
          tasks: {
            name: 'tasks',
            title: 'screens.tasks',
            path: '/tasks',
            recursive: true,
            type: RouteTypes.PRIMARY,
          },
          audit: {
            name: 'audit',
            title: 'screens.audit',
            path: '/audit',
            recursive: true,
            type: RouteTypes.PRIMARY,
          },
          justifications: {
            name: 'justifications',
            title: 'screens.justifications',
            path: '/justifications',
            recursive: true,
            type: RouteTypes.PRIMARY,
          },
          tickets: {
            name: 'tickets',
            title: 'screens.tickets', // TODO Translate
            path: '/tickets',
            type: RouteTypes.PRIMARY,
            recursive: true,
            isNew: true,
          },
          settings: {
            unfinished: true,
            name: 'settings',
            title: 'screens.settings',
            path: '/settings',
            type: RouteTypes.SECONDARY,
            disabled: true,
          },
          support: {
            unfinished: true,
            name: 'support',
            title: 'screens.support',
            path: '/support',
            type: RouteTypes.SECONDARY,
            disabled: true,
          },
        },
      },
    },
  };

  public routes: IRouteData = this.initialRoutesData;

  public findRoute(pathName: string): IRouteData {
    const rawPathStack = pathName.replace(/\/\s*$/, '').split('/');
    const pathStack = rawPathStack.slice(1);
    let route: IRouteData = this.routes;
    for (let i = 0; i < pathStack.length; i++) {
      try {
        if (route && route.children) {
          route = route.children[pathStack[i]];
        } else i = Infinity;
      } catch (err) {
        if (err instanceof Error) {
          throw err;
        }
      }
    }
    return route;
  }

  public findMainRoute(pathName: string): IRouteData {
    const rawPathStack = pathName.replace(/\/\s*$/, '').split('/');
    const pathStack = rawPathStack.slice(1);
    let route: IRouteData = this.routes;
    for (let i = 0; i < pathStack.length; i++) {
      try {
        if (route && route.type && route.type === RouteTypes.PRIMARY) {
          i = Infinity;
          break;
        }
        if (route && route.children) {
          route = route.children[pathStack[i]];
        } else i = Infinity;
      } catch (err) {
        if (err instanceof Error) {
          throw err;
        }
      }
    }
    return route;
  }

  public setAppRoutes(routes: { [name: string]: IRouteData }): void {
    try {
      const root = this.findMainRoute('');
      const appRoute = this.findMainRoute('/app');

      const newRoutes = {
        ...root,
        children: {
          ...root.children,
          app: {
            ...appRoute,
            children: routes,
          },
        },
      };
      this.routes = newRoutes;
    } catch (err) {
      if (err instanceof Error) {
        throw err;
      }
    }
  }
}

export default new RoutesData();
