import React, { useRef } from 'react';
import { NameInputRenderProps } from './interfaces';
import { InputContainer, Input } from './styles';

const NameInput: React.FC<NameInputRenderProps> = ({
  name,
  onSubmit,
  onChange,
  placeholder,
  isEditable,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      inputRef.current?.blur();
      onSubmit();
    }
  };

  return (
    <InputContainer>
      <Input
        value={name}
        ref={inputRef}
        onChange={onChange}
        onKeyPress={handleKeyDown}
        placeholder={placeholder}
        disabled={!isEditable}
      />
    </InputContainer>
  );
};

export default NameInput;
