import AsyncDropdown from '@/components_v2/inputs/Dropdown/AsyncDropdown';
import {
  Option as DropdownOption,
  RemoteOptionsLoaderParams,
} from '@/components_v2/inputs/Dropdown/interfaces';
import PopoverRemoteSelect from '@/components_v2/inputs/PopoverRemoteSelect';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import Tooltip from '@components/Tooltip';
import React, { useCallback, useMemo } from 'react';

import {
  InfoTooltip,
  QuestionContainer,
  QuestionHeader,
  QuestionSubtitle,
  QuestionTitle,
  TooltipContainer,
} from '../common';
import { RemoteSelectProps } from './interfaces';
import { mapInitialValues } from './utils/mapInitialValues';

const optionMapper = (selected: DropdownOption[] | DropdownOption) => {
  if (!selected) return null;

  const vals = Array.isArray(selected)
    ? selected.map(({ value, label }) => ({
        id: value,
        name: label,
      }))
    : {
        id: selected.value,
        name: selected.label,
      };
  return vals;
};
interface RemoteOption {
  id: string;
  name: string;
}
interface FecherResponse {
  hasNextPage: boolean;
  items: RemoteOption[];
}

const makeFetcher = (baseUrl: string) => async ({
  page,
  searchString,
}: RemoteOptionsLoaderParams) => {
  const url = `${baseUrl}&page=${page}${
    searchString ? `&search=${searchString}` : ''
  }`;
  const { body, statusCode } = await AxiosHelper.get<FecherResponse>({ url });
  if (statusCode >= 200 && statusCode <= 300) {
    const { hasNextPage, items } = body;
    const options = items.map((el) => ({ label: el.name, value: el.id }));
    return {
      hasNext: hasNextPage,
      options,
    };
  }

  throw new Error('Error fetching remote options...');
};

const RemoteSelect: React.FC<RemoteSelectProps> = ({
  questionProps,
  isMulti = false,
}) => {
  const {
    question,
    errors,
    initialValues,
    onChange,
    viewOnly = false,
  } = questionProps;
  const optionsUrl = question.config?.optionsUrl;

  const onSubumit = (selected: DropdownOption[] | DropdownOption) => {
    const parsed = optionMapper(selected);
    onChange(parsed);
  };

  const handleChange = useCallback(
    (selected: string[]) => {
      onChange(
        isMulti
          ? selected.map((id) => ({
              id,
            }))
          : selected[0],
      );
    },
    [onChange, isMulti],
  );

  const optionsFetcher = useMemo(() => makeFetcher(optionsUrl || ''), [
    optionsUrl,
  ]);

  const parsedInitialValues = useMemo(
    () => mapInitialValues(initialValues?.values),
    [initialValues?.values],
  );

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionTitle>{question.name}</QuestionTitle>
        {question.tipText && (
          <Tooltip description={question.tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      {isMulti && optionsUrl ? (
        <PopoverRemoteSelect
          onChange={handleChange}
          remoteUrl={optionsUrl}
          initialValues={initialValues?.values}
          disabled={viewOnly}
        />
      ) : (
        <AsyncDropdown
          isMulti={isMulti}
          hasError={!!errors}
          onChange={onSubumit}
          defaultValue={parsedInitialValues}
          optionsLoader={optionsFetcher}
          isDisabled={viewOnly}
        />
      )}

      <QuestionSubtitle hasError={!!errors}>
        {errors && errors.errors}
      </QuestionSubtitle>
    </QuestionContainer>
  );
};

export default RemoteSelect;
