import * as React from 'react';
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useFormContext,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';

export interface DisplayIfProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  children: React.ReactElement;
  name: TName;
  defaultValue?: FieldPathValue<TFieldValues, TName>;
  isDisabled?: boolean;
  isExact?: boolean;
  condition?: (value: unknown, context: UseFormReturn<TFieldValues>) => boolean;
}
/**
 * Conditionally render parts of a form.
 *
 * @see Docs https://saas-ui.dev/docs/components/forms/form
 */
export const DisplayIf = <TFieldValues extends FieldValues = FieldValues>({
  children,
  name,
  defaultValue,
  isDisabled,
  isExact,
  condition = (value) => !!value,
}: DisplayIfProps<TFieldValues>) => {
  const value = useWatch({
    name,
    defaultValue,
    disabled: isDisabled,
    exact: isExact,
  });
  const context = useFormContext<TFieldValues>();
  return condition(value, context) ? children : null;
};

DisplayIf.displayName = 'DisplayIf';
