import { FiChevronDown, FiX } from 'react-icons/fi';
import PulseLoader from 'react-spinners/PulseLoader';
import styled, { css } from 'styled-components';

import { ButtonType } from './interfaces';

interface ButtonProps {
  buttonType: ButtonType;
  isExpansible?: boolean;
}

export const Container = styled.button<ButtonProps>`
  outline: none;
  min-width: 50px;
  width: fit-content;
  height: 35px;
  border: none;
  background-color: ${({ buttonType, theme, disabled }) => {
    if (disabled) {
      switch (buttonType) {
        case 'PRIMARY_BACKGROUND':
          return theme.colors.v2_secondary;
        case 'GREY_BACKGROUND':
          return theme.colors.v2_secondary;
        case 'ITEM_LIST':
          return theme.colors.v2_secondary;
        default:
          return 'transparent';
      }
    } else {
      switch (buttonType) {
        case 'DROPDOWN':
        case 'PRIMARY_BACKGROUND':
          return theme.colors.primary;
        case 'GREY_BACKGROUND':
        case 'ITEM_LIST':
          return theme.colors.v2_secondary;
        default:
          return 'transparent';
      }
    }
  }};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: flex;
  padding: 5px 15px;
  border-radius: 50px;
  justify-content: center;
  transition: background-color 200ms ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
  }

  :hover {
    opacity: ${({ disabled }) => {
      if (disabled) return '';
      return '0.8';
    }};
  }
  :active {
    background: ${({ buttonType, theme, disabled }) => {
      if (disabled) return '';
      switch (buttonType) {
        case 'PRIMARY_BACKGROUND':
          return theme.colors.primary;
        case 'GREY_BACKGROUND':
          return theme.colors.v2_secondary;
        case 'ITEM_LIST':
          return theme.colors.v2_secondary;
        default:
          return 'transparent';
      }
    }};
  }

  ${({ isExpansible }) => {
    if (!isExpansible) return null;
    return css`
      transition: 120ms;
      padding: 5px 0px;
      overflow: hidden;
      .quantity {
        width: 10px;
        animation: grow-down-content 120ms forwards;
        @keyframes grow-down-content {
          from {
            transform: scale(0.7) translateY(-1px) translateX(5px);
          }
          to {
            transform: scale(0.6) translateY(5px) translateX(-6px);
          }
        }
      }
      .visible-on-hover {
        animation: hide-content 120ms forwards;
        @keyframes hide-content {
          from {
            transform: scale(1) translateX(0);
            svg {
              margin-right: 5px;
            }
          }
          to {
            transform: scale(0) translateX(-110%);
            width: 0px;
            svg {
              margin-right: 0px;
            }
          }
        }
      }

      svg {
        margin-right: 0px;
      }
      :hover {
        padding: 5px 12px;
        .quantity {
          margin-right: 10px;
          animation: grow-content 200ms forwards;
          @keyframes grow-content {
            from {
              transform: scale(0.6) translateY(5px) translateX(-6px);
            }
            to {
              transform: scale(0.7) translateY(-1px) translateX(5px);
            }
          }
        }
        .visible-on-hover {
          animation: show-content 120ms forwards;
          @keyframes show-content {
            from {
              transform: scale(0) translateX(-100%);
            }
            to {
              transform: scale(1) translateX(0);
            }
          }
        }

        svg {
          margin-right: 5px;
        }
      }
    `;
  }}
`;

export const ButtonText = styled.p<ButtonProps>`
  font-weight: 400;
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 0.9em;
  color: ${({ buttonType, theme }) => {
    const isPrimary: boolean =
      buttonType === 'PRIMARY_BACKGROUND' || buttonType === 'DROPDOWN';
    const isCancel: boolean = buttonType === 'CANCEL';
    const isConfirm: boolean = buttonType === 'CONFIRM';
    const isGrey: boolean = buttonType === 'GREY_BACKGROUND';
    if (isPrimary) return theme.colors.lighterText;
    if (isCancel) return theme.colors.warning;
    if (isConfirm) return theme.colors.secondary;
    if (isGrey) return theme.colors.darkerText;
    return theme.colors.darkText;
  }};

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export const IconX = styled(FiX)`
  width: 10px;
  height: 100%;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconChevronDown = styled(FiChevronDown)`
  width: 25px;
  height: 25px;
  display: block;
  flex-shrink: 0;
  backface-visibility: hidden;
`;

export const IconLoading = styled(PulseLoader)``;

export const Quantity = styled.p`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme }) => theme.colors.darkerText};
`;
