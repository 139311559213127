import { IconChevronRight } from '@/components_v2/ContextMenu/Icons';
import RemoveExtraContainer from '@/components_v2/ContextMenu/RemoveExtraContainer';
import { useFilter } from '@/hooks/Filters/filter';
import moment from 'moment';
import React, { useMemo, useCallback } from 'react';

import { ItemExtraProps } from './interfaces';

import { FilterContainer, FilterText, RemoveFilterIcon } from './styles';

const ItemExtra: React.FC<ItemExtraProps> = ({ filter }) => {
  const { selectedOptions, clearOption } = useFilter();

  const text = useMemo(() => {
    const { column, type } = filter;
    if (selectedOptions[column] && selectedOptions[column][type]) {
      const selected = selectedOptions[column][type];
      if (selected) {
        switch (selected.type) {
          case 'multi':
            return selected.selected.length;
          case 'single':
          case 'date':
            return 1;
          case 'date_range':
            return (
              moment(selected.selected.dateEnd).diff(
                moment(selected.selected.dateStart),
                'days',
              ) + 1
            );
          default:
            undefined;
        }
      }
    }
    return undefined;
  }, [filter, selectedOptions]);

  const handleClearFilter = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      clearOption({ ...filter });
    },
    [filter, clearOption],
  );

  return text ? (
    <RemoveExtraContainer text={text.toString()} onClick={handleClearFilter} />
  ) : (
    <></>
  );
};

export default ItemExtra;
