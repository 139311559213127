import { DiscussionTopic, FeedbackType } from '@/hooks/Feedback/interfaces';
import React from 'react';
import { useCreateFeedback } from './useCreateFeedback';
import { useFeedbackQuery } from './useFeedbackQuery';

type TUseFeedbackReactions = {
  isLoading: boolean;
  topic: DiscussionTopic | undefined;
  onLike: () => void;
  onDislike: () => void;
};

type UseFeedbackReactionsProps = {
  topicId: string;
  topic?: DiscussionTopic;
};

export function useFeedbackReactions(
  props: UseFeedbackReactionsProps,
): TUseFeedbackReactions {
  const { topicId } = props;
  const { data: topic } = useFeedbackQuery(topicId, {
    initialData: props.topic,
    enabled: true,
  });

  const { mutateAsync: mutateLikeAsync, isLoading } = useCreateFeedback();

  const onFeedback = React.useCallback(
    async (feedbackType: FeedbackType) => {
      if (topic) {
        await mutateLikeAsync({
          discussionTopicId: topic.id,
          vote: feedbackType,
        });
      }
    },
    [mutateLikeAsync, topic],
  );

  const onDislike = React.useCallback(() => {
    if (topic?.voteCurrentUser === 'dislike') {
      onFeedback('none');
      return;
    }
    onFeedback('dislike');
  }, [topic?.voteCurrentUser, onFeedback]);

  const onLike = React.useCallback(() => {
    if (topic?.voteCurrentUser === 'like') {
      onFeedback('none');
      return;
    }
    onFeedback('like');
  }, [topic?.voteCurrentUser, onFeedback]);

  return {
    onDislike,
    onLike,
    isLoading,
    topic,
  };
}
