import config from '@/Constants';
import AxiosHelper from '@shared/infra/protocols/http/AxiosHelper';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useSWR from 'swr';

import { useAuth } from '../Authentication/auth';
import {
  NotificationsListContextData,
  NotificationsListProviderProps,
  NotificationType,
  WCNotification,
} from './interfaces';
import { useNotifications } from './notifications';
import { markAsReadService } from './services';
import { readAllService } from './services/readAllService';

const NotificationsListContext = createContext<NotificationsListContextData>(
  {} as NotificationsListContextData,
);

export const NotificationsListProvider: React.FC<NotificationsListProviderProps> = ({
  children,
  read,
}) => {
  const [filters, setFilters] = useState<NotificationType[]>([]);
  const { user } = useAuth();
  const { setPendingNotificationsCount } = useNotifications();
  const [notifications, setNotifications] = useState<WCNotification[]>([]);
  const { data, isValidating } = useSWR<WCNotification[]>(
    `${config.NOTIFICATION_URL}?read=${read}${filters.reduce(
      (acc, f) => `${acc}&types[]=${f}`,
      '',
    )}`,
    (url: string) =>
      AxiosHelper.get<WCNotification[]>({
        url,
        apiV: 2,
      }).then((res) => res.body),
    {
      refreshWhenOffline: false,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      refreshInterval: 10000,
    },
  );

  useEffect(() => {
    if (!read && filters.length === 0 && notifications.length === 0) {
      setPendingNotificationsCount(0);
    }
  }, [
    read,
    filters.length,
    notifications.length,
    setPendingNotificationsCount,
  ]);

  useEffect(() => {
    if (data) {
      setNotifications(data);
    }
  }, [data]);

  const addOrRemoveFilters = useCallback(
    (types: NotificationType[]) => {
      const newFilters: NotificationType[] = [];
      const removeFilters: NotificationType[] = [];
      for (let i = 0; i < types.length; i++) {
        if (filters.includes(types[i])) {
          removeFilters.push(types[i]);
        } else {
          newFilters.push(types[i]);
        }
      }
      setFilters([
        ...filters.filter((f) => !removeFilters.includes(f)),
        ...newFilters,
      ]);
    },
    [filters],
  );

  const markAsRead = useCallback(
    ({ notificationUserId, id }: WCNotification) => {
      if (!read) {
        markAsReadService(notificationUserId);
        setNotifications((previous) => previous.filter((n) => n.id !== id));
      }
    },
    [read],
  );

  const readAll = useCallback(() => {
    if (user) {
      readAllService(user.id);
      setNotifications([]);
      setPendingNotificationsCount(0);
    }
  }, [user, setPendingNotificationsCount]);

  return (
    <NotificationsListContext.Provider
      value={{
        notifications,
        isValidating,
        filters,
        markAsRead,
        read,
        readAll,
        addOrRemoveFilters,
      }}
    >
      {children}
    </NotificationsListContext.Provider>
  );
};

export function useNotificationsList(): NotificationsListContextData {
  const context = useContext(NotificationsListContext);

  if (!context) {
    throw new Error(
      'useNotifications must be used whithin an NotificationsProvider',
    );
  }

  return context;
}
