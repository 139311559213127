export enum WorkspaceValidationStatus {
  IDLE,
  VALIDATING,
  VALID,
  INVALID,
}

export interface WorkspaceSignInProps {
  handleOnChange: (val: string) => void;
  handleSubmit: () => void;
  workspaceName: string;
  validWorkspace: WorkspaceValidationStatus;
}
