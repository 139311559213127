/* eslint-disable no-nested-ternary */
import { useFragment } from '@/gql';
import {
  DelayTypeTriggers,
  StepsCreateInput,
  StepsUpdateInput,
  StepTypes,
} from '@/gql/graphql';
import { useModals } from '@/hooks/ModalManager';
import { useCreateAutomationStep } from '@/screens/Tickets/hooks/useCreateAutomationStep';
import {
  AutomationStep_StepFragment,
  AutomationStepFragment,
  useFetchAutomationStep,
} from '@/screens/Tickets/hooks/useFetchAutomationSteps';
import { queryAutomationFlow } from '@/screens/Tickets/hooks/useFetchFlow';
import { useUpdateAutomationStep } from '@/screens/Tickets/hooks/useUpdateAutomationStep';
import { createContext } from '@/utils/react-utils';
import { useToast } from '@chakra-ui/react';
import { castArray } from 'lodash';
import React from 'react';

import { ActionSchema, StepSchema } from './form-schemas';

const getFragmentData = useFragment;

export type StepFormParams = {
  automationFlowId: string;
  position?: number;
  stepId?: string;
};

const stepFormService = (params: StepFormParams) => {
  const { automationFlowId, stepId, position: paramsPosition } = params;
  const beforePost = (formValues: StepSchema): StepsCreateInput => {
    const {
      assignType,
      name,
      stepType,
      triggers,
      actions,
      members,
      position,
      taskForm,
      localSet,
      localType,
      memberQuestions,
      localTicketProperty,
      autoFinish,
    } = formValues;

    return {
      name,
      stepType,
      automationFlowId,
      position: position || paramsPosition,
      actionEmailChecklist: actions
        ?.filter((e) => e.actionableType === 'email_checklist')
        .map(
          ({ emailAttribute, label, sendChecklistOpenFlow, id, destroy }) => ({
            id,
            sendChecklistOpenFlow: !!sendChecklistOpenFlow,
            name: label,
            questionId: emailAttribute?.[0]?.id as string,
            destroy,
          }),
        ),
      actionEmailUser: actions
        ?.filter((e) => e.actionableType === 'email_user')
        .map(({ user, label, sendChecklistOpenFlow, id, destroy }) => ({
          id,
          sendChecklistOpenFlow: !!sendChecklistOpenFlow,
          name: label,
          value: user?.map((item) => item.id) || [],
          destroy,
        })),
      actionWebhooks: actions
        ?.filter((e) => e.actionableType === 'webhook')
        .map(({ label, webhookUrl, id, destroy }) => ({
          id,
          name: label,
          destroy,
          endpoint: webhookUrl,
        })),
      triggers: triggers?.map(
        ({
          label,
          triggerable,
          triggerableType,
          id,
          destroy,
          dateQuestion,
          delayType,
        }) => ({
          id,
          name: label,
          dateQuestionId: dateQuestion?.[0]?.id,
          delayType: delayType as DelayTypeTriggers,
          triggerableId: triggerable?.[0]?.id,
          destroy,
          triggerableType,
        }),
      ),
      actionPropertyUpdate: actions
        ?.filter((e) => e.actionableType === 'update_property')
        .map(({ question, id, destroy, attribute, label }) => ({
          automationPropertyId: attribute?.[0].id || '',
          questionId: question?.[0].id || '',
          destroy,
          name: label,
          id,
        })),
      actionTask: {
        formularyId: taskForm?.[0]?.id,
        memberSetId: assignType === 'user_id' ? members?.[0]?.id : null,
        memberTask: assignType === 'member_task',
        lastMemberTask: assignType === 'last_member_task',
        localTask: localType === 'local_opening_task',
        localTicketPropertyId:
          localType === 'local_ticket_property'
            ? localTicketProperty?.[0].id
            : undefined,
        localSetId: localSet ? localSet?.[0].id : undefined,
        memberQuestionId:
          assignType === 'member_question'
            ? memberQuestions?.[0]?.id
            : undefined,
      },
      autoFinish,
    };
  };

  const beforePatch = (formValues: StepSchema): StepsUpdateInput => {
    const { automationFlowId: _omitted, ...otherParams } = stepFormService(
      params,
    ).beforePost(formValues);

    return { id: stepId!, ...otherParams };
  };

  const serialize = (fragment: AutomationStepFragment): StepSchema => {
    const {
      id,
      name,
      stepType,
      actionEmailChecklist,
      actionEmailUser,
      actionPropertyUpdate,
      actionWebhooks,
      actionTask,
      triggers,
      position,
      autoFinish,
    } = getFragmentData(AutomationStep_StepFragment, fragment);

    const actions: ActionSchema[] = [
      /** ações do tipo e-mail */
      ...(actionEmailChecklist?.map(
        (action) =>
          ({
            id: action.id,
            label: action.name || '',
            actionableType: 'email_checklist',
            sendChecklistOpenFlow: action.sendChecklistOpenFlow,
            emailAttribute: castArray({
              id: action.question?.id || '',
              name: `${action.question?.name}`,
            }),
          } as ActionSchema),
      ) || []),
      ...(actionEmailUser?.map(
        (action) =>
          ({
            id: action.id,
            label: action.name || '',
            actionableType: 'email_user',
            sendChecklistOpenFlow: action.sendChecklistOpenFlow,
            user: action?.users?.map((item) => {
              return {
                id: item.id || '',
                email: `${item.email}`,
                name: `${item.fullName}`,
              };
            }),
          } as ActionSchema),
      ) || []),
      /** ações do tipo webhook */
      ...(actionWebhooks?.map(
        (action) =>
          ({
            id: action.id,
            label: action.name || '',
            actionableType: 'webhook',
            webhookUrl: action.endpoint,
          } as ActionSchema),
      ) || []),
      /** ações do tipo atualização de propriedade */
      ...(actionPropertyUpdate?.map(
        (action) =>
          ({
            actionableType: 'update_property',
            id: action.id,
            label: action.name || '',
            question: action.question
              ? castArray({
                  id: action.question?.id,
                  name: action.question?.name,
                })
              : undefined,
            attribute: castArray({
              id: action.automationProperty?.id || '',
              name: action.automationProperty?.name,
            }),
          } as ActionSchema),
      ) || []),
    ];

    return {
      id,
      name: name || '',
      stepType: stepType || StepTypes.Opening,
      autoFinish: !!autoFinish,
      assignType: actionTask?.memberSetId
        ? 'user_id'
        : actionTask?.memberTask
        ? 'member_task'
        : actionTask?.memberQuestion
        ? 'member_question'
        : actionTask?.lastMemberTask
        ? 'last_member_task'
        : 'none',
      triggers:
        triggers?.map(
          ({
            id,
            name,
            triggerableId,
            triggerableType,
            triggerableName,
            delayType,
            dateQuestion,
          }) => ({
            id,
            label: name || 'Sem etiqueta',
            triggerable: [{ id: triggerableId, name: triggerableName || '' }],
            triggerableType,
            delayType: delayType || 'immediate',
            dateQuestion: dateQuestion
              ? [{ id: dateQuestion.id, name: `${dateQuestion.name}` }]
              : [],
            _local: false,
            destroy: false,
          }),
        ) || [],
      members: actionTask?.memberSet
        ? castArray({
            id: actionTask?.memberSet.id || '',
            name: actionTask?.memberSet.fullName || '',
          })
        : [],
      taskForm: actionTask?.formulary
        ? castArray({
            id: actionTask?.formulary.id,
            name: actionTask?.formulary.name || '',
          })
        : [],
      actions: actions as StepSchema['actions'],
      localSet: actionTask?.localSet
        ? castArray({
            id: actionTask?.localSet.id,
            name: actionTask?.localSet.name || '',
          })
        : undefined,
      localTicketProperty: actionTask?.localTicketProperty
        ? castArray({
            id: actionTask?.localTicketProperty.id,
            name: actionTask?.localTicketProperty.name || '',
          })
        : undefined,
      memberQuestions: actionTask?.memberQuestion
        ? castArray(actionTask?.memberQuestion as any)
        : undefined,
      localType: actionTask?.localTask
        ? 'local_opening_task'
        : actionTask?.localSet
        ? 'local_id'
        : actionTask?.localTicketProperty
        ? 'local_ticket_property'
        : 'none',
      position,
    };
  };

  return {
    beforePatch,
    beforePost,
    serialize,
  };
};

// queryAutomationFlow
export function useStepForm(params: StepFormParams) {
  const toast = useToast();
  const modals = useModals();
  const { stepId } = params;
  const { createAutoStep, loading: creating } = useCreateAutomationStep();
  const { updateAutoStep, loading: updating } = useUpdateAutomationStep();
  const { data, loading: loadingStepData } = useFetchAutomationStep(stepId);

  const onCreate = React.useCallback(
    (values: StepSchema) => {
      createAutoStep({
        variables: {
          input: stepFormService(params).beforePost(values),
        },
        refetchQueries: [queryAutomationFlow],
        onCompleted() {
          modals.close('board-create-form');
          toast({
            title: 'Etapa criada com sucesso',
            status: 'success',
          });
        },
      });
    },
    [createAutoStep, modals, params, toast],
  );

  const defaultValue = React.useMemo(
    () =>
      data?.automationStep
        ? stepFormService(params).serialize(data?.automationStep)
        : undefined,
    [data?.automationStep, params],
  );

  const onUpdate = React.useCallback(
    (values: StepSchema) => {
      updateAutoStep({
        variables: {
          input: stepFormService(params).beforePatch(values),
        },
        refetchQueries: [queryAutomationFlow],
        onCompleted() {
          modals.close('board-create-form');
          toast({
            title: 'Etapa atualizada com sucesso',
            status: 'success',
          });
        },
      });
    },
    [modals, params, toast, updateAutoStep],
  );

  const onSubmit = params.stepId ? onUpdate : onCreate;

  return {
    defaultValue,
    loadingStepData,
    onSubmit,
    loading: creating || updating,
    ...params,
  };
}

type UseStepForm = ReturnType<typeof useStepForm>;

export const [StepFormProvider, useStepFormContext] = createContext<
  UseStepForm
>();
