import React from 'react';

import Modal from 'react-modal';

import { ModalRenderProps } from './interfaces';
import { generateModalStyle } from './styles';

const Main: React.FC<ModalRenderProps> = ({
  children,
  styles,
  modalStyles,
  ...rest
}) => {
  return (
    <Modal
      style={generateModalStyle(styles, modalStyles)}
      closeTimeoutMS={150}
      {...rest}
      appElement={document.getElementById('root') || undefined}
    >
      {children}
    </Modal>
  );
};

export default Main;
