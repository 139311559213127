import i18n from '@/i18n';
import { EventStatusType } from '@/modules/core/domain/interfaces';
import { Tag, TagLabel } from '@chakra-ui/react';
import React from 'react';

import { eventStatusCorrelation, taskStatusCorrelation } from '../utils';

const StatusTag: React.FC<{
  status: EventStatusType;
  type: 'event' | 'task';
}> = ({ status, type }) => {
  const { bg, color, label } =
    type === 'event'
      ? eventStatusCorrelation[status]
      : taskStatusCorrelation[status];

  return (
    <Tag rounded="md" size="md" borderColor={color} bgColor={bg}>
      <TagLabel fontWeight="semibold" fontSize="13px" color={color}>
        {i18n.t(label)}
      </TagLabel>
    </Tag>
  );
};

export default StatusTag;
