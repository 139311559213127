import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

import { BaseModalProps } from './types/BaseModal.types';

export const Modal: React.FC<BaseModalProps> = (props) => {
  const {
    title,
    footer,
    children,
    isOpen,
    onClose,
    hideCloseButton,
    hideOverlay,
    size,
    ...rest
  } = props;

  return (
    <ChakraModal
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      isCentered
      {...rest}
    >
      {!hideOverlay && <ModalOverlay />}
      {isOpen && (
        <ModalContent
          borderRadius="md"
          overflow="clip"
          w={size && size === 'full' ? '98vw' : undefined}
          h={size && size === 'full' ? '98vh' : undefined}
          minH={size && size === 'full' ? '98vh' : undefined}
        >
          {title && <ModalHeader>{title}</ModalHeader>}
          {!hideCloseButton && <ModalCloseButton />}
          {children}
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </ModalContent>
      )}
    </ChakraModal>
  );
};

export const DefaultModal: React.FC<BaseModalProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Modal {...rest}>
      <ModalBody padding="0" display="flex">
        {children}
      </ModalBody>
    </Modal>
  );
};
