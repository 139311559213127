import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import debounce from '@/utils/debounce';
import { useMaskedInput } from '@/utils/Masks';
import Masks from '@/utils/Masks/Masks';
import { Flex, Input, Textarea } from '@chakra-ui/react';
import React from 'react';

import FormControlComponent from '../FormControlComponent';

const TextContent: React.FC<QuestionModel & { isParagraph?: boolean }> = ({
  inputMask,
  isParagraph,
}) => {
  const { onChange, fieldState, error } = useField();

  const debouncedOnChange = React.useMemo(() => debounce(onChange, 300), [
    onChange,
  ]);

  const [value, setValue] = React.useState(() => {
    if (fieldState && !Array.isArray(fieldState)) {
      return fieldState.content;
    }
    return '';
  });

  const handleChange = React.useCallback(
    (newValue: string) => {
      setValue(newValue);
      debouncedOnChange(createAnswer(newValue));
    },
    [debouncedOnChange],
  );

  const { onChangeText, ...rest } = useMaskedInput({
    value,
    onChangeText: handleChange,
    mask: inputMask && Masks[inputMask],
  });

  if (isParagraph) {
    return (
      <Flex w="full">
        <FormControlComponent isInvalid={error}>
          <Textarea
            w="full"
            onChange={(e) => onChangeText(e.target.value)}
            {...rest}
          />
        </FormControlComponent>
      </Flex>
    );
  }

  return inputMask || inputMask !== 'NONE' ? (
    <Flex w="full">
      <FormControlComponent isInvalid={error} mask={inputMask}>
        <Input
          w="full"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeText(e.target.value)
          }
          {...rest}
        />
      </FormControlComponent>
    </Flex>
  ) : (
    <Flex w="full">
      <FormControlComponent isInvalid={error}>
        <Input
          w="full"
          onChange={(e) => handleChange(e.target.value)}
          value={value}
        />
      </FormControlComponent>
    </Flex>
  );
};

export default TextContent;
