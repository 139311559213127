import { FieldDependencyMap, FieldStateMap } from '../interfaces';

/** dependency map with root questions and its children */
export const buildDependencyMap = (
  fields: FieldStateMap,
): FieldDependencyMap => {
  return Object.entries(fields).reduce((prev, [_, value]) => {
    let nextState = { ...prev };

    if (value.parentFieldId) {
      const prevArr = prev[value.parentFieldId] || [];
      nextState = {
        ...prev,
        [value.parentFieldId]: [...prevArr, value.id],
      };
    }
    return nextState;
  }, {} as FieldDependencyMap);
};
