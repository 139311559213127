/* eslint-disable no-case-declarations */
import {
  RecurrenceGeneratorReducer,
  RecurrenceGeneratorState,
} from '../interfaces/IRecurrenceGeneratorReducer';
import { recurrenceTemplates } from './templates';

export const initialState: RecurrenceGeneratorState = {
  recurrencyTemplateType: undefined,
  recurrenceRule: undefined,
};

export const recurrenceGeneratorReducer: RecurrenceGeneratorReducer = (
  state,
  action,
) => {
  switch (action.type) {
    case 'change_date_range':
      return {
        ...state,
        recurrenceRule: {
          ...state.recurrenceRule,
          ...action.props,
        },
      };
    case 'change_rule':
      if (state.recurrenceRule) {
        return {
          ...state,
          recurrenceRule: {
            ...state.recurrenceRule,
            typeRecurrent: action.props,
            byWeekday: [],
          },
        };
      }
      return state;
    case 'change_template':
      const template = recurrenceTemplates[action.props]({
        dateStart: state.recurrenceRule?.dateStart || undefined,
        dateEnd: state.recurrenceRule?.dateEnd || undefined,
      });
      return {
        ...state,
        recurrenceRule: template,
        recurrencyTemplateType: action.props,
      };
    case 'change_interval':
      if (state.recurrenceRule) {
        return {
          ...state,
          recurrenceRule: {
            ...state.recurrenceRule,
            ruleInterval: action.props > -1 ? Number(action.props) : 0,
          },
        };
      }
      return state;
    case 'change_week_days':
      const newWeekdays = state?.recurrenceRule?.byWeekday || [];
      const idx: number = newWeekdays.indexOf(action.props);

      if (idx !== -1 && state.recurrenceRule) {
        return {
          ...state,
          recurrenceRule: {
            ...state.recurrenceRule,
            byWeekday: [
              ...newWeekdays.slice(0, idx),
              ...newWeekdays.slice(idx + 1),
            ],
          },
        };
      }
      if (state.recurrenceRule) {
        return {
          ...state,
          recurrenceRule: {
            ...state.recurrenceRule,
            byWeekday: [...newWeekdays, action.props],
          },
        };
      }
      return state;
    default:
      return state;
  }
};
