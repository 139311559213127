import config from '@/Constants';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { Task } from '@/screens/registrations/Tasks/interfaces';

async function updateTaskService(task: PartialWithId<Task>): Promise<boolean> {
  const { statusCode } = await AxiosHelper.patch({
    url: `${config.TASKS_URL}/${task.id}`,
    body: task,
  });
  if (statusCode < 200 || statusCode > 300) {
    throw new UnexpectedError();
  }
  return true;
}

export { updateTaskService };
