import React from 'react';

import { DropdownProps, Option } from './interfaces';
import { Dropdown } from './styles';

const getDefaultValue = (
  param: Option | string | undefined,
  options: Option[],
): Option | undefined => {
  if (typeof param === 'string') {
    const idx = options.findIndex((el) => `${el.value}` === `${param}`);
    if (idx === -1) return undefined;
    return {
      ...options[idx],
      label: options[idx].label,
    };
  }
  return param;
};

const BaseDropdown: React.FC<DropdownProps> = (props) => {
  const { defaultValue, options, disabled = false } = props;
  const defaultVals = getDefaultValue(defaultValue, options);

  const translatedOptions = options.map((op) => ({
    ...op,
    label: op.label,
  }));

  const parsedDefaultVals = defaultVals
    ? {
        ...defaultVals,
        label: defaultVals?.label,
      }
    : undefined;

  return (
    <Dropdown
      {...{
        ...props,
        defaultValue: parsedDefaultVals,
        options: translatedOptions,
      }}
      classNamePrefix="simple_dropdown"
      isDisabled={disabled}
      cacheOptions
      placeholder="Selecionar..."
    />
  );
};

export default BaseDropdown;
