/* eslint-disable react/destructuring-assignment */
import { AnswerContent } from './answer-content';
import { Header } from './header';
import { ReportContext } from './hooks/use-context';
import { ImagesGrid } from './images-grid';
import { ItemsList } from './items-list';
import { QuestionList } from './question-list';
import { SectionsList } from './sections-list';
import type { EventReportData } from './types';

export function RREmbalagensReport(props: { reportData: EventReportData }) {
  return (
    <ReportContext value={props.reportData}>
      <div>
        <div
          style={{
            height: '90%',
            width: '100vw',
            flex: '1',
            backgroundColor: '#ffffff',
            fontFamily: 'sans-serif',
          }}
        >
          <div
            style={{
              margin: '0 auto',
              height: 'auto',
              maxWidth: '1024px',
            }}
          >
            <Header />
            <ItemsList>
              <SectionsList>
                <QuestionList>
                  <AnswerContent />
                </QuestionList>
              </SectionsList>
            </ItemsList>
            <ImagesGrid />
          </div>
        </div>
      </div>
    </ReportContext>
  );
}
