import React from 'react';

import Main from './Main';

import { SubMenuBarProps } from '../../interfaces';

const SubMenuBar: React.FC<SubMenuBarProps> = ({
  subList,
  subSelected,
  setSubSelected,
  isMainMenuOpen,
  mainTitle,
  collapse,
  isCollapsed,
}) => {
  return (
    <Main
      isCollapsed={isCollapsed}
      collapse={collapse}
      list={subList}
      selected={subSelected}
      isMainMenuOpen={isMainMenuOpen}
      setSelected={setSubSelected}
      mainTitle={mainTitle}
    />
  );
};

export default SubMenuBar;
