import { Property } from '@/gql/types';
import { createContext } from '@/utils/react-utils';
import { Menu, MenuList, Portal, useControllableState } from '@chakra-ui/react';
import React from 'react';

import { PropertyOptions } from './PropertyOptions';

export type PropertyMenuProps = {
  property: PartialWithId<Property>;
  children?: React.ReactNode;
};

export function usePropertyMenuHook(props: PropertyMenuProps) {
  const [property, setProperty] = useControllableState({
    value: props.property,
    defaultValue: props.property,
  });

  return {
    property,
    setProperty,
  };
}

export type UsePropertyMenu = ReturnType<typeof usePropertyMenuHook>;

const [PropertyMenuProvider, usePropertyMenu] = createContext<
  UsePropertyMenu
>();

export { PropertyMenuProvider, usePropertyMenu };

export function PropertyMenu(props: PropertyMenuProps) {
  const { children } = props;
  return (
    <PropertyMenuProvider value={usePropertyMenuHook(props)}>
      <Menu isLazy lazyBehavior="keepMounted">
        {children}
        <Portal>
          <MenuList minWidth="240px">
            <PropertyOptions.EditProperty />
          </MenuList>
        </Portal>
      </Menu>
    </PropertyMenuProvider>
  );
}
