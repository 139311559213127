import React from 'react';
import { useQuery } from 'react-query';
import {
  fetchAttachments,
  FetchAttachmentsParams,
  getFetchAttachmentsQueryKey,
} from '../services';

export function useItemAttachmentsQuery(
  params: FetchAttachmentsParams,
  enabled: boolean = true,
) {
  const queryKey = React.useMemo(() => getFetchAttachmentsQueryKey(params), [
    params,
  ]);

  const query = useQuery([queryKey], () => fetchAttachments(params), {
    structuralSharing: true,
    refetchOnWindowFocus: false,
    enabled,
  });

  return query;
}
