import { FeedbackError } from '@/components/Feedback';
import { useChecklistSummaryDrawer } from '@/components_v2/ChecklistSummary';
import { topicoPrimeAdminIds } from '@/components_v2/ChecklistSummary/componets/TaskActions';
import { formatTaskData } from '@/components_v2/ChecklistSummary/useChecklistSummary';
import PDFDownloadButton from '@/components_v2/PDFDownloadButton';
import { useFragment } from '@/gql';
import {
  AutomationSteps_FlowFragmentFragment,
  StepTypes,
  TaskTypes,
  TicketStep,
} from '@/gql/graphql';
import { Property } from '@/gql/types';
import { useAuth } from '@/hooks/Authentication/auth';
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useModals } from '@/hooks/ModalManager';
import { changeTaskStatus } from '@/screens/registrations/Tasks/services/changeTaskStatus';
import { startTaskEvent } from '@/screens/registrations/Tasks/services/startTaskEvent';
import { useAutomationFlow } from '@/screens/Tickets/context/automationFlow';
import {
  AutomationTicketFragment,
  AutomationTicketFragmentType,
} from '@/screens/Tickets/hooks/useFetchTickets';
import { useUpdateTicketStatus } from '@/screens/Tickets/hooks/useUpdateTicketStatus';
import { CustomIcons } from '@/screens/Tickets/utils/customIcons';
import camelToSnake from '@/utils/camelToSnake';
import { openInNewTab } from '@/utils/openInNewTab';
import {
  Badge,
  Button,
  ButtonGroup,
  Checkbox,
  Circle,
  CSSObject,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiExternalLink } from 'react-icons/fi';

import { useCloneTicketForm } from '../../../Forms/CloneTicketForm';
import { Card } from '../../../misc/Card';
import { PropertyMenu } from '../PropertyMenu';
import { GI, ShowContentPopover } from './TicketsList.components';

const getStatusProps = (status: TaskTypes) => {
  switch (status) {
    case TaskTypes.Done:
      return { label: 'tasks.status.done', color: 'green' };
    case TaskTypes.Draft:
      return { label: 'tasks.status.draft', color: 'orange' };
    case TaskTypes.Pending:
      return { label: 'tasks.status.pending', color: 'gray' };
    case TaskTypes.Disabled:
      return { label: 'tasks.status.disabled', color: 'gray' };
    default:
      return { label: 'tasks.status.pending', color: 'gray' };
  }
};

const openExternal = (id?: string) => {
  if (!id) return;

  openInNewTab(`${window.location.origin}/external/event/${id}`);
};

function TasksListModal({
  ticketStepTasks,
  step,
}: {
  step: AutomationSteps_FlowFragmentFragment;
  ticketStepTasks: Maybe<TicketStep>[];
}) {
  const { openDrawer } = useChecklistSummaryDrawer();
  const { updateTicketStatus } = useUpdateTicketStatus();
  const modals = useModals();
  const { t } = useTranslation();
  const { user } = useAuth();

  const isTopicoPrimeAdmin = user && topicoPrimeAdminIds.includes(user.id); // NOTE - gambiarra braba

  const handleStart = async (task: any) => {
    const formatedData = formatTaskData(camelToSnake(task));
    try {
      await changeTaskStatus(formatedData, 'draft');
      const res = await startTaskEvent(formatedData);
      openInNewTab(`${window.location.origin}/external/event/${res.id}`);
    } catch (e) {
      FeedbackError({ mainText: 'Unexpected Error' });
    }
  };

  const handleOpenExternal = (task: any) => {
    if (task?.event?.id) {
      openInNewTab(`${window.location.origin}/external/event/${task.event.id}`);
    } else {
      handleStart(task);
    }
  };

  const handleUpdateTaskStatus = (taskId: string, hideTask: boolean) => {
    updateTicketStatus({
      taskId,
      hideTask,
    });
  };

  const handleOpenDrawer = (taskId: string) => {
    modals.pop();

    openDrawer({
      id: taskId,
      type: 'task',
      refresh: () => null,
      handleDisableTask: handleUpdateTaskStatus,
    });
  };

  return (
    <VStack alignItems="flex-start" flex={1} minH="xl" p="4">
      <VStack w="full" gap="2" flex={1} overflowY="auto" pb="2">
        {ticketStepTasks
          .filter((e) => !!e?.task)
          .map((stepTask) => {
            const { color, label } = getStatusProps(stepTask!.task!.status!);

            const disabled =
              stepTask?.task?.member?.id &&
              user?.id !== stepTask?.task?.member?.id &&
              !isTopicoPrimeAdmin;

            return (
              <ButtonGroup w="full" px="1">
                <Button
                  isDisabled={!stepTask?.task?.id}
                  onClick={() => {
                    if (stepTask?.task?.id) {
                      handleOpenDrawer(stepTask?.task.id);
                    }
                  }}
                  textAlign="start"
                  justifyContent="flex-start"
                  p="2"
                  w="full"
                  h="70px"
                  variant="outline"
                  key={stepTask!.id}
                >
                  <HStack flex={1}>
                    <VStack w="full" alignItems="flex-start">
                      <HStack w="full">
                        <Text
                          flex="1"
                          fontWeight="semibold"
                          lineHeight="short"
                          fontSize="lg"
                        >
                          {stepTask?.task?.name}
                        </Text>
                        <Tag variant="subtle" colorScheme={color}>
                          {t(label)}
                        </Tag>
                      </HStack>
                      <VStack
                        textAlign="start"
                        alignItems="flex-start"
                        fontWeight="normal"
                        color="mutedText"
                        fontSize="sm"
                      >
                        <Text>
                          {stepTask?.task?.member?.fullName || '[SEM MEMBRO]'}
                        </Text>
                        {!!stepTask?.task?.finishedAt && (
                          <Text>
                            {`${t('tasks.header.finished_at')}: ${moment(
                              new Date(Number(stepTask?.task?.finishedAt)),
                            ).format('LLL ')}`}
                          </Text>
                        )}
                      </VStack>
                    </VStack>
                  </HStack>
                </Button>
                <IconButton
                  onClick={() => handleOpenExternal(stepTask?.task)}
                  h="full"
                  variant="outline"
                  aria-label="external-link"
                  colorScheme={
                    stepTask?.task?.event?.id || disabled ? '' : 'blue'
                  }
                  icon={<Icon as={FiExternalLink} fontSize="17px" />}
                  disabled={!stepTask?.task?.event?.id && disabled}
                />
              </ButtonGroup>
            );
          })}
      </VStack>
      <ButtonGroup justifyContent="flex-end" w="full">
        <Button onClick={() => modals.pop()} colorScheme="brand" size="md">
          {t('common.close')}
        </Button>
      </ButtonGroup>
    </VStack>
  );
}

function StackedStepCard(props: {
  step: AutomationSteps_FlowFragmentFragment;
  ticketStepTasks: Maybe<TicketStep>[];
}) {
  const { t } = useTranslation();
  const modals = useModals();
  const { step, ticketStepTasks } = props;

  const validated = ticketStepTasks.filter(
    (e) => !!e?.task?.event?.validated && e.task.status === TaskTypes.Done,
  );
  const pending = ticketStepTasks.filter(
    (e) =>
      !!e?.task?.status &&
      [TaskTypes.Pending, TaskTypes.Draft].includes(e?.task?.status),
  );

  const nonValidated = ticketStepTasks.filter(
    (e) => e?.task?.status === TaskTypes.Done && !e?.task?.event?.validated,
  );

  return (
    <Flex flex={1} pt="2" h="full">
      <Card
        flex={1}
        maxW="100%"
        sx={{
          bg: 'white',
          boxShadow: `
          /* The second layer */
          0 -8px 0 -5px var(--chakra-colors-gray-50),
          /* The second layer shadow */
          0 -8px 1px -4px var(--chakra-colors-blackAlpha-300),
           /* The third layer */
          0 -16px 0 -10px var(--chakra-colors-gray-50),
          /* The third layer shadow */
          0 -16px 1px -9px var(--chakra-colors-blackAlpha-300)`,
        }}
        cursor="pointer"
        onClick={() => {
          modals.open({
            title: `${step.name}`,
            scope: 'alert',
            body: (
              <TasksListModal ticketStepTasks={ticketStepTasks} step={step} />
            ),
          });
        }}
      >
        <VStack
          fontWeight="semibold"
          fontSize="xs"
          textAlign="start"
          gap="0"
          flex="1"
          justifyContent="center"
          alignItems="start"
        >
          <HStack>
            <Circle size="2.5" bg="red.500" />
            <Text>
              {t('tasks.status.pending')}: {pending.length}
            </Text>
          </HStack>
          <HStack>
            <Circle size="2.5" bg="blue.500" />
            <Text>
              {t('tasks.status.validated_one')}: {validated.length}
            </Text>
          </HStack>
          <HStack>
            <Circle size="2.5" bg="green.500" />
            <Text>
              {t('tasks.status.nonValidated_one')}: {nonValidated.length}
            </Text>
          </HStack>
        </VStack>
      </Card>
    </Flex>
  );
}

function SingleTaskStepCard(props: {
  step: AutomationSteps_FlowFragmentFragment;
  ticketStepTask: Maybe<TicketStep>;
}) {
  const { openDrawer } = useChecklistSummaryDrawer();
  const { updateTicketStatus } = useUpdateTicketStatus();

  const { step, ticketStepTask } = props;

  const authorName = ticketStepTask?.task?.member?.fullName;

  const taskStatus = ticketStepTask?.task?.status;

  const finishedAt = ticketStepTask?.task?.finishedAt;

  const baseStyle: CSSObject = {
    textColor: 'white',
    w: 'full',
    h: '28',
    p: 2,
    borderRadius: 'lg',
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  };

  const stepStatusColor = React.useCallback(
    (taskStatus?: TaskTypes | null | undefined) => {
      if (ticketStepTask?.event?.validated)
        return {
          bg: 'blue.500',
          borderColor: 'blue.700',
        };
      switch (taskStatus) {
        case TaskTypes.Draft:
          return { bg: 'orange.500', borderColor: 'orange.700' };
        case TaskTypes.Done:
          return { bg: 'green.500', borderColor: 'green.600' };
        case TaskTypes.Pending:
          return { bg: 'gray.400', borderColor: 'gray.500' };
        case TaskTypes.Disabled:
          return { bg: 'red.500', borderColor: 'red.800' };
        default:
          return { bg: 'gray.200', borderColor: 'gray.200' };
      }
    },
    [ticketStepTask?.event?.validated],
  );

  if (step.stepType === StepTypes.Canceling && ticketStepTask) {
    return (
      <Card bg="red.400" borderColor="red.500" sx={baseStyle}>
        <Text>
          {moment(new Date(ticketStepTask.createdAt)).format('DD/MM/YYYY')}
        </Text>
        <Text noOfLines={2}>CANCELADO</Text>
      </Card>
    );
  }

  if (step.stepType === StepTypes.Finishing && ticketStepTask) {
    return (
      <Card bg="green.400" borderColor="green.500" sx={baseStyle}>
        <Text>
          {moment(new Date(ticketStepTask.createdAt)).format('DD/MM/YYYY')}
        </Text>
        <Text noOfLines={2}>FINALIZADO</Text>
      </Card>
    );
  }

  const handleUpdateTaskStatus = (taskId: string, hideTask: boolean) => {
    updateTicketStatus({
      taskId,
      hideTask,
    });
  };

  const renderText = () => {
    if (taskStatus === 'disabled') return 'INATIVO';

    return authorName?.toUpperCase() || 'PENDENTE';
  };

  return (
    <Card
      cursor={ticketStepTask?.task ? 'pointer' : 'inherit'}
      onClick={() => {
        if (ticketStepTask?.task) {
          openDrawer({
            id: ticketStepTask?.task.id,
            type: 'task',
            refresh: () => null,
            handleDisableTask: handleUpdateTaskStatus,
          });
        }
      }}
      backgroundColor={stepStatusColor(taskStatus).bg}
      borderColor={stepStatusColor(taskStatus).borderColor}
      sx={baseStyle}
    >
      {ticketStepTask?.task ? (
        <>
          {!!finishedAt && (
            <Text>
              {moment(new Date(Number(finishedAt))).format('DD/MM/YYYY')}
            </Text>
          )}
          <Text noOfLines={2}>{renderText()}</Text>
        </>
      ) : (
        <></>
      )}
    </Card>
  );
}

function StepCard(props: {
  step: AutomationSteps_FlowFragmentFragment;
  ticketStepTasks: Maybe<TicketStep>[];
}) {
  const { step, ticketStepTasks } = props;

  const length = ticketStepTasks?.length || 0;
  if (length > 1) {
    return <StackedStepCard step={step} ticketStepTasks={ticketStepTasks} />;
  }

  return (
    <SingleTaskStepCard step={step} ticketStepTask={ticketStepTasks?.[0]} />
  );
}

type TicketsListItemProps = {
  ticket: AutomationTicketFragmentType;
  templateColumns: string;
};

const TicketsListItem: React.FC<TicketsListItemProps> = ({
  ticket,
  templateColumns,
}) => {
  const { openDrawer } = useChecklistSummaryDrawer();

  const { t } = useTranslation();
  const {
    selected,
    onSelect,
    onDeselect,
    orderedProperties,
    orderedSteps,
    hideProperties,
  } = useAutomationFlow();

  const ticketData = useFragment(AutomationTicketFragment, ticket);
  const code = ticketData?.automationFlow?.showCustomCode
    ? ticketData?.customCode
    : ticketData?.ticketCode;

  const { checkPermission } = useUserPermissions();

  const { openForm } = useCloneTicketForm();

  const handleCheckbox = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        onSelect(ticketData.id);
      } else {
        onDeselect(ticketData.id);
      }
    },
    [ticketData?.id, onSelect, onDeselect],
  );

  const statusTicketColor = React.useCallback((status: string) => {
    switch (status) {
      case 'active':
        return 'blue';
      case 'done':
        return 'green';
      case 'canceled':
        return 'red';
      case 'error':
        return 'red';
      case 'disabled':
        return 'red';
      default:
        return 'gray';
    }
  }, []);

  return (
    <Grid
      textTransform="uppercase"
      minW="fit-content"
      w="100%"
      px={6}
      py={2}
      mt={0}
      bg="white"
      borderBottom="1px solid #E2E8F0"
      boxShadow="0px 10px 20px rgba(18, 38, 63, 0.0313726)"
      templateColumns={templateColumns}
      position="relative"
    >
      <GI justifyContent="flex-start">
        <Checkbox
          size="lg"
          isChecked={selected?.includes(ticketData?.id)}
          onChange={handleCheckbox}
        />
      </GI>

      <GI
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        overflow="hidden"
        position="relative"
        role="group"
      >
        <Menu isLazy lazyBehavior="keepMounted">
          <MenuButton
            as={IconButton}
            position="absolute"
            right={0}
            p="0"
            bg="white"
            opacity={0}
            _groupHover={{ opacity: 1 }}
            _active={{ opacity: 1 }}
            transition="all 0.3s"
          >
            <CustomIcons.DotsHorizontal fontSize="3xl" />
          </MenuButton>
          <Portal>
            <MenuList minWidth="240px">
              <MenuItem onClick={() => openForm(ticketData as any)}>
                Clonar ticket
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
        <Text
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          fontSize="lg"
          width="100%"
          fontWeight={500}
        >
          {code}
        </Text>
      </GI>

      <GI
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        overflow="hidden"
      >
        <Text
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          fontSize="lg"
          width="100%"
          fontWeight={500}
        >
          {ticketData?.author?.fullName || 'Sem Responsável'}
        </Text>
      </GI>

      <GI>
        <ButtonGroup>
          <Badge
            variant="subtle"
            colorScheme={statusTicketColor(ticketData?.status as any)}
            fontWeight="bold"
            px={2}
            py={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {t(`common.${ticketData.status}`)}
          </Badge>
          {ticketData?.event?.id && (
            <IconButton
              onClick={() => openExternal(ticketData?.event?.id)}
              variant="outline"
              aria-label="external-link"
              icon={<Icon as={FiExternalLink} fontSize="14px" />}
            />
          )}
        </ButtonGroup>
      </GI>

      {!hideProperties &&
        orderedProperties?.map((property) => {
          const answer = ticketData.automationTicketProperties?.find(
            ({ automationPropertyId }) => automationPropertyId === property.id,
          )?.answer;

          const { formatContent, answerOptions } = answer || {};

          const isAdmin = checkPermission('admin');

          return (
            <GI
              key={property.id}
              justifyContent="flex-start"
              role="group"
              overflow="hidden"
            >
              {answerOptions && answerOptions.length > 0 ? (
                <ShowContentPopover
                  formatContent={formatContent}
                  answerOptions={answerOptions}
                />
              ) : (
                <Text
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="pre-wrap"
                  fontSize="lg"
                  width="100%"
                  noOfLines={2}
                  fontWeight={500}
                  textAlign="center"
                >
                  {formatContent || '-'}
                </Text>
              )}
              {formatContent && isAdmin && (
                <PropertyMenu
                  property={(answer as unknown) as PartialWithId<Property>}
                >
                  <MenuButton
                    as={IconButton}
                    position="relative"
                    left={0}
                    top={0}
                    p="0"
                    bg="white"
                    opacity={0}
                    _groupHover={{ opacity: 1 }}
                    _active={{ opacity: 1 }}
                    transition="all 0.3s"
                  >
                    <CustomIcons.DotsHorizontal fontSize="3xl" />
                  </MenuButton>
                </PropertyMenu>
              )}
            </GI>
          );
        })}

      <GI justifyContent="flex-start" overflow="hidden">
        <Card
          textColor="white"
          w="full"
          h="28"
          p={2}
          borderRadius="lg"
          fontWeight="bold"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          display="flex"
          bg="green.500"
          borderColor="green.600"
          cursor={ticketData.event?.parentableId ? 'pointer' : 'inherit'}
          onClick={() => {
            if (ticketData.event?.parentableId) {
              openDrawer({
                id: ticketData.event?.parentableId || '',
                type: 'task',
                refresh: () => null,
              });
            }
          }}
        >
          {ticketData.event?.author ? (
            <>
              {!!ticketData.event.finishedAt && (
                <Text noOfLines={2}>
                  {moment(ticketData.event.finishedAt * 1000).format(
                    'DD/MM/YYYY',
                  )}
                </Text>
              )}
              <Text>Iniciado por: {ticketData.event?.author?.fullName}</Text>
            </>
          ) : (
            <Text noOfLines={2}>
              Iniciado em{' '}
              {moment(ticketData.createdAt).format('DD/MM/YYYY hh:mm')}
            </Text>
          )}
        </Card>
      </GI>

      {orderedSteps?.map((step) => {
        const columnData = ticketData.automationTicketStepsTasks?.filter(
          ({ automationStepId }) => automationStepId === step.id,
        );

        return (
          <GI key={step.id} justifyContent="center">
            <StepCard step={step} ticketStepTasks={columnData as any} />
          </GI>
        );
      })}

      <GI justifyContent="center">
        <PDFDownloadButton
          namespace="tickets"
          objectId={ticketData.id}
          isEnabled
        />
      </GI>
    </Grid>
  );
};

export default TicketsListItem;
