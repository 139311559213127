import React from 'react';

import { ActionCableProvider } from './actionCable';
import { useAuth } from './Authentication/auth';
import { UserChangesListenerProvider } from './Authentication/userChangesListener';
import { NotificationsProvider } from './Notifications/notifications';

const AppProvider: React.FC = ({ children }) => {
  const { token, user } = useAuth();
  return token ? (
    <ActionCableProvider>
      <UserChangesListenerProvider userId={user ? user.id : undefined}>
        <NotificationsProvider>{children}</NotificationsProvider>
      </UserChangesListenerProvider>
    </ActionCableProvider>
  ) : (
    <>{children}</>
  );
};

export default AppProvider;
