/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql } from '@/gql';
import { getApolloInstance } from '@/modules/graphql';

export const queryUsers = gql(`#graphql
  query Users(
    $after: String,
    $before: String,
    $last: Int,
    $first: Int,
    $usersId: ID,
    $orderBy: OrderNames,
    $search: String
  ) {
    users(after: $after, before: $before, last: $last, first: $first, id: $usersId, orderBy: $orderBy, search: $search) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          email
         fullName
        }
      }
    }
  }
`);

export const fetchUsers = async (params: {
  after?: string;
  search?: string;
  first?: number;
}) => {
  const apollo = getApolloInstance();
  const { data } = await apollo.query({
    query: queryUsers,
    variables: { first: 20, ...params },
    fetchPolicy: 'network-only',
  });

  const items = data.users.edges.map((edge) => edge.node!);

  return {
    items,
    cursor: data?.users?.pageInfo?.endCursor || undefined,
  };
};
