import React from 'react';
import {
  Switch,
  RouteProps,
  useRouteMatch,
  Redirect,
  Route,
} from 'react-router-dom';

const Users = React.lazy(() => import('@/screens/registrations/Users'));
const Locals = React.lazy(() => import('@/screens/registrations/Locals'));
const Schedules = React.lazy(() => import('@/screens/registrations/Schedules'));
const Formularies = React.lazy(
  () => import('@/screens/registrations/Formularies/Index'),
);
const Collections = React.lazy(
  () => import('@/screens/registrations/Collections'),
);
const FormularyShowRoute = React.lazy(
  () => import('@/screens/registrations/Formularies/Show/Route'),
);

const RegistrationsRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/schedules`} component={Schedules} />
      <Route path={`${path}/locals`} component={Locals} />
      <Route path={`${path}/templates`} component={Formularies} exact />
      <Route
        path={`${path}/templates/:id`}
        component={FormularyShowRoute}
        exact
      />
      <Route path={`${path}/users`} component={Users} />
      <Route path={`${path}/collections`} component={Collections} exact />
      <Route path={`${path}/collections/:id`} component={Collections} />
      <Redirect to={`${path}/collections`} exact />
    </Switch>
  );
};

export default RegistrationsRoutes;
