import TabSelector from '@/components_v2/TabSelector';
import { Flex, Select } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

import { useInteractions } from '../../hooks/useInteractions';
import { useSelectedForm } from '../../hooks/useSelectedForm';
import { EventItem } from '../../interfaces';
import { QUESTION_FILTERS } from '../../utils';

export const ItemFilters: React.FC = () => {
  const form = useSelectedForm();
  const {
    selectedItemId,
    setCurrentItem,
    filter,
    setFilter,
  } = useInteractions();

  const items = React.useMemo(() => form?.items || [], [form?.items]);

  const renderOption = React.useCallback(
    (item: EventItem) => {
      return (
        <option key={item.id} value={item.id}>
          {item.collectionItemName ||
            `${form?.formName} - ${moment(item.finishedAt).format('hh:mm a')}`}
        </option>
      );
    },
    [form?.formName],
  );

  return (
    <Flex
      p="4"
      w="full"
      flexDir={{ base: 'column', md: 'row' }}
      alignItems="center"
      gap="2"
    >
      <Select
        rounded="xl"
        size="lg"
        placeholder="Selecione um item"
        bg="white"
        value={selectedItemId || ''}
        onChange={(e) => {
          const nextOption = `${e.target.selectedOptions[0].value}`;
          if (nextOption?.length) {
            setCurrentItem(nextOption);
          }
        }}
      >
        {items.map(renderOption)}
      </Select>
      <TabSelector
        variant="ios"
        onChange={(key) => {
          setFilter(key as any);
        }}
        initialKey={filter}
        tabsComponentProps={{
          w: { base: 'full', md: 'min-content' },
        }}
        tabs={QUESTION_FILTERS}
      />
    </Flex>
  );
};
