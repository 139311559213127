import styled, { css } from 'styled-components';

import constants from './constants';

interface ContainerProps {
  showSubMenu: boolean;
  subMenuCollapsed: boolean;
}

interface ContentProps {
  isMobile?: boolean;
  deviceWidth?: number;
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  flex: 1;
  position: relative;
  width: 100vw;

  grid-template-columns: auto;
  grid-template-rows: ${constants.navbarHeight} auto;
  grid-template-areas:
    'navbar'
    'content';

  @media (min-width: 768px) {
    grid-template-columns: ${constants.navbarWidth} ${(props) =>
        props.subMenuCollapsed ? '20px' : constants.subMenuBarWidth} auto;
    grid-template-rows: auto;

    ${({ showSubMenu }) => {
      if (showSubMenu) {
        return css`
          grid-template-areas: 'navbar menubar content';
        `;
      }
      return css`
        grid-template-areas: 'navbar content content';
      `;
    }}
  }
`;

export const Content = styled.div<ContentProps>`
  grid-area: content;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      overflow: hidden;
    `}

  ${({ isMobile, deviceWidth }) =>
    isMobile &&
    css`
      width: ${deviceWidth}px;
    `}
`;
