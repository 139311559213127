/* eslint-disable react/react-in-jsx-scope */
import config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import {
  RemoteSelect,
  RemoteSelectOption,
  RemoteSelectProps,
} from './RemoteSelect';

async function getUsers(input: string): Promise<RemoteSelectOption[]> {
  const response = await AxiosHelper.get<{
    items: Array<{ id: string; full_name: string; avatar_url?: string }>;
  }>({
    url: `${config.USERS_URL}?columns[]=full_name&columns[]=id&columns[]=avatar_url&search=${input}`,
  });
  return (
    response?.body?.items?.map((item) => ({
      label: item.full_name,
      value: item.id,
      avatarUrl: item?.avatar_url || '',
    })) || []
  );
}

export function UserSelect(props: Omit<RemoteSelectProps, 'fetchOptions'>) {
  return <RemoteSelect {...props} fetchOptions={getUsers} />;
}

async function getFormularies(input: string): Promise<RemoteSelectOption[]> {
  const response = await AxiosHelper.get<{
    items: Array<{ id: string; name: string }>;
  }>({
    url: `${config.FORMULARIES_URL}?filter[formularies][enabled][]=true&columns[]=name&columns[]=id&search=${input}`,
  });
  return response.body.items.map((item) => ({
    label: item.name,
    value: item.id,
  }));
}

export function FormSelect(props: Omit<RemoteSelectProps, 'fetchOptions'>) {
  return <RemoteSelect {...props} fetchOptions={getFormularies} />;
}

async function getLocals(input: string): Promise<RemoteSelectOption[]> {
  const response = await AxiosHelper.get<{
    items: Array<{ id: string; name: string }>;
  }>({
    url: `${config.LOCALS_URL}?columns[]=name&columns[]=id&search=${input}`,
  });
  return response.body.items.map((item) => ({
    label: item.name,
    value: item.id,
  }));
}

export function LocalSelect(props: Omit<RemoteSelectProps, 'fetchOptions'>) {
  return <RemoteSelect {...props} fetchOptions={getLocals} />;
}
