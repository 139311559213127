import { FeedbackError } from '@/components/Feedback';
import { EventStatus } from '@/modules/core/domain/interfaces';
import { useToast } from '@chakra-ui/react';
import React from 'react';

import { TaskWithEvent } from '../../interfaces';
import { getTaskAndEvent } from '../../services/getTaskAndEvent';
import { updateTaskEvent } from '../../services/updateTaskEvent';

export default function useTaskChecklist(
  taskId: string,
): {
  task: TaskWithEvent | undefined;
  isValidating: boolean;
  finishChecklist: () => Promise<boolean>;
  isSubmitting: boolean;
} {
  const toast = useToast();
  const [isValidating, setIsValidating] = React.useState(true);

  const [task, setTask] = React.useState<TaskWithEvent | undefined>();
  const [isSubmitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        setIsValidating(true);
        const response = await getTaskAndEvent(taskId);

        setTask(response);
      } catch (error) {
        if (error instanceof Error) {
          toast({ title: 'Erro', description: error.message, status: 'error' });
        }
      } finally {
        setIsValidating(false);
      }
    };
    fetch();
  }, [taskId, toast]);

  const finishChecklist = React.useCallback(async () => {
    try {
      const eventId = task?.event?.id;
      if (eventId) {
        setSubmitting(true);
        const result = await updateTaskEvent({
          eventId,
          finishedAt: Math.floor(new Date().getTime() / 1000),
          status: EventStatus.DONE,
          processItemSyncState: false,
        });
        return result;
      }
      return false;
    } catch (e) {
      FeedbackError({
        mainText: 'Ocorreu um erro inesperado.',
        subText: 'Tente novamente.',
      });
    } finally {
      setSubmitting(false);
    }
    return false;
  }, [task?.event?.id]);

  return {
    task,
    isValidating,
    finishChecklist,
    isSubmitting,
  };
}
