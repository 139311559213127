import { useSimpleExporter } from '@/components_v2/ExporterContextMenu';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { getExporter } from '@/screens/monitoring/EventReports/data/getExporter';
import { CheckIcon, DownloadIcon } from '@chakra-ui/icons';
import { Button, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function useItemDownloadedState(id: string, namespace: string) {
  const [downloadedItems, setDownloadedItems] = useLocalStorage<string[]>(
    'downloaded_items',
  );

  const hasDownloaded = React.useMemo(
    () => !!downloadedItems?.includes(`${namespace}-${id}`),
    [downloadedItems, id, namespace],
  );

  const setDownloaded = React.useCallback(() => {
    setDownloadedItems((old) => [...(old || []), `${namespace}-${id}`]);
  }, [id, namespace, setDownloadedItems]);

  return {
    hasDownloaded,
    setDownloaded,
  };
}

export default function PDFDownloadButton({
  objectId,
  namespace,
  isEnabled,
  screenName,
}: {
  namespace: string;
  objectId?: string;
  screenName?: string;
  isEnabled?: boolean;
}) {
  const { t } = useTranslation();

  const { hasDownloaded, setDownloaded } = useItemDownloadedState(
    objectId || '',
    namespace,
  );

  const { onExport } = useSimpleExporter({
    getExporter: getExporter(),
    exportType: 'pdf',
    screenName: screenName || namespace,
    objectId: `${objectId}`,
    payload: {
      only_media: false,
    },
    onSuccess() {
      setDownloaded();
    },
  });

  const label = hasDownloaded
    ? t('event_reports.downloaded_pdf')
    : t('event_reports.download_pdf');
  const icon = hasDownloaded ? <CheckIcon /> : <DownloadIcon />;

  return (
    <Button
      disabled={!isEnabled}
      onClick={() => onExport()}
      colorScheme={hasDownloaded ? 'green' : 'gray'}
      rightIcon={icon}
    >
      <Tooltip label={t('event_reports.download_pdf_description')}>
        {label}
      </Tooltip>
    </Button>
  );
}
