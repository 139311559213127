/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext } from 'react';

import { WebFormProps } from '../types';

const WebFormPropsContext = createContext<WebFormProps>({} as WebFormProps);

export const WebFormPropsProvider: React.FC<WebFormProps> = ({
  children,
  ...rest
}) => {
  return (
    <WebFormPropsContext.Provider value={{ ...rest }}>
      {children}
    </WebFormPropsContext.Provider>
  );
};

export function useWebFormProps(): WebFormProps {
  const context = useContext(WebFormPropsContext);

  if (!context) {
    throw new Error(
      'useWebFormProps must be used whithin an WebFormPropsProvider',
    );
  }

  return context;
}
