import React, { useCallback } from 'react';

import { useFilter } from '@hooks/Filters/filter';
import { FilterContextMenuProps } from './interfaces';

import Main from './Main';

const FilterContextMenu: React.FC<FilterContextMenuProps> = ({
  filter,
  noOrder,
}) => {
  const { clearOption, order, setOrder } = useFilter();

  const onClear = useCallback(() => {
    clearOption({
      column: filter.column,
      type: filter.type,
      table: filter.table,
    });
  }, [filter, clearOption]);

  const onOrder = useCallback(
    (ascending: boolean) => {
      setOrder({ column: filter.column, type: ascending ? 'asc' : 'desc' });
    },
    [setOrder, filter.column],
  );

  return (
    <Main
      onOrder={onOrder}
      filter={filter}
      onClear={onClear}
      noOrder={!!noOrder}
      order={order && order.column === filter.column ? order.type : undefined}
    />
  );
};

export default FilterContextMenu;
