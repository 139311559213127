import { Styles } from 'react-modal';
import styled from 'styled-components';

export const Container = styled.div``;

// Modal Style

export const ModalStyles: Styles = {
  content: {
    top: '50%',
    left: '50%',
    minWidth: '300px',
    maxWidth: '40%',
    maxHeight: '650px',
    minHeight: '80%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    boxShadow: '0px 2px 100px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    padding: '0px',
    border: '0',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  overlay: {
    zIndex: 40,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
};
