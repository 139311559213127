import styled, { css } from 'styled-components';
import {
  ACTION_ITEM_HEIGHT,
  CONTAINER_HORIZONTAL_PADDING,
  ITEM_MARGIN,
} from './constants';

type WithIsFirst = {
  isFirst?: boolean;
};

type WithClickable = {
  clickable?: boolean;
};

type WithSelected = {
  selected?: boolean;
};

type WithChevron = {
  hasChevron?: boolean;
};

type WithDisabled = {
  disabled?: boolean;
};

export const ContextMenuContainer = styled.div`
  width: 250px;
  height: 100%;
  display: block;

  padding: ${CONTAINER_HORIZONTAL_PADDING}px;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};
  box-sizing: border-box;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  outline: none;
`;

export const SectionTitle = styled.div<WithIsFirst>`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-top: ${({ isFirst }) => (isFirst ? '0px' : '5px')};

  color: ${(props) => props.theme.colors.mediumText};
`;

type ContainerProps = WithIsFirst &
  WithClickable &
  WithChevron &
  WithSelected &
  WithDisabled;

export const ActionContainer = styled.div<ContainerProps>`
  display: inline-grid;

  ${(props) => {
    if (props.hasChevron) {
      return css`
        grid-template-columns: 24px 1fr 17px;
        grid-template-areas: 'icon text chevron';
      `;
    }
    return css`
      grid-template-columns: 24px auto;
      grid-template-areas: 'icon text';
    `;
  }}

  height: ${ACTION_ITEM_HEIGHT}px;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin-top: ${({ isFirst }) => (isFirst ? '0px' : `${ITEM_MARGIN}px`)};

  color: ${(props) => props.theme.colors.darkText};

  ${({ selected, theme }) => {
    if (selected) {
      return css`
        background-color: ${theme.colors.primary};
        border-radius: 2px;
      `;
    }

    return css``;
  }}

  ${({ clickable, disabled, theme }) => {
    if (disabled) {
      return css`
        cursor: default;
        color: ${theme.colors.v2_secondary};
        & * {
          cursor: default;
          color: ${theme.colors.v2_secondary};
        }
      `;
    }

    if (!clickable) {
      return css`
        cursor: default;
      `;
    }

    return css`
      cursor: pointer;

      :hover {
        background-color: #e1e4e8;
      }
    `;
  }}

  overflow: hidden;
`;

export const TriggerWrapper = styled.div`
  width: 100%;
`;

export const ActionIcon = styled.div`
  grid-area: icon;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  flex-shrink: 0;
  flex-grow: 0;
`;

export const ActionText = styled.div<WithSelected>`
  grid-area: text;
  ${({ selected, theme }) => {
    if (selected) {
      return css`
        color: white;
      `;
    }

    return css`
      color: ${theme.colors.darkText};
    `;
  }}
  display: flex;
  flex-direction: row;
  align-items: center;

  overflow: hidden;
`;

export const ItemName = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
  align-items: center;

  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

export const ContainerList = styled.div`
  width: 400px;
  height: 360px;
  display: block;

  padding: 12px 10px;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};
  box-sizing: border-box;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  outline: none;
`;

export const ExtraContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  height: 100%;

  overflow: hidden;

  .selected {
    height: 16px;
    max-width: 130px;
    padding: 0px 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #e1e4e8;
    border-radius: 100px;
    color: ${(props) => props.theme.colors.darkText};

    font-family: ${(props) => props.theme.fonts.primaryFont};
    font-style: normal;
    font-weight: normal;
    font-size: 9px;

    .text {
      flex: 1 1 auto;
      white-space: nowrap;
      min-width: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline;
      align-items: center;
    }
  }

  .icon {
    user-select: none;
    transition: background 20ms ease-in 0s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    margin-left: 4px;
    background: transparent;
  }
`;

export const SeparatorLine = styled.div`
  position: relative;
  width: 100%;
  height: 1px;

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: calc(100% + ${CONTAINER_HORIZONTAL_PADDING * 2}px);
    left: -${CONTAINER_HORIZONTAL_PADDING}px;
    border-top: 1px solid ${(props) => props.theme.colors.borderGrey};
  }
`;

export const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: chevron;
  padding-left: auto;
`;
