import React from 'react';

import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import FormPages from './FormPages';

const MobileNav: React.FC = () => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent h="full">
          <DrawerCloseButton />
          <FormPages onSelect={onClose} />
        </DrawerContent>
      </Drawer>
      <Flex
        display={{ base: 'inline-flex', xl: 'none' }}
        zIndex={isOpen ? '1401' : 'inherit'}
        position="fixed"
        bottom="0"
        height="12"
        boxShadow="dark-lg"
        rounded="lg"
        opacity="0.9"
        bg="white"
        w="full"
        alignItems="center"
        justifyContent="center"
        backdropFilter="auto"
        backdropBlur="md"
      >
        <Button
          aria-label="Menu"
          marginLeft="2"
          onClick={onToggle}
          rightIcon={
            isOpen ? <FiChevronDown size={20} /> : <FiChevronUp size={20} />
          }
          size="sm"
          colorScheme="brand"
        >
          Páginas
        </Button>
      </Flex>
    </>
  );
};

export default MobileNav;
