import { DiscussionTopic, TopicComment } from '@/hooks/Feedback/interfaces';

import { Box, Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { useCurrentFeedback } from '../hooks/useCurrentFeedback';
import { CommentItem } from './CommentItem';
import FeedbackQuestionPreview from './FeedbackQuestionPreview';
import { ActivityItemProps } from './types';

const getMessage = (item: TopicComment, feedback: DiscussionTopic) => {
  const author = item.authorName;
  let action = '';
  const subject = feedback.question?.question.toString();

  switch (item.topicActivityType) {
    case 'review':
      action = 'Solicitou a revisão da pergunta:';
      break;
    case 'review_cancel':
      action = `Cancelou a revisão da pergunta:`;
      break;
    case 'review_done':
      action = 'Finalizou a revisão da pergunta:';
      break;
    default:
      break;
  }

  return [author, action, subject];
};

const ReviewActivity: React.FC<ActivityItemProps> = (props) => {
  const { item } = props;
  const { feedback } = useCurrentFeedback();

  if (!feedback) return null;

  const [author, action, subject] = getMessage(item, feedback);

  const colorScheme =
    // eslint-disable-next-line no-nested-ternary
    item.topicActivityType === 'review'
      ? 'purple.500'
      : item.topicActivityType === 'review_done'
      ? 'green.500'
      : 'red.500';

  return (
    <Flex alignItems="center" justifyContent="center">
      <Flex
        borderWidth="thin"
        bg="gray.100"
        h="full"
        w="80%"
        p="4"
        flexDir="column"
        rounded="xl"
        gap={2}
      >
        <Box display="inline">
          <Text fontWeight="bold" display="inline">
            {`${author}\n`}
            <Text display="inline" fontWeight="medium" color={colorScheme}>
              {`${action}\n`}
            </Text>
            <Text display="inline" fontWeight="bold">
              {`${subject}\n`}
            </Text>
          </Text>
        </Box>

        <Text color="muted" alignSelf="flex-start">
          {moment(item.createdAt).calendar()}
        </Text>
        {item.topicActivityType === 'review_done' && (
          <FeedbackQuestionPreview feedback={feedback} />
        )}
      </Flex>
    </Flex>
  );
};

export const ActivityItem: React.FC<ActivityItemProps> = (props) => {
  const { item } = props;

  if (!item.topicActivityType || item.topicActivityType === 'comment') {
    return <CommentItem {...props} />;
  }
  return <ReviewActivity {...props} />;
};
