function camelToUnderscore(key: string): string {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export default function camelToSnake(
  obj: Record<string, unknown>,
): Record<string, unknown> {
  const keys = Object.keys(obj);
  const newObj: Record<string, unknown> = {};
  for (let i = 0; i < keys.length; i++) {
    newObj[camelToUnderscore(keys[i])] = obj[keys[i]];
  }
  return newObj;
}
