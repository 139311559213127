import React from 'react';

import './modal.css';
import { ModalProps } from './interfaces';
import Main from './Main';

const Modal: React.FC<ModalProps> = ({ children, ...rest }) => {
  return <Main {...rest}>{children}</Main>;
};

export default Modal;
