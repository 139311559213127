import debounce from '@/utils/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SearchInputProps } from './interfaces';

import Main from './Main';

const SearchInput: React.FC<SearchInputProps> = ({
  onSubmit,
  placeholder,
  containerStyle,
}) => {
  const [value, setValue] = useState<string>('');
  const valueRef = useRef<string>();
  const [debounced] = useState(() => {
    return debounce(async () => {
      if (valueRef.current !== undefined) {
        onSubmit(valueRef.current);
      }
    }, 200);
  });

  useEffect(() => {
    valueRef.current = value;
    debounced.cancel();
    debounced();
  }, [debounced, value]);

  const handleChange = useCallback((newValue: string) => {
    setValue(newValue);
  }, []);

  return (
    <Main
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      containerStyle={containerStyle}
    />
  );
};

export default SearchInput;
