import { Option } from '@/components_v2/HookForm';
import config from '@/Constants';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { Tag } from '@/screens/registrations/Tasks/interfaces';

export async function fetchFormularies(input: string): Promise<Option[]> {
  const response = await AxiosHelper.get<{
    items: Array<{ id: string; name: string }>;
  }>({
    url: `${config.FORMULARIES_URL}?filter[formularies][enabled][]=true&columns[]=name&columns[]=id&search=${input}`,
  });
  return response.body.items.map((item) => ({
    label: item.name,
    value: item.id,
  }));
}

export async function fetchLocals(input: string): Promise<Option[]> {
  const response = await AxiosHelper.get<{
    items: Array<{ id: string; name: string }>;
  }>({
    url: `${config.LOCALS_URL}?columns[]=name&columns[]=id&filter[locals][active][]=true&search=${input}`,
  });
  return response.body.items.map((item) => ({
    label: item.name,
    value: item.id,
  }));
}

export async function fetchUsers(
  input: string,
): Promise<Array<Option & { avatarUrl?: string }>> {
  const response = await AxiosHelper.get<{
    items: Array<{ id: string; full_name: string; avatar_url?: string }>;
  }>({
    url: `${config.USERS_URL}?columns[]=full_name&columns[]=id&columns[]=avatar_url&search=${input}`,
  });
  return response.body.items.map((item) => ({
    label: item.full_name,
    value: item.id,
    avatarUrl: item.avatar_url,
  }));
}

export async function fetchTags(
  input: string,
): Promise<Array<{ label: string; value: string; color: string }>> {
  const response = await AxiosHelper.get<{
    items: Array<{ id: string; name: string; color: string }>;
  }>({
    url: `${config.TAGS_URL}?search=${input}`,
  });

  return response.body.items.map((item) => ({
    label: item.name,
    value: item.id,
    color: item.color,
  }));
}

export async function createTag(
  tag: Pick<Tag, 'name' | 'color'>,
): Promise<Tag> {
  const response = await AxiosHelper.post({ url: config.TAGS_URL, body: tag });
  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new UnexpectedError();
  }
  return response.body;
}
