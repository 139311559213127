import { usePresentation } from '@/hooks/Presentation';
import ActionSheetLayer from '@screens/utils/ActionSheets/ActionSheetLayer';
import React from 'react';

import MainMenuBar from './components/MainMenuBar';
import MobileDropDownMenu from './components/MobileDropDownMenu';
import Navbar from './components/Navbar';
import SubMenuBar from './components/SubMenuBar';
import { NavbarProps } from './interfaces';
import { Container, Content } from './styles';

const Main: React.FC<NavbarProps> = ({
  setMainSelected,
  subList,
  mainList,
  isMainMenuOpen,
  setMainMenuOpen,
  subSelected,
  mainSelected,
  setSubSelected,
  showSubMenu,
  children,
  isCollapsed,
  isMenuDisabled,
  isMobile,
  toggleCollapsible,
  setSubList,
}) => {
  const { isPresenting } = usePresentation();

  return (
    <Container showSubMenu={showSubMenu} subMenuCollapsed={isCollapsed}>
      <Navbar
        setMainMenuOpen={setMainMenuOpen}
        menuDisabled={isMenuDisabled || isPresenting}
        isMainMenuOpen={isMainMenuOpen}
      />
      {isMobile ? (
        <MobileDropDownMenu
          mainList={mainList}
          mainSelected={mainSelected}
          setMainSelected={setMainSelected}
          isMainMenuOpen={isMainMenuOpen}
          subList={subList}
          subSelected={subSelected}
          setSubSelected={setSubSelected}
          mainTitle={
            mainList.find((route) => route.name === mainSelected)?.title
          }
        />
      ) : (
        <>
          <MainMenuBar
            mainList={mainList}
            mainSelected={mainSelected}
            setMainSelected={setMainSelected}
            isMainMenuOpen={isMainMenuOpen}
            setSubList={setSubList}
          />
          {showSubMenu && (
            <SubMenuBar
              isCollapsed={isCollapsed}
              collapse={toggleCollapsible}
              isMainMenuOpen={isMainMenuOpen}
              subList={subList}
              subSelected={subSelected}
              setSubSelected={setSubSelected}
              mainTitle={
                mainList.find((route) => route.name === mainSelected)?.title
              }
            />
          )}
        </>
      )}
      <ActionSheetLayer />
      <Content
        isMobile={isMobile}
        deviceWidth={window.innerWidth}
        onMouseEnter={() => {
          setMainMenuOpen(false);
        }}
      >
        {children}
      </Content>
    </Container>
  );
};

export default Main;
