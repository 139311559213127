import config from '@/Constants';
import BaseImporterService from '@shared/data/usecases/BaseImporterService';
import AxiosHelper from '@shared/infra/protocols/http/AxiosHelper';

export const collectionServices = new BaseImporterService(
  AxiosHelper,
  config.IMPORTER_URL,
  'collection',
);

export const formularyServices = new BaseImporterService(
  AxiosHelper,
  config.IMPORTER_URL,
  'formulary',
);

export const localServices = new BaseImporterService(
  AxiosHelper,
  config.IMPORTER_URL,
  'local',
);

export const scheduleServices = new BaseImporterService(
  AxiosHelper,
  config.IMPORTER_URL,
  'schedule',
);

export const userServices = new BaseImporterService(
  AxiosHelper,
  config.IMPORTER_URL,
  'user',
);

export const dashboardsServices = new BaseImporterService(
  AxiosHelper,
  config.IMPORTER_URL,
  'dashboards',
);

export const dashboardUsersServices = new BaseImporterService(
  AxiosHelper,
  config.IMPORTER_URL,
  'dashboard_users',
);

export const taskServices = new BaseImporterService(
  AxiosHelper,
  config.IMPORTER_URL,
  'task',
);

export const ticketsImportService = new BaseImporterService(
  AxiosHelper,
  config.IMPORTER_URL,
  'ticket',
);
