import { Flex } from '@chakra-ui/react';
import React from 'react';

import TasksList from './components/TicketsList';

const BoardTicket: React.FC = () => {
  return (
    <Flex overflowY="hidden" direction="column" flex={1}>
      <TasksList />
    </Flex>
  );
};

export default BoardTicket;
