import Loader from '@/components/Loader';
import { BaseFormulary } from '@/components_v2/Formularies/BaseForm';
import { BaseFormRef } from '@/components_v2/Formularies/BaseForm/localGeneric';
import { useNotificationToast } from '@/components_v2/WCNotificationToast/WCNotificationToast';
import {
  Formulary,
  FormularyAnswers,
} from '@/modules/core/domain/interfaces/IFormulary';
import { IAnswerState } from '@/modules/SurveyForm';
import { ErrorHandler } from '@/services/formHandle';
import { colors } from '@/styles/theme';
import { useToast } from '@chakra-ui/react';
import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateProps, ReducerFormularyData } from './interfaces';
import Main from './Main';
import { formularyReducer } from './utils';

/**
 * Implementação do BaseForm. Utilizado nos módulos
 *  de configuração de exporter.
 */
const ExporterForm: React.FC<CreateProps> = ({
  submitHandler,
  fetchTemplate,
  buttonLabel,
}) => {
  const toast = useToast();
  const notify = useNotificationToast();
  const ref = useRef<BaseFormRef>(null);

  const [state, dispatch] = useReducer(
    formularyReducer,
    {} as ReducerFormularyData,
  );

  const { t } = useTranslation();

  const fetchFormulary = useCallback(async () => {
    dispatch({ type: 'set_fetch_loading', status: true });
    const formulary: Formulary = await fetchTemplate();

    dispatch({ type: 'set', formulary });
    dispatch({ type: 'set_fetch_loading', status: false });
  }, [fetchTemplate]);

  useEffect(() => {
    fetchFormulary();
  }, [fetchFormulary]);

  const handleRemoteSubmit = useCallback(
    async (answers: FormularyAnswers) => {
      dispatch({ type: 'set_subimit_loading', status: true });

      const { body, statusCode } = await submitHandler(answers);
      if (body && body.downloadFileUrl) {
        toast.closeAll();
        setTimeout(() => {
          notify({
            id: body.downloadFileUrl,
            duration: 8000,
            title: t('notification.export_success'),
            notificationType: 'file',
            onClick: () => window.open(body?.downloadFileUrl),
          });
        }, 1);
      } else if (statusCode >= 200 && statusCode < 300) {
        toast({
          status: 'info',
          title: t('common.success'),
          description: 'Exportação irá iniciar em instantes...',
        });
      } else {
        const formattedError: string = ErrorHandler(body.error_messages);
        toast({
          status: 'error',
          title: t('error.warning'),
          description: t(formattedError),
        });
      }
      dispatch({ type: 'set_subimit_loading', status: false });
    },
    [submitHandler, notify, t, toast],
  );

  const submitForm = useCallback(async () => {
    const result = await ref?.current?.submit();
    if (result) {
      const { answers, valid } = result;
      if (!valid) {
        toast({
          status: 'warning',
          title: t('error.warning'),
          description: t('formulary.verifyRequiredAnswers'),
        });

        return;
      }
      if (answers && !Object.keys(answers).length) {
        toast({
          status: 'warning',
          title: t('error.warning'),
          description: t('formulary.answerOneQuestion'),
        });

        return;
      }

      if (answers) {
        Object.keys(answers).forEach(
          (key) => answers[key] === undefined && delete answers[key],
        );
        handleRemoteSubmit(answers as IAnswerState);
      }
    }
  }, [handleRemoteSubmit, t, toast]);

  if (state.isFetchLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 20,
        }}
      >
        <Loader loadColor={colors.primary} />
      </div>
    );
  }

  if (!state.formulary) return <></>;

  return (
    <BaseFormulary ref={ref} formulary={state.formulary}>
      <Main
        sections={state.formulary.sections}
        submitForm={submitForm}
        buttonLabel={buttonLabel}
      />
    </BaseFormulary>
  );
};

export default ExporterForm;
