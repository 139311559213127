import { LinkIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonGroup, Collapse, Flex } from '@chakra-ui/react';
import React from 'react';

import { useWebFormProps } from '../../hooks/useWebFormProps';
import { useQuestionItem } from './context';
import { QuestionAttachmentPane } from './QuestionAttachmentPane';

const QuestionPane: React.FC<{ pane: string }> = ({ pane, children }) => {
  const { paneIsOpen } = useQuestionItem();

  const isOpen = React.useMemo(() => paneIsOpen === pane, [pane, paneIsOpen]);
  return (
    <Collapse in={isOpen}>
      <Flex bg="gray.50" borderTopWidth="thin" p="3" flex="1" mt="2" minH="10">
        {children}
      </Flex>
    </Collapse>
  );
};

export const QuestionAppend: React.FC<{ disableAttachements?: boolean }> = ({
  disableAttachements,
}) => {
  const { itemId } = useWebFormProps();

  const { togglePane, paneIsOpen } = useQuestionItem();

  return (
    <Flex borderTopWidth="thin" w="full" flexDir="column">
      {!disableAttachements && (
        <>
          <ButtonGroup p="3.5">
            {!!itemId && (
              <Button
                variant="outline"
                size="md"
                isActive={paneIsOpen === 'attachments'}
                leftIcon={<LinkIcon />}
                onClick={() =>
                  togglePane((old) =>
                    old === 'attachments' ? '' : 'attachments',
                  )
                }
              >
                Anexos
              </Button>
            )}
            {/* <Button
          variant="outline"
          size="md"
          leftIcon={<BiComment />}
          isActive={paneIsOpen === 'notes'}
          onClick={() => togglePane((old) => (old === 'notes' ? '' : 'notes'))}
          >
          Adicionar nota
        </Button> */}
          </ButtonGroup>
          <Box w="full">
            <QuestionPane pane="attachments">
              <QuestionAttachmentPane />
            </QuestionPane>
          </Box>
        </>
      )}
    </Flex>
  );
};
