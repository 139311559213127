import { useModals } from '@/hooks/ModalManager';
import { useToast } from '@chakra-ui/react';
import React from 'react';

import { useShareListModal } from '../components/ShareListModal';
import { useTemplateTaskCreateForm } from '../components/TemplateTaskCreateForm';
import { getTableColumns } from '../utils';
import { useDeleteTemplateTask } from './useTemplateTask.mutations';
import { useTemplateTasksQuery } from './useTemplateTask.queries';

export function useTemplateTasks() {
  const toast = useToast();
  const modals = useModals();
  const { mutateAsync } = useDeleteTemplateTask();
  const openForm = useTemplateTaskCreateForm();
  const [search, setSearchTerm] = React.useState<string>('');
  const openShareListModal = useShareListModal();
  const { data = [], isLoading } = useTemplateTasksQuery(search);

  const onDelete = React.useCallback(
    (id: string) => {
      modals.confirm({
        title: 'Atenção!',
        body:
          'Certeza que deseja deletar este template? A ação não pode ser desfeita.',
        onConfirm: async () => {
          await mutateAsync(id, {
            onSuccess() {
              toast({
                title: 'Template deletado.',
              });
            },
          });
        },
      });
    },
    [modals, mutateAsync, toast],
  );

  const columns = React.useMemo(
    () =>
      getTableColumns({
        onView: openForm,
        onShare: (id) => openShareListModal({ templateTaskId: id }),
        onDelete,
      }),
    [onDelete, openForm, openShareListModal],
  );

  return {
    columns,
    data,
    isLoading,
    setSearchTerm,
    openForm,
  };
}
