import { gql } from '@/gql';
import { useMutation, useQuery } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const externalTaskQuery = gql(`#graphql
  query PublicTemplateTaskUrl($templateTaskUrlId: ID!) {
    publicTemplateTaskUrl(id: $templateTaskUrlId) {
      active
      id
      createdAt
      deletedAt
      templateTaskId
      description
      templateTask {
        id
        name
        dueIn
        formulary {
          id
          sections {
            id
            imageUrl
            position
            name
            enabled
            description
            formularyId
            questions {
              id
              hasComments
              formularyId
              tipText
              validationUrl
              validationMinChar
              taskCreate
              taskButtonLabel
              sectionId
              scoreWeight
              required
              questionType
              qrCodeOnly
              position
              parentQuestionId
              parentOptionId
              name
              meta
              maxPictures
              inputMask
              imageUrl
              description
              enabled
              conditional
              codeMaxChar
              options {
                id
                isFlagged
                score
                questionId
                position
                name
                description
                alertMessage
              }
            }
          }
          enabled
          disableSubtasks
          disableComments
          disableAttachments
          disableAnswerHistory
          recurrent
          required
          name
          formularyType
        }
      }
    }
  }
`);

const templateTaskSendEmailPdf = gql(`#graphql
  mutation TemplateTaskUrlSendEmailPdf($input: SendEmailPdfInput!) {
    templateTaskUrlSendEmailPdf(input: $input) {
      email
      description
      errors
      eventId
    }
  }
`);

const submitAnswersTemplateTasksUrl = gql(`#graphql
  mutation SubmitAnswersTemplateTaskUrl($input: SubmitTemplateTaskInput!) {
    submitAnswersTemplateTaskUrl(input: $input) {
      errors
      event {
        id
      }
    }
  }
`);

export function useExternalTaskForm(id: string) {
  return useQuery(externalTaskQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      templateTaskUrlId: id,
    },
  });
}

export function useSubmitAnswersTemplateTask() {
  return useMutation(submitAnswersTemplateTasksUrl);
}

export function useSendPdf() {
  const { t } = useTranslation();
  const toast = useToast();
  return useMutation(templateTaskSendEmailPdf, {
    onError: (error) => {
      toast({
        status: 'error',
        title: t('error.warning'),
        description: error.message,
      });
    },
  });
}
