import { FilterItem, FilterList } from '@/components_v2/Filtersv3/filter-item';
import { FilterScreenProvider } from '@/components_v2/Filtersv3/filter-store';
import { FilterCalendar } from '@/components_v2/Filtersv3/reactive-filter-calendar';
import SearchBar from '@/components_v2/inputs/SearchBar';
import Loading from '@/components_v2/Loading';
import config from '@/Constants';
import { FlaggedAnswersConnectionFragmentFragment } from '@/gql/graphql';
import { MacroColumnFilter, MacroTableFilter } from '@/gql/types';
import {
  DiscussionTopic,
  DiscussionTopicStatusType,
} from '@/hooks/Feedback/interfaces';
import { useFetchOptions } from '@/hooks/useFetchOptions';
import {
  Button,
  Center,
  Flex,
  FlexProps,
  HStack,
  Icon,
  List,
  Tag,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { BsInboxFill } from 'react-icons/bs';
import { useQuery } from 'react-query';

import {
  JustificationsProvider,
  useJustificationsContext,
} from './hooks/context';
import JustificationView from './JustificationView';
import {
  JustificationViewContextProvider,
  JustificationViewDrawer,
} from './JustificationViewDrawer';
import { StatusTag } from './StatusTag';

const Header: React.FC = () => {
  // const { selectedOptions } = useFilter();
  const { setSearch } = useJustificationsContext();

  const { options, loading, refetch } = useFetchOptions({
    column: MacroColumnFilter.Content,
    table: MacroTableFilter.Answers,
    filters: {
      questions: {
        name: ['UNIDADE', '3. UNIDADE', 'UNIDADES', 'Unidade'],
      },
    },
  });

  const onSearchContentFilter = React.useCallback((search: string) => {
    refetch({
      search: search,
    });
  }, []);

  return (
    <Flex flexDir="column" w="full">
      <Flex
        alignItems="center"
        justify="space-between"
        w="full"
        minH="20"
        ps="8"
        pe="4"
        py="4"
        borderBottomWidth="thin"
      >
        <Flex alignItems="center" gap="2">
          <Text fontWeight="bold" fontSize="lg">
            Justificativas
          </Text>
        </Flex>
      </Flex>
      <Flex
        borderBottomWidth="thin"
        w="full"
        justify="space-between"
        alignItems="center"
        py="3"
        px="4"
      >
        <SearchBar onDebouce debounceTime={1000} onSubmit={setSearch} />
        <FilterList>
          <FilterItem
            label={'Unidade'}
            filterKey={'content'}
            loading={loading}
            options={options}
            onSearch={onSearchContentFilter}
          />
          <FilterCalendar label={'Data'} filterKey={'updated_at'} />
          <FilterCalendar label={'Finalizado em'} filterKey={'finished_at'} />
        </FilterList>
      </Flex>
    </Flex>
  );
};

type JustificationItemProps = {
  item: FlaggedAnswersConnectionFragmentFragment;
  isSelected?: boolean;
} & FlexProps;

const JustificationItem: React.FC<JustificationItemProps> = (props) => {
  const { isSelected, item, ...rest } = props;

  return (
    <Flex
      transition=".15s ease"
      cursor="pointer"
      borderBottomWidth="thin"
      _hover={{
        bg: 'lightHover',
      }}
      w="full"
      h="fit-content"
      ps="6"
      pe="4"
      py="4"
      bg={isSelected ? 'blackAlpha.200' : 'whiteAlpha.100'}
      flexDir="column"
      gap="2"
      {...rest}
    >
      <Flex w="full" flexDir="row" justify="space-between">
        <Text color="muted" fontWeight="bold">
          {item?.question?.formulary?.name} / {item?.question?.section?.name} /{' '}
          {moment(item.event.finishedAt * 1000).format('DD-MM-YYYY')}
        </Text>
        {item.topics?.[0]?.commentsCount &&
          item.topics?.[0]?.statusType &&
          item.topics?.[0].commentsCount > 0 && (
            <StatusTag
              status={item.topics?.[0].statusType as DiscussionTopicStatusType}
              size="md"
              flexShrink={0}
              h="fit-content"
            />
          )}
      </Flex>
      <Text color="muted" fontWeight="bold">
        UNIDADE: {item?.facility}
      </Text>
      <Text fontWeight="bold" color="darkText" noOfLines={2}>
        {item?.question?.name}
      </Text>

      {item?.flaggedOptions?.map((option) => (
        <VStack w="full" spacing="2" alignItems="flex-start">
          <Tag
            key={option?.name}
            size="lg"
            variant="solid"
            colorScheme="orange"
          >
            {option?.name}
          </Tag>
        </VStack>
      ))}

      <HStack>
        {/* <Avatar name={feedback?.lastComment?.authorName} size="xs" /> */}
        <Flex w="full" flexDir="row" justify="space-between">
          {/* <Text noOfLines={1}>{feedback?.lastComment?.content}</Text> */}
          <Text noOfLines={1}>{moment(item?.updatedAt).calendar()}</Text>
        </Flex>
      </HStack>
    </Flex>
  );
};

const Placeholder = () => {
  return (
    <Center flex="1">
      <Flex
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Icon as={BsInboxFill} fontSize="150px" color="muted" />
        <Text fontSize="lg">Selecione um item ao lado</Text>
      </Flex>
    </Center>
  );
};

const Content = () => {
  const {
    selectJustification,
    selectedJustificationId,
  } = useJustificationsContext();
  const mode = useBreakpointValue({
    base: 'drawer',
    lg: 'default',
  });

  const renderContent = React.useCallback(() => {
    if (mode !== 'default') return null;

    if (!selectedJustificationId?.length) {
      return (
        <Flex flex="1">
          <Placeholder />
        </Flex>
      );
    }

    return (
      <Flex flex="1">
        <JustificationView />
      </Flex>
    );
  }, [mode, selectedJustificationId?.length]);
  return (
    <>
      <JustificationViewContextProvider id={selectedJustificationId!}>
        <JustificationViewDrawer
          id={selectedJustificationId!}
          isOpen={!!selectedJustificationId?.length && mode === 'drawer'}
          onClose={() => selectJustification('')}
        />
      </JustificationViewContextProvider>
      {renderContent()}
    </>
  );
};

const Justifications: React.FC = () => {
  const {
    items,
    hasNextPage,
    fetchNextPage,
    selectJustification,
    selectedJustificationId,
    isLoading,
  } = useJustificationsContext();

  return (
    <Flex w="full" h="full">
      <Flex
        w={{ base: 'full', lg: '430px' }}
        borderRightWidth="thin"
        bg="white"
        flexDir="column"
      >
        <Header />
        <Scrollbars>
          <List overflowY="auto">
            <Flex
              hidden={items?.length !== 0 || isLoading}
              w="full"
              alignItems="center"
              justifyContent="center"
              p="6"
            >
              Sem resultados
            </Flex>
            {items?.map((item) => (
              <JustificationItem
                onClick={() => selectJustification(item.id)}
                isSelected={item.id === selectedJustificationId}
                key={item.id}
                item={item}
              />
            ))}
            <Flex
              hidden={!hasNextPage && !isLoading}
              w="full"
              alignItems="center"
              justifyContent="center"
              p="2"
            >
              {isLoading ? (
                <Loading />
              ) : (
                <Button onClick={fetchNextPage}>Carregar mais...</Button>
              )}
            </Flex>
          </List>
        </Scrollbars>
      </Flex>
      <Content />
    </Flex>
  );
};

const Wrapped: React.FC = () => {
  return (
    <FilterScreenProvider screen="justification">
      <JustificationsProvider>
        <Justifications />
      </JustificationsProvider>
    </FilterScreenProvider>
  );
};

export default Wrapped;
