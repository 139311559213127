import { FeedbackError } from '@/components/Feedback';
import config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { Task } from '@/screens/registrations/Tasks/interfaces';

async function updateTasksService(
  ids: string[],
  update: Partial<Task>,
): Promise<boolean> {
  const { statusCode } = await AxiosHelper.patch({
    url: `${config.TASKS_URL}`,
    body: {
      ids,
      ...update,
    },
  });
  if (statusCode < 200 || statusCode > 300) {
    FeedbackError({ mainText: 'Falha ao criar tarefa' });
    return false;
  }
  return true;
}

export { updateTasksService };
