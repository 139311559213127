import { Flex } from '@chakra-ui/react';
import React from 'react';

import { Content } from './components/Content/Content';
import { Header } from './components/Header/Header';
import { AnswerSummaryProvider } from './hooks/useAnswerSummary';
import { AnswerReportsProps, AnswerReportsRenderProps } from './interfaces';

const Reports: React.FC<AnswerReportsRenderProps> = () => {
  return (
    <Flex bg="white" flex="1" flexDir="column">
      <Header />

      <Flex overflow="auto" flex="1" position="relative">
        <Flex ms={{ base: 'none', xl: '-40' }} flex="1" justifyContent="center">
          <Content />
        </Flex>
      </Flex>
    </Flex>
  );
};

export const Answer: React.FC<AnswerReportsProps> = ({ answerId }) => {
  return (
    <AnswerSummaryProvider answerId={answerId}>
      <Reports />
    </AnswerSummaryProvider>
  );
};
