import React from 'react';

export function usePaginationObserver(props: { onPaginate?: () => void }) {
  const observerTarget = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          props?.onPaginate?.();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, props]);

  return { observerTarget };
}

export const useTick = () => {
  const [_, tick] = React.useState(-1);
  return () => tick((old) => old + 1);
};
