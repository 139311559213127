import React, { useCallback } from 'react';
import { Box, Portal, useDisclosure } from '@chakra-ui/react';
import { Line } from 'rc-progress';
import { useDropzone } from 'react-dropzone';
import DropzonePlaceholder from '../DropzonePlaceholder';
import { UploadPopoverProps } from './UploadPopover.types';

const UploadPopover: React.FC<UploadPopoverProps> = ({
  withPortal,
  onDrop,
  isUploading,
  progress,
}) => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const handleDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      onClose();
      onDrop(acceptedFiles);
    },
    [onDrop, onClose],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    maxFiles: 20,
    accept: 'image/*',
  });

  const renderInPortal = useCallback(
    (content: JSX.Element) => {
      if (withPortal) {
        return <Portal appendToParentPortal>{content}</Portal>;
      }
      return content;
    },
    [withPortal],
  );

  return (
    <Box>
      <Box
        maxWidth="300px"
        maxHeight="180px"
        display="flex"
        bg="gray.100"
        borderWidth="1px"
        borderStyle="dashed"
        borderColor="gray.300"
        borderRadius="10px"
        overflow="hidden"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <DropzonePlaceholder />
      </Box>
      {isUploading && (
        <div style={{ paddingTop: 10 }}>
          <Line
            percent={progress}
            strokeWidth={4}
            strokeColor="#0794B2"
            trailColor="#EBEBEB"
          />
        </div>
      )}
    </Box>
  );
};

export default UploadPopover;
