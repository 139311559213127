import React, { useState } from 'react';

import Tooltip from '@components/Tooltip';
import debounce from '@utils/debounce';
import {
  QuestionContainer,
  QuestionHeader,
  QuestionTitle,
  QuestionSubtitle,
  Input,
  InfoTooltip,
  TooltipContainer,
} from '../common';
import { NumberProps } from './interfaces';

const Number: React.FC<NumberProps> = ({
  question,
  errors,
  initialValues,
  onChange,
  viewOnly = false,
}) => {
  const [number, setNumer] = useState(initialValues?.values || '');
  const [debouncedText] = useState(() =>
    debounce(async (newText: string) => {
      onChange(newText);
    }, 200),
  );

  const onChangeNumber = (newNumber: string) => {
    if (newNumber === '') {
      setNumer('');
      debouncedText('');
    }
    const isValid = /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+$)/g.test(newNumber);
    if (isValid) {
      setNumer(newNumber);
      debouncedText(newNumber);
    }
  };

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionTitle>{question.name}</QuestionTitle>
        {question.tipText && (
          <Tooltip description={question.tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      <Input
        disabled={viewOnly}
        value={number}
        type="number"
        min={0}
        onChange={(e) => onChangeNumber(e.target.value)}
        hasError={!!errors}
      />
      <QuestionSubtitle hasError={!!errors}>
        {errors && errors.errors}
      </QuestionSubtitle>
    </QuestionContainer>
  );
};

export default Number;
