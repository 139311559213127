import { useNotifications } from '@/hooks/Notifications/notifications';
import { useNotificationsList } from '@/hooks/Notifications/notificationsList';
import { Box, Text as ChakraText, Flex } from '@chakra-ui/react';
import moment from 'moment';
import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';

import renderTags from '../common/renderTags';
import NotificationItemIcon from './icon';
import { NotificationItemProps } from './interfaces';
import {
  BodyContainer,
  CheckIcon,
  CheckIconContainer,
  Container,
  IconContainer,
  MainContainer,
  SentTime,
  TagsContainer,
  Text,
} from './styles';

function isSVGAnimatedString(
  s: string | SVGAnimatedString,
): s is SVGAnimatedString {
  return (s as SVGAnimatedString).baseVal !== undefined;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
}) => {
  const { getOnClickFeedback } = useNotifications();
  const { markAsRead, read } = useNotificationsList();

  const handleClick = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement> & {
        target: {
          className: string | SVGAnimatedString;
        };
      },
    ) => {
      // o StyledComponents ta com a tipagem faltando o campo styledComponentId
      const icon: { styledComponentId: string } = CheckIcon as any;
      if (e.target && e.target.className) {
        const s = e.target.className;
        if (
          isSVGAnimatedString(s) &&
          (s.baseVal.includes(CheckIconContainer.styledComponentId) ||
            s.baseVal.includes(icon.styledComponentId))
        ) {
          return;
        }
        if (
          typeof s === 'string' &&
          (s.includes(CheckIconContainer.styledComponentId) ||
            s.includes(icon.styledComponentId))
        ) {
          return;
        }
      }
      getOnClickFeedback(notification)();
      markAsRead(notification);
    },
    [getOnClickFeedback, notification, markAsRead],
  );

  const renderBody = useCallback(() => {
    switch (notification.type) {
      case 'note':
        return (
          <Box>
            <Text>
              <Trans
                i18nKey={notification.body.message.messageKey}
                values={notification.body.message.messageData}
                components={{ strong: <span /> }}
              />
            </Text>
            <Box>
              <Flex w="full" justifyContent="space-between">
                <ChakraText
                  mb="2"
                  fontWeight="bold"
                  color="muted"
                  fontSize="sm"
                  noOfLines={1}
                >
                  {`${notification.body?.message?.messageData?.formulary_name} / ${notification.body?.message?.messageData?.section_name}`}
                </ChakraText>
              </Flex>
              <ChakraText fontSize="sm">
                {notification.body?.message?.messageData?.question_name}
              </ChakraText>
            </Box>
          </Box>
        );
      case 'end_sla':
        return (
          <Box>
            <Text>
              {notification.body.title}
            </Text>
            <Box>
              <Flex w="full" justifyContent="space-between">
                <ChakraText
                  mb="2"
                  fontWeight="bold"
                  color="muted"
                  fontSize="sm"
                  noOfLines={1}
                >
                  {`${notification.body?.message?.messageData?.formulary_name} / ${notification.body?.message?.messageData?.section_name}`}
                </ChakraText>
              </Flex>
              <ChakraText fontSize="sm">
                {notification.body?.message?.messageData?.question_name}
              </ChakraText>
            </Box>
          </Box>
        );
      default:
        return (
          <Text>
            <Trans
              i18nKey={notification.body.message.messageKey}
              values={notification.body.message.messageData}
              components={{ strong: <span /> }}
            />
          </Text>
        );
    }
  }, [notification]);

  return (
    <Container onClick={handleClick}>
      <MainContainer>
        <IconContainer>
          <NotificationItemIcon notification={notification} />
        </IconContainer>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <BodyContainer>
            {renderBody()}
            <SentTime>{moment(notification.sentTime).fromNow()}</SentTime>
          </BodyContainer>

          <TagsContainer>{renderTags(notification.type)}</TagsContainer>
        </div>
        {!read && (
          <CheckIconContainer>
            <CheckIcon onClick={() => markAsRead(notification)} />
          </CheckIconContainer>
        )}
      </MainContainer>
    </Container>
  );
};

export default NotificationItem;
