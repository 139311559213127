import IAuthentication from '@security/domain/usecases/IAuthentication';
import {
  InvalidCredentialsError,
  UnexpectedError,
  DeviceLimitExceededError,
  InvalidWorkspaceError,
} from '@shared/domain/errors';
import {
  AuthParams,
  AuthReturn,
  AuthenticationErrors,
} from '@shared/domain/interfaces';
import { IApiHelper, HttpStatusCode } from '@shared/data/protocols/http';

class RemoteAuthentication implements IAuthentication {
  private readonly api: IApiHelper;
  private readonly url: string;

  constructor(api: IApiHelper, url: string) {
    this.api = api;
    this.url = url;
  }

  async auth(params: AuthParams): Promise<AuthReturn> {
    const response = await this.api.post({
      url: this.url,
      body: params,
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body;
      case HttpStatusCode.notFound:
        throw new InvalidWorkspaceError();
      case HttpStatusCode.unauthorized:
        switch (response.body.error.user_authentication) {
          case AuthenticationErrors.deviceLimitExceeded:
            throw new DeviceLimitExceededError();

          case AuthenticationErrors.invalidCredentials:
            throw new InvalidCredentialsError();
          default:
            throw new UnexpectedError();
        }
      default:
        throw new UnexpectedError();
    }
  }
}

export default RemoteAuthentication;
