import { FormView } from '@/components_v2/WebForm/components';
import { InspectionProvider } from '@/components_v2/WebForm/context/store';
import useFormDetails from '@/components_v2/WebForm/hooks/useFormDetails';
import { WebFormPropsProvider } from '@/components_v2/WebForm/hooks/useWebFormProps';
import { parseFormularyToFields } from '@/components_v2/WebForm/utils/parseFormularyToFields';
import { WebFormProvider } from '@/modules/WebForm/data/formState';
import useWebForm from '@/modules/WebForm/hooks/useWebForm';
import useSerializedSubmit, {
  SerializeCallbackFunc,
} from '@/screens/ItemEdit/hooks/useSerializedSubmit';
import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Prompt } from 'react-router-dom';

export type WorkflowInspectionProps = {
  formularyId: string;
  defaultValues?: any;
  onSubmit: SerializeCallbackFunc;
};

const Content: React.FC<WorkflowInspectionProps> = (props) => {
  const { defaultValues, onSubmit } = props;
  const { formulary } = useFormDetails();
  const { setFields, setInitialValues } = useWebForm();
  React.useEffect(() => {
    if (formulary) {
      setFields(parseFormularyToFields(formulary));
    }
  }, [formulary, setFields]);

  React.useEffect(() => {
    if (defaultValues) {
      setInitialValues(defaultValues);
    }
  }, [defaultValues, setInitialValues]);

  const handleSubmit = useSerializedSubmit((res) => {
    onSubmit(res);
  });

  return (
    <WebFormPropsProvider
      formularyId=""
      noHeader
      onSubmit={handleSubmit}
      itemId=""
    >
      <Flex flex="1" minH="70vh" h="full">
        <FormView px="16" />
      </Flex>
    </WebFormPropsProvider>
  );
};

export const WorkflowInspection: React.FC<WorkflowInspectionProps> = (
  props,
) => {
  const { formularyId } = props;
  React.useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = 'prevent_leave';
  };

  return (
    <>
      <InspectionProvider formularyId={formularyId}>
        <Prompt when message="Are you sure you want to leave?" />

        <WebFormProvider>
          <Content {...props} />
        </WebFormProvider>
      </InspectionProvider>
    </>
  );
};
