import { UnexpectedError } from '@/modules/shared/domain/errors';
import { TaskWithEvent } from '@/screens/registrations/Tasks/interfaces';

import { getEvent } from './getEvent';
import { getTask } from './getTask';

export async function getTaskAndEvent(
  id: string,
  type: 'event' | 'task',
): Promise<TaskWithEvent> {
  const fetch =
    type === 'event'
      ? (id: string) => getEvent(id)
      : (id: string) => getTask(id);

  const data: any = await fetch(id);

  if (!data) throw new UnexpectedError();

  if (type === 'event' || !data?.event_id) return data;

  const event = await getEvent(data?.event_id);

  return {
    ...data,
    event,
  };
}
