import config from '@/Constants';
import { checklistServices } from '@/modules/shared/adapters/ApiServices';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { createContext } from '@/utils/react-utils';
import { useToast } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ChecklistSummary } from '../../Checklists/interfaces';
import { useUpdateEvent } from './useUpdateEvent';

type SummaryProps = {
  eventId: string;
};

export type EventSummaryProps = {
  event?: ChecklistSummary;
  isLoading: boolean;
  isMutating: boolean;
  onUpdate: (data: PartialWithId<ChecklistSummary>) => Promise<void>;
};

const [Context, useEventSummary] = createContext<EventSummaryProps>({
  name: 'EventSummaryContext',
});

const EventSummaryProvider: React.FC<SummaryProps> = (props) => {
  const { eventId, children } = props;
  const toast = useToast();
  const { t } = useTranslation();
  const { mutateAsync, isLoading: isMutating } = useUpdateEvent();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery<ChecklistSummary>(
    `${config.EVENTS_URL}/${eventId}`,
    {
      refetchOnWindowFocus: false,
    },
  );

  const handleUpdate = React.useCallback(
    async (data: PartialWithId<ChecklistSummary>) => {
      await mutateAsync(data);
      toast({
        status: 'success',
        title: t('common.success'),
        description: t('messageEdit.event'),
      });
      const oldData = queryClient.getQueryData<ChecklistSummary>(
        `${config.EVENTS_URL}/${eventId}`,
      );

      if (!oldData) return;

      const newData: ChecklistSummary = {
        ...oldData,
        ...data,
      };

      queryClient.setQueriesData<ChecklistSummary>(
        `${config.EVENTS_URL}/${eventId}`,
        newData,
      );
    },
    [eventId, mutateAsync, queryClient, t, toast],
  );

  return (
    <Context
      value={{
        onUpdate: handleUpdate,
        event: data,
        isLoading,
        isMutating,
      }}
    >
      {children}
    </Context>
  );
};

export { useEventSummary, EventSummaryProvider };
