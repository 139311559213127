import React from 'react';
import { ScorePayload } from '../interfaces';
import { getScore, getScoreColor } from '../utils';

export function useScore(params: Partial<ScorePayload> = {}) {
  const { scoreObtained, totalScore } = params;

  const score = React.useMemo(() => getScore(params), [params]);
  const scoreColor = React.useMemo(() => getScoreColor(score || 0), [score]);

  return {
    scoreAbsolute: scoreObtained,
    score: score.toFixed(2),
    scoreColor,
    totalScore,
  };
}
