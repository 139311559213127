import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { useMutation, useQueryClient } from 'react-query';
import { updateTopic } from './services/updateTopic';
import { mutationKey } from './useFeedbackStatusType';

export function useUpdateTopic() {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateTopic, {
    onSuccess(data, variables) {
      if (!data) return;

      queryClient.setQueryData<DiscussionTopic>(
        [mutationKey, variables.id],
        (old) => {
          return {
            ...old,
            ...data,
          };
        },
      );
    },
  });

  return mutation;
}
