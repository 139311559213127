import React, { useMemo } from 'react';

import Modal from 'react-modal';

import { ModalRenderProps } from './interfaces';
import { generateModalStyle } from './styles';

const Main: React.FC<ModalRenderProps> = ({
  children,
  styles,
  modalStyles,
  ...rest
}) => {
  const routes = useMemo(() => {
    const el = document.querySelector('#app-routes');
    if (el) return el as HTMLElement;
    return undefined;
  }, []);

  return (
    <Modal
      style={generateModalStyle(styles, modalStyles)}
      closeTimeoutMS={150}
      {...rest}
      parentSelector={routes ? () => routes : undefined}
      appElement={document.getElementById('root') || undefined}
    >
      {children}
    </Modal>
  );
};

export default Main;
