import { useModals } from '@/hooks/ModalManager';
import useSubmitForm from '@/modules/WebForm/hooks/useSubmitForm';
import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import useFindQuestion from '../hooks/useFindQuestion';
import useSections from '../hooks/useSections';
import { useWebFormProps } from '../hooks/useWebFormProps';

const Pagination: React.FC = () => {
  const { onSubmit } = useWebFormProps();
  const { findQuestion } = useFindQuestion();
  const modals = useModals();
  const { sections, currentSection, setSection } = useSections();

  const idx = React.useMemo(
    () => sections.findIndex((el) => el.id === currentSection),
    [currentSection, sections],
  );

  const setSectByOffset = React.useCallback(
    (next: number) => () => {
      const nextIdx = idx + next;
      const nextSect = sections[nextIdx];
      setSection(nextSect.id);
    },
    [idx, sections, setSection],
  );

  const submitForm = useSubmitForm((result) => {
    const firstError = Object.keys(result.errors)?.[0];

    const firstErrorQuestion = findQuestion(firstError);
    if (onSubmit) {
      onSubmit(result);
      if (firstErrorQuestion?.sectionId) {
        setSection(firstErrorQuestion.sectionId);
      }
      return;
    }

    if (!result.isValid) {
      modals.alert({
        title: 'Atenção',
        body: 'Responda todos os campos obrigatórios!',
        onConfirm: () => {
          if (firstErrorQuestion?.sectionId) {
            setSection(firstErrorQuestion.sectionId);
          }
        },
      });
    }
  });

  const isLastPage = idx + 1 === sections.length;

  return (
    <Flex w="full" flexDir="row" justifyContent="space-between">
      <Button
        size="sm"
        disabled={idx - 1 === -1}
        colorScheme="brand"
        leftIcon={<FiChevronLeft />}
        onClick={setSectByOffset(-1)}
      >
        Página anterior
      </Button>
      {isLastPage ? (
        <Button
          size="sm"
          colorScheme="brand"
          rightIcon={<FiChevronRight />}
          onClick={submitForm}
        >
          Finalizar
        </Button>
      ) : (
        <Button
          size="sm"
          disabled={idx + 1 === sections.length}
          colorScheme="brand"
          rightIcon={<FiChevronRight />}
          onClick={setSectByOffset(1)}
        >
          Próxima página
        </Button>
      )}
    </Flex>
  );
};

export default Pagination;
