import React from 'react';
import { TabList, Tab, Tabs } from '@chakra-ui/react';
import { TabSelectorRenderProps } from './TabSelector.types';

function TabSelectorMinimalist<T extends string = string>({
  initialKey,
  onChange,
  tabs,
}: TabSelectorRenderProps<T>): JSX.Element {
  const [index, setIndex] = React.useState(() => {
    if (initialKey) return tabs.findIndex((tab) => tab.key === initialKey);
    return 0;
  });

  const handleChange = React.useCallback(
    (newIndex: number) => {
      setIndex(newIndex);
      onChange(tabs[newIndex].key, newIndex);
    },
    [onChange, tabs],
  );

  return (
    <Tabs variant="unstyled" index={index} onChange={handleChange}>
      <TabList borderRadius="6px" p={0}>
        {tabs.map((tab, index) => {
          let borderRadius = '0';
          if (index === 0) borderRadius = '6px 0 0 6px';
          if (index === tabs.length - 1) borderRadius = '0 6px 6px 0';
          return (
            <Tab
              px={1.5}
              py={0.5}
              fontSize="sm"
              borderWidth="1px"
              borderStyle="solid"
              borderColor="gray.200"
              borderRadius={borderRadius}
              bg="white"
              isDisabled={tabs.length === 1}
              _selected={{ bg: 'gray.200' }}
            >
              {tab.label}
            </Tab>
          );
        })}
      </TabList>
    </Tabs>
  );
}

export default TabSelectorMinimalist;
