export function fullScreenMode() {
  try {
    document.documentElement?.requestFullscreen();
  } catch (error) {
    console.log('Full Screen Mode is already enabled');
  }
}

export async function exitFullScreenMode() {
  try {
    await document.exitFullscreen();
  } catch (error) {
    console.log('Exit Full Screen Mode is already enabled');
  }
}
