import React from 'react';
import { useTranslation } from 'react-i18next';

import { renderLoading } from '@/components/Loading';
import WorkspaceAwareLogo from '@/components/WorkspaceAwareLogo';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import {
  Container,
  SignIn,
  InputContainer,
  MainContent,
  Title,
  Subtitle,
  SupportText,
} from '../styles';
import { SubmitButton } from '../components';
import { SignInProps } from './localGeneric';

const devMode = process.env.NODE_ENV === 'development';

const SignInScreen: React.FC<SignInProps> = ({
  signIn,
  isLoading,
  handleEmailChange,
  handlePasswordChange,
  isLoadingWorkspace,
  workspaceData,
  email,
  password,
  handleForgotPassword,
  handleValidateCode,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  if (!devMode && (isLoadingWorkspace || !workspaceData?.id)) return null;

  return (
    <Container onSubmit={signIn}>
      <MainContent>
        {isLoading && renderLoading()}
        <SignIn>
          <WorkspaceAwareLogo styles={{ marginBottom: 15 }} />
          <Title>
            {t('screens.signin')} {workspaceData?.name}
          </Title>
          <Subtitle>{window.location.hostname}</Subtitle>
          <InputContainer>
            <Input
              autoFocus
              value={email}
              placeholder={t('auth.login_credentials')}
              autoCapitalize="none"
              onChange={handleEmailChange}
              variant="auth"
              mb={2}
              size="lg"
            />
            <InputGroup size="lg">
              <Input
                pr={4}
                type={show ? 'text' : 'password'}
                value={password}
                variant={show ? 'outline' : 'filled'}
                placeholder={t('auth.password')}
                onChange={handlePasswordChange}
                mb={2}
              />
              <InputRightElement width={2}>
                <IconButton
                  mr={14}
                  cursor="pointer"
                  padding={0.5}
                  h="1.75rem"
                  aria-label="mostrar senha"
                  size="md"
                  onClick={handleClick}
                  as={show ? FiEyeOff : FiEye}
                />
              </InputRightElement>
            </InputGroup>
            <SubmitButton
              buttonContent={t('auth.login')}
              type="submit"
              callback={signIn}
            />
          </InputContainer>
          <SupportText
            onClick={handleForgotPassword}
            center
            styleOnHover
            pointer
          >
            {t('password_recovery.forgot_password')}
          </SupportText>
          <SupportText onClick={handleValidateCode} center styleOnHover pointer>
            {t('password_recovery.validate_verification_code')}
          </SupportText>
        </SignIn>
      </MainContent>
    </Container>
  );
};

export default SignInScreen;
