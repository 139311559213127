import Lightbox from '@/components/Lightbox';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';

import ImagePreview from './components/ImagePreview';
import WebcamCapture from './components/WebcamCapture';
import { ImageCaptureProps } from './interfaces';

export const ImageCapture: React.FC<ImageCaptureProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onChange, setUrls, urls } = props;

  const modalSize = useBreakpointValue({ base: 'full', lg: '2xl' });

  const captureImageFromWebcam = useCallback(
    (imageSrc: string) => {
      if (imageSrc) {
        setUrls((prev) => {
          const newUrls = [...prev, imageSrc];
          onChange(newUrls);
          return newUrls;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  );

  const onRemove = React.useCallback(
    (index: number) => {
      setUrls((prev) => {
        const newUrls = prev
          .slice(0, index)
          .concat(prev.slice(index + 1, prev.length));
        onChange(newUrls);
        return newUrls;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  );

  const onLightbox = React.useCallback(
    (index: number) => {
      Lightbox.openLightbox(urls, index);
    },
    [urls],
  );

  return (
    <>
      <Button mr={2} colorScheme="brand" onClick={onOpen}>
        Tirar foto
      </Button>

      <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Capture imagens com a webcam</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <WebcamCapture onCapture={captureImageFromWebcam} />
              <Text>Imagens na nuvem:</Text>
              <Wrap w="full">
                {urls.map((p, index) => (
                  <WrapItem key={`img_${p}`}>
                    <ImagePreview
                      url={p}
                      onRemove={() => onRemove(index)}
                      onOpen={() => onLightbox(index)}
                    />
                  </WrapItem>
                ))}
              </Wrap>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageCapture;
