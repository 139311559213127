import { useNotificationsList } from '@/hooks/Notifications/notificationsList';
import React from 'react';

import Main from './Main';

const NotificationsList: React.FC = () => {
  const { isValidating, notifications } = useNotificationsList();

  return <Main isValidating={isValidating} notifications={notifications} />;
};

export default NotificationsList;
