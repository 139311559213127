import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import { Field } from '@/modules/SurveyForm';
import React from 'react';

import { BaseQuestionProps } from './localGeneric';

const BaseQuestion: React.FC<BaseQuestionProps> = ({ question, render }) => {
  return (
    <Field<QuestionModel>
      question={question}
      render={render}
      key={question.id}
    />
  );
};

export default BaseQuestion;
