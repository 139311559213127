import React, { useCallback, useState } from 'react';
import { useWorkspace } from '@/hooks/workspace';
import { validateSignIn } from '@shared/validation';
import { useNavigation } from '@hooks/navigation';
import { SignInParams } from '@shared/domain/interfaces';
import { useAuth } from '@/hooks/Authentication/auth';
import Main from './SignIn';

const SignIn: React.FC = () => {
  const {
    signIn,
    loading: isLoading,
    previousLoginData,
    handlePreviousLoginData,
  } = useAuth();
  const {
    workspaceData,
    loading: loadingWorkspace,
    subdomain,
  } = useWorkspace();
  const { navigate } = useNavigation();
  const [email, setEmail] = useState<string>(previousLoginData.email || '');
  const [password, setPassword] = useState<string>('');
  const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    const currentEmail: string = event.currentTarget.value;
    setEmail(currentEmail);
  };

  const handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
    const currentPassword: string = event.currentTarget.value;
    setPassword(currentPassword);
  };

  const handleForgotPassword = () => {
    navigate('recovery');
  };

  const handleValidateCode = () => {
    navigate('recovery/validate');
  };

  const handleSignIn = useCallback(
    async (e) => {
      e.preventDefault();
      const parsedWorkspace = subdomain?.trim() || '';
      const signInParams: SignInParams = {
        workspace: parsedWorkspace,
        email: email.trim(),
        password: password.trim(),
      };
      handlePreviousLoginData({ workspace: parsedWorkspace, email });
      const isValid: boolean = await validateSignIn(signInParams);
      if (!isValid) return;
      signIn(signInParams);
    },
    [email, password, subdomain, handlePreviousLoginData, signIn],
  );

  return (
    <Main
      isLoading={isLoading}
      signIn={handleSignIn}
      handleEmailChange={handleEmailChange}
      handlePasswordChange={handlePasswordChange}
      isLoadingWorkspace={loadingWorkspace}
      workspaceData={workspaceData}
      email={email}
      password={password}
      handleForgotPassword={handleForgotPassword}
      handleValidateCode={handleValidateCode}
    />
  );
};

export default SignIn;
