import React from 'react';
import useField from '@/modules/WebForm/hooks/useField';
import { HStack, Text } from '@chakra-ui/react';
import { createAnswer } from '@/modules/WebForm/utils';
import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import GeolocationInput from '@/components_v2/inputs/GeolocationInput';
import { Coordinates } from '@/components_v2/inputs/GeolocationInput/GeolocationInput.types';
import FormControlComponent from '../FormControlComponent';

const GeolocationContent: React.FC<QuestionModel> = () => {
  const { error, onChange, fieldState, clear } = useField();
  const [address, setAddress] = React.useState<string | undefined>(undefined);

  const value = React.useMemo<Coordinates | undefined>(() => {
    if (
      fieldState &&
      !Array.isArray(fieldState) &&
      typeof fieldState.content === 'string'
    ) {
      return JSON.parse(fieldState.content);
    }
    return undefined;
  }, [fieldState]);

  const handleChange = React.useCallback(
    (newValue?: Coordinates, newAddress?: string) => {
      if (newValue) {
        onChange(createAnswer(JSON.stringify(newValue)));
      } else {
        clear();
      }
      setAddress(newAddress);
    },
    [onChange, clear],
  );

  const text = React.useMemo(() => {
    if (address) return address;
    if (value)
      return `Latitude: ${value.latitude.toFixed(
        4,
      )} Longitude: ${value.longitude.toFixed(4)}`;
    return 'Selecione um endereço';
  }, [address, value]);

  return (
    <FormControlComponent isInvalid={error}>
      <HStack maxW="100%">
        <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {text}
        </Text>
        <GeolocationInput
          initialValue={value}
          initialAddress={address}
          onChange={handleChange}
        />
      </HStack>
    </FormControlComponent>
  );
};

export default GeolocationContent;
