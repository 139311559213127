import { FeedbackError } from '@/components/Feedback';
import config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';

async function deleteTasksService(ids: string[]): Promise<boolean> {
  const { statusCode } = await AxiosHelper.delete({
    url: config.TASKS_URL,
    body: { ids },
  });
  if (statusCode < 200 || statusCode > 300) {
    FeedbackError({ mainText: 'Falha ao deletar tarefas' });
    return false;
  }
  return true;
}

export { deleteTasksService };
