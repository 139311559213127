import React from 'react';

import { Task } from '../../../interfaces';
import { TaskForm } from '../TaskForm';
import { useEditTask } from './useEditTask';

export function EditTask(props: {
  task?: Task;
  onSuccess?: (taskId?: string) => void;
  onDismiss?: () => void;
}) {
  const { task, onDismiss } = props;
  const { onSubmit, isSubmiting } = useEditTask(props);
  return (
    <TaskForm
      defaultValues={task}
      onSubmit={onSubmit}
      onDismiss={onDismiss}
      isLoading={isSubmiting}
    />
  );
}
