import config from '@/Constants';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import camelToSnake from '@/utils/camelToSnake';
import snakeToCamel from '@/utils/snakeToCamel';
import { TopicComment } from '../interfaces';

const mutationKey = config.DISCUSSION_COMMENTS_URL;

export interface TopicCommentPostData {
  content: string;
  discussionTopicId: string;
}

export const createFeedbackComment = async (
  params: TopicCommentPostData,
): Promise<TopicComment> => {
  const { body, statusCode } = await AxiosHelper.post({
    url: mutationKey,
    body: camelToSnake(params as any),
    timeout: 5000,
  });

  if (statusCode === 201) {
    return snakeToCamel(body);
  }
  throw new UnexpectedError();
};
