import Dropdown from '@/components_v2/inputs/BaseDropdown';
import { Option } from '@/components_v2/inputs/BaseDropdown/interfaces';
import Tooltip from '@components/Tooltip';
import React from 'react';

import {
  InfoTooltip,
  QuestionContainer,
  QuestionHeader,
  QuestionSubtitle,
  QuestionTitle,
  TooltipContainer,
} from '../common';
import { CheckboxProps } from './interfaces';

const Checkbox: React.FC<CheckboxProps> = ({
  question,
  errors,
  initialValues,
  onChange,
  viewOnly = false,
}) => {
  const options = question.options.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const onSubumit = (selected: Option[] | Option) => {
    if (!selected) return onChange([]);

    return Array.isArray(selected)
      ? onChange(selected.map(({ value }) => value))
      : onChange(selected.value);
  };

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionTitle>{question.name}</QuestionTitle>
        {question.tipText && (
          <Tooltip description={question.tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      <Dropdown
        isMulti
        disabled={viewOnly}
        options={options}
        hasError={!!errors}
        onChange={onSubumit}
        defaultValue={options.find(
          ({ value }) => value === initialValues?.values,
        )}
      />
      <QuestionSubtitle hasError={!!errors}>
        {errors && errors.errors}
      </QuestionSubtitle>
    </QuestionContainer>
  );
};

export default Checkbox;
