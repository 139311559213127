import { FiSearch } from 'react-icons/fi';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const InputContainer = styled.div`
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 220px;

  @media (max-width: 370px) {
    min-width: 0;
  }
`;

export const Input = styled.input`
  color: ${(props) => props.theme.colors.mediumText};
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  outline: none;
  font-family: ${(props) => props.theme.fonts.primaryFont};
  background-color: transparent;
  height: 30px;
  border: 1px solid #dddddd;
  border-radius: 50px;
  padding: 4px 8px;

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const SearchIcon = styled(FiSearch)`
  color: ${(props) => props.theme.colors.darkText};
  width: 14px;
  height: 14px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  margin-left: 2px;
`;

export const Label = styled.p`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  font-weight: 400;
  font-size: 12px;
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.darkText};
  white-space: nowrap;
`;
