import { ApolloWrapper } from '@/modules/graphql';
import { ReactQueryClientProvider } from '@/modules/reactQuery';
import AppActionSheet from '@/utils/AppActionSheet';
import AppModal from '@/utils/AppModal';
import ModalStack from '@/utils/ModalStack';
import React from 'react';

import ActionSheetProvider from './actionSheet';
import AppProvider from './AppProvider';
import { AuthenticationProvider } from './Authentication';
import { FilterProvider } from './filter';
import { LoadingProvider } from './loading';
import ModalProvider from './modal';
import { ModalManagerContextProvider } from './ModalManager';
import ModalStackProvider from './modalStack/modal';
import { NavigationProvider } from './navigation';
import { PresentationProvider } from './Presentation';
import { UserSettingsProvider } from './Settings/userSettings';
import { WorkspaceAwareThemeProvider } from './workspaceAwareTheme';

const Providers: React.FC = ({ children }) => {
  return (
    <ReactQueryClientProvider>
      <LoadingProvider>
        <WorkspaceAwareThemeProvider>
          <NavigationProvider>
            <FilterProvider>
              <AuthenticationProvider>
                <ApolloWrapper>
                  <UserSettingsProvider>
                    <ActionSheetProvider
                      ref={(ref) => AppActionSheet.setRef(ref)}
                    >
                      <ModalProvider ref={(ref) => AppModal.setRef(ref)}>
                        <ModalStackProvider
                          ref={(ref) => ModalStack.setRef(ref)}
                        >
                          <ModalManagerContextProvider>
                            <AppProvider>
                              <PresentationProvider>
                                {children}
                              </PresentationProvider>
                            </AppProvider>
                          </ModalManagerContextProvider>
                        </ModalStackProvider>
                      </ModalProvider>
                    </ActionSheetProvider>
                  </UserSettingsProvider>
                </ApolloWrapper>
              </AuthenticationProvider>
            </FilterProvider>
          </NavigationProvider>
        </WorkspaceAwareThemeProvider>
      </LoadingProvider>
    </ReactQueryClientProvider>
  );
};

export default Providers;
