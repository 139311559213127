import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionItem,
  ContextMenuContainer,
  SectionTitle,
} from '@/components_v2/ContextMenu';
import { IconDown, IconUp, IconCheck } from '@/components_v2/ContextMenu/Icons';
import { FilterType } from '@/modules/queryfilter/domain/interfaces';
import ClearFilter from './components/ClearFilter';
import MultiSelectionFilter from './components/MultiSelectionFilter';
import DateFilter from './components/DateFilter';
import { FilterContextMenuRenderProps } from './interfaces';

const FilterContextMenu: React.FC<FilterContextMenuRenderProps> = ({
  filter,
  onClear,
  onOrder,
  order,
  noOrder,
}) => {
  const { t } = useTranslation();

  const renderFilterType = useCallback(() => {
    switch (filter.type) {
      case FilterType.MULTI:
        return (
          <MultiSelectionFilter
            column={filter.column}
            table={filter.table}
            type="multi"
          />
        );
      case FilterType.SINGLE:
        return (
          <MultiSelectionFilter
            table={filter.table}
            column={filter.column}
            type="single"
          />
        );
      case FilterType.DATE:
        return (
          <DateFilter table={filter.table} column={filter.column} type="date" />
        );
      case FilterType.DATE_RANGE:
        return (
          <DateFilter
            table={filter.table}
            column={filter.column}
            type="date_range"
          />
        );
      default:
        return <></>;
    }
  }, [filter]);

  return (
    <ContextMenuContainer>
      {noOrder && (
        <>
          <SectionTitle isFirst>{t('filter.order').toUpperCase()}</SectionTitle>
          <ActionItem
            icon={order && order === 'asc' ? <IconCheck /> : <IconUp />}
            text={t('common.ascending_order')}
            onClick={() => onOrder(true)}
          />
          <ActionItem
            icon={order && order === 'desc' ? <IconCheck /> : <IconDown />}
            text={t('common.descending_order')}
            onClick={() => onOrder(false)}
          />
        </>
      )}
      <SectionTitle>{t('filter.filter_action').toUpperCase()}</SectionTitle>
      {renderFilterType()}
      <ClearFilter onClick={onClear} />
    </ContextMenuContainer>
  );
};

export default FilterContextMenu;
