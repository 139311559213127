import React from 'react';
import { Switch, RouteProps, useRouteMatch, Route } from 'react-router-dom';

import Reports from '@screens/Reports';

const SupportRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/`} component={Reports} />
    </Switch>
  );
};

export default SupportRoutes;
