import React, {
  useEffect,
  createContext,
  useContext,
  useCallback,
  useReducer,
} from 'react';

import { UserSettingsData, UserSettingsContextData } from './interfaces';

import { userSettingsReducer } from './data';

const UserSettingsContext = createContext<UserSettingsContextData>(
  {} as UserSettingsContextData,
);

export const UserSettingsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(userSettingsReducer, {});

  const getPreferences = useCallback(() => {
    return state;
  }, [state]);

  const setPreferences = useCallback((newData: UserSettingsData) => {
    dispatch({ props: newData, type: 'set' });
  }, []);

  const loadSettings = useCallback(() => {
    dispatch({ type: 'fetch_local' });
  }, []);

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  return (
    <UserSettingsContext.Provider
      value={{
        getPreferences,
        setPreferences,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export function useUserSettings(): UserSettingsContextData {
  const context = useContext(UserSettingsContext);

  if (!context) {
    throw new Error(
      'useUserSettings must be used whithin an UserSettingsProvider',
    );
  }

  return context;
}
