import React from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

const EventReportsRoute = React.lazy(
  () => import('@/screens/monitoring/EventReports/Route'),
);
const Feedbacks = React.lazy(
  () => import('@/screens/monitoring/Feedbacks/Feedbacks'),
);
const Checklists = React.lazy(
  () => import('@/screens/monitoring/Checklists/Checklists'),
);

const MonitoringRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/checklists`} component={Checklists} exact />

      <Route path={`${path}/event/:id`} component={EventReportsRoute} exact />
      <Route path={`${path}/feedbacks`} component={Feedbacks} exact />

      <Redirect to={`${path}/checklists`} />
    </Switch>
  );
};

export default MonitoringRoutes;
