type BadgeProps = {
  children: React.ReactNode;
  colorScheme?: 'green' | 'red' | 'orange' | 'purple' | 'gray' | 'blue';
  size?: 'xs' | 'sm' | 'lg';
};

export function Badge({
  children,
  colorScheme = 'gray',
  size = 'sm',
}: BadgeProps) {
  const sizeStyles = {
    xs: '0.75rem',
    sm: '0.875rem',
    lg: '1rem',
  };

  const colorSchemes = {
    green: {
      border: '#68d391',
      background: '#c6f6d5',
      text: '#2f855a',
    },
    red: {
      border: '#fc8181',
      background: '#fed7d7',
      text: '#c53030',
    },
    orange: {
      border: '#f6ad55',
      background: '#feebc8',
      text: '#975a16',
    },
    purple: {
      border: '#9f7aea',
      background: '#e9d8fd',
      text: '#6b46c1',
    },
    gray: {
      border: '#cbd5e0',
      background: '#edf2f7',
      text: '#4a5568',
    },
    blue: {
      border: '#4299e1',
      background: '#bee3f8',
      text: '#2b6cb0',
    },
  };

  return (
    <span
      style={{
        marginRight: '0.5rem',
        borderRadius: '0.375rem',
        border: `1px solid ${colorSchemes[colorScheme].border}`,
        backgroundColor: colorSchemes[colorScheme].background,
        padding: '0.25rem 0.625rem',
        fontSize: sizeStyles[size],
        fontWeight: 500,
        color: colorSchemes[colorScheme].text,
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </span>
  );
}
