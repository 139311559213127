import constants from '@/Constants';
import { useLocation, useParams } from 'react-router-dom';

import { ReportDefault } from './ReportDefault';
import { ReportFormularyQuestion } from './ReportFormularyQuestion';
import { ReportGencau } from './ReportGencau';
import { ReportMichelinfleet } from './ReportMichelinfleet';
import { ReportNovorumo } from './ReportNovorumo';
import { ReportRrembalagens } from './ReportRrembalagens';
import { ReportSESRJ } from './ReportSesrj';
import { ReportSESRJDashboard } from './ReportSesrjDashboard';
import { ReportTopico } from './ReportTopico';

export const PdfReport: React.FC = () => {
  const { reportId, workspaceSlug, token } = useParams<{
    reportId: string;
    workspaceSlug: string;
    token: string;
  }>();

  const { pathname } = useLocation();

  if (token !== constants.REPORTS_FUNCTION_AUTHORIZATION_TOKEN)
    return <div>Não Autorizado</div>;

  if (pathname.includes('formulary_questions')) {
    return (
      <ReportFormularyQuestion
        reportId={reportId}
        workspaceSlug={workspaceSlug}
      />
    );
  }

  switch (workspaceSlug) {
    case 'gencau': {
      return <ReportGencau reportId={reportId} workspaceSlug={workspaceSlug} />;
    }

    case 'michelinfleet': {
      return (
        <ReportMichelinfleet
          reportId={reportId}
          workspaceSlug={workspaceSlug}
        />
      );
    }

    case 'novorumo': {
      return (
        <ReportNovorumo reportId={reportId} workspaceSlug={workspaceSlug} />
      );
    }

    case 'rrembalagens': {
      return (
        <ReportRrembalagens reportId={reportId} workspaceSlug={workspaceSlug} />
      );
    }

    case 'sesrj': {
      return <ReportSESRJ reportId={reportId} workspaceSlug={workspaceSlug} />;
    }

    case 'pdf_dashboard_sesrj': {
      return (
        <ReportSESRJDashboard
          reportId={reportId}
          workspaceSlug={workspaceSlug}
        />
      );
    }

    case 'topico': {
      return <ReportTopico reportId={reportId} workspaceSlug={workspaceSlug} />;
    }

    default: {
      return (
        <ReportDefault reportId={reportId} workspaceSlug={workspaceSlug} />
      );
    }
  }
};
