import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import config from '@/Constants';

async function markAsReadService(id: string): Promise<void> {
  AxiosHelper.patch({
    url: `${config.NOTIFICATION_USERS_URL}/${id}`,
    body: {
      read: true,
    },
  });
}

export { markAsReadService };
