import { gql } from '@/gql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

import { queryAutomationTickets } from './useFetchTickets';

const deleteDeleteAutomationTicketInBatch = gql(`#graphql
  mutation DeleteAutomationTicketInBatch($input: TicketsDeleteInBatchInput!) {
    deleteAutomationTicketInBatch(input: $input) {
      errors
      clientMutationId
      tickets {
        id
      }
    }
  }
`);

export function useDeleteAutomationTickets() {
  const toast = useToast();
  const [deleteAutoTicketsInBatch, { data, loading, error }] = useMutation(
    deleteDeleteAutomationTicketInBatch,
    {
      refetchQueries: [queryAutomationTickets],
    },
  );

  const handleDeleteAutoTickets = useCallback(
    async (payload) => {
      const { data, errors } = await deleteAutoTicketsInBatch({
        variables: {
          input: { ids: payload },
        },
      });

      if (data || errors) {
        toast({
          title:
            errors || !!data?.deleteAutomationTicketInBatch?.errors.length
              ? 'Falha na remoção do(s) ticket(s)'
              : 'Ticket(s) deletado(s).',
          status:
            errors || !!data?.deleteAutomationTicketInBatch?.errors.length
              ? 'error'
              : 'success',
        });
      }
    },
    [deleteAutoTicketsInBatch, toast],
  );

  return {
    deleteAutoTicketsInBatch: handleDeleteAutoTickets,
    loading,
    error,
    data: data?.deleteAutomationTicketInBatch || null,
  };
}
