import { FilterType, SelectedFilters } from '@queryfilter/domain/interfaces';

export default function serializeFilters(data: SelectedFilters): string {
  const { table, filters } = data;
  let filterString = '';

  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i];
    const baseQuery = `&[filter][${table}][${filter.column}]`;
    if (filter.selected !== null) {
      switch (filter.type) {
        case FilterType.DATE_RANGE:
          filterString += `${baseQuery}[date_start]=${filter.selected.dateStart}`;
          filterString += `${baseQuery}[date_end]=${filter.selected.dateEnd}`;
          break;
        case FilterType.DATE:
          filterString += `${baseQuery}[date_start]=${filter.selected}`;
          filterString += `${baseQuery}[date_end]=${filter.selected}`;
          break;
        case FilterType.MULTI:
          for (let i = 0; i < filter.selected.length; i++) {
            filterString += `${baseQuery}[]=${encodeURIComponent(
              filter.selected[i],
            )}`;
          }
          break;
        default:
          filterString += `${baseQuery}=${encodeURIComponent(
            String(filter.selected),
          )}`;
          break;
      }
    }
  }

  return filterString;
}
