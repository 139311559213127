import { FeedbackSuccess } from '@/components/Feedback';
import { useModals } from '@/hooks/ModalManager';
import React from 'react';

import { updateItem as updateRemoteItem } from '../services/updateItem';
import { PartialAnswer } from '../types';

export default function useUpdateItem() {
  const modals = useModals();
  const [isLoading, setIsLoading] = React.useState(false);
  const updateItem = React.useCallback(
    async (itemId: string, answers: PartialAnswer[]) => {
      setIsLoading(true);

      try {
        const result = await updateRemoteItem(itemId, answers);
        if (result) {
          FeedbackSuccess({
            mainText: 'As respostas foram salvas com sucesso.',
          });
        }
      } catch (e) {
        modals.alert({
          title: 'Ocorreu um erro inesperado.',
          body: 'Tente novamente.',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [modals],
  );

  return {
    isLoading,
    updateItem,
  };
}
