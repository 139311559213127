interface FlattenedObject {
  [key: string]: any;
}

const flattenObject = (obj: any): FlattenedObject => {
  const flattened: FlattenedObject = {};

  Object.keys(obj).forEach((key) => {
    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      !Array.isArray(obj[key]) &&
      !(obj[key] instanceof Date)
    ) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
};

export default flattenObject;
