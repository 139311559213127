/* eslint-disable @typescript-eslint/no-empty-function, no-case-declarations */
import React, {
  createContext,
  useContext,
  useCallback,
  useReducer,
  useImperativeHandle,
  forwardRef,
} from 'react';

import {
  ModalProps,
  ModalStackReducer,
  ModalStackContextData,
  ModalStackState,
  PushModalProps,
  ModalStackRefProps,
} from './interfaces';

const ModalStackContext = createContext<ModalStackContextData>(
  {} as ModalStackContextData,
);

const initialState: ModalStackState = { stack: [] };

const reducer: ModalStackReducer = (state, action) => {
  switch (action.type) {
    case 'push':
      const zIndex = 100 + state.stack.length;
      const actionSheet: ModalProps = {
        ...action.props,
        zIndex,
      };
      return { stack: [...state.stack, actionSheet] };
    case 'pop':
      if (state.stack.length === 0) return state;
      const last = state.stack[state.stack.length - 1];

      if (last.onClose) last.onClose();
      return { stack: state.stack.slice(0, -1) };
    case 'close':
      const indexDestroy = state.stack.findIndex(
        (item) => item.key === action.key,
      );
      if (indexDestroy >= 0) {
        const item = state.stack[indexDestroy];
        if (item.onClose) item.onClose();
        return {
          stack: [
            ...state.stack.slice(0, indexDestroy),
            ...state.stack.slice(indexDestroy + 1),
          ],
        };
      }
      return state;
    default:
      return state;
  }
};

const ModalStackProvider: React.ForwardRefRenderFunction<
  ModalStackRefProps,
  { children: React.ReactNode }
> = ({ children }, selfRef) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const pop = useCallback(() => {
    dispatch({ type: 'pop' });
  }, []);

  const push = useCallback((data: PushModalProps) => {
    const dataWithKey: PushModalProps = {
      ...data,
      key: data.key || `${new Date().getTime()}`,
    };
    dispatch({ type: 'push', props: dataWithKey });
  }, []);

  const closeModal = useCallback((key: string) => {
    dispatch({ type: 'close', key });
  }, []);

  useImperativeHandle(
    selfRef,
    () => ({
      closeModal,
      push,
      pop,
    }),
    [closeModal, push, pop],
  );

  return (
    <ModalStackContext.Provider
      value={{
        push,
        pop,
        closeModal,
        state,
      }}
    >
      {children}
    </ModalStackContext.Provider>
  );
};

export function useModalStack(): ModalStackContextData {
  const context = useContext(ModalStackContext);

  if (!context) {
    throw new Error('useModalStack must be used whithin an ModalStackProvider');
  }

  return context;
}

export default forwardRef(ModalStackProvider);
