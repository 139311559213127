import { orderBy } from 'lodash';
import { WorkflowAttribute } from '../../types/Attribute';
import {
  CreateWorkflowEventDto,
  WorkflowEventAttributeDto,
} from '../../types/WorkflowEvent';
import { IAttributeField, IFormSchema, IFieldValue } from './types';

const FIELD_TYPES = {
  string: 'input',
  paragraph: 'textarea',
  date: 'datepicker',
  location: 'geolocation',
  picture: 'file',
  attachment: 'input',
  checkbox: 'react-select',
  user: 'user-picker',
  formulary: 'inspection',
};

export const getFieldProps = (attribute: WorkflowAttribute) => {
  let defaults: IAttributeField = {
    id: attribute.id,
    label: attribute.label,
    type: FIELD_TYPES[attribute.attType],
    attType: attribute.attType,
    rules: {
      required: attribute.isDefault ? 'Campo obrigatório' : false,
    },
  };
  switch (attribute.attType) {
    case 'date':
      defaults = { ...defaults, showTimeSelect: true };
      break;
    case 'attachment':
      defaults = { ...defaults, acceptMode: 'files' };
      break;
    case 'picture':
      defaults = { ...defaults, acceptMode: 'images' };
      break;
    case 'checkbox':
      defaults = {
        ...defaults,
        options: attribute.attOptionsAttributes?.map((e) => ({
          label: e.label,
          value: e.id,
          colorScheme: e.color,
        })),
      };
      break;
    default:
      break;
  }

  return defaults;
};

export const getFormSchema = (params: {
  attributes?: WorkflowAttribute[];
  formulary?: {
    formularyName: string;
    formularyId: string;
  };
}): IFormSchema => {
  const { attributes, formulary } = params;
  if (!attributes && !formulary) return {};

  const filteredOut =
    attributes?.filter(
      (e) =>
        e.id !== 'status' &&
        e.id !== 'last_modified_at' &&
        e.id !== 'created_at',
    ) || [];

  const sortedFields = orderBy(filteredOut, 'position', 'asc');

  let parsed = sortedFields.reduce((prev, curr) => {
    const { id } = curr;

    return {
      ...prev,
      [id]: getFieldProps(curr),
    };
  }, {});

  if (formulary) {
    parsed = {
      ...parsed,
      inspection: {
        id: 'inspection',
        label: 'Checklist',
        type: FIELD_TYPES.formulary,
        attType: 'inspection',
        ...formulary,
        rules: {
          required: 'Campo obrigatório',
        },
      },
    };
  }

  return parsed;
};

export const serializeValues = (
  formSchema: IFormSchema,
  values: Record<string, any>,
): CreateWorkflowEventDto['atts'] => {
  const atts: CreateWorkflowEventDto['atts'] = Object.entries(values).reduce(
    (prev, curr) => {
      const [key, value] = curr;
      if (!value) return prev;

      const { attType, id: workflowAttributeId } = formSchema[key];

      const fieldValue = { value, type: attType } as IFieldValue;

      let parsed = {};

      switch (fieldValue.type) {
        case 'string':
        case 'paragraph':
        case 'date':
        case 'location':
          parsed = {
            value: fieldValue.value,
          };
          break;
        case 'checkbox':
          parsed = {
            value: fieldValue.value.map((e) => e.value),
            workflowAttOptionIds: fieldValue.value.map((e) => e.value),
          };
          break;
        case 'attachment':
        case 'picture':
          parsed = {
            files: fieldValue.value,
          };
          break;
        case 'user':
          parsed = {
            value: fieldValue?.value?.value,
          };
          break;
        default:
          parsed = {
            value,
          };
      }

      return [
        ...prev,
        {
          ...parsed,
          attType: fieldValue.type,
          workflowAttributeId,
        } as WorkflowEventAttributeDto,
      ];
    },
    [] as WorkflowEventAttributeDto[],
  );

  return atts;
};
