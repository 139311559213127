import { FlaggedAnswersConnectionFragmentFragment } from '@/gql/graphql';
import { EventParentableType } from '@/modules/core/domain/interfaces/IFormulary';
import { QuestionContent } from '@/screens/monitoring/EventReports/components/Question/QuestionContent/QuestionContent';
import { openInNewTab } from '@/utils/openInNewTab';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Badge, Flex, HStack, IconButton, Tag, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

type JustificationQuestionPreviewProps = {
  item: FlaggedAnswersConnectionFragmentFragment;
};

const GetTypeTagColor = (type: EventParentableType) => {
  switch (type) {
    case 'Task':
      return '#043C73';
    case 'Schedule':
      return '#AB697B';

    default:
      return 'gray';
  }
};

const Details: React.FC<JustificationQuestionPreviewProps> = ({ item }) => {
  const { question, eventId, updatedAt, facility } = item;
  const { t } = useTranslation();

  const formularyName = question.formulary?.name;
  const eventParentableType = item.event.parentableType;

  const openExternal = React.useCallback(() => {
    openInNewTab(`${window.location.origin}/external/event/${eventId}`);
  }, [eventId]);

  return (
    <Flex
      w="full"
      flexDir="row"
      justify="space-between"
      alignItems="flex-start"
    >
      <Flex w="full" flexDir="column" gap="2">
        <HStack alignItems="flex-start" justify="space-between">
          <Text
            wordBreak="break-all"
            color="gray.400"
            fontWeight="bold"
            fontSize="sm"
          >
            {`${formularyName} / ${question?.section?.name} / ${moment(
              item.event.finishedAt * 1000,
            ).format('DD-MM-YYYY')}`}
          </Text>
          <Flex
            alignItems={{ base: 'flex-end', md: 'center' }}
            flexDir={{ base: 'column', md: 'row' }}
            gap="2"
          >
            <Badge
              w="min-content"
              variant="outline"
              colorScheme="orange"
              mr={5}
            >
              Sinalizado como inconforme
            </Badge>
          </Flex>
        </HStack>

        <Text
          wordBreak="break-all"
          color="gray.400"
          fontWeight="bold"
          fontSize="sm"
        >
          UNIDADE: {facility}
        </Text>

        <Text
          wordBreak={{ base: 'break-all', md: 'break-word' }}
          color="darkText"
          fontWeight="bold"
          fontSize="15px"
          lineHeight={1}
        >
          {question.name}
        </Text>
      </Flex>
      <HStack spacing={15}>
        <Tag
          size="lg"
          variant="solid"
          bgColor={GetTypeTagColor(eventParentableType)}
          height="12"
        >
          {t(`feedbacks.${eventParentableType}`)}
        </Tag>
        <IconButton
          size="lg"
          aria-label="open"
          icon={<ExternalLinkIcon />}
          onClick={openExternal}
        />
      </HStack>
    </Flex>
  );
};

const JustificationQuestionPreview: React.FC<JustificationQuestionPreviewProps> = (
  props,
) => {
  const { item } = props;
  if (!item?.question) return null;
  return (
    <Flex
      overflow="clip"
      boxShadow="initial"
      flexDir="column"
      borderWidth="thin"
      w="full"
      rounded="xl"
      bg="white"
    >
      <Flex p="3.5" gap={4} flexDir="column">
        <Details item={item} />
        <Flex w="full">
          <QuestionContent
            question={{ ...item.question!, answers: item.flaggedAnswers }}
          />
        </Flex>
        <Flex flexDir="column" gap="1" hidden={!item.comment}>
          <Text fontWeight="bold">Notas:</Text>
          <HStack>
            <Flex
              p="2"
              textAlign="start"
              minH="10"
              rounded="lg"
              w="full"
              bg="gray.100"
              overflow="auto"
            >
              {item.comment}
            </Flex>
          </HStack>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default JustificationQuestionPreview;
