import { WebForm } from '@/components_v2/WebForm';
import {
  InspectionProvider,
  useInspectionStoreApi,
} from '@/components_v2/WebForm/context/store';
import { useModals } from '@/hooks/ModalManager';
import { SubmitResult } from '@/modules/WebForm/interfaces';
import useUpdateItem from '@/screens/ItemEdit/hooks/useUpdateItem';
import { PartialAnswer } from '@/screens/ItemEdit/types';
import { CollectionItem } from '@/screens/ItemEdit/utils/fieldParsers';
import { getAllQuestions } from '@/screens/ItemEdit/utils/getAllQuestions';
import { serializeFromFieldsToAnswers } from '@/screens/ItemEdit/utils/serializeFromFieldsToAnswers';
import { Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

import { TaskWithEvent } from '../interfaces';
import Header from './components/Header';
import useTaskChecklist from './hooks/useTaskChecklist';

type TaskChecklistScreenProps = {
  task: TaskWithEvent;
  isSubmitting: boolean;
  onSubmit: (answers: PartialAnswer[]) => Promise<void>;
};

const serializerMap = {
  collections: CollectionItem,
};

const TaskChecklistScreen: React.FC<TaskChecklistScreenProps> = ({
  task,
  onSubmit,
  isSubmitting,
}) => {
  const { getState } = useInspectionStoreApi();
  const modals = useModals();
  const itemId = React.useMemo(() => task.event?.items?.[0]?.id, [
    task.event?.items,
  ]);

  const didSumit = React.useCallback(
    async (params: SubmitResult) => {
      if (!itemId) return;
      const { answers } = params;
      const allQuestions = getAllQuestions(getState().formulary);
      const serializedValues = serializeFromFieldsToAnswers(allQuestions, {
        answers,
        serializerMap,
      });
      onSubmit(serializedValues);
    },
    [getState, itemId, onSubmit],
  );

  const handleSubmit = React.useCallback(
    (params: SubmitResult) => {
      if (!params.isValid) {
        modals.alert({
          title: 'Este checklist contém respostas pendentes ou inválidas!',
          body: 'Preencha todos os campos para continuar.',
          closeOnConfirm: true,
        });
      } else {
        modals.confirm({
          title: 'Deseja aplicar todas as mudanças?',
          body: 'Esta ação é irreversível.',
          confirmLabel: 'Sim',
          cancelLabel: 'Continuar editando',
          onConfirm: async () => {
            await didSumit(params);
          },
        });
      }
      //
    },
    [didSumit, modals],
  );

  return (
    <>
      {isSubmitting && (
        <Flex
          position="absolute"
          top="0"
          zIndex="overlay"
          alignItems="center"
          justifyContent="center"
          w="full"
          bg="blackAlpha.300"
          h="full"
          flex="1"
        >
          <Spinner />
        </Flex>
      )}
      <WebForm
        renderHeader={() => (
          <Header
            formularyName={task.formulary_name}
            taskName={task.name}
            taskStatus={task.status}
          />
        )}
        onSubmit={handleSubmit}
        formularyId={`${task.formulary_id}`}
        itemId={`${itemId}`}
      />
    </>
  );
};

export const Wrapper: React.FC<{
  taskId: string;
  onSubmit: () => void;
}> = ({ taskId, onSubmit }) => {
  const modals = useModals();
  const {
    isValidating,
    task,
    finishChecklist,
    isSubmitting,
  } = useTaskChecklist(taskId);

  const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = 'prevent_leave';
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const itemId = React.useMemo(() => task?.event?.items?.[0]?.id, [
    task?.event?.items,
  ]);

  const { isLoading: updatingItem, updateItem } = useUpdateItem();

  const handleSubmit = React.useCallback(
    async (answers: PartialAnswer[]) => {
      if (!itemId) return;
      await updateItem(itemId, answers);
      const result = await finishChecklist();
      onSubmit();
      if (result) {
        modals.close('task-checklist');
      }
    },
    [finishChecklist, itemId, modals, onSubmit, updateItem],
  );

  if (isValidating || !task || !task.formulary_id) return null;

  return (
    <InspectionProvider formularyId={task?.formulary_id}>
      <TaskChecklistScreen
        task={task}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting || updatingItem}
      />
    </InspectionProvider>
  );
};

export default Wrapper;
