import { exitFullScreenMode, fullScreenMode } from '@/utils/toggleScreenMode';
import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  PresentationContextData,
  PresentationProviderProps,
} from './interfaces/provider';

const PresentationContext = createContext<PresentationContextData>(
  {} as PresentationContextData,
);

export function PresentationProvider({ children }: PresentationProviderProps) {
  const [isPresenting, setIsPresenting] = useState(false);

  function startPresentation() {
    fullScreenMode();
    setIsPresenting(true);
  }

  function stopPresentation() {
    exitFullScreenMode();
    setIsPresenting(false);
  }

  return (
    <PresentationContext.Provider
      value={{ isPresenting, startPresentation, stopPresentation }}
    >
      {children}
    </PresentationContext.Provider>
  );
}

export function usePresentation() {
  const context = useContext(PresentationContext);

  return context;
}
