/* eslint-disable no-underscore-dangle */
import {
  ModalStackRefProps,
  PushModalProps,
} from '@/hooks/modalStack/interfaces';

export default class ModalStack {
  static _ref: ModalStackRefProps;

  static setRef(ref: ModalStackRefProps | null): void {
    if (ref) {
      ModalStack._ref = ref;
    }
  }

  static getRef(): ModalStackRefProps {
    return ModalStack._ref;
  }

  static push(props: PushModalProps): void {
    ModalStack._ref.push(props);
  }

  static pop(): void {
    ModalStack._ref.pop();
  }

  static closeModal(key: string): void {
    ModalStack._ref.closeModal(key);
  }
}
