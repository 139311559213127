import React, { useMemo } from 'react';

import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { ActionitemWithPermission } from './interfaces';
import ActionItem from '../ActionItem';

const ActionItemWithPermission: React.FC<ActionitemWithPermission> = ({
  permissionNeeded = 'rw',
  ...rest
}) => {
  const { checkPermission } = useUserPermissions();

  const hasPermission = useMemo(() => {
    return checkPermission(permissionNeeded);
  }, [permissionNeeded, checkPermission]);

  return <ActionItem {...rest} disabled={!hasPermission} />;
};

export default ActionItemWithPermission;
