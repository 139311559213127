import styled from 'styled-components';

export const Container = styled.div`
  margin-top: auto;
  bottom: 0;
  width: 100%;
  padding: 0px 20px 20px 20px;
  display: flex;
  justify-content: flex-end;
`;
