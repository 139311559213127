import { createContext } from '@utils/createContext';

import type { FormReportData, QuestionData, SectionData } from '../types';

export const [ReportContext, useReport] = createContext<FormReportData>();

export const [QuestionContext, useQuestion] = createContext<QuestionData>();

export const [ItemContext, useItem] = createContext<SectionData[]>();

export const [SectionContext, useSection] = createContext<SectionData>();
