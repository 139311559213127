import { Combobox } from '@/components_v2/Combobox/Combobox';
import { InputDiv } from '@/components_v2/Questions/type/common/styles';
import reduceArrayToListText from '@/utils/Text/reduceArrayToListText';
import { Box, Button, Checkbox } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Div } from '../BaseInput/styles';
import { PopoverRemoteSelectProps } from './interfaces';
import { useOptionsQuery } from './utils/useOptionsQuery';

const SettingTab: React.FC<{
  selected: Option[];
  onSelect: (item: Option) => void;
  url: string;
}> = (props) => {
  const { onSelect, selected, url } = props;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const query = useOptionsQuery([url, `&search=${searchTerm}`]);

  const parsedItems = React.useMemo(
    () => (query.data?.items || query.data || []) as Option[],
    [query.data],
  );

  const renderItem = React.useCallback(
    (item: Option) => {
      const isSelected = selected.findIndex((e) => e.id === item.id) !== -1;

      const handleSelect = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        onSelect(item);
      };

      return (
        <Combobox.ListItem
          onClick={handleSelect}
          leftContent={
            <Checkbox
              size="lg"
              isChecked={isSelected}
              onChange={handleSelect}
            />
          }
        >
          {item.name}
        </Combobox.ListItem>
      );
    },
    [onSelect, selected],
  );

  const renderItems = React.useCallback(
    (mode: 'all' | 'selected' = 'all') => {
      if (mode === 'selected') {
        return selected?.map(renderItem);
      }
      return parsedItems
        ?.filter(
          (element) => selected.findIndex((e) => e.id === element.id) === -1,
        )
        .map(renderItem);
    },
    [parsedItems, renderItem, selected],
  );

  return (
    <>
      <Combobox.Search onChange={(e) => setSearchTerm(e.target.value)} />
      {!!selected.length && (
        <Combobox.ListSection label="Selecionados">
          {renderItems('selected')}
        </Combobox.ListSection>
      )}
      {!!parsedItems?.length && (
        <Combobox.ListSection label="Todos">
          {renderItems()}
        </Combobox.ListSection>
      )}
    </>
  );
};

const PopoverRemoteSelect: React.FC<PopoverRemoteSelectProps> = ({
  onChange,
  initialValues,
  remoteUrl,
}) => {
  const [data, setData] = useState<Option[]>(initialValues || []);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (option: Option) => {
      let currentCollection = data;

      if (currentCollection.findIndex((e) => e.id === option.id) === -1) {
        currentCollection = [...currentCollection, option];
      } else {
        currentCollection = currentCollection.filter((e) => e.id !== option.id);
      }

      const newState = [...currentCollection];
      setData(newState);

      onChange(newState.map((e) => e.id));
    },
    [data, onChange],
  );

  const formattedText = useMemo(() => {
    return data.length !== 0
      ? reduceArrayToListText({
          arr: [...data.map((option) => option.name)],
        })
      : t('common.select_value');
  }, [data, t]);

  return (
    <Combobox.Parent
      TriggerComponent={
        <InputDiv>
          <Div>{formattedText}</Div>
        </InputDiv>
      }
      renderFooter={(onClose) => (
        <Box>
          <Button float="right" colorScheme="brand" onClick={onClose}>
            Confirmar
          </Button>
        </Box>
      )}
    >
      <SettingTab
        url={remoteUrl}
        selected={data}
        onSelect={(item) => handleChange(item)}
      />
    </Combobox.Parent>
  );
};

export default PopoverRemoteSelect;
