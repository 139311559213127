/* eslint-disable react/destructuring-assignment */
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useAutomationFlow } from '@/screens/Tickets/context/automationFlow';
import { CustomIcons } from '@/screens/Tickets/utils/customIcons';
import { Checkbox, Grid, IconButton, MenuButton, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StepActionsMenu } from '../StepActionsMenu';
import { GI } from './TicketsList.components';

const TicketsListHeader = ({
  templateColumns,
}: {
  templateColumns: string;
}) => {
  const {
    tickets,
    orderedProperties,
    orderedSteps,
    hideProperties,
  } = useAutomationFlow();
  const { checkPermission } = useUserPermissions();
  const { t } = useTranslation();
  const { selected, onSelect, onDeselect } = useAutomationFlow();

  const handleCheckbox = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        onSelect('all');
      } else {
        onDeselect('all');
      }
      e.stopPropagation();
    },
    [onSelect, onDeselect],
  );

  return (
    <Grid
      w="100%"
      minW="fit-content"
      px={6}
      py={2}
      minH="16"
      bg="white"
      borderBottom="1px solid #E2E8F0"
      templateColumns={templateColumns}
      mb={0}
      position="sticky"
      top={0}
      key="header-tickets"
    >
      <GI justifyContent="flex-start">
        <Checkbox
          size="lg"
          isChecked={
            selected?.length === tickets?.length && selected?.length !== 0
          }
          onChange={handleCheckbox}
        />
      </GI>
      <GI justifyContent="flex-start">
        <Text variant="ticket_header" fontSize="sm" fontWeight="bold">
          TICKET
        </Text>
      </GI>
      <GI justifyContent="flex-start">
        <Text variant="ticket_header" fontSize="sm" fontWeight="bold">
          {t('tickets.responsible').toUpperCase()}
        </Text>
      </GI>
      <GI>
        <Text variant="status_header" fontSize="sm" fontWeight="bold">
          {t('tickets.status').toUpperCase()}
        </Text>
      </GI>
      {!hideProperties
        ? orderedProperties?.map(({ id, name }) => {
            return (
              <GI justifyContent="flex-start" key={id}>
                {name && (
                  <Text
                    w="full"
                    textAlign="center"
                    variant="prop_header"
                    fontSize="sm"
                    fontWeight="bold"
                  >
                    {t(name).toUpperCase()}
                  </Text>
                )}
              </GI>
            );
          })
        : null}
      <GI>
        <Text
          variant="step_header"
          textAlign="center"
          textOverflow="ellipsis"
          noOfLines={3}
          fontSize="sm"
          fontWeight="bold"
        >
          {t('tickets.opening').toUpperCase()}
        </Text>
      </GI>
      {orderedSteps?.map((step) => (
        <GI
          key={step.id}
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          role="group"
          position="relative"
          textOverflow="ellipsis"
        >
          {step.name && (
            <Text
              variant="step_header"
              textAlign="center"
              textOverflow="ellipsis"
              noOfLines={3}
              fontSize="sm"
              fontWeight="bold"
            >
              {t(step.name).toUpperCase()}
            </Text>
          )}
          {checkPermission('admin') && (
            <StepActionsMenu step={step}>
              <MenuButton
                as={IconButton}
                position="absolute"
                right={0}
                p="0"
                bg="white"
                opacity={0}
                _groupHover={{ opacity: 1 }}
                _active={{ opacity: 1 }}
                transition="all 0.3s"
              >
                <CustomIcons.DotsHorizontal fontSize="3xl" />
              </MenuButton>
            </StepActionsMenu>
          )}
        </GI>
      ))}
      <GI>
        <Text
          variant="step_header"
          textAlign="center"
          textOverflow="ellipsis"
          noOfLines={3}
          fontSize="sm"
          fontWeight="bold"
        >
          DOWNLOAD
        </Text>
      </GI>
    </Grid>
  );
};

export default TicketsListHeader;
