import React from 'react';
import {
  Stat,
  StatLabel,
  StatNumber,
  HStack,
  StatHelpText,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { StatType, StatData } from './Stats.types';
import { typeColorMap } from './Stats.data';

type StatsItemProps = {
  type: StatType;
  data: StatData;
};

const StatsItem: React.FC<StatsItemProps> = ({ type, data }) => {
  const { t } = useTranslation();

  return (
    <HStack h="100%" alignItems="center">
      <Box mr={2} h="100%" w="4px" bg={typeColorMap[type]} borderRadius="5px" />
      <Stat py={0.5}>
        <StatLabel fontSize="md" fontWeight="medium" color="gray.700">
          {t(`tasks.stats.${type}`)}
        </StatLabel>
        <StatNumber>{data.value?.toLocaleString()}</StatNumber>
        {data.description && (
          <StatHelpText mb={0} mt={-1} fontSize="md" color="gray.700">
            {data.description}
          </StatHelpText>
        )}
      </Stat>
    </HStack>
  );
};

export default StatsItem;
