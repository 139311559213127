/* eslint-disable no-underscore-dangle */
import {
  BaseField,
  DisplayIf,
  Field,
  FormLayout,
  HookForm,
} from '@/components_v2/HookForm';
import { QuestionTypes } from '@/gql/graphql';
import { useModals } from '@/hooks/ModalManager';
import { Button, chakra, Divider } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  AutomationPropertySelect,
  QuestionSelect,
  UserSelect,
} from '../../Selects';
import { actionSchema, ActionSchema } from '../form-schemas';
import { Callout } from '../misc';

const actionableTypes = [
  { value: '', label: 'Selecione' },
  { value: 'email_checklist', label: 'Enviar email' },
  { value: 'email_user', label: 'Enviar email para usuário' },
  { value: 'webhook', label: 'WebHook' },
  { value: 'update_property', label: 'Atualizar atributo do ticket' },
];

function Fields({ automationFlowId }: { automationFlowId?: string }) {
  const form = useFormContext<ActionSchema>();

  return (
    <FormLayout padding={1} spacing={5} flexGrow={0} w="full" overflow="hidden">
      <Field<ActionSchema>
        name="actionableType"
        label="Tipo de ativação"
        type="native-select"
        defaultValue=""
        options={actionableTypes}
      />
      <DisplayIf<ActionSchema>
        name="actionableType"
        condition={(value) => !!value}
      >
        <Field<ActionSchema>
          name="label"
          label="Etiqueta"
          type="text"
          placeholder="Digite..."
          help="Use a etiqueta para identificar esta ação"
        />
      </DisplayIf>

      <chakra.div>
        <DisplayIf<ActionSchema>
          name="actionableType"
          condition={(value) => value === 'email_checklist'}
        >
          <FormLayout
            padding={1}
            spacing={5}
            flexGrow={0}
            w="full"
            overflow="hidden"
          >
            <Divider />
            <Callout>
              <chakra.p fontSize="xl" fontWeight="bold">
                Como usar:
              </chakra.p>
              Um <strong>e-mail</strong> será enviado para o{' '}
              <strong>
                endereço encontrado na pergunta que você configurar a busca
              </strong>
              . Selecione abaixo a pergunta que deverá conter o valor esperado.
            </Callout>
            <Controller
              name="emailAttribute"
              control={form.control}
              shouldUnregister
              render={({ field }) => (
                <BaseField
                  name="triggerable"
                  label="Buscar e-mail na pergunta:"
                  flexGrow={0}
                >
                  <QuestionSelect
                    selectionMode="single"
                    questionTypes={[
                      QuestionTypes.Text,
                      QuestionTypes.Radio,
                      QuestionTypes.RadioCombo,
                    ]}
                    value={field.value?.map((e) => e?.id) as any}
                    initialOptions={(field.value || []) as any}
                    onChange={(keys) => {
                      field.onChange(keys);
                    }}
                  />
                </BaseField>
              )}
            />
            <Field<ActionSchema>
              name="sendChecklistOpenFlow"
              label="Anexar o checklist que iniciou esta etapa"
              type="switch"
              defaultValue=""
              options={actionableTypes}
            />
          </FormLayout>
        </DisplayIf>

        <DisplayIf<ActionSchema>
          name="actionableType"
          condition={(value) => value === 'email_user'}
        >
          <FormLayout
            padding={1}
            spacing={5}
            flexGrow={0}
            w="full"
            overflow="hidden"
          >
            <Controller
              name="user"
              control={form.control}
              shouldUnregister
              render={({ field }) => (
                <BaseField
                  name="triggerable"
                  label="Selecionar e-mail do usuário:"
                  flexGrow={0}
                >
                  <UserSelect
                    selectionMode="single"
                    value={field.value?.map((e) => e?.id) as any}
                    initialOptions={(field.value || []) as any}
                    onChange={(keys) => {
                      field.onChange(keys);
                    }}
                  />
                </BaseField>
              )}
            />
            <Field<ActionSchema>
              name="sendChecklistOpenFlow"
              label="Anexar o checklist que iniciou esta etapa"
              type="switch"
              defaultValue=""
              options={actionableTypes}
            />
          </FormLayout>
        </DisplayIf>

        <DisplayIf<ActionSchema>
          name="actionableType"
          condition={(value) => value === 'webhook'}
        >
          <FormLayout
            padding={1}
            spacing={5}
            flexGrow={0}
            w="full"
            overflow="hidden"
          >
            <Divider />
            <Callout>
              <chakra.p fontSize="xl" fontWeight="bold">
                Webhooks?
              </chakra.p>
              Um <strong>endpoint</strong> (POST) deverá ser notificado quando o{' '}
              <strong>ticket</strong> alcançar esta <strong>etapa</strong>. Os
              dados do ticket e do{' '}
              <strong>checklist que ativou esta etapa</strong> serão enviados no
              corpo da requisição.
            </Callout>
            <Field<ActionSchema>
              help="Digite a URL do endpoint que deverá ser notificado."
              name="webhookUrl"
              label="URL do WebHook (Post)"
              type="text"
            />
          </FormLayout>
        </DisplayIf>

        <DisplayIf<ActionSchema>
          name="actionableType"
          condition={(value) => value === 'update_property'}
        >
          <FormLayout spacing={5} flexGrow={0} w="full" overflow="hidden">
            <Divider />
            <Callout>
              <chakra.p fontSize="xl" fontWeight="bold">
                Como usar:
              </chakra.p>
              Você pode utilizar a ação de{' '}
              <strong>{`"${'atualizar propriedade do ticket'}"`}</strong> para
              atualizar uma propriedade do ticket com um valor{' '}
              <strong>herdado</strong> a partir de uma resposta à uma{' '}
              <strong>pergunta</strong>
            </Callout>

            <Controller
              name="attribute"
              control={form.control}
              shouldUnregister
              render={({ field }) => (
                <BaseField
                  name="attribute"
                  label="Atualizar propriedade do ticket:"
                  flexGrow={0}
                >
                  <AutomationPropertySelect
                    selectionMode="single"
                    automationFlowId={automationFlowId || ''}
                    value={field.value?.map((e) => e!.id)}
                    initialOptions={(field.value || []) as any}
                    onChange={(keys) => {
                      field.onChange(keys?.length ? keys : undefined);
                    }}
                  />
                </BaseField>
              )}
            />
            <Controller
              name="question"
              control={form.control}
              shouldUnregister
              render={({ field }) => (
                <BaseField
                  name="question"
                  label="Herdar valor da pergunta:"
                  flexGrow={0}
                >
                  <QuestionSelect
                    selectionMode="single"
                    value={field.value?.map((e) => e?.id) as any}
                    initialOptions={(field.value || []) as any}
                    onChange={(keys) => {
                      field.onChange(keys);
                    }}
                  />
                </BaseField>
              )}
            />
          </FormLayout>
        </DisplayIf>
      </chakra.div>
    </FormLayout>
  );
}

export type ActionFormProps = {
  onSubmit?: (value: ActionSchema & { key?: string }) => void;
  intitialValues?: ActionSchema;
  automationFlowId?: string;
};

export function ActionForm({
  intitialValues,
  onSubmit,
  automationFlowId,
}: ActionFormProps) {
  return (
    <HookForm<ActionSchema>
      autoComplete="off"
      overflowY="auto"
      overflowX="clip"
      position="relative"
      p="4"
      display="grid"
      flexGrow={0}
      w="full"
      id="action-form"
      resolver={yupResolver(actionSchema)}
      schema={actionSchema}
      defaultValues={intitialValues}
      onSubmit={(values) => onSubmit?.(values as ActionSchema)}
    >
      <Fields automationFlowId={automationFlowId} />
    </HookForm>
  );
}

export function useActionForm({ onSubmit }: ActionFormProps) {
  const modals = useModals();

  const _onSubmit = React.useCallback(
    (value: ActionSchema) => {
      onSubmit?.(value);
      modals.close('action-form');
    },
    [modals, onSubmit],
  );

  return (params: {
    intitialValues?: ActionSchema;
    automationFlowId?: string;
  }) => {
    modals.open({
      id: 'action-form',
      title: 'Configurar Ações secundárias',
      scope: 'alert',
      body: <ActionForm onSubmit={_onSubmit} {...params} />,
      size: 'lg',
      closeOnEsc: false,
      closeOnOverlayClick: false,
      footer: (
        <Button
          type="submit"
          form="action-form"
          w="full"
          size="md"
          colorScheme="brand"
        >
          Salvar
        </Button>
      ),
    });
  };
}
