import Layout from '@/screens/utils/AppLayout';
import React, { Suspense } from 'react';
import {
  Switch,
  RouteProps,
  useRouteMatch,
  Redirect,
  Route,
} from 'react-router-dom';

const AccessReactivation = React.lazy(
  () => import('@/screens/dashManagement/AccessReactivation'),
);
const Functionalities = React.lazy(
  () => import('@/screens/dashManagement/Functionalities'),
);
const Users = React.lazy(() => import('@/screens/dashManagement/Users'));

const MonitoringRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Suspense fallback={null}>
        <Switch>
          <Route
            path={`${path}/access_reactivation`}
            component={AccessReactivation}
          />
          <Route path={`${path}/functionalities`} component={Functionalities} />
          <Route path={`${path}/users`} component={Users} />
          <Redirect to={`${path}/users`} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default MonitoringRoutes;
