import { Answer } from '../interfaces';

export function validateField(
  answer: Answer | Answer[] | undefined,
  required?: boolean,
): boolean {
  if (!required) return true; // no check if field not required
  if (!answer) return false;
  if (Array.isArray(answer)) {
    return answer.length > 0;
  }
  return !!answer.content;
}
