import ColorPicker from '@/components_v2/ColorPicker';
import config from '@/Constants';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { Tag } from '@/screens/registrations/Tasks/interfaces';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  theme,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type TagOption = {
  label: string;
  value: string;
  color?: string;
};

export type TagCreateErrors = {
  name?: string;
  color?: string;
};

async function createTag(tag: Pick<Tag, 'name' | 'color'>): Promise<Tag> {
  const response = await AxiosHelper.post({ url: config.TAGS_URL, body: tag });
  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new UnexpectedError();
  }
  return response.body;
}

const { colors } = theme;
const MOCK = [
  colors.blue['500'],
  colors.cyan['500'],
  colors.gray['500'],
  colors.green['500'],
  colors.orange['500'],
  colors.pink['500'],
  colors.purple['500'],
  colors.teal['500'],
  colors.red['500'],
  colors.yellow['500'],
];

export const TagsSelectCreate: React.FC<{ onSubmit?: (tag: Tag) => void }> = ({
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = React.useState<TagCreateErrors>({});
  const [isOpen, setIsOpen] = React.useState(false);
  const [name, setName] = React.useState<undefined | string>(undefined);
  const [color, setColor] = React.useState<undefined | string>(undefined);

  const handleSubmit = React.useCallback(async () => {
    if (!name) {
      setErrors((prev) => ({ ...prev, name: 'Digite um nome' }));
    }
    if (!color) {
      setErrors((prev) => ({ ...prev, color: 'Selecione uma cor' }));
    }
    if (name && color) {
      const tag = await createTag({ name, color });
      if (onSubmit) onSubmit(tag);
      setIsOpen(false);
      setColor(undefined);
      setName(undefined);
    }
  }, [name, color, onSubmit]);

  const handleNameUpdate = React.useCallback((value: string) => {
    setName(value);
    setErrors((prev) => ({ ...prev, name: undefined }));
  }, []);

  const handleColorUpdate = React.useCallback((value: string) => {
    setColor(value);
    setErrors((prev) => ({ ...prev, color: undefined }));
  }, []);

  return (
    <Popover isOpen={isOpen} placement="auto">
      <PopoverTrigger>
        <Button
          size="md"
          onClick={() => setIsOpen(true)}
          aria-label={t('tasks.form.create_tag')}
        >
          {t('tasks.form.create_tag')}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton onClick={() => setIsOpen(false)} />
        <PopoverHeader>{t('tasks.form.new_tag')}:</PopoverHeader>
        <PopoverBody>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel>{t('common.name')}</FormLabel>
            <Input
              value={name}
              onChange={(e) => handleNameUpdate(e.target.value)}
            />
            {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
          </FormControl>
          <FormControl
            my={2}
            w="100%"
            display="flex"
            alignItems="flex-start"
            position="unset"
            flexDirection="column"
            isInvalid={!!errors.color}
          >
            <HStack>
              <FormLabel mb={0}>{t('common.color')}</FormLabel>
              <ColorPicker
                initialValue={color}
                colors={MOCK}
                onChange={handleColorUpdate}
              />
            </HStack>
            {errors.color && (
              <FormErrorMessage>{errors.color}</FormErrorMessage>
            )}
          </FormControl>
        </PopoverBody>
        <PopoverFooter>
          <Button onClick={handleSubmit}>{t('common.create')}</Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
