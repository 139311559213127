import { useAutomationFlow } from '@/screens/Tickets/context/automationFlow';
import { List, ListItem, Skeleton, VStack } from '@chakra-ui/react';
import React from 'react';

import TicketsListHeader from './TicketsList.Header';
import TicketsListItem from './TicketsList.Item';
import TicketsListNoItems from './TicketsList.NoItems';
import TicketsListPages from './TicketsList.Pages';
import { getTemplateColumns } from './utils';

const TicketsList: React.FC = () => {
  const {
    tickets,
    isLoading,
    orderedProperties,
    orderedSteps,
    hideProperties,
  } = useAutomationFlow();

  const templateColumns = React.useMemo(() => {
    return getTemplateColumns({
      properties: hideProperties ? 0 : orderedProperties?.length,
      steps: orderedSteps?.length,
    });
  }, [hideProperties, orderedProperties?.length, orderedSteps?.length]);

  const renderItems = React.useCallback(() => {
    if (tickets?.length === 0 || !tickets) {
      if (isLoading) {
        return (
          <>
            <Skeleton h="20" w="full" borderRadius="md" mb="1px" />
          </>
        );
      }
      return <TicketsListNoItems />;
    }

    return tickets?.map((ticket) => {
      return (
        <ListItem key={ticket.id} cursor="default">
          <TicketsListItem ticket={ticket} templateColumns={templateColumns} />
        </ListItem>
      );
    });
  }, [isLoading, templateColumns, tickets]);

  return (
    <>
      <VStack overflow="hidden" flex={1} w="100%">
        <List h="full" pr={1} overflowY="auto" spacing={0} w="100%">
          <TicketsListHeader templateColumns={templateColumns} />
          {renderItems()}
        </List>
        <TicketsListPages />
      </VStack>
    </>
  );
};

export default TicketsList;
