import { useAuth } from '@/hooks/Authentication/auth';
import { Text, VStack } from '@chakra-ui/react';
import React from 'react';

import { useChecklistSummary } from '../../useChecklistSummary';
import ChecklistActions from './ChecklistActions';
import DefaultActions from './DefaultActions';

type TaskActionsProps = { onClose: () => void; openChecklist?: () => void };

export const topicoPrimeAdminIds = [
  'ccebcd7b-c336-45ce-b7c7-17867fa0f170',
  '7023f42e-3d75-426c-900b-37e2734a03f1',
  '28a1c7b4-2fe0-435f-b139-7ac28cf415c2',
  'de08c292-dedc-45d6-bd8f-c101c656a268',
  '917c08ae-0725-462c-bfde-233281b92441',
  'c26d5faf-8366-4f7f-9df0-8d951f2909e9',
  '7fec7504-3958-41f2-9e26-e7523681b597',
  'ae31c938-98c5-449a-994f-f97bf5e9f473',
  'fad21b5f-064e-4374-b9db-e10d5bd3abb0',
  'd9acdddb-13d7-4a21-a380-b43e8e5d5842',
  '868fc81a-275b-47b7-8d29-3f6ec5c4522c', // admin user
  '28a1c7b4-2fe0-435f-b139-7ac28cf415c2',
  'de08c292-dedc-45d6-bd8f-c101c656a268',
];

const TaskActions: React.FC<TaskActionsProps> = (props) => {
  const { data } = useChecklistSummary();
  const { user } = useAuth();
  const isTopicoPrimeAdmin = user && topicoPrimeAdminIds.includes(user.id); // NOTE - gambiarra braba
  const disabled =
    (data?.member_id && user?.id !== data?.member_id && !isTopicoPrimeAdmin) ||
    data?.status === 'disabled';
  const Component = React.useMemo(() => {
    if (data?.formularies?.length)
      return <ChecklistActions disabled={disabled} {...props} />;

    return <DefaultActions disabled={disabled} {...props} />;
  }, [disabled, props, data?.formularies]);

  if (!data) return null;

  return (
    <VStack w="full">
      {!!(disabled && !['done', 'disabled'].includes(data.status)) && (
        <Text align="center" color="gray.400">
          Você não está associado a esta tarefa, portanto não poderá respondê-la
        </Text>
      )}
      {!!(disabled && ['disabled'].includes(data.status)) && (
        <Text align="center" color="gray.400">
          A tarefa está inativa e não pode ser respondida
        </Text>
      )}
      {Component}
    </VStack>
  );
};

export default TaskActions;
