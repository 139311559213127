import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FeedbackError } from '@components/Feedback';
import { UploadResp } from '@/modules/shared/domain/interfaces';
import { collectionStorage } from '@shared/adapters/CloudStorage';
import { UploadProps } from './interfaces';
import Main from './Main';

const UploadFile: React.FC<UploadProps> = (props) => {
  const { onChangeStatus, setFileDownloadUrl } = props;
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const [fileNames, setFileNames] = useState<UploadResp>();
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (loadingProgress === 100) {
      setTimeout(() => onChangeStatus('UPLOAD'), 250);
    }
  }, [loadingProgress, onChangeStatus]);

  const onAttach = async (file: File) => {
    try {
      onChangeStatus('LOADING');
      const resp = await collectionStorage.uploadFile({
        file,
        setProgress: setLoadingProgress,
        setFileDownloadUrl,
      });
      setFileNames(resp);
    } catch (e) {
      if (e instanceof Error) {
        FeedbackError({
          mainText: t(`common.error`),
          subText: t(`error.${e.message}`),
        });
      }
    }
  };

  const onDelete = async () => {
    if (!fileNames?.storage) return;
    setLoadingDelete(true);
    try {
      if (fileNames.storage) {
        await collectionStorage.deleteFileFromName(fileNames.storage);
        onChangeStatus('ATTACH');
      }
    } catch (e) {
      if (e instanceof Error) {
        FeedbackError({
          mainText: t(`common.error`),
          subText: t(`error.${e.message}`),
        });
      }
    }
    setLoadingDelete(false);
  };

  return (
    <Main
      {...props}
      loadingProgress={loadingProgress}
      fileName={fileNames?.old}
      loadingDelete={loadingDelete}
      onAttach={onAttach}
      onDelete={onDelete}
    />
  );
};

export default UploadFile;
