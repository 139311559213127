import { EventStatusType } from '@/modules/core/domain/interfaces';

export const statusCorrelation: Record<
  EventStatusType,
  { label: string; color: string; bg: string }
> = {
  pending: {
    color: 'gray.800',
    bg: 'gray.200',
    label: 'enum.pending',
  },
  done: {
    color: 'green.800',
    bg: '#D0E7E2',
    label: 'enum.done',
  },
  started: {
    color: 'teal.700',
    bg: '#D0E5F8',
    label: 'enum.started',
  },
  incomplete: {
    color: 'orange.800',
    bg: '#FDDFD2',
    label: 'enum.incomplete',
  },
  delayed: {
    color: 'orange.800',
    bg: 'orange.200',
    label: 'enum.delayed',
  },
  draft: {
    color: 'gray.800',
    bg: 'gray.200',
    label: 'enum.draft',
  },
  rescheduled: {
    color: 'orange.800',
    bg: '#FDDFD2',
    label: 'enum.rescheduled',
  },
  flagged: {
    color: 'red.800',
    bg: 'red.400',
    label: 'enum.rescheduled',
  },
  deleted: {
    color: 'blue.200',
    bg: 'blue.50',
    label: 'enum.deleted',
  },
  without_answers: {
    color: 'yellow.700',
    bg: 'yellow.500',
    label: 'enum.without_answers',
  },
  deleted_without_answers: {
    color: 'pink.700',
    bg: 'pink.300',
    label: 'enum.deleted_without_answers',
  },
};
