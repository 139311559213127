export enum FrequencyType {
  YEARLY = 0,
  MONTHLY = 1,
  WEEKLY = 2,
  DAILY = 3,
}

export const FREQUENCY_KEYS = ['yearly', 'monthly', 'weekly', 'daily'] as const;

export type FrequencyTypes = typeof FREQUENCY_KEYS[number];

export enum WeekDayEnum {
  SU = 0,
  MO = 1,
  TU = 2,
  WE = 3,
  TH = 4,
  FR = 5,
  SA = 6,
}

export const WeekDayType = {
  SU: 0,
  MO: 1,
  TU: 2,
  WE: 3,
  TH: 4,
  FR: 5,
  SA: 6,
};

export type WeekDayTypeEnum = keyof typeof WeekDayType;

export interface RecurrenceRules {
  typeRecurrent: FrequencyType;
  ruleInterval?: number;
  count?: number;
  dateStart: Date | null;
  dateEnd?: Date | null;
  weekStart?: WeekDayEnum | null;
  byWeekday?: WeekDayEnum[] | null;
  byMonthDay?: number | null;
  byMonth?: number | null;
}
