import { useChecklistSummaryDrawer } from '@/components_v2/ChecklistSummary';
import { gql } from '@/gql';
import { HideTaskInput } from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import React from 'react';

const updateTicketStatusMutation = gql(`#graphql
  mutation HideTask($input: HideTaskInput!) {
    hideTask(input: $input) {
      errors
    }
  }
`);

export function useUpdateTicketStatus() {
  const toast = useToast();
  const [mutate, payload] = useMutation(updateTicketStatusMutation);
  const { closeDrawer } = useChecklistSummaryDrawer();

  const updateTicketStatus = React.useCallback(
    async (input: HideTaskInput) => {
      const result = await mutate({
        variables: {
          input,
        },
      });

      const { data, errors } = result;

      if (data || errors) {
        toast({
          title: errors
            ? 'Edição do status falhou.'
            : 'Status alterado com sucesso.',
          status: errors ? 'error' : 'success',
        });
      }

      if (!errors) {
        closeDrawer();
      }

      return result;
    },
    [mutate, toast, closeDrawer],
  );

  return {
    updateTicketStatus,
    ...payload,
  };
}
