import styled from 'styled-components';

export const Container = styled.div`
  padding-right: 30px;
  transition: height 100ms ease-in-out;
`;

export const StatusContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
`;

export const TextWarning = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme }) => theme.colors.lightText};
  font-size: 0.7em;
  font-weight: normal;
`;

export const InputButton = styled.input`
  display: none;
`;
