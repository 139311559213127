import '@/utils/initializeGoogleApiScript';
import { Button, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiMapPin } from 'react-icons/fi';
import { Coordinates, GeolocationInputProps } from './GeolocationInput.types';
import { useGeolocationModal } from './GeolocationInputModal';

const GeolocationInput: React.FC<GeolocationInputProps> = ({
  onChange,
  initialValue,
  initialAddress = '',
}) => {
  const { onOpen } = useGeolocationModal();
  const handleApply = React.useCallback(
    (newValue?: Coordinates, newAddress?: string) => {
      onChange(newValue, newAddress);
    },
    [onChange],
  );

  const onOpenModal = React.useCallback(() => {
    onOpen({
      onChange: handleApply,
      initialAddress,
      initialValue,
    });
  }, [handleApply, initialAddress, initialValue, onOpen]);

  return (
    <>
      <Button
        minWidth="fit-content"
        colorScheme="brand"
        onClick={onOpenModal}
        leftIcon={<Icon as={FiMapPin} />}
      >
        Map
      </Button>
    </>
  );
};

export default GeolocationInput;
