import { FeedbackError } from '@/components/Feedback';
import create, { StoreApi, UseBoundStore } from 'zustand';

import { SyncContentState, SyncStore } from '../interfaces/ISync';
import { createSyncState } from '../services/createSyncState';
import { getSyncState } from '../services/getSyncState';
import { pullSyncState } from '../services/pullSyncState';
import { pushSyncState } from '../services/pushSyncState';
import {
  answersToSyncContentState,
  syncContentStateToAnswers,
} from '../utils/parseAnswersSync';

const createStore = (
  itemId: string,
): UseBoundStore<SyncStore, StoreApi<SyncStore>> =>
  create<SyncStore>((set, get) => ({
    itemId,
    syncStateId: undefined,
    isPulling: false,
    isPushing: false,
    pulledAnswers: undefined,
    lastPushAt: undefined,
    initialize: async (answers) => {
      if (get().syncStateId) return;
      getSyncState(itemId)
        .then((syncState) => {
          get().initialize_success(syncState, answers);
        })
        .catch(() => {
          get().initialize_error();
        });
    },
    initialize_success: (syncState, answers) => {
      if (syncState === null) {
        get().create(answers);
        set({ isPulling: false });
      } else {
        set({
          isPulling: false,
          pulledAnswers: syncState.content_state
            ? syncContentStateToAnswers(syncState.content_state.answers)
            : get().pulledAnswers,
          syncStateId: syncState.id,
        });
      }
    },
    initialize_error: () => {
      FeedbackError({ mainText: 'Falha ao carregar respostas' });
    },
    create: (answers) => {
      let syncContent: undefined | SyncContentState;
      set({ isPushing: true });
      if (answers) {
        syncContent = { answers: answersToSyncContentState(answers) };
      }
      createSyncState(itemId, syncContent)
        .then((syncState) => get().create_success(syncState.id))
        .catch(() => get().create_error());
    },
    create_success: (syncStateId: string) => {
      set({ syncStateId, isPushing: false });
    },
    create_error: () => {
      set({ isPushing: false });
      FeedbackError({ mainText: 'Falha ao sincronizar respostas' });
    },
    pull: () => {
      const { syncStateId } = get();
      if (!syncStateId) get().pull_error();
      else {
        set({ isPulling: true });
        pullSyncState(syncStateId)
          .then((syncState) =>
            get().pull_success(syncState.id, syncState.content_state),
          )
          .catch(() => {
            get().pull_error();
          });
      }
    },
    pull_success: (syncStateId, syncContent) => {
      let answers = get().pulledAnswers;
      if (syncContent) answers = syncContentStateToAnswers(syncContent.answers);
      set({ isPulling: false, pulledAnswers: answers, syncStateId });
    },
    pull_error: () => {
      set({ isPulling: false });

      FeedbackError({ mainText: 'Falha ao sincronizar respostas' });
    },
    push: (answers) => {
      const syncAnswers = answersToSyncContentState(answers);
      const { syncStateId } = get();
      if (syncStateId === undefined) {
        get().initialize();
        return;
      }
      set({ isPushing: true });
      pushSyncState(syncStateId, { answers: syncAnswers })
        .then(() => {
          get().push_success();
        })
        .catch(() => {
          get().push_error();
        });
    },
    push_success: () => {
      set({ isPushing: false, lastPushAt: new Date() });
    },
    push_error: () => {
      set({ isPushing: false });
      FeedbackError({ mainText: 'Falha ao sincronizar respostas' });
    },
  }));

export const createSyncStateStore = createStore;
