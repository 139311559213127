/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { FlaggedAnswersConnectionFragmentFragment } from '@/gql/graphql';
import { Flex } from '@chakra-ui/react';
import React from 'react';

import { JustificationActivities } from './Activities';
import { useCurrentJustification } from './hooks/useCurrentJustification';

type JustificationDisplayProps = {
  id: string;
  renderHeader?: (
    topic: FlaggedAnswersConnectionFragmentFragment,
  ) => JSX.Element;
};
export const JustificationDisplay: React.FC<JustificationDisplayProps> = (
  props,
) => {
  const { renderHeader = () => <></> } = props;

  const { item } = useCurrentJustification();

  return (
    <Flex flex="1" flexDir="column">
      <Flex
        w="full"
        borderBottomWidth="thin"
        h="20"
        alignItems="center"
        justifyContent="space-between"
        px="4"
      >
        {!!item && renderHeader(item)}
      </Flex>

      {!!item && <JustificationActivities item={item!} />}
    </Flex>
  );
};
