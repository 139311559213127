import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EventReport } from './EventReport';

interface EventReportsRouteParams {
  id: string;
}

const EventReportsRoute: React.FC = () => {
  const { length, goBack } = useHistory();
  const params = useParams<EventReportsRouteParams>();

  const { id } = params;
  return (
    <EventReport standaloneMode={length <= 1} onClose={goBack} eventId={id} />
  );
};

export default EventReportsRoute;
