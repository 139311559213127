/* eslint-disable @typescript-eslint/ban-types */

import { useAuth } from '@/hooks/Authentication/auth';
import { useWorkspace } from '@/hooks/workspace';
import { Audit } from '@/screens/Audit';
import { ExternalTaskPage } from '@/screens/ExternalTask/pages/ExternalTaskPage';
import Justifications from '@/screens/Justifications';
import { TaskDashboard } from '@/screens/registrations/Tasks';
import { withLayout } from '@/screens/utils/AppLayout';
import { Spinner } from '@chakra-ui/react';
import React from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import DashAccessReactivationRoutes from './groups/dashAccessReactivationRoutes.routes';
import DashboardRoutes from './groups/dashboard.routes';
import DashManagementdRoutes from './groups/dashManagement.routes';
import MonitoringRoutes from './groups/monitoring.routes';
import RegistrationsRoutes from './groups/registrations.routes';
import ReportsRoutes from './groups/reports.routes';
import SettingsRoutes from './groups/settings.routes';
import SupportRoutes from './groups/support.routes';
import TicketsRoutes from './groups/tickets.routes';
import WorkFlowRoutes from './groups/workflow.routes';

const readAllRoutes = {
  dashboard: {
    name: 'dashboard',
    title: 'screens.dashboard',
    path: '/dashboard',
    type: 'primary',
  },
  audit: {
    name: 'audit',
    title: 'screens.audit',
    path: '/audit',
    recursive: 'true',
    type: 'primary',
  },
  justifications: {
    name: 'justifications',
    title: 'screens.justifications',
    path: '/justifications',
    recursive: 'true',
    type: 'primary',
  },
};

const RoutesGate = () => {
  const hasRedirected = React.useRef(false);
  const { workspaceData, loading } = useWorkspace();
  const { replace, location } = useHistory();
  const { user } = useAuth();

  const redirect = React.useCallback(() => {
    if (loading || !workspaceData || !user) return;

    const routes =
      user?.read_all || user?.dashboard_only
        ? readAllRoutes
        : workspaceData.routes;

    const routeKeys = Object.entries(routes || {});

    if (!routeKeys.length) {
      replace(
        location.state?.from
          ? location.state?.from
          : `${location.pathname}/dashboard`,
      );
      return;
    }

    const firstRoute = routeKeys[0][1];

    if (firstRoute.path) {
      let routePath = firstRoute.path;

      if (firstRoute?.children) {
        const subRoutes: any = Object.values(firstRoute.children);
        routePath += subRoutes[0].path;
      }
      replace(location.state?.from ? location.state?.from : `/app${routePath}`);
    }
  }, [loading, location.pathname, replace, user, workspaceData]);

  React.useEffect(() => {
    redirect();
  }, [redirect]);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner size="xl" />
    </div>
  );
};

const AppRoutes: React.FC<RouteProps> = () => {
  const { token } = useAuth();
  const { path } = useRouteMatch();
  const { pathname, state } = useLocation();

  const getState = () => {
    if (state?.from) {
      return { from: state?.from };
    }
    return pathname.startsWith('/app') ? { from: pathname } : undefined;
  };

  const attemptedRoute = state?.from || `${path}/dashboard`;

  return token ? (
    <Switch>
      <Route path={`${path}`} exact component={RoutesGate} />
      <Route path={`${path}/dashboard`} component={DashboardRoutes} />
      <Route
        path={`${path}/monitoring`}
        component={withLayout(MonitoringRoutes)}
      />
      <Route
        path={`${path}/registrations`}
        component={withLayout(RegistrationsRoutes)}
      />

      <Route path={`${path}/tasks`} component={withLayout(TaskDashboard)} />
      <Route path={`${path}/task/:id`} component={ExternalTaskPage} />
      <Route path={`${path}/audit`} component={withLayout(Audit)} />
      <Route
        path={`${path}/justifications`}
        component={withLayout(Justifications)}
      />
      <Route path={`${path}/tickets`} component={TicketsRoutes} />
      <Route path={`${path}/settings`} component={withLayout(SettingsRoutes)} />
      <Route path={`${path}/support`} component={withLayout(SupportRoutes)} />
      <Route path={`${path}/reports`} component={ReportsRoutes} />
      <Route
        path={`${path}/dash_management`}
        component={DashManagementdRoutes}
      />
      <Route
        path={`${path}/dash_access_reactivation`}
        component={DashAccessReactivationRoutes}
      />
      <Redirect to={attemptedRoute} />
    </Switch>
  ) : (
    <Redirect
      to={{
        pathname: '/auth/signin',
        state: getState(),
      }}
    />
  );
};

export default AppRoutes;
