import { Tab, TabList, Tabs, theme } from '@chakra-ui/react';
import React from 'react';

import { TabSelectorRenderProps } from './TabSelector.types';

function TabSelectorIosFull<T extends string>({
  initialKey,
  onChange,
  tabs,
}: TabSelectorRenderProps<T>): JSX.Element {
  const [index, setIndex] = React.useState(() => {
    if (initialKey) return tabs.findIndex((tab) => tab.key === initialKey);
    return 0;
  });

  const handleChange = React.useCallback(
    (newIndex: number) => {
      setIndex(newIndex);
      onChange(tabs[newIndex].key, newIndex);
    },
    [onChange, tabs],
  );

  return (
    <Tabs
      maxWidth="full"
      width="full"
      variant="unstyled"
      index={index}
      onChange={handleChange}
    >
      <TabList
        bg="gray.100"
        width="full"
        borderRadius="6px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="gray.200"
        py={1}
        maxWidth="full"
      >
        {tabs.map((tab) => {
          return (
            <Tab
              key={tab.key}
              mx={1}
              py={1}
              px={5}
              flex={1}
              isDisabled={tabs.length === 1}
              _selected={{ bg: 'white', borderRadius: '6px' }}
              _disabled={{ cursor: 'unset', opacity: 0.4 }}
            >
              {tab.label}
            </Tab>
          );
        })}
      </TabList>
    </Tabs>
  );
}

export default TabSelectorIosFull;
