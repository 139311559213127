import { useToast } from '@chakra-ui/react';
import React from 'react';

import { OriginType, Task } from '../../../interfaces';
import { createTaskService } from '../../../services/createTaskService';
import { updateTaskService } from '../../../services/updateTaskService';
import { TaskSchema } from '../TaskForm';

const formatValues = (
  payload: Omit<Partial<TaskSchema>, 'id'>,
): Omit<Task, 'id' | 'status' | 'batch_tags_id' | 'task_attribute_values'> => {
  const {
    active,
    date_end,
    date_start,
    description,
    formulary,
    local,
    member,
    name,
    tags,
    user_profile_id,
    origin_id,
    origin_type,
  } = payload;
  return {
    active: !!active,
    name: name!,
    description,
    date_end: date_end?.getTime(),
    date_start: date_start?.getTime() || new Date().getTime(),
    batch_tags:
      tags?.map((tag) => ({
        id: tag!.value!,
        color: tag!.color!,
        name: tag!.label!,
      })) || [],
    member_id: member!.value,
    local_id: local?.value,
    user_profile_id,
    formulary_id: formulary?.value,
    origin_id: origin_id?.value || undefined,
    origin_type: (origin_type?.value as OriginType) || undefined,
  };
};

export function useEditTask(payload: {
  task?: Task;
  onSuccess?: (taskId?: string) => void;
}) {
  const { task, onSuccess } = payload;
  const toast = useToast();
  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const onSubmit = React.useCallback(
    async (payload: Partial<TaskSchema>) => {
      try {
        const formatted = formatValues(payload);
        if (!isSubmiting) {
          setIsSubmiting(true);
          let success = false;
          if (task?.id) {
            success = await updateTaskService({
              ...formatted,
              id: `${task.id}`,
            });
          } else {
            success = await createTaskService(formatted as Task);
          }

          if (success) {
            onSuccess?.(task?.id);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          toast({
            title: 'Erro',
            status: 'error',
            description: error.message,
          });
        }
      } finally {
        setIsSubmiting(false);
      }
    },
    [isSubmiting, onSuccess, task, toast],
  );

  return {
    onSubmit,
    isSubmiting,
  };
}
