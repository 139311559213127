import { ComponentSingleStyleConfig, CSSObject, theme } from '@chakra-ui/react';

export const Text: ComponentSingleStyleConfig = {
  variants: {
    column_header: (props): CSSObject => ({
      color: 'gray.700',
      fontWeight: 'bold',
    }),
  },
};
