import React from 'react';
import { useInspectionStoreApi } from '../context/store';
import { findQuestionFromSections } from '../utils/findQuestionFromSections';

export default function useFindQuestion() {
  const { getState } = useInspectionStoreApi();

  const findQuestion = React.useCallback((questionId: string) => {
    const { formulary } = getState();

    if (!formulary) return null;

    const question = findQuestionFromSections(formulary.sections, questionId);

    return question;
  }, []);

  return {
    findQuestion,
  };
}
