export function getTemplateColumns({
  properties = 0,
  steps = 0,
}: {
  properties?: number;
  steps?: number;
}) {
  return `40px 80px 150px repeat(${properties + 1 || 1}, 150px) repeat(${
    steps + 1 || 1
  }, 140px) 150px`;
}
