import ModalStack from '@/utils/ModalStack';
import { Button } from '@chakra-ui/react';
import React from 'react';

import { Container, Icon, Text } from './styles';

interface EmptyListItemProps {
  text?: string;
  styles?: React.CSSProperties;
}

const EmptyListItem: React.FC<EmptyListItemProps> = ({ text, styles }) => {
  return (
    <Container style={styles}>
      <Icon size={24} />
      <Text>{text || 'Nenhum item encontrado'}</Text>
      <Button mt="3" onClick={() => ModalStack.pop()}>
        Close
      </Button>
    </Container>
  );
};

export default EmptyListItem;
