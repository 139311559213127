/* eslint-disable no-underscore-dangle */
import {
  ActionSheetRefProps,
  OpenActionSheetProps,
} from '@/hooks/interfaces/actionSheet';

// export interface ActionSheetRefProps {
//   openActionSheet: (props: OpenActionSheetProps) => void;
//   closeActionSheet: (key: string) => void;
//   destroyActionSheet: (key: string) => void;
//   popActionSheet: () => void;
// }

export default class AppActionSheet {
  static _ref: ActionSheetRefProps;

  static setRef(ref: ActionSheetRefProps | null): void {
    if (ref) {
      AppActionSheet._ref = ref;
    }
  }

  static getRef(): ActionSheetRefProps {
    return AppActionSheet._ref;
  }

  static openActionSheet(props: OpenActionSheetProps): void {
    AppActionSheet._ref.openActionSheet(props);
  }

  static closeActionSheet(key: string): void {
    AppActionSheet._ref.closeActionSheet(key);
  }

  static destroyActionSheet(key: string): void {
    AppActionSheet._ref.destroyActionSheet(key);
  }

  static popActionSheet(): void {
    AppActionSheet._ref.popActionSheet();
  }
}
