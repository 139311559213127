import React from 'react';

import Main from './Main';

import { AlertModalProps } from './interfaces';

const AlertModal: React.FC<AlertModalProps> = ({
  onClose,
  title,
  subTitle,
}) => {
  return <Main onClose={onClose} title={title} subTitle={subTitle} />;
};

export type { AlertModalProps };

export default AlertModal;
