import { Cable, Channel } from 'actioncable';
import ISocketClient, {
  Listener,
  SubscribeParams,
} from '@shared/data/protocols/websocket/ISocketClient';

interface CableConnection {
  [key: string]: Channel;
}

export default class ActionCableClient implements ISocketClient {
  public url: string;
  private connections: CableConnection = {};
  private cable: Cable;

  constructor(url: string, cable: Cable) {
    this.url = url;
    this.cable = cable;
  }

  public on<T>(params: SubscribeParams, listener: Listener<T>): void {
    const connection = this.cable.subscriptions.create(params, {
      received: listener,
    });
    this.connections[params.channel + params.id] = connection;
  }

  public off(params?: SubscribeParams): void {
    if (params) {
      if (this.connections[params.channel + params.id]) {
        this.connections[params.channel + params.id].unsubscribe();
        delete this.connections[params.channel + params.id];
      }
    } else {
      const keys = Object.keys(this.connections);
      for (let i = 0; i <= keys.length; i++) {
        this.connections[keys[i]].unsubscribe();
      }
      this.connections = {};
    }
  }

  public disconnect(): void {
    this.cable.disconnect();
  }
}
