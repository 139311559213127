import { usePagination } from '@/components/DashboardCard/utils/usePagination';
import { useFiltersByScreen } from '@/components_v2/Filtersv3/filter-store';
import { useFragment } from '@/gql';
import { filterObject, reduceNestedFilters } from '@/utils/filters';
import { createContext } from '@/utils/react-utils';
import { orderBy } from 'lodash';
import React from 'react';

import {
  AutomationFlowPropsFrag,
  AutomationProperties_FlowFragment,
  AutomationSteps_FlowFragment,
  useFetchFlow,
} from '../hooks/useFetchFlow';
import { useFetchTickets } from '../hooks/useFetchTickets';

const getFragmentData = useFragment;

export function useAutomationFlowProvider(id: string) {
  const [hideProperties, setHideProperties] = React.useState(false);
  const { data: remoteFlow } = useFetchFlow({ id });

  const dataFlow = useFragment(AutomationFlowPropsFrag, remoteFlow);

  const [selected, setSelected] = React.useState<string[]>([]);

  const [paginationParams] = usePagination({
    options: { initialPage: 1, initialSize: 15, debounceRate: 1000 },
  });

  const { byKey } = useFiltersByScreen();

  const reduced = reduceNestedFilters(byKey);

  const ticketsFilters = filterObject(reduced);

  const ticketsRangeFilter = ticketsFilters.created_at
    ? JSON.parse(ticketsFilters.created_at)
    : {};
  delete ticketsFilters['created_at'];

  const usersFilters = { full_name: ticketsFilters.full_name };
  delete ticketsFilters['full_name'];

  const localsFilters = { name: ticketsFilters.name };
  delete ticketsFilters['name'];

  const macroFilters = {
    automation_tickets: { ...ticketsFilters, ...ticketsRangeFilter },
    users: { ...usersFilters },
    locals: { ...localsFilters },
  };

  const {
    search,
    pagination,
    nextPage,
    prevPage,
    setSize,
    handleSearch,
    setPage,
  } = paginationParams;

  const { data: tickets, ticketsTotal, loading } = useFetchTickets({
    flowId: dataFlow?.id,
    pagination,
    search,
    macroFilters,
  });

  const onSearch = React.useCallback(
    (searchStr: string) => {
      setPage(1);
      if (!searchStr || !searchStr.length) {
        handleSearch('');
        return;
      }
      handleSearch(`${searchStr}`);
    },
    [handleSearch, setPage],
  );

  const onSelect = React.useCallback(
    (id: string | 'all') => {
      if (id === 'all' && selected) {
        tickets && setSelected(tickets.map((i) => i.id));
      } else {
        setSelected((prev) => [...prev, id]);
      }
    },
    [selected, tickets],
  );

  const onDeselect = React.useCallback(
    (id: string | 'all') => {
      if (id === 'all' && selected) {
        setSelected([]);
      } else {
        setSelected((prev) => prev.filter((i) => i !== id));
      }
    },
    [selected],
  );

  const orderedProperties = React.useMemo(() => {
    if (!dataFlow?.automationProperties) return [];
    return orderBy(
      getFragmentData(
        AutomationProperties_FlowFragment,
        dataFlow.automationProperties,
      ),
      'position',
      'asc',
    );
  }, [dataFlow?.automationProperties]);

  const orderedSteps = React.useMemo(() => {
    if (!dataFlow?.automationSteps) return [];
    return orderBy(
      getFragmentData(AutomationSteps_FlowFragment, dataFlow.automationSteps),
      'position',
      'asc',
    );
  }, [dataFlow?.automationSteps]);

  return {
    flow: remoteFlow,
    orderedProperties,
    orderedSteps,
    tickets,
    ticketsTotal,
    selected,
    isLoading: loading,
    hideProperties,
    setHideProperties,
    pagination,
    onSearch,
    onSelect,
    onDeselect,
    prevPage,
    nextPage,
    setSize,
  };
}

export type AutomationFlowContextData = ReturnType<
  typeof useAutomationFlowProvider
>;

export const [AutomationFlowProvider, useAutomationFlow] = createContext<
  AutomationFlowContextData
>();
