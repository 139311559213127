import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import config, { VALIDATION_CODE_PLACEHOLDER } from '@/Constants';
import { useNavigation } from '@hooks/navigation';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { HttpStatusCode } from '@/modules/shared/data/protocols/http';
import { FeedbackInfo } from '@/components/Feedback';
import Main from './ValidateCode';

const ValidateCode: React.FC = () => {
  const [validationCode, setValidationCode] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const { navigate } = useNavigation();
  const { t } = useTranslation();

  const handleValidationCodeChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const token: string = event.currentTarget.value;
      setValidationCode(token);
    },
    [],
  );

  const handleApiProccess = useCallback(async () => {
    const url: string = config.PASSWORD_RECOVERY_VALIDATE_URL.replace(
      VALIDATION_CODE_PLACEHOLDER,
      validationCode,
    );
    const { body, statusCode } = await AxiosHelper.get({ url });
    if (statusCode === HttpStatusCode.ok) {
      navigate(`change_password/${validationCode}`);
      return;
    }
    FeedbackInfo({
      mainText: t('error.warning'),
      subText: body?.error
        ? t(`password_recovery.errors.${body.error}`)
        : t('error.unexpectedError'),
    });
  }, [validationCode, navigate, t]);

  const handleSubmitCode = useCallback(async () => {
    setLoading(true);
    await handleApiProccess();
    setLoading(false);
  }, [handleApiProccess]);

  return (
    <Main
      handleSubmitCode={handleSubmitCode}
      handleValidationCodeChange={handleValidationCodeChange}
      validationCode={validationCode}
      isLoading={isLoading}
    />
  );
};

export default ValidateCode;
