import React from 'react';

import Loader from '@components/Loader';
import { SubmitButtonProps } from './localGeneric';
import { Button, TextContent } from './styles';

const SubmitButton: React.FC<SubmitButtonProps> = ({
  callback,
  buttonContent,
  type,
  disabled,
  style,
  isLoading,
}) => {
  return (
    <Button onClick={callback} type={type} disabled={disabled} style={style}>
      {isLoading ? (
        <Loader
          backgroundColor="transparent"
          loadColor="#fff"
          height="35px"
          width="35px"
        />
      ) : (
        <TextContent>{buttonContent}</TextContent>
      )}
    </Button>
  );
};

export default SubmitButton;
