/* eslint-disable consistent-return */

import { FiArrowRight } from 'react-icons/fi';
import styled, { css } from 'styled-components';

import constants from '../../constants';

interface ListItemProps {
  isSelected: boolean;
  disabled?: boolean | false;
}
interface SubMenuBarProps {
  isMainMenuOpen: boolean;
}

export const Container = styled.div<SubMenuBarProps>`
  background-color: ${(props) => props.theme.colors.terciaryContent};
  display: flex;
  position: absolute;
  left: ${constants.navbarWidth};
  transform: ${(props) =>
    props.isMainMenuOpen ? 'translateX(0%)' : 'translateX(-100%)'};

  height: 100%;
  width: ${constants.mainMenuBarWidth};
  flex-direction: column;
  z-index: 9;
  overflow: hidden;
  transition: transform 200ms ease-in-out, visibility 200ms ease-in-out;

  @media (min-width: 768px) {
    padding-top: 80px;
  }

  visibility: ${(props) => {
    return props.isMainMenuOpen ? 'visible' : 'hidden';
  }};
`;

const ListItem = styled.li<ListItemProps>`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  position: relative;
  margin-bottom: 20px;
  ${(props) => {
    if (props.disabled) return `pointer-events: none; opacity: 0.3;`;
    return '';
  }}

  color: ${(props) =>
    props.isSelected
      ? props.theme.colors.primary
      : props.theme.colors.darkText};

  p {
    z-index: 1;
    position: relative;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ListItemContainer = styled(ListItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 15px;
  font-weight: 400;
  font-size: 15px;
  height: 36px;
  transition: 0.15s;
  color: ${(props) =>
    props.isSelected
      ? props.theme.colors.primary
      : props.theme.colors.darkText};

  :hover {
    ${({ isSelected }) => {
      if (!isSelected) {
        return css`
          transform: scale(1.05);
        `;
      }
    }}
  }

  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        left: -10px;
        right: 0;
        z-index: 0;
        width: 190px;
        height: 36px;
        padding-left: 10px;
        background-color: ${(props) => props.theme.colors.secondaryContent};
        border-radius: 18px 0 0 18px;
      `;
    }
  }}
`;

export const List = styled.ul`
  list-style-type: none;
  padding-left: 26px;
`;

export const ArrowIcon = styled(FiArrowRight)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.commentGrey};
  visibility: hidden;
  margin-top: 5px;
`;
