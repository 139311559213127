import { QuestionModel } from '@/components_v2/Questions/interfaces';
import {
  useWebFormState,
  useWebFormStore,
} from '@/modules/WebForm/data/formState';
import { Answer } from '@/modules/WebForm/interfaces';
import { createAnswer } from '@/modules/WebForm/utils';
import { createContext, useLazyRef } from '@/utils/react-utils';
import { castArray } from 'lodash';
import React, { Reducer, ReducerAction, ReducerState } from 'react';
import {
  Action,
  SignoffState,
} from '../components/CirculabiSignoffResult/types';
import {
  Criticality,
  getCriticalityResult,
  SignoffQuestionMeta,
} from '../core';
import { getNoImpact } from '../utils/getNoImpact';
import { getObsolete } from '../utils/getObsolete';
import { parseScore } from '../utils/parseScore';
import { safeParseJson } from '../utils/safeParseJson';
import { signoffReducer } from '../utils/signoffReducer';

function useSubscribeField(
  fieldIds: string | string[] | undefined,
  callback: (id: string, value: any) => void,
) {
  const { getState, subscribe } = useWebFormStore();

  const scratchRef = useLazyRef(() => {
    if (!fieldIds) return undefined;
    const fields = castArray(fieldIds).filter((e) => e !== undefined);
    const values = fields.map((key) => getState().answers[key]);
    const firstValid = values.find((el) => typeof el !== undefined);

    if (Array.isArray(firstValid)) return undefined;

    return firstValid?.content;
  });

  React.useEffect(() => {
    let subscriptions: (() => void)[] = [];
    if (fieldIds) {
      subscriptions = castArray(fieldIds)
        .filter((e) => e !== undefined)
        .map((field) => {
          return subscribe(({ eventFieldId }) => {
            if (eventFieldId !== field) return; // agora sim
            const { visibleFields, answers } = getState();

            const answer = answers[field];

            if (visibleFields[field] && !Array.isArray(answer)) {
              scratchRef.current = answer?.content;
              callback(field, scratchRef.current);
            }
          });
        });
    }

    return () => {
      subscriptions.forEach((sub) => sub());
    };
  }, [callback, fieldIds, getState, scratchRef, subscribe]);
}

function useCriticalityValues(
  question: QuestionModel<any, SignoffQuestionMeta>,
  dispatch: React.Dispatch<Action>,
) {
  const prevCriticality = React.useRef<Criticality | undefined>();
  const stateRef = React.useRef<{
    severity: number | undefined;
    probability: number | undefined;
  }>({ probability: undefined, severity: undefined });
  const { getState } = useWebFormStore();

  const { meta } = question;

  const onChange = React.useCallback(
    (params: { severity?: number; probability?: number }) => {
      stateRef.current = { ...stateRef.current, ...params };

      const { probability, severity } = stateRef.current;
      if ([probability, severity].some((e) => Number.isNaN(e))) return;

      const criticality = getCriticalityResult({
        probability,
        severity,
      });
      prevCriticality.current = criticality;

      dispatch({ type: 'set_state', criticality });
    },
    [dispatch],
  );

  useSubscribeField(meta?.severity, (fieldId, value) => {
    const field = getState().fields[fieldId];

    const selectedOption = field?.options?.find((e) => e.id === value);

    if (selectedOption?.score !== undefined) {
      const severity = parseScore(selectedOption.score);

      if (severity !== stateRef.current.severity) {
        onChange({ severity });
      }
    }
  });

  useSubscribeField(meta?.probability, (fieldId, value) => {
    const field = getState().fields[fieldId];
    const selectedOption = field?.options?.find((e) => e.id === value);
    if (selectedOption?.score !== undefined) {
      const probability = parseScore(selectedOption.score);

      if (probability !== stateRef.current.probability) {
        onChange({ probability });
      }
    }
  });
}

function useSubscribeSignoffValues(
  question: QuestionModel<any, SignoffQuestionMeta>,
  dispatch: React.Dispatch<Action>,
) {
  const { getState } = useWebFormStore();

  const { meta } = question;

  useSubscribeField(
    meta?.obsolete,
    React.useCallback(
      (fieldId, value) => {
        const field = getState().fields[fieldId];

        const selectedOption = field?.options?.find((e) => e.id === value);

        const obsolete = getObsolete(
          selectedOption?.score
            ? Number.parseFloat(selectedOption?.score)
            : undefined,
        );
        dispatch({ type: 'set_state', obsolete });
      },
      [dispatch, getState],
    ),
  );

  useSubscribeField(
    meta?.has_impact,
    React.useCallback(
      (fieldId, value) => {
        const field = getState().fields[fieldId];

        const selectedOption = field?.options?.find((e) => e.id === value);

        const hasNoImpact = getNoImpact(
          selectedOption?.score ? Number.parseFloat(selectedOption?.score) : 0,
        );

        dispatch({ type: 'set_state', hasNoImpact });
      },
      [dispatch, getState],
    ),
  );

  useCriticalityValues(question, dispatch);
}

function useControlledReducer<R extends Reducer<any, any>>(
  reducer: R,
  initializer: () => ReducerState<R>,
  onChange?: (value: React.ReducerState<R>) => void,
): [ReducerState<R>, React.Dispatch<ReducerAction<R>>] {
  const [_, render] = React.useState(new Date().getTime());
  const stateRef = useLazyRef(initializer);

  const dispatch: React.Dispatch<ReducerAction<R>> = React.useCallback(
    (action) => {
      stateRef.current = reducer(stateRef.current, action);
      render(new Date().getTime());
      onChange?.(stateRef.current);
    },
    [onChange, reducer, stateRef],
  );

  return [stateRef.current, dispatch];
}

export function useSignOff(params: {
  question: QuestionModel;
  viewOnly?: boolean;
}) {
  const { question, viewOnly = false } = params;

  const onChangeAnswer = useWebFormState((state) => state.onChangeAnswer);

  const { getState } = useWebFormStore();

  const [state, dispatch] = useControlledReducer(
    signoffReducer,
    () => {
      const fieldState = getState().answers[question.id] as Answer | undefined;
      if (fieldState && !!fieldState?.content) {
        return safeParseJson(fieldState.content as any) as SignoffState;
      }
      return {};
    },
    (values) => {
      onChangeAnswer(question.id, createAnswer(JSON.stringify(values)));
    },
  );

  useSubscribeSignoffValues(question, dispatch);

  return { state, dispatch, viewOnly };
}

type UseSignOff = ReturnType<typeof useSignOff>;

const [SignOffProvider, useSignoffContext] = createContext<UseSignOff>();

export { SignOffProvider, useSignoffContext };
