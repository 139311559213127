import { AnswerStateMap } from '../interfaces';
import { SyncAnswers } from '../interfaces/ISync';

function answersToSyncContentState(answers: AnswerStateMap): SyncAnswers {
  const entries = Object.entries(answers);
  const state: SyncAnswers = [];
  for (let i = 0; i < entries.length; i++) {
    const [id, values] = entries[i];
    if (values) {
      if (Array.isArray(values)) {
        for (let j = 0; j < values.length; j++) {
          state.push({ ...values[j], questionId: id });
        }
      } else {
        state.push({ ...values, questionId: id });
      }
    }
  }
  return state;
}

function syncContentStateToAnswers(state: SyncAnswers): AnswerStateMap {
  const answers: AnswerStateMap = {};

  for (let i = 0; i < state.length; i++) {
    const s = state[i];
    const a = answers[s.questionId];
    if (a === undefined) {
      answers[s.questionId] = s;
    } else if (Array.isArray(a)) {
      a.push(s);
    } else {
      answers[s.questionId] = [a, s];
    }
  }

  return answers;
}

export { answersToSyncContentState, syncContentStateToAnswers };
