import config from '@/Constants';
import { UserModel } from '@/modules/shared/domain/interfaces';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';

async function fetchUser(id: string): Promise<UserModel> {
  const response = await AxiosHelper.get({ url: `${config.USERS_URL}/${id}` });

  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new Error();
  }
  return response.body;
}

export { fetchUser };
