import config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';

import { SyncContentState } from '../interfaces/ISync';

async function pushSyncState(
  syncStateId: string,
  syncContent: SyncContentState,
): Promise<void> {
  const response = await AxiosHelper.patch({
    url: `${config.ITEM_SYNC_STATES_URL}/${syncStateId}`,
    body: {
      content_state: syncContent,
    },
  });
  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new Error();
  }
}

export { pushSyncState };
