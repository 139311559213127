import React from 'react';
import { useSignoffContext } from '@/modules/circulabi/hooks/useSignOff';
import {
  chakra,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import debounce from '@/utils/debounce';
import { parseScore } from '@/modules/circulabi/utils/parseScore';

export function CurrentStockInput() {
  const { state, dispatch, viewOnly } = useSignoffContext();

  const onChange = React.useRef(
    debounce((val: string) => {
      dispatch({ type: 'set_current_stock', currentStock: parseScore(val) });
    }, 500),
  ).current;
  if (!state.criticality) return null;

  return (
    <chakra.div display="flex">
      <FormControl>
        <FormLabel>Estoque atual</FormLabel>
        <NumberInput
          isDisabled={viewOnly}
          min={0}
          onChange={(event) => onChange(event)}
          defaultValue={state.currentStock?.toString()}
        >
          <NumberInputField />
        </NumberInput>
      </FormControl>
    </chakra.div>
  );
}
