/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useColorMode } from '@chakra-ui/react';

import 'react-datepicker/dist/react-datepicker.css';
import '@/components_v2/WCDatePicker/date-picker.css';
import { FieldProps, registerFieldType } from '@/components_v2/HookForm';

type DateInputProps<T extends string, R extends boolean> = ReactDatePickerProps<
  T,
  R
> &
  FieldProps;

export const DateInput = <T extends string, R extends boolean>({
  ...props
}: DateInputProps<T, R>): JSX.Element => {
  const { onChange, value } = props;
  const isLight = useColorMode().colorMode === 'light';
  return (
    <div className={isLight ? 'light-theme' : 'dark-theme'}>
      <ReactDatePicker
        className="react-datapicker__input-text"
        selected={value as any}
        {...props}
        onChange={onChange as any}
        onSelect={onChange as any}
        dateFormat="Pp"
        showPopperArrow={false}
      />
    </div>
  );
};

registerFieldType('datepicker', DateInput, {
  isControlled: true,
});
