import React, { Suspense } from 'react';
import { Switch, RouteProps, useRouteMatch, Route } from 'react-router-dom';

const EventReportsRoute = React.lazy(
  () => import('@/screens/monitoring/EventReports/Route'),
);

const ReportsRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path={`${path}/event/:id`} component={EventReportsRoute} />
      </Switch>
    </Suspense>
  );
};

export default ReportsRoutes;
