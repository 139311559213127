import styled from 'styled-components';
import {
  FiUpload,
  FiDownload,
  FiTrash2,
  FiPlusCircle,
  FiFilter,
} from 'react-icons/fi';
import { BiCog } from 'react-icons/bi';

export const FilterIcon = styled(FiFilter)`
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 15px;
  margin-right: 3px;
`;

export const ExportIcon = styled(FiUpload)`
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 15px;
  margin-right: 3px;
`;

export const ImportIcon = styled(FiDownload)`
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 15px;
  margin-right: 3px;
`;

export const DeleteIcon = styled(FiTrash2)`
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 15px;
  margin-right: 3px;
`;

export const AddIcon = styled(FiPlusCircle)`
  color: ${({ theme }) => theme.colors.lighterText};
  font-size: 15px;
  margin-right: 3px;
`;

export const ConfigIcon = styled(BiCog)`
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 16px;
  margin-right: 3px;
`;
