import { Answer, IField } from '../interfaces';

export const getComparisonHandler = (field: IField) => {
  switch (field.parentComparisonType) {
    case 'option_id':
      return (parentValue: Answer | Answer[] | undefined) => {
        if (!parentValue) return false;
        const { parentValueMatch } = field;
        if (Array.isArray(parentValue)) {
          return (
            parentValue.findIndex((el) => el.content === parentValueMatch) > -1
          );
        }

        return parentValue.content === parentValueMatch;
      };

    default:
      return () => false;
  }
};
