import React from 'react';

import { Container, ToogleBall } from './styles';

interface ToggleProps {
  state: boolean;
  color?: string;
  width?: number;
  height?: number;
  onChange: () => void;
  disabled?: boolean;
}

const Toggle: React.FC<ToggleProps> = ({
  state,
  width = 43,
  height = 21,
  color,
  onChange,
  disabled = false,
}) => {
  return (
    <Container
      state={state}
      width={width}
      height={height}
      color={color}
      onClick={() => (!disabled ? onChange() : undefined)}
    >
      <ToogleBall state={state} width={width} color={color} height={height} />
    </Container>
  );
};

export default Toggle;
