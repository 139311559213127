import { DefaultTheme } from 'styled-components';

import { colors } from './colors';
import { fonts } from './fonts';

const defaultTheme: DefaultTheme = {
  title: 'default',

  fonts: {
    primaryFont: fonts.nunito,
  },

  colors: {
    primary: colors.primary,
    primaryV2: colors.primaryV2,
    secondary: colors.secondary,

    background: colors.backgroundDefault,
    border: colors.lighterGrey,
    darkBorder: colors.lightGrey,

    darkerText: colors.pureBlack,
    darkText: colors.lightBlack,
    mediumText: colors.darkGrey,
    lightText: colors.mediumGrey,
    lighterText: colors.pureWhite,

    commentGrey: colors.commentGrey,
    borderGrey: colors.borderGrey,

    imageBackground: colors.mistGrey,

    success: colors.limeGreen,
    warning: colors.lightOrange,
    pending: colors.strongOrange,
    incomplete: colors.strongOrange,
    started: colors.primary,
    pendingGrey: colors.lightGrey,
    draft: colors.strongOrange,
    done: colors.limeGreen,
    error: colors.strongRed,

    notification: colors.strongRed,

    primaryContent: colors.pureWhite,
    secondaryContent: colors.lightWhite,
    terciaryContent: colors.lighterGrey,

    tableHeader: colors.lightGrey,
    tableDark: colors.lightWhite,
    tableLight: colors.pureWhite,

    v2_secondary: colors.gray2,
    v2_backgound: colors.gray4,

    black: colors.pureBlack,
  },
};

export { defaultTheme };
