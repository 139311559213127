import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const WeekItemContainer = styled.div`
  border-radius: 5px;
  height: 35px;
  width: 40px;
  margin-right: 5px;
`;
