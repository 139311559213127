import React, {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { LightboxProps, LightboxRefProps } from './interfaces';

import Main from './Main';

const Lightbox: React.ForwardRefRenderFunction<
  LightboxRefProps,
  LightboxProps
> = (props, selfRef) => {
  const {
    images = [],
    initialIndex = -1,
    isOpen = false,
    onClose = () => null,
    onOpen = () => null,
  } = props;
  const [openState, setOpenState] = useState<boolean>(isOpen);
  const [currentImages, setCurrentImages] = useState<string[]>(images);
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex);

  const setOpen = useCallback(
    (images: string[], idx: number) => {
      setOpenState(true);
      setCurrentImages(images);
      setCurrentIndex(idx);
      onOpen();
    },
    [onOpen],
  );

  const setClosed = useCallback(() => {
    setOpenState(false);
    setCurrentImages([]);
    setCurrentIndex(-1);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!isOpen) {
      onClose();
    } else {
      onOpen();
    }
  }, [isOpen]);

  const onPrevious = useCallback(() => {
    setCurrentIndex(
      (idx) => (idx + currentImages.length - 1) % currentImages.length,
    );
  }, [currentImages]);

  const onNext = useCallback(() => {
    setCurrentIndex((idx) => (idx + 1) % currentImages.length);
  }, [currentImages]);

  useImperativeHandle(selfRef, () => ({
    open: setOpen,
    close: setClosed,
  }));

  if (!openState) return null;

  return (
    <Main
      currentIndex={currentIndex}
      images={currentImages}
      onCloseRequest={setClosed}
      onNextRequest={onNext}
      onPreviousRequest={onPrevious}
    />
  );
};

let moduleRef: LightboxRefProps;

function setLightboxRef(ref: LightboxRefProps): void {
  moduleRef = ref;
}

function getLightboxRef(): LightboxRefProps | undefined {
  return moduleRef;
}

function openLightbox(images: string[], idx: number): void {
  moduleRef.open(images, idx);
}

function closeLightbox(): void {
  moduleRef.close();
}

const LightboxComponent = forwardRef(Lightbox);

export default {
  LightboxComponent,
  setLightboxRef,
  getLightboxRef,
  openLightbox,
  closeLightbox,
};
