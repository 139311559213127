import { QuestionAttachments } from '@/components_v2/QuestionAttachments/QuestionAttachments';
import { useDefaultAttachmentsHandler } from '@/components_v2/QuestionAttachments/useDefaultAttachmentsHandler';
import { withUploadProvider } from '@/hooks/upload/uploadProvider';
import { attachmentsStorage } from '@/modules/shared/adapters/CloudStorage';
import { Collapse, Flex } from '@chakra-ui/react';
import React from 'react';

import { useInteractions } from '../../hooks/useInteractions';
import { useQuestionContext } from './QuestionItem';

const makeAttachmentsHook = (payload: {
  questionId: string;
  itemId: string;
}) => {
  return () => useDefaultAttachmentsHandler(payload);
};

export function Content({ questionId }: { questionId: string }): JSX.Element {
  const { paneOpen } = useQuestionContext();
  const { selectedItemId } = useInteractions();

  const useUploader = React.useRef(
    makeAttachmentsHook({ itemId: `${selectedItemId}`, questionId }),
  ).current;

  if (!selectedItemId) return <></>;
  return (
    <Collapse in={paneOpen === 'attachments'}>
      <Flex bg="gray.50" borderTopWidth="thin" p="3" flex="1">
        <QuestionAttachments useUploader={useUploader} />
      </Flex>
    </Collapse>
  );
}

export const QuestionAttachmentsPane = withUploadProvider(attachmentsStorage)(
  Content,
);
