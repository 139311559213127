import LanguageSwitcher from '@/components_v2/LanguageSwitcher';
import { Flex, Text } from '@chakra-ui/react';

import { Container, LanguageIcon } from './styles';

export default function ChangeLanguageBtn({
  isMobile,
}: {
  isMobile?: boolean;
}) {
  return (
    <Container>
      <LanguageSwitcher isMobile={isMobile}>
        <Flex gap="0.75rem" justifyContent="center" alignItems="center">
          <LanguageIcon size={18} width={18} />
          {isMobile && <Text fontSize="md">Idioma</Text>}
        </Flex>
      </LanguageSwitcher>
    </Container>
  );
}
