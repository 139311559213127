import { useModals } from '@/hooks/ModalManager';
import camelToSnake from '@/utils/camelToSnake';
import {
  Flex,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Button,
  Skeleton,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { EventForm, IFormSchema, serializeValues } from '../Common/EventForm';
import {
  useUploadStatus,
  withUploadStatus,
} from '../Common/EventForm/useUploadStatus';

import { useCreateFlowEventMutation } from '../data/createFlowEvent.mutation';
import { useFlowQuery } from '../data/useFlow.query';
import { WorkflowEventableType } from '../types/WorkflowEvent';

type Props = {
  flowId: string;
  eventableType?: WorkflowEventableType;
  eventableId?: string;
  externalId?: string; // event_id
  onSuccess?: () => void;
};

export const FormWrapper: React.FC<Props> = withUploadStatus((props) => {
  const { isBusy } = useUploadStatus();
  const toast = useToast();
  const {
    flowId,
    eventableId,
    eventableType,
    externalId,
    onSuccess = () => null,
  } = props;
  const modals = useModals();
  const { mutateAsync, isLoading } = useCreateFlowEventMutation(flowId);
  const { data: flow } = useFlowQuery(flowId);

  const attributes = React.useMemo(
    () => flow?.attributes.filter((e) => e.required || e.isDefault) || [],
    [flow?.attributes],
  );

  const handleSubmit = React.useCallback(
    async (params: { payload: Record<string, any>; schema: IFormSchema }) => {
      const { payload, schema } = params;
      const serializedValues = serializeValues(schema, payload);
      await mutateAsync(
        {
          atts: serializedValues.map(camelToSnake) as any,
          eventable_type: eventableType,
          eventable_id: eventableId,
          external_id: externalId,
        },
        {
          onSuccess: () => {
            toast({
              title: 'Item criado.',
              description: `O item foi criado no fluxo "${flow?.name}"`,
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
            modals.close('flow-event-form');
            onSuccess();
          },
          onError: () => {
            toast({
              title: 'Ocorreu um erro!',
              description: `Tente novamente.`,
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
            modals.close('flow-event-form');
          },
        },
      );
    },
    [
      eventableId,
      eventableType,
      externalId,
      flow?.name,
      modals,
      mutateAsync,
      onSuccess,
      toast,
    ],
  );

  return (
    <Flex flexDir="column" flex="1">
      <ModalBody>
        <Flex flexDir="column" w="full" color="mutedText">
          {!attributes.length && (
            <Stack spacing="4">
              <Skeleton h="10" />
              <Skeleton h="10" />
              <Skeleton h="10" />
              <Skeleton h="10" />
            </Stack>
          )}
          {!!attributes.length && (
            <Flex flex="1" flexDir="column">
              <EventForm attributes={attributes} onSubmit={handleSubmit} />
            </Flex>
          )}
        </Flex>
      </ModalBody>

      <ModalFooter>
        <Flex py="4" w="full" justify="flex-end">
          <ButtonGroup w="full" size="md">
            <Button
              isDisabled={isLoading || isBusy}
              onClick={modals.pop}
              variant="outline"
              flex="1"
            >
              Cancelar
            </Button>
            <Button
              isLoading={isLoading || isBusy}
              flex="1"
              type="submit"
              form="event-form"
              colorScheme="primary"
            >
              Submeter
            </Button>
          </ButtonGroup>
        </Flex>
      </ModalFooter>
    </Flex>
  );
});

export function useFlowEventForm() {
  const modals = useModals();

  const openModal = React.useCallback(
    (
      flowId: string,
      eventable: {
        eventableType?: WorkflowEventableType;
        eventableId?: string;
        externalId?: string;
      } = {},
      cb?: () => void,
    ) => {
      modals.open({
        id: 'flow-event-form',
        title: 'Novo item',
        scope: 'alert',
        size: 'xl',
        hideCloseButton: true,
        closeOnEsc: false,
        body: <FormWrapper flowId={flowId} {...eventable} onSuccess={cb} />,
      });
    },
    [modals],
  );

  return { openModal };
}
