import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Select, MultiValue, OptionBase } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

type CheckboxSelectProps = {
  values?: OptionBase[];
  options: OptionBase[];
  onChange: (value: MultiValue<OptionBase>) => void;
} & Omit<UseFormRegisterReturn, 'onChange'>;

export const CheckboxInput: React.FC<CheckboxSelectProps> = ({
  options,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Select
      placeholder={t('tasks.form.user_placeholder')}
      isClearable
      isMulti
      menuPortalTarget={document.body}
      options={options}
      {...rest}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          rounded: 'lg',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          bg: 'transparent',
          px: 2,
          cursor: 'inherit',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
      }}
      styles={{
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 1900,
        }),
      }}
    />
  );
};
