import { useCancelFeedbackReview } from '@/hooks/Feedback/useCancelFeedbackReview';
import { useCreateFeedbackReview } from '@/hooks/Feedback/useCreateFeedbackReview';
import { useModals } from '@/hooks/ModalManager';
import React from 'react';
import { useCurrentFeedback } from './useCurrentFeedback';

const alertMessages = {
  create:
    'Ao solicitar revisão, este item estará disponível para que o autor edite-o. Tem certeza que deseja executar esta ação?',
  cancel: 'Tem certeza que deseja cancelar esta revisão ?',
};

export function useFeedbackReview() {
  const { feedback } = useCurrentFeedback();

  const {
    mutate: createFeedbackReview,
    isLoading: isLoadingCreate,
  } = useCreateFeedbackReview();
  const {
    mutate: cancelFeedbackReview,
    isLoading: isLoadingCancel,
  } = useCancelFeedbackReview();

  const modals = useModals();

  const alertMessage = React.useMemo(() => {
    if (feedback?.itemStatus === 'review') {
      return alertMessages.cancel;
    }

    return alertMessages.create;
  }, [feedback?.itemStatus]);

  const onSubmit = React.useCallback(() => {
    if (feedback?.reviewOn) {
      cancelFeedbackReview({
        discussionTopicId: feedback!.id,
      });
      return;
    }

    createFeedbackReview({
      discussionTopicId: feedback!.id,
    });
  }, [cancelFeedbackReview, createFeedbackReview, feedback]);

  const handleReviewRequest = React.useCallback(() => {
    modals.confirm({
      title: 'Atenção!',
      body: alertMessage,
      onConfirm() {
        onSubmit();
      },
    });
  }, [alertMessage, modals, onSubmit]);

  return {
    handleReviewRequest,
    isLoading: isLoadingCancel || isLoadingCreate,
  };
}
