import { Table, Td } from '../components/table';
import { useReport } from './hooks/use-context';

function ReportDetails() {
  const { header } = useReport();

  if (!header) return null;

  const {
    event_created_at,
    event_finished_at,
    author_email,
    hide_score,
    score_obtained,
    total_score,
    local,
    percent_score,
    fantasy_name,
    company_name,
    address,
    document: companyDocument,
    number_seq,
  } = header;

  return (
    <div
      style={{
        width: '100%',
        fontSize: '0.875rem',
        fontWeight: 'bold',
      }}
    >
      <Table>
        <tbody>
          {number_seq && (
            <tr>
              <Td>Número de controle</Td>
              <Td>{number_seq}</Td>
            </tr>
          )}

          <tr>
            <Td>Iniciado em</Td>
            <Td>{!!event_created_at && event_created_at}</Td>
          </tr>

          {/* <tr>
            <Td>Respondido em</Td>
            <Td>{!!event_date_time && event_date_time}</Td>
          </tr> */}

          <tr>
            <Td>Finalizado em</Td>
            <Td>{!!event_finished_at && event_finished_at}</Td>
          </tr>

          <tr>
            <Td>Autor</Td>
            <Td>{author_email}</Td>
          </tr>

          {!hide_score && score_obtained > 0 && (
            <tr>
              <Td>Pontuação do checklist</Td>
              <Td>
                {score_obtained} / {total_score} - {percent_score}%
              </Td>
            </tr>
          )}

          {!!local && (
            <tr>
              <Td>Local do evento:</Td>
              <Td>
                {` ${local.name}`}
                {local.address ? `, ${local.address}` : null}
                {local.city ? `, ${local.city}` : null}
                {local.state ? `, ${local.state}` : null}
              </Td>
            </tr>
          )}

          {!(!header.company_name || !header.fantasy_name) && (
            <tr>
              <Td>Dados da empresa:</Td>
              <Td>
                {fantasy_name ? ` ${fantasy_name}` : ''}
                {company_name ? ` - ${company_name}` : ''}
                {companyDocument ? `, ${companyDocument}` : ''}
                {address ? `, ${address}` : ''}
              </Td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export function Header() {
  const { header } = useReport();

  if (!header) return null;

  const formattedTitle = header.event_name?.split(/ - #/)[0];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        width: '100%',
        color: 'black',
        marginBottom: '1.5rem',
        pageBreakAfter: 'avoid',
      }}
    >
      <h1
        style={{
          fontSize: '1.875rem',
          fontWeight: 500,
          lineHeight: '1.25',
        }}
      >
        {formattedTitle}
      </h1>

      <ReportDetails />
    </div>
  );
}
