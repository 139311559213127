import React from 'react';

import Table from '@components/TableMini';
import { Container, Subtitle } from './styles';
import { TableExempleMainProps } from './interfaces';

const TableExemple: React.FC<TableExempleMainProps> = ({
  subtitle,
  options,
}) => {
  return (
    <Container>
      <Subtitle>{subtitle}</Subtitle>
      {options?.tableTemplate ? (
        <>
          <Table {...options?.tableTemplate} />
        </>
      ) : null}
    </Container>
  );
};

export default TableExemple;
