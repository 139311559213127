import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import config from '@/Constants';
import { useWorkspace } from '@/hooks/workspace';
import { validateEmail } from '@shared/validation';
import { useNavigation } from '@hooks/navigation';
import { FeedbackInfo } from '@/components/Feedback';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { HttpStatusCode } from '@/modules/shared/data/protocols/http';
import Main from './InformEmail';

const InformEmail: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const { navigate } = useNavigation();
  const { workspaceData } = useWorkspace();
  const { t } = useTranslation();

  const handleEmailChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const currentEmail: string = event.currentTarget.value;
      setEmail(currentEmail);
    },
    [],
  );

  const handleApiProccess = useCallback(async () => {
    const domain: string = workspaceData.subdomain;
    AxiosHelper.setDomain(domain);
    const { body, statusCode } = await AxiosHelper.post({
      url: config.PASSWORD_RECOVERY_URL,
      body: {
        email,
      },
    });
    if (statusCode === HttpStatusCode.ok) return true;
    FeedbackInfo({
      mainText: t('error.warning'),
      subText: body?.error
        ? t(`password_recovery.errors.${body.error.split('.')[0]}`)
        : t('error.unexpectedError'),
    });
    return false;
  }, [workspaceData.subdomain, email, t]);

  const handleEmailValidation = useCallback(async () => {
    try {
      await validateEmail(email);
      return true;
    } catch (err: any) {
      FeedbackInfo({
        mainText: err.errors[0],
        subText: t('error.verifyField'),
      });
    }
    return false;
  }, [email, t]);

  const handleSubmitEmail = useCallback(async () => {
    setLoading(true);
    const isValid: boolean = await handleEmailValidation();
    if (!isValid) {
      setLoading(false);
      return;
    }
    const hasSuccess: boolean = await handleApiProccess();
    setLoading(false);
    if (hasSuccess) navigate('code_sent', { email });
  }, [email, handleEmailValidation, handleApiProccess, navigate]);

  return (
    <Main
      email={email}
      handleEmailChange={handleEmailChange}
      handleSubmitEmail={handleSubmitEmail}
      isLoading={isLoading}
    />
  );
};

export default InformEmail;
