import React, { useEffect } from 'react';
import { Field } from '.';
import { useSurveyForm } from '../hooks';
import { IFieldDecorator } from '../domain/usecases';

/**
 *
 * @param children component to be passed down
 * @param question component to be rendered
 * @returns uncontrolled question input
 *
 * WARNING: THIS IS A EXPERIMENTAL FEATURE. TODO - AUTOMATED TESTING
 */
export const getFieldDecorator: IFieldDecorator = (question, children) => {
  const Wrapped = () => {
    const { registerField, unregisterField } = useSurveyForm();
    useEffect(() => {
      const { id, required } = question;
      registerField({ id, required });
      return () => {
        unregisterField(id);
      };
    }, [unregisterField, registerField]);
    return <Field question={question} render={(params) => children(params)} />;
  };

  return <Wrapped />;
};
