import { gql } from '@/gql';
import { getApolloInstance } from '@/modules/graphql';

const queryAutomationProperties = gql(`#graphql
  query AutomationProperties($filter: AutomationPropertyFilter) {
    automationProperties(filter: $filter) {
      id
      name
    }
  }
`);

export const fetchAutomationProperties = async (params: {
  search?: string;
  automationFlowId: string;
}) => {
  const apollo = getApolloInstance();
  const { data } = await apollo.query({
    query: queryAutomationProperties,
    variables: {
      filter: {
        ...params,
      },
    },
    fetchPolicy: 'network-only',
  });

  const items = data?.automationProperties || [];

  return {
    items,
    cursor: undefined,
  };
};
