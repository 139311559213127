import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: white;
  height: 98vh;
`;

// Header

export const Header = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 10px 20px;
`;

export const HeaderTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme }) => theme.colors.lighterText};
  font-size: 1.5em;
  font-weight: 500;
`;

// Content

export const ContainerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const Content = styled.div`
  margin-top: 20px;
  flex: 1;
  display: flex;
  padding: 20px;
`;
