import React from 'react';
import {
  Switch,
  RouteProps,
  useRouteMatch,
  Route,
  Redirect,
} from 'react-router-dom';

import AccessReactivation from '@/screens/dashManagement/AccessReactivation';

const DashAccessReactivationRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} component={AccessReactivation} exact />
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default DashAccessReactivationRoutes;
