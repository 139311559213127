import Config from '@/Constants';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { useToast } from '@chakra-ui/react';
import React from 'react';

import { IFormularyState } from '../types';

export function useFetchFormulary(formularyId: string) {
  const toast = useToast();

  const [isLoading, setIsValidating] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  const [data, setData] = React.useState<IFormularyState | undefined>();

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const { body } = await AxiosHelper.get<IFormularyState>({
          url: `${Config.QUESTIONS_URL}?formularyId=${formularyId}`,
        });

        setData(body);
      } catch (e) {
        if (e instanceof Error) {
          setIsError(true);
          toast({ title: 'Erro', description: e.message, status: 'error' });
        }
      } finally {
        setIsValidating(false);
      }
    };

    fetch();
  }, [formularyId, toast]);

  return { isLoading, isError, data };
}
