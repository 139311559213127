import config from '@/Constants';
import { ExporterServiceFactory, IExporterService } from '@/services/exporter';

const getExporter = (exportType: 'pdf' | 'xlsx' | 'csv' | 'zip' = 'pdf') => (
  screen: string,
): IExporterService => {
  const baseUrl = config.EXPORTER_URL;

  const instance = ExporterServiceFactory.getInstance({
    baseUrl,
    exportType,
    screen,
  });

  return instance;
};

export { getExporter };
