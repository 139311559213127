import { useLazyRef } from '@/utils/react-utils';
import React from 'react';
import { useWebFormStore } from '../data/formState';

export type FieldContextValue = {
  fieldId: string;
};

type FieldProps = {
  fieldId: string;
};

export const FieldContext = React.createContext<FieldContextValue>(
  {} as FieldContextValue,
);

/** todos os campos seriam filhos de Field */

const Field: React.FC<FieldProps> = (props) => {
  const { fieldId, children } = props;
  const { getState, subscribe } = useWebFormStore();

  const [visible, setVisible] = React.useState(
    () => !!getState().visibleFields?.[fieldId],
  );
  const prevVisible = useLazyRef(() => !!getState().visibleFields?.[fieldId]);

  const field = useLazyRef(() => getState().fields[fieldId]);

  React.useEffect(() => {
    const sub = subscribe((state) => {
      const nextVisible = state.visibleFields[fieldId];

      if (nextVisible !== prevVisible.current) {
        prevVisible.current = nextVisible;

        setVisible(nextVisible);
      }
    });

    return () => sub();
  }, [fieldId, prevVisible, subscribe]);

  if (!visible || !field) return null;

  return (
    <FieldContext.Provider key={fieldId} value={{ fieldId }}>
      {children}
    </FieldContext.Provider>
  );
};

export default Field;
