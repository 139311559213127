import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import { Badge, Flex, Radio, RadioGroup, VStack } from '@chakra-ui/react';
import React, { MouseEventHandler } from 'react';

import FormControlComponent from '../FormControlComponent';

const RadioContent: React.FC<QuestionModel> = ({ options }) => {
  const { error, onChange, fieldState, clear } = useField();

  const parsedValue = React.useMemo(() => {
    if (fieldState && !Array.isArray(fieldState)) {
      return fieldState.content;
    }
    return '';
  }, [fieldState]);

  const handleChange = React.useCallback(
    (newValue: string) => {
      onChange(createAnswer(newValue));
    },
    [onChange],
  );

  const handleUncheck: MouseEventHandler<HTMLDivElement> = React.useCallback(
    (event) => {
      const target = event.target as HTMLInputElement;
      if (target.value === parsedValue) clear();
    },
    [clear, parsedValue],
  );

  return (
    <Flex>
      <FormControlComponent isInvalid={error}>
        <RadioGroup
          size="lg"
          colorScheme="brand"
          value={parsedValue}
          onChange={handleChange}
          onClick={handleUncheck}
        >
          <VStack alignItems="flex-start">
            {options.map((o) => {
              return (
                <VStack alignItems="flex-start">
                  <Radio key={o.id} value={o.id}>
                    {o.name}
                  </Radio>
                  {o.alertMessage && parsedValue === o.id && (
                    <Badge
                      rounded="md"
                      w="min-content"
                      fontSize="md"
                      fontWeight="bold"
                      colorScheme="orange"
                      variant="outline"
                    >
                      {o.alertMessage}
                    </Badge>
                  )}
                </VStack>
              );
            })}
          </VStack>
        </RadioGroup>
      </FormControlComponent>
    </Flex>
  );
};

export default RadioContent;
