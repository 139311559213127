import { useQuery } from 'react-query';
import { ITemplateTasksListType } from '../types';
import { templateTasksIndexKey } from '../utils';

export function useTemplateTasksQuery(search?: string) {
  const query = useQuery<ITemplateTasksListType[]>([
    `${templateTasksIndexKey}${
      search?.length ? `?search=${encodeURIComponent(search)}` : ''
    }`,
  ]);
  return query;
}

export function useTemplateTaskQuery(id?: string) {
  const query = useQuery<ITemplateTasksListType>(
    `${templateTasksIndexKey}/${id}`,
    {
      enabled: !!id,
    },
  );
  return query;
}
