import config from '@/Constants';
import { EventStatus } from '@/modules/core/domain/interfaces';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import { getExecutionTimestamps } from '@/modules/WebForm/utils';

export type UpdateEventParams = {
  eventId: string;
  status?: EventStatus;
  processItemSyncState?: boolean;
  finishedAt?: number;
  memberId?: string;
  maintainExecution?: boolean;
};

export async function updateTaskEvent(
  params: UpdateEventParams,
): Promise<boolean> {
  const {
    eventId,
    finishedAt,
    status,
    processItemSyncState,
    memberId,
    maintainExecution,
  } = params;
  const {
    executionDate,
    executionTime,
    executionTimezone,
  } = getExecutionTimestamps();

  const body = {
    finished_at: finishedAt,
    process_item_sync_state: processItemSyncState,
    author_id: memberId,
    status,
  };

  const doneBody = {
    ...body,
    execution_date: executionDate,
    execution_time: executionTime,
    execution_time_zone: executionTimezone,
  };

  const { statusCode } = await AxiosHelper.patch({
    url: `${config.EVENTS_URL}/${eventId}`,
    body: maintainExecution ? body : doneBody,
  });
  if (statusCode < 200 || statusCode > 300) {
    throw new UnexpectedError();
  }
  return true;
}
