import moment from 'moment';
import React, { useCallback } from 'react';
import Calendar from 'react-calendar';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import { DatePickerProps } from './interfaces';
import { CalendarWrapper, Container } from './styles';

const Datepicker: React.FC<DatePickerProps> = ({
  type,
  onChangeDate,
  selectedDate,
  minDate,
  maxDate,
}) => {
  const { colors } = useTheme();

  const handleDateChange = useCallback((date: Date | Date[]) => {
    if (Array.isArray(date)) {
      return [date[0], new Date(date[1].toDateString())];
    }
    return [date];
  }, []);

  return (
    <Container>
      <CalendarWrapper>
        <Calendar
          selectRange={type === 'date_range'}
          formatShortWeekday={(locale, date) => moment(date).format('dd')}
          minDetail="year"
          minDate={minDate}
          maxDate={maxDate}
          nextLabel={<FiChevronRight width={12} color={colors.darkText} />}
          next2Label={null}
          prevLabel={<FiChevronLeft width={12} color={colors.darkText} />}
          prev2Label={null}
          value={selectedDate}
          onChange={(date) => onChangeDate(handleDateChange(date))}
          returnValue="range"
        />
      </CalendarWrapper>
    </Container>
  );
};

export default Datepicker;
