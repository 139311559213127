import { IconBaseProps } from 'react-icons';
import { GrLanguage } from 'react-icons/gr';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  border-radius: 50%;
  background-color: white;
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  border: none;
  color: var(--chakra-gray-800);

  cursor: pointer;
  :hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: start;
    border-radius: 0;
    padding: var(--chakra-space-3) 0;
    background-color: transparent;
    height: 24px;
    color: inherit;
    :hover {
      box-shadow: none;
    }
  }
`;

export const LanguageIcon = styled(GrLanguage)<IconBaseProps>`
  /* color: ${(props) => props.theme.colors.darkText}; */
  color: var(--chakra-gray-800);
  width: 100%;
`;
