import React from 'react';
import { RecurrenceProvider } from './hooks/useRecurrence';

import { Container, DropdownContainer } from './components/Common/styles';
import { TemplateSelector, DatePicker, CustomRecurrence } from './components';
import { RecurrenceGeneratorProps } from './interfaces/IRecurrenceGeneratorProvider';

const RecurrenceGenerator: React.FC<RecurrenceGeneratorProps> = (
  props: RecurrenceGeneratorProps,
) => {
  const { initialValues, onChange, viewOnly } = props;
  return (
    <RecurrenceProvider
      viewOnly={viewOnly}
      onChange={onChange}
      initialValues={initialValues}
    >
      <Container>
        <DatePicker />

        <TemplateSelector />

        <DropdownContainer>
          <CustomRecurrence />
        </DropdownContainer>
      </Container>
    </RecurrenceProvider>
  );
};
export default RecurrenceGenerator;
