import {
  ChakraTheme,
  theme as defaultTheme,
  extendTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import components from './components';
import { forms } from './forms';
import { layout } from './layout';
import { media } from './media';
import { semanticTokens } from './semanticTokens';
import { text } from './text';
import { colors } from './theme/colors';
import { shadows } from './theme/shadows';
import { fonts, fontSizes, textStyles } from './typography';
import { useColorValue } from './useColorValue';

interface ThemeProps {
  primaryColor: string;
}
export const theme = ({ primaryColor }: ThemeProps) => {
  return extendTheme(
    {
      colors,
      shadows: shadows(primaryColor),
      fontSizes,
      fonts,
      textStyles,
      container: {
        sm: '30em',
        md: '48em',
        lg: '62em',
        xl: '80em',
        '2xl': '96em',
      },
      config: {
        useSystemColorMode: false,
        initialColorMode: 'light',
      },
      styles: {
        global: (props) => ({
          ...defaultTheme.styles,
          body: {
            WebkitFontSmoothing: 'antialiased',
            TextRendering: 'optimizelegibility',
          },
          'body > #root': {
            height: '100vh',
            display: 'flex',
            'text-rendering': 'optimizedlegibility',
            '-webkit-font-smooth': 'antialiased',
          },
          '*': {
            boxSizing: 'border-box',
          },
          '*, *::before, &::after': {
            borderColor: mode('gray.200', 'whiteAlpha.200')(props),
            wordWrap: 'break-word',
          },
        }),
      },
      components: {
        ...layout,
        ...forms,
        ...media,
        ...text,
        ...components,
      },
      semanticTokens,
    } as Partial<ChakraTheme>,
    withDefaultColorScheme({
      colorScheme: 'primary',
      components: ['Radio', 'Switch', 'Checkbox'],
    }),
  );
};

export { useColorValue };

export default theme;
