import React from 'react';
import { TabSelectorProps } from './TabSelector.types';
import TabSelectorChakra from './TabSelector.Chakra';
import TabSelectorIos from './TabSelector.Ios';
import TabSelectorIosFull from './TabSelector.IosFull';
import TabSelectorMinimalist from './TabSelector.Minimalist';

function TabSelector<T extends string = string>({
  variant,
  ...rest
}: TabSelectorProps<T>): JSX.Element {
  switch (variant) {
    case 'ios':
      return <TabSelectorIos {...rest} />;
    case 'ios-full':
      return <TabSelectorIosFull {...rest} />;
    case 'minimalist':
      return <TabSelectorMinimalist {...rest} />;
    default:
      return <TabSelectorChakra {...rest} variant={variant} />;
  }
}

export default TabSelector;
