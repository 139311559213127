/* eslint-disable react/react-in-jsx-scope */
import BlockButton from '@/components_v2/Buttons/BlockButton';
import { BaseQuestion } from '@/components_v2/Formularies/BaseForm';
import RenderQuestions from '@/components_v2/Questions';
import {
  QuestionModel,
  Section,
} from '@/modules/core/domain/interfaces/IFormulary';
import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { orderSection } from './data';
import { CreateMainProps } from './interfaces';

const renderQuestion = (question: QuestionModel) => {
  return <BaseQuestion question={question} render={RenderQuestions} />;
};

const renderSection = (section: Section) => {
  const orderedSection = orderSection(section);
  const questions = orderedSection.questions
    .filter((q) => q.enabled)
    .map(renderQuestion);
  return questions;
};

const ExporterFormRender: React.FC<CreateMainProps> = ({
  sections,
  submitForm,
  buttonLabel,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {sections.map(renderSection)}
      <Box position="sticky" bottom={-2} bg="white" py="4">
        <Button
          isFullWidth
          colorScheme="primary"
          size="md"
          leftIcon={<DownloadIcon />}
          onClick={submitForm}
        >
          {`${t('common.export')} ${buttonLabel || t('common.table')}`}
        </Button>
      </Box>
    </>
  );
};

export default ExporterFormRender;
