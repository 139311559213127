import {
  Button,
  Flex,
  Icon,
  Text,
  useToast,
  UseToastOptions,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosNotifications } from 'react-icons/io';

export type WCNotificationToastProps = Partial<UseToastOptions> & {
  notificationType: 'initial' | 'user' | 'file';
  onClick?: () => void;
  onDismiss?: () => void;
};

const WCNotificationToast = React.memo((props: WCNotificationToastProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { onDismiss, onClick } = props;

  const handleOnDismiss = React.useCallback(() => {
    onDismiss?.();
    toast.close(props.id || '');
  }, [onDismiss, props.id, toast]);

  const handleOnClick = React.useCallback(() => {
    onClick?.();
    toast.close(props.id || '');
  }, [onClick, props.id, toast]);
  return (
    <Flex
      backdropFilter="blur(6px)"
      opacity={0.95}
      boxShadow="lg"
      rounded="2xl"
      bg="gray.50"
      borderWidth="1.5px"
      flexDir="row"
      alignItems="center"
      justify="space-between"
      gap="1"
      minH="62px"
      maxH="80px"
      maxW={{ base: 'full', md: '420px' }}
      position="relative"
    >
      <Flex gap="2" alignItems="center" p="3.5">
        <Flex h="full">
          <Flex
            rounded="xl"
            h="10"
            w="10"
            bg="green.100"
            alignItems="center"
            borderColor="green.200"
            borderWidth="thin"
            justifyContent="center"
          >
            <Icon as={IoIosNotifications} fontSize="18px" color="green.600" />
          </Flex>
        </Flex>
        <Flex flexDir="column">
          <Text fontWeight="bold" fontSize="lg" noOfLines={2}>
            {props.title}
          </Text>
          <Text wordBreak="break-all" noOfLines={2}>
            {props.description}
          </Text>
        </Flex>
      </Flex>
      <Flex
        minH="65px"
        w="fit-content"
        px="3"
        py="2"
        gap="2"
        flexDir="column"
        justifyContent="center"
      >
        <Button px="6" size="sm" colorScheme="primary" onClick={handleOnClick}>
          {t('common.view')}
        </Button>
        <Button
          onClick={handleOnDismiss}
          size="sm"
          colorScheme="gray"
          borderWidth="thin"
        >
          {t('common.close')}
        </Button>
      </Flex>
    </Flex>
  );
});

export function useNotificationToast() {
  const toast = useToast();

  return React.useCallback(
    (props: WCNotificationToastProps) => {
      toast({
        ...props,
        render: () => <WCNotificationToast {...props} />,
      });
    },
    [toast],
  );
}
