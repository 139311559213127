import React from 'react';

import { AuthProvider, AuthContext } from './auth';
import { UserPermissionsProvider } from './userPermissions';

export const AuthenticationProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <AuthContext.Consumer>
        {({ user }) => (
          <UserPermissionsProvider
            isOwner={user ? user.is_owner : false}
            permissions={user ? user.user_type : 'r'}
          >
            {children}
          </UserPermissionsProvider>
        )}
      </AuthContext.Consumer>
    </AuthProvider>
  );
};
