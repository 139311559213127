import { IFormularyState, IQuestionState } from '@/components_v2/WebForm/types';

export const getAllQuestions = (formulary: IFormularyState | undefined) => {
  return (
    formulary?.sections.reduce((prev, curr) => {
      const currQ = curr?.questions || [];
      return [...prev, ...currQ];
    }, [] as IQuestionState[]) || []
  );
};
