import {
  ActionItem,
  SearchInput,
  SeparatorLine,
} from '@/components_v2/ContextMenu';
import {
  ACTION_ITEM_HEIGHT,
  ITEM_MARGIN,
} from '@/components_v2/ContextMenu/constants';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';

import { CHECK_ALL_KEY } from './data';
import { MultiSelectionFilterRenderProps } from './interfaces';
import { Container } from './styles';
import { renderIcon } from './utils';

const Main: React.FC<MultiSelectionFilterRenderProps> = ({
  onAdd,
  onRemove,
  options,
  selectedOptions,
  type,
  onSearch,
}) => {
  const { t } = useTranslation();

  const renderSelecteds = useCallback(
    ({ index, style }) => {
      const { id, label } = selectedOptions[index];
      const text = id === CHECK_ALL_KEY ? t('filter.all.deselect') : label;
      return (
        <div id={id} style={style}>
          <ActionItem
            key={id}
            onClick={() => onRemove(id)}
            icon={renderIcon(type, true)}
            text={text}
          />
        </div>
      );
    },
    [type, onRemove, selectedOptions, t],
  );

  const renderOptions = useCallback(
    ({ index, style }) => {
      const { id, label } = options[index];
      const text = id === CHECK_ALL_KEY ? t('filter.all.select') : label;
      return (
        <div key={id} style={style}>
          <ActionItem
            onClick={() => onAdd(id)}
            icon={renderIcon(type, false)}
            text={text}
          />
        </div>
      );
    },
    [type, options, onAdd, t],
  );

  return (
    <Container>
      <SearchInput onSubmit={onSearch} />
      <List
        width="100%"
        style={{ overflow: 'hidden' }}
        height={selectedOptions.length * (ACTION_ITEM_HEIGHT + ITEM_MARGIN)}
        itemSize={ACTION_ITEM_HEIGHT + ITEM_MARGIN}
        itemCount={selectedOptions.length}
      >
        {renderSelecteds}
      </List>
      {selectedOptions.length > 0 && options.length > 0 && <SeparatorLine />}
      <List
        width="100%"
        style={{ overflow: 'hidden' }}
        height={options.length * (ACTION_ITEM_HEIGHT + ITEM_MARGIN)}
        itemSize={ACTION_ITEM_HEIGHT + ITEM_MARGIN}
        itemCount={options.length}
      >
        {renderOptions}
      </List>
    </Container>
  );
};

export default Main;
