import { gql } from '@/gql';
import { useQuery } from '@apollo/client';

export const AutomationProperties_FlowFragment = gql(`#graphql
  fragment AutomationProperties_FlowFragment on Property {
    id
    name
    propertyType
    position
    createdAt
    authorId
  }
`);

export const AutomationSteps_FlowFragment = gql(`#graphql
  fragment AutomationSteps_FlowFragment on Step {
    id
    name
    position
    stepType
    automationFlowId
  }
`);

export const AutomationFlowPropsFrag = gql(`#graphql
  fragment AutomationFlowPropsFrag on Flow {
    id
    name
    position
    automationProperties {
      ...AutomationProperties_FlowFragment
    }
    automationSteps {
      ...AutomationSteps_FlowFragment
    }
    accessSettings {
      teams {
        id
        name
      }
      users {
        id
        name
      }
    }
  }
`);

export const queryAutomationFlow = gql(`#graphql
  query AutomationFlow($automationFlowId: String!) {
    automationFlow(id: $automationFlowId) {
      id
      ...AutomationFlowPropsFrag
    }
  }
`);

export function useFetchFlow({ id }: { id?: string }) {
  const { data, loading } = useQuery(queryAutomationFlow, {
    variables: id
      ? {
          automationFlowId: id,
        }
      : undefined,
    skip: !id,
  });

  return { data: data?.automationFlow || null, loading };
}
