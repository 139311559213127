import TooltipComponent from '@/components/Tooltip';
import {
  InfoTooltip,
  TooltipContainer,
} from '@/components_v2/Questions/type/common';
import { withUploadProvider } from '@/hooks/upload/uploadProvider';
import { attachmentsStorage } from '@/modules/shared/adapters/CloudStorage';
import Field from '@/modules/WebForm/context/Field';
import { linkify } from '@/utils/linkify';
import { Flex, HStack, Link, Tag, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import useFormDetails from '../../hooks/useFormDetails';
import { IQuestionState } from '../../types';
import QuestionContent from '../QuestionContent';
import { QuestionItemProvider, useQuestionItem } from './context';
import { QuestionAppend } from './QuestionAppend';

type QuestionItemProps = {
  question: IQuestionState;
};

const Content = () => {
  const { question } = useQuestionItem();
  const { formulary } = useFormDetails();

  const { name, description, required } = question;

  const linkedDescription = description ? linkify(description) : null;

  return (
    <Flex
      w="full"
      marginTop="4"
      overflow="visible"
      boxShadow="initial"
      flexDir="column"
      borderWidth="thin"
      rounded="xl"
      bg="white"
      role="group"
    >
      <VStack spacing="1" align="flex-start" w="full">
        <Flex justify="space-between" align="flex-start" w="full" padding="4">
          <VStack w="full" pr="4" align="flex-start">
            <HStack alignItems="center" spacing="2.5">
              <Text
                color="darkText"
                fontWeight="bold"
                fontSize="15px"
                noOfLines={3}
                lineHeight={1}
              >
                {name}
              </Text>
              {question.tipText && (
                <TooltipComponent
                  description={question.tipText}
                  placement="top right"
                >
                  <TooltipContainer>
                    <InfoTooltip />
                  </TooltipContainer>
                </TooltipComponent>
              )}
            </HStack>
            <VStack spacing="3" align="flex-start" w="full">
              {linkedDescription && (
                <Text
                  maxW="90%"
                  color="gray.400"
                  fontWeight="bold"
                  fontSize="sm"
                  display="flex"
                >
                  {linkedDescription.map((part) =>
                    part.type === 'link' ? (
                      <Link key={part.key} href={part.value} isExternal mx={1}>
                        {part.value}
                      </Link>
                    ) : (
                      <Text key={part.key}>{part.value}</Text>
                    ),
                  )}
                </Text>
              )}
              {required && (
                <Tag size="md" colorScheme="orange">
                  Required
                </Tag>
              )}

              <Flex w="full">
                <QuestionContent {...question} />
              </Flex>
            </VStack>
          </VStack>
        </Flex>
        <QuestionAppend disableAttachements={formulary?.disableAttachments} />
      </VStack>
    </Flex>
  );
};

const Wrapper: React.FC<QuestionItemProps> = ({ question }) => {
  return (
    <Field fieldId={question.id}>
      <QuestionItemProvider question={question}>
        <Content />
      </QuestionItemProvider>
    </Field>
  );
};

export const QuestionItem = withUploadProvider(attachmentsStorage)(Wrapper);
