import { gql } from '@/gql';
import { AnswersUpdateInBatchInput } from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import React from 'react';

import { queryAutomationAnswer } from './useFetchAnswer';

export const answerUpdateInBatchMutation = gql(`#graphql
  mutation AnswersUpdateInBatch($input: AnswersUpdateInBatchInput!) {
    answersUpdateInBatch(input: $input) {
      errors
    }
  }
`);

export function useAnswerUpdateInBatch() {
  const toast = useToast();
  const [mutate, payload] = useMutation(answerUpdateInBatchMutation, {
    refetchQueries: [queryAutomationAnswer],
  });

  const answerUpdateInBatch = React.useCallback(
    async (input: AnswersUpdateInBatchInput) => {
      const result = await mutate({
        variables: {
          input,
        },
      });

      const { data, errors } = result;

      if (data || errors) {
        toast({
          title: errors ? 'Edição da resposta falhou.' : 'Resposta alterada.',
          status: errors ? 'error' : 'success',
        });
      }
      return result;
    },
    [mutate, toast],
  );

  return {
    answerUpdateInBatch,
    ...payload,
  };
}
