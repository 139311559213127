import { TicketDashboard } from '@/screens/Tickets';
// import { ItemViewRoute } from '@/screens/Tickets/ItemView';
import { withLayout } from '@/screens/utils/AppLayout';
import React from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';

const FlowProxy = React.lazy(() => import('@/screens/Tickets/utils/FlowProxy'));

const WorkflowRoute: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={withLayout(TicketDashboard)} />
    </Switch>
  );
};

const WorkflowRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={withLayout(FlowProxy)} />
      <Route path={`${path}/:id`} component={WorkflowRoute} />
    </Switch>
  );
};

export default WorkflowRoutes;
