import { gql } from '@/gql';
import { FlowsUpdateInput } from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import React from 'react';

import { queryAutomationGroups } from './useFetchAutomationGroups';

export const updateAutomationGroupMutation = gql(`#graphql
 mutation UpdateAutomationGroup($input: GroupsUpdateInput!) {
  updateAutomationGroup(input: $input) {
    errors
    automationGroup {
      id
      name
      position
      deletedAt
      createdAt
      updatedAt
    }
  }
}
`);

export function useUpdateAutomationGroup() {
  const toast = useToast();
  const [mutate, payload] = useMutation(updateAutomationGroupMutation, {
    refetchQueries: [queryAutomationGroups],
    awaitRefetchQueries: true,
  });

  const updateAutomationGroup = React.useCallback(
    async (input: FlowsUpdateInput) => {
      const result = await mutate({
        variables: {
          input,
        },
      });

      const { data, errors } = result;

      if (data || errors) {
        toast({
          title: errors ? 'Erro ao atualizar grupo' : 'Grupo atualizado.',
          status: errors ? 'error' : 'success',
        });
      }
      return result;
    },
    [mutate, toast],
  );

  return {
    updateAutomationGroup,
    ...payload,
  };
}
