const devMode = process.env.NODE_ENV === 'development';
const subdomainBlacklist = process.env.REACT_APP_SUBDOMAIN_BLACKLIST
  ? process.env.REACT_APP_SUBDOMAIN_BLACKLIST.split(' ')
  : ['www'];

export function getSubdomain(): string {
  let subdomain = '';

  try {
    const { host } = window.location;

    const parts = host.split('.');

    if (parts.length && subdomainBlacklist.includes(parts[0])) return '';

    if (devMode) {
      if (parts.length > 1) {
        const localHost =
          parts[1].split(':').length > 0 && parts[1].split(':')[0];
        if (localHost && localHost === 'localhost') {
          [subdomain] = parts;
        }
      }

      if (subdomain.length) {
        return subdomain;
      }
    }

    if (parts.length >= 3) {
      const [sub] = parts;
      subdomain = sub;
      // Remove the subdomain from the parts list
      parts.splice(0, 1);
      // Set the location to the new url
    }

    return subdomain;
  } catch (e) {
    return subdomain;
  }
}
