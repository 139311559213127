/* eslint-disable radix */

import TimePickerInput from '@/components_v2/TimePicker';
import { parseDateMillisSinceMidnight } from '@/components_v2/TimePicker/utils/TimeUtils';
import { milisSinceMidnight } from '@/utils/Date/milisSinceMidnight';
import Tooltip from '@components/Tooltip';
import React, { useMemo } from 'react';

import {
  InfoTooltip,
  QuestionContainer,
  QuestionHeader,
  QuestionSubtitle,
  QuestionTitle,
  TooltipContainer,
} from '../common';
import { TimePickerProps } from './interfaces';

const TimePicker: React.FC<TimePickerProps> = ({
  question,
  errors,
  initialValues,
  onChange,
  viewOnly = false,
}) => {
  const parsedInitialValues = initialValues
    ? parseInt(initialValues.values)
    : 0;
  const onChangeDate = (newDate: Date | null) => {
    if (newDate) {
      const val = milisSinceMidnight(newDate);
      onChange(val);
    }
  };

  const parsedDate = useMemo(
    () => parseDateMillisSinceMidnight(parsedInitialValues),
    [parsedInitialValues],
  );

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionTitle>{question.name}</QuestionTitle>
        {question.tipText && (
          <Tooltip description={question.tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      <TimePickerInput
        disabled={viewOnly}
        hasError={!!errors}
        initialValues={parsedDate}
        onChange={onChangeDate}
      />
      <QuestionSubtitle hasError={!!errors}>
        {errors && errors.errors}
      </QuestionSubtitle>
    </QuestionContainer>
  );
};

export default TimePicker;
