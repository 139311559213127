import config, { DOMAIN_PLACEHOLDER } from '@/Constants';
import { HttpStatusCode } from '@/modules/shared/data/protocols/http';
import { getSubdomain } from '@/utils/getSubdomain';
import { setSubdomain as setUrlSubdomain } from '@/utils/setSubdomain';
import Axios from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useSWR from 'swr';

import { Workspace, WorkspaceContextData } from './interfaces/workspace';

const getWorkspacesURL = (sub: string) =>
  `${config.BASE_URL.replace(DOMAIN_PLACEHOLDER, sub)}v1/${
    config.WORKSPACES_URL
  }`;

export const WorkspaceContext = createContext<WorkspaceContextData>(
  {} as WorkspaceContextData,
);

const subdomainFromHost = getSubdomain();

export const WorkspaceProvider: React.FC = ({ children }) => {
  const [subdomain, setCurrentSubdomain] = useState(subdomainFromHost);

  const { data: currentWorkspace, isValidating } = useSWR<Workspace>(
    getWorkspacesURL(subdomainFromHost),
    async (url): Promise<Workspace> => {
      if (!subdomainFromHost || subdomainFromHost.length === 0) {
        return {} as Workspace;
      }
      const { data, status } = await Axios.get<Workspace>(url);

      if (status === HttpStatusCode.ok) {
        return data;
      }
      return {} as Workspace;
    },
    {
      revalidateOnFocus: false,
    },
  );

  const setSubdomain = useCallback((sub: string) => {
    setCurrentSubdomain(sub);
    setTimeout(() => {
      setUrlSubdomain(sub);
    }, 1);
  }, []);

  const getRemoteWorkspace = useCallback(async (sub: string): Promise<
    Workspace
  > => {
    try {
      const { data, status } = await Axios.get<Workspace>(
        getWorkspacesURL(sub),
      );

      if (status === HttpStatusCode.ok) {
        return data;
      }
      return {} as Workspace;
    } catch (e) {
      return {} as Workspace;
    }
  }, []);

  useEffect(() => {
    if (subdomain && subdomain.length > 0) {
      document.title = subdomain;
    }
  }, [subdomain]);

  if (subdomainFromHost && subdomainFromHost.length > 0 && !currentWorkspace)
    return null;

  return (
    <WorkspaceContext.Provider
      value={{
        loading: isValidating,
        subdomain,
        setSubdomain,
        getRemoteWorkspace,
        workspaceData: currentWorkspace || ({} as Workspace),
        validWorkspace: !!(currentWorkspace && currentWorkspace.id),
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export function useWorkspace(): WorkspaceContextData {
  const context = useContext(WorkspaceContext);

  if (!context) {
    throw new Error('useWorkspace must be used whithin an WorkspaceProvider');
  }

  return context;
}
