import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import config from '@/Constants';

async function readAllService(id: string): Promise<void> {
  AxiosHelper.patch({
    url: `${config.USERS_URL}/${id}`,
    body: {
      read_notifications: true,
    },
  });
}

export { readAllService };
