export type SignoffQuestionMeta = Record<string, string | string[]>;

export type Criticality = {
  score: number;
  label: string;
  color: string;
  stockable?: boolean;
};

export type TCriticalityMatrix = Criticality[][];

const nonCritical: Criticality = {
  score: 0,
  label: 'Não crítico',
  color: '#62CF87',
};

const low: Criticality = {
  score: 1,
  label: 'Baixa criticidade',
  color: '#00B050',
};

const medium: Criticality = {
  score: 2,
  label: 'Média criticidade',
  color: '#D0D00D',
  stockable: true,
};

const high: Criticality = {
  score: 3,
  label: 'Alta criticidade',
  color: '#997507',
  stockable: true,
};

const extraHigh: Criticality = {
  score: 4,
  label: 'Estratégico de alta Criticidade',
  color: '#CC0F0F',
  stockable: true,
};

export const criticalityMap = {
  nonCritical,
  low,
  medium,
  high,
  extraHigh,
};

const criticalityMatrix: TCriticalityMatrix = [
  // catastrófico
  [
    criticalityMap.medium,
    criticalityMap.high,
    criticalityMap.high,
    criticalityMap.extraHigh,
    criticalityMap.extraHigh,
  ],
  // crítico
  [
    criticalityMap.low,
    criticalityMap.medium,
    criticalityMap.high,
    criticalityMap.high,
    criticalityMap.extraHigh,
  ],
  // moderado
  [
    criticalityMap.low,
    criticalityMap.medium,
    criticalityMap.medium,
    criticalityMap.high,
    criticalityMap.high,
  ],
  // marginal
  [
    criticalityMap.nonCritical,
    criticalityMap.low,
    criticalityMap.medium,
    criticalityMap.medium,
    criticalityMap.high,
  ],
  [
    criticalityMap.nonCritical,
    criticalityMap.nonCritical,
    criticalityMap.low,
    criticalityMap.low,
    criticalityMap.medium,
  ],
];

type CriticalityResultProps = {
  probability?: number;
  severity?: number;
};

const resultCache: WeakMap<CriticalityResultProps, Criticality> = new WeakMap();

export const getCriticalityResult = (params: CriticalityResultProps) => {
  const { probability, severity } = params;
  if (
    typeof probability === 'undefined' ||
    typeof severity === 'undefined' ||
    Number.isNaN(probability) ||
    Number.isNaN(severity)
  ) {
    return undefined;
  }
  // const cachedValue = resultCache.get(params);

  // if (cachedValue) return cachedValue;

  const result = criticalityMatrix[severity][probability];

  // resultCache.set(params, result);

  return result;
};
