import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import { Badge, Flex } from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import React from 'react';

import FormControlComponent from '../FormControlComponent';

const RadioComboContent: React.FC<QuestionModel> = ({ options }) => {
  const { error, onChange, fieldState, clear } = useField();

  const [value, setValue] = React.useState<SelectOption | null>(() => {
    if (fieldState && !Array.isArray(fieldState)) {
      const option = options.find((o) => o.id === fieldState.content);
      return option
        ? { label: option.name, value: option.id, message: option.alertMessage }
        : null;
    }
    return null;
  });

  const handleChange = React.useCallback(
    (newValue: SingleValue<SelectOption>) => {
      if (newValue && newValue.value === value?.value) {
        clear();
        setValue(null);
      } else {
        setValue(newValue || null);
        onChange(createAnswer(newValue ? newValue.value : null));
      }
    },
    [clear, onChange, value?.value],
  );

  return (
    <Flex w="full" flexDir="column">
      <FormControlComponent isInvalid={error}>
        <Select
          // menuPortalTarget={document.body}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 1600 }),
          }}
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              flex: 1,
              maxWidth: '300px',
            }),
          }}
          onChange={handleChange}
          isMulti={false}
          options={options.map((o) => ({
            label: o.name,
            value: o.id,
            message: o.alertMessage,
          }))}
          value={value}
          colorScheme="brand"
        />
      </FormControlComponent>
      {value?.message && (
        <Badge
          rounded="md"
          w="min-content"
          fontSize="md"
          fontWeight="bold"
          colorScheme="orange"
          variant="outline"
          marginTop={4}
        >
          {value.message}
        </Badge>
      )}
    </Flex>
  );
};

export default RadioComboContent;
