import { checklistServices } from '@/modules/shared/adapters/ApiServices';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import React from 'react';
import { useMutation } from 'react-query';

import { ChecklistSummary } from '../../Checklists/interfaces';

export function useUpdateEvent() {
  const mutation = useMutation(
    async (data: PartialWithId<ChecklistSummary>) => {
      const response = await checklistServices.update({
        ids: [data.id],
        data,
        updateAll: false,
      });
      if (response.statusCode < 200 || response.statusCode >= 300) {
        throw new UnexpectedError();
      }
      return response;
    },
  );
  return mutation;
}
