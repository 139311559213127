const phoneStrToNumber = (phoneStr: string): string =>
  phoneStr.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

const phoneValidator = (phoneNumber: string): boolean => {
  if (phoneNumber.length < 11) return false;
  return /^[0-9]*$/.test(phoneNumber);
};

const phoneParser = (phoneSrt: string): string => {
  const phoneNumber = phoneStrToNumber(phoneSrt);
  const phoneStr = Array.from(phoneNumber).reduce(
    (acc: string, cur: string) => {
      switch (acc.length) {
        case 0:
          return `(${cur}`;
        case 3:
          return `${acc}) ${cur}`;
        case 10:
          return `${acc}-${cur}`;
        default:
          return `${acc}${cur}`;
      }
    },
    '',
  );
  return phoneStr;
};

export default { phoneParser, phoneValidator, phoneStrToNumber };
