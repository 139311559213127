/* eslint-disable no-case-declarations */
import {
  AsyncDropdownState,
  AsyncDropdownReducer,
} from '../../interfaces/IAsyncDropdown';

export const initialState: AsyncDropdownState = {
  options: [],
  page: 1,
  isLoading: false,
  hasNext: true,
  selected: [],
  searchParams: '',
};

export const asyncDropdownReducer: AsyncDropdownReducer = (state, action) => {
  switch (action.type) {
    case 'set_loading':
      return {
        ...state,
        isLoading: action.props,
      };

    case 'fetch_start':
      if (state.isLoading || !state.hasNext) return state;
      const nextPage = state.page + 1;
      return {
        ...state,
        page: nextPage,
        isLoading: true,
      };
    case 'fetch_success':
      const { options: newOptions, hasNext } = action.props;
      return {
        ...state,
        hasNext,
        isLoading: false,
        options: [...state.options, ...newOptions],
      };
    case 'fetch_fail':
      return {
        ...state,
        isLoading: false,
      };
    case 'change_search_params':
      return {
        ...state,
        searchParams: action.props,
        page: 1,
      };
    default:
      return state;
  }
};
