import React from 'react';

import Button from '@/components/Button';
import { DownloadFileMainProps } from './interfaces';
import { Container } from './styles';

const DownloadFile: React.FC<DownloadFileMainProps> = ({
  options,
  onDownload,
}) => {
  return (
    <Container>
      <Button
        text={options?.buttonLabel}
        callback={() => onDownload()}
        type="GREY_BACKGOUND"
        icon="DOWNLOAD"
        styleTitle={{ fontSize: '0.8em' }}
      />
    </Container>
  );
};

export default DownloadFile;
