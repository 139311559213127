import React from 'react';

import { useInspectionStore } from '../context/store';

export default function useCurrentSection() {
  const { formulary, isLoading, currentSection } = useInspectionStore(
    React.useCallback(
      ({ formulary, isLoading, currentSection }) => ({
        formulary,
        isLoading,
        currentSection,
      }),
      [],
    ),
  );

  const curr = React.useMemo(() => {
    if (!currentSection?.length || !formulary?.sections?.length)
      return undefined;

    return formulary?.sections.filter((el) => el.id === currentSection)?.[0];
  }, [currentSection, formulary?.sections]);

  return {
    currentSection: curr,
    isLoading,
  };
}
