import {
  collectionServices,
  formularyServices,
  localServices,
  scheduleServices,
  taskServices,
  userServices,
} from '@shared/adapters/ImporterService';
import { ImportTypeEnum } from '@/modules/shared/domain/interfaces';
import BaseImporterService from '@/modules/shared/data/usecases/BaseImporterService';

const getApiServices = (screen: ImportTypeEnum): BaseImporterService | null => {
  switch (screen) {
    case 'collection':
      return collectionServices;
    case 'formulary':
      return formularyServices;
    case 'local':
      return localServices;
    case 'schedule':
      return scheduleServices;
    case 'user':
      return userServices;
    case 'task':
      return taskServices;
    default:
      return null;
  }
};

export { getApiServices };
