import { HttpResponse } from '@/modules/shared/data/protocols/http';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';
import snakeToCamel from './snakeToCamel';

type MethodTypes = 'post' | 'patch' | 'delete';

export const makeMutationFetcher = <T, R>(
  url: string,
  method: MethodTypes = 'post',
) => async (params: T): Promise<HttpResponse<R>> => {
  const response = await AxiosHelper[method]({
    url,
    body: params,
  });

  if (response.statusCode < 200 || response.statusCode >= 300) {
    throw new UnexpectedError();
  }

  const body: R = snakeToCamel(response?.body);

  return { ...response, body };
};
