/* eslint-disable react/no-array-index-key */
import { EventReportTableData } from './types';

export function Table({ report }: { report?: EventReportTableData }) {
  const header = [
    'Unidades',
    'Data da Visita',
    'Seção',
    'Pergunta',
    'Resposta',
    'Comentário',
    'Prazo para resposta da Fundação Saúde',
    'Resposta da Fundação Saúde',
    'Data da Resposta da FS',
    'Nova Resposta da FS',
    'Data da Nova Resposta da FS',
    'Análise da resposta',
    'Data Reiteração',
    'Resposta da Fundação Saúde à Reiteração',
    'Análise da resposta à Reiteração',
  ];

  return (
    <div style={{ width: '100%' }}>
      <table
        style={{
          minWidth: '100%',
          tableLayout: 'fixed',
          border: '1px solid #E5E7EB',
          backgroundColor: '#FFFFFF',
        }}
      >
        <thead
          style={{
            backgroundColor: '#E5E7EB',
            fontSize: '0.65rem',
            textTransform: 'uppercase',
            lineHeight: '1.5',
            color: '#4B5563',
          }}
        >
          <tr>
            <th
              style={{
                width: '3.23%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[0]}
            </th>
            <th
              style={{
                width: '3.23%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[1]}
            </th>
            <th
              style={{
                width: '6.45%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[2]}
            </th>
            <th
              style={{
                width: '16.13%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[3]}
            </th>
            <th
              style={{
                width: '3.23%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[4]}
            </th>
            <th
              style={{
                width: '9.68%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[5]}
            </th>
            <th
              style={{
                width: '6.45%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[6]}
            </th>
            <th
              style={{
                width: '9.68%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[7]}
            </th>
            <th
              style={{
                width: '3.23%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[8]}
            </th>
            <th
              style={{
                width: '9.68%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[9]}
            </th>
            <th
              style={{
                width: '3.23%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[10]}
            </th>
            <th
              style={{
                width: '3.23%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[11]}
            </th>
            <th
              style={{
                width: '3.23%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[12]}
            </th>
            <th
              style={{
                width: '9.68%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[13]}
            </th>
            <th
              style={{
                width: '3.23%',
                padding: '0.75rem 0.5rem',
                textAlign: 'center',
              }}
            >
              {header[14]}
            </th>
          </tr>
        </thead>
        <tbody
          style={{ fontSize: '0.75rem', fontWeight: 300, color: '#4B5563' }}
        >
          {report?.body?.map((bodyItem, index) => (
            <tr
              key={index}
              style={{
                breakInside: 'avoid',
                borderBottom: '1px solid #E5E7EB',
              }}
            >
              <td
                style={{
                  width: '3.23%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[0]}
              </td>
              <td
                style={{
                  width: '3.23%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[1]}
              </td>
              <td
                style={{
                  width: '6.45%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[2]}
              </td>
              <td
                style={{
                  width: '16.13%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[3]}
              </td>
              <td
                style={{
                  width: '3.23%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[4]}
              </td>
              <td
                style={{
                  width: '9.68%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[5]}
              </td>
              <td
                style={{
                  width: '6.45%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[6]}
              </td>
              <td
                style={{
                  width: '9.68%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[7]}
              </td>
              <td
                style={{
                  width: '3.23%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[8]}
              </td>
              <td
                style={{
                  width: '9.68%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[9]}
              </td>
              <td
                style={{
                  width: '3.23%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[10]}
              </td>
              <td
                style={{
                  width: '3.23%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[11]}
              </td>
              <td
                style={{
                  width: '3.23%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[12]}
              </td>
              <td
                style={{
                  width: '9.68%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[13]}
              </td>
              <td
                style={{
                  width: '3.23%',
                  padding: '0.75rem 0.5rem',
                  textAlign: 'center',
                }}
              >
                {bodyItem[14]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
