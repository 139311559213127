import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';
import { BiPlus } from 'react-icons/bi';

export const AddButton: React.FC<Partial<IconButtonProps>> = (props) => {
  return (
    <IconButton
      aria-label="add"
      icon={<BiPlus size={16} />}
      size="sm"
      {...props}
    />
  );
};
