import React, {
  createContext,
  useState,
  useContext,
  useReducer,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import debounce from '@/utils/debounce';
import {
  FrequencyType,
  WeekDayEnum,
} from '@/modules/core/domain/interfaces/IRecurrence';
import {
  initialState,
  recurrenceGeneratorReducer,
} from '../utils/recurrenceGeneratorReducer';
import {
  DateProps,
  RecurrenceTemplates,
} from '../interfaces/IRecurrenceTemplates';
import {
  RecurrenceContextData,
  RecurrenceGeneratorProps,
} from '../interfaces/IRecurrenceGeneratorProvider';

export const RecurrenceContext = createContext<RecurrenceContextData>(
  {} as RecurrenceContextData,
);

export const RecurrenceProvider: React.FC<RecurrenceGeneratorProps> = ({
  children,
  initialValues,
  onChange = () => null,
  viewOnly = false,
}) => {
  const initialized = useRef(false);
  const init = initialValues || initialState;
  const [state, dispatch] = useReducer(recurrenceGeneratorReducer, init);
  const [debouncedChangeTemplate] = useState(() => {
    return debounce((value: string) => {
      dispatch({
        type: 'change_template',
        props: value as RecurrenceTemplates,
      });
    }, 200);
  });

  const handleChangeDate = useCallback((params: DateProps) => {
    dispatch({ type: 'change_date_range', props: params });
  }, []);

  const [debouncedChangeInterval] = useState(() => {
    return debounce((params: number) => {
      dispatch({ type: 'change_interval', props: params });
    }, 10);
  });

  const handleChangeRule = useCallback((params: FrequencyType) => {
    dispatch({ type: 'change_rule', props: params });
  }, []);

  const handleChangeWeekdays = useCallback((params: WeekDayEnum) => {
    dispatch({ type: 'change_week_days', props: params });
  }, []);

  useEffect(() => {
    if (initialized.current) {
      onChange(state);
    }
    initialized.current = true;
  }, [state]);

  return (
    <RecurrenceContext.Provider
      value={{
        state,
        onChangeDate: handleChangeDate,
        onChangeTemplate: debouncedChangeTemplate,
        onChangeRule: handleChangeRule,
        onChangeInterval: debouncedChangeInterval,
        onChangeWeekdays: handleChangeWeekdays,
        viewOnly,
      }}
    >
      {children}
    </RecurrenceContext.Provider>
  );
};

export function useRecurrence(): RecurrenceContextData {
  const context = useContext(RecurrenceContext);

  if (!context) {
    throw new Error('useRecurrence must be used whithin an RecurrenceProvider');
  }

  return context;
}
