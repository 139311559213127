import React from 'react';

import Main from './Main';
import { ButtonProps } from './interfaces';

const Button: React.FC<ButtonProps> = (props) => {
  return <Main {...props} />;
};

export default Button;
