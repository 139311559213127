import * as React from 'react';

/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 *
 * @param children the children
 */
export function getValidChildren(children: React.ReactNode) {
  return React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  ) as React.ReactElement[];
}

/**
 * Get the first child of a specific type.
 * @param children The children
 * @param type The component type
 */
export function getChildOfType(
  children: React.ReactNode,
  type: React.JSXElementConstructor<any>,
) {
  return (React.Children.toArray(children) as React.ReactElement[]).find(
    (item) => item.type === type,
  );
}

/**
 * Get all children of a specific type.
 * @param children The children
 * @param type The component type
 */
export function getChildrenOfType(
  children: React.ReactNode,
  type: React.JSXElementConstructor<any> | React.JSXElementConstructor<any>[],
) {
  return (React.Children.toArray(
    children,
  ) as React.ReactElement[]).filter((item) =>
    Array.isArray(type)
      ? type.some((component) => component === item.type)
      : item.type === type,
  );
}
