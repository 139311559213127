import React from 'react';
import styled from 'styled-components';

interface TableStyleProps {
  columnsCount?: number;
  backgroundColor?: string;
  rowHeight?: number;
  maxHeight?: number;
  hasBorder?: boolean;
}

export const Container = styled.div<TableStyleProps>`
  flex: 1;
  overflow-y: auto;
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}

  display: grid;
  grid-template-columns: ${({ columnsCount }) =>
    `repeat(${columnsCount}, auto)`};
`;

export const CellContainer = (
  params?: TableStyleProps,
): React.CSSProperties => {
  const css: React.CSSProperties = {
    padding: 8,
  };
  if (params?.rowHeight) css.height = params?.rowHeight;
  if (params?.hasBorder) css.boxShadow = 'inset 0px -1px 0px #EBEBEB';
  return css;
};
