import config from '@/Constants';
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useCreateAction } from '@/hooks/useCreateAction';
import { createContext } from '@/utils/react-utils';
import { AddIcon, ChevronDownIcon, LinkIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { useQueryClient } from 'react-query';

import { useEventSummary } from '../../hooks/useEventSummary';
import { useInteractions } from '../../hooks/useInteractions';
import { useSelectedForm } from '../../hooks/useSelectedForm';
import { useUpdateQuestionNote } from '../../hooks/useUpdateQuestionNote';
import { ItemQuestion, ItemSection } from '../../interfaces';
import { QuestionActions } from './QuestionActions';
import { QuestionAttachmentsPane } from './QuestionAttachmentsPane';
import { QuestionContent } from './QuestionContent/QuestionContent';
import { QuestionFeedback } from './QuestionFeedback';

type QuestionContextProps = {
  paneOpen: 'actions' | 'attachments' | null;
  setQuestion: React.Dispatch<React.SetStateAction<ItemQuestion>>;
  togglePane: (pane: 'actions' | 'attachments') => void;
  question: ItemQuestion;
  section: ItemSection;
};

const [QuestionContext, useQuestionContext] = createContext<
  QuestionContextProps
>({
  name: 'QuestionContext',
});

const QuestionAppend: React.FC = () => {
  const queryClient = useQueryClient();
  const { question, paneOpen, togglePane } = useQuestionContext();
  const [countFlows, setCountFlows] = React.useState(question?.countFlows || 0);

  const onToggleActions = React.useCallback(() => togglePane('actions'), [
    togglePane,
  ]);

  const actionsOpen = React.useMemo(() => paneOpen === 'actions', [paneOpen]);

  const hasFlaggedItems = React.useMemo(
    () => question.answers.some((e) => e.isFlagged),
    [question.answers],
  );

  const createAction = useCreateAction();

  const { event } = useEventSummary();
  const eventId = event?.id || '';

  const handleActionButton = React.useCallback(() => {
    createAction(
      {
        questionId: question.id,
        eventId,
      },
      async () => {
        await queryClient.fetchQuery(
          `${config.EVENTS_URL}/${eventId}/flows/${question.id}`,
        );
        setCountFlows((old) => old + 1);
        if (!actionsOpen) onToggleActions?.();
      },
    );
  }, [
    actionsOpen,
    createAction,
    eventId,
    onToggleActions,
    queryClient,
    question.id,
  ]);

  return (
    <Flex borderTopWidth="thin" p="3.5" pt="2" w="full" flexDir="column">
      <QuestionLastComment />
      <Flex mt="1.5" w="full" justifyContent="space-between">
        <QuestionFeedback question={question} />
        <ButtonGroup>
          {countFlows ? (
            <Button
              variant="outline"
              size="md"
              onClick={onToggleActions}
              rightIcon={<ChevronDownIcon fontSize="20px" />}
            >
              {countFlows} Ações
            </Button>
          ) : (
            event?.status !== 'done' && (
              <Button
                colorScheme={hasFlaggedItems ? 'orange' : 'gray'}
                variant={hasFlaggedItems ? 'solid' : 'outline'}
                size="md"
                onClick={handleActionButton}
                rightIcon={<AddIcon />}
              >
                Nova Ação
              </Button>
            )
          )}
          <Button
            colorScheme="gray"
            variant="outline"
            size="md"
            onClick={() => togglePane('attachments')}
            rightIcon={<LinkIcon />}
          >
            {question.countAttachments
              ? `${question.countAttachments} ${
                  question.countAttachments > 1 ? 'Anexos' : 'Anexo'
                }`
              : 'Anexos'}
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  );
};

function QuestionNoteEdit({ toggleEditing }: { toggleEditing: () => void }) {
  const toast = useToast();
  const { question, setQuestion } = useQuestionContext();
  const [value, setValue] = React.useState(question.comment);
  const { mutateAsync, isLoading } = useUpdateQuestionNote();
  const { selectedItemId } = useInteractions();
  const onSubmit = React.useCallback(async () => {
    if (!selectedItemId) return;
    try {
      const res = await mutateAsync({
        note: value,
        item_id: selectedItemId,
        question_id: question.id,
      });

      if (res) {
        setQuestion((old) => ({
          ...old,
          comment: value,
        }));
        toggleEditing();
        toast({
          title: 'Nota atualizada',
          status: 'success',
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Error',
          description: e.message,
          status: 'error',
        });
        toggleEditing();
      }
    }
  }, [
    mutateAsync,
    question.id,
    selectedItemId,
    setQuestion,
    toast,
    toggleEditing,
    value,
  ]);

  return (
    <HStack>
      <Input
        onChange={(e) => setValue(e.target.value)}
        value={value}
        defaultValue={question.comment}
      />
      <Button
        isLoading={isLoading}
        colorScheme="brand"
        onClick={() => onSubmit()}
        size="md"
      >
        Salvar
      </Button>
      <Button isLoading={isLoading} onClick={() => toggleEditing()} size="md">
        Cancelar
      </Button>
    </HStack>
  );
}

const QuestionNotes: React.FC = () => {
  const { question } = useQuestionContext();
  const [isEditing, setIsEditing] = React.useState(false);
  const { checkPermission } = useUserPermissions();
  return (
    <Flex flexDir="column" gap="1" hidden={!question.comment}>
      <Text fontWeight="bold">Notas:</Text>
      {isEditing ? (
        <QuestionNoteEdit toggleEditing={() => setIsEditing((old) => !old)} />
      ) : (
        <HStack>
          <Flex
            p="2"
            textAlign="start"
            minH="10"
            rounded="lg"
            w="full"
            bg="gray.100"
            overflow="auto"
          >
            {question.comment}
          </Flex>
          {checkPermission('rw') && (
            <Button onClick={() => setIsEditing(true)} size="md">
              Editar nota
            </Button>
          )}
        </HStack>
      )}
    </Flex>
  );
};

const QuestionLastComment: React.FC = () => {
  const { question } = useQuestionContext();

  const { discussionTopic } = question;
  if (!discussionTopic) return null;
  const { lastComment } = discussionTopic;
  return (
    <Flex flexDir="column" hidden={!lastComment}>
      <Text fontWeight="bold" color="darkText">
        Último comentário:
      </Text>
      <HStack>
        <Text fontWeight="bold" color="muted">
          {lastComment?.authorName}
        </Text>
        <Text noOfLines={2} color="muted">
          {lastComment?.content}
        </Text>
      </HStack>
    </Flex>
  );
};

const QuestionDetails: React.FC = () => {
  const { question, section } = useQuestionContext();
  const isFlagged = question.answers.some((e) => e.isFlagged);
  const form = useSelectedForm();

  const firstAnswer = question.answers?.[0];

  const { scoreObtained } = question;

  return (
    <Flex w="full" flexDir="column" gap="2">
      <HStack alignItems="flex-start" justify="space-between">
        {!!firstAnswer && (
          <Text
            wordBreak="break-all"
            color="gray.400"
            fontWeight="bold"
            fontSize="sm"
          >
            {`${form?.formName} / ${section.name}${
              firstAnswer?.questionParentOptionName
                ? ` / ${firstAnswer?.questionParentOptionName}`
                : ''
            } / ${moment(firstAnswer.answeredAt).format('hh:mm a')}`}
          </Text>
        )}
        <Flex
          alignItems={{ base: 'flex-end', md: 'center' }}
          flexDir={{ base: 'column', md: 'row' }}
          gap="2"
        >
          <Badge
            w="min-content"
            hidden={!isFlagged}
            variant="outline"
            colorScheme="orange"
          >
            Sinalizado como inconforme
          </Badge>
          <Badge
            w="min-content"
            hidden={!scoreObtained}
            variant="outline"
            colorScheme="green"
          >
            {`Pontuação: ${scoreObtained}`}
          </Badge>
        </Flex>
      </HStack>

      <Text
        wordBreak={{ base: 'break-all', md: 'break-word' }}
        color="darkText"
        fontWeight="bold"
        fontSize="15px"
        lineHeight={1}
      >
        {question.question}
      </Text>
    </Flex>
  );
};

export const QuestionItemComponent: React.FC<{
  question: ItemQuestion;
  section: ItemSection;
}> = ({ question: _question, section }) => {
  const [question, setQuestion] = React.useState<ItemQuestion>(_question);
  const [paneOpen, setPaneOpen] = React.useState<
    'actions' | 'attachments' | null
  >(null);

  const togglePane = React.useCallback(
    (pane: 'actions' | 'attachments' | null) => {
      setPaneOpen((old) => (pane === old ? null : pane));
    },
    [],
  );

  return (
    <QuestionContext
      key={question.id}
      value={{
        paneOpen,
        setQuestion,
        togglePane,
        question,
        section,
      }}
    >
      <Flex
        overflow="clip"
        boxShadow="initial"
        flexDir="column"
        borderWidth="thin"
        w="full"
        rounded="xl"
        bg="white"
      >
        <Flex p="3.5" gap={4} flexDir="column">
          <QuestionDetails />
          <Flex w="full">
            <QuestionContent question={question} />
          </Flex>
          <QuestionNotes />
        </Flex>
        <QuestionAppend />
        <QuestionAttachmentsPane questionId={question.id} />
        <QuestionActions />
      </Flex>
    </QuestionContext>
  );
};

export const QuestionItem = React.memo(QuestionItemComponent);

export { useQuestionContext };
