import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';
import { useNavigation } from '@hooks/navigation';
import { ReceivedParams } from './localGeneric';
import { SubmitButton } from '../../components';
import { CommonContainer, Title, Description } from '../components';

const InformCodeSent: React.FC = () => {
  const { navigate } = useNavigation();
  const { state } = useLocation<ReceivedParams>();
  const { t } = useTranslation();

  const goToValidation = useCallback(() => {
    navigate('validate');
  }, [navigate]);

  return (
    <CommonContainer>
      <Title content={t('password_recovery.title')} />
      <Description
        content={t('password_recovery.sending_email_description').replace(
          'EMAIL_PLACEHOLDER',
          state.email,
        )}
      />
      <SubmitButton
        buttonContent={t('password_recovery.validate')}
        callback={goToValidation}
        style={{ margin: 0, marginTop: 15 }}
      />
    </CommonContainer>
  );
};

export default InformCodeSent;
