const subdomainBlacklist = process.env.REACT_APP_SUBDOMAIN_BLACKLIST
  ? process.env.REACT_APP_SUBDOMAIN_BLACKLIST.split(' ')
  : ['www'];

export function setSubdomain(subdomain: string): void {
  const { host } = window.location;
  const { protocol } = window.location;

  const filtered = host
    .split('.')
    .filter((item) => !subdomainBlacklist.includes(item));

  let parts = [subdomain].concat(filtered);

  parts = Array.from(new Set(parts));

  if (parts.length > 0 && parts.length <= 4) {
    window.location.replace(`${protocol}//${parts.join('.')}/auth`);
  }
}
