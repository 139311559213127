import React from 'react';
import useField from '@/modules/WebForm/hooks/useField';
import { createAnswer } from '@/modules/WebForm/utils';
import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import WCDatePicker from '@/components_v2/WCDatePicker';
import FormControlComponent from '../FormControlComponent';

const DateContent: React.FC<QuestionModel> = () => {
  const { error, onChange, fieldState } = useField();

  const value = React.useMemo(() => {
    if (fieldState && !Array.isArray(fieldState)) {
      if (typeof fieldState.content === 'number') {
        return new Date(fieldState.content * 1000);
      }
      if (fieldState.content instanceof Date) {
        return fieldState.content;
      }
      return new Date(parseInt(fieldState?.content, 10) * 1000);
    }
    return undefined;
  }, [fieldState]);

  const handleChange = React.useCallback(
    (newValue: Date | null) => {
      onChange(
        createAnswer(
          newValue ? Math.trunc(newValue.getTime() / 1000) : undefined,
        ),
      );
    },
    [onChange],
  );

  return (
    <FormControlComponent isInvalid={error}>
      <WCDatePicker
        withPortal
        dateFormat="dd/MM/yyyy"
        onChange={handleChange}
        selected={value}
      />
    </FormControlComponent>
  );
};

export default DateContent;
