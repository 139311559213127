export enum HttpStatusCode {
  ok = 200,
  noContent = 201,
  deletedSuccessfully = 204,
  badRequest = 400,
  unauthorized = 401,
  notFound = 404,
  serverError = 500,
}

export type HttpResponse<T> = {
  statusCode: number;
  body: T;
};
