export const colors = {
  primary: '#0794B2',
  primaryV2: '#0794B2',
  secondary: '#4FDA91',

  backgroundDefault: '#ffffff',

  pureBlack: '#000000',
  lightBlack: '#333333',

  pureWhite: '#ffffff',
  lightWhite: '#fafafa',

  lighterGrey: '#ebebeb',
  lightGrey: '#bdbdbd',
  mediumGrey: '#828282',
  gray2: '#E1E4E8', // Figma reference 'workflow'
  gray4: '#F8F8F8', // Figma reference 'workflow'
  darkGrey: '#6E7882',
  mistGrey: 'rgba(0, 0, 0, .05)',
  commentGrey: '#ADB4BB',
  borderGrey: '#dddddd',

  limeGreen: '#7FC008',
  lightOrange: '#DB8C28',
  strongOrange: '#FF6B00',
  strongRed: '#ff0000',
};
