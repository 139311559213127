import { FeedbackError } from '@/components/Feedback';
import config from '@/Constants';
import {
  Answer,
  FormularyItem,
} from '@/modules/core/domain/interfaces/IFormulary';
import { UnexpectedError } from '@/modules/shared/domain/errors';
import { AxiosHelper } from '@/modules/shared/infra/protocols/http';

import snakeToCamel from '@/utils/snakeToCamel';

export type ItemWithAnswers = FormularyItem & {
  answers: Answer[];
};

export async function fetchItem(itemId: string): Promise<ItemWithAnswers> {
  const url = `${config.ITEMS_URL}/${itemId}`;

  const response = await AxiosHelper.get<ItemWithAnswers>({ url });
  //
  const { body, statusCode } = response;

  if (statusCode < 200 || statusCode >= 300) {
    FeedbackError({ mainText: 'Erro ao carregar respostas' });
    throw new UnexpectedError();
  }

  const parsed: ItemWithAnswers = snakeToCamel(body);

  return parsed;
}
