import { createContext } from '@/utils/createContext';
import React from 'react';

import { IQuestionState } from '../../types';

function useQuestionState(props: IQuestionState) {
  const question = React.useRef(props).current;

  const [paneIsOpen, togglePane] = React.useState<'attachments' | 'notes' | ''>(
    '',
  );

  return {
    question,
    paneIsOpen,
    togglePane,
  };
}

export type QuestionItemContextValue = ReturnType<typeof useQuestionState>;

const [QuestionItemContext, useQuestionItem] = createContext<
  QuestionItemContextValue
>();

export const QuestionItemProvider: React.FC<{ question: IQuestionState }> = ({
  children,
  question,
}) => {
  return (
    <QuestionItemContext value={useQuestionState(question)}>
      {children}
    </QuestionItemContext>
  );
};

export { useQuestionItem };
