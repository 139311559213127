/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import {
  BaseField,
  Field,
  FormLayout,
  HookForm,
} from '@/components_v2/HookForm';
import PopoverAccessSettings from '@/components_v2/inputs/PopoverAccessSettings';
import { useFragment } from '@/gql';
import { AccessSettings, AutomationPropertyTypes } from '@/gql/graphql';
import { useModals } from '@/hooks/ModalManager';
import { useDeleteConfirmation } from '@/screens/Tickets/Common/DeleteConfirmation';
import { useCreateAutomationFlow } from '@/screens/Tickets/hooks/useCreateAutomationFlow';
import { useCurrentAutoFlow } from '@/screens/Tickets/hooks/useCurrentAutoFlow';
import { useDeleteAutomationFlow } from '@/screens/Tickets/hooks/useDeleteAutomationFlow';
import {
  AutomationFlowPropsFrag,
  AutomationProperties_FlowFragment,
  useFetchFlow,
} from '@/screens/Tickets/hooks/useFetchFlow';
import { useUpdateAutomationFlow } from '@/screens/Tickets/hooks/useUpdateAutomationFlow';
import { CustomIcons } from '@/screens/Workflow/utils/customIcons';
import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  Flex,
  FormLabel,
  GridItem,
  IconButton,
  Input,
  SimpleGrid,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const getFragmentData = useFragment;

type FormProps = { id?: string; groupId?: string };

const propertySchema = yup.object({
  id: yup.string().optional().nullable(),
  name: yup.string().required('Campo obrigatório'),
  type: yup
    .mixed<AutomationPropertyTypes>()
    .oneOf(Object.values(AutomationPropertyTypes))
    .required('Campo obrigatório')
    .default(AutomationPropertyTypes.Text),
  _local: yup.boolean().optional(),
  _destroy: yup.boolean().optional(),
});

const formSchema = yup
  .object({
    id: yup.string(),
    name: yup
      .string()
      .label('Etiqueta')
      .required('Etiqueta é um campo obrigatório'),
    accessSettings: yup
      .object({
        teamIds: yup.array().of(yup.string().required()),
        userIds: yup.array().of(yup.string().required()),
      })
      .nullable(),
    properties: yup.array().of(propertySchema.required()).default([]),
  })
  .required();

type FlowFormSchema = yup.InferType<typeof formSchema>;

function useAutomationFlowFormController(params: {
  id?: string;
  groupId?: string;
}) {
  const { groupId, id } = params;
  const { data, loading } = useFetchFlow({ id });

  const flowFragment = useFragment(AutomationFlowPropsFrag, data);

  const { createAutomationFlow, ...createPayload } = useCreateAutomationFlow();
  const { updateAutomationFlow, ...updatePayload } = useUpdateAutomationFlow();

  const onCreate = React.useCallback(
    (payload: FlowFormSchema) => {
      const { name, properties, accessSettings } = payload;
      if (!groupId) return undefined;

      return createAutomationFlow({
        name,
        automationGroupId: groupId,
        accessSettings,
        automationPropertiesAttributes: properties?.map(
          ({ name, type, _destroy }) => ({
            name,
            _destroy,
            propertyType: type,
          }),
        ),
      });
    },
    [createAutomationFlow, groupId],
  );

  const onUpdate = React.useCallback(
    (payload: FlowFormSchema) => {
      const { name, properties, id, accessSettings } = payload;

      return updateAutomationFlow({
        id,
        name,
        automationGroupId: groupId,
        accessSettings,
        automationPropertiesAttributes: properties?.map(
          ({ name, type, _destroy, id }) => ({
            id,
            name,
            _destroy,
            propertyType: type,
          }),
        ),
      });
    },
    [groupId, updateAutomationFlow],
  );

  const handleSubmit = React.useMemo(() => (id ? onUpdate : onCreate), [
    id,
    onCreate,
    onUpdate,
  ]);

  const formDefaultValues: FlowFormSchema | undefined = React.useMemo(() => {
    if (!flowFragment) {
      return {
        name: '',
        properties: [
          {
            id: null,
            name: '',
            type: AutomationPropertyTypes.Text,
            _local: true,
            _destroy: false,
          },
        ],
      };
    }
    const automationProperties = getFragmentData(
      AutomationProperties_FlowFragment,
      flowFragment.automationProperties,
    );
    return {
      id: flowFragment.id,
      name: flowFragment.name,

      properties:
        automationProperties?.map(({ id: propertyId, name, propertyType }) => ({
          id: propertyId,
          name: name || '',
          type: propertyType || AutomationPropertyTypes.Text,
          _local: false,
          _destroy: false,
        })) || [],
    } as FlowFormSchema;
  }, [flowFragment]);

  return {
    handleSubmit,
    accessSettings: flowFragment?.accessSettings,
    formDefaultValues,
    loading,
    mutating: createPayload.loading || updatePayload.loading,
  };
}

function AccessSettingsField({
  accessSettings,
}: {
  accessSettings?: Maybe<AccessSettings>;
}) {
  const form = useFormContext<FlowFormSchema>();
  const control = useController({
    control: form.control,
    name: 'accessSettings',
  });
  return (
    <BaseField name="accessSettings" label="Quem Pode ver?">
      <PopoverAccessSettings
        teamsUrl="api/v1/teams?only_id_name=true"
        usersUrl="api/v1/users?&only_id_name=trueapi/v1/users?&only_id_name=true"
        initialValues={{
          teams:
            accessSettings?.teams?.map((opt) => ({
              id: opt.id || '',
              name: opt.name || '',
            })) || [],
          users:
            accessSettings?.users?.map((opt) => ({
              id: opt.id || '',
              name: opt.name || '',
            })) || [],
        }}
        onChange={(values) => {
          control.field.onChange({
            teamIds: values.teams,
            userIds: values.users,
          });
        }}
        renderTrigger={(formattedText) => (
          <Input
            as={Button}
            overflow="hidden"
            textAlign="start"
            maxW="350px"
            fontWeight="medium"
            display="block"
            textOverflow="ellipsis"
          >
            <span>{formattedText}</span>
          </Input>
        )}
      />
    </BaseField>
  );
}

export const PropertiesInput = () => {
  const modals = useModals();
  const { t } = useTranslation();
  const { control } = useFormContext<FlowFormSchema>();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'properties',
    keyName: 'key',
  });

  return (
    <BaseField name="properties">
      <SimpleGrid gap="3" columns={3} templateColumns="1fr 1fr 32px">
        {!!fields.length && (
          <>
            <GridItem>
              <FormLabel fontWeight="semibold">Nome do atributo</FormLabel>
            </GridItem>
            <GridItem>
              <FormLabel fontWeight="semibold">Tipo</FormLabel>
            </GridItem>
            <GridItem />
          </>
        )}

        {fields.map((field, idx) => {
          return (
            !field._destroy && (
              <>
                <GridItem key={`${field.key}-name-${idx}`}>
                  <Field<FlowFormSchema>
                    placeholder="Digite o nome do atributo"
                    name={`properties.${idx}.name`}
                  />
                </GridItem>
                <GridItem key={`${field.key}-type-${idx}`}>
                  <Field<FlowFormSchema>
                    type="native-select"
                    name={`properties.${idx}.type`}
                    options={[
                      { value: 'text', label: 'Texto' },
                      { value: 'number', label: 'Número' },
                    ]}
                  />
                </GridItem>
                <GridItem key={`${field.key}-actions-${idx}`}>
                  <IconButton
                    onClick={() => {
                      modals.confirm({
                        title: t('error.warning'),
                        body: t('messageDelete.ask'),
                        onConfirm() {
                          if (field._local) {
                            const idx = fields.findIndex(
                              (item) => item.key === field.key,
                            );
                            remove(idx);
                          } else {
                            const idx = fields.findIndex(
                              (item) => item.id === field.id,
                            );
                            update(idx, { ...field, _destroy: true });
                          }
                        },
                      });
                    }}
                    icon={<CustomIcons.TrashIcon />}
                    aria-label=""
                  />
                </GridItem>
              </>
            )
          );
        })}
        <GridItem mt="4" colSpan={3}>
          <Button
            w="full"
            size="md"
            leftIcon={<CustomIcons.AddIcon />}
            onClick={() =>
              append({
                id: null,
                name: '',
                type: AutomationPropertyTypes.Text,
                _local: true,
                _destroy: false,
              })
            }
          >
            Adicionar atributo
          </Button>
        </GridItem>
      </SimpleGrid>
    </BaseField>
  );
};

interface WrappedFormProps {
  id?: string;
  groupId?: string;
}

const WrappedForm: React.FC<WrappedFormProps> = ({
  groupId,
  id,
}: FormProps) => {
  const deleteConfirmation = useDeleteConfirmation();
  const [deleteAutomationFlow] = useDeleteAutomationFlow();
  const { removeFlow } = useCurrentAutoFlow();
  const modals = useModals();
  const {
    handleSubmit,
    mutating,
    loading,
    accessSettings,
    formDefaultValues,
  } = useAutomationFlowFormController({
    groupId,
    id,
  });

  const close = () => modals.close('create-automation-flow-modal');

  const handleDelete = () => {
    if (!formDefaultValues || !id) return;
    deleteConfirmation({
      description: (
        <span>
          Esta ação não pode ser desfeita. Isso excluirá permanentemente o fluxo{' '}
          <strong>{formDefaultValues.name}</strong>, assim como seus tickets,
          gatilhos e ações secundárias.
        </span>
      ),
      itemName: formDefaultValues.name || 'fluxo',
      buttonLabel: 'Eu entendo as consequências, exclua este fluxo',
      async onConfirm() {
        await deleteAutomationFlow({
          variables: {
            input: {
              id,
            },
          },
        });
        removeFlow();
        close();
      },
    });
  };

  const isLoading = loading || mutating;

  return (
    <chakra.fieldset disabled={isLoading}>
      <HookForm<FlowFormSchema>
        autoComplete="off"
        p="4"
        position="relative"
        id="step-form"
        resolver={yupResolver(formSchema)}
        schema={formSchema}
        defaultValues={formDefaultValues}
        onSubmit={async (values) => {
          const res = await handleSubmit(values);

          if (!res?.errors) {
            close();
          }
        }}
      >
        <FormLayout>
          <Field<FlowFormSchema> name="name" label="Etiqueta" />
          <AccessSettingsField accessSettings={accessSettings} />
          <PropertiesInput />
        </FormLayout>

        <Flex w="full" justifyContent="space-between" mt="10">
          <Flex>
            <Button
              display={id ? 'inherit' : 'none'}
              colorScheme="red"
              size="md"
              isLoading={isLoading}
              onClick={() => handleDelete()}
            >
              Excluir fluxo
            </Button>
          </Flex>

          <ButtonGroup size="md">
            <Button isLoading={isLoading} onClick={() => close()}>
              Cancelar
            </Button>
            <Button isLoading={isLoading} colorScheme="primary" type="submit">
              {id ? 'Atualizar fluxo' : 'Criar fluxo'}
            </Button>
          </ButtonGroup>
        </Flex>
      </HookForm>
    </chakra.fieldset>
  );
};

export function useAutomationFlowForm() {
  const modals = useModals();

  const openForm = React.useCallback(
    (params: FormProps) => {
      modals.open({
        id: 'create-automation-flow-modal',
        scrollBehavior: 'inside',
        title: params.id ? 'Editar fluxo' : 'Criar novo fluxo',
        trapFocus: false,
        size: '2xl',
        body: (
          <Box flex="1">
            <WrappedForm {...params} />
          </Box>
        ),
      });
    },
    [modals],
  );

  return openForm;
}
