import { gql, useFragment } from '@/gql';
import { CreateEmailInput } from '@/gql/graphql';
import { useModals } from '@/hooks/ModalManager';
import { useMutation, useQuery } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import React from 'react';

export const getFragmentData = useFragment;

// FRAGMENTS

export const templateTaskUrlEmailFragment = gql(`#graphql
  fragment TemplateTaskUrlEmailFragment on TemplateTaskUrlEmail {
    id
    email
    createdAt
    sentAt
    templateTaskUrlId
    deletedAt
    authorId
  }
`);

export const templateTaskUrlFragment = gql(`#graphql
  fragment TemplateTaskUrlFragment on TemplateTaskUrl {
    id
    active
    urlType
    updatedAt
    description
    templateTaskUrlEmails {
      ...TemplateTaskUrlEmailFragment
    }
  }
`);

// MUTATIONS

/** Enviar email novamente */
export const templateTaskUrlResendEmail = gql(`#graphql
  mutation TemplateTaskUrlResendEmail($input: ResendEmailInput!) {
    templateTaskUrlResendEmail(input: $input) {
      errors
      templateTaskUrlEmail {
       ...TemplateTaskUrlEmailFragment
      }
    }
  }
`);

/** Deletar email */
export const templateTaskUrlDeleteEmail = gql(`#graphql
  mutation TemplateTaskUrlDeleteEmail($input: DeleteEmailInput!) {
    templateTaskUrlDeleteEmail(input: $input) {
      errors
      templateTaskUrlEmail {
        ...TemplateTaskUrlEmailFragment
      }
    }
  }
`);

/** Criar Compartilhamento */
export const createTemplateTaskUrl = gql(`#graphql
  mutation CreateTemplateTaskUrl($input: CreateInput!) {
    createTemplateTaskUrl(input: $input) {
      errors
      templateTaskUrl {
        ...TemplateTaskUrlFragment
      }
    }
  }
`);

/** Editar compartilhamento */
export const updateTemplateTaskUrl = gql(`#graphql
  mutation UpdateTemplateTaskUrl($input: UpdateInput!) {
    updateTemplateTaskUrl(input: $input) {
      errors
      templateTaskUrl {
        ...TemplateTaskUrlFragment
      }
    }
  }
`);

/** Deletar compartilhamento */
export const deleteTemplateTaskUrl = gql(`#graphql
  mutation DeleteTemplateTaskUrl($input: DeleteInput!) {
    deleteTemplateTaskUrl(input: $input) {
      errors
      templateTaskUrl {
        ...TemplateTaskUrlFragment
      }
    }
  }
`);

/** Criar email */
export const templateTaskCreateEmail = gql(`#graphql
  mutation TemplateTaskUrlCreateEmail($input: CreateEmailInput!) {
    templateTaskUrlCreateEmail(input: $input) {
      errors
      templateTaskUrlEmail {
        ...TemplateTaskUrlEmailFragment
      }
    }
  }
`);

// QUERIES

/** Buscar lista de compartilhamento */
export const templateTaskUrls = gql(`#graphql
  query TemplateTaskUrls($templateTaskId: ID!) {
    templateTaskUrls(templateTaskId: $templateTaskId) {
      id
      templateTaskId
      urlType
      active
      authorId
      deletedAt
      createdAt
      updatedAt
      templateTaskUrlEmails {
        ...TemplateTaskUrlEmailFragment
      }
    }
  }
`);

/** Buscar compartilhamento */
export const templateTaskUrl = gql(`#graphql
  query TemplateTaskUrl($templateTaskUrlId: ID!) {
    templateTaskUrl(id: $templateTaskUrlId) {
      ...TemplateTaskUrlFragment
    }
  }
`);

// HOOKS

export function useTemplateTaskUrlsQuery({
  templateTaskId,
}: {
  templateTaskId: string;
}) {
  return useQuery(templateTaskUrls, {
    variables: {
      templateTaskId,
    },
    fetchPolicy: 'cache-and-network',
  });
}

export function useTemplateTaskUrlQuery({ id }: { id: string }) {
  return useQuery(templateTaskUrl, {
    variables: {
      templateTaskUrlId: id,
    },
    fetchPolicy: 'cache-and-network',
  });
}

export function useTemplateTaskUrlResendEmail() {
  return useMutation(templateTaskUrlResendEmail);
}

export function useTemplateTaskUrlDeleteEmail() {
  return useMutation(templateTaskUrlDeleteEmail);
}

export function useCreateTemplateTaskUrl() {
  return useMutation(createTemplateTaskUrl);
}

export function useUpdateTemplateTaskUrl() {
  return useMutation(updateTemplateTaskUrl);
}

export function useToggleUrlActive() {
  const [mutate, payload] = useUpdateTemplateTaskUrl();

  const update = (props: { id: string; active: boolean }) => {
    return mutate({
      variables: {
        input: {
          id: props.id,
          active: props.active,
        },
      },
    });
  };

  return [update, payload] as const;
}

export function useDeleteTemplateTaskUrl() {
  const modals = useModals();
  const toast = useToast();
  const [mutation, payload] = useMutation(deleteTemplateTaskUrl);

  const onDelete = React.useCallback(
    (id: string, onComplete: () => void) => {
      modals.confirm({
        title: 'Atenção!',
        body:
          'Você está prestes a excluir este compartilhamento. Os usuários não terão mais acesso e a ação não pode ser desfeita. Tem certeza que deseja continuar?',
        onConfirm() {
          mutation({
            variables: {
              input: {
                id,
              },
            },
            onCompleted() {
              onComplete();
              toast({ title: 'Compartilhamento excluído', status: 'info' });
            },
          });
        },
      });
    },
    [modals, mutation, toast],
  );
  return [onDelete, payload] as const;
}

export function useCreateTemplateTaskEmail() {
  const toast = useToast();
  const [mutate, payload] = useMutation(templateTaskCreateEmail);

  const send = React.useCallback(
    (input: CreateEmailInput) => {
      return mutate({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: templateTaskUrl,
            variables: {
              templateTaskUrlId: input.templateTaskUrlId,
            },
          },
        ],
        onCompleted() {
          toast({
            title: 'Email enviado',
            description: `Em breve ${input.email} receberá o e-mail.`,
            status: 'success',
          });
        },
        onError(error) {
          toast({
            title: 'Ocorreu um erro',
            description: error.message,
            status: 'error',
          });
        },
      });
    },
    [mutate, toast],
  );

  return [send, payload] as const;
}

export function useDeleteTemplateTaskUrlEmail() {
  const modals = useModals();
  const toast = useToast();
  const [mutation, payload] = useMutation(templateTaskUrlDeleteEmail);

  const onDelete = React.useCallback(
    (
      { id, templateTaskUrlId }: { id: string; templateTaskUrlId: string },
      onComplete?: () => void,
    ) => {
      modals.confirm({
        title: 'Atenção!',
        body:
          'Você está prestes a excluir este compartilhamento via e-mail. Tem certeza que deseja continuar?',
        onConfirm() {
          mutation({
            refetchQueries: [
              {
                query: templateTaskUrl,
                variables: {
                  templateTaskUrlId,
                },
              },
            ],
            variables: {
              input: {
                id,
              },
            },
            onCompleted() {
              onComplete?.();
              toast({
                title: 'Compartilhamento via e-mail excluído',
                status: 'info',
              });
            },
          });
        },
      });
    },
    [modals, mutation, toast],
  );
  return [onDelete, payload] as const;
}

export function useResendTemplateTaskUrlEmail() {
  const modals = useModals();
  const toast = useToast();
  const [mutation, payload] = useMutation(templateTaskUrlResendEmail);

  const onDelete = React.useCallback(
    (
      {
        id,
        templateTaskUrlId,
        email,
      }: { id: string; templateTaskUrlId: string; email: string },
      onComplete?: () => void,
    ) => {
      modals.confirm({
        title: 'Atenção!',
        body: 'Tem certeza que deseja reenviar para este e-mail?',
        onConfirm() {
          mutation({
            refetchQueries: [
              {
                query: templateTaskUrl,
                variables: {
                  templateTaskUrlId,
                },
              },
            ],
            variables: {
              input: {
                id,
              },
            },
            onCompleted() {
              onComplete?.();
              toast({
                title: 'Email enviado',
                description: `Em breve ${email} receberá o e-mail.`,
                status: 'success',
              });
            },
          });
        },
      });
    },
    [modals, mutation, toast],
  );
  return [onDelete, payload] as const;
}
