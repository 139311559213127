import { parseHex } from './parseHex';

export type ColorType = 'black' | 'white';

export function getContrastColor(
  hexcolor: string,
  threshold: number = 200,
): ColorType {
  const formattedHex = parseHex(hexcolor).replace('#', '');
  const r = parseInt(formattedHex.substr(0, 2), 16);
  const g = parseInt(formattedHex.substr(2, 2), 16);
  const b = parseInt(formattedHex.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= threshold ? 'black' : 'white';
}
