/* eslint-disable react/no-array-index-key */
import config from '@/Constants';
import { useModals } from '@/hooks/ModalManager';
import { BoardItem } from '@/screens/Workflow/Common/BoardItem';
import { Workflow } from '@/screens/Workflow/types/Workflow';
import { Flex, List, Skeleton } from '@chakra-ui/react';
import React from 'react';
import { useQuery } from 'react-query';

export type FlowsListProps = {
  onSelect?: (id: string) => void;
};

export const FlowsList: React.FC<FlowsListProps> = ({
  onSelect = () => null,
}) => {
  const { data, isLoading } = useQuery<Workflow[]>(config.WORKFLOWS_URL);

  const items = React.useMemo(() => data || [], [data]);

  const renderItems = React.useCallback(() => {
    return items
      ?.filter((e) => e.name)
      .map((item) => (
        <BoardItem
          onClick={() => onSelect(item.id)}
          selected={false}
          color={item.color}
          key={item.id}
        >
          {item.name}
        </BoardItem>
      ));
  }, [items, onSelect]);

  return (
    <List flex="1" overflow="auto">
      {isLoading
        ? new Array(4)
            .fill(null)
            .map((e, k) => (
              <Skeleton key={k} w="full" h="9" rounded="md" mb="1.5" />
            ))
        : renderItems()}
    </List>
  );
};

const WrappedList: React.FC<FlowsListProps> = ({ onSelect }) => {
  return (
    <Flex flex="1" p="4">
      <FlowsList onSelect={onSelect} />
    </Flex>
  );
};

export const useFlowSelectModal = () => {
  const modals = useModals();

  const open = React.useCallback(
    (params?: FlowsListProps) => {
      const onSelect = (id: string) => {
        modals.close('flow-select');
        params?.onSelect && params?.onSelect(id);
      };
      modals.open({
        id: 'flow-select',
        scope: 'alert',
        title: 'Selecione um fluxo',
        body: <WrappedList onSelect={onSelect} />,
      });
    },
    [modals],
  );

  return { open };
};
